import React, { useEffect, useState } from 'react';
import { get, put } from 'helpers/apiHelpers';
import { combineStyles, isGranted } from 'helpers/helpers';
import { ROLE_SHOW_NOTE, ROLE_NOTE_CLIENT } from 'helpers/roles';
import Moment from 'moment';

import { fetchUser } from 'actions/Users';
import { fetchDiets } from 'actions/Diets';
import { fetchVariants } from 'actions/Variants';

import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import withStyles from '@material-ui/core/styles/withStyles';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';

import 'views/Orders/calendar.css';
import DietDetailsNotes from './DietDetailsNotes';
import DietDetailsHeader from './DietDetailsHeader';
import DietDetailsClientData from './DietDetailsClientData';
import DietDetailsMoneyBox from './DietDetailsMoneybox';
import DietDetailsAddress from './DietDetailsAddress';
import SingleDayDetails from './SingleDayDetails';

import Calendar from './Calendar';
import DietDetailsPrices from './DietDetailsPrices';
import { DialogLoader } from 'components/DialogLoader';
import LogView from 'components/History/LogView';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const DietDetails = ({
  diets,
  match,
  history,
  classes,
  variants,
  fetchUser,
  openToast,
  fetchDiets,
  fetchVariants,
}) => {
  const [pickupPoints, setPickupPoints] = useState([]);
  const [addressType, setAddressType] = useState('');
  const [bagInfo, setBagInfo] = useState(null);
  const [brandInfo, setBrandInfo] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [dietCreator, setDietCreator] = useState(null);
  const [isCreatedByEmployee, setIsCreatedByEmployee] = useState(false);

  const [selectedDiet, selectDiet] = useState('');

  const [client, setClient] = useState({});
  const [dietInfo, setDietInfo] = useState(null);
  const [allDiets, setAllDiets] = useState([]);
  const [clientAddresses, setClientAddresses] = useState([]);
  const [notes, setNotes] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [fetchingDay, setFetchingDay] = useState(false);
  const [firstVisibleDay, setFirstVisibleDay] = useState('');
  const [lastVisibleDay, setLastVisibleDay] = useState('');
  const [
    isSelectedDaySubscriptionIntent,
    setIsSelectedDaySubscription,
  ] = useState(false);

  const [calendarNeedsUpdate, setCalendarNeedsUpdate] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchOnDietChange = ecommerceDietId => {
    setCalendarNeedsUpdate(true);

    Promise.all([
      //fetch all info about diet
      get(`/ecommerce-diets/${ecommerceDietId}`),
      // get history
      get(`ecommerce-diets/${ecommerceDietId}`, {
        showHistory: true,
        itemsPerPage: 1,
        'order[loggedAt]': 'ASC',
      }),
    ])
      .then(([ecommerceDiet, dietHistory, pickupPoints, details]) => {
        const clientId = ecommerceDiet.client['@id'];

        let pureClientId = clientId.match(/\d/g);
        pureClientId = pureClientId.join('');

        const createdByRecord = dietHistory
          ? dietHistory['hydra:member'][0]
          : null;
        setDietCreator(createdByRecord);
        setDietInfo(ecommerceDiet);

        return Promise.all([
          //get client addresses
          get(`${ecommerceDiet.client['@id']}/addresses`),
          //get client
          get(clientId),
          //get client notes
          get(`/notes`, { key: clientId }),
          // get all client diets
          get('ecommerce-diets', {
            pagination: false,
            'client.id': pureClientId,
            'properties[id]': 'value',
            'properties[name]': 'value',
            'properties[active]': 'value',
          }),
          // get order
          get(ecommerceDiet.order['@id']),
        ]);
      })
      .then(([addresses, client, notes, allDiets, orderInfo]) => {
        //after get
        setNotes(notes['hydra:member']);
        setClient(client);
        setClientAddresses(addresses['hydra:member']);
        setIsCreatedByEmployee(orderInfo.createdByEmployee);
        setOrderInfo(orderInfo);
        setAllDiets(
          allDiets['hydra:member'].map(diet => {
            const dietId = diet.id;
            const dietName = diet?.name ? `- ${diet?.name}` : '';
            return {
              label: diet.active ? (
                <b>
                  <Trans i18nKey="dietDetails.activeDietId" dietId={dietId}>
                    ID #{{ dietId }} {t('diets.active', 'Aktywna')}
                  </Trans>{' '}
                  {dietName}
                </b>
              ) : (
                <span>
                  ID #{diet.id} {t('diets.inactive', 'Nieaktywna')} {dietName}
                </span>
              ),
              active: diet.active,
              id: diet.id,
              value: diet['@id'],
            };
          })
        );
        setLoading(false);
      });
  };

  const fetchData = dietId => {
    Promise.all([
      //fetch all info about diet
      get(`/ecommerce-diets/${match.params.id}`),
      // get history
      get(`ecommerce-diets/${match.params.id}`, {
        showHistory: true,
        itemsPerPage: 1,
        'order[loggedAt]': 'ASC',
      }),
      get('/pick-up-points'),
      fetchDiets(),
      fetchVariants(),
    ])
      .then(([ecommerceDiet, dietHistory, pickupPoints, details]) => {
        const clientId = ecommerceDiet.client['@id'];
        let pureClientId = clientId.match(/\d/g);
        pureClientId = pureClientId.join('');

        const createdByRecord = dietHistory
          ? dietHistory['hydra:member'][0]
          : null;
        setPickupPoints(pickupPoints['hydra:member']);
        setDietCreator(createdByRecord);
        setDietInfo(ecommerceDiet);
        // setDietDetails(details);

        return Promise.all([
          //get client addresses
          get(`${ecommerceDiet.client['@id']}/addresses`),
          //get client
          get(clientId),
          //get client notes
          get(`/notes`, { key: clientId }),
          // get all client diets
          get('ecommerce-diets', {
            pagination: false,
            'client.id': pureClientId,
            'properties[id]': 'value',
            'properties[name]': 'value',
            'properties[active]': 'value',
          }),
          // get order
          get(ecommerceDiet.order['@id']),
        ]);
      })
      .then(([addresses, client, notes, allDiets, orderInfo]) => {
        //after get
        setNotes(notes['hydra:member']);
        setClient(client);
        setClientAddresses(addresses['hydra:member']);
        setIsCreatedByEmployee(orderInfo.createdByEmployee);
        setOrderInfo(orderInfo);
        setAllDiets(
          allDiets['hydra:member'].map(diet => {
            const dietId = diet.id;
            const dietName = diet?.name ? `- ${diet?.name}` : '';

            return {
              label: diet.active ? (
                <b>
                  <Trans i18nKey="dietDetails.activeDietId" dietId={dietId}>
                    ID #{{ dietId }} {t('diets.active', 'Aktywna')}
                  </Trans>{' '}
                  {dietName}
                </b>
              ) : (
                <span>
                  ID #{diet.id} {t('diets.inactive', 'Nieaktywna')} {dietName}
                </span>
              ),
              active: diet.active,
              id: diet.id,
              value: diet['@id'],
            };
          })
        );
        setLoading(false);
      });
  };

  if (isLoading) {
    return <DialogLoader loaderState={isLoading} text={'Ładowanie...'} />;
  }

  const newOrder = () => {
    history.push(`/admin/orders/add?userId=${client.id}`);
  };

  const createdDate =
    dietCreator &&
    new Moment(dietCreator.loggedAt).format(
      Moment.localeData().longDateFormat('L')
    );
  const createdTime =
    dietCreator && new Moment(dietCreator.loggedAt).format('HH:mm:ss');

  const CreatedBy = () => {
    const styles = {
      marginBottom: '10px',
    };

    const createdBy = isCreatedByEmployee
      ? t('common.employeeGen', 'pracownika')
      : t('common.clientGen', 'klienta');

    const actor = dietCreator.actor
      ? `${dietCreator.actor?.firstName} ${dietCreator.actor?.lastName} (${dietCreator.actor?.email})`
      : `${dietCreator.associatedClient?.firstName} ${dietCreator.associatedClient?.lastName} (${dietCreator.associatedClient?.email})`;

    return dietCreator ? (
      <div style={styles}>
        <Trans
          i18nKey="dietDetails.creationDate"
          createdDate={createdDate}
          createdTime={createdTime}
          createdBy={createdBy}
          actor={actor}
        >
          Dieta utworzona <b>{{ createdDate }}</b> o godzinie{' '}
          <b>{{ createdTime }}</b> przez{' '}
          <b>
            {{ createdBy }} {{ actor }}
          </b>
        </Trans>
      </div>
    ) : null;
  };

  const removeAddons = addonId => {
    const dietSubscriptionId = dietInfo?.subscription?.id;

    const url = isSelectedDaySubscriptionIntent
      ? `subscription/${dietSubscriptionId}/remove-addons`
      : `${bagInfo['@id']}/remove-addons`;

    put(url, {
      addons: [addonId],
    }).then(setBagInfo);
  };

  return (
    <div>
      <CreatedBy />
      <Card>
        <CardHeader>
          <GridContainer>
            <GridItem md={12}>
              <DietDetailsHeader
                classes={classes}
                fetchOnDietChange={fetchOnDietChange}
                allDiets={allDiets}
                orderInfo={orderInfo}
                dietInfo={dietInfo}
                history={history}
                client={client}
              />
            </GridItem>
          </GridContainer>
          <Divider />
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem md={2}>
              <div style={{ marginLeft: '5px', marginBottom: '10px' }}>
                <Button
                  variant={'outlined'}
                  fullWidth
                  onClick={() => newOrder()}
                >
                  {t('orders.newOrder')} +
                </Button>
              </div>
              <DietDetailsPrices dietInfo={dietInfo} />
              <DietDetailsClientData
                classes={classes}
                client={client}
                openToast={openToast}
              />
              <DietDetailsAddress
                setClientAddresses={setClientAddresses}
                clientAddresses={clientAddresses}
                client={client}
                dietInfo={dietInfo}
              />
              <DietDetailsMoneyBox
                client={client}
                setClient={setClient}
                fetchUser={fetchUser}
              />
              {(isGranted(ROLE_SHOW_NOTE) || isGranted(ROLE_NOTE_CLIENT)) && (
                <DietDetailsNotes
                  classes={classes}
                  setNotes={setNotes}
                  notes={notes}
                  client={client}
                  openToast={openToast}
                />
              )}
            </GridItem>
            <GridItem md={7}>
              <Calendar
                match={match}
                dietInfo={dietInfo}
                bagInfo={bagInfo}
                setBagInfo={setBagInfo}
                openToast={openToast}
                setAddressType={setAddressType}
                setFetchingDay={setFetchingDay}
                fetchingDay={fetchingDay}
                fetchOnDietChange={fetchOnDietChange}
                diets={diets}
                selectDiet={selectDiet}
                firstVisibleDay={firstVisibleDay}
                setFirstVisibleDay={setFirstVisibleDay}
                lastVisibleDay={lastVisibleDay}
                setLastVisibleDay={setLastVisibleDay}
                calendarNeedsUpdate={calendarNeedsUpdate}
                setCalendarNeedsUpdate={setCalendarNeedsUpdate}
                setBrandInfo={setBrandInfo}
                setIsSelectedDaySubscription={setIsSelectedDaySubscription}
              />
            </GridItem>
            <GridItem md={3}>
              <SingleDayDetails
                classes={classes}
                match={match}
                bagInfo={bagInfo}
                dietInfo={dietInfo}
                setBagInfo={setBagInfo}
                variants={variants}
                diets={diets}
                fetchOnDietChange={fetchOnDietChange}
                setAddressType={setAddressType}
                addressType={addressType}
                clientAddresses={clientAddresses}
                pickupPoints={pickupPoints}
                openToast={openToast}
                fetchingDay={fetchingDay}
                setFetchingDay={setFetchingDay}
                selectedDiet={selectedDiet}
                selectDiet={selectDiet}
                calendarNeedsUpdate={calendarNeedsUpdate}
                setCalendarNeedsUpdate={setCalendarNeedsUpdate}
                brandInfo={brandInfo}
                orderInfo={orderInfo}
                removeAddons={removeAddons}
                isSelectedDaySubscriptionIntent={
                  isSelectedDaySubscriptionIntent
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <LogView iri={`/ecommerce-diets/${match.params.id}`} />
    </div>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  diets: state.Diets.diets,
  variants: state.Variants.variants,
});

const mapDispatchToProps = {
  fetchUser,
  fetchDiets,
  fetchVariants,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combinedStyles)(withToast(DietDetails)));
