import React from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Input from '@material-ui/core/Input';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import TableActions from 'components/DataTable/Actions';
import { Tooltip } from '@material-ui/core/index';
import { Info, Help } from '@material-ui/icons';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react';

import { withTranslation } from 'react-i18next';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class IngredientsDetails extends React.Component {
  renderIngredients = () => {
    const renderCell = (value, multiplier, unit) => {
      return parseFloat(value * multiplier).toFixed(2) + unit;
    };

    return this.props.data.map((ingredient, index) => {
      const workName =
        ingredient.name !== ingredient.workName
          ? `(${ingredient.workName})`
          : '';

      return {
        name: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TableActions
              handleRemove={() => this.props.handleRemove(ingredient)}
            />
            <Link to={`/admin/ingredients/edit/${ingredient.id}`}>
              {ingredient.name}
              <br />
              {workName}
            </Link>
          </div>
        ),
        weight: (
          <Input
            inputProps={{
              style: {
                fontSize: 14,
                paddingLeft: 0,
                paddingTop: 5,
                paddingBottom: 5,
              },
              type: 'number',
              value: ingredient.weight,
              onChange: event => this.props.handleQuantity(index, event),
            }}
          />
        ),
        quantity: (
          <Input
            inputProps={{
              style: {
                fontSize: 14,
                paddingLeft: 0,
                paddingTop: 5,
                paddingBottom: 5,
              },
              type: 'number',
              value: ingredient.userValue,
              onChange: event => this.props.handleUserValue(index, event),
            }}
          />
        ),
        unit: ingredient.unit ? ingredient.unit.value : 'brak',
        glycemicIndex: ingredient?.glycemicIndex || (
          <Tooltip
            title={<h4>{this.props.t('glycemicIndex.ingredientNoIG')}</h4>}
          >
            <Help
              style={{
                fontSize: '16px',
                color: 'grey',
              }}
            />
          </Tooltip>
        ),
        calories: renderCell(
          ingredient.calories,
          (((ingredient.workingOnMachining || 0) / 100) * ingredient.weight) /
            100,
          ' kcal'
        ),
        protein: renderCell(
          ingredient.protein,
          (((ingredient.workingOnMachining || 0) / 100) * ingredient.weight) /
            100,
          ' g'
        ),
        fat: renderCell(
          ingredient.fat,
          (((ingredient.workingOnMachining || 0) / 100) * ingredient.weight) /
            100,
          ' g'
        ),
        carbohydrates: renderCell(
          ingredient.carbohydrates,
          (((ingredient.workingOnMachining || 0) / 100) * ingredient.weight) /
            100,
          ' g'
        ),
        roughage: renderCell(
          ingredient.roughage,
          (((ingredient.workingOnMachining || 0) / 100) * ingredient.weight) /
            100,
          ' g'
        ),
        thermalProcessing: (
          <Input
            inputProps={{
              style: {
                fontSize: 14,
                paddingLeft: 0,
                paddingTop: 5,
                paddingBottom: 5,
              },
              type: 'number',
              value:
                ingredient.thermalProcessing === null ||
                isNaN(ingredient.thermalProcessing)
                  ? ''
                  : ingredient.thermalProcessing,
              onChange: event =>
                this.props.handleThermalProcessing(index, event),
            }}
          />
        ),
        workingOnMachining: (
          <Input
            inputProps={{
              style: {
                fontSize: 14,
                paddingLeft: 0,
                paddingTop: 5,
                paddingBottom: 5,
              },
              type: 'number',
              value:
                ingredient.workingOnMachining === null ||
                isNaN(ingredient.workingOnMachining)
                  ? ''
                  : ingredient.workingOnMachining,
              onChange: event =>
                this.props.handleWorkingOnMachining(index, event),
            }}
          />
        ),
        processedWeight:
          parseFloat(
            (isNaN(ingredient.thermalProcessing) ||
            isNaN(ingredient.workingOnMachining)
              ? 0
              : ((ingredient.thermalProcessing / 100) *
                  ingredient.workingOnMachining) /
                100) * ingredient.weight
          ).toFixed(2) + ' g',
        price:
          parseFloat((ingredient.price * ingredient.weight) / 100).toFixed(2) +
          ' zł',
      };
    });
  };

  renderSumByValue = (value, unit) => {
    const { data } = this.props;
    const sum = data.reduce((acc, ingredient) => {
      return (acc += parseFloat(ingredient[value]));
    }, 0);

    return (
      <div>
        <b>{`${sum.toFixed(2)} ${unit}`}</b>
      </div>
    );
  };

  renderPriceSum = unit => {
    const { data } = this.props;
    const sum = data.reduce((acc, ingredient) => {
      return (acc += parseFloat(
        ((ingredient.price * ingredient.weight) / 100).toFixed(2)
      ));
    }, 0);

    return (
      <div>
        <b>{`${sum.toFixed(2)} ${unit}`}</b>
      </div>
    );
  };

  renderProcessedWeightSum = unit => {
    const { data } = this.props;
    const sum = data.reduce((acc, ingredient) => {
      if (ingredient.thermalProcessing || ingredient.workingOnMachining) {
        const ingredientWpO =
          (ingredient.thermalProcessing / 100) *
          (ingredient.workingOnMachining / 100) *
          parseFloat(ingredient.weight);

        return (acc += ingredientWpO);
      }

      return acc;
    }, 0);

    return (
      <div style={{ padding: '20px 0 10px' }}>
        <b>{`${sum.toFixed(2)} ${unit}`}</b>
      </div>
    );
  };

  getIngredientWomValue = (ingredient, value) => {
    const { [value]: ingredientValue, workingOnMachining, weight } = ingredient;

    return (
      (ingredientValue / 100) * (workingOnMachining / 100) * parseFloat(weight)
    );
  };

  renderFooter = (value, unit) => {
    const { data } = this.props;

    const sum = data.reduce((acc, ingredient) => {
      return (acc += this.getIngredientWomValue(ingredient, value));
    }, 0);

    return (
      <div style={{ padding: '20px 0 10px' }}>
        <b>{`${sum.toFixed(2)} ${unit}`}</b>
      </div>
    );
  };

  renderGlycemicIndex = () => {
    const { data, t } = this.props;

    const { recipeCarbohydrates, recipeGlycemicIndex } = data.reduce(
      (acc, ingredient) => {
        const { glycemicIndex, userValue } = ingredient;
        const quantity = parseFloat(parseFloat(userValue).toFixed(2));
        const ingredientCarbohydrates = this.getIngredientWomValue(
          ingredient,
          'carbohydrates'
        );
        const ingredientGlycemicIndex =
          quantity > 0 ? glycemicIndex * ingredientCarbohydrates : 0;

        return {
          ...acc,
          recipeCarbohydrates:
            acc.recipeCarbohydrates + ingredientCarbohydrates,
          recipeGlycemicIndex:
            acc.recipeGlycemicIndex + ingredientGlycemicIndex,
        };
      },
      {
        recipeCarbohydrates: 0,
        recipeGlycemicIndex: 0,
      }
    );

    const glycemicIndex =
      recipeGlycemicIndex && recipeCarbohydrates
        ? parseFloat(recipeGlycemicIndex / recipeCarbohydrates).toFixed(2)
        : 0;

    const somIngredientNoGlycmicIndex = data.some(
      ingredient => ingredient?.macrosAfterProcessing?.glycemicIndex === null
    );

    return (
      <div style={{ padding: '20px 0 10px' }}>
        {somIngredientNoGlycmicIndex ? (
          <Tooltip title={<h4>{t('glycemicIndex.recipeIngredientNoIG')}</h4>}>
            <Help
              style={{
                fontSize: '16px',
                color: 'grey',
              }}
            />
          </Tooltip>
        ) : (
          <>
            <b>{`${glycemicIndex}`}</b>{' '}
            <Tooltip title={<h4>{t('glycemicIndex.tooltip')}</h4>}>
              <Info
                style={{
                  fontSize: '16px',
                  color: 'grey',
                  marginLeft: '4px',
                  marginBottom: '-5px',
                }}
              />
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ReactTable
                data={this.renderIngredients()}
                resizable={false}
                sortable={false}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== undefined
                    ? String(row[id])
                        .toLowerCase()
                        .includes(filter.value.toLowerCase())
                    : true;
                }}
                columns={[
                  {
                    Header: t('form.ingredientsDetails.name'),
                    accessor: 'name',
                    width: 340,
                    Footer: <b>{t('form.ingredientsDetails.recipeSummary')}</b>,
                  },
                  {
                    Header: t('form.ingredientsDetails.weight'),
                    accessor: 'weight',
                    Footer: this.renderSumByValue('weight', 'g'),
                  },
                  {
                    Header: t('form.ingredientsDetails.quantity'),
                    accessor: 'quantity',
                  },
                  {
                    Header: t('form.ingredientsDetails.unit'),
                    accessor: 'unit',
                  },
                  {
                    Header: t('glycemicIndex.shortLabel'),
                    accessor: 'glycemicIndex',
                    width: 100,
                    Footer: this.renderGlycemicIndex(),
                  },
                  {
                    Header: t('form.ingredientsDetails.calories'),
                    accessor: 'calories',
                    width: 100,
                    Footer: this.renderFooter('calories', 'kcal'),
                  },
                  {
                    Header: t('form.ingredientsDetails.protein'),
                    accessor: 'protein',
                    Footer: this.renderFooter('protein', 'g'),
                  },
                  {
                    Header: t('form.ingredientsDetails.fat'),
                    accessor: 'fat',
                    Footer: this.renderFooter('fat', 'g'),
                  },
                  {
                    Header: t('form.ingredientsDetails.carbohydrates'),
                    accessor: 'carbohydrates',
                    Footer: this.renderFooter('carbohydrates', 'g'),
                  },
                  {
                    Header: t('form.ingredientsDetails.fiber'),
                    accessor: 'roughage',
                    Footer: this.renderFooter('roughage', 'g'),
                  },
                  {
                    Header: t('form.field.wot'),
                    accessor: 'thermalProcessing',
                  },
                  {
                    Header: t('form.field.wom'),
                    accessor: 'workingOnMachining',
                  },
                  {
                    Header: t('form.field.wpo') + ' [g]',
                    accessor: 'processedWeight',
                    Footer: this.renderProcessedWeightSum('g'),
                  },
                  {
                    Header: t('form.ingredientsDetails.price'),
                    accessor: 'price',
                    Footer: this.renderPriceSum(
                      t('form.ingredientsDetails.currency')
                    ),
                  },
                ]}
                minRows={3}
                showPaginationTop={true}
                showPaginationBottom={true}
                className="-striped -highlight"
                previousText={t('form.ingredientsDetails.prevPage')}
                nextText={t('form.ingredientsDetails.nextPage')}
                loadingText={t('form.ingredientsDetails.loading')}
                noDataText={t('form.ingredientsDetails.addIngredient')}
                pageText={t('form.ingredientsDetails.page')}
                ofText={t('form.ingredientsDetails.of')}
                rowsText={t('form.ingredientsDetails.results')}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withTranslation()(withStyles(styles)(IngredientsDetails));
