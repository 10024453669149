const modules = {
  Addons: 'Addons',
  AuthServices: 'AuthServices',
  BlueMedia: 'BlueMedia',
  Caching: 'Caching',
  ConfigClientPanel: 'ConfigClientPanel',
  FacebookPixel: 'FacebookPixel',
  Fakturownia: 'Fakturownia',
  Fonts: 'Fonts',
  Goodspeed: 'Goodspeed',
  GoogleAnalytics: 'GoogleAnalytics',
  GoogleTagManager: 'GoogleTagManager',
  HotJar: 'HotJar',
  PayU: 'PayU',
  SalesManago: 'SalesManago',
  Stripe: 'Stripe',
  MyLead: 'MyLead',
  PaymentModesCopy: 'PaymentModesCopy',
};

export default modules;
