import { combineStyles, sortBy } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ArrowDown from '../../assets/img/arrow-down.svg';
import ArrowRight from '../../assets/img/arrow-right.svg';
import ArrowDiagonal from '../../assets/img/arrow-diagonal.svg';
import Datetime from 'react-datetime';
import SelectInput from '../../components/FormSelect/SelectInput';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import TagsInput from 'react-tagsinput';
import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import FormTextInputNoGrid from '../../components/FormTextInput/FormTextInputNoGrid';
import React, { Fragment, useEffect, useState } from 'react';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import Card from '../../components/Card/Card';
import moment from 'moment';
import produce from 'immer';
import { cloneDeep, zipWith } from 'lodash';
import {
  dayObject,
  dayFields,
  timeFields,
  days as daysOfWeek,
  modulesNames,
} from './consts';
import { put } from '../../helpers/apiHelpers';
import { DialogLoader } from '../../components/DialogLoader';

const translateDays = t => ({
  monday: t('common.monday', 'Poniedziałek'),
  tuesday: t('common.tuesday', 'Wtorek'),
  wednesday: t('common.wednesday', 'Środa'),
  thursday: t('common.thursday', 'Czwartek'),
  friday: t('common.friday', 'Piątek'),
  saturday: t('common.saturday', 'Sobota'),
  sunday: t('common.sunday', 'Niedziela'),
});

const translateFields = t => ({
  newOrder: t('brands.newBrandForm.translateFields.newOrder'),
  newOrderSubscription: t(
    'brands.newBrandForm.translateFields.newOrderSubscription'
  ),
  changeDate: t('brands.newBrandForm.translateFields.changeDate'),
  changeAddress: t('brands.newBrandForm.translateFields.changeAddress'),
  changeDiet: t('brands.newBrandForm.translateFields.changeDiet'),
  buyAddons: t('brands.newBrandForm.translateFields.buyAddons'),
  shouldBeDeliveredIn: t(
    'brands.newBrandForm.translateFields.shouldBeDeliveredIn'
  ),
  shouldBeDeliveredInBlock: t(
    'brands.newBrandForm.translateFields.shouldBeDeliveredInBlock'
  ),
  paymentDeadline: t('brands.newBrandForm.translateFields.paymentDeadline'),
  rate: t('brands.newBrandForm.translateFields.rate', 'Ocena menu'),
});

const DeliveriesAndOrders = ({
  classes,
  t,
  openToast,
  selectedBrand,
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
}) => {
  const [state, setState] = useState({
    days: [],
    adminDays: [],
    daysDefault: null,
    daysMax: null,
    daysMin: null,
    daysSelectorType: null,
    deliveryDaysOptions: [
      { label: 'Niedostępny', name: 'inaccessible', value: 0 },
      { label: 'Poniedziałek', name: 'monday', value: 1 },
      { label: 'Wtorek', name: 'tuesday', value: 2 },
      { label: 'Środa', name: 'wednesday', value: 3 },
      { label: 'Czwartek', name: 'thursday', value: 4 },
      { label: 'Piątek', name: 'friday', value: 5 },
      { label: 'Sobota', name: 'saturday', value: 6 },
      { label: 'Niedziela', name: 'sunday', value: 7 },
    ],
    disabledDays: [],
    calendarText: '',
    tableHeaderDates: {
      monday: '2021-09-20',
      tuesday: '2021-09-21',
      wednesday: '2021-09-22',
      thursday: '2021-09-23',
      friday: '2021-09-24',
      saturday: '2021-09-25',
      sunday: '2021-09-26',
    },
    chosenDateAndTime: {
      monday: {
        newOrder: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
      },
      tuesday: {
        newOrder: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
      },
      wednesday: {
        newOrder: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
      },
      thursday: {
        newOrder: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
      },
      friday: {
        newOrder: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
      },
      saturday: {
        newOrder: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
      },
      sunday: {
        newOrder: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        newOrderSubscription: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        buyAddons: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        changeDiet: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        changeDate: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        changeAddress: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
      },
    },
    ratingDateAndTime: {
      day: '2021-09-20',
      time: '00:00',
    },
    testDays: [],
    testDaysDefault: null,
    testDaysEnabled: false,
    testDaysMax: null,
    testDaysMin: null,
    testDaysSelectorType: null,
  });
  const [modules, setModules] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );
      const newState = {
        adminDays: brandConfig?.adminDays.sort(sortDays) || [],
        calendarText: brandConfig.calendarText || '',
        days: brandConfig.days.sort(sortDays),
        daysDefault: brandConfig.daysDefault,
        daysMax: brandConfig.daysMax,
        daysMin: brandConfig.daysMin,
        daysSelectorType: brandConfig.daysSelectorType,
        deliveryDaysOptions: translateDeliveryDaysOptions(),
        disabledDays: brandConfig.disabledDays.map(d => d.substr(0, 10)),
        testDays: brandConfig.testDays,
        testDaysDefault: brandConfig.testDaysDefault,
        testDaysEnabled: brandConfig.testDaysEnabled,
        testDaysMax: brandConfig.testDaysMax,
        testDaysMin: brandConfig.testDaysMin,
        testDaysSelectorType: brandConfig.testDaysSelectorType,
      };

      setState(prevState => ({ ...prevState, ...newState }));
      convertMinutesToDate(brandConfig);
      setModules(modulesConfig.configuration);

      setIsLoading(false);
    })();
  }, []);

  const convertMinutesToDate = brandConfig => {
    let obj = { ...state.chosenDateAndTime };
    let addedRatingData = false;
    let ratingDay = null;
    let ratingTime = null;

    daysOfWeek.forEach(dayOfWeek => {
      setState(prevState => ({
        ...prevState,
        [dayOfWeek]: dayObject(brandConfig[dayOfWeek]),
      }));

      timeFields.forEach(field => {
        const minutes = brandConfig[dayOfWeek][field];
        if (field === 'rate' && !addedRatingData) {
          const data = moment('2021-09-20').add(minutes, 'minutes');
          const date = data.format('YYYY-MM-DD');
          const time = data.format('HH:mm');
          ratingDay = date;
          ratingTime = time;
          addedRatingData = true;
        } else if (field !== 'rate') {
          const data = moment(state.tableHeaderDates[dayOfWeek]).subtract(
            minutes,
            'minutes'
          );
          const date = data.format('YYYY-MM-DD');
          const time = data.format('HH:mm');
          const day = data.locale('en').format('dddd');
          obj[dayOfWeek][field].day = { label: day, value: date };
          obj[dayOfWeek][field].time = time;
        }
      });
    });

    setState(prevState => ({
      ...prevState,
      chosenDateAndTime: obj,
      ratingDateAndTime: { day: ratingDay, time: ratingTime },
    }));
  };

  const convertDateToMinutes = () => {
    return new Promise(resolve => {
      const daysWithConvertedMinutes = {};

      for (const [dayName, value] of Object.entries(state.tableHeaderDates)) {
        const obj = {};

        for (const [dayField, dayFieldData] of Object.entries(
          state.chosenDateAndTime[dayName]
        )) {
          const dayFieldMinutes = moment(value).diff(
            moment(`${dayFieldData.day.value} ${dayFieldData.time}`),
            'minutes'
          );

          obj[dayField] = dayFieldMinutes;

          if (dayField === 'paymentDeadline') {
            const deadline = obj.newOrder - 30;
            obj.paymentDeadline = deadline < 0 ? 0 : deadline;
          }
        }

        obj.rate = moment(
          `${state.ratingDateAndTime.day} ${state.ratingDateAndTime.time}`
        ).diff(moment('2021-09-20'), 'minutes');

        // setState(prevState => ({
        //   ...prevState,
        //   [dayName]: {
        //     ...prevState[dayName],
        //     ...obj,
        //   },
        // }));
        daysWithConvertedMinutes[dayName] = { ...state[dayName], ...obj };
      }

      resolve(daysWithConvertedMinutes);
    });
  };

  const sortDays = (a, b) => a - b;

  const translateDeliveryDaysOptions = () => {
    const weekdays = cloneDeep(moment.localeData().weekdays());
    const sunday = weekdays.shift();
    weekdays.push(sunday);
    weekdays.unshift(t('common.unavailable', 'Niedostępny'));

    const deliveryDaysOptions = [
      {
        label: t('common.unavailable', 'Niedostępny'),
        name: 'inaccessible',
        value: 0,
      },
      { label: 'Poniedziałek', name: 'monday', value: 1 },
      { label: 'Wtorek', name: 'tuesday', value: 2 },
      { label: 'Środa', name: 'wednesday', value: 3 },
      { label: 'Czwartek', name: 'thursday', value: 4 },
      { label: 'Piątek', name: 'friday', value: 5 },
      { label: 'Sobota', name: 'saturday', value: 6 },
      { label: 'Niedziela', name: 'sunday', value: 7 },
    ];

    const zipped = zipWith(deliveryDaysOptions, weekdays, (d, w) => {
      d.label = w;
      return d;
    });

    return zipped;
  };

  const handleRatingTimeChange = time => {
    setState(prevState => ({
      ...prevState,
      ratingDateAndTime: {
        ...prevState.ratingDateAndTime,
        time: time.format('HH:mm'),
      },
    }));
  };

  const handleDeliveryDaySelectChange = (
    event,
    selectedValue,
    dayOfWeek,
    field
  ) => {
    let filteredDisabledDays = [];
    if (selectedValue.name === 'inaccessible') {
      filteredDisabledDays = state.disabledDays.filter(
        date =>
          new moment(date).locale('en').format('dddd').toLowerCase() !==
          event.target.name
      );

      setState(prevState => ({
        ...prevState,
        disabledDays: filteredDisabledDays,
      }));
    }

    setState(prevState => ({
      ...prevState,
      [dayOfWeek]: {
        ...prevState[dayOfWeek],
        [field]: selectedValue.value,
      },
    }));
  };

  const getWeekdayOptions = date => {
    const options = [];

    const dayMap = {
      0: t('common.sunday'),
      1: t('common.monday'),
      2: t('common.tuesday'),
      3: t('common.wednesday'),
      4: t('common.thursday'),
      5: t('common.friday'),
      6: t('common.saturday'),
    };

    for (let i = 7; i > 0; i--) {
      let dateObject = moment(date).subtract(i, 'days');
      let dayName = dayMap[dateObject.day()];
      let obj = { label: dayName, value: dateObject.format('YYYY-MM-DD') };
      options.push(obj);
    }

    return options;
  };

  const handleDaySelect = (selectedDay, dayOfWeek, field) => {
    const newState = produce(state, draftState => {
      let dayWeek = draftState.chosenDateAndTime[dayOfWeek];
      dayWeek[field].day = selectedDay;
    });

    setState(newState);
  };

  const handleTimeSelect = (selectedTime, dayOfWeek, field) => {
    const newState = produce(state, draftState => {
      let dayWeek = draftState.chosenDateAndTime[dayOfWeek];
      dayWeek[field].time = selectedTime.format('HH:mm');
    });

    setState(newState);
  };

  const returnTableCell = (classes, dayOfWeek, field) => {
    if (
      field === 'shouldBeDeliveredIn' ||
      field === 'shouldBeDeliveredInBlock'
    ) {
      return (
        <>
          <SelectInput
            noGrid
            classes={classes}
            mapBy="label"
            trackBy="value"
            name={dayOfWeek}
            value={state[dayOfWeek][field]}
            options={state.deliveryDaysOptions}
            handleChange={(ev, obj) =>
              handleDeliveryDaySelectChange(ev, obj, dayOfWeek, field)
            }
            size={12}
          />
        </>
      );
    }

    return (
      <>
        <SelectInput
          noGrid
          classes={classes}
          mapBy="label"
          trackBy="value"
          name={dayOfWeek}
          value={state.chosenDateAndTime[dayOfWeek][field].day}
          options={getWeekdayOptions(state.tableHeaderDates[dayOfWeek])}
          handleChange={(ev, obj) => handleDaySelect(obj, dayOfWeek, field)}
          size={12}
        />

        <Datetime
          dateFormat={false}
          value={state.chosenDateAndTime[dayOfWeek][field].time}
          onChange={e => handleTimeSelect(e, dayOfWeek, field)}
          inputProps={{
            placeholder: t('zones.select'),
            readOnly: true,
          }}
        />
      </>
    );
  };

  const getRatingOptions = () => {
    const options = [];
    const optionLabels = {
      0: t('brands.newBrandForm.ratingSelect.sameDay'),
      1: t('brands.newBrandForm.ratingSelect.oneDayAfter'),
      2: t('brands.newBrandForm.ratingSelect.twoDaysAfter'),
      3: t('brands.newBrandForm.ratingSelect.threeDaysAfter'),
      4: t('brands.newBrandForm.ratingSelect.fourDaysAfter'),
      5: t('brands.newBrandForm.ratingSelect.fiveDaysAfter'),
      6: t('brands.newBrandForm.ratingSelect.sixDaysAfter'),
      7: t('brands.newBrandForm.ratingSelect.sevenDaysAfter'),
    };

    for (let i = 0; i < 8; i++) {
      let dateObject = moment('2021-09-20').add(i, 'days');
      let dayName = optionLabels[i];
      let obj = { label: dayName, value: dateObject.format('YYYY-MM-DD') };
      options.push(obj);
    }

    return options;
  };

  const handleRatingDayChange = (e, day) => {
    setState(prevState => ({
      ...prevState,
      ratingDateAndTime: {
        ...prevState.ratingDateAndTime,
        day: day.value,
      },
    }));
  };

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };

  // const handleModuleUpdate = data => {
  //   const type = data['@type'];
  //   let module = { ...state.modules[type], ...data };
  //   setState(prevState => ({
  //     ...prevState,
  //     modules: { ...state.modules, [type]: module },
  //   }));
  // };

  const handleRadio = (ev, test) => {
    let stateName;
    test
      ? (stateName = 'testDaysSelectorType')
      : (stateName = 'daysSelectorType');

    setState(prevState => ({ ...prevState, [stateName]: ev.target.name }));
  };

  const handleDays = (days, changed, daysType) => {
    const hasDuplicates = () => {
      const lengthCount = days.filter(length => {
        return changed.includes(length);
      }).length;
      return lengthCount > 1;
    };

    if (hasDuplicates()) {
      return openToast({
        messages: [
          t('errors.valueAlreadyAdded', 'Ta wartość została już dodana'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (days.some(val => isNaN(val))) {
      return openToast({
        messages: [t('errors.valueMustBeNumber', 'Wartość musi być liczbą')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    setState(prevState => ({ ...prevState, [daysType]: days }));
  };

  const handleInputChange = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    try {
      const days = await convertDateToMinutes();
      const data = {
        calendarText: state.calendarText,
        disabledDays: state.disabledDays.map(el => el + ' 00:00:00'),
        days: state.days.map(length => parseInt(length)),
        daysDefault: parseInt(state.daysDefault),
        daysMax: parseInt(state.daysMax),
        daysMin: parseInt(state.daysMin),
        daysSelectorType: state.daysSelectorType,
        adminDays: state.adminDays,
        testDays: state.testDays,
        testDaysDefault: parseInt(state.testDaysDefault),
        testDaysEnabled: state.testDaysEnabled,
        testDaysMax: parseInt(state.testDaysMax),
        testDaysMin: parseInt(state.testDaysMin),
        testDaysSelectorType: state.testDaysSelectorType,
        ...days,
      };

      // daysOfWeek.forEach(dayOfWeek => {
      //   if (!data.hasOwnProperty(dayOfWeek)) {
      //     data[dayOfWeek] = {};
      //   }
      //
      //   dayFields.forEach(field => {
      //     let val = state[dayOfWeek][field];
      //     const id = state[dayOfWeek]['@id'];
      //
      //     data[dayOfWeek][field] = val;
      //
      //     if (id) {
      //       data[dayOfWeek]['@id'] = id;
      //     }
      //   });
      //
      //   data[dayOfWeek].rate = state[dayOfWeek].rate;
      // });
      //
      await put(`/brands/${selectedBrand}`, data);
      await updateBrandConfigModulesPack(selectedBrand, modules);

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 3000,
      });
      // this.props.errorHandlerCatch(error);
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} style={{ marginBottom: '50px' }}>
                  <h4>{t('brandCfg.timeSettings')}</h4>
                  <Divider style={{ marginBottom: '20px' }} />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={'250px'}>
                          Aby dokonać [akcja]{' '}
                          <img src={ArrowDown} alt="arrow" /> na [dzień]{' '}
                          <img src={ArrowRight} alt="arrow" /> należy to zrobić
                          przed <img src={ArrowDiagonal} alt="arrow" />
                        </TableCell>
                        {daysOfWeek.map((dayOfWeek, dayOfWeekIndex) => {
                          return (
                            <TableCell padding={'none'} key={dayOfWeekIndex}>
                              {translateDays(t)[dayOfWeek]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dayFields
                        .filter(field => field !== 'paymentDeadline')
                        .map((field, fieldIndex) => {
                          return (
                            <TableRow key={fieldIndex}>
                              <TableCell>
                                {translateFields(t)[field]}{' '}
                                {field === 'newOrderSubscription' && (
                                  <span style={{ color: 'grey' }}>
                                    {t(
                                      'brands.newBrandForm.translateHelpText.newOrderSubscription'
                                    )}
                                  </span>
                                )}{' '}
                                {field === 'changeDate' && (
                                  <span style={{ color: 'grey' }}>
                                    {t(
                                      'brands.newBrandForm.translateHelpText.changeDate'
                                    )}
                                  </span>
                                )}
                              </TableCell>
                              {daysOfWeek.map((dayOfWeek, dayOfWeekIndex) => {
                                return (
                                  <TableCell
                                    padding={'none'}
                                    key={dayOfWeekIndex}
                                  >
                                    <div
                                      style={{
                                        padding: '10px 5px',
                                        minWidth: '120px',
                                      }}
                                    >
                                      {returnTableCell(
                                        classes,
                                        dayOfWeek,
                                        field
                                      )}
                                    </div>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>

                  <div className="new-brand-form-rating__wrapper">
                    <div className="new-brand-form-rating__header">
                      <p>Ocena Menu</p>
                    </div>

                    <div className="new-brand-form-rating__input-wrapper">
                      <p>możliwa do godziny:</p>

                      <Datetime
                        dateFormat={false}
                        value={state.ratingDateAndTime.time}
                        onChange={handleRatingTimeChange}
                        inputProps={{
                          placeholder: t('zones.select'),
                          readOnly: true,
                        }}
                      />

                      <SelectInput
                        noGrid
                        classes={classes}
                        mapBy="label"
                        trackBy="value"
                        name="rate"
                        value={state.ratingDateAndTime.day}
                        options={getRatingOptions()}
                        handleChange={(e, obj) => handleRatingDayChange(e, obj)}
                      />
                    </div>
                  </div>

                  <h4 style={{ marginTop: '50px' }}>{t('brandCfg.daysOut')}</h4>
                  <Divider style={{ marginBottom: '20px' }} />
                  <Datetime
                    className={'customRdtPickerStyle'}
                    timeFormat={false}
                    input={false}
                    open={true}
                    value={'0'}
                    disabled={true}
                    renderDay={(props, currentDate, selectedDate) => {
                      let disabledDays = [...state.disabledDays];
                      const isSelected = disabledDays.includes(
                        currentDate.format('YYYY-MM-DD')
                      );
                      const key = currentDate
                        .locale('en')
                        .format('dddd')
                        .toLowerCase();

                      props.className += ' customTdStyle';
                      let customDayStyle = 'customDayStyle';

                      if (state[key].shouldBeDeliveredIn === 0) {
                        customDayStyle = 'customDisabledClick';
                      }
                      if (state[key].shouldBeDeliveredInBlock === 0) {
                        customDayStyle = 'customDisabledClick';
                      }
                      if (isSelected) {
                        props.className += ' dayExcluded';
                        customDayStyle += ' isSelected';
                      }
                      return (
                        <td {...props}>
                          <div className={customDayStyle}>
                            {currentDate.date()}
                          </div>
                        </td>
                      );
                    }}
                    onChange={ev => {
                      const key = ev.locale('en').format('dddd').toLowerCase();
                      if (state[key] === 0) {
                        return;
                      }
                      let disabledDays = [...state.disabledDays];
                      if (disabledDays.includes(ev.format('YYYY-MM-DD'))) {
                        setState(prevState => ({
                          ...prevState,
                          disabledDays: state.disabledDays.filter(
                            date => date !== ev.format('YYYY-MM-DD')
                          ),
                        }));
                      } else {
                        disabledDays.push(ev.format('YYYY-MM-DD'));

                        setState(prevState => ({
                          ...prevState,
                          disabledDays: disabledDays,
                        }));
                        return false;
                      }
                    }}
                  />
                  <GridContainer>
                    <GridItem md={12}>
                      <Checkbox
                        style={{ marginLeft: '-14px' }}
                        onClick={() =>
                          handleModuleUpdate({
                            ...modules?.[modulesNames.ConfigClientPanel],
                            hideDisabledDaysOnClientCalendar: !modules?.[
                              modulesNames.ConfigClientPanel
                            ].hideDisabledDaysOnClientCalendar,
                          })
                        }
                        checked={
                          modules?.[modulesNames.ConfigClientPanel]
                            .hideDisabledDaysOnClientCalendar
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                      <span>{t('brandCfg.hideDisabledDays')}</span>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={12}>
                      <h4 style={{ marginTop: '50px' }}>
                        {t('brandCfg.orderLength')}
                      </h4>
                      <Divider style={{ marginBottom: '20px' }} />
                    </GridItem>
                    <GridItem md={6}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              state.daysSelectorType === 'PREDEFINED_OPTIONS'
                            }
                            onChange={ev => handleRadio(ev)}
                            name={'PREDEFINED_OPTIONS'}
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={t('brandCfg.defLength')}
                      />
                      <label
                        htmlFor="orderLength"
                        onClick={() => {
                          setState(prevState => ({
                            ...prevState,
                            daysSelectorType: 'PREDEFINED_OPTIONS',
                          }));
                        }}
                        style={{
                          cursor: 'pointer',
                          display: 'block',
                          color: 'rgba(0, 0, 0, 0.46)',
                        }}
                      >
                        {t('brandCfg.addLength')}
                      </label>
                      <TagsInput
                        renderTag={props => {
                          let {
                            tag,
                            key,
                            disabled,
                            onRemove,
                            classNameRemove,
                            getTagDisplayValue,
                            ...other
                          } = props;
                          return (
                            <span key={key} {...other}>
                              {getTagDisplayValue(
                                parseInt(tag) === 1
                                  ? tag + t('brandCfg.day')
                                  : tag + t('brandCfg.days')
                              )}
                              {!disabled && (
                                <a
                                  className={classNameRemove}
                                  onClick={() => onRemove(key)}
                                />
                              )}
                            </span>
                          );
                        }}
                        inputProps={{
                          style: {
                            width: '45px',
                            marginTop: '25px',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.26)',
                            paddingBottom: '10px',
                          },
                          id: 'orderLength',
                          placeholder: t('brands.newBrandForm.eg', 'np: "7"'),
                          disabled:
                            state.daysSelectorType !== 'PREDEFINED_OPTIONS',
                        }}
                        tagProps={{
                          className: 'react-tagsinput-tag info',
                        }}
                        value={state.days}
                        // onlyUnique={true}
                        onChange={(tags, changed) => {
                          const tagsToInts = tags.map(el => parseInt(el));
                          const changedToInts = changed.map(el => parseInt(el));
                          handleDays(tagsToInts, changedToInts, 'days');
                        }}
                      />
                    </GridItem>
                    <GridItem md={6}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              state.daysSelectorType === 'RANGE' ||
                              state.daysSelectorType === 'RANGE_INPUT'
                            }
                            name={'RANGE'}
                            onChange={ev => handleRadio(ev)}
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={t('brandCfg.minimumAndMaximum')}
                      />
                      <label
                        htmlFor="orderLength"
                        style={{
                          cursor: 'pointer',
                          display: 'block',
                          color: 'rgba(0, 0, 0, 0.46)',
                        }}
                        onClick={() => {
                          setState(prevState => ({
                            ...prevState,
                            daysSelectorType: 'RANGE',
                          }));
                        }}
                      >
                        {t('brandCfg.setupMinMax')}
                      </label>
                      <GridContainer>
                        <GridItem md={4}>
                          <FormLabel className={classes.labelHorizontal}>
                            {t('brandCfg.minimum')}
                          </FormLabel>
                          <NumberFormat
                            classes={classes}
                            customInput={FormTextInputNoGrid}
                            format={'###'}
                            name={'daysMin'}
                            value={state.daysMin}
                            onChange={handleInputChange}
                          />
                        </GridItem>
                        <GridItem md={4}>
                          <FormLabel className={classes.labelHorizontal}>
                            {t('brandCfg.maximum')}
                          </FormLabel>
                          <NumberFormat
                            classes={classes}
                            customInput={FormTextInputNoGrid}
                            format={'###'}
                            name={'daysMax'}
                            value={state.daysMax}
                            onChange={handleInputChange}
                          />
                        </GridItem>
                        <GridItem md={4}>
                          <FormLabel className={classes.labelHorizontal}>
                            {t('brandCfg.defValue')}
                          </FormLabel>
                          <NumberFormat
                            classes={classes}
                            customInput={FormTextInputNoGrid}
                            format={'###'}
                            name={'daysDefault'}
                            value={state.daysDefault}
                            onChange={handleInputChange}
                          />
                        </GridItem>
                      </GridContainer>
                      {(state.daysSelectorType === 'RANGE' ||
                        state.daysSelectorType === 'RANGE_INPUT') && (
                        <Fragment>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={state.daysSelectorType === 'RANGE'}
                                onChange={ev => handleRadio(ev)}
                                name={'RANGE'}
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                            }}
                            label="Slider"
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  state.daysSelectorType === 'RANGE_INPUT'
                                }
                                onChange={ev => handleRadio(ev)}
                                name={'RANGE_INPUT'}
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                            }}
                            label={t(
                              'brands.newBrandForm.manual',
                              'Ręczne wpisanie'
                            )}
                          />
                        </Fragment>
                      )}
                    </GridItem>
                    <GridItem md={12}>
                      <h4 style={{ marginTop: '50px' }}>
                        {t('brandCfg.adminOrderLength')}
                      </h4>
                      <Divider style={{ marginBottom: '20px' }} />
                    </GridItem>
                    <GridItem md={12}>
                      <label
                        htmlFor="adminDays"
                        style={{
                          cursor: 'pointer',
                          display: 'block',
                          color: 'rgba(0, 0, 0, 0.46)',
                        }}
                      >
                        {t('brandCfg.addLength')}
                      </label>
                      <TagsInput
                        renderTag={props => {
                          let {
                            tag,
                            key,
                            disabled,
                            onRemove,
                            classNameRemove,
                            getTagDisplayValue,
                            ...other
                          } = props;
                          return (
                            <span key={key} {...other}>
                              {getTagDisplayValue(
                                parseInt(tag) === 1
                                  ? tag + t('brandCfg.day')
                                  : tag + t('brandCfg.days')
                              )}
                              {!disabled && (
                                <a
                                  className={classNameRemove}
                                  onClick={() => onRemove(key)}
                                />
                              )}
                            </span>
                          );
                        }}
                        inputProps={{
                          style: {
                            width: '45px',
                            marginTop: '25px',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.26)',
                            paddingBottom: '10px',
                          },
                          id: 'adminDays',
                          placeholder: 'np: "7"',
                        }}
                        tagProps={{
                          className: 'react-tagsinput-tag danger',
                        }}
                        value={state.adminDays}
                        onChange={(tags, changed) => {
                          const tagsToInts = tags.map(el => parseInt(el));
                          const changedToInts = changed.map(el => parseInt(el));
                          handleDays(tagsToInts, changedToInts, 'adminDays');
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <h4 style={{ marginTop: '50px' }}>
                    {t('brandCfg.callendarInstruction')}
                  </h4>
                  <Divider />
                  <GridContainer>
                    <GridItem md={12}>
                      <FormTextInput
                        classes={classes}
                        name="calendarText"
                        value={state.calendarText}
                        handleChange={handleInputChange}
                        inputSize={4}
                        multiline={true}
                        inputProps={{
                          maxLength: 600,
                          placeholder: t('brandCfg.typeTextCalendar'),
                        }}
                        rows={2}
                        rowsMax={10}
                        helpText={`${t('brandCfg.charCount')}: ${
                          state.calendarText.length
                        }/600`}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.testDaysEnabled}
                    onChange={e =>
                      setState(prevState => ({
                        ...prevState,
                        testDaysEnabled: !state.testDaysEnabled,
                      }))
                    }
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t('brandCfg.offerTestDays')}
              />
              <GridContainer>
                <GridItem md={12}>
                  <h4>
                    {t(
                      'brands.newBrandForm.testOrderLength',
                      'Długości zamówień testowych'
                    )}
                  </h4>
                  <Divider style={{ marginBottom: '20px' }} />
                </GridItem>
                <GridItem md={6}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          state.testDaysSelectorType === 'PREDEFINED_OPTIONS'
                        }
                        onChange={ev => handleRadio(ev, true)}
                        name={'PREDEFINED_OPTIONS'}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={t(
                      'brands.newBrandForm.definedLengths',
                      'Zdefiniowane długości'
                    )}
                  />
                  <label
                    htmlFor="testOrderLength"
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        testDaysSelectorType: 'PREDEFINED_OPTIONS',
                      }));
                    }}
                    style={{
                      cursor: 'pointer',
                      display: 'block',
                      color: 'rgba(0, 0, 0, 0.46)',
                    }}
                  >
                    {t(
                      'brands.newBrandForm.confirmBy',
                      'Dodaj długość zamówienia testowego i zatwierdź klikając  "Enter" lub "Tab" '
                    )}
                  </label>
                  <TagsInput
                    renderTag={props => {
                      let {
                        tag,
                        key,
                        disabled,
                        onRemove,
                        classNameRemove,
                        getTagDisplayValue,
                        ...other
                      } = props;
                      return (
                        <span key={key} {...other}>
                          {getTagDisplayValue(
                            parseInt(tag) === 1
                              ? tag + ` ${t('brandCfg.day', 'dzień')}`
                              : tag + ` ${t('brandCfg.days', 'dni')}`
                          )}
                          {!disabled && (
                            <a
                              className={classNameRemove}
                              onClick={e => onRemove(key)}
                            />
                          )}
                        </span>
                      );
                    }}
                    inputProps={{
                      style: {
                        width: '45px',
                        marginTop: '25px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.26)',
                        paddingBottom: '10px',
                      },
                      id: 'testOrderLength',
                      placeholder: t('brandCfg.daysExample', 'np: "7"'),
                      disabled:
                        state.testDaysSelectorType !== 'PREDEFINED_OPTIONS',
                    }}
                    tagProps={{
                      className: 'react-tagsinput-tag info',
                    }}
                    value={state.testDays}
                    onChange={(tags, changed) => {
                      const tagsToInts = tags.map(el => parseInt(el));
                      const changedToInts = changed.map(el => parseInt(el));
                      handleDays(tagsToInts, changedToInts, 'testDays');
                    }}
                  />
                </GridItem>
                <GridItem md={6}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          state.testDaysSelectorType === 'RANGE' ||
                          state.testDaysSelectorType === 'RANGE_INPUT'
                        }
                        name={'RANGE'}
                        onChange={ev => handleRadio(ev, true)}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={t(
                      'brandCfg.minimumAndMaximum',
                      'Minimalna oraz maksymalna długość'
                    )}
                  />
                  <label
                    htmlFor="orderLength"
                    style={{
                      cursor: 'pointer',
                      display: 'block',
                      color: 'rgba(0, 0, 0, 0.46)',
                    }}
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        testDaysSelectorType: 'RANGE',
                      }));
                    }}
                  >
                    {t(
                      'brands.newBrandForm.minAndMax',
                      'Ustaw minimalną oraz maksymalną wartość możliwych dni zamówień testowych '
                    )}
                  </label>
                  <GridContainer>
                    <GridItem md={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('brandCfg.minimum', 'Minimalnie ')}
                      </FormLabel>
                      <NumberFormat
                        classes={classes}
                        customInput={FormTextInputNoGrid}
                        format={'###'}
                        name={'testDaysMin'}
                        value={state.testDaysMin}
                        disabled={state.testDaysSelectorType !== 'RANGE'}
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem md={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('brandCfg.maximum', 'Maksymalnie ')}
                      </FormLabel>
                      <NumberFormat
                        classes={classes}
                        customInput={FormTextInputNoGrid}
                        format={'###'}
                        name={'testDaysMax'}
                        value={state.testDaysMax}
                        disabled={state.testDaysSelectorType !== 'RANGE'}
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem md={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('brandCfg.defValue', 'Wartość domyślna ')}
                      </FormLabel>
                      <NumberFormat
                        classes={classes}
                        customInput={FormTextInputNoGrid}
                        format={'###'}
                        name={'testDaysDefault'}
                        value={state.testDaysDefault}
                        disabled={state.testDaysSelectorType !== 'RANGE'}
                        onChange={handleInputChange}
                      />
                    </GridItem>
                  </GridContainer>
                  {(state.testDaysSelectorType === 'RANGE' ||
                    state.testDaysSelectorType === 'RANGE_INPUT') && (
                    <Fragment>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={state.testDaysSelectorType === 'RANGE'}
                            onChange={ev => handleRadio(ev, true)}
                            name={'RANGE'}
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label="Slider"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              state.testDaysSelectorType === 'RANGE_INPUT'
                            }
                            onChange={ev => handleRadio(ev, true)}
                            name={'RANGE_INPUT'}
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={t(
                          'brands.newBrandForm.manual',
                          'Ręczne wpisanie'
                        )}
                      />
                    </Fragment>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrand, fetchBrandConfigModulesPack, updateBrandConfigModulesPack }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(DeliveriesAndOrders);
