import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// javascript plugin used to create scrollbars on windows
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import Person from '@material-ui/icons/Person';
import OpenInNew from '@material-ui/icons/OpenInNew';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.jsx';
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';
import { isGranted } from 'helpers/helpers';

import { withTranslation } from 'react-i18next';

// var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  render() {
    const { className, headerLinks, links } = this.props;

    return (
      <div className={className} ref="sidebarWrapper">
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };
  // verifies if routeName is the one active (in browser input)
  disabledRoute = prop => {
    return (
      this.props.location.pathname.indexOf(prop.path) > -1 &&
      window.location.pathname === prop.layout + prop.path
    );
  };

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes, color, rtlActive } = this.props;

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.invisible) return null;
      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });
        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          ' ' +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        const caret =
          classes.caret +
          ' ' +
          cx({
            [classes.caretRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          ' ' +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={'#'}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : this.props.t(prop.mini)}
                </span>
              )}
              <ListItemText
                primary={
                  rtlActive
                    ? prop.rtlName
                    : this.props.t(prop.name, prop?.nameFallback ?? prop?.name)
                }
                secondary={
                  <b
                    className={
                      caret +
                      ' ' +
                      (this.state[prop.state] ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        ' ' +
        cx({
          [' ' + classes[color]]: this.activeRoute(prop.path),
        });
      const collapseItemMini =
        classes.collapseItemMini +
        ' ' +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      const navLinkClasses =
        classes.itemLink +
        ' ' +
        cx({
          [' ' + classes[color]]: this.activeRoute(prop.path),
        });
      const itemText =
        classes.itemText +
        ' ' +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        ' ' +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
      const itemIcon =
        classes.itemIcon +
        ' ' +
        cx({
          [classes.itemIconRTL]: rtlActive,
        });

      const tmp = {
        component:
          this.disabledRoute(prop) || prop.externalLink ? 'div' : NavLink,
      };

      let linkProps = {};
      if (prop.externalLink) {
        linkProps.onClick = event => {
          event.preventDefault();
          window.open(prop.path);
        };
      }

      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <tmp.component
            style={{ cursor: 'pointer', userSelect: 'none' }}
            to={prop.layout + (prop.sidebarPath || prop.path)}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
            {...linkProps}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === 'string' ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : this.props.t(prop.mini)}
              </span>
            )}
            <ListItemText
              primary={
                rtlActive
                  ? prop.rtlName
                  : this.props.t(prop.name, prop?.nameFallback ?? prop?.name)
              }
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </tmp.component>
        </ListItem>
      );
    });
  };

  render() {
    let { classes, image, logoText, routes, bgColor, rtlActive } = this.props;

    const filterRoutes = route => {
      if (
        route.hasOwnProperty('granted') &&
        typeof route.granted === 'function'
      ) {
        return route.granted();
      }

      if (route.hasOwnProperty('role')) {
        return isGranted(route.role);
      }

      if (route.hasOwnProperty('roles')) {
        return !route.roles.some(multipleRole => !isGranted(multipleRole));
      }

      return true;
    };

    routes = routes.filter(filterRoutes);
    routes = routes.map(route => {
      let tmp = { ...route };
      if (route.hasOwnProperty('views')) {
        tmp.views = [...route.views];
        tmp.views = tmp.views.filter(filterRoutes);
      }

      return tmp;
    });
    routes = routes.filter(route => {
      if (route.hasOwnProperty('views')) {
        return route.views.length > 0;
      }

      return true;
    });

    const shortLinks = this.props.links.filter(
      shortLink =>
        shortLink.brands.length === 0 ||
        shortLink.brands.includes('/brands/' + this.props.selectedBrand)
    );

    const adminNavLinks = shortLinks.map(shortLink => ({
      externalLink: true,
      path: shortLink.link,
      name: shortLink.label,
      mini: shortLink.symbol,
    }));

    adminNavLinks.push({
      path: '/my-links',
      name: 'common.mainMenu.navLinks.myNavLinks',
      icon: SettingsApplications,
      layout: '/admin',
    });

    routes.splice(6, 0, {
      collapse: true,
      name: this.props.t('common.mainMenu.navLinks.tabName', 'Skróty'),
      icon: OpenInNew,
      views: adminNavLinks,
    });

    const itemText =
      classes.itemText +
      ' ' +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive,
      });

    const logoMini =
      classes.logoMini +
      ' ' +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const logoClasses =
      classes.logo +
      ' ' +
      cx({
        [classes.whiteAfter]: bgColor === 'white',
      });
    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
      });

    const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}uploaded-media/`;

    var links = (
      <div className={sidebarWrapper}>
        <List className={classes.list}>{this.createLinks(routes)}</List>
      </div>
    );

    const logoNormal =
      classes.logoNormal +
      ' ' +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    var brand = (
      <div className={logoClasses}>
        {/*<a href="/" className={logoMini}>*/}
        {/*  <img src={logo} alt="logo" className={classes.img} />*/}
        {/*</a>*/}
        <a
          href="/"
          style={{ display: 'flex', justifyContent: 'center' }}
          className={logoNormal}
        >
          {logoText}
        </a>
      </div>
    );

    var user = (
      <div className={logoClasses}>
        {this.props.user.image ? (
          <div style={{ marginLeft: '25px' }} className={logoMini}>
            <img
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                verticalAlign: 'middle',
              }}
              alt="userAvatar"
              src={uploadedContentBaseURL + this.props.user.imageUrl}
            />
          </div>
        ) : (
          <div style={{ marginLeft: '25px' }} className={logoMini}>
            <Person />
          </div>
        )}
        <NavLink
          to="/admin/my-profile"
          style={{ cursor: 'pointer', margin: '0 15px' }}
          className={classes.itemLink + ' ' + classes.userCollapseButton}
        >
          <ListItemText
            primary={
              <b>
                {this.props.user.firstName} {this.props.user.lastName}
              </b>
            }
            disableTypography={true}
            className={itemText + ' ' + classes.userItemText}
          />
        </NavLink>
      </div>
    );

    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? 'left' : 'right'}
            open={this.props.open}
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            {user}
            <SidebarWrapper
              className={sidebarWrapper}
              headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? 'right' : 'left'}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
          >
            {brand}
            {user}
            <SidebarWrapper className={sidebarWrapper} links={links} />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['creamy', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    'white',
    'red',
    'orange',
    'green',
    'blue',
    'purple',
    'rose',
    'gold',
    'caterings',
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
  return {
    user: state.Auth.user,
    selectedBrand: state.Auth.selectedBrand,
    links: state.Auth.links,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null)(withStyles(sidebarStyle)(Sidebar))
);
