import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class Packers extends React.Component {
  getFileName = title => ({ date }) => {
    return `${title.replaceAll(' ', '_')}-${date}_(${moment().format(
      'YYYY-MM-DD_HH.mm'
    )})`;
  };

  render() {
    const { t } = this.props;
    const title = t('common.mainMenu.reportPackers');
    return (
      <BaseBrandAndDateReport
        title={title}
        excelUrl="reports/bag-stickers"
        pdfUrl="reports/packers-v2"
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_PACKERS}
        pdfAvaliable={true}
        excelAvaliable={false}
        archiveReportNames={['PACKERS']}
      />
    );
  }
}

export default withTranslation()(Packers);
