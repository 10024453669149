import React, { Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Datetime from 'react-datetime';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Map from 'components/Map/Map';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';

import useDebounce from 'hooks/common/useDebounce';
import { yesNoOptions } from 'utils/yesNoOptions';
import { fetchUserAddresses } from 'actions/Addresses';

import { combineStyles } from 'helpers/helpers';
import { get, post, put } from 'helpers/apiHelpers';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import defaultState from './defaultState';

const searchFields = [
  { field: 'city', zoom: 11 },
  { field: 'street', zoom: 17 },
  { field: 'buildNumber', zoom: 19 },
];

const AddressForm = ({
  match,
  classes,
  openToast,
  closeModal,
  editAddress,
  reRenderModal,
  fetchUserAddresses,
  useAddressesWithLessFields,
  selectedRegionForValidations,
  ...restProps
}) => {
  const { t } = useTranslation();

  const mapRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [mapZoom, setMapZoom] = useState(11);
  const [mapPosition, setMapPosition] = useState({
    x: 52.23682205,
    y: 21.0570343800358,
  });
  const [drivers, setDrivers] = useState([]);
  const [cities, setCities] = useState([]);
  const [streets, setStreets] = useState([]);
  const [deliveryHours, setDeliveryHours] = useState([]);
  const [buildingNumbers, setBuildingNumbers] = useState([]);

  const [state, setState] = useState({
    ...defaultState,
  });

  const isEditing = editAddress.length !== 0;
  const userId = restProps?.userId ?? match.params.id;
  const debouncedCity = useDebounce(state.city);
  const debouncedStreet = useDebounce(state.street);
  const debouncedPostCode = useDebounce(state.postCode);
  const debouncedBuildNumber = useDebounce(state.buildNumber);

  useEffect(() => {
    if (isEditing) {
      loadAddressToEdit();
    } else {
      setIsInitialized(true);
    }
  }, []);

  // USEEFFECT TO SUBSCRIBE DELIVERY TYPES LIST
  useEffect(() => {
    if (
      isInitialized &&
      !isEmpty(state.city) &&
      !isEmpty(state.street) &&
      !isEmpty(state.buildNumber) &&
      !isEmpty(debouncedCity) &&
      !isEmpty(debouncedStreet) &&
      !isEmpty(debouncedPostCode) &&
      !isEmpty(debouncedBuildNumber)
    ) {
      fetchDeliveryHours({
        city: debouncedCity,
        street: debouncedStreet,
        postCode: debouncedPostCode,
        buildNumber: debouncedBuildNumber,
      });
    } else if (
      isInitialized &&
      (isEmpty(debouncedCity) ||
        isEmpty(debouncedStreet) ||
        isEmpty(debouncedPostCode) ||
        isEmpty(debouncedBuildNumber))
    ) {
      setDeliveryHours([]);
    }
  }, [debouncedPostCode, debouncedCity, debouncedStreet, debouncedBuildNumber]);

  useEffect(() => {
    valdiateDeliveryHour();
  }, [deliveryHours]);

  const loadAddressToEdit = async () => {
    const address = editAddress[0];
    let setZoom = defaultState.zoom;

    searchFields.forEach(({ field, zoom }) => {
      if (canUsePartOfAddress(address[field])) {
        setZoom = zoom;
      }
    });

    const [addressLine1 = '', addressLine2 = ''] = address?.street?.split('; ');

    try {
      const citiesData = await fetchCities(address?.postCode);
      const streetsData = await fetchStreets({
        postCode: address?.postCode,
        city: address?.city,
      });
      const buildingNumbersData = await fetchBuildingNumbers({
        postCode: address?.postCode,
        city: address?.city,
        street: address?.street,
      });
      await fetchDeliveryHours({
        city: address?.city,
        street: address?.street,
        postCode: address?.postCode,
        buildNumber: address?.buildNumber,
      });
      setCities(
        citiesData?.allowCustom
          ? []
          : (citiesData?.cities?.['hydra:member'] ?? []).map(currentCity => ({
              '@id': currentCity,
              label: currentCity,
            }))
      );
      setStreets(
        streetsData?.allowCustom
          ? []
          : (streetsData?.streets?.['hydra:member'] ?? []).map(
              currentStreet => ({
                '@id': currentStreet,
                label: currentStreet,
              })
            )
      );
      setBuildingNumbers(
        buildingNumbersData?.allowCustom
          ? []
          : (buildingNumbersData?.streets?.['hydra:member'] ?? []).map(
              currentBuildinNumber => ({
                '@id': currentBuildinNumber,
                label: currentBuildinNumber,
              })
            )
      );
    } catch {}

    setMapZoom(setZoom);

    if (address?.zone) {
      get(address.zone['@id']).then(async res => {
        let driver;
        let addressDrivers = res.drivers;

        if (address.driver) {
          !addressDrivers.some(
            driver => driver['@id'] === address.driver['@id']
          ) && addressDrivers.push(address.driver);
          driver = addressDrivers.find(
            driver => driver['@id'] === address.driver['@id']
          )['@id'];
        } else {
          driver = null;
        }

        setMapPosition({
          x: address.locationX || mapPosition.x,
          y: address.locationY || mapPosition.y,
        });

        setDrivers(addressDrivers);
        setState({
          ...state,
          id: address.id,
          city: address.city,
          zone: address.zone ? address.zone.name : '',
          gate: address.gate,
          floor: address.floor,
          street: address.street,
          driver: driver,
          default: address.isDefault === false ? 1 : 2,
          comment: address.comment,
          hourFrom: moment(address?.selectedDeliveryHour?.hourFrom).format(
            'HH:mm'
          ),
          hourTo: moment(address?.selectedDeliveryHour?.hourTo).format('HH:mm'),
          postCode: address.postCode,
          keyToGate: address.keyToGate,
          buildNumber: address.buildNumber,
          placeNumber: address.placeNumber,
          addressLine1,
          addressLine2,
          deliveryType: res?.deliveryType ?? null,
          selectedHour: {
            '@id': address?.selectedDeliveryHour?.zoneDeliveryHours?.['@id'],
            hourFrom: address?.selectedDeliveryHour?.hourFrom,
            hourTo: address?.selectedDeliveryHour?.hourTo,
            label: `od ${moment(address?.selectedDeliveryHour?.hourFrom).format(
              'HH:mm'
            )} do ${moment(address?.selectedDeliveryHour?.hourTo).format(
              'HH:mm'
            )}`,
          },
          keyToIntercom: address.keyToIntercom,
          postCodeIsValid: true,
          customDeliveryHours: address.customDeliveryHours,
        });
        setTimeout(() => {
          setIsInitialized(true);
        }, 2000);
      });
    } else {
      setState({
        ...state,
        default: address.isDefault === false ? 1 : 2,
        id: address.id,
        city: address.city,
        customDeliveryHours: address.customDeliveryHours,
        postCode: address.postCode,
        street: address.street,
        addressLine1,
        addressLine2,
        deliveryType: null,
        buildNumber: address.buildNumber,
        placeNumber: address.placeNumber,
        floor: address.floor,
        gate: address.gate,
        keyToGate: address.keyToGate,
        keyToIntercom: address.keyToIntercom,
        comment: address.comment,
      });
      setTimeout(() => {
        setIsInitialized(true);
      }, 2000);
    }
  };

  const handleChange = (event, obj) => {
    const newState = {
      ...state,
      [event.target.name]: obj ?? event.target.value,
    };

    findLocation(event.target.name, event.target.value);
    setState(newState);
  };

  const handlePostCodeInput = async event => {
    const postCode = event.target.value;
    const regexp = selectedRegionForValidations?.postCodeRegexp;
    const regexpTest = new RegExp(`${regexp}$`);
    const postCodeIsValid = null === regexp || regexpTest.test(postCode);
    let newCities = [];

    const newState = {
      ...state,
      postCode,
      postCodeIsValid,
      postCodeHelpText: '',
    };

    setState(newState);

    if (postCodeIsValid) {
      try {
        const citiesData = await fetchCities(postCode);
        newCities = citiesData?.allowCustom
          ? []
          : (citiesData?.cities?.['hydra:member'] ?? []).map(currentCity => ({
              '@id': currentCity,
              label: currentCity,
            }));

        const isCurrentCityValid =
          !isEmpty(state.city) &&
          newCities.map(({ label }) => label).includes(state.city);

        if (!isCurrentCityValid) {
          setState({
            ...newState,
            city: '',
          });
        }
        setCities(newCities);
      } catch ({ response }) {
        const isPostCodeInvalid = (response?.data?.violations ?? []).some(
          ({ propertyPath }) => propertyPath === 'postCode'
        );

        if (isPostCodeInvalid) {
          setState({ ...newState, postCodeIsValid: false });
        }
      }
    }
  };

  const handleCitySelect = async event => {
    const { postCode } = state;

    const streetsData = await fetchStreets({
      postCode,
      city: event.target.value,
    });
    const newStreets = streetsData?.allowCustom
      ? []
      : (streetsData?.streets?.['hydra:member'] ?? []).map(currentStreet => ({
          '@id': currentStreet,
          label: currentStreet,
        }));

    findLocation('city', event.target.value);
    setState(() => ({
      ...state,
      city: event.target.value,
      street:
        newStreets.map(({ label }) => label).includes(state.street) ||
        isEmpty(newStreets)
          ? state.street
          : '',
    }));
    setStreets(newStreets);
  };

  const handleStreetSelect = async event => {
    const { postCode, city } = state;
    const buildingNumbersData = await fetchBuildingNumbers({
      postCode,
      city,
      street: event.target.value,
    });
    const newBuildingNumbers = buildingNumbersData?.allowCustom
      ? []
      : (buildingNumbersData?.buildings?.['hydra:member'] ?? []).map(
          currentBulidingNumber => ({
            '@id': currentBulidingNumber,
            label: currentBulidingNumber,
          })
        );

    findLocation('street', event.target.value);
    setState(() => ({
      ...state,
      street: event.target.value,
      buildNumber:
        newBuildingNumbers
          .map(({ label }) => label)
          .includes(state.buildNumber) || isEmpty(newBuildingNumbers)
          ? state.buildNumber
          : '',
    }));
    setBuildingNumbers(newBuildingNumbers);
  };

  const handleBuildingNumberSelect = async event => {
    findLocation('buildNumber', event.target.value);

    setState(() => ({
      ...state,
      buildNumber: event.target.value,
    }));
  };

  const fetchCities = postCode => {
    return get(`/frontend/zone-post-codes/cities?postCode=${postCode}`);
  };

  const fetchStreets = ({ postCode, city }) => {
    return get(
      `/frontend/zone-post-codes/cities/streets?postCode=${postCode}&city=${city}`
    );
  };

  const fetchBuildingNumbers = ({ postCode, city, street }) => {
    return get(
      `/frontend/zone-post-codes/cities/streets/buildings?postCode=${postCode}&city=${city}&street=${street}`
    );
  };

  const fetchDeliveryHours = async ({
    city,
    street,
    postCode,
    buildNumber,
  }) => {
    const deliveryHoursData = await get(
      `/frontend/delivery-hours?postCode=${postCode}&city=${city}&street=${street}&building=${buildNumber}`
    );

    const hours = (deliveryHoursData?.hours ?? []).map(hour => {
      const formattedHourFrom = hour?.hourFrom
        ? new moment(hour.hourFrom).format('HH:mm')
        : null;
      const formattedHourTo = hour?.hourTo
        ? new moment(hour.hourTo).format('HH:mm')
        : null;

      return {
        '@id': hour['@id'],
        hourFrom: hour?.hourFrom,
        hourTo: hour?.hourTo,
        label: [
          hour?.hourFrom &&
            [t('$*common.from', '$$od'), formattedHourFrom].join(' '),
          hour?.hourTo && [t('$*common.to', '$$do'), formattedHourTo].join(' '),
        ].join(' '),
      };
    });

    setDeliveryHours(hours ?? []);
  };

  const canUsePartOfAddress = part => {
    return part !== null && typeof part !== 'undefined' && part !== '';
  };

  const findLocation = (fieldName, value) => {
    if (!searchFields.map(el => el.field).includes(fieldName)) {
      return;
    }

    let query = '';
    let setZoom = mapZoom;

    searchFields.forEach(({ field, zoom }) => {
      const queryValue = field === fieldName ? value : state[field];

      if (canUsePartOfAddress(queryValue)) {
        query += queryValue + ' ';
        setZoom = zoom;
      }
    });

    if (query === '') {
      return;
    }

    mapRef.current.findLocation(query);
    setMapZoom(setZoom);
  };

  const validatePostCode = () => {
    return (
      state.postCodeIsValid &&
      state.postCode.length !== 0 &&
      !state.postCode.includes(' ')
    );
  };

  const valdiateDeliveryHour = () => {
    const isCurrentDeliveryHourAvaliable = deliveryHours.some(
      ({ '@id': iri }) => iri === state.selectedHour?.['@id']
    );

    if (
      isEmpty(deliveryHours) ||
      (!isEmpty(deliveryHours) && !isCurrentDeliveryHourAvaliable)
    ) {
      setState({ ...state, selectedHour: null });
    }
  };

  const validateForm = () => {
    const { city, street, postCode, buildNumber, addressLine1 } = state;

    return useAddressesWithLessFields
      ? addressLine1 && postCode && city
      : buildNumber && street && postCode && city;
  };

  const handleSubmit = ev => {
    setIsLoading(true);

    if (!validatePostCode()) {
      setIsLoading(false);
      return openToast({
        messages: [t('clients.postCodeIncorrect')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (!validateForm()) {
      setIsLoading(false);
      return openToast({
        messages: [t('clients.fillAllFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      default: state.default === 1 ? false : true,
      user: `/clients/${userId}`,
      locationX: mapPosition.x,
      locationY: mapPosition.y,
      postCode: state.postCode,
      city: state.city,
      ...(useAddressesWithLessFields
        ? {
            street: `${state.addressLine1}; ${state.addressLine2}`,
            buildNumber: '0',
          }
        : { street: state.street, buildNumber: state.buildNumber }),
      placeNumber: state.placeNumber,
      gate: state.gate,
      floor: parseInt(state.floor),
      keyToGate: state.keyToGate,
      keyToIntercom: state.keyToIntercom,
      comment: state.comment,
      deliveryType: state.deliveryType?.['@id'] ?? null,
      customDeliveryHours: state.customDeliveryHours,
      ...(state.customDeliveryHours
        ? {
            deliveryHourFrom: state.hourFrom,
            deliveryHourTo: state.hourTo,
          }
        : {
            deliveryHourFrom: state.selectedHour?.hourFrom,
            deliveryHourTo: state.selectedHour?.hourTo,
          }),
      driver: state.driver || null,
    };

    const action = isEditing
      ? put(`/addresses/${state.id}`, data)
      : post('/addresses', data);

    action.then(
      async response => {
        await fetchUserAddresses(userId);
        reRenderModal(response.id);
        setIsLoading(false);
        if (typeof closeModal !== 'undefined') {
          closeModal();
        }
        return openToast({
          messages: [
            `${
              isEditing
                ? t('clients.addressChangesSaved')
                : t('clients.addressCreated')
            }`,
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        if (
          error.response.data.violations &&
          error.response.data.violations[0].propertyPath === 'postCode'
        ) {
          setIsLoading(false);
          return openToast({
            messages: [error.response.data.violations[0].message],
            type: 'error',
            autoHideDuration: 3000,
          });
        } else {
          setIsLoading(false);
          return openToast({
            messages: [t('clients.smthWentWrong'), t('clients.checkForm')],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    );
  };

  const handleToggle = () => {
    setState({ ...state, customDeliveryHours: !state.customDeliveryHours });
  };

  return (
    <form>
      <GridContainer>
        <GridItem sm={6}>
          <GridContainer>
            {/* POST CODE */}
            <GridItem sm={6} style={{ marginBottom: '0' }}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '11px' }}
              >
                {t('clients.postCode')} *
              </FormLabel>
              <FormTextInputNoGrid
                classes={classes}
                customInput={FormTextInputNoGrid}
                value={state.postCode}
                success={state.postCodeIsValid}
                error={!state.postCodeIsValid}
                helpText={state.postCodeHelpText}
                onChange={e => handlePostCodeInput(e)}
                name="postCode"
              />
            </GridItem>
            {/* CITY */}
            <GridItem sm={6}>
              {isEmpty(cities) ? (
                <>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '11px' }}
                  >
                    {t('clients.city')} *
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'city',
                      value: state.city,
                      onChange: ev => handleChange(ev),
                    }}
                    disabled={!state.postCodeIsValid}
                  />
                </>
              ) : (
                <SelectInput
                  classes={classes}
                  label={`${t('clients.city')}*`}
                  options={cities}
                  value={state.city}
                  mapBy="label"
                  trackBy="@id"
                  name="city"
                  handleChange={handleCitySelect}
                  id="city"
                  disabled={isEmpty(cities)}
                />
              )}
            </GridItem>
            {/* SHORT ADDRESS FORM INPUTS */}
            {useAddressesWithLessFields && (
              <Fragment>
                <GridItem sm={6} style={{ marginTop: '0' }}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '11px' }}
                  >
                    {t('clients.addressLine1')} *
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'addressLine1',
                      value: state.addressLine1,
                      onChange: ev => handleChange(ev),
                    }}
                  />
                </GridItem>
                <GridItem sm={6} style={{ marginTop: '0' }}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '11px' }}
                  >
                    {t('clients.addressLine2')}
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'addressLine2',
                      value: state.addressLine2,
                      onChange: ev => handleChange(ev),
                    }}
                  />
                </GridItem>
              </Fragment>
            )}

            {!useAddressesWithLessFields && (
              <Fragment>
                {/* STREET */}
                <GridItem sm={4} style={{ marginTop: '0' }}>
                  {isEmpty(cities) || isEmpty(streets) ? (
                    <>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ marginBottom: '11px' }}
                      >
                        {t('clients.street')} *
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'street',
                          value: state.street,
                          onChange: ev => handleChange(ev),
                        }}
                        disabled={isEmpty(state.city)}
                      />
                    </>
                  ) : (
                    <SelectInput
                      classes={classes}
                      label={`${t('clients.street')} *`}
                      options={streets}
                      value={state.street}
                      mapBy="label"
                      trackBy="@id"
                      name="street"
                      handleChange={handleStreetSelect}
                      id="street"
                      disabled={isEmpty(streets)}
                    />
                  )}
                </GridItem>
                {/* BUILDING NUMBER */}
                <GridItem sm={4} style={{ marginTop: '0' }}>
                  {isEmpty(cities) ||
                  isEmpty(streets) ||
                  isEmpty(buildingNumbers) ? (
                    <>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ marginBottom: '11px' }}
                      >
                        {t('clients.buildingNo')} *
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'buildNumber',
                          maxLength: 16,
                          value: state.buildNumber,
                          onChange: ev => handleChange(ev),
                        }}
                        disabled={isEmpty(state.street)}
                      />
                    </>
                  ) : (
                    <SelectInput
                      classes={classes}
                      label={`${t('clients.buildingNo')} *`}
                      options={buildingNumbers}
                      value={state.buildNumber}
                      mapBy="label"
                      trackBy="@id"
                      name="buildNumber"
                      handleChange={handleBuildingNumberSelect}
                      id="buildNumber"
                      disabled={isEmpty(buildingNumbers)}
                    />
                  )}
                </GridItem>
                {/* DOOR NUMBER */}
                <GridItem sm={4} style={{ marginTop: '0' }}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '11px' }}
                  >
                    {t('clients.localNo')}
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'placeNumber',
                      maxLength: 16,
                      value: state.placeNumber,
                      onChange: ev => handleChange(ev),
                    }}
                  />
                </GridItem>
                {/* GATE */}
                <GridItem sm={4} style={{ marginTop: '0' }}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '11px' }}
                  >
                    {t('clients.gate')}
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'gate',
                      maxLength: 16,
                      value: state.gate,
                      onChange: ev => handleChange(ev),
                    }}
                  />
                </GridItem>
                {/* FLOOR */}
                <GridItem sm={4} style={{ marginTop: '3px' }}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '11px' }}
                  >
                    {t('clients.floor')}
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'floor',
                      type: 'number',
                      value: state.floor,
                      onChange: ev => handleChange(ev),
                    }}
                  />
                </GridItem>
                {/* INTERCOM CODE */}
                <GridItem sm={4} style={{ marginTop: '0', marginBottom: '0' }}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '11px' }}
                  >
                    {t('clients.callCode')}
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    maxLength={32}
                    inputProps={{
                      name: 'keyToIntercom',
                      value: state.keyToIntercom,
                      onChange: ev => handleChange(ev),
                    }}
                  />
                </GridItem>
              </Fragment>
            )}

            <GridItem sm={6} style={{ marginTop: '0', marginBottom: '0' }}>
              <FormSelectSingle
                style={{ marginTop: '5px' }}
                label={t('clients.defaultAddress')}
                classes={classes}
                options={yesNoOptions({ t: t }).map(option => {
                  option.value++;
                  return option;
                })}
                value={state.default}
                mapBy="name"
                trackBy="value"
                name="default"
                handleChange={ev => handleChange(ev)}
                id="default"
              />
            </GridItem>
            {/* ZONE */}
            <GridItem sm={6}>
              <FormLabel
                style={{ marginBottom: '6px' }}
                className={classes.labelHorizontal}
              >
                {t('clients.zone')}
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                helpText={state.zone !== '' ? '' : t('clients.thisFieldAuto')}
                inputProps={{
                  disabled: true,
                  value: state.zone,
                }}
              />
            </GridItem>
            {/* ADDITIONAL NOTES */}
            <GridItem sm={12} style={{ marginTop: '-10px', marginBottom: '0' }}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '11px' }}
              >
                {t('clients.addressNotes')}
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  multiline: true,
                  maxLength: 400,
                  name: 'comment',
                  value: state.comment,
                  onChange: ev => handleChange(ev),
                }}
              />
            </GridItem>
            {/* DELIVERY TYPE */}
            <GridItem sm={4} style={{ marginTop: '0' }}>
              <FormLabel
                style={{ marginBottom: '11px' }}
                className={classes.labelHorizontal}
              >
                {t('clients.type')}
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                helpText={
                  state.deliveryType !== '' ? '' : t('clients.thisFieldAuto')
                }
                inputProps={{
                  disabled: true,
                  value: state.deliveryType?.value,
                }}
              />
            </GridItem>
            {/* STANDARD DELIVERY HOURS */}
            {!state.customDeliveryHours && (
              <GridItem sm={4} style={{ marginTop: '0' }}>
                <SelectInput
                  classes={classes}
                  label={`${t('clients.deliveryH')}`}
                  options={deliveryHours}
                  value={state.selectedHour}
                  mapBy="label"
                  trackBy="@id"
                  name="selectedHour"
                  handleChange={(ev, obj) => handleChange(ev, obj)}
                  id="selectedHour"
                  disabled={isEmpty(deliveryHours)}
                />
                <p>
                  {t(
                    '$*users.address.editing.deliveryHours',
                    '$Zmiana godzin dostawy może zmienić przypisaną strefę i, co za tym idzie, kierowcę. Sprawdź kierowcę po zapisaniu zmian w tym polu.'
                  )}
                </p>
              </GridItem>
            )}
            {/* DRIVER */}
            <GridItem sm={4} style={{ marginTop: '0' }}>
              <div
                onClick={() =>
                  !isEditing &&
                  openToast({
                    messages: [t('clients.driverAuto')],
                    type: 'info',
                    autoHideDuration: 4000,
                  })
                }
              >
                <SelectInput
                  classes={classes}
                  label={t('clients.assignedDriver')}
                  options={drivers}
                  value={state.driver}
                  mapBy="name"
                  trackBy="@id"
                  name="driver"
                  handleChange={ev => handleChange(ev)}
                  disabled={!isEditing}
                  id="driver"
                />
              </div>
            </GridItem>
            {/* CUSTOM DELIVERY HOURS */}
            <GridItem sm={12} style={{ marginTop: '0' }}>
              <GridContainer>
                <GridItem sm={4} style={{ marginTop: '0' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="customDeliveryHours"
                        onClick={handleToggle}
                        checked={state.customDeliveryHours}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={t('form.customDeliveryHours')}
                  />
                </GridItem>
                {state.customDeliveryHours && (
                  <GridItem sm={4} style={{ marginTop: '0' }}>
                    <div>
                      <FormLabel
                        style={{ marginTop: '5px' }}
                        className={classes.labelHorizontal}
                      >
                        {`${t('comments.from')} *`}
                      </FormLabel>
                      <Datetime
                        dateFormat={false}
                        value={state.hourFrom}
                        onChange={ev =>
                          setState({ ...state, hourFrom: ev.format('HH:mm') })
                        }
                      />
                    </div>
                  </GridItem>
                )}
                {state.customDeliveryHours && (
                  <GridItem sm={4} style={{ marginTop: '0' }}>
                    <div>
                      <FormLabel
                        style={{ marginTop: '5px' }}
                        className={classes.labelHorizontal}
                      >
                        {`${t('comments.to')} *`}
                      </FormLabel>
                      <Datetime
                        dateFormat={false}
                        value={state.hourTo}
                        onChange={ev =>
                          setState({ ...state, hourTo: ev.format('HH:mm') })
                        }
                      />
                    </div>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
        {/* MAP */}
        <GridItem sm={6}>
          <GridContainer>
            <GridItem sm={12} style={{ marginTop: '12px' }}>
              <Map
                x={mapPosition.x}
                y={mapPosition.y}
                ref={mapRef}
                zoom={mapZoom}
                height={'382px'}
                handleChange={(x, y) => {
                  setMapPosition({ x: parseFloat(x), y: parseFloat(y) });
                }}
              />
            </GridItem>
            <GridItem sm={6}>
              <FormLabel className={classes.labelHorizontal}>
                {t('clients.latitude')}
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  disabled: true,
                  value: mapPosition?.x?.toFixed(6),
                }}
              />
            </GridItem>
            <GridItem sm={6}>
              <FormLabel className={classes.labelHorizontal}>
                {t('clients.longitude')}
              </FormLabel>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  disabled: true,
                  value: mapPosition?.y?.toFixed(6),
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      {/* BUTTONS */}
      <GridContainer justify="center">
        <GridItem style={{ marginTop: '0' }}>
          <Button className={classes.marginRight} onClick={() => closeModal()}>
            {t('common.shared.cancel')}
          </Button>
          <Button
            color="success"
            className={classes.marginLeft}
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {t('common.shared.save')}
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  drivers: state.Drivers.drivers,
  selectedRegionForValidations: state.Brands.selectedRegionForValidations,
  useAddressesWithLessFields:
    state.Brands.modules?.ConfigClientPanel?.useAddressesWithLessFields ??
    false,
});

const mapDispatchToProps = dispatch => ({
  fetchUserAddresses: id => dispatch(fetchUserAddresses(id)),
});

const enhance = compose(
  withStyles(combinedStyles),
  withToast,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AddressForm);
