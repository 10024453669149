import React, { useState, useEffect } from 'react';
import AdminTable from '../../layouts/AdminTable';
import { put, get } from 'helpers/apiHelpers';
import { combineStyles, isGranted } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import navPillsStyle from '../../assets/jss/material-dashboard-pro-react/components/navPillsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import FormSelectSingle from '../../components/FormSelect/FormSelectSingle';
import { isDatepickerValidDay } from '../../helpers/dateHelpers';
import moment from 'moment';
import Datetime from 'react-datetime';
import { SelectContainer, TableContainer } from './RoutePlanner.styles';
import { DragDropContext } from 'react-beautiful-dnd';
import Table from './Table';
import { DialogLoader } from '../../components/DialogLoader';
import { withToast } from 'material-ui-toast-redux';
import axios from '../../helpers/gastro';
import {
  ROLE_EDIT_LOGISTIC_ROUTES,
  ROLE_SHOW_REPORT_FOR_DRIVER,
} from 'helpers/roles';
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";

const sortRoutes = (array, filter) => {
  const tables = {
    unsorted: {
      id: 'unsorted',
      routes: [],
    },
    sorted: {
      id: 'sorted',
      routes: [],
    },
  };

  let temporarySorted = [];

  array.forEach((e, idx, arr) =>
    filter(e, idx, arr)
      ? temporarySorted.push(e)
      : tables['unsorted'].routes.push(e.id.toString())
  );

  temporarySorted.sort((a, b) => a.priority - b.priority);
  temporarySorted.forEach(route =>
    tables['sorted'].routes.push(route.id.toString())
  );

  return tables;
};

const tableOrder = ['unsorted', 'sorted'];

const RoutePlanner = ({ classes, t, openToast }) => {
  const [drivers, setDrivers] = useState(null);
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDriverRoutes, setSelectedDriverRoutes] = useState(null);
  const [tables, setTables] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deliveryTypes, setDeliveryTypes] = useState(null);
  const [selectedDeliveryTypeId, setSelectedDeliveryTypeId] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await get(`drivers?pagination=false`);
      setDrivers(response['hydra:member']);

      const res = await get('/delivery-types', { pagination: false });
      setDeliveryTypes(res['hydra:member']);
    })();
  }, []);

  useEffect(() => {
    if (selectedDriverId && selectedDate) {
      (async () => {
        setIsLoading(true);
        const response = await get(
          `route-points/${selectedDriverId}/${selectedDate}?deliveryTypeId=${selectedDeliveryTypeId}`
        );

        const newSelectedDriverRoutes = {};
        response['hydra:member'].forEach(
          route => (newSelectedDriverRoutes[route.id.toString()] = route)
        );
        setSelectedDriverRoutes(newSelectedDriverRoutes);

        const newTables = sortRoutes(
          response['hydra:member'],
          e => e.priority !== null
        );
        setTables(newTables);

        setIsLoading(false);
      })();
    }
  }, [selectedDriverId, selectedDate, selectedDeliveryTypeId]);

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = tables[source.droppableId];
    const finish = tables[destination.droppableId];

    if (start === finish) {
      const newRouteIds = Array.from(start.routes);
      newRouteIds.splice(source.index, 1);
      newRouteIds.splice(destination.index, 0, draggableId);

      const newTable = {
        ...start,
        routes: newRouteIds,
      };

      const newTables = {
        ...tables,
        [newTable.id]: newTable,
      };

      setTables(newTables);
    } else {
      const startRouteIds = Array.from(start.routes);
      startRouteIds.splice(source.index, 1);
      const newStart = {
        ...start,
        routes: startRouteIds,
      };

      const finishRouteIds = Array.from(finish.routes);
      finishRouteIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        routes: finishRouteIds,
      };

      const newTables = {
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      };

      setTables(newTables);
    }
  };

  const saveRoutes = async () => {
    setIsSubmitting(true);

    let sortedRoutes = tables.sorted.routes.map(routeId => {
      return selectedDriverRoutes[routeId];
    });

    sortedRoutes = sortedRoutes.map((route, index) => {
      return { ...route, priority: index + 1 };
    });

    const unsortedRoutes = tables.unsorted.routes.map(routeId => {
      return { ...selectedDriverRoutes[routeId], priority: null };
    });

    try {
      await put('/route-points/update', {
        routePoints: unsortedRoutes.concat(sortedRoutes),
      });

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (e) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const generatePDF = (fileType) => {
    const params = {
      date: selectedDate,
      drivers: [`/drivers/${selectedDriverId}`],
      zones: [],
      pagination: false,
    };

    const title = t('common.mainMenu.reportsForDriver', 'Dla kierowcy');

    const fileName = `${title.replaceAll(' ', '_')}-${selectedDate}.${fileType}`;

    axios
      .get('reports/delivery', {
        responseType: 'blob',
        params: params,
        headers: {
          accept: fileType === 'xlsx'
            ? 'application/vnd.ms-excel'
            : 'application/pdf',
        },
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        },
        error => {
          this.props.openToast({
            messages: [
              this.props.t('reports.cannotGenerate'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      );
  };

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />
      <DialogLoader loaderState={isLoading} text={t('common.loading')} />

      <AdminTable title={t('routePlanner.header')} icon>
        {drivers && deliveryTypes && (
          <SelectContainer>
            <div>
              <p>{t('routePlanner.selectDriver')}</p>
              <FormSelectSingle
                classes={classes}
                mapBy="name"
                trackBy="id"
                value={selectedDriverId}
                options={drivers}
                handleChange={e => setSelectedDriverId(e.target.value)}
                name="drivers"
                id="drivers"
              />
            </div>

            <div>
              <p>{t('reports.selectDeliveryType')}</p>
              <FormSelectSingle
                classes={classes}
                mapBy="systemValue"
                trackBy="id"
                value={selectedDeliveryTypeId}
                options={deliveryTypes}
                handleChange={e => setSelectedDeliveryTypeId(e.target.value)}
                name="deliveryType"
                id="deliveryType"
              />
            </div>

            <div>
              <p>{t('common.shared.selectDate')}</p>
              <Datetime
                isValidDate={isDatepickerValidDay}
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={selectedDate}
                onChange={e => setSelectedDate(e.format('YYYY-MM-DD'))}
                inputProps={{
                  readOnly: true,
                }}
              />
            </div>
          </SelectContainer>
        )}
      </AdminTable>

      {tables && !isLoading && (
        <DragDropContext onDragEnd={onDragEnd}>
          <TableContainer>
            {tableOrder.map(tableId => {
              const table = tables[tableId];
              const routes = table.routes.map(
                routeId => selectedDriverRoutes[routeId]
              );

              return (
                <Table
                  key={table.id}
                  table={table}
                  routes={routes}
                  isDragDisabled={!isGranted(ROLE_EDIT_LOGISTIC_ROUTES)}
                />
              );
            })}
          </TableContainer>
        </DragDropContext>
      )}
      {tables && !isLoading && (
        <GridContainer justify="flex-end">
          <GridItem>
            <Button
              onClick={() => generatePDF('pdf')}
              disabled={!isGranted(ROLE_SHOW_REPORT_FOR_DRIVER)}
              color={'success'}
              round
            >
              {t('common.shared.generatePDF')}
            </Button>
          </GridItem>
          <GridItem>
            <Button
              onClick={() => generatePDF('xlsx')}
              disabled={!isGranted(ROLE_SHOW_REPORT_FOR_DRIVER)}
              color={'success'}
              round
            >
              {t('common.shared.generateXSLX')}
            </Button>
          </GridItem>
          <GridItem>
            <Button
              onClick={saveRoutes}
              disabled={!isGranted(ROLE_EDIT_LOGISTIC_ROUTES)}
              color={'success'}
              round
            >
              {t('zones.save')}
            </Button>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  navPillsStyle
);

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  })),
  withToast
);

export default enhance(RoutePlanner);
