import React, { Fragment } from 'react';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import RegularButton from 'components/CustomButtons/Button.jsx';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import CustomInput from '../../components/CustomInput/CustomInput';
import { get, put } from '../../helpers/apiHelpers';
import {
  combineStyles,
  getNumberFromString,
  isGranted,
} from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import withStyles from '@material-ui/core/styles/withStyles';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import FormLabel from '@material-ui/core/FormLabel';
import SweetAlert from 'react-bootstrap-sweetalert';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import roles from '../../helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

require('prismjs/components/prism-clike');
require('prismjs/components/prism-javascript');
require('prismjs/components/prism-twig');
require('prismjs/components/prism-markup');
require('prismjs/components/prism-jsx');

const mailingOptions = t => ({
  diet_reminder: t(
    'mailNotification.mailingOptions.dietReminder',
    'Informacja o diecie'
  ),
  new_account: t(
    'mailNotification.mailingOptions.newAccount',
    'Założenie konta'
  ),
  new_order: t(
    'mailNotification.mailingOptions.newOrder',
    'Złożenie zamówienia na nową dietę przez klienta'
  ),
  change_with_surcharge: t(
    'mailNotification.mailingOptions.changeWithSurcharge',
    'Złożenie zamówienia na "dodatek" do diety z dopłatą'
  ),
  change_with_refund: t(
    'mailNotification.mailingOptions.changeWithRefund',
    'Złożenie zamówienia na "dodatek" do diety ze zwrotem punktów dla klienta'
  ),
  order_status_change: t(
    'mailNotification.mailingOptions.orderStatusChange',
    'Zmiana statusu zamówienia'
  ),
  remove_account: t(
    'mailNotification.mailingOptions.removeAccount',
    'Usunięcie konta'
  ),
  lost_password_admin: t(
    'mailNotification.mailingOptions.lostPasswordAdmin',
    'Zapomniane hasło (administracja)'
  ),
  lost_password: t(
    'mailNotification.mailingOptions.lostPassword',
    'Zapomniane hasło (klient)'
  ),
  assign_zone_to_addresses_started: t(
    'mailNotification.mailingOptions.assignZoneToAddressStarted',
    'Rozpoczęcie przypisywania stref'
  ),
  assign_zone_to_addresses_finished: t(
    'mailNotification.mailingOptions.assignZoneToAddressFinished',
    'Zakończenie przypisywania stref'
  ),
  subscription_renewed_order_successful: t(
    'mailNotification.mailingOptions.subscriptionRenewedOrderSuccessfull',
    'Odnowienie subskrypcji'
  ),
  subscription_renewed_order_failed: t(
    'mailNotification.mailingOptions.subscriptionRenewedOrderFailed',
    'Nie udało się odnowić subskrypcji'
  ),
  change_delivery: t(
    'mailNotification.mailingOptions.changeDelivery',
    'Edycja diety'
  ),
  change_bag_dishes: t(
    'mailNotification.mailingOptions.changeDish',
    'Edycja posiłku'
  ),
  order_verified: t(
    'mailNotification.mailingOptions.orderVerified',
    'Weryfikacja zamówienia'
  ),
});

class Notifications extends React.Component {
  state = {
    mailing: [],
    confirmModal: null,
    defaultConfigFromBrand: null,
  };

  componentDidMount() {
    this.fetchMailConfigurations();
    get(this.props.user.company, {
      properties: ['defaultConfigFromBrand'],
    }).then(res =>
      this.setState({
        defaultConfigFromBrand: res.defaultConfigFromBrand,
      })
    );
  }

  fetchMailConfigurations = () => {
    get('mail-configurations').then(
      res => {
        const configurations = res['hydra:member'].map(configuration => ({
          ...configuration,
          hasChanged: false,
          isHidden: true,
          contentType:
            configuration.managedBySystem ||
            (configuration.enabledForOffice && !configuration.enabledForClient)
              ? 'office'
              : 'client',
        }));
        this.setState({
          mailing: configurations,
        });
      },
      error =>
        this.props.openToast({
          messages: [this.props.t('notify.cantGetCfg')],
          type: 'error',
          autoHideDuration: 3000,
        })
    );
  };

  renderAlert = (onConfirm, onCancel) => {
    const { classes } = this.props;

    const confirmModal = (
      <SweetAlert
        warning
        title={this.props.t('notify.resetContent')}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={this.props.t('notify.yes')}
        cancelBtnText={this.props.t('notify.no')}
        showCancel
      />
    );

    this.setState({ confirmModal });
  };

  render() {
    const { classes, t } = this.props;

    if (this.state.mailing.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <Card>
        <CardBody>
          {this.state.confirmModal}
          <div style={{ overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{this.props.t('notify.whatMail')}</TableCell>
                  <TableCell>{this.props.t('notify.client')}</TableCell>
                  <TableCell>{this.props.t('notify.admin')}</TableCell>
                  <TableCell>{this.props.t('notify.content')}</TableCell>
                  {isGranted(roles.ROLE_EDIT_MAIL_CONFIGURATION) && (
                    <TableCell>{this.props.t('notify.actions')}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.mailing.map(mailingOption => {
                  const selectedBrandDoesNotMatchDefaultFromConfig =
                    this.state.defaultConfigFromBrand &&
                    this.props.brand !==
                      parseInt(
                        getNumberFromString(
                          this.state.defaultConfigFromBrand['@id']
                        )
                      );

                  if (
                    mailingOption.mailKey === 'lost_password_admin' &&
                    selectedBrandDoesNotMatchDefaultFromConfig
                  ) {
                    return;
                  }
                  return (
                    <TableRow>
                      <TableCell>
                        {`${
                          mailingOptions(t)[mailingOption.mailKey] ||
                          mailingOption.mailKey
                        }`}
                        <div>
                          <b>
                            {mailingOption.managedBySystem
                              ? t(
                                  'mailNotifications.systemNotification',
                                  'Powiadomienie systemowe'
                                )
                              : ''}
                          </b>
                        </div>
                      </TableCell>
                      <TableCell>
                        {!mailingOption.managedBySystem ? (
                          <Checkbox
                            checked={
                              mailingOption.managedBySystem
                                ? false
                                : mailingOption.enabledForClient
                            }
                            onChange={e => {
                              if (mailingOption.managedBySystem) {
                                return this.props.openToast({
                                  messages: [
                                    t(
                                      'mailNotifications.thisIsASystemNotification',
                                      'To powiadomienie, jest powiadomieniem systemowym.'
                                    ),
                                    t(
                                      'mailNotifications.systemNotificationAvailableOnlyForAdministratrs',
                                      'Powiadomienia systemowe są dostępne tylko dla administracji.'
                                    ),
                                  ],
                                  type: 'warning',
                                  autoHideDuration: 5000,
                                });
                              }

                              const newMailing = [...this.state.mailing];

                              const optionToChange = newMailing.find(
                                newMailingOption =>
                                  newMailingOption.id === mailingOption.id
                              );

                              optionToChange.enabledForClient = !mailingOption.enabledForClient;
                              optionToChange.hasChanged = true;

                              this.setState({
                                mailing: newMailing,
                              });
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={
                            mailingOption.managedBySystem
                              ? true
                              : mailingOption.enabledForOffice
                          }
                          onChange={e => {
                            if (mailingOption.managedBySystem) {
                              return this.props.openToast({
                                messages: [
                                  t(
                                    'mailNotifications.thisIsASystemNotification',
                                    'To powiadomienie, jest powiadomieniem systemowym.'
                                  ),
                                  t(
                                    'mailNotifications.cannotDisabeSystemNotification',
                                    'Nie możesz wyłączyć powiadomienia systemowego.'
                                  ),
                                ],
                                type: 'warning',
                                autoHideDuration: 5000,
                              });
                            }

                            const newMailing = [...this.state.mailing];

                            const optionToChange = newMailing.find(
                              newMailingOption =>
                                newMailingOption.id === mailingOption.id
                            );

                            optionToChange.enabledForOffice = !mailingOption.enabledForOffice;
                            optionToChange.hasChanged = true;

                            this.setState({
                              mailing: newMailing,
                            });
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <div>
                          <span
                            onClick={() =>
                              mailingOption.managedBySystem &&
                              this.props.openToast({
                                messages: [
                                  t(
                                    'mailNotifications.thisIsASystemNotification',
                                    'To powiadomienie, jest powiadomieniem systemowym.'
                                  ),
                                  t(
                                    'mailNotifications.systemNotificationAvailableOnlyForAdministratrs',
                                    'Powiadomienia systemowe są dostępne tylko dla administracji.'
                                  ),
                                ],
                                type: 'warning',
                                autoHideDuration: 5000,
                              })
                            }
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  disabled={mailingOption.managedBySystem}
                                  checked={
                                    mailingOption.contentType === 'client'
                                  }
                                  onChange={() => {
                                    const newMailing = [...this.state.mailing];
                                    const optionToChange = newMailing.find(
                                      newMailingOption =>
                                        newMailingOption.id === mailingOption.id
                                    );
                                    optionToChange.contentType = 'client';

                                    this.setState({
                                      mailing: newMailing,
                                    });
                                  }}
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <FiberManualRecord
                                      className={classes.radioChecked}
                                    />
                                  }
                                  classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                              label={t(
                                'mailNotifications.forClientLabel',
                                'Dla klienta'
                              )}
                            />
                          </span>
                          <FormControlLabel
                            control={
                              <Radio
                                disabled={false}
                                checked={mailingOption.contentType === 'office'}
                                onChange={() => {
                                  const newMailing = [...this.state.mailing];
                                  const optionToChange = newMailing.find(
                                    newMailingOption =>
                                      newMailingOption.id === mailingOption.id
                                  );
                                  optionToChange.contentType = 'office';

                                  this.setState({
                                    mailing: newMailing,
                                  });
                                }}
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classes.label,
                            }}
                            label={t(
                              'mailNotifications.forAdministators',
                              'Dla administracji'
                            )}
                          />
                        </div>
                        {mailingOption.contentType === 'client' ? (
                          <Fragment>
                            <FormLabel
                              htmlFor="titleForClient"
                              className={classes.labelHorizontal}
                            >
                              {t(
                                'mailNotifications.titleForClient',
                                'Tytuł dla klienta'
                              )}
                            </FormLabel>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                name: 'titleForClient',
                                value: mailingOption.titleForClient,
                                onChange: ev => {
                                  const newMailing = [...this.state.mailing];
                                  const optionToChange = newMailing.find(
                                    newMailingOption =>
                                      newMailingOption.id === mailingOption.id
                                  );
                                  optionToChange.titleForClient =
                                    ev.target.value;
                                  optionToChange.hasChanged = true;

                                  this.setState({
                                    mailing: newMailing,
                                  });
                                },
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '10px 0 5px 0',
                                flexWrap: 'wrap',
                              }}
                            >
                              <FormLabel className={classes.labelHorizontal}>
                                {t(
                                  'mailNotifications.contentForClient',
                                  'Treść dla klienta'
                                )}
                              </FormLabel>
                              <div>
                                <Button
                                  onClick={() => {
                                    const newMailing = [...this.state.mailing];
                                    const optionToChange = newMailing.find(
                                      newMailingOption =>
                                        newMailingOption.id === mailingOption.id
                                    );
                                    optionToChange.isHidden = !mailingOption.isHidden;

                                    return this.setState({
                                      mailing: newMailing,
                                    });
                                  }}
                                >
                                  {mailingOption.isHidden
                                    ? t(
                                        'mailNotifications.showCode',
                                        'Pokaż kod'
                                      )
                                    : t(
                                        'mailNotifications.hideCode',
                                        'Ukryj kod'
                                      )}
                                </Button>
                                <Button
                                  onClick={() => {
                                    const win = window.open(
                                      '',
                                      t(
                                        'mailNotifications.clientPreview',
                                        'Podgląd Klient'
                                      ),
                                      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
                                    );
                                    win.document.write(
                                      mailingOption.templateForClient
                                    );
                                  }}
                                >
                                  {t(
                                    'menuPlanner.action.template.use.preview',
                                    'Podgląd'
                                  )}
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.renderAlert(
                                      () => {
                                        const newMailing = [
                                          ...this.state.mailing,
                                        ];
                                        const optionToChange = newMailing.find(
                                          newMailingOption =>
                                            newMailingOption.id ===
                                            mailingOption.id
                                        );
                                        optionToChange.templateForClient =
                                          optionToChange.defaultTemplateForClient;
                                        optionToChange.hasChanged = true;

                                        this.setState({ confirmModal: null });
                                        return this.setState({
                                          mailing: newMailing,
                                        });
                                      },
                                      () =>
                                        this.setState({ confirmModal: null })
                                    );
                                  }}
                                >
                                  {t(
                                    'common.restoreDefaultFem',
                                    'Przywróć domyślną'
                                  )}
                                </Button>
                              </div>
                            </div>
                            {!mailingOption.isHidden && (
                              <Editor
                                value={mailingOption.templateForClient}
                                onValueChange={code => {
                                  const newMailing = [...this.state.mailing];
                                  const optionToChange = newMailing.find(
                                    newMailingOption =>
                                      newMailingOption.id === mailingOption.id
                                  );
                                  optionToChange.templateForClient = code;
                                  optionToChange.hasChanged = true;

                                  this.setState({
                                    mailing: newMailing,
                                  });
                                }}
                                highlight={code =>
                                  highlight(code, languages.jsx)
                                }
                                padding={10}
                                style={{
                                  fontFamily:
                                    '"Fira code", "Fira Mono", monospace',
                                  fontSize: 12,
                                }}
                              />
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <FormLabel
                              htmlFor="titleForOffice"
                              className={classes.labelHorizontal}
                            >
                              {t(
                                'mailNotifications.titleForAdministration',
                                'Tytuł dla administracji'
                              )}
                            </FormLabel>
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                name: 'titleForOffice',
                                value: mailingOption.titleForOffice,
                                onChange: ev => {
                                  const newMailing = [...this.state.mailing];
                                  const optionToChange = newMailing.find(
                                    newMailingOption =>
                                      newMailingOption.id === mailingOption.id
                                  );
                                  optionToChange.titleForOffice =
                                    ev.target.value;
                                  optionToChange.hasChanged = true;

                                  this.setState({
                                    mailing: newMailing,
                                  });
                                },
                              }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '10px 0 5px 0',
                              }}
                            >
                              <FormLabel className={classes.labelHorizontal}>
                                {t(
                                  'mailNotifications.contentFormAdministration',
                                  'Treść dla administracji'
                                )}
                              </FormLabel>
                              <div>
                                <Button
                                  onClick={() => {
                                    const newMailing = [...this.state.mailing];
                                    const optionToChange = newMailing.find(
                                      newMailingOption =>
                                        newMailingOption.id === mailingOption.id
                                    );
                                    optionToChange.isHidden = !mailingOption.isHidden;

                                    return this.setState({
                                      mailing: newMailing,
                                    });
                                  }}
                                >
                                  {mailingOption.isHidden
                                    ? t(
                                        'mailNotifications.showCode',
                                        'Pokaż kod'
                                      )
                                    : t(
                                        'mailNotifications.hideCode',
                                        'Ukryj kod'
                                      )}
                                </Button>
                                <Button
                                  onClick={() => {
                                    const win = window.open(
                                      '',
                                      'Podgląd Administracja',
                                      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
                                    );
                                    win.document.write(
                                      mailingOption.templateForOffice
                                    );
                                  }}
                                >
                                  {t(
                                    'menuPlanner.action.template.use.preview',
                                    'Podgląd'
                                  )}
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.renderAlert(
                                      () => {
                                        const newMailing = [
                                          ...this.state.mailing,
                                        ];
                                        const optionToChange = newMailing.find(
                                          newMailingOption =>
                                            newMailingOption.id ===
                                            mailingOption.id
                                        );
                                        optionToChange.templateForOffice =
                                          optionToChange.defaultTemplateForOffice;
                                        optionToChange.hasChanged = true;

                                        this.setState({ confirmModal: null });
                                        return this.setState({
                                          mailing: newMailing,
                                        });
                                      },
                                      () =>
                                        this.setState({ confirmModal: null })
                                    );
                                  }}
                                >
                                  {t(
                                    'common.restoreDefaultFem',
                                    'Przywróć domyślną'
                                  )}
                                </Button>
                              </div>
                            </div>
                            {!mailingOption.isHidden && (
                              <Editor
                                value={mailingOption.templateForOffice}
                                onValueChange={code => {
                                  const newMailing = [...this.state.mailing];
                                  const optionToChange = newMailing.find(
                                    newMailingOption =>
                                      newMailingOption.id === mailingOption.id
                                  );
                                  optionToChange.templateForOffice = code;
                                  optionToChange.hasChanged = true;

                                  this.setState({
                                    mailing: newMailing,
                                  });
                                }}
                                highlight={code =>
                                  highlight(code, languages.jsx)
                                }
                                padding={10}
                                style={{
                                  fontFamily:
                                    '"Fira code", "Fira Mono", monospace',
                                  fontSize: 12,
                                }}
                              />
                            )}
                          </Fragment>
                        )}
                      </TableCell>
                      {isGranted(roles.ROLE_EDIT_MAIL_CONFIGURATION) && (
                        <TableCell>
                          <RegularButton
                            disabled={!mailingOption.hasChanged}
                            color={'success'}
                            onClick={() => {
                              put(mailingOption['@id'], mailingOption).then(
                                res => {
                                  const newMailing = [...this.state.mailing];

                                  let optionToChange = newMailing.find(
                                    newMailingOption =>
                                      newMailingOption.id === mailingOption.id
                                  );
                                  optionToChange.hasChanged = false;

                                  this.setState({
                                    mailing: newMailing,
                                  });
                                  this.props.openToast({
                                    messages: [
                                      this.props.t(
                                        'notify.changesSaved',
                                        'Zmiany zostały zapisane'
                                      ),
                                    ],
                                    type: 'success',
                                    autoHideDuration: 3000,
                                  });
                                },
                                error =>
                                  this.props.openToast({
                                    messages: [
                                      this.props.t(
                                        'notify.cannotSave',
                                        'Nie udało się zapisać zmian'
                                      ),
                                    ],
                                    type: 'error',
                                    autoHideDuration: 3000,
                                  })
                              );
                            }}
                          >
                            {t('common.shared.save', 'Zapisz')}
                          </RegularButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
    user: state.Auth.user,
  };
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps, null)
);

export default enhance(Notifications);
