import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AdminTable from '../../layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import PropTypes from 'prop-types';
import { get } from 'helpers/apiHelpers';
import Moment from 'moment';
import Paper from '@material-ui/core/es/Paper/Paper';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Storage } from '@material-ui/icons';

const DisplayValue = ({ obj: { '@LogType': type, ...args } }) => {
  if (type === 'PURE') {
    return <span>{args.value}</span>;
  }
  if (type === 'ENTITY') {
    if (typeof args.labels !== 'undefined') {
      return <span>{args.labels.objectName || args.value}</span>;
    } else {
      return <span>{args.value}</span>;
    }
  }
  if (type === 'COLLECTION') {
    if (args.value.hasOwnProperty('length')) {
      return args.value.map((log, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && ','} <DisplayValue obj={log} />
          </React.Fragment>
        );
      });
    } else {
      const keys = Object.keys(args.value);

      return keys.map((key, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && ','} {key}: <DisplayValue obj={args.value[key]} />
          </React.Fragment>
        );
      });
    }
  }

  return <span></span>;
};

const ActorInfo = ({ loggedAt, actorRawInfo, impersonator }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {Moment(loggedAt).format('llll ')}

      {impersonator && (
        <>
          {impersonator.firstName} {impersonator.lastName} ({impersonator.email}
          ){' '}
          {t('logView.actorInfo.loggedIn', 'Zalogowany na koncie użytkownika')}{' '}
          {actorRawInfo}
        </>
      )}
      {!impersonator && actorRawInfo}
    </React.Fragment>
  );
};

const InsertBlock = ({
  actorRawInfo,
  changes,
  loggedAt,
  objectName,
  impersonatedBy,
  labels,
  objectId,
  snapshot,
}) => {
  const changedFields = Object.keys(changes).filter(
    field => !['currentObjectInfo'].includes(field)
  );
  const { t } = useTranslation();

  const title = t('logView.insertBlock.title', {
    defaultValue: `Utworzenie rekordu o ID: {{id}} "{{changes}}"`,
    replace: {
      changes:
        labels._objectName ||
        (
          changes.currentObjectInfo || {
            currentObjectInfo: { value: objectName },
          }
        ).value ||
        '',
      id: objectId,
    },
  });
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <AddCircleIcon />
      </ListItemIcon>
      <ListItemText
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">
              <ActorInfo
                actorRawInfo={actorRawInfo}
                loggedAt={loggedAt}
                impersonator={impersonatedBy}
              />
            </Typography>
            <Table style={{ width: 'initial' }}>
              <TableBody>
                <TableRow>
                  <TableCell className={'head'}>
                    {t('form.fieldName', 'Pole')}
                  </TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>{labels[field] ?? field}</TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>{t('form.value', 'Wartość')}</TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>
                        <DisplayValue obj={changes[field].to} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </React.Fragment>
        }
      >
        <span>
          {title}
          {!Array.isArray(snapshot) && (
            <Tooltip
              placement="top"
              title={<pre>{JSON.stringify(snapshot, null, 2)}</pre>}
            >
              <Storage style={{ verticalAlign: 'middle' }} />
            </Tooltip>
          )}
        </span>
      </ListItemText>
    </ListItem>
  );
};

const UpdateBlock = ({
  actorRawInfo,
  changes,
  loggedAt,
  objectName,
  impersonatedBy,
  labels,
  objectId,
  snapshot,
}) => {
  const { t } = useTranslation();
  const changedFields = Object.keys(changes).filter(
    field => !['currentObjectInfo'].includes(field)
  );
  const title = t('logView.updateBlock.title', {
    defaultValue: `Modyfikacja rekordu o ID: {{id}}  {{changes}}`,
    replace: {
      changes:
        labels._objectName ||
        (
          changes.currentObjectInfo || {
            currentObjectInfo: { value: objectName },
          }
        ).value ||
        '',
      id: objectId,
    },
  });

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <CreateIcon />
      </ListItemIcon>
      <ListItemText
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">
              <ActorInfo
                actorRawInfo={actorRawInfo}
                loggedAt={loggedAt}
                impersonator={impersonatedBy}
              />
            </Typography>
            <Table style={{ width: 'initial' }}>
              <TableBody>
                <TableRow>
                  <TableCell className={'head'}>
                    {t('form.fieldName', 'Pole')}
                  </TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>{labels[field] ?? field}</TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('form.valueBefore', 'Wartość przed')}
                  </TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>
                        <DisplayValue obj={changes[field].from} />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>{t('form.valueAfter', 'Wartość po')}</TableCell>
                  {changedFields.map((field, key) => {
                    return (
                      <TableCell key={key}>
                        <DisplayValue obj={changes[field].to} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </React.Fragment>
        }
      >
        <span>
          {title}
          {!Array.isArray(snapshot) && (
            <Tooltip
              placement="top"
              title={<pre>{JSON.stringify(snapshot, null, 2)}</pre>}
            >
              <Storage style={{ verticalAlign: 'middle' }} />
            </Tooltip>
          )}
        </span>
      </ListItemText>
    </ListItem>
  );
};

const RemoveBlock = ({
  actorRawInfo,
  changes,
  loggedAt,
  objectName,
  impersonatedBy,
  labels,
  objectId,
  snapshot,
}) => {
  const { t } = useTranslation();
  const title = t('logView.removeBlock.title', {
    defaultValue: `Skasowanie rekordu o ID: {{id}} {{changes}}`,
    replace: {
      changes:
        labels._objectName ||
        (
          changes.currentObjectInfo || {
            currentObjectInfo: { value: objectName },
          }
        ).value ||
        '',
      id: objectId,
    },
  });

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <RemoveIcon />
      </ListItemIcon>
      <ListItemText
        secondary={
          <>
            <React.Fragment>
              <Typography component="span" variant="body2" color="textPrimary">
                <ActorInfo
                  actorRawInfo={actorRawInfo}
                  loggedAt={loggedAt}
                  impersonator={impersonatedBy}
                />
              </Typography>
            </React.Fragment>
          </>
        }
      >
        <span>
          {title}
          {!Array.isArray(snapshot) && (
            <Tooltip
              placement="top"
              title={<pre>{JSON.stringify(snapshot, null, 2)}</pre>}
            >
              <Storage style={{ verticalAlign: 'middle' }} />
            </Tooltip>
          )}
        </span>
      </ListItemText>
    </ListItem>
  );
};

const LogBlock = ({ historyRow }) => {
  const map = { INSERT: InsertBlock, UPDATE: UpdateBlock, REMOVE: RemoveBlock };

  if (map.hasOwnProperty(historyRow.actionType)) {
    const ViewComponent = map[historyRow.actionType];

    return (
      <Paper style={{ overflowX: 'auto', marginBottom: '14px' }}>
        {' '}
        <ViewComponent {...historyRow} />
      </Paper>
    );
  }

  return <div>{historyRow.actionType}</div>;
};

class LogView extends React.Component {
  initialState = {
    history: [],
    showHistory: this.props.historyOpenDefault,
    loading: true,
    updating: false,
    page: 1,
    itemsPerPage: 5,
    allLoaded: false,
  };
  state = this.initialState;

  componentDidMount() {
    this.props.historyOpenDefault
      ? get(this.props.iri, {
          showHistory: true,
          partial: true,
          itemsPerPage: this.state.itemsPerPage,
          page: this.state.page,
        }).then(res => {
          return this.setState({
            history: res['hydra:member'],
            loading: false,
            allLoaded: res['hydra:member'].length === 0,
          });
        })
      : this.setState({ loading: false });
  }

  toggleHistory = async () => {
    const { 'hydra:member': history } =
      this.state.history.length === 0 &&
      (await get(this.props.iri, {
        showHistory: true,
        partial: true,
        itemsPerPage: this.state.itemsPerPage,
        page: this.state.page,
      }));
    const showHistory = !this.state.showHistory;
    this.setState(prev => ({
      ...prev,
      history: history ? history : prev.history,
      showHistory,
      allLoaded: history ? history.length === 0 : false,
    }));
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.iri !== this.props.iri) {
      this.setState({ ...this.initialState, loading: false }, () =>
        this.toggleHistory()
      );
    }
  }

  updateHistory = loadAll => {
    this.setState({ updating: true });

    const action = loadAll
      ? get(this.props.iri, {
          showHistory: true,
          partial: false,
          pagination: false,
        })
      : get(this.props.iri, {
          showHistory: true,
          partial: true,
          itemsPerPage: this.state.itemsPerPage,
          page: this.state.page + 1,
        });

    action.then(res => {
      this.setState({
        page: this.state.page + 1,
        history: loadAll
          ? res['hydra:member']
          : [...this.state.history, ...res['hydra:member']],
        allLoaded: loadAll ? true : res['hydra:member'].length === 0,
        updating: false,
      });
    });
  };

  render() {
    const { classes } = this.props;
    return this.state.loading ? (
      <CircularProgress />
    ) : (
      <>
        {this.state.showHistory && this.props.hideButtonHidden !== true && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={this.toggleHistory}>
              {this.props.t('form.hideHistory')}
            </Button>
          </div>
        )}
        {!this.state.showHistory && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={this.toggleHistory}>
              {this.props.t('form.showHistory')}
            </Button>
          </div>
        )}
        {this.state.showHistory && (
          <AdminTable>
            <h3>{this.props.t('form.history')}</h3>
            <List className={classes.root}>
              {this.state.history
                .slice(0, this.state.historyOffset)
                .map((historyRow, index) => {
                  return <LogBlock historyRow={historyRow} key={index} />;
                })}
            </List>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                disabled={this.state.allLoaded}
                onClick={() => this.updateHistory(false)}
              >
                {this.state.updating ? (
                  <CircularProgress />
                ) : this.state.allLoaded ? (
                  `${this.props.t('form.loadedRecords')} (${
                    this.state.history.length
                  })`
                ) : (
                  `${this.props.t('form.loadNextRecords')} (${
                    this.state.itemsPerPage
                  })`
                )}
              </Button>
              {!this.state.allLoaded && !this.state.updating && (
                <Button onClick={() => this.updateHistory(true)}>
                  {this.props.t('form.loadAllData', 'Załaduj wszystkie')}
                </Button>
              )}
            </div>
          </AdminTable>
        )}
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

LogView.propTypes = {
  iri: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(combinedStyles)(LogView));
