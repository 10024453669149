import React from 'react';
import roles from 'helpers/roles';
import moment from 'moment';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CombinedReport from './CombinedReport/CombinedReport';

class ShoppingList extends React.Component {
  resolveFileName = ({ parameters }) => {
    const title = this.props
      .t('common.mainMenu.reportsShoppingList')
      .replace(' ', '-');

    if (parameters.length === 1) {
      const { multiplier, dateFrom, dateTo } = parameters[0];

      return `${multiplier}x${title}-${dateFrom}-${dateTo}_(${moment().format(
        'YYYY-MM-DD_HH.mm'
      )})`;
    }

    return `${title}_(${moment().format('YYYY-MM-DD_HH.mm')})`;
  };

  render() {
    const { t } = this.props;

    return (
      <CombinedReport
        title={t('reports.reportShoppingList')}
        url={'/reports/shopping'}
        mimeTypes={['application/vnd.ms-excel']}
        role={roles.ROLE_SHOW_REPORT_SHOPPING}
        reportConfiguration={{
          report: 'Shopping',
          fields: [
            {
              size: 12,
              field: 'reportType',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  'WEIGHT_SUMMARY',
                  'QUANTITY_SUMMARY',
                  'ALL_SUMMARY',
                ].map(key => ({
                  value: key,
                  label: t(`reports.reportShoppingList.reportType.${key}`),
                })),
              },
            },
            {
              size: 12,
              field: 'roundDecimalPoints',
              inputType: 'number',
            },
          ],
        }}
        fileName={this.resolveFileName}
        useDateRange={true}
        useZoneCategories={true}
        showMultiplier={true}
        validateBrands={false}
        validateDatePicker={true}
        archiveReportNames={['SHOPPING_LIST']}
      />
    );
  }
}

const enhance = compose(withTranslation());

export default enhance(ShoppingList);
