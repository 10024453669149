import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class BoxLabels extends React.Component {
  getFileName = title => ({ date }) => {
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${date}_(${moment().format('YYYY-MM-DD_HH.mm')})`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  render() {
    const { t } = this.props;
    const title = t('reports.reportBoxLabelsMini');
    return (
      <BaseBrandAndDateReport
        title={title}
        excelUrl="reports/bag-stickers-mini"
        mimeType="application/vnd.ms-excel"
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_BAG_STICKERS_MINI}
        excelAvaliable={true}
        archiveReportNames={['BAG_STICKERS_MINI']}
      />
    );
  }
}

export default withTranslation()(BoxLabels);
