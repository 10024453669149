import React from 'react';
import ListComponents from './ListComponents';
import StatusPicker from './StatusPicker';
import VerifyButton from './VerifyButton';
import PaymentNeedPayButton from './PaymentNeedPayButton';
import YesNoFilter from 'components/Grid/Filter/YesNo';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';
import { translateOrderStatuses } from './statusTranslations';
import { dateTimeCell } from 'components/Grid/Cells/DateTimeCell';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import { Tooltip } from '@material-ui/core';
import { currencyToSymbol } from 'utils/currencies';

export const paymentTypeOptions = [
  'paymentType.all',
  'paymentType.tPay',
  'paymentType.payPo',
  'paymentType.transfer',
  'paymentType.cash',
  'paymentType.payU',
  'paymentType.payUCard',
  'paymentType.stripeLink',
  'paymentType.stripeCard',
  'paymentType.blueMedia',
  'paymentType.blueMediaCard',
];

export const paymentTypesTranslations = {
  TPAY: 'paymentType.tPay',
  PAYPO: 'paymentType.payPo',
  BANK_WIRE: 'paymentType.transfer',
  CASH: 'paymentType.cash',
  PAYU: 'paymentType.payU',
  PAYU_CARD: 'paymentType.payUCard',
  STRIPE_LINK: 'paymentType.stripeLink',
  STRIPE_CARD: 'paymentType.stripeCard',
  BLUE_MEDIA: 'paymentType.blueMedia',
  BLUE_MEDIA_CARD: 'paymentType.blueMediaCard',
};

const columnConfig = (grid, fillModal, t) => {
  return [
    {
      title: 'ID',
      accessor: 'id',
      width: 100,
      filterable: true,
      sortable: true,
    },
    {
      title: 'orders.isTest',
      accessor: row => <BooleanView value={row.isTest} />,
      name: 'isTest',
      width: 75,
      Filter: ({ onChange, filter }) => {
        return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.isClientsFirstOrder',
      accessor: row => <BooleanView value={row.isClientsFirstOrder} />,
      name: 'isClientsFirstOrder',
      width: 75,
      Filter: ({ onChange, filter }) => {
        return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.verified',
      accessor: row => (
        <VerifyButton verified={row.verified} orderId={row['@id']} />
      ),
      name: 'verified',
      width: 75,
      Filter: ({ onChange, filter }) => {
        return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.paymentNeedVerification',
      accessor: row => (
        <PaymentNeedPayButton
          paymentNeedVerification={row.paymentNeedVerification}
          orderId={row['@id']}
        />
      ),
      name: 'paymentNeedVerification',
      width: 75,
      Filter: ({ onChange, filter }) => {
        return <YesNoFilter onChange={onChange} value={`${filter?.value}`} />;
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.fullname',
      accessor: row => {
        return (
          <a href={`/admin/clients/edit/${row?.client?.id}`}>
            {`${row.client.firstName} ${row.client.lastName}`}
          </a>
        );
      },
      name: 'client.firstName',
      filterable: true,
      sortable: true,
    },
    {
      title: 'addresses.city',
      accessor: row => {
        if (!row.client || !row.client.defaultAddress) {
          return '';
        }

        return row.client.defaultAddress.city;
      },
      name: 'client.defaultAddress.city',
      sortable: false,
    },
    {
      title: 'addresses.zone',
      accessor: row => {
        return row?.client?.defaultAddress?.zone?.name;
      },
      name: 'client.defaultAddress.zone.name',
      sortable: false,
    },
    {
      title: 'orders.orderDate',
      Filter: ({ onChange, filter }) => {
        return <DateRangeFilter onChange={onChange} value={filter?.value} />;
      },
      accessor: row => {
        return dateTimeCell(row.createdAt);
      },
      name: 'createdAt',
      filterable: true,
      sortable: true,
      width: 160,
    },
    {
      title: 'orders.items',
      accessor: row => {
        const v2types = [
          'OrderItemDayDish',
          'OrderItemDayAddon',
          'OrderItemDayDelivery',
          'OrderItemDayModification',
        ];

        const orderItemDiets = row.items.filter(
          el => el.type === 'OrderItemDiet'
        );
        const v2Items = row.items.filter(el => v2types.includes(el.type));

        if (orderItemDiets.length > 0 && v2Items?.length > 0) {
          const Diet = ListComponents['OrderItemDiet'];
          const V2Items = ListComponents['v2Types'];

          return (
            <>
              {orderItemDiets
                .map(el => (
                  <Diet orderId={row.id} item={el} fillModal={fillModal} />
                ))
                .reduce((prev, curr) => [prev, ', ', curr])}
              {', '}
              <V2Items items={v2Items} fillModal={fillModal} />
            </>
          );
        }

        if (v2Items?.length > 0) {
          const Component = ListComponents['v2Types'];

          return <Component items={v2Items} fillModal={fillModal} />;
        }

        if (orderItemDiets.length > 0) {
          const Component = ListComponents['OrderItemDiet'];
          return orderItemDiets
            .map(el => (
              <Component orderId={row.id} item={el} fillModal={fillModal} />
            ))
            .reduce((prev, curr) => [prev, ', ', curr]);
        }

        const diets = row.items.filter(el => el.type === 'DietItem');
        if (diets.length > 0) {
          const Component = ListComponents['DietItem'];

          return diets
            .map(el => (
              <Component orderId={row.id} item={el} fillModal={fillModal} />
            ))
            .reduce((prev, curr) => [prev, ', ', curr]);
        }

        if (row.items.length === 0) {
          return null;
        }

        const Component = ListComponents[row.items[0].type] || null;

        if (null === Component) {
          return `Unknown type "${row.items[0].type}"`;
        }

        return (
          <Component
            orderId={row.id}
            fillModal={fillModal}
            items={row.items}
            item={row.items[0]}
            userId={row.client.id}
          />
        );
      },
      name: 'items.diet.diet.name',
      width: 250,
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.value',
      accessor: row => row.priceAfterDiscountWithoutMoneyBox.toFixed(2),
      name: 'priceAfterDiscountWithoutMoneyBox',
      width: 120,
      Filter: ({ onChange, filter }) => {
        return (
          <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
        );
      },
      filterable: true,
      sortable: false,
    },
    {
      title: 'orders.pointsFromMoneyBox',
      accessor: 'pointsFromMoneyBox',
      width: 100,
      Filter: ({ onChange, filter }) => {
        return (
          <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
        );
      },
      filterable: true,
      sortable: true,
    },
    {
      name: 'discountCode.code',
      title: 'orders.discountCode',
      accessor: (row, plug) => {
        return row.discountCode !== null ? (
          <Tooltip
            title={
              <h4>
                {row.discountCode.discount}{' '}
                {!row.isPercentage
                  ? currencyToSymbol(plug.multinational.currency)
                  : '%'}
              </h4>
            }
            placement="top"
          >
            <span>{row.discountCode.code}</span>
          </Tooltip>
        ) : (
          '-'
        );
      },
      filterable: true,
      sortable: true,
    },
    {
      title: 'orders.status',
      accessor: row => {
        return (
          <StatusPicker
            value={row.status}
            disabled={row.status.systemValue === 'CANCELED'}
            orderId={row['@id']}
            grid={grid}
          />
        );
      },
      filterable: true,
      sortable: false,
      name: 'status.systemValue',
      width: 200,
      Filter: ({ onChange, filter }) => {
        const selectOptions = translateOrderStatuses(t);
        return (
          <select
            style={{ height: '30px', width: '100%', padding: '0' }}
            onChange={e => onChange(e.target.value)}
            value={filter?.value || ''}
          >
            <option value={''}>{selectOptions[0].label}</option>
            <option value={selectOptions[1].systemValue}>
              {selectOptions[1].label}
            </option>
            <option value={selectOptions[2].systemValue}>
              {selectOptions[2].label}
            </option>
            <option value={selectOptions[3].systemValue}>
              {selectOptions[3].label}
            </option>
          </select>
        );
      },
    },
    {
      title: 'orders.paymentType',
      accessor: row => t(paymentTypesTranslations[row.paymentType]),
      sortable: false,
      filterable: true,
      name: 'paymentType',
      Filter: ({ onChange, filter }) => {
        return (
          <select
            style={{ height: '30px', width: '100%', padding: '0' }}
            onChange={e => onChange(e.target.value)}
            value={filter?.value || ''}
          >
            <option value={''}>{t(paymentTypeOptions[0])}</option>
            <option value={'TPAY'}>{t(paymentTypeOptions[1])}</option>
            <option value={'PAYPO'}>{t(paymentTypeOptions[2])}</option>
            <option value={'BANK_WIRE'}>{t(paymentTypeOptions[3])}</option>
            <option value={'CASH'}>{t(paymentTypeOptions[4])}</option>
            <option value={'PAYU'}>{t(paymentTypeOptions[5])}</option>
            <option value={'PAYU_CARD'}>{t(paymentTypeOptions[6])}</option>
            <option value={'STRIPE_LINK'}>{t(paymentTypeOptions[7])}</option>
            <option value={'STRIPE_CARD'}>{t(paymentTypeOptions[8])}</option>=
            <option value={'BLUE_MEDIA'}>{t(paymentTypeOptions[9])}</option>
            <option value={'BLUE_MEDIA_CARD'}>
              {t(paymentTypeOptions[10])}
            </option>
          </select>
        );
      },
    },
  ];
};

export default columnConfig;
