import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class FoodCost extends React.Component {
  getFileName = title => props => {
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${props.date}_(${moment().format(
      'YYYY-MM-DD_HH.mm'
    )})`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  render() {
    const { t } = this.props;
    const title = t('reports.reportFoodCost');

    return (
      <BaseBrandAndDateReport
        title={title}
        excelUrl="reports/food-cost"
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_FOOD_COST}
        pdfAvaliable={false}
        excelAvaliable={true}
        archiveReportNames={['FOOD_COST']}
      />
    );
  }
}

export default withTranslation()(FoodCost);
