import { useState, useEffect } from 'react';

import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  fetchPaymentTypes,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import { Divider, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import FormLabel from '@material-ui/core/FormLabel';
import FormImageUpload from '../../components/FormImageUpload/FormImageUpload';
import ColorPicker from '../../components/BlockColorPicker/BlockColorPicker';
import { FontsModule } from './modules';
import Card from '../../components/Card/Card';
import React from 'react';
import { modulesNames } from './consts';
import Button from 'components/CustomButtons/Button.jsx';

import styled from 'styled-components';
import { put } from '../../helpers/apiHelpers';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import StatusIcons from './components/StatusIcons';
import { DialogLoader } from '../../components/DialogLoader';
import AvailableSoonOverlay from './components/availableSoonOverlay';

const Wrapper = styled.div`
  display: flex;
  gap: 40px;

  > div {
    max-width: 225px;
  }

  > div:last-child {
    button {
      margin-top: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ClientPanelDesign = ({
  classes,
  t,
  selectedBrand,
  openToast,
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
}) => {
  const [state, setState] = useState({
    newOrderInfoEnabled: false,
    newOrderInfoTitle: '',
    newOrderInfoDescription: '',
    newOrderInfoBannerDesktop: null,
    newOrderInfoBannerDesktopUrl: null,
    newOrderInfoBannerMobile: null,
    newOrderInfoBannerMobileUrl: null,
    mainColor: '#000000',
    secondaryColor: '#B58A78',
    backgroundColor: '#FAFBFC',
    backgroundImage: null,
    backgroundImageUrl: null,
    useImageAsBackground: false,
    logoMobile: null,
    logoDesktop: null,
    logoMail: null,
    logoMobileUrl: null,
    logoDesktopUrl: null,
    logoMailUrl: null,
    favicon: null,
    faviconUrl: null,
    customCSS: '',
  });
  const [modules, setModules] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isColor, setIsColor] = useState(true);
  // TODO: match variable names with ones from BE
  const [isWhiteBackground, setIsWhiteBackground] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      setState(prevState => ({
        ...prevState,
        newOrderInfoBannerDesktop:
          brandConfig.newOrderInfoBannerDesktop?.['@id'] || null,
        newOrderInfoBannerDesktopUrl:
          brandConfig.newOrderInfoBannerDesktop?.contentUrl || null,
        newOrderInfoBannerMobile:
          brandConfig.newOrderInfoBannerMobile?.['@id'] || null,
        newOrderInfoBannerMobileUrl:
          brandConfig.newOrderInfoBannerMobile?.contentUrl || null,
        newOrderInfoDescription: brandConfig.newOrderInfoDescription,
        newOrderInfoEnabled: brandConfig.newOrderInfoEnabled,
        newOrderInfoTitle: brandConfig.newOrderInfoTitle,
        mainColor:
          brandConfig.mainColor === null ? '#000000' : brandConfig.mainColor,
        secondaryColor:
          brandConfig.secondaryColor === null ? '#B58A78' : brandConfig.secondaryColor,
        backgroundColor: brandConfig.backgroundColor
          ? brandConfig.backgroundColor
          : state.backgroundColor,
        backgroundImage: brandConfig.backgroundImage?.['@id'] || null,
        backgroundImageUrl: brandConfig.backgroundImage?.contentUrl || null,
        useImageAsBackground: brandConfig.useImageAsBackground,
        logoMobile: brandConfig.logoMobile?.['@id'] || null,
        logoDesktop: brandConfig.logoDesktop?.['@id'] || null,
        logoMail: brandConfig.logoMail?.['@id'] || null,
        logoMobileUrl: brandConfig.logoMobile?.contentUrl || null,
        logoDesktopUrl: brandConfig.logoDesktop?.contentUrl || null,
        logoMailUrl: brandConfig.logoMail?.contentUrl || null,
        favicon: brandConfig.favicon?.['@id'] || null,
        faviconUrl: brandConfig.favicon?.contentUrl || null,
        customCSS: brandConfig.customCSS
      }));

      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleInputChange = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const getImage = (stateName, data) => {
    setState(prevState => ({
      ...prevState,
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    }));
  };

  const removeImage = stateName => {
    setState(prevState => ({
      ...prevState,
      [stateName]: null,
      [`${stateName}Url`]: null,
      ...(stateName === 'backgroundImage'
        ? { useImageAsBackground: false }
        : {}),
    }));
  };

  const handleToggle = variableName => {
    setState(prevState => ({
      ...prevState,
      [variableName]: !prevState[variableName],
    }));
  };

  const handleColorPicker = (color, stateName) => {
    setState(prevState => ({ ...prevState, [stateName]: color.hex }));
  };

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };

  const handleSubmit = async () => {
    const data = {
      newOrderInfoEnabled: state.newOrderInfoEnabled,
      newOrderInfoTitle: state.newOrderInfoTitle,
      newOrderInfoDescription: state.newOrderInfoDescription,
      newOrderInfoBannerDesktop: state.newOrderInfoBannerDesktop,
      newOrderInfoBannerDesktopUrl: state.newOrderInfoBannerDesktopUrl,
      newOrderInfoBannerMobile: state.newOrderInfoBannerMobile,
      newOrderInfoBannerMobileUrl: state.newOrderInfoBannerMobileUrl,
      mainColor: state.mainColor,
      secondaryColor: state.secondaryColor,
      backgroundColor: state.backgroundColor,
      backgroundImage: state.backgroundImage,
      backgroundImageUrl: state.backgroundImageUrl,
      useImageAsBackground: state.useImageAsBackground,
      logoMobile: state.logoMobile,
      logoDesktop: state.logoDesktop,
      logoMail: state.logoMail,
      favicon: state.favicon,
      customCSS: state.customCSS
    };

    try {
      const response = await put(`/brands/${selectedBrand}`, data);
      await updateBrandConfigModulesPack(response.id, modules);

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      openToast({
        messages: [
          t('errors.modulesCouldNotBeSaved', 'Nie udało się zapisać modułów'),
          error.message,
        ],
        type: 'error',
        autoHideDuration: 300,
      });
      // this.props.errorHandlerCatch(error);
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <h1>{t('brandCfg.view')}</h1>
                </GridItem>
                <GridItem md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => handleToggle('newOrderInfoEnabled')}
                        checked={state.newOrderInfoEnabled}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('brandCfg.infoBannerShow')}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      textAlign: 'center',
                    }}
                  >
                    <div>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ marginTop: '15px', marginBottom: '24px' }}
                      >
                        {t('brandCfg.bannerPanorama')}
                      </FormLabel>
                      <FormImageUpload
                        classes={classes}
                        stateName="newOrderInfoBannerDesktop"
                        buttonText={t('common.shared.select', 'Wybierz')}
                        getImage={getImage}
                        imgId="desktop-banner"
                        tooltipImageSize={t('brandCfg.widthMinPanorama')}
                        removeImage={removeImage}
                        previewUrl={state.newOrderInfoBannerDesktopUrl}
                      />
                    </div>
                    <div>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ marginTop: '15px', marginBottom: '24px' }}
                      >
                        {t('brandCfg.bannerPortrait')}
                      </FormLabel>
                      <FormImageUpload
                        classes={classes}
                        stateName="newOrderInfoBannerMobile"
                        buttonText={t('common.shared.select', 'Wybierz')}
                        getImage={getImage}
                        imgId="mobile-banner"
                        tooltipImageSize={t('brandCfg.widthPortrait')}
                        removeImage={removeImage}
                        previewUrl={state.newOrderInfoBannerMobileUrl}
                      />
                    </div>
                  </div>
                </GridItem>
                <GridItem md={6}>
                  <FormTextInput
                    multiline
                    rows={3}
                    rowsMax={10}
                    label={
                      <div style={{ display: 'flex' }}>
                        <span>{t('brandCfg.captionInBanner')}</span>
                        <div
                          style={{
                            marginLeft: '10px',
                            display: 'inline-block',
                          }}
                        >
                          <Tooltip
                            title={
                              <div>
                                <h4>{t('brandCfg.captionInBannerTooltip')}</h4>
                                <h4>
                                  {t('brandCfg.etc')}{' '}
                                  {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt nagłówka</div>`}
                                </h4>
                              </div>
                            }
                            placement="right"
                          >
                            <Info fontSize={'small'} />
                          </Tooltip>
                        </div>
                      </div>
                    }
                    classes={classes}
                    name="newOrderInfoTitle"
                    value={state.newOrderInfoTitle}
                    handleChange={handleInputChange}
                    inputSize={12}
                    maxLength={160}
                  />
                  <FormTextInput
                    label={
                      <div style={{ display: 'flex' }}>
                        <span>{t('brandCfg.textUnderCaption')}</span>
                        <div
                          style={{
                            marginLeft: '10px',
                            display: 'inline-block',
                          }}
                        >
                          <Tooltip
                            title={
                              <div>
                                <h4>{t('brandCfg.textUnderCaptionTooltip')}</h4>
                                <h4>
                                  {t('brandCfg.etc')}{' '}
                                  {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt pod nagłówkiem</div>`}
                                </h4>
                              </div>
                            }
                            placement="right"
                          >
                            <Info fontSize={'small'} />
                          </Tooltip>
                        </div>
                      </div>
                    }
                    classes={classes}
                    name="newOrderInfoDescription"
                    value={state.newOrderInfoDescription}
                    handleChange={handleInputChange}
                    inputSize={12}
                    maxLength={1000}
                    rows={3}
                    rowsMax={20}
                    multiline={true}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <GridItem sm={6} md={3}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px', marginBottom: '24px' }}
                  >
                    {t('brandCfg.logoMobile')}
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('brandCfg.logoMobileDescriptionTooltip')}</h4>
                          <h4>
                            {t('common.height')}
                            {' 60px; '}
                            {t('common.width')}
                            {' 160px'}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </FormLabel>
                  <div style={{ marginLeft: '-15px' }}>
                    <FormImageUpload
                      classes={classes}
                      stateName="logoMobile"
                      buttonText={t('brandCfg.selectLogo')}
                      getImage={getImage}
                      removeImage={removeImage}
                      previewUrl={state.logoMobileUrl}
                    />
                  </div>
                </GridItem>
                <GridItem sm={6} md={3}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px', marginBottom: '24px' }}
                  >
                    {t('brandCfg.logoDesktop')}
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('brandCfg.logoDesktopDescriptionTooltip')}</h4>
                          <h4>
                            {t('common.height')}
                            {' 70px; '}
                            {t('common.width')}
                            {' 160px'}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </FormLabel>
                  <div style={{ marginLeft: '-15px' }}>
                    <FormImageUpload
                      classes={classes}
                      stateName="logoDesktop"
                      buttonText={t('brandCfg.selectLogo')}
                      getImage={getImage}
                      removeImage={removeImage}
                      previewUrl={state.logoDesktopUrl}
                    />
                  </div>
                </GridItem>
                <GridItem sm={6} md={3}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px', marginBottom: '24px' }}
                  >
                    {t('brandCfg.logoMail')}
                    <Tooltip
                      title={
                        <div>
                          <h4>
                            {t('common.height')} {t('common.any')}
                            {'; '}
                            {t('common.width')}
                            {' 200px'}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </FormLabel>
                  <div style={{ marginLeft: '-15px' }}>
                    <FormImageUpload
                      classes={classes}
                      stateName="logoMail"
                      buttonText={t('brandCfg.selectLogo')}
                      getImage={getImage}
                      removeImage={removeImage}
                      previewUrl={state.logoMailUrl}
                    />
                  </div>
                </GridItem>
                <GridItem sm={6} md={3}>
                  {' '}
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px', marginBottom: '24px' }}
                  >
                    {t('brandCfg.iconTabBrowser')}
                  </FormLabel>
                  <FormImageUpload
                    classes={classes}
                    stateName="favicon"
                    buttonText={t('brandCfg.selectIcon')}
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={state.faviconUrl}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <GridItem sm={12}>
                  <FontsModule
                    classes={classes}
                    handleModuleUpdate={handleModuleUpdate}
                    module={modules[modulesNames.Fonts]}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <GridItem sm={12}>
                  <Wrapper>
                    <div>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ marginTop: '15px' }}
                      >
                        {t('brandCfg.baseColor')}
                      </FormLabel>
                      <div style={{ marginTop: '30px' }}>
                        <ColorPicker
                          color={state.mainColor}
                          handleChange={color =>
                            handleColorPicker(color, 'mainColor')
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ marginTop: '15px' }}
                      >
                        {t('brandCfg.secondaryColor')}
                      </FormLabel>
                      <div style={{ marginTop: '30px' }}>
                        <ColorPicker
                          color={state.secondaryColor}
                          handleChange={color =>
                            handleColorPicker(color, 'secondaryColor')
                          }
                        />
                      </div>
                    </div>
                    <div>
                      {isColor ? (
                        <div>
                          <FormLabel
                            className={classes.labelHorizontal}
                            style={{ marginTop: '15px' }}
                          >
                            {t('brandCfg.backgroundColor', 'Kolor tła')}
                          </FormLabel>
                          <div style={{ marginTop: '30px' }}>
                            <ColorPicker
                              color={state.backgroundColor}
                              handleChange={color =>
                                handleColorPicker(color, 'backgroundColor')
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            <FormLabel
                              className={classes.labelHorizontal}
                              style={{
                                marginTop: '15px',
                                marginBottom: '24px',
                              }}
                            >
                              {t('brandCfg.backgroundPic')}
                            </FormLabel>
                            <div style={{ marginLeft: '-15px' }}>
                              <FormImageUpload
                                classes={classes}
                                stateName="backgroundImage"
                                buttonText={t('brandCfg.selectBackground')}
                                getImage={getImage}
                                removeImage={removeImage}
                                previewUrl={state.backgroundImageUrl}
                              />
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              !state.backgroundImage &&
                              openToast({
                                messages: [
                                  'Aby użyć obrazka jako tło strony klienckiej, załaduj obrazek',
                                ],
                                type: 'warning',
                                autoHideDuration: 6000,
                              })
                            }
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={state.useImageAsBackground}
                                  onChange={e => {
                                    setState(prevState => ({
                                      ...prevState,
                                      useImageAsBackground: !state.useImageAsBackground,
                                    }));
                                  }}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  disabled={!state.backgroundImage}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              label={t('brandCfg.useImage')}
                            />
                          </div>
                        </div>
                      )}

                      <Button
                        color="info"
                        round={false}
                        fullWidth={true}
                        onClick={() => setIsColor(!isColor)}
                      >
                        {isColor
                          ? t(
                              'brandCfg.clientPanelDesign.changeToImage',
                              'Zmień na grafikę'
                            )
                          : t(
                              'brandCfg.clientPanelDesign.changeToColor',
                              'Zmień na kolor'
                            )}
                      </Button>
                    </div>

                    {/*<div>
                      <AvailableSoonOverlay>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isWhiteBackground"
                              onClick={() =>
                                setIsWhiteBackground(!isWhiteBackground)
                              }
                              checked={isWhiteBackground}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          label={t(
                            'brandCfg.clientPanelDesign.addWhiteBackgroundToOrderFormAndClientPanel',
                            'Dodaj białe tło dla poszczególnych sekcji formularza zamówienia i panelu klienta'
                          )}
                        />
                      </AvailableSoonOverlay>
                    </div>*/}
                  </Wrapper>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          {/* TODO: find what variable indicates that brand has subscription */}
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <StatusIcons
                    classes={classes}
                    handleModuleUpdate={handleModuleUpdate}
                    module={modules[modulesNames.ConfigClientPanel]}
                    hasSubscription={false}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={6}>
                  <h4>CSS</h4>
                  <Divider style={{ marginBottom: '20px' }} />
                  <FormTextInput
                    multiline
                    classes={classes}
                    name="customCSS"
                    value={state.customCSS}
                    maxLength={5000}
                    rows={10}
                    handleChange={handleInputChange}
                    inputSize={12}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <AvailableSoonOverlay>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem md={12}>
                    <h1>
                      {t(
                        'brandCfg.clientPanelDesign.dietDayDetailsPageConfig',
                        'Konfiguracja widoku szczegółów dnia diety'
                      )}
                    </h1>
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          name="checkbox1"
                          onClick={() => setCheckbox1(true)}
                          checked={checkbox1}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={t(
                        'brandCfg.clientPanelDesign.showInfoWithPhotoAboutDeliveredBag',
                        'Wyświetlaj w panelu klienta informację ze zdjęciem o dostarczonej torbie'
                      )}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </AvailableSoonOverlay>

          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <h1>
                    {t(
                      'brandCfg.clientPanelDesign.headerNavbarConfig',
                      'Konfiguracja nagłówka / paska nawigacji'
                    )}
                  </h1>
                </GridItem>
                <GridItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="allowClientSeeOwnId"
                        onClick={() =>
                          setModules(prevState => ({
                            ...prevState,
                            [modulesNames.ConfigClientPanel]: {
                              ...prevState[modulesNames.ConfigClientPanel],
                              allowClientSeeOwnId: !modules[
                                modulesNames.ConfigClientPanel
                              ].allowClientSeeOwnId,
                            },
                          }))
                        }
                        checked={
                          modules[modulesNames.ConfigClientPanel]
                            .allowClientSeeOwnId
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.clientPanelDesign.showIdOnNavbar',
                      'Włącz pokazywanie ID na pasku nawigacji'
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    {
      fetchBrand,
      fetchBrandConfigModulesPack,
      fetchPaymentTypes,
      updateBrandConfigModulesPack,
    }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(ClientPanelDesign);
