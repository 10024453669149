import roles from 'helpers/roles';

export const onSelect = {
  [roles.ROLE_EDIT_COST]: [
    roles.ROLE_SHOW_COST,
    roles.ROLE_REMOVE_COST,
    roles.ROLE_CREATE_COST,
  ],
  [roles.ROLE_SHOW_COST]: [roles.ROLE_SHOW_DIET, roles.ROLE_SHOW_VARIANT],
  [roles.ROLE_CREATE_COST]: [
    roles.ROLE_SHOW_COST,
    roles.ROLE_REMOVE_COST,
    roles.ROLE_EDIT_COST,
  ],
  [roles.ROLE_REMOVE_COST]: [
    roles.ROLE_SHOW_COST,
    roles.ROLE_CREATE_COST,
    roles.ROLE_EDIT_COST,
  ],
  [roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_SHOW_MONEY_BOX_CONFIGURATION,
    roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_SHOW_MONEY_BOX_CONFIGURATION,
    roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_SHOW_MONEY_BOX_CONFIGURATION,
    roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_SHOW_INGREDIENT]: [roles.ROLE_SHOW_DICTIONARY],
  [roles.ROLE_SHOW_DISH]: [roles.ROLE_SHOW_DICTIONARY],
  [roles.ROLE_SHOW_OVERPRODUCTION]: [
    roles.ROLE_SHOW_MENU_PLANNING,
    roles.ROLE_SHOW_MEAL_TYPE,
    roles.ROLE_SHOW_SIZE,
    roles.ROLE_SHOW_SUB_BRAND,
  ],
  [roles.ROLE_SHOW_REPORT_OVERPRODUCTION]: [roles.ROLE_SHOW_SUB_BRAND],
  [roles.ROLE_CREATE_OVERPRODUCTION]: [
    roles.ROLE_SHOW_OVERPRODUCTION,
    roles.ROLE_SHOW_MEAL_TYPE,
    roles.ROLE_SHOW_SIZE,
    roles.ROLE_SHOW_SUB_BRAND,
  ],
  [roles.ROLE_EDIT_OVERPRODUCTION]: [
    roles.ROLE_SHOW_OVERPRODUCTION,
    roles.ROLE_SHOW_MEAL_TYPE,
    roles.ROLE_SHOW_SIZE,
    roles.ROLE_SHOW_SUB_BRAND,
  ],
  [roles.ROLE_SHOW_MENU_SUMMARY]: [
    roles.ROLE_SHOW_MEAL_TYPE,
    roles.ROLE_SHOW_VARIANT,
    roles.ROLE_SHOW_DIET,
  ],
  [roles.ROLE_SHOW_REPORT_MACRO_INGREDIENTS]: [roles.ROLE_SHOW_VARIANT],
};

export const onDeselect = {
  [roles.ROLE_EDIT_COST]: [roles.ROLE_REMOVE_COST, roles.ROLE_CREATE_COST],
  [roles.ROLE_CREATE_COST]: [roles.ROLE_REMOVE_COST, roles.ROLE_EDIT_COST],
  [roles.ROLE_REMOVE_COST]: [roles.ROLE_CREATE_COST, roles.ROLE_EDIT_COST],
  [roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_REMOVE_MONEY_BOX_CONFIGURATION]: [
    roles.ROLE_CREATE_MONEY_BOX_CONFIGURATION,
    roles.ROLE_EDIT_MONEY_BOX_CONFIGURATION,
  ],
  [roles.ROLE_SHOW_MEAL_TYPE]: [
    roles.ROLE_SHOW_OVERPRODUCTION,
    roles.ROLE_SHOW_MENU_SUMMARY,
  ],
  [roles.ROLE_SHOW_SIZE]: [roles.ROLE_SHOW_OVERPRODUCTION],
  [roles.ROLE_SHOW_DIET]: [roles.ROLE_SHOW_COST, roles.ROLE_SHOW_MENU_SUMMARY],
  [roles.ROLE_SHOW_VARIANT]: [
    roles.ROLE_SHOW_COST,
    roles.ROLE_SHOW_MENU_SUMMARY,
    roles.ROLE_SHOW_REPORT_MACRO_INGREDIENTS,
  ],
  [roles.ROLE_SHOW_DICTIONARY]: [
    roles.ROLE_SHOW_INGREDIENT,
    roles.ROLE_SHOW_DISH,
  ],
  [roles.ROLE_SHOW_SUB_BRAND]: [
    roles.ROLE_SHOW_OVERPRODUCTION,
    roles.ROLE_SHOW_REPORT_OVERPRODUCTION,
  ],
  [roles.ROLE_SHOW_OVERPRODUCTION]: [
    roles.ROLE_CREATE_OVERPRODUCTION,
    roles.ROLE_EDIT_OVERPRODUCTION,
    roles.ROLE_REMOVE_OVERPRODUCTION,
  ],
  [roles.ROLE_SHOW_MENU_PLANNING]: [roles.ROLE_SHOW_OVERPRODUCTION],
};
