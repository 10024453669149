import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { Check, Info } from '@material-ui/icons';
import { Checkbox, Tooltip } from '@material-ui/core';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import useCheckIsPaymentDisabled from 'helpers/useCheckIsPaymentDisabled';

const PayUModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();
  const payuCard = useCheckIsPaymentDisabled('PAYU_CARD');
  const payu = useCheckIsPaymentDisabled('PAYU');

  useEffect(() => {
    if (payuCard.isSelected) {
      handleModuleUpdate({
        ...module,
        cardPaymentsEnabled: true,
      });
    }
  }, [payuCard.isSelected]);

  useEffect(() => {
    if (payu.isSelected) {
      handleModuleUpdate({
        ...module,
        enabled: true,
      });
    }
  }, [payu.isSelected]);

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'PayUModule',
        })}
      </div>
    );
  }

  return (
    <>
      <GridContainer style={{ width: '100%', margin: '0' }}>
        <GridItem xs={3}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              style={{ marginLeft: '-14px' }}
              onClick={() => {
                handleModuleUpdate({
                  ...module,
                  cardPaymentsEnabled: !module.cardPaymentsEnabled,
                });
              }}
              checked={module.cardPaymentsEnabled}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              disabled={payuCard.isSelected}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
            <span>{t('$*config.modules.PayU.cardPayments')}</span>
            {payuCard.isSelected && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <h4>
                      {t(
                        `brandCfg.payments.disabled.${payuCard.paymentName}.tooltip`
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info
                    fontSize="small"
                    style={{ color: 'grey', display: 'block' }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </GridItem>
        <GridItem xs={3}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              style={{ marginLeft: '-14px' }}
              onClick={() =>
                handleModuleUpdate({ ...module, enabled: !module.enabled })
              }
              checked={module.enabled}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              disabled={payu.isSelected}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
            <span>{t('$*config.modules.PayU.linkPayments')}</span>
            {payu.isSelected && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <h4>
                      {t(
                        `brandCfg.payments.disabled.${payu.paymentName}.tooltip`
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info
                    fontSize="small"
                    style={{ color: 'grey', display: 'block' }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </GridItem>
        <GridItem xs={6}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() =>
              handleModuleUpdate({ ...module, sandbox: !module.sandbox })
            }
            checked={module.sandbox}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          Sandbox
        </GridItem>
      </GridContainer>
      <GridItem xs={6}>
        <NumberFormat
          label="Protokół OAuth - client_id:"
          classes={classes}
          customInput={FormTextInput}
          value={module.clientId}
          onChange={e =>
            handleModuleUpdate({ ...module, clientId: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <NumberFormat
          label="Id punktu płatności (pos_id):"
          classes={classes}
          customInput={FormTextInput}
          value={module.posId}
          onChange={e =>
            handleModuleUpdate({ ...module, posId: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Protokół OAuth - client_secret:"
          classes={classes}
          name="payUClientSecret"
          value={module.clientSecret}
          handleChange={e =>
            handleModuleUpdate({ ...module, clientSecret: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Drugi klucz (MD5):"
          classes={classes}
          name="payUmd5Key"
          value={module.md5Key}
          handleChange={e =>
            handleModuleUpdate({ ...module, md5Key: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
    </>
  );
};

export default PayUModule;
