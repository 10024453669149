import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCheckIsPaymentDisabled = paymentTypeToCheck => {
  const paymentTypes = useSelector(state => state.Brands.paymentTypes);
  const [paymentTypeAfterChecking, setPaymentTypeAfterChecking] = useState({
    isSelected: false,
    paymentName: null,
  });

  useEffect(() => {
    if (paymentTypes) {
      if (paymentTypeToCheck === paymentTypes.dietStandard.defaultPaymentType) {
        setPaymentTypeAfterChecking(prevState => ({
          ...prevState,
          isSelected: true,
          paymentName: 'oneTimePaymentDefaultGateway',
        }));
      } else if (
        paymentTypeToCheck === paymentTypes.dietSubscription.defaultPaymentType
      ) {
        setPaymentTypeAfterChecking(prevState => ({
          ...prevState,
          isSelected: true,
          paymentName: 'subscriptionPaymentDefaultGateway',
        }));
      } else if (paymentTypeToCheck === paymentTypes.other.defaultPaymentType) {
        setPaymentTypeAfterChecking(prevState => ({
          ...prevState,
          isSelected: true,
          paymentName: 'orderPaymentDefaultGateway',
        }));
      } else {
        setPaymentTypeAfterChecking(prevState => ({
          ...prevState,
          isSelected: false,
          paymentName: 'null',
        }));
      }
    }
  }, [paymentTypes]);

  return paymentTypeAfterChecking;
};

export default useCheckIsPaymentDisabled;
