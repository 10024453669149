import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SizeNameCell from './SizeNameCell';
import { withTranslation } from 'react-i18next';

class SizeNameRow extends React.Component {
  render() {
    const {
      dish,
      typeSizes,
      dishSizes,
      handleSizeNameChange,
      classes,
      t,
    } = this.props;

    return (
      <TableRow>
        <TableCell>
          {t('mealTypes.nameForSize', 'Nazwa dla rozmiaru')}
        </TableCell>
        <TableCell />
        <TableCell />
        {typeSizes.map((size, sizeKey) => {
          return (
            <SizeNameCell
              key={sizeKey}
              dish={dish}
              classes={classes}
              size={size.size}
              dishSizes={dishSizes}
              handleSizeNameChange={handleSizeNameChange}
            />
          );
        })}
      </TableRow>
    );
  }
}

export default withTranslation()(SizeNameRow);
