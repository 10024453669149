import React from 'react';
import { TableCell } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTranslation } from 'react-i18next';

class SizeNameCell extends React.Component {
  state = {
    isDefault: false,
  };

  componentDidMount() {
    const { dishSizes, size, dish } = this.props;
    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    const isDefault =
      !dishSizeBySize.name || dish.nameForClient === dishSizeBySize.name;

    this.setState({
      isDefault: isDefault,
    });
  }

  render() {
    const {
      size,
      dishSizes,
      handleSizeShopAvailabilityChange,
      classes,
    } = this.props;
    const { isDefault } = this.state;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    return (
      <TableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={dishSizeBySize.allowedInShop}
              onChange={e => {
                handleSizeShopAvailabilityChange(
                  !dishSizeBySize.allowedInShop,
                  dishSizeBySize
                );
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
        />
        {!isDefault && (
          <CustomInput
            formControlProps={{ fullWidth: true }}
            maxLength={160}
            inputProps={{
              placeholder: '',
              value: dishSizeBySize.name,
              onChange: e =>
                handleSizeShopAvailabilityChange(
                  e.target.value,
                  dishSizeBySize
                ),
            }}
          />
        )}
      </TableCell>
    );
  }
}

export default withTranslation()(SizeNameCell);
