import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { set as _set } from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { combineStyles, isGranted, sortBy } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import { ReactPhoneNumberAdapter } from '../../components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import { fetchBrand } from 'actions/Brands';
import { Divider, Tooltip } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import TagsInput from 'react-tagsinput';
import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import FormTextInputNoGrid from '../../components/FormTextInput/FormTextInputNoGrid';
import { post, put } from '../../helpers/apiHelpers';
import { addUserBrand } from 'actions/Auth';
import withErrorHandler from 'components/HOC/withErrorHandler';

const dayFields = [
  'newOrder',
  'newOrderSubscription',
  'changeDate',
  'changeAddress',
  'changeDiet',
  'buyAddons',
  'paymentDeadline',
  'shouldBeDeliveredIn',
  'shouldBeDeliveredInBlock',
];

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const dayObject = (curr = {}) => {
  let obj = {};

  dayFields.forEach(field => {
    obj[field] = 0;
  });

  return { ...obj, ...curr };
};

const BrandInformation = ({
  classes,
  t,
  selectedBrand,
  fetchBrand,
  openToast,
  isEdit,
  addUserBrand,
  errorHandlerCatch,
  errors,
  errorHandlerResetState,
}) => {
  const [brandConfig, setBrandConfig] = useState({
    name: '',
    shortName: '',
    mainDomain: '',
    description: '',
    email: '',
    phone: '',
    marketingTermsContent: '',
    termsContent: '',
    termsLink: '',
    marketingTermsLink: '',
    homePageLink: '',
    logoLink: '',
    moneyBoxReadMoreLink: '',
    pickUpPointInformationLink: '',
    privacyPolicyLink: '',
    showCalculatorLink: false,
    calculatorLink: '',
    // adminDays: [],
    // days: [],
    // daysDefault: null,
    // daysMax: null,
    // daysMin: null,
    // daysSelectorType: null,
  });

  useEffect(() => {
    if (isEdit) {
      (async () => {
        const brandConfig = await fetchBrand(selectedBrand);

        setBrandConfig({
          name: brandConfig.name,
          shortName: brandConfig.shortName,
          mainDomain: brandConfig.mainDomain,
          description: brandConfig.description,
          email: brandConfig.email,
          phone: brandConfig.phone,
          marketingTermsContent: brandConfig.marketingTermsContent,
          termsContent: brandConfig.termsContent,
          termsLink: brandConfig.termsLink,
          marketingTermsLink: brandConfig.marketingTermsLink,
          homePageLink: brandConfig.homePageLink,
          logoLink: brandConfig.logoLink,
          moneyBoxReadMoreLink: brandConfig.moneyBoxReadMoreLink,
          pickUpPointInformationLink: brandConfig.pickUpPointInformationLink,
          privacyPolicyLink: brandConfig.privacyPolicyLink,
          showCalculatorLink: brandConfig.showCalculatorLink,
          calculatorLink: brandConfig.calculatorLink,
          // adminDays: brandConfig?.adminDays.sort(sortDays) || [],
          // days: brandConfig.days.sort(sortDays),
          // daysDefault: brandConfig.daysDefault,
          // daysMax: brandConfig.daysMax,
          // daysMin: brandConfig.daysMin,
          // daysSelectorType: brandConfig.daysSelectorType,
        });
      })();
    }
  }, []);

  const sortDays = (a, b) => a - b;

  const handleInputChange = e => {
    setBrandConfig(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePhoneNumberChange = e => {
    setBrandConfig(prevState => _set(prevState, e.target.name, e.target.value));
  };

  const handleRadio = (ev, test) => {
    let stateName;
    test
      ? (stateName = 'testDaysSelectorType')
      : (stateName = 'daysSelectorType');

    setBrandConfig(prevState => ({
      ...prevState,
      [stateName]: ev.target.name,
    }));
  };

  const handleDays = (days, changed, daysType) => {
    const hasDuplicates = () => {
      const lengthCount = days.filter(length => {
        return changed.includes(length);
      }).length;
      return lengthCount > 1;
    };

    if (hasDuplicates()) {
      return openToast({
        messages: [
          t('errors.valueAlreadyAdded', 'Ta wartość została już dodana'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (days.some(val => isNaN(val))) {
      return openToast({
        messages: [t('errors.valueMustBeNumber', 'Wartość musi być liczbą')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    setBrandConfig(prevState => ({ ...prevState, [daysType]: days }));
  };

  // const validateForm = () => {
  //   if (brandConfig.name === '') {
  //     window.scrollTo({ top: 0, behavior: 'smooth' });
  //     openToast({
  //       messages: [
  //         t(
  //           'errors.fillAllRequiredFields',
  //           'Wypełnij wszystkie obowiązkowe pola oznaczone gwiazdką'
  //         ),
  //       ],
  //       type: 'error',
  //       autoHideDuration: 3000,
  //     });
  //     return false;
  //   }
  //
  //   if (brandConfig.days < 1) {
  //     window.scrollTo({ top: 0, behavior: 'smooth' });
  //     openToast({
  //       messages: [
  //         t(
  //           'errors.addMinimumOneDietLengthForClient',
  //           'Dodaj co najmniej jedną długość zamówienia dla klienta'
  //         ),
  //       ],
  //       type: 'error',
  //       autoHideDuration: 3000,
  //     });
  //     return false;
  //   }
  //
  //   // if (!validateMailerFrom()) {
  //   //   window.scrollTo({ top: 0, behavior: 'smooth' });
  //   //   openToast({
  //   //     messages: [
  //   //       t(
  //   //         'errors.senderEmailInvalid',
  //   //         'Ten Email nadawcy jest nieprawidłowy'
  //   //       ),
  //   //     ],
  //   //     type: 'error',
  //   //     autoHideDuration: 3000,
  //   //   });
  //   //   return false;
  //   // }
  //
  //   return true;
  // };

  const handleSubmit = async () => {
    // if (!validateForm()) {
    //   return;
    // }

    const data = {
      name: brandConfig.name,
      shortName: brandConfig.shortName,
      mainDomain: brandConfig.mainDomain,
      description: brandConfig.description,
      email: brandConfig.email,
      phone: brandConfig.phone,
      marketingTermsContent: brandConfig.marketingTermsContent,
      termsContent: brandConfig.termsContent,
      termsLink: brandConfig.termsLink,
      marketingTermsLink: brandConfig.marketingTermsLink,
      homePageLink: brandConfig.homePageLink,
      logoLink: brandConfig.logoLink,
      moneyBoxReadMoreLink: brandConfig.moneyBoxReadMoreLink,
      pickUpPointInformationLink: brandConfig.pickUpPointInformationLink,
      privacyPolicyLink: brandConfig.privacyPolicyLink,
      showCalculatorLink: brandConfig.showCalculatorLink,
      calculatorLink: brandConfig.calculatorLink,
      // days: brandConfig.days.map(length => parseInt(length)),
      // daysDefault: parseInt(brandConfig.daysDefault),
      // daysMax: parseInt(brandConfig.daysMax),
      // daysMin: parseInt(brandConfig.daysMin),
      // daysSelectorType: brandConfig.daysSelectorType,
      // adminDays: brandConfig.adminDays,
    };

    if (!isEdit) {
      days.forEach(field => {
        data[field] = dayObject();
      });

      data.adminDays = [];
      data.days = [];
      data.daysDefault = null;
      data.daysMax = null;
      data.daysMin = null;
      data.daysSelectorType = 'RANGE';
    }

    try {
      errorHandlerResetState();

      const response = isEdit
        ? await put(`/brands/${selectedBrand}`, data)
        : await post('/brands', data);

      // TODO: translate
      if (!isEdit) {
        openToast({
          messages: [
            'Marka została utworzona.',
            'Pamiętaj o dodaniu uprawnień do marki odpowiednim pracownikom.',
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      } else {
        openToast({
          messages: [t('success.changesSaved')],
          type: 'success',
          autoHideDuration: 3000,
        });
      }

      await addUserBrand({
        '@id': response['@id'],
        id: response.id,
        name: response.name,
      });

      // !isEdit && this.props.history.push('/admin/company');
    } catch (error) {
      errorHandlerCatch(error);
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('brandCfg.basic')}</h1>
            </GridItem>
            <GridItem md={6}>
              <FormTextInput
                label={t('brandCfg.brandName') + '*'}
                classes={classes}
                name="name"
                value={brandConfig.name}
                handleChange={handleInputChange}
                inputSize={12}
                errors={errors}
              />
              <FormTextInput
                label={t('brandCfg.mainDomain')}
                classes={classes}
                name="mainDomain"
                value={brandConfig.mainDomain}
                maxLength={64}
                handleChange={handleInputChange}
                inputSize={12}
              />
              <FormTextInput
                label={t('brandCfg.short')}
                classes={classes}
                name="shortName"
                value={brandConfig.shortName}
                handleChange={handleInputChange}
                inputSize={12}
              />
              <FormTextInput
                label={t('brandCfg.brandDesc')}
                classes={classes}
                name="description"
                value={brandConfig.description}
                handleChange={handleInputChange}
                inputSize={12}
                multiline={true}
                rows={3}
                rowsMax={20}
                maxLength={500}
              />
            </GridItem>
            <GridItem md={6}>
              <FormTextInput
                label={t('brandCfg.emailAddr')}
                classes={classes}
                name="email"
                value={brandConfig.email}
                handleChange={handleInputChange}
                inputSize={12}
                errors={errors}
              />

              <ReactPhoneNumberAdapter
                label={t('brandCfg.infolineClient')}
                name="phone"
                value={brandConfig.phone}
                onChange={handlePhoneNumberChange}
                inputSize={12}
              />

              <FormTextInput
                label={t('brandCfg.marketingContent')}
                classes={classes}
                name="marketingTermsContent"
                value={brandConfig.marketingTermsContent}
                handleChange={handleInputChange}
                inputSize={12}
                multiline={true}
                rows={2}
                rowsMax={20}
                maxLength={4000}
              />
              <FormTextInput
                label={t('brandCfg.contentReg')}
                classes={classes}
                name="termsContent"
                value={brandConfig.termsContent}
                handleChange={handleInputChange}
                inputSize={12}
                multiline={true}
                rows={2}
                rowsMax={20}
                maxLength={4000}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('brandCfg.addrAndLinks')}</h1>
            </GridItem>
            <GridItem md={4}>
              <FormTextInput
                label={t('brandCfg.regAddr')}
                classes={classes}
                name="termsLink"
                value={brandConfig.termsLink}
                maxLength={255}
                handleChange={handleInputChange}
                inputSize={12}
              />
              <FormTextInput
                label={t('brandCfg.addrMarketing')}
                classes={classes}
                name="marketingTermsLink"
                value={brandConfig.marketingTermsLink}
                maxLength={255}
                handleChange={handleInputChange}
                inputSize={12}
              />
              <FormTextInput
                label={t('brandCfg.homepageAddr')}
                classes={classes}
                name="homePageLink"
                value={brandConfig.homePageLink}
                maxLength={255}
                handleChange={handleInputChange}
                inputSize={12}
              />
            </GridItem>
            <GridItem md={4}>
              <FormTextInput
                label={t('brandCfg.addrDestLogo')}
                classes={classes}
                name="logoLink"
                value={brandConfig.logoLink}
                maxLength={255}
                handleChange={handleInputChange}
                inputSize={12}
              />
              <FormTextInput
                label={t('brandCfg.moneyboxAddr')}
                classes={classes}
                name="moneyBoxReadMoreLink"
                value={brandConfig.moneyBoxReadMoreLink}
                maxLength={255}
                handleChange={handleInputChange}
                inputSize={12}
              />
              <FormTextInput
                label={t('brandCfg.pickupPointsAddr')}
                classes={classes}
                name="pickUpPointInformationLink"
                value={brandConfig.pickUpPointInformationLink}
                maxLength={255}
                handleChange={handleInputChange}
                inputSize={12}
              />
            </GridItem>
            <GridItem md={4}>
              <FormTextInput
                label={t('brandCfg.privacyPolicyAddr')}
                classes={classes}
                name="privacyPolicyLink"
                value={brandConfig.privacyPolicyLink}
                maxLength={255}
                handleChange={handleInputChange}
                inputSize={12}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={brandConfig.showCalculatorLink}
                    onChange={() => {
                      setBrandConfig(prevState => ({
                        ...prevState,
                        showCalculatorLink: !brandConfig.showCalculatorLink,
                      }));
                    }}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t('brandCfg.calcBtnShow')}
              />
              <FormTextInput
                label={t('brandCfg.calcAddr')}
                classes={classes}
                name="calculatorLink"
                value={brandConfig.calculatorLink}
                handleChange={handleInputChange}
                inputSize={12}
                maxLength={255}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      {/*<Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h4 style={{ marginTop: '50px' }}>{t('brandCfg.orderLength')}</h4>
              <Divider style={{ marginBottom: '20px' }} />
            </GridItem>
            <GridItem md={6}>
              <FormControlLabel
                control={
                  <Radio
                    checked={
                      brandConfig.daysSelectorType === 'PREDEFINED_OPTIONS'
                    }
                    onChange={ev => handleRadio(ev)}
                    name={'PREDEFINED_OPTIONS'}
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={t('brandCfg.defLength')}
              />
              <label
                htmlFor="orderLength"
                onClick={() => {
                  setBrandConfig(prevState => ({
                    ...prevState,
                    daysSelectorType: 'PREDEFINED_OPTIONS',
                  }));
                }}
                style={{
                  cursor: 'pointer',
                  display: 'block',
                  color: 'rgba(0, 0, 0, 0.46)',
                }}
              >
                {t('brandCfg.addLength')}
              </label>
              <TagsInput
                renderTag={props => {
                  let {
                    tag,
                    key,
                    disabled,
                    onRemove,
                    classNameRemove,
                    getTagDisplayValue,
                    ...other
                  } = props;
                  return (
                    <span key={key} {...other}>
                      {getTagDisplayValue(
                        parseInt(tag) === 1
                          ? tag + t('brandCfg.day')
                          : tag + t('brandCfg.days')
                      )}
                      {!disabled && (
                        <a
                          className={classNameRemove}
                          onClick={() => onRemove(key)}
                        />
                      )}
                    </span>
                  );
                }}
                inputProps={{
                  style: {
                    width: '45px',
                    marginTop: '25px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.26)',
                    paddingBottom: '10px',
                  },
                  id: 'orderLength',
                  placeholder: t('brands.newBrandForm.eg', 'np: "7"'),
                  disabled:
                    brandConfig.daysSelectorType !== 'PREDEFINED_OPTIONS',
                }}
                tagProps={{
                  className: 'react-tagsinput-tag info',
                }}
                value={brandConfig.days}
                // onlyUnique={true}
                onChange={(tags, changed) => {
                  const tagsToInts = tags.map(el => parseInt(el));
                  const changedToInts = changed.map(el => parseInt(el));
                  handleDays(tagsToInts, changedToInts, 'days');
                }}
              />
            </GridItem>
            <GridItem md={6}>
              <FormControlLabel
                control={
                  <Radio
                    checked={
                      brandConfig.daysSelectorType === 'RANGE' ||
                      brandConfig.daysSelectorType === 'RANGE_INPUT'
                    }
                    name={'RANGE'}
                    onChange={ev => handleRadio(ev)}
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={t('brandCfg.minimumAndMaximum')}
              />
              <label
                htmlFor="orderLength"
                style={{
                  cursor: 'pointer',
                  display: 'block',
                  color: 'rgba(0, 0, 0, 0.46)',
                }}
                onClick={() => {
                  setBrandConfig(prevState => ({
                    ...prevState,
                    daysSelectorType: 'RANGE',
                  }));
                }}
              >
                {t('brandCfg.setupMinMax')}
              </label>
              <GridContainer>
                <GridItem md={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brandCfg.minimum')}
                  </FormLabel>
                  <NumberFormat
                    classes={classes}
                    customInput={FormTextInputNoGrid}
                    format={'###'}
                    name={'daysMin'}
                    value={brandConfig.daysMin}
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem md={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brandCfg.maximum')}
                  </FormLabel>
                  <NumberFormat
                    classes={classes}
                    customInput={FormTextInputNoGrid}
                    format={'###'}
                    name={'daysMax'}
                    value={brandConfig.daysMax}
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem md={4}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brandCfg.defValue')}
                  </FormLabel>
                  <NumberFormat
                    classes={classes}
                    customInput={FormTextInputNoGrid}
                    format={'###'}
                    name={'daysDefault'}
                    value={brandConfig.daysDefault}
                    onChange={handleInputChange}
                  />
                </GridItem>
              </GridContainer>
              {(brandConfig.daysSelectorType === 'RANGE' ||
                brandConfig.daysSelectorType === 'RANGE_INPUT') && (
                <Fragment>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={brandConfig.daysSelectorType === 'RANGE'}
                        onChange={ev => handleRadio(ev)}
                        name={'RANGE'}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label="Slider"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={brandConfig.daysSelectorType === 'RANGE_INPUT'}
                        onChange={ev => handleRadio(ev)}
                        name={'RANGE_INPUT'}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={t('brands.newBrandForm.manual', 'Ręczne wpisanie')}
                  />
                </Fragment>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>*/}

      {/*<Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h4 style={{ marginTop: '50px' }}>
                {t('brandCfg.adminOrderLength')}
              </h4>
              <Divider style={{ marginBottom: '20px' }} />
            </GridItem>
            <GridItem md={12}>
              <label
                htmlFor="adminDays"
                style={{
                  cursor: 'pointer',
                  display: 'block',
                  color: 'rgba(0, 0, 0, 0.46)',
                }}
              >
                {t('brandCfg.addLength')}
              </label>
              <TagsInput
                renderTag={props => {
                  let {
                    tag,
                    key,
                    disabled,
                    onRemove,
                    classNameRemove,
                    getTagDisplayValue,
                    ...other
                  } = props;
                  return (
                    <span key={key} {...other}>
                      {getTagDisplayValue(
                        parseInt(tag) === 1
                          ? tag + t('brandCfg.day')
                          : tag + t('brandCfg.days')
                      )}
                      {!disabled && (
                        <a
                          className={classNameRemove}
                          onClick={() => onRemove(key)}
                        />
                      )}
                    </span>
                  );
                }}
                inputProps={{
                  style: {
                    width: '45px',
                    marginTop: '25px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.26)',
                    paddingBottom: '10px',
                  },
                  id: 'adminDays',
                  placeholder: 'np: "7"',
                }}
                tagProps={{
                  className: 'react-tagsinput-tag danger',
                }}
                value={brandConfig.adminDays}
                onChange={(tags, changed) => {
                  const tagsToInts = tags.map(el => parseInt(el));
                  const changedToInts = changed.map(el => parseInt(el));
                  handleDays(tagsToInts, changedToInts, 'adminDays');
                }}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>*/}

      <FormControlButtons
        classes={classes}
        discardText={t('common.shared.cancel')}
        submitText={t('common.shared.save')}
        cancelPath="/admin/brands"
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrand, addUserBrand }
  ),
  withToast,
  withStyles(combinedStyles),
  withErrorHandler
);

export default enhance(BrandInformation);
