import React from 'react';

import { withTranslation } from 'react-i18next';

import FormTextInput from '../FormTextInput/FormTextInput';
import LabelWithTooltip from '../../views/PagesCreator/LabelWithTooltip';
import LettersCounter from '../../views/PagesCreator/LettersCounter';
import AdminTable from '../../layouts/AdminTable';

const BasicInfoCard = ({
  t,
  metaDescription,
  metaTitle,
  urlSlug,
  friendlyName,
  handleChange,
}) => {
  return (
    <AdminTable>
      <h1>{t('form.basicInfo')}</h1>

      {friendlyName !== undefined && (
        <FormTextInput
          label={t('Przyjazna nazwa - widoczna na stronie')}
          classes={''}
          name="friendlyName"
          value={friendlyName}
          handleChange={handleChange}
          inputSize={12}
          maxLength={64}
        />
      )}

      <FormTextInput
        label={
          <LabelWithTooltip
            label={t('views.addons.addonSubpage.pageUrl') + '*'}
            tooltip={t('pages.pageUrl.tooltip')}
          />
        }
        classes={''}
        name="urlSlug"
        value={urlSlug}
        handleChange={handleChange}
        inputSize={12}
        maxLength={64}
      />

      <FormTextInput
        label={
          <LabelWithTooltip
            label={t('views.addons.addonSubpage.metaTitle') + '*'}
            tooltip={t('pages.title.tooltip')}
          />
        }
        classes={''}
        name="metaTitle"
        value={metaTitle}
        handleChange={handleChange}
        inputSize={12}
        maxLength={60}
      />

      <FormTextInput
        label={
          <LettersCounter
            label={t('pages.metaDesc')}
            wordsCount={metaDescription.length}
          />
        }
        classes={''}
        name="metaDescription"
        value={metaDescription}
        handleChange={handleChange}
        inputSize={12}
        maxLength={300}
        rows={2}
        multiline
      />
    </AdminTable>
  );
};

export default withTranslation()(BasicInfoCard);
