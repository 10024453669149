import types from 'helpers/constants';
import produce from 'immer';

const initialState = {
  sexes: [],
  howKnow: [],
  isLoading: {
    sexes: false,
    howKnow: false,
  },
};

const DictionaryReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case types.FETCH_SEXES: {
        draft.isLoading.sexes = true;
        break;
      }

      case types.FETCH_SEXES_SUCCESS: {
        draft.sexes = payload.sexes;
        break;
      }

      case types.FETCH_SEXES_FAILED: {
        draft.isLoading.sexes = false;
        break;
      }

      case types.FETCH_HOW_KNOW: {
        draft.isLoading.howKnow = true;
        break;
      }

      case types.FETCH_HOW_KNOW_SUCCESS: {
        draft.howKnow = payload.howKnow;
        break;
      }

      case types.FETCH_HOW_KNOW_FAILED: {
        draft.isLoading.howKnow = false;
        break;
      }

      default:
        return draft;
    }
  });

export default DictionaryReducer;
