import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import Button from 'components/CustomButtons/Button';
import { isEmpty, isGranted } from 'helpers/helpers';
import { ROLE_IMPERSONATE_USER } from 'helpers/roles';

const FakeUserIcon = ({ domain, mainDomain, email, refreshToken, token }) => {
  const redirectDomain = domain ?? mainDomain;

  if (!isGranted(ROLE_IMPERSONATE_USER) || isEmpty(redirectDomain)) {
    return null;
  }

  return (
    <Button
      simple={true}
      round={true}
      style={{
        margin: '10px 1px 10px 18px',
        padding: '0',
        width: '20px',
      }}
    >
      <a
        href={`https://${redirectDomain}/auth/switch?token=${token}&refreshToken=${refreshToken}&email=${email}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SupervisedUserCircleIcon />
      </a>
    </Button>
  );
};

const mapStateToProps = ({
  Brands: { brand },
  Auth: { token, refreshToken, selectedBrand },
}) => ({
  mainDomain: brand.mainDomain || '',
  refreshToken,
  selectedBrand,
  token,
});

FakeUserIcon.propTypes = {
  domain: PropTypes.string,
  mainDomain: PropTypes.string,
  email: PropTypes.string.isRequired,
  refreshToken: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(FakeUserIcon);
