import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles, { ROLE_SHOW_SUB_BRAND } from 'helpers/roles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import CardIcon from 'components/Card/CardIcon';
import SelectAll from 'components/SelectAll';

import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from '../../components/Security/AccessDenied';
import { fetchSubBrands } from '../../actions/SubBrands';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Archive from '../../components/Archive/Archive';

class Overproduction extends React.Component {
  state = {
    subBrands: [],
    dateFrom: '',
    dateTo: '',
    isGenerating: false,
  };

  componentDidMount() {
    this.props.fetchSubBrands();
  }

  url = 'reports/overproduction';

  getFileName = ({ dateFrom, dateTo }, title) => {
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${dateFrom}-${dateTo}_(${moment().format(
      'YYYY-MM-DD_HH.mm'
    )}).xlsx`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    return this.state.dateFrom && this.state.dateTo;
  };

  validateSubBrands = () => {
    return this.state.subBrands.length !== 0;
  };

  handleGenerate = title => {
    this.setState({ isGenerating: true });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [this.props.t('reports.selectDates')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (!this.validateSubBrands()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [this.props.t('reports.selectSubbrands')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const params = {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      subBrands: this.state.subBrands.map(subBrand => subBrand.value),
    };

    axios
      .get(this.url, {
        responseType: 'blob',
        params: params,
        headers: { accept: 'application/vnd.ms-excel' },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.getFileName(this.state, title));
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(this.props.t('reports.noAccess'));
            this.setState({ isGenerating: false });
          }
        },
        error => {
          this.props.openToast({
            messages: [
              this.props.t('reports.cannotGenerate'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          this.setState({ isGenerating: false });
        }
      );
  };

  render() {
    const { classes, t } = this.props;
    const title = t('reports.reportAddOrders');

    return isGranted(roles.ROLE_SHOW_REPORT_OVERPRODUCTION) ? (
      <>
        <Card style={{ marginTop: '20px' }}>
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>{this.props.t('reports.generate')}</h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <PlaylistAddCheck />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify="flex-end">
              {isGranted(ROLE_SHOW_SUB_BRAND) && (
                <GridItem sm={4}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '2px' }}
                  >
                    {this.props.t('reports.selectSubbrands')}
                  </FormLabel>
                  <SelectAll
                    options={this.props.subBrands}
                    trackBy={['@id']}
                    mapBy={'name'}
                    optionSelected={this.state.subBrands}
                    customLabel={subBrand => {
                      const fullBrand = this.props.userBrands.find(
                        brand => brand['@id'] === subBrand.brand
                      );
                      const brandName = fullBrand && fullBrand.name;
                      const subBrandName = subBrand.name;
                      const label = subBrandName;
                      const labelWithBrandName = `${label} (${brandName})`;

                      return brandName ? labelWithBrandName : label;
                    }}
                    handleChange={selected => {
                      this.setState({
                        subBrands: selected,
                      });
                    }}
                  />
                </GridItem>
              )}
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {this.props.t('reports.selectDate1')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateFrom)}
                    onChange={ev =>
                      this.setState({ dateFrom: ev.format('YYYY-MM-DD') })
                    }
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {this.props.t('reports.selectDate2')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateTo)}
                    onChange={ev =>
                      this.setState({ dateTo: ev.format('YYYY-MM-DD') })
                    }
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <Button
                  onClick={() => this.handleGenerate(title)}
                  disabled={this.state.isGenerating}
                  color={'success'}
                  round
                >
                  {this.props.t('reports.gReport')}
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Archive type={['REPORT']} reportName={['OVERPRODUCTION']} />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  subBrands: state.SubBrands.subBrands,
  userBrands: state.Auth.user.brands,
});

const mapDispatchToProps = {
  fetchSubBrands,
};

BaseBrandAndDateReport.propTypes = {
  brands: PropTypes.array,
  classes: PropTypes.any,
  fileName: PropTypes.func.isRequired,
  mimeType: PropTypes.string.isRequired,
  openToast: PropTypes.any,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withTranslation(),
  withStyles(combinedStyles)
);

export default enhance(Overproduction);
