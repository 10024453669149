import { useState, useEffect } from 'react';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { Goodspeed } from './modules';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import Card from '../../components/Card/Card';
import React from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesNames } from './consts';
import { fetchZones } from '../../actions/Zones';
import { put } from '../../helpers/apiHelpers';
import { DialogLoader } from '../../components/DialogLoader';

const DeliveryOptions = ({
  classes,
  t,
  zones,
  selectedBrand,
  fetchBrand,
  fetchZones,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
  openToast,
}) => {
  const [state, setState] = useState({
    deliveryToDoorEnabled: true,
    pickUpPointEnabled: false,
  });
  const [modules, setModules] = useState({
    ConfigClientPanel: {
      '@type': modulesNames.ConfigClientPanel,
      defaultPaymentMode: 2,
      enableVariantStep: true,
      isOneTimePayPaymentMode: true,
      isSubscriptionPaymentMode: false,
      oneTimePaymentDefaultGateway: 'TPAY',
      orderPaymentDefaultGateway: 'TPAY',
      referralProgramDescription: '',
      hideDisabledDaysOnClientCalendar: false,
      subscriptionPaymentDefaultGateway: 'STRIPE_CARD',
      validateBagChangesWithSlots: false,
      showCalendarIcons: true,
      useAddressesWithLessFields: false,
      allowClientPayWithMoneboxEverywhere: false,
    },
    Fakturownia: {
      '@type': modulesNames.Fakturownia,
      addonsCustomName: '',
      addonsCustomNameEnabled: false,
      allowInvoicesForClient: false,
      bagModificationName: '',
      bagModificationVat: 8,
      deliveryItemName: '',
      department: null,
      dietItemName: '',
      enabled: false,
      host: '',
      integrationType: 'DISABLED',
      token: '',
    },
    PayU: {
      '@type': modulesNames.PayU,
      clientId: null,
      clientSecret: '',
      enabled: false,
      md5Key: '',
      posId: null,
      sandbox: false,
    },
    Stripe: {
      '@type': modulesNames.Stripe,
      cardPayments: false,
      enabled: false,
      linkPayments: false,
      publishableKey: '',
      rulesLink: null,
      secretKey: '',
    },
    MyLead: {
      '@type': modulesNames.MyLead,
      clickId: null,
      enabled: false,
      payoutDecimal: null,
      providerHash: null,
      userId: null,
    },
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      await fetchZones();

      setState(prevState => ({
        ...prevState,
        deliveryToDoorEnabled: brandConfig.deliveryToDoorEnabled,
        pickUpPointEnabled: brandConfig.pickUpPointEnabled,
        // modules: { ...modulesConfig.configuration },
      }));
      setModules(modulesConfig.configuration);

      setIsLoading(false);
    })();
  }, []);

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };

  const handleSubmit = async () => {
    try {
      await put(`/brands/${selectedBrand}`, state);
      await updateBrandConfigModulesPack(selectedBrand, modules);

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      openToast({
        messages: [
          t('errors.modulesCouldNotBeSaved', 'Nie udało się zapisać modułów'),
          error.message,
        ],
        type: 'error',
        autoHideDuration: 300,
      });
      // this.props.errorHandlerCatch(error);
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <h1>
                    {t('brands.newBrandForm.deliveryOptions', 'Opcje dostawy')}
                  </h1>
                </GridItem>

                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.deliveryToDoorEnabled}
                        onChange={() => {
                          setState(prevState => ({
                            ...prevState,
                            deliveryToDoorEnabled: !state.deliveryToDoorEnabled,
                          }));
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('brands.newBrandForm.toDoor', 'Dostawa pod drzwi')}
                  />
                </GridItem>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.pickUpPointEnabled}
                        onChange={() => {
                          setState(prevState => ({
                            ...prevState,
                            pickUpPointEnabled: !state.pickUpPointEnabled,
                          }));
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('brands.newBrandForm.atPoint', 'Odbiór w punkcie')}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h1>Goodspeed</h1>
              <Goodspeed
                classes={classes}
                zones={zones}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.Goodspeed]}
              />
            </CardBody>
          </Card>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Zones: { zones } }) => ({
      selectedBrand,
      zones,
    }),
    {
      fetchBrand,
      fetchBrandConfigModulesPack,
      fetchZones,
      updateBrandConfigModulesPack,
    }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(DeliveryOptions);
