import { useState } from 'react';

import PostCodeRow from './PostCodeRow';
import { v4 as uuidv4 } from 'uuid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { AddCircle } from '@material-ui/icons';
import ModalButton from '../../../../components/CustomButtons/Button';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlButtons from '../../../../components/FormControlButtons/FormControlButtons';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { combineStyles } from '../../../../helpers/helpers';
import extendedFormsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';

const defaultRow = () => ({
  _key: uuidv4(),
  postCode: '',
  city: '',
  street: '',
  buildingNumber: '',
});

const PostCodeModal = ({
  t,
  classes,
  postCodes,
  shortFormEnabled,
  postCodeModalStatus,
  togglePostCodeModal,
  setMergedAddresses,
  postCodeValidator,
}) => {
  const [postCodesData, setPostCodesData] = useState([defaultRow()]);
  const [errors, setErrors] = useState({});

  const addNextRow = () => {
    setPostCodesData(prevState => [...prevState, defaultRow()]);
  };

  const removeRow = row => {
    setPostCodesData(prevState =>
      prevState.filter(parameter => parameter._key !== row._key)
    );
  };

  const updateRow = (id, e) => {
    const postCodesDataCopy = [...postCodesData];
    const rowIndex = postCodesDataCopy.findIndex(
      postCodeData => postCodeData._key === id
    );
    postCodesDataCopy[rowIndex][e.target.name] = e.target.value;
    setPostCodesData(postCodesDataCopy);
  };

  const copyRow = row => {
    const copiedRow = { ...row, _key: uuidv4() };
    setPostCodesData(prevState => [...prevState, copiedRow]);
  };

  const setError = (rowId, property, message) => {
    setErrors(prevState => ({
      ...prevState,
      [rowId]: { ...prevState[rowId], [property]: message },
    }));
  };

  const removeError = (rowId, property) => {
    const copiedState = { ...errors };
    if (
      copiedState.hasOwnProperty(rowId) &&
      copiedState[rowId].hasOwnProperty(property)
    ) {
      delete copiedState[rowId][property];
      if (Object.keys(copiedState[rowId]).length === 0) {
        delete copiedState[rowId];
      }
      setErrors(copiedState);
    }
  };

  const validateInput = (addressData, property) => {
    if (addressData[property] === '') {
      setError(addressData._key, property, t('zones.requiredField'));
      return false;
    } else if (!postCodeValidator(addressData[property])) {
      setError(addressData._key, property, t('zones.typeValidPostcode'));
      return false;
    } else {
      return true;
    }
  };

  const mergeAddresses = () => {
    const areAddressesValid = [];
    postCodesData.forEach(address => {
      const isPostCodeValid = validateInput(address, 'postCode');
      areAddressesValid.push(isPostCodeValid);
    });

    if (areAddressesValid.some(x => x === false)) {
      return;
    }

    const postCodesCopy = [...postCodes];
    const mergedPostCodes = postCodesData.reduce((acc, item) => {
      const existsPostCodeIndex = acc.findIndex(
        postCode => postCode.code === item.postCode
      );

      if (existsPostCodeIndex !== -1) {
        const postCodeCities = acc[existsPostCodeIndex].cities;
        const existsCityIndex = postCodeCities.findIndex(
          city => city.name === item.city
        );

        if (existsCityIndex !== -1) {
          const postCodeStreets =
            acc[existsPostCodeIndex].cities[existsCityIndex].streets;
          const existsStreetIndex = postCodeStreets.findIndex(
            street => street.name === item.street
          );

          if (existsStreetIndex !== -1) {
            const postCodeBuildings =
              acc[existsPostCodeIndex].cities[existsCityIndex].streets[
                existsStreetIndex
              ].buildings;
            const existsBuildingIndex = postCodeBuildings.findIndex(
              buildingName => buildingName === item.buildingNumber
            );

            if (existsBuildingIndex === -1) {
              acc[existsPostCodeIndex].cities[existsCityIndex].streets[
                existsStreetIndex
              ].buildings.push(item.buildingNumber);
            }
          } else {
            acc[existsPostCodeIndex].cities[existsCityIndex].streets.push({
              name: item.street,
              buildings: item.buildingNumber.length
                ? [item.buildingNumber]
                : [],
            });
          }
        } else {
          acc[existsPostCodeIndex].cities.push({
            name: item.city,
            streets: item.street.length
              ? [{ name: item.street, buildings: [item.buildingNumber] }]
              : [],
          });
        }
      } else {
        acc.push({
          code: item.postCode,
          cities:
            item.city.length > 0
              ? [
                  {
                    name: item.city,
                    streets:
                      item.street.length > 0
                        ? [
                            {
                              name: item.street,
                              buildings:
                                item.buildingNumber.length > 0
                                  ? [item.buildingNumber]
                                  : [],
                            },
                          ]
                        : [],
                  },
                ]
              : [],
        });
      }

      return acc;
    }, postCodesCopy);

    setMergedAddresses(mergedPostCodes);
    setPostCodesData([defaultRow()]);
    togglePostCodeModal();
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={false}
      open={postCodeModalStatus}
      onClose={togglePostCodeModal}
    >
      <DialogContent>
        <ModalButton
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            cursor: 'pointer',
          }}
          color={'transparent'}
          justIcon
          round
          onClick={togglePostCodeModal}
        >
          <Close />
        </ModalButton>
        <GridContainer>
          <GridItem xs={12}>
            {postCodesData?.map((postCodeData, index) => (
              <PostCodeRow
                key={postCodeData._key}
                copyRow={copyRow}
                removeRow={removeRow}
                updateRow={updateRow}
                removeError={removeError}
                postCodeData={postCodeData}
                canBeRemoved={index !== 0}
                shortFormEnabled={shortFormEnabled}
                errors={errors[postCodeData._key]}
              />
            ))}
          </GridItem>

          <GridItem xs={12}>
            <Button
              onClick={addNextRow}
              disabled={false}
              color={'default'}
              round
              justIcon
            >
              <AddCircle />
            </Button>
          </GridItem>

          <GridItem xs={12}>
            <FormControlButtons
              classes={classes}
              submitText={t('zones.add', 'Dodaj')}
              handleSubmit={mergeAddresses}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(PostCodeModal);
