import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { Check, Info } from '@material-ui/icons';
import { Checkbox, Tooltip } from '@material-ui/core';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import useCheckIsPaymentDisabled from 'helpers/useCheckIsPaymentDisabled';

const BlueMediaModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();
  const blueMediaCard = useCheckIsPaymentDisabled('BLUE_MEDIA_CARD');
  const blueMedia = useCheckIsPaymentDisabled('BLUE_MEDIA');

  useEffect(() => {
    if (blueMediaCard.isSelected) {
      handleModuleUpdate({
        ...module,
        cardPaymentsEnabled: true,
      });
    }
  }, [blueMediaCard.isSelected]);

  useEffect(() => {
    if (blueMedia.isSelected) {
      handleModuleUpdate({
        ...module,
        enabled: true,
      });
    }
  }, [blueMedia.isSelected]);

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'BlueMediaModule',
        })}
      </div>
    );
  }

  return (
    <>
      <GridContainer style={{ width: '100%', margin: '0' }}>
        <GridItem xs={3}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              style={{ marginLeft: '-14px' }}
              onClick={() =>
                handleModuleUpdate({
                  ...module,
                  cardPaymentsEnabled: !module.cardPaymentsEnabled,
                })
              }
              checked={module.cardPaymentsEnabled}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              disabled={blueMediaCard.isSelected}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
            <span>{t('$*config.modules.BlueMedia.cardPayments')}</span>
            {blueMediaCard.isSelected && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <h4>
                      {t(
                        `brandCfg.payments.disabled.${blueMediaCard.paymentName}.tooltip`
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info
                    fontSize="small"
                    style={{ color: 'grey', display: 'block' }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </GridItem>
        <GridItem xs={3}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              style={{ marginLeft: '-14px' }}
              onClick={() =>
                handleModuleUpdate({ ...module, enabled: !module.enabled })
              }
              checked={module.enabled}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              disabled={blueMedia.isSelected}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
            <span>{t('$*config.modules.BlueMedia.linkPayments')}</span>
            {blueMedia.isSelected && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <h4>
                      {t(
                        `brandCfg.payments.disabled.${blueMedia.paymentName}.tooltip`
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info
                    fontSize="small"
                    style={{ color: 'grey', display: 'block' }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </GridItem>
      </GridContainer>
      <GridItem xs={6}>
        <NumberFormat
          label={t(
            'brandCfg.payments.blueMedia.serviceId.label',
            '$$ID serwisu'
          )}
          classes={classes}
          customInput={FormTextInput}
          value={module.serviceId}
          onChange={e =>
            handleModuleUpdate({ ...module, serviceId: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label={t(
            'brandCfg.payments.blueMedia.hashMethod.label',
            '$$Metoda szyfrowania hash'
          )}
          classes={classes}
          name="hashMethod"
          value={module.hashMethod}
          handleChange={e =>
            handleModuleUpdate({ ...module, hashMethod: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label={t(
            'brandCfg.payments.blueMedia.serviceKey.label',
            '$$Klucz serwisu'
          )}
          classes={classes}
          name="serviceKey"
          value={module.serviceKey}
          handleChange={e =>
            handleModuleUpdate({ ...module, serviceKey: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label={t(
            'brandCfg.payments.blueMedia.separator.label',
            '$$Separator'
          )}
          classes={classes}
          name="separator"
          value={module.separator}
          handleChange={e =>
            handleModuleUpdate({ ...module, separator: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label={t(
            'brandCfg.payments.blueMedia.gatewayUrl.label',
            '$$URL sklepu dla startu transakcji'
          )}
          classes={classes}
          name="gatewayUrl"
          value={module.gatewayUrl}
          handleChange={e =>
            handleModuleUpdate({ ...module, gatewayUrl: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label={t(
            'config.modules.BlueMedia.subscriptionTerms',
            'Tekst przycisku i adres regulaminu'
          )}
          classes={classes}
          value={module.rulesLink}
          handleChange={e =>
            handleModuleUpdate({
              ...module,
              rulesLink: e.target.value,
            })
          }
          inputSize={12}
          maxLength={255}
        />
      </GridItem>
    </>
  );
};

export default BlueMediaModule;
