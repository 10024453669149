import React from 'react';
import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import FormImageUpload from '../../../components/FormImageUpload/FormImageUpload';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';

const ClientsPanelInterface = ({
  classes,
  t,
  handleInputChange,
  state,
  getImage,
  removeImage,
}) => {
  return (
    <Card>
      <CardBody>
        <h5>Wygląd panelu klienta</h5>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <FormTextInput
              label={t('form.field.description')}
              classes={classes}
              name="description"
              value={state.description}
              handleChange={handleInputChange}
              inputSize={6}
              multiline={true}
              rows={1}
              rowsMax={20}
              maxLength={2000}
            />
            <FormTextInput
              label={t('form.field.orderInOrderForm')}
              classes={classes}
              name="position"
              value={state.position}
              type={'number'}
              handleChange={handleInputChange}
              inputSize={6}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <GridContainer>
              <GridItem xs={12} lg={6}>
                <FormImageUpload
                  label={
                    <span style={{ marginBottom: '0.5rem' }}>
                      {t('form.field.dietPhoto')}
                    </span>
                  }
                  classes={classes}
                  stateName="clientImage"
                  getImage={getImage}
                  removeImage={removeImage}
                  previewUrl={state.clientImageUrl}
                />
              </GridItem>
              <GridItem style={{ textAlign: 'center' }} xs={12} lg={6}>
                <FormLabel
                  style={{ marginTop: '10px' }}
                  className={classes.labelHorizontal}
                >
                  {t('form.field.dietIcon')}
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('form.dietIconInfo1')}</h4>
                          <h4>{t('form.dietIconInfo2')}</h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </FormLabel>
                <FormImageUpload
                  buttonText={t('form.field.selectIcon')}
                  isIcon
                  classes={classes}
                  stateName="mainImage"
                  getImage={getImage}
                  removeImage={removeImage}
                  previewUrl={state.mainImageUrl}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  connect(({ Diets: { diets }, Variants: { variants } }) => ({
    diets,
    variants,
  })),
  withTranslation(),
  withStyles(combinedStyles)
);

export default enhance(ClientsPanelInterface);
