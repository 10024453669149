import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ListElement = styled.li`
  display: flex;
  justify-content: space-between;
`;

const OldPrice = styled.span`
  color: red;
  text-decoration: line-through;
  margin-left: auto;
  margin-right: 0.5rem;
`;

const NewPriceModalContent = ({ currency, ...newPriceConfirmData }) => {
  const { t } = useTranslation();

  const pricePartNames = {
    ADDON: t('$*orderFormTabs.addons', '$$Dodatki'),
    DIET: t('clients.diet', '$$Dieta'),
    ZONE: t('$*basket.labelDelivery', '$$Dostawa'),
    CHANGE_MENU: t('$*orderFormTabs.premium', '$$Zmiana menu'),
    ECO_CONTAINER: t('orders.ecoBoxes', '$$Eko opakowania'),
    PICK_UP_POINT: t('orders.pickupPoint', '$$Punkt odbioru'),
  };

  const {
    newPrice: { priceParts: newPriceParts },
    price: { priceParts },
  } = newPriceConfirmData;

  const changes = Object.keys({ ...newPriceParts, ...priceParts })
    .map(key => {
      return {
        oldPrice: priceParts[key],
        newPrice: newPriceParts[key],
        name: pricePartNames[key],
      };
    })
    .filter(({ oldPrice, newPrice }) => oldPrice !== newPrice);

  return (
    <>
      {t(
        '$*common.changesWillChangePrice',
        '$$Wybrane zmiany wiążą się ze zmianą ceny!'
      )}
      <ul>
        {changes.map(({ oldPrice, newPrice, name }) => {
          return (
            <ListElement>
              <span>{name}</span>
              {oldPrice && (
                <OldPrice>
                  {oldPrice.toFixed(2)} {currency}{' '}
                  {!newPrice && `/ ${t('brandCfg.day')}`}
                </OldPrice>
              )}

              {newPrice && (
                <span>
                  {oldPrice === undefined && '+'}
                  {newPrice.toFixed(2)} {currency} / {t('brandCfg.day')}
                </span>
              )}
            </ListElement>
          );
        })}
      </ul>
    </>
  );
};

export default connect(
  ({
    Brands: {
      brand: {
        multinational: { currency },
      },
    },
  }) => ({ currency }),
  null
)(NewPriceModalContent);
