import styled from 'styled-components';

const AvailableSoonOverlay = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }

  &:after {
    content: 'dostępne wkrótce';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    color: #d3d3d3;
    font-size: 25px;
    font-weight: bold;
    z-index: 3;
  }
`;

export default AvailableSoonOverlay;
