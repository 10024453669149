import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { FormTextInput } from 'components';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';

const AuthServicesModule = ({
  classes,
  handleModuleUpdate,
  module,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'AuthServicesModule',
        })}
      </div>
    );
  }

  return (
    <>
      <Card>
        <CardBody>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.facebook.enabled}
                onChange={e => {
                  handleModuleUpdate({
                    ...module,
                    facebook: {
                      ...module.facebook,
                      enabled: !module.facebook.enabled,
                    },
                  });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t(
              'brands.newBrandForm.viaFacebook',
              'Logowanie przez Facebook'
            )}
          />
          <FormTextInput
            label="Facebook APP ID"
            classes={classes}
            name="facebookAppId"
            value={module.facebook.id}
            maxLength={128}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                facebook: { ...module.facebook, id: e.target.value },
              })
            }
            inputSize={6}
          />
          <FormTextInput
            label="Facebook secret key"
            classes={classes}
            name="facebookAppSecret"
            value={module.facebook.secret}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                facebook: { ...module.facebook, secret: e.target.value },
              })
            }
            inputSize={6}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.google.enabled}
                onChange={e => {
                  handleModuleUpdate({
                    ...module,
                    google: {
                      ...module.google,
                      enabled: !module.google.enabled,
                    },
                  });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t('brands.newBrandForm.viaGoogle', 'Logowanie przez Google')}
          />
          <FormTextInput
            label="Google CLIENT ID"
            classes={classes}
            name="googleClientId"
            value={module.google.id}
            maxLength={128}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                google: { ...module.google, id: e.target.value },
              })
            }
            inputSize={6}
          />
          <FormTextInput
            label="Google SECRET KEY"
            classes={classes}
            name="googleClientSecret"
            value={module.google.secret}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                google: { ...module.google, secret: e.target.value },
              })
            }
            inputSize={6}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.apple.enabled}
                onChange={e => {
                  handleModuleUpdate({
                    ...module,
                    apple: {
                      ...module.apple,
                      enabled: !module.apple.enabled,
                    },
                  });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t('brands.newBrandForm.viaApple', 'Logowanie przez Apple')}
          />
          <FormTextInput
            label="Apple TEAM ID"
            classes={classes}
            name="appleTeamId"
            value={module.apple.teamId}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apple: { ...module.apple, teamId: e.target.value },
              })
            }
            inputSize={6}
          />
          <FormTextInput
            label="Apple CLIENT ID"
            classes={classes}
            name="appleClientId"
            value={module.apple.clientId}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apple: { ...module.apple, clientId: e.target.value },
              })
            }
            inputSize={6}
          />
          <FormTextInput
            label="Apple KEY FILE ID"
            classes={classes}
            name="appleKeyFileId"
            value={module.apple.keyFileId}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apple: { ...module.apple, keyFileId: e.target.value },
              })
            }
            inputSize={6}
          />
          <FormTextInput
            label="Apple KEY FILE CONTENT"
            classes={classes}
            name="appleKeyFileContent"
            value={module.apple.keyFileContent}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apple: { ...module.apple, keyFileContent: e.target.value },
              })
            }
            maxLength={1000}
            rows={5}
            inputSize={6}
            multiline
          />
        </CardBody>
      </Card>

      <FormControlButtons
        classes={classes}
        submitText={t('common.shared.save', 'Zapisz')}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default AuthServicesModule;
