import BasketItemDiet from './BasketItemDiet'
import BasketItemAddon from './BasketItemAddon'
import BasketItemDish from './BasketItemDish'
import BasketItemDayModification from './BasketItemDayModification'

export default {
  BasketItemDiet,
  BasketItemAddon,
  BasketItemDish,
  BasketItemDayModification,
}
