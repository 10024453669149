import React from 'react';
import { connect } from 'react-redux';
import AdminTable from 'layouts/AdminTable';
import Moment from 'moment';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import ButtonSwitch from 'components/ButtonSwitch/ButtonSwitch';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { fetchMealTypes } from 'actions/MealTypes';
import { fetchVariants } from 'actions/Variants';
import { fetchDiets } from 'actions/Diets';
import { fetchBrand } from 'actions/Brands';
import Datetime from 'react-datetime';
import { get, post, remove } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';
import extendedGridStyle from 'assets/jss/material-dashboard-pro-react/views/extendedGridStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchMenuTemplate, fetchMenuTemplates } from 'actions/MenuTemplates';
import { withToast } from 'material-ui-toast-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import DataGrid from 'components/DataGrid/DataGrid';
import columnsConfig from './columnsConfig.js';
import Variant from './Variant';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import ActionMenu from './components/ActionMenu';
import { withTranslation } from 'react-i18next';
import SelectInput from '../../../components/FormSelect/SelectInput';
import './style.css';
import { DialogLoader } from '../../../components/DialogLoader';

class Planner extends React.Component {
  state = {
    date: new Moment(),
    loading: true,
    mounted: false,
    menuPlanners: [],
    canAssignTo: [],
    viewVariants: [],
    selectedDish: null,
    isAssigning: false,
    alertOpen: false,
    showDishStats: false,
    multipleVariantPlaningEnabled: true,
    showPublishedPlanning: true,
    viewMode: 'PUBLISHED',
    refreshGrid: false,
    pickedVariantDiets: {},
    buttonDisabled: false,
    isLoading: false,
  };

  async componentDidMount() {
    const {
      fetchDiets,
      fetchBrand,
      selectedBrand,
      fetchVariants,
      fetchMealTypes,
      loadAllVariantsInitially,
    } = this.props;

    await Promise.all([
      fetchMealTypes(),
      fetchVariants(),
      fetchDiets(),
      fetchBrand(selectedBrand),
      get('/tags', { pagination: false }).then(response => {
        let result = response['hydra:member'];
        this.setState({ tags: result });
      }),
    ]);

    if (typeof this.props.match.params.date !== 'undefined') {
      await this.setState(prevState => ({
        ...prevState,
        date: new Moment(this.props.match.params.date),
      }));
    }

    const pickedVariantDiets = {};
    const viewVariants = this.props.variants.map(el => {
      pickedVariantDiets[el['@id']] = el.diets;
      return el['@id'];
    });

    this.setState(prevState => ({
      ...prevState,
      viewVariants: loadAllVariantsInitially ? viewVariants : [],
      pickedVariantDiets,
      mounted: true,
    }));

    await this.fetchMenuPlanners();
  }

  async modifyDate(add = true, date) {
    if (!add && date) {
      await this.setState({
        date: date,
      });
      this.props.match.params.date = this.state.date.format('YYYY-MM-DD');
      this.refreshGrid();
      this.fetchMenuPlanners();
      return this.props.history.push(
        `/admin/menu-planner/${this.state.date.format('YYYY-MM-DD')}`
      );
    }

    await this.setState(prevState => {
      return {
        ...prevState,
        date:
          add === true
            ? this.getNextValidDate(prevState.date.add('days', 1))
            : this.getPrevValidDate(prevState.date.add('days', -1)),
      };
    });

    this.props.match.params.date = this.state.date.format('YYYY-MM-DD');

    this.props.history.push(
      `/admin/menu-planner/${this.state.date.format('YYYY-MM-DD')}`
    );

    this.refreshGrid();
    await this.fetchMenuPlanners();
  }

  refreshGrid = () =>
    this.setState(prevState => ({
      ...prevState,
      refreshGrid: !prevState.refreshGrid,
    }));

  fetchMenuPlanners() {
    return new Promise(async (res, rej) => {
      this.setState(prevState => {
        return {
          ...prevState,
          loading: true,
        };
      });
      const date = this.state.date.format('YYYY-MM-DD');
      const response = await get('/menu-planners', {
        pagination: false,
        'date[after]': date,
        'date[before]': date,
      });

      this.setState(prevState => {
        return {
          ...prevState,
          menuPlanners: response['hydra:member'].filter(
            menuPlanner => menuPlanner !== null
          ),
          loading: false,
        };
      });

      this.props.fetchMenuTemplates(!this.state.mounted);
      this.props.fetchMenuTemplate(this.state.date.format('YYYY-MM-DD'));

      res({});
    });
  }

  goToSummary = () => {
    this.props.history.push(
      `/admin/menu-summary/${this.state.date.format('YYYY-MM-DD')}`
    );
  };

  scheduleRandomly = async () => {
    const date = this.state.date.format('YYYY-MM-DD');
    this.setState({ buttonDisabled: true, isLoading: true });

    try {
      const response = await post('/admin/menu-planner/auto-fill', {
        date,
      });

      if (response === 'OK') {
        this.fetchMenuPlanners();
      }
    } catch (err) {
      return this.props.openToast({
        messages: [
          this.props.t('menuPlanner.errors.somethingGoWrong'),
          this.props.t('menuPlanner.errors.tryLater'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    this.setState({ buttonDisabled: false, isLoading: false });
  };

  handleCodeChange = (code, menuPlanner) => {
    let oldMenuPlanners = this.state.menuPlanners;

    let changedMenuPlanner = oldMenuPlanners.find(planner => {
      return planner['@id'] === menuPlanner;
    });

    changedMenuPlanner.reportCode = code;

    this.setState(prev => ({
      ...prev,
      menuPlanners: oldMenuPlanners,
    }));
  };

  trPropsCallback = (state, rowInfo, column, gridComponent) => {
    let options = {
      onClick: (e, handleOriginal) => {
        if (typeof rowInfo !== 'undefined') {
          this.setState(prevState => {
            return {
              ...prevState,
              selectedDish:
                rowInfo.original['@id'] === this.state.selectedDish
                  ? null
                  : rowInfo.original['@id'],
              canAssignTo:
                rowInfo.original['@id'] === this.state.selectedDish
                  ? []
                  : rowInfo.original.types.map(el => el['@id']),
            };
          });
        }
        if (handleOriginal) {
          handleOriginal();
        }
        gridComponent.forceUpdate();
      },
      style: {
        background: 'white',
        color: 'black',
      },
    };

    if (
      typeof rowInfo !== 'undefined' &&
      this.state.selectedDish === rowInfo.original['@id']
    ) {
      options.style.background = '#00d3ee';
      options.style.color = '#fff';
    }

    return options;
  };

  afterFetchData = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        selectedDish: null,
      };
    });
  };

  onAssignMenu = data => {
    if (this.state.isAssigning) {
      return;
    }

    data.date = this.state.date.format('YYYY-MM-DD');

    const config = this.state.multipleVariantPlaningEnabled
      ? { params: { multipleVariant: true } }
      : {};

    this.setState({ isAssigning: true });
    post('/menu-planners', data, config).then(response => {
      this.setState(prevState => ({ ...prevState, isAssigning: false }));
      this.fetchMenuPlanners();
    });
  };

  onMenuPlannerUpdated = () => {
    this.fetchMenuPlanners();
  };

  onRemove = menuPlannerId => {
    return new Promise(async (res, rej) => {
      const menuPlanner = this.state.menuPlanners.find(
        el => el['@id'] === menuPlannerId
      );

      if (menuPlanner.draft === true) {
        const config = this.state.multipleVariantPlaningEnabled
          ? { params: { multipleVariant: true } }
          : {};

        remove(menuPlannerId, config).then(async () => {
          await this.fetchMenuPlanners();
          res({});
        });
      } else {
        this.setState(prevState => ({
          ...prevState,
          menuPlanners: this.state.menuPlanners.filter(
            el => el['@id'] !== menuPlannerId
          ),
        }));
        res({});
      }
    });
  };

  publish = () => {
    if (this.state.isAssigning) {
      return;
    }

    post('/menu-planner/publish', {
      date: this.state.date.format('YYYY-MM-DD'),
    }).then(() => {
      this.fetchMenuPlanners();
      this.setState(prevState => ({
        ...prevState,
        viewMode: 'PUBLISHED',
      }));
    });
  };

  getNextValidDate = date => {
    if (this.isValidDate(date)) {
      return date;
    }

    return this.getNextValidDate(date.add('day', 1));
  };

  getPrevValidDate = date => {
    if (this.isValidDate(date)) {
      return date;
    }

    return this.getPrevValidDate(date.add('day', -1));
  };

  isValidDate = (currentDate, s) => {
    const key = currentDate.locale('en').format('dddd').toLowerCase();

    return this.props.brand[key] !== 0;
  };

  manipulateQuery = (requestData, query) => {
    if (typeof query['sizes.ingredients.ingredient.name'] !== 'undefined') {
      query['sizes.ingredients.recipe.clientName'] =
        query['sizes.ingredients.ingredient.name'];
    }

    // value added here to extract value from allergens, its a bug with symfony serializer, it dont know about nested properties when custom method is serialized
    query['properties'] = [
      'id',
      'nameForClient',
      'workingName',
      'lastUsedDate',
      'lastUsedDaysAgo',
      'rateAVG',
      'value',
      'menuPlannerInfo',
      'latestUsage',
      'featureUsage',
    ];
    query['properties[tags]'] = 'value';
    query['properties[allergens]'] = ['value'];
    query['properties[servingSuggestion]'] = ['value'];
    query['properties[types]'] = ['name'];
    query['properties[components][recipe]'] = ['workName'];
    query['properties[components][ingredient]'] = ['name'];
    query['lookingFromDate'] = this.state.date.format('YYYY-MM-DD');

    return query;
  };

  onFinishAssign = () => {
    return this.fetchMenuPlanners();
  };

  hideAlert = () => {
    this.setState({
      alertOpen: false,
    });
  };

  confirmAutomaticPlan = () => {
    this.setState({ alertOpen: true });
  };

  variantMenuPlanners = variant => {
    return this.state.menuPlanners.filter(
      menuPlanner => menuPlanner.variant['@id'] === variant['@id']
    );
  };

  toggleShowDishStats = () =>
    this.setState(prevState => ({
      ...prevState,
      showDishStats: !prevState.showDishStats,
    }));

  toggleMultipleVariantPlanning = () =>
    this.setState(prevState => ({
      ...prevState,
      multipleVariantPlaningEnabled: !prevState.multipleVariantPlaningEnabled,
    }));

  toggleShowPublishedPlanning = () =>
    this.setState(prevState => ({
      ...prevState,
      showPublishedPlanning: !prevState.showPublishedPlanning,
    }));

  customActions = [
    {
      name: this.props.t('menuPlanner.variants.selectAll'),
      '@id': '_SELECT_ALL',
      onSelect: () => {
        const variants =
          this.props.variants.length === this.state.viewVariants.length
            ? []
            : this.props.variants.map(el => el['@id']);
        this.setState(prevState => ({
          ...prevState,
          viewVariants: variants,
        }));
      },
    },
  ];

  customVariantDietsAction = [
    {
      name: this.props.t('menuPlanner.variants.selectAll'),
      '@id': '_SELECT_ALL',
      onSelect: variant => {
        const diets = this.props.diets.filter(diet =>
          diet.variants.includes(variant)
        );
        const equalNumberOfDiets =
          this.state.pickedVariantDiets[variant].length === diets.length;
        const dietsToBeSet = equalNumberOfDiets
          ? []
          : diets.map(diet => diet['@id']);

        this.setState(prevState => ({
          pickedVariantDiets: {
            ...prevState.pickedVariantDiets,
            [variant]: dietsToBeSet,
          },
        }));
      },
    },
  ];

  getViewVariants = () =>
    this.props.variants.filter(el =>
      this.state.viewVariants.includes(el['@id'])
    );

  variantOptions = () => {
    return [...this.customActions].concat(this.props.variants);
  };

  handleChangeVariants = ({ target: { value } }) => {
    const customActions = this.customActions.map(el => el['@id']);
    const containsCustomActions = value.filter(el =>
      customActions.includes(el)
    );

    if (containsCustomActions.length > 0) {
      this.customActions
        .find(el => el['@id'] === containsCustomActions[0])
        .onSelect();
    } else {
      this.setState(prevState => ({
        ...prevState,
        viewVariants: value,
      }));
    }
  };

  variantDiets = variant => {
    return this.customVariantDietsAction.concat(
      this.props.diets.filter(diet => variant.diets.includes(diet['@id']))
    );
  };

  handleChangeVariantDiets = ({ target: { value } }, variant) => {
    const containsCustomAction = value.filter(
      el => el === this.customVariantDietsAction[0]['@id']
    );

    if (containsCustomAction.length > 0) {
      this.customVariantDietsAction[0].onSelect(variant);
    } else {
      this.setState(prevState => ({
        pickedVariantDiets: {
          ...prevState.pickedVariantDiets,
          [variant]: value,
        },
      }));
    }
  };

  render() {
    const { mealTypes } = this.props;
    const { classes } = this.props;

    if (!this.state.mounted) {
      return null;
    }

    const selectedLanguage = this.props.i18n.language;
    return (
      <>
        <DialogLoader
          loaderState={this.state.isLoading}
          text={this.props.t('common.loader')}
        />
        <AdminTable title={this.props.t('common.mainMenu.menuPlanner')} icon>
          <GridContainer>
            <GridItem xs={12} className={'menu-planner-datagrid'}>
              <DataGrid
                title={this.props.t('menuPlanner.dishesGrid.title')}
                url="/dishes-for-planning"
                reportName={'dish'}
                columns={columnsConfig({
                  t: this.props.t,
                  tags: this.state.tags,
                  displayedDate: this.state.date,
                })}
                showColumnFilter
                disableExport={true}
                customDefaultPageSize={3}
                disableTotal={true}
                getTrProps={this.trPropsCallback}
                afterFetchData={this.afterFetchData}
                manipulateQuery={this.manipulateQuery}
                refresh={this.state.refreshGrid}
                minRows={3}
                extractFiltersByComma={false}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} style={{ display: 'flex' }}>
              <Button
                color="info"
                onClick={() => this.modifyDate(false)}
                disabled={this.state.loading}
                style={{ flex: 1, padding: 8 }}
              >
                <KeyboardArrowLeft /> {this.props.t('menuPlanner.prevDay')}
              </Button>
              {!this.state.loading && (
                <Datetime
                  className={'menuPlannerPicker'}
                  locale={selectedLanguage}
                  timeFormat={false}
                  value={this.state.date}
                  onChange={ev => this.modifyDate(false, ev)}
                  isValidDate={this.isValidDate}
                  style={{ flex: 1 }}
                />
              )}
              {this.state.loading && (
                <div style={{ flex: 1, textAlign: 'center', maxWidth: 170 }}>
                  <CircularProgress />
                </div>
              )}
              <Button
                color="info"
                onClick={() => this.modifyDate(true)}
                disabled={this.state.loading}
                style={{ flex: 1, padding: 8 }}
              >
                {this.props.t('menuPlanner.nextDay')}
                <KeyboardArrowRight />
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} style={{ textAlign: 'center', display: 'flex' }}>
              <ButtonSwitch
                options={[
                  {
                    label: this.props.t('menuPlanner.draftView'),
                    value: 'DRAFT',
                  },
                  {
                    label: this.props.t('menuPlanner.publishedView'),
                    value: 'PUBLISHED',
                  },
                ]}
                value={this.state.viewMode}
                onChange={value =>
                  this.setState(prev => ({ ...prev, viewMode: value }))
                }
              />
            </GridItem>
            <GridItem xs={12} style={{ textAlign: 'center', display: 'flex' }}>
              <SelectInput
                classes={this.props.classes}
                multiple={true}
                label={this.props.t('menuPlanner.variants.label')}
                mapBy="name"
                trackBy="@id"
                value={this.state.viewVariants}
                options={this.variantOptions()}
                handleChange={this.handleChangeVariants}
                id="viewVariants"
                size={12}
              />
            </GridItem>
          </GridContainer>
          {this.getViewVariants().map((variant, key) => (
            <>
              <GridContainer>
                <GridItem
                  xs={12}
                  style={{ textAlign: 'center', display: 'flex' }}
                >
                  <SelectInput
                    classes={this.props.classes}
                    multiple={true}
                    label={this.props.t('menuPlanner.variants.diets.label')}
                    mapBy="name"
                    trackBy="@id"
                    value={this.state.pickedVariantDiets[variant['@id']]}
                    options={this.variantDiets(variant)}
                    handleChange={e =>
                      this.handleChangeVariantDiets(e, variant['@id'])
                    }
                    id="viewVariants"
                    size={12}
                  />
                </GridItem>
              </GridContainer>

              <Variant
                key={key}
                variant={variant}
                mealTypes={mealTypes}
                diets={this.variantDiets(variant).filter(v => {
                  return this.state.pickedVariantDiets[variant['@id']].includes(
                    v['@id']
                  );
                })}
                menuPlanners={this.variantMenuPlanners(variant)}
                date={this.state.date}
                dish={this.state.selectedDish}
                isAssigning={this.state.isAssigning || this.state.loading}
                canAssignTo={this.state.canAssignTo}
                viewMode={this.state.viewMode}
                showDishStats={this.state.showDishStats}
                onAssignMenu={this.onAssignMenu}
                onRemove={this.onRemove}
                onMenuPlannerUpdated={this.onMenuPlannerUpdated}
                showPublishedPlanning={this.state.showPublishedPlanning}
              />
            </>
          ))}

          <Dialog fullWidth={true} open={this.state.alertOpen}>
            <SweetAlert
              warning
              style={{ display: 'block' }}
              title={this.props.t('menuPlanner.planner.randomPlanConfirmation')}
              onConfirm={async () => {
                await this.scheduleRandomly();
                this.hideAlert();
              }}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
              cancelBtnCssClass={`${classes.button} ${classes.danger}`}
              confirmBtnText={this.props.t('common.shared.yes')}
              cancelBtnText={this.props.t('common.shared.no')}
              showCancel
              disabled={this.state.buttonDisabled}
            />
          </Dialog>
          <div
            className={'sticked-panel'}
            style={{ position: 'sticky', bottom: 0, zIndex: 10 }}
          >
            <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
              <ActionMenu
                variants={this.props.variants}
                diets={this.props.diets}
                menuPlanners={this.state.menuPlanners}
                date={this.state.date.format('YYYY-MM-DD')}
                viewMode={this.state.viewMode}
                loading={this.state.loading}
                onRefresh={() => {this.fetchMenuPlanners()}}
                onAutomaticPlan={this.confirmAutomaticPlan}
                onPublish={this.publish}
                onGoToSummary={this.goToSummary}
                onFinishAssignMenu={this.onFinishAssign}
                onRemovePlanning={this.onRemove}
                showDishStats={this.state.showDishStats}
                multipleVariantPlaningEnabled={
                  this.state.multipleVariantPlaningEnabled
                }
                showPublishedPlanning={this.state.showPublishedPlanning}
                onToggleStats={this.toggleShowDishStats}
                onToggleMultipleVariantPlanning={
                  this.toggleMultipleVariantPlanning
                }
                onToggleShowPublishedPlanning={this.toggleShowPublishedPlanning}
              />
            </div>
          </div>
        </AdminTable>
      </>
    );
  }
}

const combinedStyles = combineStyles(
  buttonsStyle,
  extendedGridStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    variants: state.Variants.variants,
    mealTypes: state.MealTypes.mealTypes,
    diets: state.Diets.diets,
    brand: state.Brands.brand,
    selectedBrand: state.Auth.selectedBrand,
    menuTemplate: state.MenuTemplates.menuTemplate,
    loadAllVariantsInitially:
      state?.Company?.dietarySetup?.loadAllVariantsInitially,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuTemplates: (force = false) => dispatch(fetchMenuTemplates(force)),
  fetchMenuTemplate: date => dispatch(fetchMenuTemplate(date)),
  fetchMealTypes: () => dispatch(fetchMealTypes()),
  fetchVariants: () => dispatch(fetchVariants()),
  fetchDiets: () => dispatch(fetchDiets()),
  fetchBrand: id => dispatch(fetchBrand(id)),
});

export default withTranslation()(
  withStyles(combinedStyles)(
    connect(mapStateToProps, mapDispatchToProps)(withToast(Planner))
  )
);
