import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import translateColumnConfig from './columnConfig';
import { isEmpty, isGranted } from 'helpers/helpers';
import {
  ROLE_CREATE_IMPORTER_TASK,
  ROLE_SHOW_IMPORTER_TASK,
  ROLE_CREATE_USER,
  ROLE_IMPERSONATE_USER,
  ROLE_IMPORT_USER,
} from 'helpers/roles';
import Button from 'components/CustomButtons/Button';
import InfoModal from 'components/InfoModal/InfoModal';
import MediaUpload from 'components/MediaUpload/MediaUpload';
import importCSVExample from './import-csv-example.csv';
import importCSVExampleFI from './import-csv-example-fi.csv';
import importCSVExampleEN from './import-csv-example-en.csv';
import moneyboxCSVExample from './import-points-example.xlsx';
import moneyboxCSVExampleEN from './import-points-example-en.xlsx';
import moneyboxCSVExampleFI from './import-points-example-fi.xlsx';
import gastro from 'helpers/gastro';
import { post } from 'helpers/apiHelpers';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchBrandsList } from 'actions/Brands';
import { fetchSexes } from 'actions/Dictionary';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';

class List extends Component {
  state = {
    openedModal: false,
    file: null,
    isImporting: false,
    importButtonClicked: false,
    showMoneyboxModal: false,
    moneyboxFile: null,
  };

  componentDidMount() {
    this.props.fetchBrandsList();
    this.props.fetchSexes();
  }

  newSize = () => this.props.history.push(`${window.location.pathname}/add`);

  toggleModal = () => {
    this.setState(prevState => ({
      openedModal: !prevState.openedModal,
    }));
  };

  toggleMoneyboxModal = () => {
    this.setState(prevState => ({
      showMoneyboxModal: !prevState.showMoneyboxModal,
    }));
  };

  importClients = () => {
    this.setState({ importButtonClicked: true });
    this.uploadFile(this.state.file).then(file => {
      this.postImporterTask(file);
    });
  };

  importMoneyboxState = () => {
    this.setState({ importButtonClicked: true });
    this.uploadFile(this.state.moneyboxFile).then(file => {
      this.postMoneyboxImportTask(file);
    });
  };

  postMoneyboxImportTask = file => {
    this.setState({ isImporting: true });

    const data = {
      type: 'MONEY_BOX_CHANGE',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      res => {
        this.setState({
          showMoneyboxModal: false,
          moneyboxFile: null,
          importButtonClicked: false,
        });
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [
            this.props.t('clients.moneyboxImportAdded'),
            this.props.t('clients.refreshPage'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [this.props.t('clients.moneyboxCannotImport')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  postImporterTask = file => {
    this.setState({ isImporting: true });
    const data = {
      type: 'CLIENTS',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      res => {
        this.setState({
          openedModal: false,
          file: null,
          importButtonClicked: false,
        });
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [
            this.props.t('clients.importAdded'),
            this.props.t('clients.refreshPage'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [this.props.t('clients.cannotImport')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  uploadFile = async file => {
    let data = new FormData();
    data.append('file', file);

    let response = await gastro.post('/media', data);

    return new Promise((resolve, reject) => {
      resolve(response.data);
    });
  };

  getExampleImportFile(language) {
    switch (language) {
      case 'fi':
        return importCSVExampleFI;
      case 'en':
        return importCSVExampleEN;
      default:
        return importCSVExample;
    }
  }

  getExampleMoneyboxImportFile(language) {
    switch (language) {
      case 'fi':
        return moneyboxCSVExampleFI;
      case 'en':
        return moneyboxCSVExampleEN;
      default:
        return moneyboxCSVExample;
    }
  }

  render() {
    const {
      classes,
      auth: { token, refreshToken },
      t,
      i18n: { language },
    } = this.props;

    const customActions = () => {
      return [
        {
          component: ({ row }) => {
            if (!isGranted(ROLE_IMPERSONATE_USER) || isEmpty(row.homeDomain)) {
              return null;
            }

            return (
              <Button
                simple={true}
                round={true}
                style={{
                  margin: '10px 1px 10px 5px',
                  padding: '0',
                  width: '20px',
                }}
              >
                <a
                  href={`https://${row.homeDomain}/auth/switch?token=${token}&refreshToken=${refreshToken}&email=${row.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SupervisedUserCircleIcon />
                </a>
              </Button>
            );
          },
          type: 'customComponent',
        },
      ];
    };
    return (
      <AdminTable title={t('clients.clientsList')} icon>
        <InfoModal
          modalState={this.state.openedModal}
          handleClose={() => this.toggleModal}
          modalContent={
            <div>
              <h1>{t('clients.clientsImport')}</h1>
              <p style={{ textAlign: 'left' }}>
                <ul
                  dangerouslySetInnerHTML={{
                    __html: t('clients.importerModalInfo', {
                      importCSVExample: this.getExampleImportFile(language),
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              </p>
              <MediaUpload
                file={this.state}
                setFile={file => this.setState({ file: file })}
                buttonText={t('clients.selectCSVFile')}
                addButtonProps={{
                  color: 'info',
                  round: false,
                }}
                changeButtonProps={{
                  color: 'info',
                  round: false,
                }}
                removeButtonProps={{
                  color: 'danger',
                  round: false,
                }}
              />
              <div>
                {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                  isGranted(ROLE_IMPORT_USER) && (
                    <Button
                      color={this.state.file ? 'success' : 'default'}
                      disabled={
                        !this.state.file ||
                        this.state.isImporting ||
                        this.state.importButtonClicked
                      }
                      onClick={this.importClients}
                    >
                      {t('clients.import')}
                    </Button>
                  )}
                <Button onClick={this.toggleModal}>{t('clients.close')}</Button>
              </div>
            </div>
          }
          closeButtonText={t('clients.close')}
          hideDefaultCloseButton
        />

        <InfoModal
          modalState={this.state.showMoneyboxModal}
          handleClose={() => this.toggleMoneyboxModal}
          modalContent={
            <div>
              <h1>{t('clients.moneyboxModalHeader')}</h1>
              <p style={{ textAlign: 'left' }}>
                <ol
                  dangerouslySetInnerHTML={{
                    __html: t('clients.moneyboxModal', {
                      moneyboxCSVExample: this.getExampleMoneyboxImportFile(
                        language
                      ),
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              </p>
              <MediaUpload
                file={this.state}
                setFile={file => this.setState({ moneyboxFile: file })}
                buttonText={t('clients.selectCSVFile')}
                addButtonProps={{
                  color: 'info',
                  round: false,
                }}
                changeButtonProps={{
                  color: 'info',
                  round: false,
                }}
                removeButtonProps={{
                  color: 'danger',
                  round: false,
                }}
              />
              <div>
                {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                  isGranted(ROLE_IMPORT_USER) && (
                    <Button
                      color={this.state.moneyboxFile ? 'success' : 'default'}
                      disabled={
                        !this.state.moneyboxFile ||
                        this.state.isImporting ||
                        this.state.importButtonClicked
                      }
                      onClick={this.importMoneyboxState}
                    >
                      {t('clients.import')}
                    </Button>
                  )}
                <Button onClick={this.toggleMoneyboxModal}>
                  {t('clients.close')}
                </Button>
              </div>
            </div>
          }
          closeButtonText={t('clients.close')}
          hideDefaultCloseButton
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {isGranted(ROLE_SHOW_IMPORTER_TASK) && isGranted(ROLE_IMPORT_USER) && (
            <Button
              style={{ margin: '5px', width: '158px' }}
              color={'info'}
              onClick={this.toggleModal}
            >
              {t('clients.importClients')}
            </Button>
          )}
          {isGranted(ROLE_SHOW_IMPORTER_TASK) && isGranted(ROLE_IMPORT_USER) && (
            <Button
              style={{ margin: '5px', width: '300px' }}
              color={'info'}
              onClick={this.toggleMoneyboxModal}
            >
              {t('clients.moneyboxModalHeader')}
            </Button>
          )}
          {isGranted(ROLE_CREATE_USER) && (
            <FormControlButtons
              classes={classes}
              handleSubmit={this.newSize}
              submitText={this.props.t('clients.addClient') + ' +'}
            />
          )}
        </div>
        <DataGrid
          refreshOnFetch
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/clients"
          reportName={'client'}
          columns={translateColumnConfig(t, this.props.sexes)}
          role="USER"
          customActions={customActions}
          customActionsWidth={150}
          customActionsColumnDirection={'actions-left'}
          canRemoveChecker={(canRemove, row) => {
            return canRemove && !row.hasActiveDiets;
          }}
          manipulateQuery={(requestData, query) => {
            if (query.gender) {
              query['gender[]'] = query.gender;
              query['gender[_operator]'] = 'eq';
            }
            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;
              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }

            if (query.firstName) {
              const splicedName = query.firstName.split(' ');
              let _andX = [
                [
                  {
                    firstName: splicedName[0],
                    lastName: splicedName[0],
                  },
                ],
              ];

              if (splicedName.length > 1) {
                _andX[0][0].lastName = splicedName[1];
              }

              const operator = splicedName.length > 1 ? '_andX' : '_orX';

              delete query.firstName;
              query[operator] = _andX;
            }

            query.type = 'CLIENT';

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const mapStateToProps = state => ({
  brands: state.Brands.brandsList,
  auth: state.Auth,
  sexes: state.Dictionary.sexes,
});

const mapDispatchToProps = {
  fetchBrandsList,
  fetchSexes,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(buttonsStyle),
  withTranslation()
);

export default enhance(List);
