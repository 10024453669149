import { currencyToSymbol } from 'utils/currencies';

const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: true,
    width: 50,
  },
  {
    title: 'list.workingName',
    name: 'workName',
    accessor: 'workName',
    filterable: true,
  },
  {
    title: 'list.ingredients',
    name: 'ingredients.ingredient.workName',
    accessor: row =>
      row.ingredients
        .map(ingredient => ingredient.ingredient.workName)
        .join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'list.allergens',
    name: 'ingredients.ingredient.allergens.value',
    accessor: row => row.allergens.map(allergen => allergen.value).join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'list.tags',
    name: 'tags.value',
    accessor: row => row.tags.map(tag => tag.value).join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'list.price',
    name: 'price',
    accessor: (row, plug) => {
      return row.price
        ? `${row.price.toFixed(2)} ${currencyToSymbol(
            plug.multinational.currency
          )}`
        : '';
    },
    filterable: false,
    sortable: true,
  },
];

export default columnConfig;
