import { useEffect, useState } from 'react';
import GridItem from '../../../components/Grid/GridItem';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import React, { Fragment } from 'react';
import GridContainer from '../../../components/Grid/GridContainer';
import { useTranslation } from 'react-i18next';
import ClientPanelCalendarIcon from './ClientPanelCalendarIcon';

const StatusIcons = ({
  classes,
  handleModuleUpdate,
  module,
  hasSubscription,
}) => {
  const { t } = useTranslation();
  const [availableIcons, setAvailableIcons] = useState([
    { icon: 'calendarIconSubscriptionAddDelivery', subTitle: false },
    { icon: 'calendarIconDelivered', subTitle: false },
    { icon: 'calendarIconMenuSoon', subTitle: false },
    { icon: 'calendarIconChangeMenu', subTitle: false },
    { icon: 'calendarIconDetails', subTitle: false },
    { icon: 'calendarIconRated', subTitle: false },
    { icon: 'calendarIconRatedPartial', subTitle: true },
    { icon: 'calendarIconCanBeRated', subTitle: true },
  ]);

  useEffect(() => {
    // TODO: change icon names to match the ones from BE
    if (hasSubscription) {
      setAvailableIcons(prevState => [
        ...prevState,
        { icon: 'subscription.mainCalendar.Ordered', subTitle: true },
        { icon: 'subscription.mainCalendar.addDelivery', subTitle: true },
      ]);
    }
  }, []);

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'ConfigClientPanelModule',
        })}
      </div>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12} style={{ marginTop: '40px' }}>
        <h4>{t('brandCfg.statusesLabel')}</h4>
        <Divider style={{ marginBottom: '20px' }} />
      </GridItem>
      <GridItem xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={module.showCalendarIcons}
              onChange={() =>
                handleModuleUpdate({
                  ...module,
                  showCalendarIcons: !module.showCalendarIcons,
                })
              }
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{t('module.configClientPanel.showCalendarIcons')}</span>
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <Fragment>
                      <h4>
                        {t(
                          'module.configClientPanel.icons.calendarIconSizeInfo',
                          {
                            x: '128px',
                            y: '128px',
                          }
                        )}
                      </h4>

                      <h4>
                        {t(
                          'module.configClientPanel.icons.calendarIconWeightInfo',
                          {
                            x: '500 KB',
                          }
                        )}
                      </h4>

                      <h4>
                        {t(
                          'module.configClientPanel.icons.calendarIconContentInfo'
                        )}
                      </h4>
                      <h4>
                        {t(
                          'module.configClientPanel.icons.calendarIconPlacementInfo'
                        )}
                      </h4>
                    </Fragment>
                  }
                  placement="right"
                >
                  <Info
                    fontSize="small"
                    style={{ color: 'grey', display: 'block' }}
                  />
                </Tooltip>
              </div>
            </div>
          }
        />
      </GridItem>

      {availableIcons.map((el, index) => (
        <GridItem sm={12} md={6}>
          <ClientPanelCalendarIcon
            module={module}
            handleModuleUpdate={handleModuleUpdate}
            key={index}
            iconKey={el.icon}
            subTitle={el.subTitle}
          />
        </GridItem>
      ))}
    </GridContainer>
  );
};

export default StatusIcons;
