import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Card from '../../components/Card/Card';
import React, { useEffect, useState } from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import navPillsStyle from '../../assets/jss/material-dashboard-pro-react/components/navPillsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import { get, put } from 'helpers/apiHelpers';
import { connect } from 'react-redux';
import { DialogLoader } from '../../components/DialogLoader';

const DietsCmsPage = ({ classes, t, selectedBrand }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [showNextDaysMenu, setShowNextDaysMenu] = useState(false);
  const [showRates, setShowRates] = useState(false);
  const [showOtherDiets, setShowOtherDiets] = useState(false);
  const [showVariants, setShowVariants] = useState(false);

  useEffect(() => {
    (async () => {
      const {
        configuration: { SeoDiet },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoDiet`);

      setEnabled(SeoDiet.enabled);
      setShowNextDaysMenu(SeoDiet.showNextDaysMenu);
      setShowRates(SeoDiet.showRates);
      setShowOtherDiets(SeoDiet.showOtherDiets);
      setShowVariants(SeoDiet.showVariants);
    })();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await put(`brands/${selectedBrand}/modules`, {
        module: 'SeoDiet',
        configuration: {
          SeoDiet: {
            enabled,
            showNextDaysMenu,
            showRates,
            showOtherDiets,
            showVariants,
          },
        },
      });

      console.log(response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('form.basicInfo')}</h1>

              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setEnabled(!enabled)}
                    checked={enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showDietSubpage')}{' '}
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{t('cms.diets.showDietSubpage.tooltip')}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  </div>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('cms.diets.visibilityOptionsHeader')}</h1>
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowNextDaysMenu(!showNextDaysMenu)}
                    checked={showNextDaysMenu}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showUpcomingDaysMenu')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowRates(!showRates)}
                    checked={showRates}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showRatings')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowOtherDiets(!showOtherDiets)}
                    checked={showOtherDiets}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showOtherDiets')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowVariants(!showVariants)}
                    checked={showVariants}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.diets.showVariants')}
                  </div>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <FormControlButtons
        classes={classes}
        submitText={t('form.save')}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  navPillsStyle
);

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(DietsCmsPage);
