export const currenciesMap = {
  PLN: 'zł',
  EUR: '€',
};

export const symbolToCurrency = symbol => {
  let _f = Object.entries(currenciesMap).find(
    ([key, val]) => val.toLowerCase() === symbol.toLowerCase()
  );
  if (_f) return _f[0];
  _f = Object.keys(currenciesMap).find(
    k => k.toLowerCase() === symbol.toLowerCase()
  );
  if (_f) return _f;
  console.warn('symbol unresolved in currencies map', symbol);
  return symbol;
};

export const currencyToSymbol = currency =>
  currenciesMap[currency.toUpperCase().trim()] || currency;
