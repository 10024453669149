import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';

class PostCodeRequests extends Component {
  render() {
    return (
      <AdminTable title={this.props.t('postCodeRequest.list')} icon>
        <DataGrid
          actions={false}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          reportName={'postcoderequest'}
          url="/post-code-requests"
          role="POST_CODE_REQUESTS"
          columns={columnConfig}
          minRows={20}
        />
      </AdminTable>
    );
  }
}

const mapStateToProps = state => ({});

const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(buttonsStyle),
  withTranslation()
);

export default enhance(PostCodeRequests);
