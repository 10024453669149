import React from 'react';
import { applyMenuTemplate } from 'actions/MenuTemplates';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { Publish } from '@material-ui/icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withTranslation } from 'react-i18next';

class PublishMenu extends React.Component {
  state = {
    alert: null,
  };

  setLoading = value =>
    this.setState(prevState => ({ ...prevState, loading: value }));

  confirmModal = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={this.props.t('menuPlanner.action.plan.publish.title')}
          onConfirm={() => {
            this.props.onPublish();
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText={this.props.t('common.shared.yes')}
          cancelBtnText={this.props.t('common.shared.no')}
          showCancel
        />
      ),
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    return (
      <>
        <BottomNavigationAction
          label={this.props.t('menuPlanner.action.plan.publish.actionTitle')}
          showLabel={true}
          icon={<Publish style={{ color: '#46ad58' }} />}
          onClick={this.confirmModal}
        />
        {this.state.alert}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  applyMenuTemplate: (id, date) => dispatch(applyMenuTemplate(id, date)),
});

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(sweetAlertStyle)(withToast(PublishMenu)))
);
