import { dateTimeCell } from '../../components/Grid/Cells/DateTimeCell';
import React from 'react';
import DateFilter from '../../components/Grid/Filter/Date';

const columnConfig = [
  {
    title: 'postCodeRequest.requestedAt',
    accessor: row => dateTimeCell(row.requestedAt, true),
    name: 'requestedAt',
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
  },
  {
    title: 'postCodeRequest.postCode',
    accessor: 'postCode',
    name: 'postCode',
  },
  {
    title: 'postCodeRequest.ip',
    accessor: 'ip',
    name: 'ip',
  },
];

export default columnConfig;
