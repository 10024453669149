const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const ADD_USER_BRAND = 'ADD_USER_BRAND';
const CHANGE_BRAND = 'CHANGE_BRAND';
const REFRESH_LOGIN = 'REFRESH_LOGIN';
const UPDATE_MY_ROLES = 'UPDATE_MY_ROLES';
const UPDATE_MY_LINKS = 'UPDATE_MY_LINKS';

const THEME_SET_MAIN_COLOR = 'THEME_SET_MAIN_COLOR';

const APP_FETCH_REQUEST = 'APP_FETCH_REQUEST';
const APP_FETCH_SUCCESS = 'APP_FETCH_SUCCESS';
const APP_FETCH_FAILURE = 'APP_FETCH_FAILURE';
const APP_FETCH_RESET = 'APP_FETCH_RESET';
const APP_OPEN_SWEET_TOAST = 'APP_OPEN_SWEET_TOAST';
const APP_CLOSE_ALL_TOASTS = 'APP_CLOSE_ALL_TOASTS';
const APP_CLOSE_TOAST = 'APP_CLOSE_TOAST';

const FETCH_INGREDIENTS = 'FETCH_INGREDIENTS';
const FETCH_INGREDIENT_CATEGORIES = 'FETCH_INGREDIENT_CATEGORIES';
const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
const LOADING_INGREDIENTS = 'LOADING_INGREDIENTS';
const FETCH_INGREDIENT = 'FETCH_INGREDIENT';

const FETCH_RECIPES = 'FETCH_RECIPES';
const FETCH_RECIPE_CATEGORIES = 'FETCH_RECIPE_CATEGORIES';
const REMOVE_RECIPE = 'REMOVE_RECIPE';
const LOADING_RECIPES = 'LOADING_RECIPES';
const FETCH_RECIPE = 'FETCH_RECIPE';

const FETCH_MEALSIZES = 'FETCH_MEALSIZES';
const CLEAR_MEALSIZES = 'CLEAR_MEALSIZES';
const FETCH_MEALSIZE = 'FETCH_MEALSIZE';
const REMOVE_MEALSIZE = 'REMOVE_MEALSIZE';

const FETCH_MEALTYPES = 'FETCH_MEALTYPES';
const CLEAR_MEALTYPES = 'CLEAR_MEALTYPES';
const FETCH_MEALTYPE = 'FETCH_MEALTYPE';
const REMOVE_MEALTYPE = 'REMOVE_MEALTYPE';

const FETCH_DIETS = 'FETCH_DIETS';
const CLEAR_DIETS = 'CLEAR_DIETS';
const FETCH_DIET = 'FETCH_DIET';
const REMOVE_DIET = 'REMOVE_DIET';

const FETCH_VARIANTS = 'FETCH_VARIANTS';
const CLEAR_VARIANTS = 'CLEAR_VARIANTS';
const FETCH_VARIANT = 'FETCH_VARIANT';
const REMOVE_VARIANT = 'REMOVE_VARIANT';

const FETCH_ALLERGENS = 'FETCH_ALLERGENS';
const REMOVE_ALLERGEN = 'REMOVE_ALLERGEN';
const LOADING_ALLERGENS = 'LOADING_ALLERGENS';

const FETCH_DELIVERYTYPES = 'FETCH_DELIVERYTYPES';
const REMOVE_DELIVERYTYPE = 'REMOVE_FETCH_DELIVERYTYPE';
const LOADING_DELIVERYTYPES = 'LOADING_DELIVERYTYPES';

const FETCH_DISHES = 'FETCH_DISHES';
const REMOVE_DISH = 'REMOVE_DISH';
const FETCH_DISH = 'FETCH_DISH';

const FETCH_UNITS = 'FETCH_UNITS';
const REMOVE_UNIT = 'REMOVE_UNIT';
const LOADING_UNITS = 'LOADING_UNITS';

const FETCH_PURPOSES = 'FETCH_PURPOSES';
const REMOVE_PURPOSE = 'REMOVE_PURPOSE';
const LOADING_PURPOSES = 'LOADING_PURPOSES';

const FETCH_CONTAINERS = 'FETCH_CONTAINERS';

const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';

const FETCH_BRANDS = 'FETCH_BRANDS';
const FETCH_BRANDSLIST = 'FETCH_BRANDSLIST';
const FETCH_BRAND = 'FETCH_BRAND';
const REMOVE_BRAND = 'REMOVE_BRAND';
const FETCH_BRAND_CONFIG_MODULE = 'FETCH_BRAND_CONFIG_MODULE';
const FETCH_COMPANY = 'FETCH_COMPANY';

const FETCH_ROLES = 'FETCH_ROLES';
const FETCH_ROLE = 'FETCH_ROLE';
const REMOVE_ROLE = 'REMOVE_ROLE';

const FETCH_USERS = 'FETCH_USERS';
const FETCH_USER = 'FETCH_USER';
const CLEAR_USER = 'CLEAR_USER';
const CLEAR_USERS = 'CLEAR_USERS';
const REMOVE_USER = 'REMOVE_USER';

const FETCH_ZONE_CATEGORIES = 'FETCH_ZONE_CATEGORIES';
const FETCH_ZONE_CATEGORY = 'FETCH_ZONE_CATEGORY';
const REMOVE_ZONE_CATEGORY = 'REMOVE_ZONE_CATEGORY';

const FETCH_ZONES = 'FETCH_ZONES';
const FETCH_ZONE = 'FETCH_ZONE';
const REMOVE_ZONE = 'REMOVE_ZONE';

const FETCH_DRIVERS = 'FETCH_DRIVERS';
const CLEAR_DRIVER = 'CLEAR_DRIVERS';
const FETCH_DRIVER = 'FETCH_DRIVER';
const REMOVE_DRIVER = 'REMOVE_DRIVER';

const LOADING_MENU_TEMPLATES = 'LOADING_MENU_TEMPLATES';
const FETCH_MENU_TEMPLATES = 'FETCH_MENU_TEMPLATES';
const FETCH_MENU_TEMPLATE = 'FETCH_MENU_TEMPLATE';
const CREATE_MENU_TEMPLATE = 'CREATE_MENU_TEMPLATE';
const REMOVE_MENU_TEMPLATE = 'REMOVE_MENU_TEMPLATE';
const RESET_MENU_TEMPLATES_STATE = 'RESET_MENU_TEMPLATES_STATE';
const RESET_MENU_TEMPLATE_STATE = 'RESET_MENU_TEMPLATE_STATE';

const FETCH_USERADDRESSES = 'FETCH_USERADDRESSES';
const REMOVE_ADDRESS = 'REMOVE_ADDRESS';

const FETCH_ORDERSTATUSES = 'FETCH_ORDERSTATUSES';
const START_LOADING_ORDER_STATUSES = 'START_LOADING_ORDER_STATUSES';
const FINISH_LOADING_ORDER_STATUSES = 'FINISH_LOADING_ORDER_STATUSES';

const FETCH_SUBBRANDS = 'FETCH_SUBBRANDS';

const FETCH_WHOLESALERS = 'FETCH_WHOLESALERS';

const FETCH_PACKAGE = 'FETCH_PACKAGE';
const FETCH_PACKAGES = 'FETCH_PACKAGES';
const FETCH_PAYMET_TYPES = 'FETCH_PAYMET_TYPES';
const FETCH_COUNTRY_INFORMATIONS = 'FETCH_COUNTRY_INFORMATIONS';

const SET_REGION_FOR_VALIDATIONS = 'SET_REGION_FOR_VALIDATIONS';

const FETCH_SEXES = `dictionary/FETCH_SEXES`;
const FETCH_SEXES_SUCCESS = `dictionary/FETCH_SEXES_SUCCESS`;
const FETCH_SEXES_FAILED = `dictionary/FETCH_SEXES_FAILED`;

const FETCH_HOW_KNOW = `dictionary/FETCH_HOW_KNOW`;
const FETCH_HOW_KNOW_SUCCESS = `dictionary/FETCH_HOW_KNOW_SUCCESS`;
const FETCH_HOW_KNOW_FAILED = `dictionary/FETCH_HOW_KNOW_FAILED`;

const SET_TEMPORARY_LOCK = `auth/SET_TEMPORARY_LOCK`;

const constants = {
  //GLOBAL
  CHANGE_BRAND,
  REFRESH_LOGIN,

  // THEME
  THEME_SET_MAIN_COLOR,

  //APP
  APP_FETCH_REQUEST,
  APP_FETCH_SUCCESS,
  APP_FETCH_FAILURE,
  APP_FETCH_RESET,
  APP_OPEN_SWEET_TOAST,
  APP_CLOSE_ALL_TOASTS,
  APP_CLOSE_TOAST,

  //LOGIN
  LOGIN,
  LOGOUT,
  UPDATE_MY_ROLES,
  UPDATE_MY_LINKS,

  //Ingredients
  FETCH_INGREDIENTS,
  FETCH_INGREDIENT_CATEGORIES,
  FETCH_INGREDIENT,
  REMOVE_INGREDIENT,

  LOADING_INGREDIENTS,

  FETCH_RECIPES,
  FETCH_RECIPE_CATEGORIES,
  REMOVE_RECIPE,
  LOADING_RECIPES,
  FETCH_RECIPE,

  //MealSizes
  FETCH_MEALSIZES,
  CLEAR_MEALSIZES,
  FETCH_MEALSIZE,
  REMOVE_MEALSIZE,
  //MealTypes
  FETCH_MEALTYPES,
  CLEAR_MEALTYPES,
  FETCH_MEALTYPE,
  REMOVE_MEALTYPE,
  //Diets
  FETCH_DIETS,
  CLEAR_DIETS,
  FETCH_DIET,
  REMOVE_DIET,
  //Variants
  FETCH_VARIANTS,
  CLEAR_VARIANTS,
  FETCH_VARIANT,
  REMOVE_VARIANT,
  //Wholesalers
  FETCH_WHOLESALERS,
  //Allergens
  FETCH_ALLERGENS,
  REMOVE_ALLERGEN,
  LOADING_ALLERGENS,
  //Delivery types
  FETCH_DELIVERYTYPES,
  REMOVE_DELIVERYTYPE,
  LOADING_DELIVERYTYPES,
  //Units
  FETCH_UNITS,
  REMOVE_UNIT,
  LOADING_UNITS,
  //Purposes
  FETCH_PURPOSES,
  REMOVE_PURPOSE,
  LOADING_PURPOSES,
  //Dishes
  FETCH_DISHES,
  REMOVE_DISH,
  FETCH_DISH,
  //Containers
  FETCH_CONTAINERS,
  //Brands
  FETCH_BRANDS,
  FETCH_BRANDSLIST,
  FETCH_BRAND,
  REMOVE_BRAND,
  FETCH_BRAND_CONFIG_MODULE,
  FETCH_COMPANY,
  FETCH_PAYMET_TYPES,
  FETCH_COUNTRY_INFORMATIONS,
  SET_REGION_FOR_VALIDATIONS,
  //Roles
  FETCH_ROLES,
  FETCH_ROLE,
  REMOVE_ROLE,
  //Permissions
  FETCH_PERMISSIONS,

  //Users
  FETCH_USERS,
  FETCH_USER,
  CLEAR_USER,
  CLEAR_USERS,
  REMOVE_USER,
  ADD_USER_BRAND,

  // Zone Categories
  FETCH_ZONE_CATEGORIES,
  FETCH_ZONE_CATEGORY,
  REMOVE_ZONE_CATEGORY,

  //Zones
  FETCH_ZONES,
  FETCH_ZONE,
  REMOVE_ZONE,

  //Addresses
  FETCH_USERADDRESSES,
  REMOVE_ADDRESS,
  //Drivers
  FETCH_DRIVERS,
  CLEAR_DRIVER,
  FETCH_DRIVER,
  REMOVE_DRIVER,
  //MenuTemplates
  LOADING_MENU_TEMPLATES,
  FETCH_MENU_TEMPLATES,
  FETCH_MENU_TEMPLATE,
  CREATE_MENU_TEMPLATE,
  REMOVE_MENU_TEMPLATE,
  RESET_MENU_TEMPLATES_STATE,
  RESET_MENU_TEMPLATE_STATE,
  //Order Statuses
  FETCH_ORDERSTATUSES,
  START_LOADING_ORDER_STATUSES,
  FINISH_LOADING_ORDER_STATUSES,
  //PACKAGES
  FETCH_PACKAGE,
  FETCH_PACKAGES,
  //SUBBRANDS
  FETCH_SUBBRANDS,

  // DICTIONARY
  FETCH_SEXES,
  FETCH_SEXES_SUCCESS,
  FETCH_SEXES_FAILED,
  FETCH_HOW_KNOW,
  FETCH_HOW_KNOW_SUCCESS,
  FETCH_HOW_KNOW_FAILED,

  SET_TEMPORARY_LOCK,
};

export default constants;
