import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_VARIANT, ROLE_REMOVE_VARIANT } from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);
  render() {
    const { classes } = this.props;

    return (
      <AdminTable title={this.props.t('list.variantsList')} icon>
        {isGranted(ROLE_CREATE_VARIANT) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newSize}
            submitText={this.props.t('form.addVariant') + ' +'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={isGranted(ROLE_REMOVE_VARIANT)}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="variants"
          reportName={'variant'}
          columns={columnConfig(this.props.t)}
          role="VARIANT"
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);
