import React from 'react';
import Moment from 'moment';
import DateFilter from '../../components/Grid/Filter/Date';

const columnConfig = [
  {
    title: 'columns.date',
    accessor: row => Moment(row.date).format('YYYY-MM-DD'),
    name: 'date',
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    filterable: true,
    sortable: false,
  },
  {
    title: 'diets.diet',
    accessor: row => row.diet.name,
    name: 'diet.name',
    filterable: true,
    sortable: false,
  },
  {
    title: 'diets.variant',
    accessor: row => row.variant.name,
    name: 'variant.name',
    filterable: true,
    sortable: false,
  },
  {
    title: 'employees.brand',
    accessor: row => row.brand.name,
    name: 'brand.name',
    filterable: true,
    sortable: false,
  },
];

export default columnConfig;
