import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';

class DietsChecklist extends React.Component {
  state = {
    includeSubscriptions: false,
  };

  getFileName = title => ({ date }) => {
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${date}_(${moment().format('YYYY-MM-DD_HH.mm')})`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  render() {
    const { classes, t } = this.props;
    const title = t('reports.reportDietsChecklist');
    return (
      <BaseBrandAndDateReport
        title={title}
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_CHECKLIST_DIETS}
        pdfAvaliable={true}
        pdfUrl="reports/checklist-diets"
        additionalParams={this.state}
        useDateRange={false}
        showMultiplier={true}
        archiveReportNames={['CHECK_LIST_DIETS']}
        useZoneCategories={true}
      >
        <GridItem sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                className="input-checkbox-include-subscriptions"
                checked={this.state.includeSubscriptions}
                onChange={e => {
                  this.setState({
                    includeSubscriptions: !this.state.includeSubscriptions,
                  });
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t('reports.includeSubscriptions')}
          />
        </GridItem>
      </BaseBrandAndDateReport>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(DietsChecklist);
