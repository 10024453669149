import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_ROLE } from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import { getMyLinks } from '../../actions/Auth';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';

class List extends Component {
  isCustom = this.props.custom === true;

  newObject = () =>
    this.props.history.push(`${this.props.location.pathname}/add`);
  render() {
    const { classes } = this.props;
    const url = this.isCustom ? '/my-links' : '/admin-nav-links';

    return (
      <AdminTable title={this.props.t('navLinks.list')} icon>
        {isGranted(ROLE_CREATE_ROLE) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newObject}
            submitText={this.props.t('navLinks.create') + '+'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${this.props.location.pathname}/edit`}
          remove={true}
          export={!this.isCustom}
          paginationTop={true}
          paginationBottom={false}
          url={url}
          columns={columnConfig(this.isCustom)}
          role="ADMIN_NAV_LINK"
          afterRemove={this.props.getMyLinks}
          canRemoveChecker={(canRemove, row) => {
            if (this.isCustom) {
              return row.custom === true;
            } else {
              return canRemove;
            }
          }}
          canEditChecker={(canEdit, row) => {
            if (this.isCustom) {
              return row.custom === true;
            } else {
              return canEdit;
            }
          }}
        />
      </AdminTable>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMyLinks: () => dispatch(getMyLinks()),
});

export default withTranslation()(
  connect(null, mapDispatchToProps)(withToast(withStyles(buttonsStyle)(List)))
);
