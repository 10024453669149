import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import FormControlButtons from '../../../components/FormControlButtons/FormControlButtons';
import { ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS } from 'helpers/roles';
import { isGranted } from 'helpers/helpers';
class List extends Component {
  createNew = () =>
    this.props.history.push(`/admin/mass-actions/diet-elements-add`);
  render() {
    return (
      <AdminTable title={' '} icon>
        {isGranted(ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS) && (
          <FormControlButtons
            classes={this.props.classes}
            handleSubmit={this.createNew}
            submitText={`${this.props.t('common.mainMenu.listAdd')} +`}
          />
        )}
        <DataGrid
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/diet-elements-configurations"
          reportName={'dietelementsconfigurations'}
          columns={columnConfig(this.props.t)}
          role="DIET_ELEMENTS_CONFIGURATIONS"
          defaultSorted={[{ id: 'createdDate', desc: true }]}
          minRows={20}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);
