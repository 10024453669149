import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class ActiveDiets extends React.Component {
  getFileName = title => ({ date }) => {
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${date}_(${moment().format('YYYY-MM-DD_HH.mm')})`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  render() {
    const { t } = this.props;
    const title = t('reports.reportActiveDiets');
    return (
      <BaseBrandAndDateReport
        title={title}
        excelUrl="/reports/active-diets"
        pdfUrl="/reports/active-diets"
        mimeType="application/vnd.ms-excel"
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_ACTIVE_DIET}
        pdfAvaliable={true}
        excelAvaliable={true}
        useDateRange={true}
        archiveReportNames={['ACTIVE_DIETS']}
        useZoneCategories={true}
      />
    );
  }
}

export default withTranslation()(ActiveDiets);
