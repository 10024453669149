import React, { Component } from 'react';
import { compose } from 'redux';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_ZONE_CATEGORY } from 'helpers/roles';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';

class List extends Component {
  state = {
    openedModal: false,
    file: null,
    isImporting: false,
    importButtonClicked: false,
  };

  componentDidMount() {
    const successfullyAddedZoneCategory = !!this.props.location.state
      ?.successfullyAddedZoneCategory;

    if (successfullyAddedZoneCategory) {
      this.props.openToast({
        messages: [this.props.t('zoneCategories.successAdded')],
        type: 'success',
        autoHideDuration: 5000,
      });
    }
  }

  newZone = () => this.props.history.push(`${window.location.pathname}/add`);

  render() {
    const { classes, t } = this.props;

    return (
      <AdminTable title={t('zoneCategories.list')} icon>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {isGranted(ROLE_CREATE_ZONE_CATEGORY) && (
            <FormControlButtons
              classes={classes}
              handleSubmit={this.newZone}
              submitText={t('zoneCategories.add') + ' +'}
            />
          )}
        </div>
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/zone-categories"
          reportName={'zoneCategory'}
          columns={columnConfig}
          role="ZONE_CATEGORY"
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withToast, withStyles(buttonsStyle), withTranslation());

export default enhance(List);
