import React, { Component } from 'react';
import { orderStyles } from '../../styles';

import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_EMAIL, ROLE_SHOW_EMAIL } from 'helpers/roles';
import { post } from 'helpers/apiHelpers';

import ModalButton from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import DataGrid from 'components/DataGrid/DataGrid';
import emailColumnConfig from './emailColumnConfig';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import { withTranslation } from 'react-i18next';

class EmailModal extends Component {
  state = {
    data: '',
    content: '',
  };

  componentDidMount() {
    this.setState({ data: this.props.client.email });
  }

  sendEmail = e => {
    const { t } = this.props;
    if (!this.state.data || !this.state.content) {
      return this.props.openToast({
        messages: [
          t(
            'errors.numberAndContentReuired',
            'Numer i treść nie mogą być puste'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    e.preventDefault();
    post('/emails', {
      data: this.state.data,
      content: this.state.content,
      client: this.props.client['@id'],
    }).then(newMessage => {
      this.setState({ content: '' });
      this.props.setEmailModalStatus(false);
      this.props.openToast({
        messages: [t('success.emailSent', 'Email został wysłany')],
        type: 'success',
        autoHideDuration: 3000,
      });
    });
  };

  render() {
    const { classes, setEmailModalStatus, EmailModalStatus, t } = this.props;
    return (
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={EmailModalStatus}
        onClose={() => setEmailModalStatus(false)}
      >
        <DialogContent>
          <ModalButton
            style={orderStyles.closeButton}
            color={'transparent'}
            justIcon
            round
            onClick={() => setEmailModalStatus(false)}
          >
            <Close />
          </ModalButton>
          <GridContainer>
            {isGranted(ROLE_CREATE_EMAIL) && (
              <GridItem md={isGranted(ROLE_SHOW_EMAIL) ? 3 : 12}>
                <div style={{ marginLeft: '5px', ...orderStyles.box }}>
                  <b>{t('emailModal.emailToClient', 'Email do klienta')}</b>
                  <FormTextInput
                    disabled
                    label="Adres email"
                    classes={classes}
                    value={this.state.data}
                    inputSize={12}
                    maxLength={64}
                  />
                  <FormTextInput
                    multiline
                    rows={8}
                    label={t('common.messageContent', 'Treść wiadomości')}
                    classes={classes}
                    value={this.state.content}
                    handleChange={ev =>
                      this.setState({ content: ev.target.value })
                    }
                    inputSize={12}
                    maxLength={669}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalButton
                      color={'primary'}
                      onClick={ev => this.sendEmail(ev)}
                    >
                      {t('emailModal.sendEmail', 'Wyślij email')}
                    </ModalButton>
                  </div>
                </div>
              </GridItem>
            )}
            {isGranted(ROLE_SHOW_EMAIL) && (
              <GridItem md={isGranted(ROLE_CREATE_EMAIL) ? 9 : 12}>
                <div
                  style={{
                    marginLeft: '5px',
                    ...orderStyles.box,
                    paddingBottom: '10px',
                  }}
                >
                  <b>
                    {t(
                      'emailModal.emailsHistory',
                      'Historia wysłanych wiadomości email'
                    )}
                  </b>
                </div>
                <DataGrid
                  actions={false}
                  paginationTop={true}
                  paginationBottom={false}
                  url={`${this.props.client['@id']}/email-messages`}
                  columns={emailColumnConfig}
                  role="EMAIL"
                />
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(EmailModal);
