import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { combineStyles, isGranted } from 'helpers/helpers';
import { ROLE_CREATE_MEAL_TYPE } from 'helpers/roles';

import { withTranslation } from 'react-i18next';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { connect } from 'react-redux';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);
  render() {
    const { classes } = this.props;

    return (
      <AdminTable title={this.props.t('reportArchive')} icon>
        <DataGrid
          minRows={10}
          actions={true}
          remove={false}
          export={false}
          paginationTop={true}
          paginationBottom={false}
          url="export-archives"
          columns={columnConfig(this.props)}
        />
      </AdminTable>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Auth.user,
  };
};

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(buttonsStyle)(List))
);
