import React from 'react';
import Moment from 'moment';
import { withTranslation } from 'react-i18next';
import SelectInput from '../../FormSelect/SelectInput';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from '../../../helpers/helpers';
import extendedGridStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedGridStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';

class Select extends React.Component {
  state = {
    selected: [],
  };

  handleDiets = (e, selected) => {
    this.setState({
      selected: selected,
    });

    if (this.props.multiple) {
      this.props?.onChange(selected.map(el => el['@id']));
    } else {
      if (this.props.value === selected?.['@id']) {
        return this.props?.onChange(null);
      }
      return this.props?.onChange(selected['@id']);
    }
  };

  render() {
    let m = Moment;
    return (
      <SelectInput
        multiple={this.props.multiple ?? true}
        classes={this.props.classes}
        label={false}
        mapBy="name"
        trackBy="@id"
        value={this.props.value || this.state.selected}
        options={this.props.options || []}
        handleChange={(ev, obj) => this.handleDiets(ev, obj)}
      />
    );
  }
}

const combinedStyles = combineStyles(extendedGridStyle, buttonsStyle);

export default withTranslation()(withStyles(combinedStyles)(Select));
