import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';
// core components
import Button from 'components/CustomButtons/Button.jsx';

import defaultImage from 'assets/img/image_placeholder.jpg';
import { withTranslation } from 'react-i18next';

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.defaultImage || defaultImage,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidUpdate = prevProps => {
    if (this.props.previewUrl !== prevProps.previewUrl) {
      this.setState(prevState => ({
        ...prevState,
        file: null,
        imagePreviewUrl: this.props.defaultImage || defaultImage,
      }));
    }
  };

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    this.props.getImage(this.props.stateName, file);
    file && reader.readAsDataURL(file);
  }

  handleClick() {
    this.refs.fileInput.click();
  }

  handleRemove() {
    this.setState(prevState => ({
      ...prevState,
      file: null,
      imagePreviewUrl: this.props.defaultImage || defaultImage,
    }));

    this.props.removeImage(this.props.stateName);
    this.refs.fileInput.value = null;
  }

  render() {
    const {
      avatar,
      buttonText,
      isIcon,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      imgId,
    } = this.props;

    return (
      <div
        style={{
          maxWidth: isIcon ? '250px' : 'inherit',
          padding: isIcon ? '0 80px' : 'inherit',
        }}
        className="fileinput text-center"
      >
        <input
          type="file"
          onChange={this.handleImageChange}
          ref="fileInput"
          accept="image/*"
        />
        <div className={'thumbnail'}>
          <img
            id={imgId}
            src={
              this.props.previewUrl !== null
                ? this.props.previewUrl
                : this.state.imagePreviewUrl
            }
            alt="..."
          />
        </div>
        <div
          style={{
            display: isIcon ? 'flex' : 'block',
            justifyContent: isIcon ? 'center' : 'inherit',
          }}
        >
          {this.state.file === null ? (
            <span>
              <Button {...addButtonProps} onClick={() => this.handleClick()}>
                {buttonText ? buttonText : this.props.t('form.selectPhoto')}
              </Button>
              {this.props.previewUrl && (
                <Button
                  {...removeButtonProps}
                  onClick={() => this.handleRemove()}
                >
                  <i className="fas fa-times" /> {this.props.t('form.delete')}
                </Button>
              )}
            </span>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                {this.props.t('form.change')}
              </Button>
              {avatar ? <br /> : null}
              <Button
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                <i className="fas fa-times" /> {this.props.t('form.delete')}
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

export default withTranslation()(ImageUpload);
