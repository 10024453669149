import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class PersonalPickups extends React.Component {
  getFileName = title => ({ date }) => {
    return `${title.replaceAll(' ', '_')}-${date}_(${moment().format(
      'YYYY-MM-DD_HH.mm'
    )})`;
  };

  render() {
    const { t } = this.props;
    const title = t('common.mainMenu.reportPersonalPickups');
    return (
      <BaseBrandAndDateReport
        title={title}
        excelUrl="/reports/personal-pickups"
        mimeType="application/vnd.ms-excel"
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_PERSONAL_PICKUPS}
        pdfAvaliable={false}
        excelAvaliable={true}
        useDateRange={false}
        archiveReportNames={['PERSONAL_PICKUPS']}
      />
    );
  }
}

export default withTranslation()(PersonalPickups);
