import React, { Component } from 'react';
import { combineStyles } from 'helpers/helpers';
import { post, put } from 'helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import Moment from 'moment';
import produce from 'immer';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import { compose } from 'redux';
//actions
import { fetchZone } from 'actions/Zones';
import { fetchBrandConfigModulesPack } from 'actions/Brands';
import { fetchDeliveryTypes } from 'actions/DeliveryTypes';
import { fetchZoneCategories } from 'actions/ZoneCategories';
import { fetchDrivers } from 'actions/Drivers';
//styles
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
//material-ui core
import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
// components
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';
import TreeView from './components/TreeView';
import CardBody from 'components/Card/CardBody';
import AdminTable from 'layouts/AdminTable';
import CustomInput from 'components/CustomInput/CustomInput';
import PostCodeModal from './components/PostCodeModal';
import GridContainer from 'components/Grid/GridContainer';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
// icons
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import SelectInput from 'components/FormSelect/SelectInput';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

const SETTINGS = 'SETTINGS';

class Form extends Component {
  state = {
    deliveryHours: [],
    deliveryInterval: '',
    selectedDeliveryType: '',
    name: '',
    shortName: '',
    priority: '',
    category: null,
    postCodePreview: false,
    postCode: '',
    postCodes: [],
    postCodeModalStatus: false,
    drivers: [],
    subpage: SETTINGS,
    urlSlug: '',
    metaTitle: '',
    metaDescription: '',
    friendlyName: '',
    deliveryZonesDescription: '',
    bannerEnabled: false,
    bannerHeader: '',
    bannerText: '',
    infoBannerDesktop: null,
    infoBannerDesktopUrl: null,
    infoBannerMobile: null,
    infoBannerMobileUrl: null,
    bannerId: null,
    useAddressesWithLessFields: true,
  };

  editId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  async componentDidMount() {
    await this.props.fetchDeliveryTypes();
    await this.props.fetchDrivers();
    await this.props.fetchZoneCategories();
    const {
      configuration: {
        ConfigClientPanel: { useAddressesWithLessFields } = {},
      } = {},
    } = await this.props.fetchBrandConfigModulesPack(this.props.selectedBrand, [
      'ConfigClientPanel',
    ]);

    if (this.isEdit) {
      this.props.fetchZone(this.editId).then(response => {
        const newState = {
          name: response.name,
          shortName: response.shortName,
          priority: response.priority,
          postCodes: response.postCodes,
          selectedDeliveryType: response.deliveryType
            ? response.deliveryType['@id']
            : '',
          deliveryHours: response.deliveryHours,
          drivers: response.drivers.map(driver => driver['@id']),
          category: response.category?.['@id'],
          urlSlug: response.pageSettings.slug ?? '',
          metaTitle: response.seo.title ?? '',
          metaDescription: response.seo.description ?? '',
          deliveryZonesDescription: response.pageSettings.content ?? '',
          friendlyName: response.pageSettings.title ?? '',
          bannerEnabled: response.banner ? response.banner.enabled : false,
          bannerHeader: response.banner ? response.banner.heading : '',
          bannerText: response.banner ? response.banner.content : '',
          useAddressesWithLessFields: useAddressesWithLessFields,
        };

        if (response.banner) {
          newState.bannerId = response.banner['@id'];

          if (response.banner.desktop) {
            newState.infoBannerDesktop = response.banner.desktop['@id'];
            newState.infoBannerDesktopUrl = response.banner.desktop?.contentUrl;
          }

          if (response.banner.mobile) {
            newState.infoBannerMobile = response.banner.mobile['@id'];
            newState.infoBannerMobileUrl = response.banner.mobile?.contentUrl;
          }
        }

        return this.setState(newState);
      });
    } else {
      this.setState({
        useAddressesWithLessFields: useAddressesWithLessFields,
      });
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDefaultDeliveryHourChange = newDefaultIndex => {
    let newDeliveryHours = this.state.deliveryHours.map(
      (deliveryHour, index) => {
        if (index === newDefaultIndex) {
          return { ...deliveryHour, default: true };
        } else if (deliveryHour?.default === true) {
          return { ...deliveryHour, default: false };
        } else {
          return deliveryHour;
        }
      }
    );

    this.setState({ deliveryHours: newDeliveryHours });
  };

  handleDeliveryHourChange = (key, newHour, index) => {
    let { deliveryHours } = this.state;

    deliveryHours[index][key] = newHour ? new Date(newHour) : null;

    this.setState({ deliveryHours });
  };

  handleDeliveryHourClear = (key, index) => {
    let { deliveryHours } = this.state;

    deliveryHours[index][key] = null;

    this.setState({ deliveryHours });
  };

  handleDeliveryHourToRemove = index => {
    let { deliveryHours } = this.state;

    deliveryHours.splice(index, 1);

    this.setState({ deliveryHours });
  };

  validatePostCode = () => {
    const validator = new RegExp('^[0-9]{2}-[0-9]{3}$');
    return validator.test(this.state.postCode);
  };

  postCodeValidator = regex => stringToValidate => {
    if (stringToValidate.includes('*')) {
      return true;
    }
    if (null === regex) {
      return true;
    }
    const validator = new RegExp(`${regex}$`);
    return validator.test(stringToValidate);
  };

  validateZones = () => {
    return this.state.postCodes.length > 0;
  };

  validateCodes = () => {
    return !this.state.postCodes.some(postCode => postCode.code === '');
  };

  validateForm = () => {
    return (
      this.state.name &&
      this.state.shortName &&
      this.state.priority !== '' &&
      this.state.selectedDeliveryType
    );
  };

  handleSubmit = () => {
    if (!this.validateCodes()) {
      return this.props.openToast({
        messages: [this.props.t('zones.codeCantBeEmpty')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (!this.validateZones()) {
      return this.props.openToast({
        messages: [this.props.t('zones.zoneMust')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('zones.fillAllFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      name: this.state.name,
      shortName: this.state.shortName,
      priority: parseInt(this.state.priority),
      postCodes: this.state.postCodes,
      deliveryType: this.state.selectedDeliveryType,
      deliveryHours: this.state.deliveryHours.map(hour => ({
        ...hour,
        hourFrom: hour.hourFrom ? new Moment(hour.hourFrom).format() : null,
        hourTo: hour.hourTo ? new Moment(hour.hourTo).format() : null,
      })),
      drivers: this.state.drivers,
      category: this.state.category,
      seo: {
        title: this.state.metaTitle,
        description: this.state.metaDescription,
      },
      pageSettings: {
        slug: this.state.urlSlug,
        content: this.state.deliveryZonesDescription,
        title: this.state.friendlyName,
      },
      banner: {
        enabled: this.state.bannerEnabled,
        heading: this.state.bannerHeader,
        content: this.state.bannerText,
        desktop: this.state.infoBannerDesktop,
        mobile: this.state.infoBannerMobile,
      },
    };

    if (this.state.bannerId) {
      data.banner['@id'] = this.state.bannerId;
    }

    const action = this.isEdit
      ? put(`/zones/${this.editId}`, data)
      : post('/zones', data);

    action.then(
      () =>
        this.props.history.push('/admin/zones', {
          successfullyAddedZone: true,
        }),
      error => {
        return this.props.openToast({
          messages: [
            this.props.t('zones.smthWentWrong'),
            this.props.t('zones.checkForm'),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  handleChangeLocation = produceCallback => {
    const newState = produce(this.state.postCodes, produceCallback);

    this.setState(prevState => ({ ...prevState, postCodes: newState }));
  };

  togglePostCodeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      postCodeModalStatus: !prevState.postCodeModalStatus,
    }));
  };

  render() {
    const { classes, t, multinational } = this.props;
    const selectedSupportedRegion = multinational?.supportedRegions?.[0];
    const selectedCountry = this.props.countryInformations.find(
      countryInf => countryInf.code === selectedSupportedRegion
    );

    const postCodeValidator = this.postCodeValidator(
      selectedCountry.postCodeRegexp
    );

    return (
      <>
        <AdminTable
          title={this.isEdit ? t('zones.editZone') : t('zones.addNewZone')}
          icon
        >
          <GridContainer>
            <GridItem sm={6}>
              <h4>{t('zones.basicInfo')}</h4>
              <GridContainer>
                <GridItem sm={6}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('zones.zoneName')}*
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'name',
                      value: this.state.name,
                      onChange: ev => this.handleChange(ev),
                    }}
                  />
                </GridItem>
                <GridItem sm={6}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('zones.shortName')}*
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'shortName',
                      value: this.state.shortName,
                      onChange: ev => this.handleChange(ev),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6}>
                  <FormLabel
                    style={{ marginTop: '4px', marginBottom: '10px' }}
                    className={classes.labelHorizontal}
                  >
                    {t('zones.priority')}*
                  </FormLabel>
                  <CustomInput
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: 'priority',
                      type: 'number',
                      value: this.state.priority,
                      onChange: ev => this.handleChange(ev),
                    }}
                  />
                </GridItem>
                <GridItem sm={6}>
                  <SelectInput
                    classes={classes}
                    multiple={true}
                    label={t('zones.assignedDrivers') + '*'}
                    mapBy="name"
                    trackBy="@id"
                    name="drivers"
                    value={this.state.drivers}
                    options={this.props.drivers || []}
                    handleChange={this.handleChange}
                    size={12}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6}>
                  <SelectInput
                    classes={classes}
                    multiple={false}
                    label={t('zones.category')}
                    mapBy="name"
                    trackBy="@id"
                    name="category"
                    value={this.state.category}
                    options={this.props.zoneCategories || []}
                    handleChange={this.handleChange}
                    size={12}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem sm={6}>
              <h4>{t('zones.postcodesShow')}</h4>
              <GridContainer>
                <GridItem sm={6}>
                  <div>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('zones.selectPostcode')}
                    </FormLabel>
                    <CustomInput
                      classes={classes}
                      customInput={FormTextInputNoGrid}
                      success={postCodeValidator(this.state.postCode)}
                      error={!postCodeValidator(this.state.postCode)}
                      helpText={
                        !postCodeValidator(this.state.postCode) &&
                        this.state.postCode.length > 0
                          ? t('zones.incorrectPostcode')
                          : ''
                      }
                      inputProps={{
                        value: this.state.postCode,
                        onChange: ev =>
                          this.setState({ postCode: ev.target.value }),
                      }}
                      name="postCode"
                    />
                  </div>
                </GridItem>
                <GridItem sm={6}>
                  <Button
                    onClick={() =>
                      !this.validatePostCode()
                        ? this.props.openToast({
                            messages: [t('zones.typeValidPostcode')],
                            type: 'warning',
                            autoHideDuration: 3000,
                          })
                        : window.open(
                            `https://mapa.targeo.pl/${this.state.postCode}/kod-pocztowy`
                          )
                    }
                    color={'info'}
                  >
                    {t('zones.showOnMap')}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem sm={6}>
              <GridContainer>
                <GridItem sm={12}>
                  <h4>{t('zones.additionalInfo')}</h4>
                </GridItem>
                <GridItem sm={12} style={{ marginTop: '2px' }}>
                  <FormSelectSingle
                    classes={classes}
                    label={t('zones.type') + '*'}
                    options={this.props.deliveryTypes}
                    value={this.state.selectedDeliveryType}
                    mapBy="value"
                    trackBy="@id"
                    name="selectedDeliveryType"
                    handleChange={ev => this.handleChange(ev)}
                    id="selectedDeliveryType"
                  />
                </GridItem>
                <GridItem sm={12}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormLabel
                      style={{ marginTop: '20px' }}
                      className={classes.labelHorizontal}
                    >
                      {t('zones.deliveryHours')}
                      <div style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={
                            <div>
                              <h5
                                dangerouslySetInnerHTML={{
                                  __html: t('zones.deliveryHoursTooltip', {
                                    interpolation: { escapeValue: false },
                                  }),
                                }}
                              />
                            </div>
                          }
                          placement="right"
                        >
                          <Info fontSize={'small'} />
                        </Tooltip>
                      </div>
                    </FormLabel>
                    <Fab
                      color="secondary"
                      variant="extended"
                      className={classes.fab}
                      onClick={() =>
                        this.setState({
                          deliveryHours: [
                            ...this.state.deliveryHours,
                            {
                              hourFrom: null,
                              hourTo: null,
                              default:
                                !this.state.deliveryHours ||
                                this.state.deliveryHours?.length === 0,
                            },
                          ],
                        })
                      }
                    >
                      <AddIcon />
                      {t('zones.addDeliveryHour')}
                    </Fab>
                  </div>
                </GridItem>
                {this.state.deliveryHours.map((hour, index) => {
                  return (
                    <React.Fragment key={index}>
                      <GridItem sm={12}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0 -4px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'baseline',
                              padding: '0 4px',
                            }}
                          >
                            <span style={{ marginRight: '10px' }}>
                              {t('comments.from', 'Od')}
                            </span>
                            <div>
                              <Datetime
                                dateFormat={false}
                                value={
                                  this.state.deliveryHours[index].hourFrom
                                    ? Moment(
                                        this.state.deliveryHours[index].hourFrom
                                      ).format('HH:mm')
                                    : ''
                                }
                                onChange={ev =>
                                  this.handleDeliveryHourChange(
                                    'hourFrom',
                                    ev,
                                    index
                                  )
                                }
                                inputProps={{
                                  placeholder: t('zones.select'),
                                  readOnly: true,
                                }}
                              />

                              <Button
                                round
                                onClick={() =>
                                  this.handleDeliveryHourClear(
                                    'hourFrom',
                                    index
                                  )
                                }
                                color="danger"
                                className="remove"
                                disabled={
                                  !this.state.deliveryHours[index].hourTo ||
                                  !this.state.deliveryHours[index].hourFrom
                                }
                              >
                                {t('zones.clear', '$$Wyczyść')}
                              </Button>
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'baseline',
                              padding: '0 4px',
                            }}
                          >
                            <span style={{ marginRight: '10px' }}>
                              {t('comments.to', 'do')}
                            </span>

                            <div>
                              <Datetime
                                dateFormat={false}
                                value={
                                  this.state.deliveryHours[index].hourTo
                                    ? Moment(
                                        this.state.deliveryHours[index].hourTo
                                      ).format('HH:mm')
                                    : ''
                                }
                                onChange={ev =>
                                  this.handleDeliveryHourChange(
                                    'hourTo',
                                    ev,
                                    index
                                  )
                                }
                                inputProps={{
                                  placeholder: t('zones.select'),
                                  readOnly: true,
                                }}
                              />
                              <Button
                                round
                                onClick={() =>
                                  this.handleDeliveryHourClear('hourTo', index)
                                }
                                color="danger"
                                className="remove"
                                disabled={
                                  !this.state.deliveryHours[index].hourFrom ||
                                  !this.state.deliveryHours[index].hourTo
                                }
                              >
                                {t('zones.clear', '$$Wyczyść')}
                              </Button>
                            </div>
                          </div>

                          <Button
                            justIcon
                            round
                            simple
                            onClick={() =>
                              this.handleDeliveryHourToRemove(index)
                            }
                            color="danger"
                            className="remove"
                          >
                            <Close />
                          </Button>
                          <span>{t('common.isDefault', 'Is default: ')}</span>
                          <Checkbox
                            checked={hour?.default}
                            onClick={() =>
                              this.handleDefaultDeliveryHourChange(index)
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        </div>
                      </GridItem>
                    </React.Fragment>
                  );
                })}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </AdminTable>
        <Card>
          <CardBody>
            <Button color="success" onClick={this.togglePostCodeModal}>
              {t('zones.button.addAddress', '+ Dodaj adres')}
            </Button>

            <TreeView
              t={this.props.t}
              postCodes={this.state.postCodes}
              handleChangeLocation={this.handleChangeLocation}
            />
          </CardBody>
        </Card>

        <PostCodeModal
          postCodeModalStatus={this.state.postCodeModalStatus}
          togglePostCodeModal={this.togglePostCodeModal}
          postCodes={this.state.postCodes}
          shortFormEnabled={this.state.useAddressesWithLessFields}
          setMergedAddresses={mergedAddresses => {
            this.setState(prevState => ({
              ...prevState,
              postCodes: mergedAddresses,
            }));
          }}
          postCodeValidator={postCodeValidator}
        />

        <FormControlButtons
          classes={classes}
          discardText={t('zones.cancel')}
          submitText={t('zones.save')}
          cancelPath="/admin/zones"
          handleSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  zone: state.Zones.zone,
  selectedBrand: state.Auth.selectedBrand,
  deliveryTypes: state.DeliveryTypes.deliveryTypes,
  drivers: state.Drivers.drivers,
  multinational: state.Brands.brand.multinational,
  countryInformations: state.Brands.countryInformations,
  zoneCategories: state.ZoneCategories.zoneCategories,
});

const mapDispatchToProps = dispatch => ({
  fetchDeliveryTypes: () => dispatch(fetchDeliveryTypes()),
  fetchZone: id => dispatch(fetchZone(id)),
  fetchDrivers: () => dispatch(fetchDrivers()),
  fetchZoneCategories: () => dispatch(fetchZoneCategories()),
  fetchBrandConfigModulesPack: (selectedBrand, config) =>
    dispatch(fetchBrandConfigModulesPack(selectedBrand, config)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(Form);
