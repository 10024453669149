import React from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

import GroupIcon from '@material-ui/icons/Group';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_CONTRACTOR } from 'helpers/roles';

import columnConfig from './columnConfig';
import { useTranslation } from 'react-i18next';

const ContractorsList = ({ classes }) => {
  const history = useHistory();
  const newContractor = () => history.push(`${window.location.pathname}/add`);
  const { t } = useTranslation();

  return (
    <AdminTable
      title={t('contractors.cotractorsList', 'Lista kontrahentów')}
      icon
      iconComponent={<GroupIcon />}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {isGranted(ROLE_CREATE_CONTRACTOR) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={newContractor}
            submitText={t('contractors.addNew', 'Dodaj kontrahenta') + ' +'}
          />
        )}
      </div>
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={true}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        url="/contractors"
        reportName={'contractor'}
        columns={columnConfig}
        role="CONTRACTOR"
      />
    </AdminTable>
  );
};

const enhance = compose(withStyles(buttonsStyle));

export default enhance(ContractorsList);
