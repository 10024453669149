import React from 'react';
import {combineStyles} from 'helpers/helpers';
import buttonsStyle
  from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle
  from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import {withToast} from 'material-ui-toast-redux';
import {withTranslation} from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PriceBlock from '../Common/PriceBlock';

class BasketItemDayModification extends React.Component {

  render() {
    const {row, t} = this.props;
    return (
      <>
        <GridContainer style={{padding: '8px'}}>
          <GridItem xs={12}>
            <h4 style={{textAlign: 'center', fontWeight: 'bold'}}>{t('basket.details.BasketItemDayModification.title')}</h4>
          </GridItem>
          <GridItem xs={12} style={{padding: '0 16px'}}>
            {row.address && <>
              <p><b>{t('basket.details.BasketItemDayModification.address')}</b>: {(<>
                  {row.address.postCode} {row.address.city},{' '}
                  {row.address.street} {row.address.buildNumber}{row.address.placeNumber && '/'}{row.address.placeNumber}
                </>
              )}</p>
            </>}
            {row.pickUpPoint && <>
              <p><b>{t('basket.details.BasketItemDayModification.pickUpPoint')}</b>: {row.pickUpPoint.value}</p>
            </>}
            {row.diet && <>
              <p><b>{t('basket.details.BasketItemDayModification.diet')}</b>: {row.diet.name}</p>
            </>}
            {row.variant && <>
              <p><b>{t('basket.details.BasketItemDayModification.variant')}</b>: {row.variant.name}</p>
            </>}
            {row.calorific && <>
              <p><b>{t('basket.details.BasketItemDayModification.calorific')}</b>: {row.calorific.name}</p>
            </>}
            {row.date && <>
              <p><b>{t('basket.details.BasketItemDayModification.date')}</b>: {row.date}</p>
            </>}
            {row.options.optionChangeMenu && <>
              <p><b>{t('basket.details.BasketItemDayModification.optionChangeMenu')}</b></p>
            </>}
            {row.options.useEcoContainers && <>
              <p><b>{t('basket.details.BasketItemDayModification.useEcoContainers')}</b></p>
            </>}
            <h4 style={{textAlign: 'center', fontWeight: 'bold'}}>{t('basket.details.common.cost')}</h4>
            <PriceBlock price={row.price}/>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

export default withTranslation()(
  withStyles(combinedStyles)(withToast(BasketItemDayModification)),
);
