import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from '../../../components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { withTranslation } from 'react-i18next';
import BooleanView from '../../../components/DataGrid/ValueViews/BooleanView';
import Visibility from '@material-ui/icons/Visibility';
import { OpenInNew } from '@material-ui/icons';
import { isGranted } from 'helpers/helpers';

class List extends Component {
  state = {
    modal: false,
  };

  hideModal = () => {
    this.setState({
      modal: false,
    });
  };

  openModal = () => {
    this.setState({
      modal: true,
    });
  };

  buildLink = id => {
    const filter = window.btoa(
      unescape(
        encodeURIComponent(
          JSON.stringify([{ id: this.props.previewKey ?? 'id', value: id }])
        )
      )
    );

    return this.props.previewLink + '?filter=' + filter;
  };

  openLink = id => {
    window.open(this.buildLink(id), '_blank').focus();
  };

  renderLink = () => {
    return this.props.previewLink && isGranted(this.props.linkRole);
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Dialog
          maxWidth={'md'}
          open={this.state.modal}
          onClose={this.hideModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.hideModal}
            >
              &times;
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <Paper>
              <Table style={{ textAlign: 'center' }}>
                <TableHead>
                  <TableRow style={{ textAlign: 'center' }}>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        border: '1px solid rgb(224, 224, 224)',
                      }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        border: '1px solid rgb(224, 224, 224)',
                        width: '88px',
                      }}
                    >
                      {t('massAction.dietElements.status')}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                <Table>
                  <TableBody>
                    {this.props.source.sort().map(id => (
                      <TableRow>
                        <TableCell
                          colSpan={1}
                          style={{
                            textAlign: 'center',
                            border: '1px solid rgb(224, 224, 224)',
                          }}
                        >
                          {id}{' '}
                          {this.renderLink() && (
                            <OpenInNew onClick={() => this.openLink(id)} />
                          )}
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          style={{
                            textAlign: 'center',
                            border: '1px solid rgb(224, 224, 224)',
                            width: '70px',
                          }}
                        >
                          <BooleanView
                            value={this.props.finished.includes(id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Paper>
          </DialogContent>
        </Dialog>
        <Button onClick={this.openModal}>
          <Visibility />
        </Button>
      </>
    );
  }
}

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);
