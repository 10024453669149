import { useEffect } from 'react';

import TreeNode from './TreeNode';
import S from './TreeView.styles';

const TreeView = ({ t, postCodes, handleChangeLocation }) => {
  return (
    <S.TreeWrapper>
      {postCodes.length > 0 && (
        <S.TreeNodeHeader>
          {t('zones.postCodes', 'Kody pocztowe')}
        </S.TreeNodeHeader>
      )}
      {postCodes?.map((postCode, postCodeIndex) => (
        <TreeNode
          label={postCode.code}
          onChange={e =>
            handleChangeLocation(draftState => {
              draftState[postCodeIndex].code = e.target.value;
            })
          }
          onDeleteNode={() =>
            handleChangeLocation(draftState => {
              draftState.splice(postCodeIndex, 1);
            })
          }
        >
          {postCode.cities.length > 0 && (
            <S.TreeNodeHeader indent>
              {t('zones.cities', 'Miejscowości')}
            </S.TreeNodeHeader>
          )}
          {postCode.cities?.map((city, cityIndex) => (
            <TreeNode
              label={city.name}
              indent
              onChange={e =>
                handleChangeLocation(draftState => {
                  draftState[postCodeIndex].cities[cityIndex].name =
                    e.target.value;
                })
              }
              onDeleteNode={() =>
                handleChangeLocation(draftState => {
                  draftState[postCodeIndex].cities.splice(cityIndex, 1);
                })
              }
            >
              {city.streets.length > 0 && (
                <S.TreeNodeHeader indent>
                  {t('zones.streets', 'Ulice')}
                </S.TreeNodeHeader>
              )}
              {city.streets?.map((street, streetIndex) => (
                <TreeNode
                  label={street.name}
                  indent
                  onChange={e =>
                    handleChangeLocation(draftState => {
                      draftState[postCodeIndex].cities[cityIndex].streets[
                        streetIndex
                      ].name = e.target.value;
                    })
                  }
                  onDeleteNode={() =>
                    handleChangeLocation(draftState => {
                      draftState[postCodeIndex].cities[
                        cityIndex
                      ].streets.splice(streetIndex, 1);
                    })
                  }
                >
                  {street.buildings.length > 0 && (
                    <S.TreeNodeHeader indent>
                      {t('zones.buildingNumbers', 'Numery budynków')}
                    </S.TreeNodeHeader>
                  )}
                  {street.buildings?.map(
                    (buildingNumber, buildingNumberIndex) => (
                      <TreeNode
                        label={buildingNumber}
                        indent
                        onChange={e =>
                          handleChangeLocation(draftState => {
                            draftState[postCodeIndex].cities[cityIndex].streets[
                              streetIndex
                            ].buildings[buildingNumberIndex] = e.target.value;
                          })
                        }
                        onDeleteNode={() =>
                          handleChangeLocation(draftState => {
                            draftState[postCodeIndex].cities[cityIndex].streets[
                              streetIndex
                            ].buildings.splice(buildingNumberIndex, 1);
                          })
                        }
                      />
                    )
                  )}
                </TreeNode>
              ))}
            </TreeNode>
          ))}
        </TreeNode>
      ))}
    </S.TreeWrapper>
  );
};

export default TreeView;
