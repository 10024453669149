import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import {
  ROLE_CREATE_INGREDIENT,
  ROLE_CREATE_IMPORTER_TASK,
  ROLE_SHOW_IMPORTER_TASK,
  ROLE_IMPORT_INGREDIENT,
} from 'helpers/roles';
import Button from 'components/CustomButtons/Button';
import InfoModal from 'components/InfoModal/InfoModal';
import MediaUpload from 'components/MediaUpload/MediaUpload';
import gastro from 'helpers/gastro';
import { post } from 'helpers/apiHelpers';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { currencyToSymbol } from 'utils/currencies';
import importCSVExample from './import-csv-example.csv';
import importCSVExampleFi from './import-csv-example-fi.csv';

class List extends Component {
  state = {
    openedModal: false,
    file: null,
    isImporting: false,
    importButtonClicked: false,
  };

  newSize = () => this.props.history.push(`${window.location.pathname}/add`);

  toggleModal = () => {
    this.setState(prevState => ({
      openedModal: !prevState.openedModal,
    }));
  };

  importIngredients = () => {
    this.setState({ importButtonClicked: true });
    this.uploadFile(this.state.file).then(file => {
      this.postImporterTask(file);
    });
  };

  postImporterTask = file => {
    this.setState({ isImporting: true });
    const data = {
      type: 'INGREDIENTS',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      res => {
        this.setState({
          openedModal: false,
          file: null,
          importButtonClicked: false,
        });
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [
            this.props.t('form.importerInfo22'),
            this.props.t('form.importerInfo23'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [this.props.t('form.importerInfo24')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  uploadFile = async file => {
    let data = new FormData();
    data.append('file', file);

    let response = await gastro.post('/media', data);

    return new Promise((resolve, reject) => {
      resolve(response.data);
    });
  };

  loadExample(language) {
    switch (language) {
      case 'fi':
        return importCSVExampleFi;
      default:
        return importCSVExample;
    }
  }

  render() {
    const {
      classes,
      t,
      i18n: { language },
      multinational: { currency },
    } = this.props;

    return (
      <AdminTable title={this.props.t('list.ingredientsList')} icon>
        <InfoModal
          modalState={this.state.openedModal}
          handleClose={() => this.toggleModal}
          modalContent={
            <div>
              <h1>{t('ingredients.ingredientsImport')}</h1>
              <p style={{ textAlign: 'left' }}>
                <ul
                  dangerouslySetInnerHTML={{
                    __html: t('ingredients.importerModalInfo', {
                      importCSVExample: this.loadExample(language),
                      interpolation: { escapeValue: false },
                      currency: currencyToSymbol(currency),
                    }),
                  }}
                />
              </p>
              <MediaUpload
                file={this.state}
                setFile={file => this.setState({ file: file })}
                buttonText={t('form.selectCSVFile')}
                addButtonProps={{
                  color: 'info',
                  round: false,
                }}
                changeButtonProps={{
                  color: 'info',
                  round: false,
                }}
                removeButtonProps={{
                  color: 'danger',
                  round: false,
                }}
              />
              <div>
                {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                  isGranted(ROLE_IMPORT_INGREDIENT) && (
                    <Button
                      color={this.state.file ? 'success' : 'default'}
                      disabled={
                        !this.state.file ||
                        this.state.isImporting ||
                        this.state.importButtonClicked
                      }
                      onClick={this.importIngredients}
                    >
                      {t('form.import')}
                    </Button>
                  )}
                <Button onClick={this.toggleModal}>{t('form.close')}</Button>
              </div>
            </div>
          }
          closeButtonText={t('form.close')}
          hideDefaultCloseButton
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {isGranted(ROLE_SHOW_IMPORTER_TASK) &&
            isGranted(ROLE_IMPORT_INGREDIENT) && (
              <Button
                style={{ margin: '5px', width: '158px' }}
                color={'info'}
                onClick={this.toggleModal}
              >
                {t('form.import')}
              </Button>
            )}
          {isGranted(ROLE_CREATE_INGREDIENT) && (
            <FormControlButtons
              classes={classes}
              handleSubmit={this.newSize}
              submitText={t('form.addIngredient') + ' +'}
            />
          )}
        </div>
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="ingredients"
          reportName={'ingredient'}
          columns={columnConfig}
          role="INGREDIENT"
          manipulateQuery={(table, query) => {
            query['properties'] = [
              'id',
              'name',
              'workName',
              'price',
              'inventoryAmount',
            ];
            query['properties[categories]'] = ['value'];
            query['properties[allergens]'] = ['value'];

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(
  connect(({ Brands: { brand: { multinational } } }) => ({ multinational })),
  withToast,
  withStyles(buttonsStyle),
  withTranslation()
);

export default enhance(List);
