import React, { Component } from 'react';
import { combineStyles, isGranted } from 'helpers/helpers';
import { withRouter } from 'react-router-dom';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import columnConfig from './columnConfig';
import { get, post } from 'helpers/apiHelpers';
import Moment from 'moment';
import { ROLE_CREATE_MONEY_BOX_HISTORY } from 'helpers/roles';
import DataGrid from 'components/DataGrid/DataGrid';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import FormLabel from '@material-ui/core/FormLabel';

import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import CustomInput from 'components/CustomInput/CustomInput';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Divider } from '@material-ui/core';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class Moneybox extends Component {
  state = {
    openedModal: false,
    moneybox: [],
    value: '',
    event: '',
  };

  userId = this.props.userId ? this.props.userId : this.props.match.params.id;

  componentDidMount() {
    get(`clients/${this.userId}/money-box-histories`, {
      pagination: false,
      'order[id]': 'DESC',
    }).then(response => {
      this.setState({
        moneybox: response['hydra:member'],
      });
    });
  }

  calculateTotalPoints = () => {
    if (this.state.moneybox.length > 0) {
      return this.state.moneybox.reduce((a, b) => a + (b['value'] || 0), 0);
    }
  };

  closeModal = () => {
    this.setState({
      openedModal: false,
    });
  };

  newPoints = () => {
    this.setState({
      openedModal: true,
    });
  };

  renderDataTable = () =>
    this.state.moneybox.map(moneybox => {
      return {
        createdAt: new Moment(moneybox.createdAt).format('DD.MM.YYYY HH:mm:ss'),
        event: moneybox.event,
        value: moneybox.value,
      };
    });

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    return this.state.value;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('clients.fillAllFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    const data = {
      value: parseFloat(this.state.value),
      event: this.state.event,
      client: `/clients/${this.userId}`,
    };
    post('/money-box-histories', data).then(
      () => {
        this.setState({
          value: '',
          event: '',
        });
        get(`clients/${this.userId}/money-box-histories`, {
          pagination: false,
          'order[id]': 'DESC',
        }).then(response => {
          this.setState({
            moneybox: response['hydra:member'],
          });
        });
        this.props.fetchUser(this.userId);
        this.closeModal();
      },
      error => {
        if (error.response.status === 500) {
          return this.props.openToast({
            messages: [
              this.props.t('clients.smthWentWrong'),
              this.props.t('clients.checkForm'),
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
        this.props.openToast({
          messages: [error.response.data.violations[0].message],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          {this.state.moneybox && this.state.moneybox.length > 0 && (
            <h4>
              {this.props.t(
                'client.moneyBox.pointsAmount',
                'Suma punktów klienta'
              )}
              : <b>{this.state.moneybox[0]?.stateAfter ?? ''}</b>
            </h4>
          )}
          {isGranted(ROLE_CREATE_MONEY_BOX_HISTORY) && (
            <FormControlButtons
              classes={classes}
              cancelPath={this.props.noCancel ? '' : '/admin/clients'}
              discardText={this.props.t('clients.backToList')}
              handleSubmit={this.newPoints}
              submitText={this.props.t('clients.modify')}
            />
          )}
        </div>
        <Divider style={{ marginBottom: '10px' }} />
        <DataGrid
          refreshOnFetch
          incjetedData={this.state.moneybox}
          actions={false}
          url={`clients/${this.userId}/money-box-histories`}
          role={'ORDER'}
          paginationTop={false}
          paginationBottom={false}
          disableTotal
          columns={columnConfig}
        />
        <Dialog
          maxWidth={'sm'}
          fullWidth={true}
          open={this.state.openedModal}
          onClose={this.closeModal}
        >
          <DialogContent>
            <GridContainer>
              <GridItem xs={6}>
                <FormLabel className={classes.labelHorizontal}>
                  {this.props.t('clients.pointsCount')}*
                </FormLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: 'value',
                    value: this.state.value,
                    type: 'number',
                    onChange: ev => this.handleChange(ev),
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <FormLabel className={classes.labelHorizontal}>
                  {this.props.t('clients.event')}*
                </FormLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    multiline: true,
                    rowsMax: 3,
                    name: 'event',
                    value: this.state.event,
                    onChange: ev => this.handleChange(ev),
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem>
                <Button
                  className={classes.marginRight}
                  onClick={() => this.closeModal()}
                >
                  {this.props.t('clients.cancel')}
                </Button>
                <Button
                  color="success"
                  className={classes.marginLeft}
                  onClick={this.handleSubmit}
                >
                  {this.props.t('clients.add')}
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withStyles(combinedStyles),
  withToast,
  withRouter,
  withTranslation()
);

export default enhance(Moneybox);
