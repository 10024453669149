import React, { useState } from 'react';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import navPillsStyle from '../../assets/jss/material-dashboard-pro-react/components/navPillsStyle';
import BasicInfoCard from '../../components/CMS/BasicInfoCard';
import InfoBannerCard from '../../components/CMS/InfoBannerCard';
import DescriptionCard from '../../components/CMS/DescriptionCard';

const DietSubpage = ({
  classes,
  t,
  urlSlug,
  metaTitle,
  metaDescription,
  dietDescription,
  handleInputChange,
  infoBannerCheckbox,
  bannerHeader,
  bannerText,
  // infoBannerDesktop,
  // infoBannerMobile,
  handleBannerInputChange,
  getImage,
  removeImage,
  infoBannerDesktopUrl,
  infoBannerMobileUrl,
  handleChange,
}) => {
  // const [urlSlug, setUrlSlug] = useState('');
  // const [metaTitle, setMetaTitle] = useState('');
  // const [metaDescription, setMetaDescription] = useState('');
  // const [dietDescription, setDietDescription] = useState('');
  // const [infoBannerCheckbox, setInfoBannerCheckbox] = useState(false);
  // const [bannerHeader, setBannerHeader] = useState('');
  // const [bannerText, setBannerText] = useState('');
  // const [infoBannerDesktop, setInfoBannerDesktop] = useState({
  //   id: null,
  //   url: null,
  // });
  // const [infoBannerMobile, setInfoBannerMobile] = useState({
  //   id: null,
  //   url: null,
  // });

  return (
    <>
      <BasicInfoCard
        urlSlug={urlSlug}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        handleChange={handleChange}
      />

      <InfoBannerCard
        infoBannerCheckbox={infoBannerCheckbox}
        setInfoBannerCheckbox={v => handleBannerInputChange(v, 'enabled')}
        bannerHeader={bannerHeader}
        setBannerHeader={v => handleBannerInputChange(v, 'heading')}
        bannerText={bannerText}
        setBannerText={v => handleBannerInputChange(v, 'content')}
        // infoBannerDesktop={infoBannerDesktop}
        // setInfoBannerDesktop={v => handleBannerInputChange(v, 'desktop')}
        // infoBannerMobile={infoBannerMobile}
        // setInfoBannerMobile={v => handleBannerInputChange(v, 'mobile')}
        getImage={getImage}
        removeImage={removeImage}
        infoBannerMobileUrl={infoBannerMobileUrl}
        infoBannerDesktopUrl={infoBannerDesktopUrl}
      />

      <DescriptionCard
        value={dietDescription}
        setValue={value => handleInputChange(value, 'dietDescription')}
        title={t('views.diets.dietDescription')}
      />

      {/*<FormControlButtons*/}
      {/*  classes={classes}*/}
      {/*  discardText={t('form.cancel')}*/}
      {/*  submitText={t('form.save')}*/}
      {/*  cancelPath="/admin/diets"*/}
      {/*  handleSubmit={() => console.log('submit')}*/}
      {/*/>*/}
    </>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  navPillsStyle
);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(DietSubpage);
