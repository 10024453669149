import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';

import defaultState from './defaultState';
import { connect } from 'react-redux';
import { fetchMealSize } from 'actions/MealSizes';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import { post, put } from 'helpers/apiHelpers';

import { withTranslation } from 'react-i18next';
import withRole from 'components/Security/withRole';
import { ROLE_SHOW_SYSTEM_ADVANCED_DISH } from 'helpers/roles';

class Form extends Component {
  state = {
    ...defaultState,
  };

  sizeId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount() {
    if (this.isEdit) {
      this.props.fetchMealSize(this.sizeId).then(response => {
        this.setState({
          name: response.name,
          nameForClient: response.nameForClient,
          position: response.position,
          portionDivision: response.portionDivision,
        });
      });
    }
  }

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    return (
      this.state.name &&
      this.state.nameForClient &&
      this.state.position !== '' &&
      this.state.portionDivision !== ''
    );
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    const data = {
      name: this.state.name,
      nameForClient: this.state.nameForClient,
      position: parseInt(this.state.position),
      portionDivision: parseInt(this.state.portionDivision),
    };

    const action = this.isEdit
      ? put(`/sizes/${this.sizeId}`, data)
      : post('/sizes', data);

    action.then(() => this.props.history.push('/admin/sizes'));
  };

  render() {
    const { classes } = this.props;

    const AdvancedDishFormField = withRole(
      ROLE_SHOW_SYSTEM_ADVANCED_DISH,
      FormTextInput,
      true
    );

    return (
      <AdminTable title={this.props.t('form.addNewSize')} icon>
        <FormTextInput
          label={this.props.t('form.field.sizeName') + '*'}
          classes={classes}
          name="name"
          value={this.state.name}
          handleChange={this.handleInputChange}
          inputSize={4}
        />
        <FormTextInput
          label={this.props.t('form.field.nameForClient') + '*'}
          classes={classes}
          name="nameForClient"
          value={this.state.nameForClient}
          handleChange={this.handleInputChange}
          inputSize={4}
        />
        <FormTextInput
          label={this.props.t('form.field.order') + '*'}
          classes={classes}
          name="position"
          value={this.state.position}
          type="number"
          handleChange={this.handleInputChange}
          inputSize={2}
        />
        <AdvancedDishFormField
          label={this.props.t('form.field.portionDivision') + '*'}
          classes={classes}
          name="portionDivision"
          value={this.state.portionDivision}
          type="number"
          handleChange={this.handleInputChange}
          inputSize={3}
          required={true}
        />
        <FormControlButtons
          classes={classes}
          discardText={this.props.t('form.cancel')}
          submitText={this.props.t('form.save')}
          cancelPath="/admin/sizes"
          handleSubmit={this.handleSubmit}
        />
      </AdminTable>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    mealSize: state.MealSizes.mealSize,
    loading: state.MealSizes.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMealSize: id => dispatch(fetchMealSize(id)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withToast(withStyles(combinedStyles)(Form)))
);
