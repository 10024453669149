import React from 'react';
import Moment from 'moment';

import DateFilter from 'components/Grid/Filter/Date';

const columnConfig = [
  {
    title: 'clients.dateCreated',
    name: 'createdAt',
    accessor: row => {
      return row.createdAt
        ? new Moment(row.createdAt).format(
            `${Moment.localeData().longDateFormat('L')} HH:mm:ss`
          )
        : '-';
    },
    Filter: ({ onChange, filter }) => {
      return <DateFilter onChange={onChange} value={filter?.value} />;
    },
    width: 120,
    filterable: false,
    sortable: false,
  },
  {
    title: 'clients.event',
    accessor: 'event',
    name: 'event',
    sortable: false,
  },
  {
    title: 'clients.points',
    accessor: 'value',
    name: 'value',
  },
];

export default columnConfig;
