import PropTypes from 'prop-types';
import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';

//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import CardIcon from 'components/Card/CardIcon';
import SelectAll from 'components/SelectAll';
import ReportConfigurator from 'components/Report/ReportConfigurator';
import MultiplierCalculator from 'components/MultiplierCalculator/MultiplierCalculator';

import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from '../../components/Security/AccessDenied';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import moment from 'moment';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import Archive from '../../components/Archive/Archive';
import { fetchZoneCategories } from '../../actions/ZoneCategories';
import { ROLE_SHOW_ZONE_CATEGORY } from '../../helpers/roles';

const FileTypes = {
  PDF: 'application/pdf',
  XLSX: 'application/vnd.ms-excel',
  XLS: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/comma-separated-values',
};

class BaseBrandAndDateReport extends React.Component {
  state = {
    selectedBrands: [],
    selectedZoneCategories: [],
    date: '',
    dateFrom: '',
    dateTo: '',
    multiplier: 1,
    isGenerating: false,
    multiplierCalculatorOpen: false,
  };

  componentDidMount = async () => {
    if (this.props.useZoneCategories) {
      await this.props.fetchZoneCategories();
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    return this.props.useDateRange
      ? this.state.dateFrom && this.state.dateTo
      : this.state.date;
  };

  validateBrands = () => {
    return this.state.selectedBrands.length !== 0;
  };

  handleGenerate = mimeType => {
    const { t } = this.props;
    this.setState({ isGenerating: true });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t(
            'errors.raportRequiresDate',
            'Wybierz datę, dla której chcesz wygenerować raport'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (!this.validateBrands()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t('errors.raportRequireBrand', 'Wybierz przynajmniej jedną markę'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const params = {
      ...(this.props.useDateRange
        ? {
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            multiplier: this.state.multiplier,
          }
        : { date: this.state.date, multiplier: this.state.multiplier }),
      ...(this.props.additionalParams || {}),
      brands: this.state.selectedBrands.map(brand => brand.value),
      zoneCategories: this.state.selectedZoneCategories.map(
        zoneCategory => zoneCategory.value
      ),
    };

    const config = {
      [FileTypes.CSV]: {
        url: this.props.csvUrl || this.props.excelUrl,
        extension: 'csv',
      },
      [FileTypes.PDF]: {
        url: this.props.pdfUrl,
        extension: 'pdf',
      },
      [FileTypes.XLS]: {
        url: this.props.excelUrl,
        extension: 'xls',
      },
    }[mimeType] || {
      url: this.props.excelUrl,
      extension: 'xlsx',
    };

    const fileName = this.props.fileName(this.state) + '.' + config.extension;

    axios
      .get(config.url, {
        responseType: 'blob',
        params: params,
        headers: { accept: mimeType },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(
              t(
                'reports.noAccess',
                'Nie posiadasz uprawnień do wygenerowania tego raportu. Skontaktuj się z administratorem.'
              )
            );
          }
        },
        error => {
          this.props.openToast({
            messages: [
              t('reports.cannotGenerate', 'Nie udało się wygenerować raportu'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          this.setState({ isGenerating: false });
        }
      );
  };

  render() {
    const {
      classes,
      title,
      pdfAvaliable,
      csvAvailable,
      excelAvaliable,
      excelXlsAvailable,
      company,
      reportConfiguration,
    } = this.props;
    const companyId = parseInt(company.split('/').pop());
    const reportKey = reportConfiguration?.report || false;
    const fields = reportConfiguration?.fields;

    return isGranted(this.props.role) ? (
      <>
        <Card style={{ marginTop: '20px' }}>
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>
                  {this.props.t('reports.generate', 'Trwa generowanie raportu')}
                </h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <PlaylistAddCheck />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {title}{' '}
              {reportKey && (
                <ReportConfigurator
                  companyId={companyId}
                  report={reportKey}
                  fields={fields}
                />
              )}
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify="flex-start">
              <GridItem
                sm={
                  this.props.showMultiplier && this.props.useZoneCategories
                    ? 2
                    : this.props.showMultiplier || this.props.useZoneCategories
                    ? 4
                    : 6
                }
              >
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '2px' }}
                >
                  {this.props.t('reports.selectBrands')}
                </FormLabel>
                <SelectAll
                  className="input-select--brand"
                  options={this.props.brands}
                  trackBy={['@id']}
                  mapBy={'name'}
                  optionSelected={this.state.selectedBrands}
                  handleChange={selected => {
                    this.setState({
                      selectedBrands: selected,
                    });
                  }}
                />
              </GridItem>
              {isGranted(ROLE_SHOW_ZONE_CATEGORY) &&
                this.props.useZoneCategories && (
                  <GridItem sm={2}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '2px' }}
                    >
                      {this.props.t('reports.selectZoneCategories')}
                    </FormLabel>
                    <SelectAll
                      className="input-select--zone-category"
                      options={this.props.zoneCategories}
                      trackBy={'@id'}
                      mapBy={'name'}
                      optionSelected={this.state.selectedZoneCategories}
                      handleChange={selected => {
                        this.setState({
                          selectedZoneCategories: selected,
                        });
                      }}
                    />
                  </GridItem>
                )}
              {this.props.showMultiplier === true && (
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '5px' }}
                  >
                    {this.props.t('common.multiplier')}
                  </FormLabel>
                  <FormControl fullWidth>
                    <FormTextInput
                      className="input-text--multiplier"
                      classes={classes}
                      name="multiplier"
                      value={this.state.multiplier}
                      type={'number'}
                      handleChange={this.handleChange}
                      inputSize={12}
                      onFocus={() =>
                        this.setState({ multiplierCalculatorOpen: true })
                      }
                      inputRef={node => {
                        this.anchorElement = node;
                      }}
                    />
                    <MultiplierCalculator
                      classes={classes}
                      url={'/statistics/ecommerce/bags/day/'}
                      open={this.state.multiplierCalculatorOpen}
                      anchorElement={this.anchorElement}
                      brandIds={this.state.selectedBrands}
                      handleChange={(name, value) =>
                        this.handleChange({
                          target: {
                            name: name,
                            value: value,
                          },
                        })
                      }
                    />
                  </FormControl>
                </GridItem>
              )}
              {this.props.useDateRange && (
                <>
                  <GridItem sm={3}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('reports.dateFrom')}
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        className="input-datetime--date-from"
                        isValidDate={isDatepickerValidDay}
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.dateFrom)}
                        onChange={ev =>
                          this.setState({ dateFrom: ev.format('YYYY-MM-DD') })
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem sm={3}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('reports.dateTo')}
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        className="input-datetime--date-to"
                        isValidDate={isDatepickerValidDay}
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.dateTo)}
                        onChange={ev =>
                          this.setState({ dateTo: ev.format('YYYY-MM-DD') })
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </>
              )}
              {!this.props.useDateRange && (
                <>
                  <GridItem sm={6}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('reports.selectDate')}
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        className="input-datetime--date"
                        isValidDate={isDatepickerValidDay}
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.date)}
                        onChange={ev =>
                          this.setState({ date: ev.format('YYYY-MM-DD') })
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </>
              )}
              {this.props.children}
            </GridContainer>
            <GridContainer justify="flex-end">
              {pdfAvaliable && (
                <GridItem>
                  <Button
                    className="btn--generate-pdf"
                    onClick={() => this.handleGenerate(FileTypes.PDF)}
                    disabled={this.state.isGenerating}
                    color={'success'}
                    round
                  >
                    {this.props.t('reports.gPDF')}
                  </Button>
                </GridItem>
              )}
              {csvAvailable && (
                <GridItem>
                  <Button
                    className="btn--generate-csv"
                    onClick={() => this.handleGenerate(FileTypes.CSV)}
                    disabled={this.state.isGenerating}
                    color={'success'}
                    round
                  >
                    {this.props.t('reports.gCSV')}
                  </Button>
                </GridItem>
              )}
              {excelAvaliable && (
                <GridItem>
                  <Button
                    className="btn--generate-xlsx"
                    onClick={() => this.handleGenerate(FileTypes.XLSX)}
                    disabled={this.state.isGenerating}
                    color={'success'}
                    round
                  >
                    {this.props.t('reports.gExcel', { format: 'XLSX' })}
                  </Button>
                </GridItem>
              )}
              {excelXlsAvailable && (
                <GridItem>
                  <Button
                    className="btn--generate-xls"
                    onClick={() => this.handleGenerate(FileTypes.XLS)}
                    disabled={this.state.isGenerating}
                    color={'success'}
                    round
                  >
                    {this.props.t('reports.gExcel', { format: 'XLS' })}
                  </Button>
                </GridItem>
              )}
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Archive
              type={['REPORT']}
              reportName={this.props.archiveReportNames}
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapDispatchToProps = dispatch => ({
  fetchZoneCategories: () => dispatch(fetchZoneCategories()),
});

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
  zoneCategories: state.ZoneCategories.zoneCategories,
  company: state.Auth.user.company,
});

BaseBrandAndDateReport.propTypes = {
  brands: PropTypes.array,
  zoneCategories: PropTypes.array,
  classes: PropTypes.any,
  archiveReportNames: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.func.isRequired,
  showMultiplier: PropTypes.bool,
  mimeType: PropTypes.string.isRequired,
  openToast: PropTypes.any,
  title: PropTypes.string,
  useDateRange: PropTypes.bool,
  useZoneCategories: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(BaseBrandAndDateReport);
