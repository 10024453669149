import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';

import { combineStyles } from 'helpers/helpers';
import { get, put } from 'helpers/apiHelpers';
import { ROLE_EDIT_SEO } from 'helpers/roles';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DescriptionLetterCounter from './components/DescriptionLetterCounter';
import TitleLetterCounter from './components/TitleLetterCounter';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

export class SEOSettings extends Component {
  state = {
    image: null,
    imageUrl: null,
    metaTitle: '',
    indexing: false,
    metaKeywords: '',
    openGraphTitle: '',
    metaDescription: 'false',
    openGraphDescription: '',
  };

  packageId = this.props.match.params.id;

  componentDidMount = async () => {
    const response = await get(
      `/brands/${this.props.selectedBrand}/modules?module[]=Seo`
    );

    const {
      ogImage: image,
      ['@resources']: resources,
      indexing,
      metaTitle,
      ogTitle: openGraphTitle,
      metaDescription,
      ogDescription: openGraphDescription,
    } = response.configuration.Seo || {};

    const imageUrl = resources[image]?.contentUrl;

    this.setState({
      image: image?.['@id'] || null,
      indexing: indexing || false,
      imageUrl,
      metaTitle: metaTitle ? metaTitle : '',
      openGraphTitle: openGraphTitle ? openGraphTitle : '',
      metaDescription: metaDescription ? metaDescription : '',
      openGraphDescription: openGraphDescription ? openGraphDescription : '',
    });
  };

  handleInputChange = (event, value) => {
    const newValue = event.target.value;
    this.setState({ [event.target.name]: value ? value : newValue });
  };

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  handleToggle = e => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  validateForm = () => {
    return this.state.title;
  };

  handleSubmit = async () => {
    const data = {
      ogImage: this.state.image,
      indexing: this.state.indexing,
      metaTitle: this.state.metaTitle,
      ogTitle: this.state.openGraphTitle,
      metaDescription: this.state.metaDescription,
      ogDescription: this.state.openGraphDescription,
    };

    try {
      await put(`/brands/${this.props.selectedBrand}/modules`, {
        module: 'Seo',
        configuration: {
          Seo: {
            ...data,
          },
        },
      });

      this.props.openToast({
        messages: [this.props.t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (e) {
      this.props.openToast({
        messages: [this.props.t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      indexing,
      imageUrl,
      metaTitle,
      openGraphTitle,
      metaDescription,
      openGraphDescription,
    } = this.state;

    const isEditEnabled = this.props.roles
      ? this.props.roles.includes(ROLE_EDIT_SEO)
      : false;

    return (
      <>
        <AdminTable title={this.props.t('seo.settings')} icon>
          <FormTextInput
            label={<TitleLetterCounter wordsCount={metaTitle.length} />}
            classes={classes}
            name="metaTitle"
            value={metaTitle}
            handleChange={this.handleInputChange}
            inputSize={6}
          />
          <FormTextInput
            label={
              <DescriptionLetterCounter wordsCount={metaDescription.length} />
            }
            classes={classes}
            name="metaDescription"
            value={metaDescription}
            handleChange={this.handleInputChange}
            multiline
            inputSize={6}
            rowsMax={10}
          />
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('seo.indexPage')}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    name="indexing"
                    onClick={this.handleToggle}
                    checked={indexing}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={''}
              />
            </GridItem>
          </GridContainer>
          <FormTextInput
            label={this.props.t('seo.openGraphTitle')}
            classes={classes}
            name="openGraphTitle"
            value={openGraphTitle}
            handleChange={this.handleInputChange}
            inputSize={6}
          />
          <FormTextInput
            label={this.props.t('seo.openGraphDesc')}
            classes={classes}
            name="openGraphDescription"
            value={openGraphDescription}
            handleChange={this.handleInputChange}
            inputSize={6}
            rowsMax={10}
          />

          <GridContainer style={{ marginTop: '20px' }}>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('seo.openGraphPhoto')}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10} style={{ marginLeft: '-15px' }}>
              <FormImageUpload
                classes={classes}
                stateName="image"
                getImage={this.getImage}
                removeImage={this.removeImage}
                previewUrl={imageUrl}
              />
            </GridItem>
          </GridContainer>

          {isEditEnabled && (
            <FormControlButtons
              classes={classes}
              submitText={this.props.t('common.shared.save')}
              handleSubmit={this.handleSubmit}
            />
          )}
        </AdminTable>
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    roles: state.Auth.user.roles,
    selectedBrand: state.Auth.selectedBrand,
  };
};

const enhance = compose(
  connect(mapStateToProps, null),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(SEOSettings);
