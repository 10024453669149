import React, { useEffect, useState } from 'react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import navPillsStyle from 'assets/jss/material-dashboard-pro-react/components/navPillsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import InfoBannerCard from '../../components/CMS/InfoBannerCard';
import { get, put } from '../../helpers/apiHelpers';
import { connect } from 'react-redux';
import { DialogLoader } from '../../components/DialogLoader';

const AddonsCmsPage = ({ classes, t, selectedBrand }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerTitle, setBannerTitle] = useState('');
  const [bannerSubTitle, setBannerSubTitle] = useState('');
  const [bannerDesktop, setBannerDesktop] = useState(null);
  const [bannerMobile, setBannerMobile] = useState(null);

  useEffect(() => {
    (async () => {
      const {
        configuration: { SeoAddon },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoAddon`);

      const bannerDesktop = SeoAddon.bannerDesktop
        ? SeoAddon['@resources'][SeoAddon.bannerDesktop]
        : null;

      const bannerMobile = SeoAddon.bannerMobile
        ? SeoAddon['@resources'][SeoAddon.bannerMobile]
        : null;

      setEnabled(SeoAddon.enabled);
      setShowBanner(SeoAddon.showBanner);
      setBannerTitle(SeoAddon.bannerTitle);
      setBannerSubTitle(SeoAddon.bannerSubTitle);
      setBannerDesktop(bannerDesktop);
      setBannerMobile(bannerMobile);
    })();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await put(`brands/${selectedBrand}/modules`, {
        module: 'SeoAddon',
        configuration: {
          SeoAddon: {
            enabled,
            showBanner,
            bannerTitle,
            bannerSubTitle,
            bannerDesktop: bannerDesktop?.['@id'],
            bannerMobile: bannerMobile?.['@id'],
          },
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getImage = (name, data) => {
    if (name === 'infoBannerDesktop') {
      setBannerDesktop(data);
    }

    if (name === 'infoBannerMobile') {
      setBannerMobile(data);
    }
  };

  const removeImage = (name, data) => {
    if (name === 'infoBannerDesktop') {
      setBannerDesktop(null);
    }

    if (name === 'infoBannerMobile') {
      setBannerMobile(null);
    }
  };

  const handleBannerTextChange = ({ target }) => {
    if (target?.name === 'bannerHeader') {
      setBannerTitle(target?.value);
    }

    if (target?.name === 'bannerText') {
      setBannerSubTitle(target?.value);
    }
  };

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('form.basicInfo')}</h1>

              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setEnabled(!enabled)}
                    checked={enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.addons.showAddonSubpage')}{' '}
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{t('cms.addons.showAddonSubpage.tooltip')}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  </div>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <InfoBannerCard
        getImage={getImage}
        bannerText={bannerSubTitle}
        removeImage={removeImage}
        bannerHeader={bannerTitle}
        handleInputChange={handleBannerTextChange}
        infoBannerTooltip={t('cms.showInfoBanner.tooltip.addons')}
        infoBannerCheckbox={showBanner}
        infoBannerMobileUrl={bannerMobile?.contentUrl}
        infoBannerDesktopUrl={bannerDesktop?.contentUrl}
        handleCheckboxChange={() => setShowBanner(!showBanner)}
      />

      <FormControlButtons
        classes={classes}
        submitText={t('form.save')}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  navPillsStyle
);

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(AddonsCmsPage);
