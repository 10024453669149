import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {Info} from '@material-ui/icons';
import SelectInput from '../../../components/FormSelect/SelectInput';
import CardBody from '../../../components/Card/CardBody';
import Card from '../../../components/Card/Card';
import {combineStyles} from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Config = ({ t, state, getDietOptions, handleDiets, classes, handleToggle }) => {
  return (
    <Card>
      <CardBody style={{ marginBottom: '1rem' }}>
        <h5>{t('diets.configChoseMenu')}</h5>
        <SelectInput
          multiple
          classes={classes}
          label={
            <>
              {t('form.field.allowSelectMenuFromDiets')}
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <div>
                      <h4>
                        {t('form.field.allowSelectMenuFromDiets.tooltip')}
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </div>
            </>
          }
          mapBy="name"
          trackBy="@id"
          value={state.allowSelectMenuFromDiets}
          options={getDietOptions()}
          handleChange={(ev, obj) => handleDiets(ev, obj)}
        />
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              onChange={() => handleToggle('hideDisabledMenuPlannerOptions')}
              checked={state.hideDisabledMenuPlannerOptions}
              checkedIcon={<Check className={classes.checkedIcon}/>}
              icon={<Check className={classes.uncheckedIcon}/>}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          classes={{
            label: classes.label,
          }}
          label={t('form.diet.hideDisabledMenuPlannerOptions')}
        />
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(Config);
