import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { combineStyles } from '../../helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import Button from '../../components/CustomButtons/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import SweetAlert from 'react-bootstrap-sweetalert';

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

class AssignZonesWithClearAllSection extends Component {
  state = {
    alert: null,
  };
  handleAssignZones = () => {
    return this.props.handleAssignZones();
  };

  confirmModal = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={this.props.t('clients.areYouSure')}
          onConfirm={() => {
            this.hideAlert();
            this.confirmModal2();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText={this.props.t('common.shared.yes')}
          cancelBtnText={this.props.t('common.shared.no')}
          showCancel
        >
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.t('addresses.clickWithClearAll.confirm1'),
            }}
          ></div>
        </SweetAlert>
      ),
    });
  };

  confirmModal2 = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={this.props.t('clients.areYouSure')}
          onConfirm={() => {
            this.hideAlert();
            this.handleAssignZones();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText={this.props.t('common.shared.yes')}
          cancelBtnText={this.props.t('common.shared.no')}
          showCancel
        >
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.t('addresses.clickWithClearAll.confirm2'),
            }}
          ></div>
        </SweetAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const { t } = this.props;

    return (
      <GridContainer>
        {this.state.alert}
        <GridItem md={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color={'primary'}
              onClick={this.confirmModal}
              disabled={this.props.disabled}
            >
              {t('addresses.assignZonesWithClearAll')}
            </Button>
            <div style={{ marginLeft: '10px' }}>
              <Tooltip
                title={
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: t('addresses.clickWithClearAll'),
                    }}
                  />
                }
                placement="right"
              >
                <Info
                  fontSize="small"
                  style={{
                    color: 'grey',
                    display: 'block',
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

const combinedStyles = combineStyles(styles, buttonsStyle, extendedFormsStyle);
const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(AssignZonesWithClearAllSection);
