import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Tooltip } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import useCheckIsPaymentDisabled from 'helpers/useCheckIsPaymentDisabled';

const StripeModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();
  const stripeCard = useCheckIsPaymentDisabled('STRIPE_CARD');
  const stripeLink = useCheckIsPaymentDisabled('STRIPE_LINK');

  useEffect(() => {
    if (stripeCard.isSelected) {
      handleModuleUpdate({
        ...module,
        cardPayments: true,
      });
    }
  }, [stripeCard.isSelected]);

  useEffect(() => {
    if (stripeLink.isSelected) {
      handleModuleUpdate({
        ...module,
        linkPayments: true,
      });
    }
  }, [stripeLink.isSelected]);

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'StripeModule',
        })}
      </div>
    );
  }

  return (
    <Fragment>
      <GridContainer style={{ width: '100%', margin: '0' }}>
        <GridItem xs={3}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              style={{ marginLeft: '-14px' }}
              onClick={() =>
                handleModuleUpdate({
                  ...module,
                  cardPayments: !module.cardPayments,
                })
              }
              checked={module.cardPayments}
              disabled={stripeCard.isSelected}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />

            <span>
              {t(
                '$*config.modules.Stripe.cardPayments',
                '$$Stripe cardPayments'
              )}
            </span>

            {stripeCard.isSelected && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <h4>
                      {t(
                        `brandCfg.payments.disabled.${stripeCard.paymentName}.tooltip`
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info
                    fontSize="small"
                    style={{ color: 'grey', display: 'block' }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </GridItem>
        <GridItem xs={3}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              style={{ marginLeft: '-14px' }}
              onClick={() =>
                handleModuleUpdate({
                  ...module,
                  linkPayments: !module.linkPayments,
                })
              }
              checked={module.linkPayments}
              disabled={stripeLink.isSelected}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />

            <span>
              {t(
                '$*config.modules.Stripe.linkPayments',
                '$$Stripe linkPayments'
              )}
            </span>
            {stripeLink.isSelected && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <h4>
                      {t(
                        `brandCfg.payments.disabled.${stripeLink.paymentName}.tooltip`
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info
                    fontSize="small"
                    style={{ color: 'grey', display: 'block' }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </GridItem>
      </GridContainer>
      <GridItem xs={6}>
        <FormTextInput
          label="Publishable Key"
          classes={classes}
          value={module.publishableKey}
          handleChange={e =>
            handleModuleUpdate({ ...module, publishableKey: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Secret Key"
          classes={classes}
          value={module.secretKey}
          handleChange={e =>
            handleModuleUpdate({ ...module, secretKey: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Webhook Signing Secret"
          classes={classes}
          value={module.webhookSecret}
          handleChange={e =>
            handleModuleUpdate({ ...module, webhookSecret: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label={t(
            'config.modules.Stripe.subscriptionConditionsAddress',
            'Adres do regulaminu płatności cyklicznych'
          )}
          classes={classes}
          value={module.rulesLink}
          handleChange={e =>
            handleModuleUpdate({
              ...module,
              rulesLink: e.target.value,
            })
          }
          inputSize={12}
          maxLength={255}
        />
      </GridItem>
    </Fragment>
  );
};

export default StripeModule;
