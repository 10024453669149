import React from 'react';

// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import CardHeader from 'components/Card/CardHeader';
import withStyles from '@material-ui/core/styles/withStyles';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const AdminTable = ({
  children,
  classes,
  title,
  icon,
  iconComponent,
  settingsComponent,
  iconColor = 'primary',
}) => (
  <GridContainer style={{ marginTop: '15px' }}>
    <GridItem xs={12}>
      <Card>
        <CardHeader color="primary" icon>
          {icon && (
            <CardIcon color={iconColor}>
              {iconComponent ? iconComponent : <Assignment />}
            </CardIcon>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h4 className={classes.cardIconTitle}>{title}</h4>
            <div style={{ marginTop: '15px' }}>{settingsComponent}</div>
          </div>
        </CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    </GridItem>
  </GridContainer>
);

export default withStyles(styles)(AdminTable);
