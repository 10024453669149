import React, { Component } from 'react';
import * as ol from 'openlayers';
import axios from 'axios';

const defaultProps = {
  x: 52.2721099,
  y: 20.726582,
  width: '100%',
  height: '100%',
  zoom: 10,
  handleChange: (x, y) => {
    console.log(`Location changed: [${x}, ${y}] `);
  },
};

const optProjection = 'EPSG:900913';

class Map extends Component {
  constructor(props) {
    super(props);

    this.mapArea = React.createRef();
  }

  mapIsCalling = false;
  mapNeedRefresh = false;
  scheduledQueries = [];
  lastQuery = '';

  computeProps = () => {
    return { ...defaultProps, ...this.props };
  };

  componentDidMount() {
    this.loadMap();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let needUpdate = false;
    const props = ['x', 'y', 'zoom'];
    props.forEach(prop => {
      if (!needUpdate && prevProps[prop] !== this.props[prop]) {
        needUpdate = true;
      }
    });

    if (this.map && needUpdate) {
      this.reloadMap();
    }
  }

  reloadMap = () => {
    const { zoom, x, y } = this.computeProps();

    this.marker.setGeometry(
      new ol.geom.Point(ol.proj.fromLonLat([y, x], optProjection))
    );
    this.map.getView().setCenter(ol.proj.fromLonLat([y, x], optProjection));
    this.map.getView().setZoom(zoom);
  };

  loadMap = () => {
    const props = this.computeProps();

    this.map = new ol.Map({
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM(),
        }),
      ],
      target: this.mapArea.current,
      view: new ol.View({
        center: ol.proj.fromLonLat([props.y, props.x], optProjection),
        zoom: props.zoom,
      }),
    });

    this.marker = new ol.Feature({
      geometry: new ol.geom.Point(
        ol.proj.fromLonLat([props.y, props.x], optProjection)
      ),
    });

    const vectorSource = new ol.source.Vector({
      features: [this.marker], //add an array of features
    });

    const iconStyle = new ol.style.Style({
      image: new ol.style.Icon(
        /** @type {olx.style.IconOptions} */ ({
          anchor: [0.5, 32],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: 'http://openlayers.org/api/img/marker.png',
        })
      ),
    });

    this.markers = new ol.layer.Vector({
      source: vectorSource,
      style: iconStyle,
    });

    const dragInteraction = new ol.interaction.Modify({
      features: new ol.Collection([this.marker]),
      style: null,
      pixelTolerance: 20,
    });

    dragInteraction.on(
      'modifyend',
      () => {
        let cords = ol.proj.transform(
          this.marker.getGeometry().getCoordinates(),
          'EPSG:3857',
          'EPSG:4326'
        );
        props.handleChange(cords[1], cords[0]);
      },
      this.marker
    );

    this.map.addLayer(this.markers);
    this.map.addInteraction(dragInteraction);
  };

  findLocation = location => {
    if (this.lastQuery === location) {
      return;
    }

    if (this.mapIsCalling) {
      this.scheduledQueries.push(location);
      this.mapNeedRefresh = true;
      return;
    }
    this.mapIsCalling = true;
    this.lastQuery = location;
    const { handleChange } = this.computeProps();

    let url =
      'https://nominatim.openstreetmap.org/search?format=json&q=' + location;
    axios.get(url, { withCredentials: false }).then(
      response => {
        let locations = response.data;
        if (locations.length > 0) {
          handleChange(locations[0].lat, locations[0].lon);
        }
        this.mapIsCalling = false;

        if (this.mapNeedRefresh) {
          this.scheduledMapRefresh();
        }
      },
      response => {
        console.error(response);
      }
    );
  };

  scheduledMapRefresh = () => {
    const query = this.scheduledQueries.pop();
    this.scheduledQueries = [];
    this.mapNeedRefresh = false;
    this.findLocation(query);
  };

  render() {
    const { width, height } = this.computeProps();

    return <div ref={this.mapArea} style={{ width, height }}></div>;
  }
}

export default Map;
