import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class FoodCostBags extends React.Component {
  getFileName = title => props => {
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${props.date}_(${moment().format(
      'YYYY-MM-DD_HH.mm'
    )})`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  render() {
    const { t } = this.props;
    const title = t('reports.reportFoodCostBags');

    return (
      <BaseBrandAndDateReport
        title={title}
        excelUrl="reports/food-cost-bags"
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_FOOD_COST_BAGS}
        pdfAvaliable={false}
        excelAvaliable={true}
        useDateRange={true}
        archiveReportNames={['FOOD_COST_BAGS']}
      />
    );
  }
}

export default withTranslation()(FoodCostBags);
