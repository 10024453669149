import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

class TotalBel extends React.Component {
  getFileName = title => ({ date }) => {
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${date}_(${moment().format('YYYY-MM-DD_HH.mm')})`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  render() {
    const { t } = this.props;
    const title = t('reports.reportTotalBel');
    return (
      <BaseBrandAndDateReport
        title={title}
        excelUrl="reports/total-bel"
        mimeType="application/vnd.ms-excel"
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_TOTAL_BEL}
        excelAvaliable
        reportConfiguration={{
          report: 'TotalBel',
          fields: [
            {
              size: 12,
              field: 'groupAddressStreet',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
          ],
        }}
        useZoneCategories={true}
        archiveReportNames={['TOTAL_BEL']}
      />
    );
  }
}

export default withTranslation()(TotalBel);
