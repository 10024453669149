import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_MEAL_TYPE } from 'helpers/roles';

import { withTranslation } from 'react-i18next';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);
  render() {
    const { classes } = this.props;

    return (
      <AdminTable title={this.props.t('list.mealTypesList')} icon>
        {isGranted(ROLE_CREATE_MEAL_TYPE) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newSize}
            submitText={this.props.t('list.addMealType') + ' +'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          defaultSorted={[{ id: 'position', desc: false }]}
          url="meal-types"
          reportName={'mealtype'}
          columns={columnConfig}
          role="MEAL_TYPE"
        />
      </AdminTable>
    );
  }
}

export default withTranslation()(withStyles(buttonsStyle)(List));
