const defaultState = {
  name: '',
  workName: '',
  shortName: '',
  weight: '',
  calories: '',
  fat: '',
  carbohydrates: '',
  monosaccharide: '',
  proteins: '',
  dietaryFiber: '',
  salt: '',
  nkt: '',
  note: null,
  homeIngredient: false,
  allert: false,
  wot: 100,
  wom: 100,
  price: '',
  pricePerUnit: '',
  pricePerKg: '',
  inventoryAmount: '',
  hermesCardIndex: '',
  glycemicIndex: '',
  allergens: [],
  unit: null,
  categories: [],
  wholesaler: null,
  historyHidden: true,
};

export default defaultState;
