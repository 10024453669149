import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import React, { useEffect, useState } from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import navPillsStyle from '../../assets/jss/material-dashboard-pro-react/components/navPillsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { DialogLoader } from '../../components/DialogLoader';
import Button from '../../components/CustomButtons/Button';
import BasicInfoCard from '../../components/CMS/BasicInfoCard';
import InfoBannerCard from '../../components/CMS/InfoBannerCard';
import DescriptionCard from '../../components/CMS/DescriptionCard';
import { get, put } from '../../helpers/apiHelpers';
import { connect } from 'react-redux';

// const DELIVERY_ZONES = 'DELIVERY_ZONES';
// const ZONES_LIST = 'ZONES_LIST';

const ZonesCmsPage = ({ classes, t, selectedBrand }) => {
  // const [subpage, setSubpage] = useState(DELIVERY_ZONES);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enabled, setEnabled] = useState(false);
  // const [
  //   listOfDeliveryZonesSubpageCheckbox,
  //   setListOfDeliveryZonesSubpageCheckbox,
  // ] = useState(false);
  // const [localitiesCheckbox, setLocalitiesCheckbox] = useState(false);
  const [showDeliveryHours, setShowDeliveryHours] = useState(false);
  const [showPrices, setShowPrices] = useState(false);

  // const [urlSlug, setUrlSlug] = useState('');
  // const [metaTitle, setMetaTitle] = useState('');
  // const [metaDescription, setMetaDescription] = useState('');
  // const [deliveryZonesDescription, setDeliveryZonesDescription] = useState('');
  // const [infoBannerCheckbox, setInfoBannerCheckbox] = useState(false);
  // const [bannerHeader, setBannerHeader] = useState('');
  // const [bannerText, setBannerText] = useState('');
  // const [infoBannerDesktop, setInfoBannerDesktop] = useState({
  //   id: null,
  //   url: null,
  // });
  // const [infoBannerMobile, setInfoBannerMobile] = useState({
  //   id: null,
  //   url: null,
  // });

  useEffect(() => {
    (async () => {
      const {
        configuration: { SeoZone },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoZone`);

      setEnabled(SeoZone.enabled);
      setShowDeliveryHours(SeoZone.showDeliveryHours);
      setShowPrices(SeoZone.showPrices);
    })();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await put(`brands/${selectedBrand}/modules`, {
        module: 'SeoZone',
        configuration: {
          SeoZone: {
            enabled,
            showDeliveryHours,
            showPrices,
          },
        },
      });

      console.log(response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />
      {/*<DialogLoader*/}
      {/*  loaderState={this.state.isLoading}*/}
      {/*  text={t('form.loadingMeal')}*/}
      {/*/>*/}

      {/*<GridContainer>*/}
      {/*  <GridItem xs={12}>*/}
      {/*    <h2 style={{ display: 'block' }}>*/}
      {/*      <div>*/}
      {/*        <Button*/}
      {/*          onClick={() => setSubpage(DELIVERY_ZONES)}*/}
      {/*          color={subpage === DELIVERY_ZONES ? 'info' : ''}*/}
      {/*        >*/}
      {/*          {t('cms.zones.deliveryZones')}*/}
      {/*        </Button>*/}

      {/*        <Button*/}
      {/*          onClick={() => setSubpage(ZONES_LIST)}*/}
      {/*          color={subpage === ZONES_LIST ? 'info' : ''}*/}
      {/*        >*/}
      {/*          {t('cms.zones.zonesList')}*/}
      {/*        </Button>*/}
      {/*      </div>*/}
      {/*    </h2>*/}
      {/*  </GridItem>*/}
      {/*</GridContainer>*/}

      {/*{subpage === DELIVERY_ZONES && (*/}
      {/*  <>*/}
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('form.basicInfo')}</h1>
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setEnabled(!enabled)}
                    checked={enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.zones.showDeliveryZoneSubpages')}
                  </div>
                }
              />
            </GridItem>

            {/*<GridItem md={12}>*/}
            {/*  <FormControlLabel*/}
            {/*    control={*/}
            {/*      <Checkbox*/}
            {/*        onClick={() =>*/}
            {/*          setListOfDeliveryZonesSubpageCheckbox(*/}
            {/*            !listOfDeliveryZonesSubpageCheckbox*/}
            {/*          )*/}
            {/*        }*/}
            {/*        checked={listOfDeliveryZonesSubpageCheckbox}*/}
            {/*        checkedIcon={<Check className={classes.checkedIcon} />}*/}
            {/*        icon={<Check className={classes.uncheckedIcon} />}*/}
            {/*        classes={{*/}
            {/*          checked: classes.checked,*/}
            {/*          root: classes.checkRoot,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    }*/}
            {/*    label={*/}
            {/*      <div style={{ display: 'flex' }}>*/}
            {/*        {t('cms.zones.showListOfDeliveryZonesSubpage')}*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*  />*/}
            {/*</GridItem>*/}
          </GridContainer>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('cms.zones.infoVisibility')}</h1>
            </GridItem>

            {/*<GridItem md={12}>*/}
            {/*  <FormControlLabel*/}
            {/*    control={*/}
            {/*      <Checkbox*/}
            {/*        onClick={() => setLocalitiesCheckbox(!localitiesCheckbox)}*/}
            {/*        checked={localitiesCheckbox}*/}
            {/*        checkedIcon={<Check className={classes.checkedIcon} />}*/}
            {/*        icon={<Check className={classes.uncheckedIcon} />}*/}
            {/*        classes={{*/}
            {/*          checked: classes.checked,*/}
            {/*          root: classes.checkRoot,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    }*/}
            {/*    label={*/}
            {/*      <div style={{ display: 'flex' }}>*/}
            {/*        {t('cms.zones.showLocalities')}*/}
            {/*      </div>*/}
            {/*    }*/}
            {/*  />*/}
            {/*</GridItem>*/}

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowDeliveryHours(!showDeliveryHours)}
                    checked={showDeliveryHours}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.zones.showDeliveryTime')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowPrices(!showPrices)}
                    checked={showPrices}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.zones.showDeliveryPrice')}
                  </div>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {/*  </>*/}
      {/*)}*/}

      {/*{subpage === ZONES_LIST && (*/}
      {/*  <>*/}
      {/*    <BasicInfoCard*/}
      {/*      urlSlug={urlSlug}*/}
      {/*      setUrlSlug={setUrlSlug}*/}
      {/*      metaTitle={metaTitle}*/}
      {/*      setMetaTitle={setMetaTitle}*/}
      {/*      metaDescription={metaDescription}*/}
      {/*      setMetaDescription={setMetaDescription}*/}
      {/*    />*/}

      {/*    <InfoBannerCard*/}
      {/*      infoBannerCheckbox={infoBannerCheckbox}*/}
      {/*      setInfoBannerCheckbox={setInfoBannerCheckbox}*/}
      {/*      bannerHeader={bannerHeader}*/}
      {/*      setBannerHeader={setBannerHeader}*/}
      {/*      bannerText={bannerText}*/}
      {/*      setBannerText={setBannerText}*/}
      {/*      infoBannerDesktop={infoBannerDesktop}*/}
      {/*      setInfoBannerDesktop={setInfoBannerDesktop}*/}
      {/*      infoBannerMobile={infoBannerMobile}*/}
      {/*      setInfoBannerMobile={setInfoBannerMobile}*/}
      {/*    />*/}

      {/*    <DescriptionCard*/}
      {/*      value={deliveryZonesDescription}*/}
      {/*      setValue={setDeliveryZonesDescription}*/}
      {/*      title={t('cms.zones.deliveryZonesDescription')}*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}

      <FormControlButtons
        classes={classes}
        submitText={t('form.save')}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  navPillsStyle
);

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(ZonesCmsPage);
