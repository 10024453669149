import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_EMPLOYEE } from 'helpers/roles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchRoles } from 'actions/Roles';
import { fetchBrandsList } from 'actions/Brands';
import { withTranslation } from 'react-i18next';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);

  componentDidMount() {
    this.props.fetchRoles();
    this.props.fetchBrandsList();
  }

  render() {
    const { classes } = this.props;

    return (
      <AdminTable title={this.props.t('employees.list')} icon>
        {isGranted(ROLE_CREATE_EMPLOYEE) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newSize}
            submitText={this.props.t('employees.add') + '+'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/employees"
          reportName={'employee'}
          role="EMPLOYEE"
          columns={columnConfig(
            this.props.t,
            this.props.roles,
            this.props.brands
          )}
          defaultHiddenColumns={['brands.id']}
          canRemoveChecker={(canRemove, row) => {
            return canRemove && this.props.loggedUserId !== row['@id'];
          }}
        />
      </AdminTable>
    );
  }
}

const mapStateToProps = state => ({
  loggedUserId: state.Auth.user.id,
  roles: state.Roles.roles,
  brands: state.Brands.brandsList,
});

const mapDispatchToProps = { fetchRoles, fetchBrandsList };

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withStyles(buttonsStyle)
);

export default enhance(List);
