import { useState, useEffect } from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  fetchPaymentTypes,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { modulesNames, modulesDefaultState } from './consts';
import Check from '@material-ui/icons/Check';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import { Info } from '@material-ui/icons';
import {
  Caching,
  ConfigClientPanelModule,
  PaymentModesCopyModule,
} from './modules';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import Card from '../../components/Card/Card';
import React from 'react';
import produce from 'immer';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import ArrowRight from '../../assets/img/arrow-right.svg';
import ArrowDown from '../../assets/img/arrow-down.svg';
import { put } from '../../helpers/apiHelpers';
import { DialogLoader } from '../../components/DialogLoader';
import AvailableSoonOverlay from './components/availableSoonOverlay';
import ShareRecipesSettings from '../Shop/ShareRecipesSettings';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';

const tableRowNames = [
  'accountCreate',
  'newDietOrder',
  'orderVerification',
  'orderStatusChange',
  'subscriptionRenewal',
  'subscriptionRenewalFailure',
  'dietEditOutOfTime',
  'mealEdit',
  'orderPlacementReturnPoints',
  'orderPlacementSubsidy',
  'bagDelivery',
  'forgottenPassword',
  'accountDeletion',
];

const PanelConfiguration = ({
  classes,
  t,
  selectedBrand,
  fetchBrand,
  fetchBrandConfigModulesPack,
  fetchPaymentTypes,
  paymentTypes,
  openToast,
  updateBrandConfigModulesPack,
}) => {
  const [state, setState] = useState({
    allChangesWithoutCosts: false,
    allowChangeAddress: false,
    allowChangeCalorific: false,
    allowChangeVariant: false,
    allowChangeDeliveryDate: false,
    allowChangeMultipleDays: false,
    allowChangeDiet: false,
    showInformation: {
      dishImagePlaceholder: null,
      macroIngredients: false,
      dishCalorific: false,
      dishImage: false,
      allergens: false,
      dishComposition: false,
      tags: false,
      servingProposal: false,
      avgDishRating: false,
      glycemicIndex: { show: false, tooltip: '' },
    },
    showRefererCode: false,
    showFacebookCodeReference: true,
    showLinkedinCodeReference: true,
    showTwitterCodeReference: true,
    showWhatsappCodeReference: true,
    showMessengerCodeReference: true,
    facebookCodeReferenceLink: '',
    linkedinCodeReferenceLink: '',
    whatsappCodeReferenceLink: '',
    twitterCodeReferenceLink: '',
    messengerCodeReferenceLink: '',
    // TODO: check if variable names work with BE
    sharingRecipes: {
      orderForm: {
        printRecipe: false,
        downloadRecipe: false,
        showRecipe: false,
        showMealSubpage: false,
      },
      clientPanel: {
        printRecipe: false,
        downloadRecipe: false,
        showRecipe: false,
        showMealSubpage: false,
      },
    },
    customScripts: '',
  });
  // const [modules, setModules] = useState(null);
  // const [shopModule, setShopModule] = useState({});
  const [configClientPanelModule, setConfigClientPanelModule] = useState({});
  const [paymentModesCopyModule, setPaymentModesCopyModule] = useState(null);
  const [cachingModule, setCachingModule] = useState(null);
  // TODO: check if variable names match the one on BE
  const [notifications, setNotifications] = useState({
    accountCreate: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    newDietOrder: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    orderVerification: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    orderStatusChange: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    subscriptionRenewal: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    subscriptionRenewalFailure: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    dietEditOutOfTime: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    mealEdit: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    orderPlacementReturnPoints: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    orderPlacementSubsidy: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    bagDelivery: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    forgottenPassword: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
    accountDeletion: {
      allowNotifications: false,
      email: { onByDefault: false, possibleToEditOnClientAccount: false },
      sms: { onByDefault: false, possibleToEditOnClientAccount: false },
    },
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(selectedBrand, [
        // 'Shop',
        'ConfigClientPanel',
        'PaymentModesCopy',
        'Caching',
      ]);

      // const {
      //   '@id': idIri,
      //   '@type': typeIri,
      //   ...restShopVariables
      // } = modulesConfig.configuration.Shop;
      const {
        '@id': idCcpIri,
        '@type': typeCcpIri,
        ...restConfigClientPanelVariables
      } = modulesConfig.configuration.ConfigClientPanel;

      // setShopModule(restShopVariables);
      setConfigClientPanelModule(restConfigClientPanelVariables);
      setPaymentModesCopyModule(modulesConfig.configuration.PaymentModesCopy);
      setCachingModule(modulesConfig.configuration.Caching);

      await fetchPaymentTypes(selectedBrand);

      setState(prevState => ({
        ...prevState,
        allChangesWithoutCosts: brandConfig.allChangesWithoutCosts,
        allowChangeAddress: brandConfig.allowChangeAddress,
        allowChangeVariant: brandConfig.allowChangeVariant,
        allowChangeCalorific: brandConfig.allowChangeCalorific,
        allowChangeDeliveryDate: brandConfig.allowChangeDeliveryDate,
        allowChangeDiet: brandConfig.allowChangeDiet,
        allowChangeMultipleDays: brandConfig.allowChangeMultipleDays,
        showInformation: brandConfig.showInformation,
        showFacebookCodeReference: brandConfig.showFacebookCodeReference,
        showLinkedinCodeReference: brandConfig.showLinkedinCodeReference,
        showMessengerCodeReference: brandConfig.showMessengerCodeReference,
        showRefererCode: brandConfig.showRefererCode,
        showTwitterCodeReference: brandConfig.showTwitterCodeReference,
        showWhatsappCodeReference: brandConfig.showWhatsappCodeReference,
        facebookCodeReferenceLink: brandConfig.facebookCodeReferenceLink
          ? brandConfig.facebookCodeReferenceLink
          : brandConfig.homePageLink,
        linkedinCodeReferenceLink: brandConfig.linkedinCodeReferenceLink
          ? brandConfig.linkedinCodeReferenceLink
          : brandConfig.homePageLink,
        whatsappCodeReferenceLink: brandConfig.whatsappCodeReferenceLink
          ? brandConfig.whatsappCodeReferenceLink
          : brandConfig.homePageLink,
        twitterCodeReferenceLink: brandConfig.twitterCodeReferenceLink
          ? brandConfig.twitterCodeReferenceLink
          : brandConfig.homePageLink,
        messengerCodeReferenceLink: brandConfig.messengerCodeReferenceLink
          ? brandConfig.messengerCodeReferenceLink
          : brandConfig.homePageLink,
        // deliveryToDoorEnabled: brandConfig.deliveryToDoorEnabled,
        // pickUpPointEnabled: brandConfig.pickUpPointEnabled,
        customScripts: brandConfig.customScripts,
      }));

      // setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleChangeDietViewOption = ({ target: { name } }) => {
    setState(
      produce(draft => {
        draft.showInformation[name] = !draft.showInformation[name];
      })
    );
  };

  const handleGlycemicIndexChange = ({ target: { name, value } }) => {
    setState(
      produce(draft => {
        const isBoolean = ['show'].includes(name);
        const glycemicIndex = draft.showInformation.glycemicIndex;

        glycemicIndex[name] = isBoolean ? !glycemicIndex[name] : value;
      })
    );
  };

  const handleInputChange = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const setDishImagePlaceholder = (fieldName, data) => {
    setConfigClientPanelModule({
      ...configClientPanelModule,
      '@resources': {
        ...(configClientPanelModule?.['@resources'] ?? {}),
        [data?.['@id']]: { ...data },
      },
      [fieldName]: data?.['@id'] ?? null,
    });
  };

  const removeDishImagePlaceholder = fieldName => {
    setConfigClientPanelModule({
      ...configClientPanelModule,
      [fieldName]: null,
    });
  };

  const handleChangeRecipeSharingOptions = (e, category) => {
    setState(prevState => ({
      ...prevState,
      sharingRecipes: {
        ...prevState.sharingRecipes,
        [category]: {
          ...prevState.sharingRecipes[category],
          [e.target.name]: !state.sharingRecipes[category][e.target.name],
        },
      },
    }));
  };

  const handleNotificationsChange = (
    category,
    property,
    innerProperty = null
  ) => {
    if (innerProperty) {
      setNotifications(prevState => ({
        ...prevState,
        [category]: {
          ...prevState[category],
          [property]: {
            ...prevState[category][property],
            [innerProperty]: !prevState[category][property][innerProperty],
          },
        },
      }));
    } else {
      setNotifications(prevState => ({
        ...prevState,
        [category]: {
          ...prevState[category],
          [property]: !prevState[category][property],
        },
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      await put(`/brands/${selectedBrand}`, { ...state, ...notifications });
      await updateBrandConfigModulesPack(selectedBrand, {
        // Shop: shopModule,
        ConfigClientPanel: configClientPanelModule,
        PaymentModesCopy: paymentModesCopyModule,
        Caching: cachingModule,
        ItemPaymentTypes: paymentTypes,
      });

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 300,
      });
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          {/* CLIENT PANEL GENERAL SETTINGS */}
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <h4>
                    {t(
                      'brands.newBrandForm.availableOptions',
                      'Opcje zmian w panelu klienta'
                    )}
                  </h4>
                  <Divider style={{ marginBottom: '20px' }} />
                </GridItem>
                <GridItem md={6}>
                  <GridContainer>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.allChangesWithoutCosts}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                allChangesWithoutCosts: !state.allChangesWithoutCosts,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.freeChanges',
                          'Wszystkie poniższe zmiany są bezpłatne'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.allowChangeMultipleDays}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                allowChangeMultipleDays: !state.allowChangeMultipleDays,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.manyDays',
                          'Klient ma możliwość zmiany wielu dni diety jednocześnie'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.allowChangeAddress}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                allowChangeAddress: !state.allowChangeAddress,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.addressChange',
                          'Klient ma możliwość zmiany adresu'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.allowChangeDeliveryDate}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                allowChangeDeliveryDate: !state.allowChangeDeliveryDate,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.dateChange',
                          'Klient ma możliwość zmiany daty dostawy'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.allowChangeDiet}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                allowChangeDiet: !state.allowChangeDiet,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.dietChange',
                          'Klient ma możliwość zmiany diety'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <h4>
                        {t(
                          'brands.newBrandForm.dietChangeDescription',
                          'Tekst na zmianach diety w panelu klienta'
                        )}
                        <Tooltip
                          title={
                            <h4>
                              {t(
                                'brands.newBrandForm.dietChangeDescription.tooltip',
                                {
                                  interpolation: {
                                    escapeValue: true,
                                    prefix: '{{{',
                                    suffix: '}}}',
                                  },
                                }
                              )}
                            </h4>
                          }
                          placement="right"
                        >
                          <Info
                            fontSize="small"
                            style={{
                              color: 'grey',
                              verticalAlign: 'sub',
                              marginLeft: '0.25rem',
                            }}
                          />
                        </Tooltip>
                      </h4>
                      <Divider style={{ marginBottom: '20px' }} />

                      <FormTextInput
                        multiline
                        classes={classes}
                        name="needToChangeDietDescription"
                        value={
                          configClientPanelModule?.needToChangeDietDescription
                        }
                        maxLength={5000}
                        rows={10}
                        handleChange={event => {
                          const needToChangeDietDescription =
                            event.target.value !== ''
                              ? event.target.value
                              : null;

                          setConfigClientPanelModule(prevState => ({
                            ...prevState,
                            needToChangeDietDescription,
                          }));
                          // handleModuleUpdate({
                          //   ...modules[modulesNames.ConfigClientPanel],
                          //   needToChangeDietDescription,
                          // });
                        }}
                        inputSize={12}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              configClientPanelModule.allowClientOwnDietName ||
                              false
                            }
                            onChange={
                              () =>
                                setConfigClientPanelModule(prevState => ({
                                  ...prevState,
                                  allowClientOwnDietName: !prevState.allowClientOwnDietName,
                                }))
                              // handleModuleUpdate({
                              //   ...modules[modulesNames.ConfigClientPanel],
                              //   allowClientOwnDietName: !modules[
                              //     modulesNames.ConfigClientPanel
                              //   ].allowClientOwnDietName,
                              // })
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.allowClientOwnDietName',
                          'Zezwól klientowi na własne nazwy diet'
                        )}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem md={6}>
                  <GridContainer>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.allowChangeVariant}
                            onChange={() => {
                              !state.allowChangeVariant
                                ? setState(prevState => ({
                                    ...prevState,
                                    allowChangeVariant: !state.allowChangeVariant,
                                    allowChangeCalorific: true,
                                  }))
                                : setState(prevState => ({
                                    ...prevState,
                                    allowChangeVariant: !state.allowChangeVariant,
                                  }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.variantChange',
                          'Klient ma możliwość zmiany wariantu'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.allowChangeCalorific}
                            disabled={state.allowChangeVariant}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                allowChangeCalorific: !state.allowChangeCalorific,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.calorificChange',
                          'Klient ma możliwość zmiany kaloryczności'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              configClientPanelModule?.allowClientPayWithMoneboxEverywhere
                            }
                            onChange={
                              () =>
                                setConfigClientPanelModule(prevState => ({
                                  ...prevState,
                                  allowClientPayWithMoneboxEverywhere: !prevState.allowClientPayWithMoneboxEverywhere,
                                }))

                              // handleModuleUpdate({
                              //   ...modules[modulesNames.ConfigClientPanel],
                              //   allowClientPayWithMoneboxEverywhere: !modules?.[
                              //     modulesNames.ConfigClientPanel
                              //   ]?.allowClientPayWithMoneboxEverywhere,
                              // })
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.allowClientPayWithMoneboxEverywhere',
                          'Klient ma możliwość płacenia punktami ze skarbonki za zmiany w panelu klienta'
                        )}
                      />
                    </GridItem>

                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              configClientPanelModule?.clientCanCancelSubscription
                            }
                            onChange={
                              () =>
                                setConfigClientPanelModule(prevState => ({
                                  ...prevState,
                                  clientCanCancelSubscription: !prevState.clientCanCancelSubscription,
                                }))
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'module.configClientPanel.clientCanCancelSubscription',
                          'Klient ma możliwość samodzielnego anulowania subskrypcji'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              configClientPanelModule?.clientCanRemoveAccount
                            }
                            onChange={
                              () =>
                                setConfigClientPanelModule(prevState => ({
                                  ...prevState,
                                  clientCanRemoveAccount: !prevState.clientCanRemoveAccount,
                                }))
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'module.configClientPanel.clientCanRemoveAccount',
                          'Klient ma możliwość samodzielnego skasowania konta'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              configClientPanelModule?.filterShownMenuByDiet
                            }
                            onChange={() =>
                              setConfigClientPanelModule(prevState => ({
                                ...prevState,
                                filterShownMenuByDiet: !prevState.filterShownMenuByDiet,
                              }))
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.filterShownMenuByDiet',
                          'Przefiltruj wyświetlane menu przez wybraną dietę'
                        )}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          {/* DISH DETAILS */}
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <h4>
                    {t(
                      'brands.newBrandForm.dietDayDetail',
                      'Widoczność szczegółów dania'
                    )}
                  </h4>
                  <Divider style={{ marginBottom: '20px' }} />
                </GridItem>

                <GridItem md={6}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="dishCalorific"
                            checked={state.showInformation.dishCalorific}
                            onChange={handleChangeDietViewOption}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.caloricContent',
                          'Kaloryczność dania'
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="macroIngredients"
                            checked={state.showInformation.macroIngredients}
                            onChange={handleChangeDietViewOption}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.macronutrients',
                          'Makroskładniki (białka, węglowodany, tłuszcze)'
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="allergens"
                            checked={state.showInformation.allergens}
                            onChange={handleChangeDietViewOption}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t('common.mainMenu.allergens', 'Alergeny')}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="show"
                            checked={state.showInformation.glycemicIndex?.show}
                            onChange={handleGlycemicIndexChange}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t('glycemicIndex.label', 'Indeks glikemiczny')}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      {' '}
                      <FormTextInput
                        label={
                          <div style={{ display: 'flex' }}>
                            <span>
                              {t(
                                'brands.newBrandForm.onMouseOver',
                                'Opis wyświetlany dla klienta po najechaniu myszką'
                              )}
                            </span>
                            <div
                              style={{
                                marginLeft: '10px',
                                display: 'inline-block',
                              }}
                            >
                              <Tooltip
                                title={
                                  <div>
                                    <h4>
                                      {t('brandCfg.textUnderCaptionTooltip')}
                                    </h4>
                                    <h4>
                                      {t('brandCfg.etc')}{' '}
                                      {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt pod nagłówkiem</div>`}
                                    </h4>
                                  </div>
                                }
                                placement="right"
                              >
                                <Info fontSize={'small'} />
                              </Tooltip>
                            </div>
                          </div>
                        }
                        classes={classes}
                        name="tooltip"
                        value={state.showInformation.glycemicIndex?.tooltip}
                        handleChange={handleGlycemicIndexChange}
                        inputSize={12}
                        maxLength={1000}
                        rows={3}
                        rowsMax={20}
                        multiline={true}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem md={6}>
                  <GridContainer>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="dishImage"
                            checked={state.showInformation.dishImage}
                            onChange={handleChangeDietViewOption}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.mealPhoto',
                          'Zdjęcie dania'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="dishComposition"
                            checked={state.showInformation.dishComposition}
                            onChange={handleChangeDietViewOption}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.composition',
                          'Skład dania'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="tags"
                            checked={state.showInformation.tags}
                            onChange={handleChangeDietViewOption}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.clientTags',
                          'Tagi dla klienta'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="servingProposal"
                            checked={state.showInformation.servingProposal}
                            onChange={handleChangeDietViewOption}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.servProposal',
                          'Propozycja podania'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="avgDishRating"
                            checked={state.showInformation.avgDishRating}
                            onChange={handleChangeDietViewOption}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.averageRating',
                          'Średnia ocena dania'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormLabel
                          className={classes.labelHorizontal}
                          style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                          {t(
                            'brands.newBrandForm.dishImagePlaceholder',
                            '$$Zastępcze zdjęcie dania'
                          )}
                          <Tooltip
                            title={
                              <h4>
                                {t(
                                  'brands.newBrandForm.dishImagePlaceholderTooltip'
                                )}
                              </h4>
                            }
                            placement="right"
                          >
                            <Info
                              fontSize="small"
                              style={{
                                color: 'grey',
                                verticalAlign: 'sub',
                                marginLeft: '0.25rem',
                              }}
                            />
                          </Tooltip>
                        </FormLabel>
                      </div>
                      <FormImageUpload
                        classes={classes}
                        stateName="dishImagePlaceholder"
                        buttonText={t('common.shared.select', 'Wybierz')}
                        getImage={setDishImagePlaceholder}
                        imgId="dishImagePlaceholder"
                        removeImage={removeDishImagePlaceholder}
                        previewUrl={
                          configClientPanelModule?.['@resources']?.[
                            configClientPanelModule.dishImagePlaceholder
                          ]?.contentUrl
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          {/* NEW ORDER FORM CONFIG */}
          <Card>
            <CardBody>
              <ConfigClientPanelModule
                classes={classes}
                handleModuleUpdate={module =>
                  setConfigClientPanelModule(module)
                }
                module={configClientPanelModule}
                // paymentTypes={paymentTypes}
              />
            </CardBody>
          </Card>

          {/* REF LINKS SETTINGS */}
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <h4>
                    {t(
                      'brands.newBrandForm.sharingOptions',
                      'Opcje udostępniania kodu polecającego'
                    )}
                  </h4>
                  <Divider style={{ marginBottom: '20px' }} />
                </GridItem>
                <GridItem md={12} lg={6}>
                  <GridContainer>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.showRefererCode}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                showRefererCode: !state.showRefererCode,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.sharingOption',
                          'Klient ma możliwość udostępniania kodu polecającego'
                        )}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.showFacebookCodeReference}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                showFacebookCodeReference: !state.showFacebookCodeReference,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.facebookSharing',
                          'Udostępnianie przez Facebook'
                        )}
                      />
                      <FormTextInput
                        label={t(
                          'brands.newBrandForm.facebookAddress',
                          'Adres udostępniany przez Facebook'
                        )}
                        classes={classes}
                        name="facebookCodeReferenceLink"
                        value={state.facebookCodeReferenceLink}
                        disabled={!state.showFacebookCodeReference}
                        maxLength={64}
                        handleChange={handleInputChange}
                        inputSize={6}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.showLinkedinCodeReference}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                showLinkedinCodeReference: !state.showLinkedinCodeReference,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.linkedinSharing',
                          'Udostępnianie przez LinkedIn'
                        )}
                      />
                      <FormTextInput
                        label={t(
                          'brands.newBrandForm.linkedinAddress',
                          'Adres udostępniany przez LinkedIn'
                        )}
                        classes={classes}
                        name="linkedinCodeReferenceLink"
                        value={state.linkedinCodeReferenceLink}
                        disabled={!state.showLinkedinCodeReference}
                        maxLength={64}
                        handleChange={handleInputChange}
                        inputSize={6}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.showTwitterCodeReference}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                showTwitterCodeReference: !state.showTwitterCodeReference,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.twitterSharing',
                          'Udostępnianie przez Twitter'
                        )}
                      />
                      <FormTextInput
                        label={t(
                          'brands.newBrandForm.twitterAddress',
                          'Adres udostępniany przez Twitter'
                        )}
                        classes={classes}
                        name="twitterCodeReferenceLink"
                        value={state.twitterCodeReferenceLink}
                        disabled={!state.showTwitterCodeReference}
                        maxLength={64}
                        handleChange={handleInputChange}
                        inputSize={6}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem md={12} lg={6}>
                  <GridContainer>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.showWhatsappCodeReference}
                            onChange={() => {
                              setState(prevState => ({
                                ...prevState,
                                showWhatsappCodeReference: !state.showWhatsappCodeReference,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.whatsappSharing',
                          'Udostępnianie przez WhatsApp'
                        )}
                      />
                      <FormTextInput
                        label={t(
                          'brands.newBrandForm.whatsapAddress',
                          'Adres udostępniany przez WhatsApp'
                        )}
                        classes={classes}
                        name="whatsappCodeReferenceLink"
                        value={state.whatsappCodeReferenceLink}
                        disabled={!state.showWhatsappCodeReference}
                        maxLength={64}
                        handleChange={handleInputChange}
                        inputSize={6}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.showMessengerCodeReference}
                            onChange={e => {
                              setState(prevState => ({
                                ...prevState,
                                showMessengerCodeReference: !state.showMessengerCodeReference,
                              }));
                            }}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brands.newBrandForm.messengerSharing',
                          'Udostępnianie przez Messenger'
                        )}
                      />
                      <FormTextInput
                        label={t(
                          'brands.newBrandForm.messengerAddress',
                          'Adres udostępniany przez Messenger'
                        )}
                        classes={classes}
                        name="messengerCodeReferenceLink"
                        value={state.messengerCodeReferenceLink}
                        disabled={!state.showMessengerCodeReference}
                        maxLength={64}
                        handleChange={handleInputChange}
                        inputSize={6}
                      />
                    </GridItem>
                    <GridItem md={12}>
                      <FormTextInput
                        label={t(
                          'brands.newBrandForm.sharingCopy',
                          'Tekst zaproszenia'
                        )}
                        classes={classes}
                        name="referralProgramDescription"
                        value={
                          configClientPanelModule.referralProgramDescription
                        }
                        disabled={false}
                        maxLength={600}
                        multiline
                        handleChange={event => {
                          setConfigClientPanelModule(prevState => ({
                            ...prevState,
                            referralProgramDescription: event.target.value,
                          }));

                          // handleModuleUpdate({
                          //   ...modules[modulesNames.ConfigClientPanel],
                          //   referralProgramDescription: event.target.value,
                          // });
                        }}
                        inputSize={6}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          {/* SCRIPTS */}
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={6}>
                  <h4>{t('brandCfg.scripts', 'Skrypty')}</h4>
                  <Divider style={{ marginBottom: '20px' }} />
                  <FormTextInput
                    multiline
                    classes={classes}
                    name="customScripts"
                    value={state.customScripts}
                    maxLength={5000}
                    rows={10}
                    handleChange={handleInputChange}
                    inputSize={12}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          {/* DELIVERY TYPE TILES CONTENT */}
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <h4>
                    {t(
                      '$*paymentModesCopyModule.title',
                      '$$Treść podpunktów na kafelkach z typem zamówienia'
                    )}
                  </h4>
                  <Divider style={{ marginBottom: '20px' }} />
                  <PaymentModesCopyModule
                    classes={classes}
                    handleModuleUpdate={module =>
                      setPaymentModesCopyModule(module)
                    }
                    module={paymentModesCopyModule}
                    // module={modules[modulesNames.PaymentModesCopy]}
                    moduleConfigClientPanel={
                      configClientPanelModule
                      // modules?.[modulesNames.ConfigClientPanel]
                    }
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          {/* CACHING */}
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <h4>Caching</h4>
                  <Divider style={{ marginBottom: '20px' }} />
                  <Caching
                    classes={classes}
                    handleModuleUpdate={module => setCachingModule(module)}
                    module={cachingModule}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <AvailableSoonOverlay>
            <Card>
              <CardBody>
                <h4>
                  {t(
                    'brands.newBrandForm.notificationSettingsForClients',
                    'Ustawienia powiadomień dla klientów'
                  )}
                </h4>
                <Divider style={{ marginBottom: '20px' }} />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={1}>
                        {''}
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        {''}
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Mail
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        SMS
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t(
                          'brandCfg.panelConfiguration.notificationsTable.allowSendingNotifications',
                          'Umożliwiaj wysyłanie powiadomień'
                        )}
                      </TableCell>
                      <TableCell>
                        {t(
                          'brandCfg.panelConfiguration.notificationsTable.notificationForm',
                          'Forma powiadomienia'
                        )}{' '}
                        <img src={ArrowRight} alt="arrow" />
                        <br />
                        {t(
                          'brandCfg.panelConfiguration.notificationsTable.whatItRefersTo',
                          'Czego ono dotyczy'
                        )}{' '}
                        <img src={ArrowDown} alt="arrow" />
                      </TableCell>
                      <TableCell>
                        {t(
                          'brandCfg.panelConfiguration.notificationsTable.onByDefault',
                          'domyślnie włączone'
                        )}
                      </TableCell>
                      <TableCell>
                        {t(
                          'brandCfg.panelConfiguration.notificationsTable.editAllowedOnClientAccount',
                          'możliwa edycja na koncie klienta'
                        )}
                      </TableCell>
                      <TableCell>
                        {t(
                          'brandCfg.panelConfiguration.notificationsTable.onByDefault',
                          'domyślnie włączone'
                        )}
                      </TableCell>
                      <TableCell>
                        {t(
                          'brandCfg.panelConfiguration.notificationsTable.editAllowedOnClientAccount',
                          'możliwa edycja na koncie klienta'
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRowNames.map(name => {
                      return (
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              onClick={() =>
                                handleNotificationsChange(
                                  name,
                                  'allowNotifications'
                                )
                              }
                              checked={notifications[name].allowNotifications}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {t(`brandCfg.notifications.${name}`)}
                          </TableCell>
                          <TableCell>
                            {notifications[name].allowNotifications ? (
                              <Checkbox
                                onClick={() =>
                                  handleNotificationsChange(
                                    name,
                                    'email',
                                    'onByDefault'
                                  )
                                }
                                checked={notifications[name].email.onByDefault}
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell>
                            {notifications[name].allowNotifications ? (
                              <Checkbox
                                onClick={() =>
                                  handleNotificationsChange(
                                    name,
                                    'email',
                                    'possibleToEditOnClientAccount'
                                  )
                                }
                                checked={
                                  notifications[name].email
                                    .possibleToEditOnClientAccount
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell>
                            {notifications[name].allowNotifications ? (
                              <Checkbox
                                onClick={() =>
                                  handleNotificationsChange(
                                    name,
                                    'sms',
                                    'onByDefault'
                                  )
                                }
                                checked={notifications[name].sms.onByDefault}
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell>
                            {notifications[name].allowNotifications ? (
                              <Checkbox
                                onClick={() =>
                                  handleNotificationsChange(
                                    name,
                                    'sms',
                                    'possibleToEditOnClientAccount'
                                  )
                                }
                                checked={
                                  notifications[name].sms
                                    .possibleToEditOnClientAccount
                                }
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </AvailableSoonOverlay>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
      selectedBrand,
      paymentTypes,
    }),
    {
      fetchBrand,
      fetchBrandConfigModulesPack,
      fetchPaymentTypes,
      updateBrandConfigModulesPack,
    }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(PanelConfiguration);
