import React from 'react';
import { connect } from 'react-redux';

const FullAddressAccessor = ({ slug, mainDomain }) => {
  const protocol = 'https://';
  return (
    <div>
      {protocol}
      {mainDomain}/pages/{slug.slug}
    </div>
  );
};

export default connect(({ Brands: { brand: { mainDomain } } }) => ({
  mainDomain,
}))(FullAddressAccessor);
