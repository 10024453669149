const columnConfig = () => [
  {
    title: 'ID',
    accessor: 'id',
    width: 100,
  },
  {
    title: 'columns.name',
    accessor: 'value',
  },
  {
    title: 'columns.position',
    accessor: 'position',
  },
];

export default columnConfig;
