import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './List';
import Form from './Form';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_DIET,
  ROLE_EDIT_DIET,
  ROLE_SHOW_DIET,
} from 'helpers/roles';

class Diets extends Component {
  render() {
    const props = this.props;

    const ListSecured = withRole(ROLE_SHOW_DIET, List);
    const CreateForm = withRole(ROLE_CREATE_DIET, Form);
    const EditForm = withRole(ROLE_EDIT_DIET, Form);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <CreateForm {...props} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => <EditForm {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Diets);
