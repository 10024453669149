import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_BRAND, ROLE_IMPERSONATE_USER } from 'helpers/roles';
import { handleBrandChange } from '../../actions/Auth';
import { compose } from 'redux';
import { connect } from 'react-redux';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);

  hasPermissionToEditBrand = brandId => {
    const found = this.props.userBrands.find(brand => brand.id === brandId);
    return !!found;
  };

  editAction = (row, _) => {
    this.props.handleBrandChange(null, { brand: { id: row.id } });
    this.props.history.push(`${window.location.pathname}/edit/${row.id}`);
  };

  render() {
    const { classes } = this.props;

    return (
      <AdminTable title="Lista marek" icon>
        {isGranted(ROLE_CREATE_BRAND) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newSize}
            submitText="Dodaj markę +"
          />
        )}
        <DataGrid
          actions={true}
          remove={true}
          paginationTop={true}
          paginationBottom={false}
          url="/brands"
          reportName={'brand'}
          columns={columnConfig}
          role="BRAND"
          editAction={this.editAction}
          canEditChecker={(_, row) => this.hasPermissionToEditBrand(row.id)}
          canRemoveChecker={(_, row) => this.hasPermissionToEditBrand(row.id)}
        ></DataGrid>
      </AdminTable>
    );
  }
}

const mapStateToProps = state => {
  return {
    userBrands: state.Auth.user.brands,
  };
};

const mapDispatchToProps = {
  handleBrandChange,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(buttonsStyle)
);

export default enhance(List);
