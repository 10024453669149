import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import CardIcon from 'components/Card/CardIcon';
import SelectAll from 'components/SelectAll';

import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from '../../components/Security/AccessDenied';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Archive from '../../components/Archive/Archive';
import { fetchZoneCategories } from '../../actions/ZoneCategories';

class DishesToProduction extends React.Component {
  state = {
    selectedBrands: [],
    dateFrom: '',
    dateTo: '',
    isGenerating: false,
    selectedZoneCategories: [],
  };

  url = 'reports/dishes-list';

  componentDidMount = async () => {
    await this.props.fetchZoneCategories();
  };

  getFileName = ({ dateFrom, dateTo }, title) => {
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${dateFrom}-${dateTo}_(${moment().format(
      'YYYY-MM-DD_HH.mm'
    )}).xlsx`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    return this.state.dateFrom && this.state.dateTo;
  };

  validateBrands = () => {
    return this.state.selectedBrands.length !== 0;
  };

  handleGenerate = title => {
    this.setState({ isGenerating: true });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [this.props.t('reports.selectDates')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (!this.validateBrands()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [this.props.t('reports.selectBrand')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const params = {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      brands: this.state.selectedBrands.map(brand => brand.value),
      zoneCategories: this.state.selectedZoneCategories.map(
        zoneCategory => zoneCategory.value
      ),
    };

    axios
      .get(this.url, {
        responseType: 'blob',
        params: params,
        headers: { accept: 'application/vnd.ms-excel' },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.getFileName(this.state, title));
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(this.props.t('reports.noAccess'));
            this.setState({ isGenerating: false });
          }
        },
        error => {
          this.props.openToast({
            messages: [
              this.props.t('reports.cannotGenerate'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          this.setState({ isGenerating: false });
        }
      );
  };

  render() {
    const { classes, t } = this.props;

    const title = t('reports.reportDishesToProd');

    return isGranted(roles.ROLE_SHOW_REPORT_DISH_LIST) ? (
      <>
        <Card style={{ marginTop: '20px' }}>
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>{t('reports.generate')}</h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <PlaylistAddCheck />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '2px' }}
                >
                  {t('reports.selectBrands')}
                </FormLabel>
                <SelectAll
                  className="input-select--brand"
                  options={this.props.brands}
                  trackBy={['@id']}
                  mapBy={'name'}
                  optionSelected={this.state.selectedBrands}
                  handleChange={selected => {
                    this.setState({
                      selectedBrands: selected,
                    });
                  }}
                />
              </GridItem>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {t('reports.selectDate1')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    className="input-datetime--date-from"
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateFrom)}
                    onChange={ev =>
                      this.setState({ dateFrom: ev.format('YYYY-MM-DD') })
                    }
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {t('reports.selectDate2')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    className="input-datetime--date-to"
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateTo)}
                    onChange={ev =>
                      this.setState({ dateTo: ev.format('YYYY-MM-DD') })
                    }
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {this.props.t('reports.selectZoneCategories')}
                </FormLabel>
                <SelectAll
                  className="input-select--zone-category"
                  options={this.props.zoneCategories}
                  trackBy={'@id'}
                  mapBy={'name'}
                  optionSelected={this.state.selectedZoneCategories}
                  handleChange={selected => {
                    this.setState({
                      selectedZoneCategories: selected,
                    });
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem>
                <Button
                  className="btn--generate-xlsx"
                  onClick={() => this.handleGenerate(title)}
                  disabled={this.state.isGenerating}
                  color={'success'}
                  round
                >
                  {t('reports.gReport')}
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Archive type={['REPORT']} reportName={['DISH_LIST']} />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
  zoneCategories: state.ZoneCategories.zoneCategories,
});

const mapDispatchToProps = dispatch => ({
  fetchZoneCategories: () => dispatch(fetchZoneCategories()),
});

BaseBrandAndDateReport.propTypes = {
  brands: PropTypes.array,
  classes: PropTypes.any,
  fileName: PropTypes.func.isRequired,
  mimeType: PropTypes.string.isRequired,
  openToast: PropTypes.any,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(DishesToProduction);
