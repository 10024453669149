import React, { useState } from 'react';

import { isGranted, translateNamesForExport } from 'helpers/helpers';
import { ROLE_EDIT_COST } from 'helpers/roles';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import Table from '@material-ui/core/Table';
import { Tooltip } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Help } from '@material-ui/icons';

import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import LogView from 'components/History/LogView';
import { DialogLoader } from 'components/DialogLoader';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import axios from 'helpers/gastro';
import moment from 'moment';

const DietPrices = ({
  classes,
  submitForm,
  diets,
  selectedDiet,
  handleDietChange,
  handleChange,
  selectedDietCosts,
  saveAll,
  alert,
  isLoading,
  openToast,
}) => {
  const { t } = useTranslation();
  const { multinational } = useSelector(
    ({
      Brands: {
        brand: { multinational },
      },
    }) => ({
      multinational,
    }),
    shallowEqual
  );

  const [isExporting, setIsExporting] = useState(false);

  const getExportButtonConfig = () => {
    const namesForExport = translateNamesForExport(t);

    return [
      {
        label: 'XLSX',
        format: 'xlsx',
        endpoint: 'diet-costs',
        fileName: (url, date) =>
          namesForExport[url] ? namesForExport[url] + date : url + date,
      },
      {
        label: 'CSV',
        format: 'csv',
        endpoint: 'diet-costs',
        fileName: (url, date) =>
          namesForExport[url] ? namesForExport[url] + date : url + date,
      },
    ];
  };

  const exportData = ({ format, fileName, endpoint }) => {
    setIsExporting(true);

    const date = moment().format('DD-MM-YYYY_HH-mm');
    const exportedName =
      typeof fileName === 'string' ? fileName : fileName(endpoint, date);

    axios
      .get(`${endpoint}.${format}`, {
        responseType: 'blob',
        params: {},
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportedName}.${format}`);
          document.body.appendChild(link);
          link.click();
        },
        () => {
          openToast({
            messages: [
              t(
                'errors.dataExportFailed',
                'Nie udało się wyeksportować danych'
              ),
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      )
      .finally(() => {
        setIsExporting(false);
      });
  };

  return (
    <form
      onSubmit={submitForm}
      style={{ opacity: isLoading ? 0 : 1, transition: '0.3s all' }}
    >
      {alert}
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />
      <DialogLoader loaderState={isExporting} text={t('dataGrid.exporting')} />
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem>
              <CustomDropdown
                hoverColor="info"
                buttonText={t('dataGrid.export')}
                buttonProps={{
                  fullWidth: true,
                }}
                dropdownHeader={t('dataGrid.selectFormat')}
                dropdownList={getExportButtonConfig().map(conf => ({
                  handleClick: () =>
                    exportData({
                      format: conf.format,
                      fileName: conf.fileName,
                      endpoint: conf.endpoint,
                      manipulateQuery: conf.manipulateQuery,
                    }),
                  optionText: conf.label,
                }))}
              />
            </GridItem>

            <GridItem md={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormSelectSingle
                        classes={classes}
                        label={t('common.dietPrices.selectDiet')}
                        mapBy="name"
                        trackBy="@id"
                        value={selectedDiet}
                        options={diets}
                        handleChange={handleDietChange}
                        name="diets"
                        id="diets"
                        labelSm={2}
                        selectSm={8}
                      />
                    </TableCell>
                    <TableCell>{t('common.dietPrices.variant')}</TableCell>
                    <TableCell>{t('common.dietPrices.calories')}</TableCell>
                    <TableCell>
                      {t('common.dietPrices.pricePerDay', {
                        defaultValue:
                          'Hinta per toimitus - kertatilaus [{{currency}}]',
                        replace: {
                          currency: multinational.currency,
                        },
                      })}
                    </TableCell>
                    <TableCell>
                      {t('common.dietPrices.pricePerTestDay', {
                        defaultValue: 'Hinta testipäivää kohti [{{currency}}]',
                        replace: {
                          currency: multinational.currency,
                        },
                      })}
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          <h4>
                            {t(
                              'common.dietPrices.pricePerSubscriptionDay.tooltip'
                            )}
                          </h4>
                        }
                        placement="right"
                      >
                        <span
                          style={{ position: 'relative', display: 'block' }}
                        >
                          {t('common.dietPrices.pricePerSubscriptionDay', {
                            defaultValue:
                              'Hinta per toimitus - jatkuva tilaus [{{currency}}]',
                            replace: {
                              currency: multinational.currency,
                            },
                          })}
                          <Help
                            style={{
                              position: 'absolute',
                              top: -12,
                              right: -24,
                            }}
                          />
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {t('common.dietPrices.expectedFoodCost', {
                        replace: {
                          currency: multinational.currency,
                        },
                      })}
                    </TableCell>
                    <TableCell>{t('common.dietPrices.vat')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedDietCosts.map((dietCost, index) => {
                    return (
                      <TableRow
                        style={{
                          backgroundColor: dietCost.hasChanged
                            ? '#ffffd4'
                            : 'transparent',
                        }}
                        key={index}
                      >
                        <TableCell>
                          <span>
                            {dietCost.dietName}
                            {dietCost.hasChanged
                              ? ` ${t('common.shared.modified')}`
                              : null}
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {' '}
                          {dietCost.variantName}{' '}
                        </TableCell>
                        <TableCell> {dietCost.calorificName} </TableCell>
                        <TableCell>
                          <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              disabled: !isGranted(ROLE_EDIT_COST),
                              type: 'text',
                              placeholder: t(
                                'common.dietPrices.pricePerDayInfo'
                              ),
                              name: 'price',
                              value: dietCost.price,
                              onChange: event => handleChange(event, index),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              disabled: !isGranted(ROLE_EDIT_COST),
                              type: 'text',
                              placeholder: t(
                                'common.dietPrices.pricePerTestDayInfo'
                              ),
                              name: 'priceForTest',
                              value: dietCost.priceForTest,
                              onChange: event => handleChange(event, index),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              disabled: !isGranted(ROLE_EDIT_COST),
                              type: 'text',
                              placeholder: t(
                                'common.dietPrices.pricePerSubscriptionDayInfo'
                              ),
                              name: 'priceForSubscription',
                              value: dietCost.priceForSubscription,
                              onChange: event => handleChange(event, index),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              disabled: !isGranted(ROLE_EDIT_COST),
                              type: 'text',
                              placeholder: t(
                                'common.dietPrices.expectedFoodCostInfo'
                              ),
                              name: 'expectedFoodCost',
                              value: dietCost.expectedFoodCost,
                              onChange: event => handleChange(event, index),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <CustomInput
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              disabled: !isGranted(ROLE_EDIT_COST),
                              placeholder: t('common.dietPrices.typeVat'),
                              type: 'text',
                              name: 'vat',
                              value: dietCost.vat,
                              onChange: event => handleChange(event, index),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </GridItem>
            {isGranted(ROLE_EDIT_COST) && (
              <FormControlButtons
                classes={classes}
                submitDisabled={
                  !selectedDietCosts.some(dietCost => dietCost.hasChanged)
                }
                handleSubmit={saveAll}
                submitText={t('common.shared.save')}
              />
            )}
          </GridContainer>
        </CardBody>
      </Card>
      <LogView iri={`/diet-costs`} />
    </form>
  );
};

export default DietPrices;
