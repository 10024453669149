import React, { useState, useEffect, Fragment } from 'react';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import produce from 'immer';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
// import ExtensionIcon from '@material-ui/icons/Extension';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import addonPhotoTemplate from 'assets/img/addonPhotoTemplate.jpg';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';
import CustomInput from 'components/CustomInput/CustomInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import { CustomTooltip } from 'components';

import { get, post, put } from 'helpers/apiHelpers';
import { combineStyles, isGranted, trimSpaces } from 'helpers/helpers';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from '../../../components/CustomButtons/Button';
// import roles from '../../../helpers/roles';
import BasicInfoCard from '../../../components/CMS/BasicInfoCard';
import DescriptionCard from '../../../components/CMS/DescriptionCard';

const initialFormData = {
  active: true,
  category: null,
  clientCost: 0,
  clientVat: 0,
  code: '',
  contractor: null,
  cost: 0,
  defaultUsage: 'FOR_BAG',
  excludedPlaces: [],
  image: null,
  imageUrl: null,
  name: '',
  notes: '',
  position: 0,
  shortDescription: '',
  stock: 0,
};

const defaultUsageOptions = [
  { name: 'form.field.defaultUsage.option.forBag', value: 'FOR_BAG' },
  { name: 'form.field.defaultUsage.option.forDiet', value: 'FOR_DIET' },
];

const excludedPlacesOptions = [
  { name: 'form.field.excludedPlaces.option.newOrder', value: 'NEW_ORDER' },
  { name: 'form.field.excludedPlaces.option.extendBag', value: 'EXTEND_BAG' },
];

const schema = yup.object().shape({
  clientCost: yup.number().required(),
  clientVat: yup.number().required(),
  code: yup.string().required(),
  name: yup.string().required(),
});

const CLIENT_PANEL = 'CLIENT_PANEL';
const ADDON_SUBPAGE = 'ADDON_SUBPAGE';

const AddonsForm = ({ classes, history, location, match, openToast }) => {
  const isEdit = location.pathname.includes('edit');
  const addonId = match.params.id;
  const { t } = useTranslation();

  const [formData, setFormData] = useState(initialFormData);
  const [categories, setCategories] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [subpage, setSubpage] = useState(CLIENT_PANEL);
  const [addonSubpageData, setAddonSubpageData] = useState({
    urlSlug: '',
    metaTitle: '',
    metaDescription: '',
  });
  // const [urlSlug, setUrlSlug] = useState('');
  // const [metaTitle, setMetaTitle] = useState('');
  // const [metaDescription, setMetaDescription] = useState('');
  const [addonDescription, setAddonDescription] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('/addon-categories', { pagination: false });
        setCategories(response['hydra:member']);
      } catch (error) {}

      try {
        const response = await get('/contractors', { pagination: false });
        setContractors([
          {
            '@id': null,
            name: t('form.field.contractor.selectOption.noContractor'),
          },
          ...response['hydra:member'],
        ]);
      } catch (error) {}

      if (isEdit) {
        try {
          const response = await get(`/addons/${addonId}`);
          const {
            '@id': iriId,
            '@type': iriType,
            createdAt,
            updatedAt,
            category,
            image,
            ...onlyFormData
          } = response;

          setFormData({
            ...onlyFormData,
            category: category?.['@id'] || null,
            image: image?.['@id'] || null,
            imageUrl: image?.contentUrl || null,
          });

          setAddonSubpageData({
            urlSlug: response.pageSettings.slug ?? '',
            metaTitle: response.seo.title ?? '',
            metaDescription: response.seo.description ?? '',
          });
          setAddonDescription(response.pageSettings.content ?? '');
        } catch (error) {}
      }
    };

    fetchData();
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setFormData(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const handleAddonSubpageInputChange = e => {
    setAddonSubpageData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckboxClick = ({ target: { name } }) => {
    setFormData(
      produce(draft => {
        draft[name] = !draft[name];
      })
    );
  };

  const getImage = (stateName, data) => {
    setFormData(
      produce(draft => {
        draft[stateName] = data?.['@id'] || null;
        draft[`${stateName}Url`] = data?.contentUrl || null;
      })
    );
  };

  const removeImage = stateName => {
    setFormData(
      produce(draft => {
        draft[stateName] = null;
        draft[`${stateName}Url`] = null;
      })
    );
  };

  const handleSubmit = async () => {
    const isValid = schema.isValidSync(formData);
    if (!isValid) {
      return openToast({
        messages: [t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    try {
      const {
        clientCost,
        clientVat,
        cost,
        imageUrl,
        position,
        stock,
        ...restFormData
      } = formData;

      const data = {
        ...trimSpaces(restFormData),
        clientCost: parseFloat(clientCost),
        clientVat: parseFloat(clientVat),
        cost: parseFloat(cost),
        position: parseInt(position),
        stock: parseInt(stock),
        seo: {
          title: addonSubpageData.metaTitle,
          description: addonSubpageData.metaDescription,
        },
        pageSettings: {
          slug: addonSubpageData.urlSlug,
          content: addonDescription,
        },
      };

      isEdit
        ? await put(`/addons/${addonId}`, data)
        : await post('/addons', data);

      isEdit ? history.push('/admin/addons') : setFormData(initialFormData);

      !isEdit &&
        openToast({
          messages: [t('status.success.save')],
          type: 'success',
          autoHideDuration: 3000,
        });
    } catch ({ response: { data = {}, status } }) {
      if (status === 500) {
        return openToast({
          messages: [
            t('status.error.serverNotResponding'),
            t('status.error.tryAgainLater'),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
      }

      openToast({
        messages: [
          t('status.error.somethingWentWrong'),
          t('status.error.fillCorrectForm'),
          '',
          ...(status === 400
            ? (data?.violations ?? []).map(
                ({ message, propertyPath }) => `${propertyPath}: ${message}`
              )
            : []),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <h2 style={{ display: 'block' }}>
            {isEdit
              ? t('views.addons.addons.editAddon')
              : t('views.addons.addons.newAddon')}
            <div>
              <Button
                onClick={() => setSubpage(CLIENT_PANEL)}
                color={subpage === CLIENT_PANEL ? 'info' : ''}
              >
                {t('views.addons.clientPanel')}
              </Button>
              <Button
                onClick={() => setSubpage(ADDON_SUBPAGE)}
                color={subpage === ADDON_SUBPAGE ? 'info' : ''}
              >
                {t('views.addons.addonSubpage')}
              </Button>
            </div>
          </h2>
        </GridItem>
      </GridContainer>

      {subpage === CLIENT_PANEL && (
        <>
          <AdminTable>
            <GridContainer>
              <GridItem sm={12}>
                <h1>{t('views.addons.addons.section.clientPanel.title')}:</h1>
              </GridItem>

              <GridItem sm={8}>
                <GridContainer>
                  <GridItem sm={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.name.label')} *
                      <CustomTooltip
                        title={t('views.addons.addons.form.field.name.tooltip')}
                      />
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      maxLength={70}
                      inputProps={{
                        name: 'name',
                        value: formData.name,
                        onChange: handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem sm={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.shortDescription.label')}
                      <CustomTooltip
                        title={t(
                          'views.addons.addons.form.field.shortDescription.tooltip'
                        )}
                      />
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      maxLength={300}
                      inputProps={{
                        name: 'shortDescription',
                        value: formData.shortDescription,
                        onChange: handleChange,
                      }}
                    />
                  </GridItem>

                  <GridItem sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          name="active"
                          onClick={handleCheckboxClick}
                          checked={formData.active}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        <>
                          {t('form.field.active.label')}
                          <CustomTooltip
                            title={t(
                              'views.addons.addons.form.field.active.tooltip'
                            )}
                          />
                        </>
                      }
                    />
                  </GridItem>
                  <GridItem sm={5}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.excludedPlaces.label')}
                      <CustomTooltip
                        title={t(
                          'views.addons.addons.form.field.excludedPlaces.tooltip'
                        )}
                      />
                    </FormLabel>
                    <SelectInput
                      noGrid
                      multiple
                      classes={classes}
                      name="excludedPlaces"
                      mapBy="name"
                      trackBy="value"
                      options={excludedPlacesOptions.map(({ name, value }) => ({
                        name: t(name),
                        value,
                      }))}
                      value={formData.excludedPlaces}
                      handleChange={handleChange}
                    />
                  </GridItem>
                  <GridItem sm={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.defaultUsage.label')}
                    </FormLabel>
                    <SelectInput
                      noGrid
                      classes={classes}
                      name="defaultUsage"
                      mapBy="name"
                      trackBy="value"
                      options={defaultUsageOptions.map(({ name, value }) => ({
                        name: t(name),
                        value,
                      }))}
                      value={formData.defaultUsage}
                      handleChange={handleChange}
                    />
                  </GridItem>

                  <GridItem sm={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.category.label')}
                      <CustomTooltip
                        title={t(
                          'views.addons.addons.form.field.category.tooltip'
                        )}
                      />
                    </FormLabel>
                    <SelectInput
                      noGrid
                      classes={classes}
                      name="category"
                      mapBy="name"
                      options={categories}
                      value={formData.category}
                      handleChange={handleChange}
                    />
                  </GridItem>
                  <GridItem sm={2}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '9px' }}
                    >
                      {t('form.field.priority.label')}
                      <CustomTooltip
                        title={t(
                          'views.addons.addons.form.field.priority.tooltip'
                        )}
                      />
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'position',
                        value: formData.position,
                        onChange: handleChange,
                        type: 'number',
                      }}
                    />
                  </GridItem>
                  <GridItem sm={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.clientCost.label')} *
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'clientCost',
                        value: formData.clientCost,
                        onChange: handleChange,
                        type: 'number',
                        style: { marginTop: '9px' },
                      }}
                    />
                  </GridItem>
                  <GridItem sm={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.clientVat.label')} *
                      <CustomTooltip
                        title={t(
                          'views.addons.addons.form.field.clientVat.tooltip'
                        )}
                      />
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'clientVat',
                        value: formData.clientVat,
                        onChange: handleChange,
                        type: 'number',
                        style: { marginTop: '9px' },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem sm={4}>
                <FormImageUpload
                  classes={classes}
                  stateName="image"
                  getImage={getImage}
                  removeImage={removeImage}
                  previewUrl={formData?.imageUrl}
                  defaultImage={addonPhotoTemplate}
                />
              </GridItem>
            </GridContainer>
          </AdminTable>

          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <h1>
                    {t('views.addons.addons.section.otherSettings.title')}:
                  </h1>
                </GridItem>
                <GridItem sm={8}>
                  <GridContainer>
                    <GridItem sm={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.cost.label')} *
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'cost',
                          value: formData.cost,
                          onChange: handleChange,
                          type: 'number',
                          style: { marginTop: '9px' },
                        }}
                      />
                    </GridItem>

                    <GridItem sm={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.contractor.label')}
                      </FormLabel>
                      <SelectInput
                        noGrid
                        classes={classes}
                        name="contractor"
                        mapBy="name"
                        options={contractors}
                        value={formData.contractor}
                        handleChange={handleChange}
                      />
                    </GridItem>

                    <GridItem sm={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.stock.label')}
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'stock',
                          value: formData.stock,
                          onChange: handleChange,
                          type: 'number',
                          style: { marginTop: '9px' },
                        }}
                      />
                    </GridItem>

                    <GridItem sm={4}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ marginBottom: '7px' }}
                      >
                        {t('form.field.code.label')} *
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'code',
                          value: formData.code,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem sm={8}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.notes.label')}
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'notes',
                          value: formData.notes,
                          onChange: handleChange,
                          style: { marginTop: '7px' },
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </>
      )}

      {subpage === ADDON_SUBPAGE && (
        <>
          <BasicInfoCard
            urlSlug={addonSubpageData.urlSlug}
            metaTitle={addonSubpageData.metaTitle}
            metaDescription={addonSubpageData.metaDescription}
            handleChange={handleAddonSubpageInputChange}
          />

          <DescriptionCard
            value={addonDescription}
            setValue={setAddonDescription}
            title={t('views.addons.addonSubpage.addonDescription')}
          />
        </>
      )}

      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/addons"
        handleSubmit={handleSubmit}
      />
    </Fragment>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withToast, withStyles(combinedStyles));

export default enhance(AddonsForm);
