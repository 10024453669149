import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

const defaultStyles = {
  control: styles => {
    return {
      ...styles,
      border: 'none',
      borderRadius: '0',
      borderBottom: '1px solid #d2d2d2',
      backgroundColor: 'white',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none',
      },
    };
  },
  dropdownIndicator: styles => ({
    ...styles,
    display: 'none',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? '#9c27b0' : 'white',
    ':active': {
      backgroundColor: '#9c27b0',
    },
  }),
  multiValue: styles => {
    return {
      ...styles,
      borderRadius: '9px',
    };
  },
  multiValueRemove: styles => ({
    ...styles,
    ':hover': {
      ...styles,
      borderRadius: '0 7px 7px 0',
      cursor: 'pointer',
    },
  }),
};

const MySelect = props => {
  const { t } = useTranslation();
  const localProps = { ...props };
  const defaultProps = {
    allOption: {
      label: t('common.shared.checkAll', 'Zaznacz wszystko'),
      value: '*',
    },
  };
  localProps.allOption = localProps.allOption || defaultProps.allOption;
  if (localProps.allowSelectAll) {
    return (
      <ReactSelect
        {...localProps}
        styles={defaultStyles}
        options={[localProps.allOption, ...localProps.options]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (
              selected[selected.length - 1].value === localProps.allOption.value
            ) {
              return localProps.onChange([
                localProps.allOption,
                ...localProps.options,
              ]);
            }
            let result = [];
            if (selected.length === localProps.options.length) {
              if (
                selected.some(
                  selectedItem =>
                    selectedItem.value === localProps.allOption.value
                )
              ) {
                result = selected.filter(
                  option => option.value !== localProps.allOption.value
                );
              } else if (event.action === 'select-option') {
                result = [localProps.allOption, ...localProps.options];
              }
              return localProps.onChange(result);
            }
          }

          return localProps.onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect {...localProps} />;
};

MySelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  isClearable: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default MySelect;
