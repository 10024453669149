import React from 'react';
import {get, post, put, remove} from '../../helpers/apiHelpers';
import {combineStyles, isGranted} from '../../helpers/helpers';
import {withToast} from 'material-ui-toast-redux';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import withStyles from '@material-ui/core/styles/withStyles';
import EndOfDietReminders from './EndOfDietReminders';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import SweetAlert from 'react-bootstrap-sweetalert';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {connect} from 'react-redux';
import {ROLE_EDIT_MAILING} from '../../helpers/roles';
import {withTranslation} from 'react-i18next';

class Reminders extends React.Component {
  state = {
    mailing: {
      end_of_diet_reminder_config: [],
    },
    checkboxState: false,
    confirmModal: null,
  };

  componentDidMount() {
    Promise.all([
      get('end-of-diet-reminders'),
      get(`brands/${this.props.brand}/mailing`),
    ]).then(([reminders, mailing]) => {
      this.setState({
        mailing: {
          end_of_diet_reminder_config: reminders['hydra:member'].map(
            config => ({
              ...config,
              hasChanged: false,
            })
          ),
        },
        checkboxState: mailing.mailing.end_of_diet_reminder,
      });
    });
  }

  renderAlert = (onConfirm, onCancel) => {
    const { classes, t } = this.props;

    const confirmModal = (
      <SweetAlert
        warning
        title={t(
          'reminders.mailReminders.deleteMessage',
          'Czy na pewno chcesz usunąć to przypomnienie?'
        )}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('common.shared.yes')}
        cancelBtnText={t('common.shared.no')}
        showCancel
      />
    );

    this.setState({ confirmModal });
  };

  render() {
    const { t } = this.props;
    return (
      <Card>
        <CardBody>
          {this.state.confirmModal}
          {isGranted(ROLE_EDIT_MAILING) && (
            <FormControlLabel
              label={t('notify.notifyUsers')}
              control={
                <Checkbox
                  checked={this.state.checkboxState}
                  onChange={e => {
                    const newCheckboxState = !this.state.checkboxState;
                    this.setState({ checkboxState: newCheckboxState });
                    put(`brands/${this.props.brand}/mailing`, {
                      mailing: { end_of_diet_reminder: newCheckboxState },
                    });
                  }}
                  checkedIcon={
                    <Check className={this.props.classes.checkedIcon} />
                  }
                  icon={<Check className={this.props.classes.uncheckedIcon} />}
                  classes={{
                    checked: this.props.classes.checked,
                    root: this.props.classes.checkRoot,
                  }}
                />
              }
            />
          )}
          <EndOfDietReminders
            classes={this.props.classes}
            mailing={this.state.mailing}
            handleCheckbox={() => {
              this.setState(prevState => ({
                ...prevState,
                mailing: {
                  ...prevState.mailing,
                  end_of_diet_reminder: !prevState.mailing.end_of_diet_reminder,
                },
              }));
            }}
            addRow={() => {
              const newRow = {
                days: 0,
                template: '',
                ignoreClientMarketingAgreement: false,
              };
              this.setState(prevState => {
                return {
                  ...prevState,
                  mailing: {
                    ...prevState.mailing,
                    end_of_diet_reminder_config: prevState.mailing.end_of_diet_reminder_config.concat(
                      newRow
                    ),
                  },
                };
              });
            }}
            saveRow={index => {
              const row = this.state.mailing.end_of_diet_reminder_config[index];
              const action = row.id ? put : post;

              action(action === put ? row['@id'] : 'end-of-diet-reminders', {
                ...row,
                days: parseInt(row.days),
                ignoreClientMarketingAgreement: row.ignoreClientMarketingAgreement
              }).then(
                () => {
                  const { mailing } = this.state;

                  mailing.end_of_diet_reminder_config[index].hasChanged = false;

                  this.setState({ mailing });

                  this.props.openToast({
                    messages: [t('notify.changesSaved')],
                    type: 'success',
                    autoHideDuration: 3000,
                  });
                },
                error =>
                  this.props.openToast({
                    messages: [t('notify.cannotSave')],
                    type: 'error',
                    autoHideDuration: 3000,
                  })
              );
            }}
            removeRow={index => {
              this.renderAlert(
                () => {
                  const configAfterDelete = this.state.mailing.end_of_diet_reminder_config.filter(
                    (el, ind) => ind !== index
                  );
                  const row = this.state.mailing.end_of_diet_reminder_config[
                    index
                  ];
                  row.id && remove(row['@id']);
                  this.setState(prevState => {
                    return {
                      ...prevState,
                      confirmModal: false,
                      mailing: {
                        ...prevState.mailing,
                        end_of_diet_reminder_config: configAfterDelete,
                      },
                    };
                  });
                },
                () => this.setState({ confirmModal: false })
              );
            }}
            handlePrefix={(event, index) => {
              const { mailing } = this.state;
              const { value } = event.target;
              const configValue =
                mailing.end_of_diet_reminder_config[index].days;
              let newNumber;

              if (value === '0') {
                newNumber = 0;
              } else if (value === '-') {
                configValue > 0
                  ? (newNumber = -1 * configValue)
                  : (newNumber = configValue);
              } else {
                configValue < 0
                  ? (newNumber = -1 * configValue)
                  : (newNumber = configValue);
              }

              mailing.end_of_diet_reminder_config[index].days = newNumber;

              this.setState({ mailing });
            }}
            handleRow={(event, index) => {
              const { name, value } = event.target;
              const { mailing } = this.state;

              mailing.end_of_diet_reminder_config[index][name] = value;
              mailing.end_of_diet_reminder_config[index].hasChanged = true;

              this.setState({ mailing });
            }}
            handleEditor={(code, name, index) => {
              const { mailing } = this.state;

              mailing.end_of_diet_reminder_config[index][name] = code;
              mailing.end_of_diet_reminder_config[index].hasChanged = true;

              this.setState({ mailing });
            }}
            handleToggle = {(event, index) => {
              const { name } = event.target;
              const { mailing } = this.state;

              mailing.end_of_diet_reminder_config[index][name] = !mailing.end_of_diet_reminder_config[index][name];
              mailing.end_of_diet_reminder_config[index].hasChanged = true;

              this.setState({ mailing });
            }}
          />
        </CardBody>
      </Card>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
  };
};

export default connect(
  mapStateToProps,
  null
)(withToast(withStyles(combinedStyles)(withTranslation()(Reminders))));
