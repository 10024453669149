import React from 'react';
import DataGrid from './DataGrid';
import roles from 'helpers/roles';
import moment from 'moment';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class BoxLabels extends React.Component {
  getFileName = ({ date }) => {
    return `Etykiety-na-torby-${date}_(${moment().format('YYYY-MM-DD_HH.mm')})`;
  };

  render() {
    return (
      <DataGrid
        title={this.props.t('reports.pdfReport')}
        excelUrl="reports/bag-stickers"
        pdfUrl="reports/bag-stickers-pdf"
        mimeType="application/vnd.ms-excel"
        fileName={this.getFileName}
        role={roles.ROLE_SHOW_REPORT_BAG_STICKERS}
        pdfAvaliable
      />
    );
  }
}

const enhance = compose(withTranslation());

export default enhance(BoxLabels);
