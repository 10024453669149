import { get, put } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const getCompany = iri => {
  return dispatch => {
    return get(iri).then(response => {
      dispatch({
        type: types.FETCH_COMPANY,
        payload: response,
      });

      return response;
    });
  };
};

export const saveCompany = (iri, data) => {
  return dispatch => {
    return put(iri, data).then(response => {
      dispatch({
        type: types.FETCH_COMPANY,
        payload: response,
      });

      return response;
    });
  };
};
