import React from 'react';

import YesNoFilter from 'components/Grid/Filter/YesNo';

import { Tooltip } from '@material-ui/core';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import { dateTimeCell } from 'components/Grid/Cells/DateTimeCell';
import NumberRangeFilter from 'components/Grid/Filter/NumberRange';

const translateColumnConfig = t => [
  {
    title: 'subscription.clientDiet.id',
    name: 'clientDiet.id',
    width: 75,
    accessor: row => (
      <Tooltip
        interactive
        title={
          <span style={{ fontSize: '12px' }}>
            {t('subscription.id')}: {row.id}
            <br />
            {t('subscription.clientDiet.id')}: {row.clientDiet.id}
          </span>
        }
        placement={'right'}
      >
        <span style={{ cursor: 'help' }}>{row.clientDiet.id}</span>
      </Tooltip>
    ),
  },
  {
    title: 'subscription.clientDiet.active',
    width: 75,
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.clientDiet.active} />,
    name: 'clientDiet.active',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: true,
  },
  {
    title: 'subscription.active',
    width: 75,
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.active} />,
    name: 'active',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: true,
  },
  {
    title: 'subscription.markedToRemove',
    width: 75,
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.markedToRemove} />,
    name: 'markedToRemove',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: true,
  },
  {
    title: 'subscription.addons',
    width: 75,
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.addons.length > 0} />,
    name: 'addons',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: false,
  },
  {
    title: 'subscription.clientDiet.optionChangeMenu',
    width: 75,
    headerStyles: { display: 'block', width: '100%', textAlign: 'center' },
    accessor: row => <BooleanView value={row.clientDiet.optionChangeMenu} />,
    name: 'clientDiet.optionChangeMenu',
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    sortable: false,
    filterable: true,
  },
  {
    title: 'subscription.clientDiet.subscriptionStartsAt',
    Filter: ({ onChange, filter }) => {
      return <DateRangeFilter onChange={onChange} value={filter?.value} />;
    },
    accessor: row => {
      return dateTimeCell(row.clientDiet.subscriptionStartsAt, true);
    },
    name: 'clientDiet.subscriptionStartsAt',
    filterable: true,
    sortable: true,
    width: 160,
  },
  {
    title: 'subscription.price',
    name: 'priceAfterDiscount',
    sortable: false,
    accessor: row => (
      <div style={{ textAlign: 'right', paddingRight: 8 }}>
        <Tooltip
          interactive
          title={
            <span style={{ fontSize: '12px' }}>
              {t('subscription.priceBeforeDiscount')}:{' '}
              {row.priceBeforeDiscount.toFixed(2)}
              <br />
              {t('subscription.priceDiscount')}: {row.priceDiscount.toFixed(2)}
              <br />
              {t('subscription.priceAfterDiscount')}:{' '}
              {row.priceAfterDiscount.toFixed(2)}
            </span>
          }
          placement={'right'}
        >
          <span style={{ cursor: 'help' }}>
            {row.priceAfterDiscount.toFixed(2)}
          </span>
        </Tooltip>
      </div>
    ),
    Filter: ({ onChange, filter }) => {
      return (
        <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
      );
    },
  },
  {
    title: 'subscription.clientDiet.client',
    sortable: false,
    accessor: row => {
      return (
        <>
          <span style={{ whiteSpace: 'pre-wrap' }}>
            {row.clientDiet.client.firstName} {row.clientDiet.client.lastName}
          </span>
          <br />
          <span style={{ whiteSpace: 'pre-wrap' }}>
            {row.clientDiet.client.email}
          </span>
        </>
      );
    },
    name: 'client',
  },
  {
    title: 'subscription.clientDiet.client.phone',
    accessor: prop => {
      return (
        <a href={`tel:${prop.clientDiet.client?.phone?.number}`}>
          {prop.clientDiet.client?.phone?.number}
        </a>
      );
    },
    name: 'clientDiet.client.phone.number',
  },
  {
    title: 'subscription.clientDiet.calorific',
    accessor: 'clientDiet.calorific.name',
    name: 'clientDiet.calorific.name',
  },
  {
    title: 'subscription.clientDiet.diet',
    accessor: 'clientDiet.diet.name',
    name: 'clientDiet.diet.name',
  },
  {
    title: 'subscription.clientDiet.variant',
    accessor: 'clientDiet.variant.name',
    name: 'clientDiet.variant.name',
  },
];

export default translateColumnConfig;
