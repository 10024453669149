import React, { Component } from 'react';
import update from 'immutability-helper';
import withStyles from '@material-ui/core/styles/withStyles';
import AdminTable from 'layouts/AdminTable';
import HTML5Backend from 'react-dnd-html5-backend';
import DragableTable from './DragableTable';
import './styles.css';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { DndProvider } from 'react-dnd';
import { combineStyles, sortByKey, sortByNestedKey } from 'helpers/helpers';

import { fetchDiets } from 'actions/Diets';
import { openSweetToast } from 'actions/App';
import { fetchMealTypes } from 'actions/MealTypes';
import { addVariant, editVariant, fetchVariant } from 'actions/Variants';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import PromptContent from './PromptContent';

import List from '@material-ui/core/List';
import Table from '@material-ui/core/Table';
import Check from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import FormLabel from '@material-ui/core/FormLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';

import { withTranslation } from 'react-i18next';

class MealTypeForm extends Component {
  state = {
    modal: null,
    modalOptions: [],
    image: null,
    imageUrl: '',
    variant: {
      name: '',
      shortName: '',
      position: '',
      mealTypes: [],
      diets: [],
      active: true,
      allowSelectMenuFromOtherVariants: true,
      calories: [],
    },
  };

  editId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');
  initialMealTypes = [];

  componentDidMount() {
    Promise.all([this.props.fetchMealTypes(), this.props.fetchDiets()]).then(
      () => {
        this.editId &&
          this.props.fetchVariant(this.props.match.params.id).then(() => {
            let givenVariant = this.props.variant;
            const mealTypes = this.props.mealTypes.filter(mealType =>
              givenVariant.mealTypes.includes(mealType['@id'])
            );
            this.initialMealTypes = mealTypes;
            this.setState({
              variant: {
                ...givenVariant,
                mealTypes,
                calories: sortByKey(givenVariant.calories, 'position'),
              },
              image: givenVariant.image?.['@id'] || null,
              imageUrl: givenVariant.image?.contentUrl || null,
            });
          });
      }
    );
  }

  setCalories = (dragIndex, hoverIndex, dragRow) => {
    this.setState({
      variant: update(this.state.variant, {
        calories: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
    });
  };

  handleNameChange = e => {
    this.setState({
      variant: update(this.state.variant, {
        name: { $set: e.target.value },
      }),
    });
  };

  handleVariantName = (calorie, variant, e) => {
    calorie.name = e.target.value;
    this.setState({ variant: variant });
  };

  handleVariantWorkName = (calorie, variant, e) => {
    calorie.workName = e.target.value;
    this.setState({ variant: variant });
  };

  toggleCalorificActivness = (calorie, variant) => {
    calorie.active = !calorie.active;
    this.setState({ variant });
  };

  removeRow = (variant, index) => {
    let calories = variant.calories;
    calories.splice(index, 1);
    this.setState({
      variant: update(this.state.variant, {
        calories: { $set: calories },
      }),
    });
  };

  handleShortNameChange = e => {
    this.setState({
      variant: update(this.state.variant, {
        shortName: { $set: e.target.value },
      }),
    });
  };

  handleDescriptionChange = e => {
    this.setState({
      variant: update(this.state.variant, {
        description: { $set: e.target.value },
      }),
    });
  };

  handlePositionChange = e => {
    this.setState({
      variant: update(this.state.variant, {
        position: { $set: parseInt(e.target.value) },
      }),
    });
  };

  handleDiets = (e, selected) => {
    this.setState({
      variant: update(this.state.variant, {
        diets: { $set: e.target.value },
      }),
    });
  };

  handleToggleActive = () => {
    this.setState({
      variant: update(this.state.variant, {
        active: { $set: !this.state.variant.active },
      }),
    });
  };

  handleToggleAllowSelectMenuFromOtherVariants = () => {
    this.setState({
      variant: update(this.state.variant, {
        allowSelectMenuFromOtherVariants: {
          $set: !this.state.variant.allowSelectMenuFromOtherVariants,
        },
      }),
    });
  };

  handleMealTypes = (e, selected) => {
    let variant = this.state.variant;
    let mealTypes = selected.map(el => el['@id']);
    variant.calories.forEach((calorie, rowIndex) => {
      calorie.sizes = calorie.sizes.filter(size => {
        return mealTypes.includes(size.mealType);
      });
    });

    this.setState({
      variant: update(variant, {
        mealTypes: { $set: selected },
      }),
    });
  };

  addCalorie = () => {
    this.setState({
      variant: update(this.state.variant, {
        calories: {
          $set: [
            ...this.state.variant.calories,
            { name: '', workName: '', sizes: [], active: true },
          ],
        },
      }),
    });
  };

  getSizeName = (row, mealTypeId) => {
    let editedVariant = { ...this.state.variant };
    let calorie = editedVariant.calories[row];
    let editedSize = calorie.sizes.find(size => size.mealType === mealTypeId);
    if (!editedSize) {
      return <button className={'variant-sizeButton'}>+</button>;
    }
    let size = this.props.mealTypes
      .find(mealType => mealType['@id'] === mealTypeId)
      .sizes.find(size => {
        return size['@id'] === editedSize.size;
      });

    if (typeof size === 'undefined') {
      size = { size: { name: 'BRAK' }, calories: 0 };
    }
    return (
      <button className={'variant-sizeFilled'}>
        {size.size.name} - {size.calories} kcal
      </button>
    );
  };

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  getRowSum = row => {
    const { mealTypes } = this.props;
    let sum = 0;

    row.sizes.forEach(size => {
      mealTypes.forEach(mealType => {
        if (mealType['@id'] === size.mealType) {
          mealType.sizes.forEach(mtSize => {
            if (mtSize['@id'] === size.size) {
              sum += mtSize.calories;
            }
          });
        }
      });
    });

    return `${sum} kcal`;
  };

  closeModal = () => {
    this.setState({
      modal: null,
    });
  };

  openModal = (rawSizes, row, mealTypeId) => {
    const sizes = sortByNestedKey(rawSizes, 'size', 'position');

    this.setState({
      modal: (
        <Dialog open={true} onClose={this.closeModal}>
          <DialogTitle> Wybierz wielkość posiłku </DialogTitle>
          <DialogContent>
            <List>
              {sizes.length > 0 ? (
                sizes.map((size, index) => (
                  <ListItem
                    className={'variant-listItem'}
                    style={{
                      cursor: 'pointer',
                      textAlign: 'center',
                    }}
                    key={index}
                    onClick={() => {
                      let editedVariant = { ...this.state.variant };
                      let calorie = editedVariant.calories[row];
                      let editedSize = calorie.sizes.find(
                        size => size.mealType === mealTypeId
                      );
                      if (!editedSize) {
                        editedSize = { mealType: mealTypeId, size: null };
                        calorie.sizes.push(editedSize);
                      }
                      editedSize.size = size['@id'];
                      this.closeModal();
                    }}
                  >
                    <ListItemText>
                      {size.size.name} - {size.calories} kcal
                    </ListItemText>
                  </ListItem>
                ))
              ) : (
                <div>
                  <h1>
                    Nie istnieją żadne wielkości przypisane do tego typu
                    posiłku.
                  </h1>
                </div>
              )}
            </List>
          </DialogContent>
        </Dialog>
      ),
    });
  };

  validateForm = () => {
    let isValid = true;
    const { variant } = this.state;

    const validations = [
      {
        condition: variant.name === '',
        message: 'form.variantNameCannotEmpty',
      },
      {
        condition: variant.shortName === '',
        message: 'form.shortVariantNameCannotEmpty',
      },
      {
        condition: variant.mealTypes.length === 0,
        message: 'form.selectMealTypes',
      },
      {
        condition: variant.calories.length < 1,
        message: 'form.addMinOneCalories',
      },
      {
        condition: variant.calories.some(calorie => calorie.name === ''),
        message: 'form.addNamesForAllCalories',
      },
      {
        condition: variant.calories
          .map(calorie => calorie.name)
          .some(
            (name, index) =>
              variant.calories.map(calorie => calorie.name).indexOf(name) !==
              index
          ),
        message: 'form.caloriesNameCannotBeSame',
      },
      {
        condition: variant.calories
          .map(calorie => calorie.sizes.length === variant.mealTypes.length)
          .some(el => el === false),
        message: 'form.addSizesForAllMealTypes',
      },
    ];

    const errors = validations.filter(({ condition, message }) => {
      if (condition) {
        return message;
      }

      return null;
    });

    if (errors.length > 0) {
      this.props.openToast({
        messages: [this.props.t(errors[0].message)],
        type: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    return isValid;
  };

  handleSubmit = async () => {
    if (!this.validateForm()) {
      return;
    }
    const data = {
      ...this.state.variant,
      image: this.state.image,
      mealTypes: this.state.variant.mealTypes.map(mealType => mealType['@id']),
      calories: this.state.variant.calories.map((calorie, index) => {
        calorie.position = index;
        return calorie;
      }),
    };

    if (this.isEdit) {
      try {
        if (this.showWarningModal()) {
          await this.sweetToastPrompt();
        }
        await this.props.editVariant(this.props.match.params.id, data);
        this.props.history.push('/admin/variants');
      } catch (err) {}
    } else {
      this.props.addVariant(data).then(() => {
        this.props.history.push('/admin/variants');
      });
    }
  };

  showWarningModal = () =>
    !this.initialMealTypes.every(initialMealType =>
      this.state.variant.mealTypes.some(
        stateMealType => stateMealType.id === initialMealType.id
      )
    );

  sweetToastPrompt = function () {
    return new Promise((resolve, reject) => {
      this.props.openSweetToast({
        style: { display: 'block' },
        title: this.props.t('variants.save.prompt.title'),
        content: <PromptContent />,
        showCancel: true,
        onConfirm: resolve,
        onCancel: reject,
        confirmBtnText: this.props.t('common.shared.yes', 'Tak'),
        cancelBtnText: this.props.t('common.shared.no', 'Nie'),
      });
    });
  };

  render() {
    const { variant } = this.state;
    const { classes } = this.props;

    return (
      <AdminTable
        title={
          this.isEdit
            ? this.props.t('form.editVariant')
            : this.props.t('form.addNewVariant')
        }
        icon
      >
        {this.state.modal}
        <GridContainer>
          <GridItem sm={4}>
            <FormTextInput
              label={this.props.t('form.field.variantName') + '*'}
              classes={classes}
              name="variant.name"
              value={variant.name}
              handleChange={this.handleNameChange}
              inputSize={12}
              maxLength={64}
            />
          </GridItem>
          <GridItem sm={1}>
            <FormTextInput
              label={this.props.t('columns.code') + '*'}
              classes={classes}
              name="variant.shortName"
              value={this.state.variant.shortName}
              handleChange={this.handleShortNameChange}
              inputSize={12}
              maxLength={32}
            />
          </GridItem>
          <GridItem sm={1}>
            <FormTextInput
              label={this.props.t('form.field.order') + '*'}
              classes={classes}
              name="variant.position"
              type="number"
              value={this.state.variant.position}
              handleChange={this.handlePositionChange}
              inputSize={12}
              maxLength={6}
              inputProps={{ min: 0 }}
            />
          </GridItem>
          <GridItem sm={6} />
          <GridItem sm={5}>
            <FormTextInput
              multiline
              label={this.props.t('form.field.description')}
              classes={classes}
              name="variant.description"
              value={this.state.variant.description}
              handleChange={this.handleDescriptionChange}
              inputSize={12}
              maxLength={500}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={this.handleToggleActive}
                  checked={this.state.variant.active}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={
                <div style={{ display: 'flex' }}>
                  {this.props.t('form.field.active.label')}{' '}
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip
                      title={
                        <div>
                          <h4>{this.props.t('form.variantActiveInfo1')}:</h4>
                          <ul style={{ fontSize: '14px' }}>
                            <li>{this.props.t('form.variantActiveInfo2')}</li>
                            <li>{this.props.t('form.variantActiveInfo3')}</li>
                          </ul>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
            />
          </GridItem>
          <GridItem xs={12} sm={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={this.handleToggleAllowSelectMenuFromOtherVariants}
                  checked={this.state.variant.allowSelectMenuFromOtherVariants}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={
                <div style={{ display: 'flex' }}>
                  {this.props.t(
                    'form.field.allowSelectMenuFromOtherVariants.label'
                  )}{' '}
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip
                      title={
                        <div>
                          <h4>
                            {this.props.t(
                              'form.variantAllowSelectMenuFromOtherVariantsInfo1'
                            )}
                            :
                          </h4>
                          <ul style={{ fontSize: '14px' }}>
                            <li>
                              {this.props.t(
                                'form.variantAllowSelectMenuFromOtherVariantsInfo2'
                              )}
                            </li>
                          </ul>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
            />
          </GridItem>
        </GridContainer>
        <SelectInput
          multiple
          classes={classes}
          label={this.props.t('form.field.avDiets')}
          mapBy="name"
          trackBy="@id"
          value={this.state.variant.diets}
          options={this.props.diets}
          handleChange={(ev, obj) => this.handleDiets(ev, obj)}
        />
        <SelectInput
          classes={classes}
          multiple={true}
          label={this.props.t('form.field.mealTypes') + '*'}
          mapBy="name"
          trackBy="@id"
          value={this.state.variant.mealTypes}
          options={sortByKey(this.props.mealTypes, 'position')}
          handleChange={this.handleMealTypes}
          id="types"
          size={12}
        />
        <GridContainer style={{ marginTop: '20px' }}>
          <GridItem xs={12} sm={2}>
            <FormLabel className={classes.labelHorizontal}>
              {this.props.t('form.field.variantPhoto')}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={10} style={{ marginLeft: '-15px' }}>
            <FormImageUpload
              classes={classes}
              stateName="image"
              getImage={this.getImage}
              removeImage={this.removeImage}
              previewUrl={this.state.imageUrl}
            />
          </GridItem>
        </GridContainer>
        <Table style={{ tableLayout: 'fixed' }}>
          {variant.mealTypes.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell align={'left'}>
                  {this.props.t('form.field.name')} *
                </TableCell>
                <TableCell align={'left'}>
                  {this.props.t('form.field.workingName')}
                </TableCell>
                {variant.mealTypes.map((mealType, columnIndex) => {
                  return (
                    <TableCell
                      key={columnIndex}
                      align={'center'}
                      padding={'none'}
                    >
                      {mealType.name} *
                    </TableCell>
                  );
                })}
                <TableCell align={'left'}>
                  {this.props.t('diets.active')}
                </TableCell>
                <TableCell align={'left'}>
                  {this.props.t('form.field.caloriesSum')}
                </TableCell>
              </TableRow>
            </TableHead>
          )}
        </Table>
        <DndProvider backend={HTML5Backend}>
          <DragableTable
            calories={variant.calories}
            variant={variant}
            setCalories={this.setCalories}
            getSizeName={this.getSizeName}
            getRowSum={this.getRowSum}
            openModal={this.openModal}
            handleVariantName={this.handleVariantName}
            handleVariantWorkName={this.handleVariantWorkName}
            removeRow={this.removeRow}
            toggleCalorificActivness={this.toggleCalorificActivness}
          />
        </DndProvider>
        <Table>
          <TableBody>
            {variant.mealTypes.length > 0 && (
              <TableRow>
                <TableCell
                  className={'variant-addRow'}
                  colSpan={variant.mealTypes.length + 2}
                  onClick={this.addCalorie}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ display: 'block' }}>
                      + {this.props.t('form.addCalories')}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div style={{ marginTop: '25px' }}>
          <FormControlButtons
            classes={classes}
            discardText={this.props.t('form.cancel')}
            submitText={this.props.t('form.save')}
            cancelPath="/admin/variants"
            handleSubmit={this.handleSubmit}
          />
        </div>
      </AdminTable>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  variant: state.Variants.variant,
  mealTypes: state.MealTypes.mealTypes,
  diets: state.Diets.diets,
});

const mapDispatchToProps = {
  fetchMealTypes,
  fetchDiets,
  addVariant,
  editVariant,
  fetchVariant,
  openSweetToast,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withToast(withStyles(combinedStyles)(MealTypeForm)))
);
