import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button.jsx';

import roles from 'helpers/roles';
import { isGranted } from 'helpers/helpers';

import { RECIPE_FORM_TABS } from '../constants/recipeFormTabs';

const RecipeFromTabsNavigation = ({
  isEdit,
  saveToLs,
  selectedBrand,
  currentRecipeFormTab,
  setCurrentRecipeFormTab,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Button
        color={currentRecipeFormTab === RECIPE_FORM_TABS.REGULAR && 'info'}
        onClick={() => setCurrentRecipeFormTab(RECIPE_FORM_TABS.REGULAR)}
      >
        {t('common.regular', 'Regular')}
      </Button>
      {isGranted(roles.ROLE_SHOW_NOTE) ? (
        <Button
          onClick={() => setCurrentRecipeFormTab(RECIPE_FORM_TABS.NOTES)}
          color={currentRecipeFormTab === RECIPE_FORM_TABS.NOTES && 'info'}
        >
          {t('common.notes', 'Notes')}
        </Button>
      ) : null}
      {isEdit && (
        <Link
          to={`/admin/recipes/add/?savedRecipe&selectedBrand=${selectedBrand}`}
          target={'_blank'}
        >
          <Button color="primary" variant="contained" onClick={saveToLs}>
            {t('form.copyToNewRecipe', 'Skopiuj do nowego przepisu')}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default RecipeFromTabsNavigation;
