import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

const Counter = ({
  min = 0,
  max = 0,
  count = 0,
  handleChangeValue = () => {},
  handleClickDecrement = () => {},
  handleClickIncrement = () => {},
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        type="button"
        style={{
          width: '100%',
          maxWidth: '25px',
          height: '25px',
          padding: '3px 0 4px',
        }}
        onClick={handleClickDecrement}
        disabled={!(min === null || (min !== null && count - 1 >= min))}
      >
        -
      </Button>
      <CustomInput
        type="number"
        noArrowsNumberInput
        onClick={e => {
          e.stopPropagation();
        }}
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          value: count > 0 ? count : '',
          onChange: handleChangeValue,
          style: {
            margin: '0 10px',
            textAlign: 'center',
          },
        }}
      />
      <Button
        type="button"
        style={{
          width: '100%',
          maxWidth: '25px',
          height: '25px',
          padding: '3px 0 4px',
        }}
        onClick={handleClickIncrement}
        disabled={!(min === null || (min !== null && count + 1 <= max))}
      >
        +
      </Button>
    </div>
  );
};

export default Counter;
