import GridItem from '../../../components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import React, { useEffect } from 'react';
import useCheckIsPaymentDisabled from 'helpers/useCheckIsPaymentDisabled';
import { useTranslation } from 'react-i18next';

const TPayModule = ({
  classes,
  tpayCheck,
  tpayId,
  tpayAuthKey,
  handleInputChange,
  handleToggle,
}) => {
  const { t } = useTranslation();
  const tPay = useCheckIsPaymentDisabled('TPAY');

  useEffect(() => {
    if (tPay.isSelected && !tpayCheck) {
      handleToggle('tpayCheck');
    }
  }, [tPay.isSelected]);

  return (
    <>
      <GridItem xs={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() => handleToggle('tpayCheck')}
            checked={tpayCheck}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            disabled={tPay.isSelected}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />

          <span>tPay</span>
          {tPay.isSelected && (
            <div style={{ marginLeft: '10px' }}>
              <Tooltip
                title={
                  <h4>
                    {t(
                      `brandCfg.payments.disabled.${tPay.paymentName}.tooltip`
                    )}
                  </h4>
                }
                placement="right"
              >
                <Info
                  fontSize="small"
                  style={{ color: 'grey', display: 'block' }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="tPay ID"
          classes={classes}
          name="tpayId"
          value={!tpayCheck ? '' : tpayId}
          disabled={!tpayCheck}
          handleChange={handleInputChange}
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label={t('brandCfg.securityCode')}
          classes={classes}
          name="tpayAuthKey"
          value={!tpayCheck ? '' : tpayAuthKey}
          disabled={!tpayCheck}
          handleChange={handleInputChange}
          inputSize={12}
        />
      </GridItem>
    </>
  );
};

export default TPayModule;
