import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import {combineStyles} from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import {Info} from '@material-ui/icons';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GridItem from '../../../components/Grid/GridItem';
import ColorPicker from '../../../components/BlockColorPicker/BlockColorPicker';
import GridContainer from '../../../components/Grid/GridContainer';
import Card from '../../../components/Card/Card';
import CardIcon from '../../../components/Card/CardIcon';
import Assignment from '@material-ui/icons/Assignment';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

const BasicInfo = ({
  classes,
  t,
  handleInputChange,
  state,
  handleToggle,
  handleColorPicker,
  isEdit,
}) => {
  return (
    <Card>
      {/*<CardHeader color="primary" icon>*/}
      {/*  <CardIcon color="primary">*/}
      {/*    <Assignment />*/}
      {/*  </CardIcon>*/}
      {/*  <h4 className={classes.cardIconTitle}>*/}
      {/*    {isEdit ? t('form.editDiet') : t('form.addNewDiet')}*/}
      {/*  </h4>*/}
      {/*</CardHeader>*/}
      <CardBody style={{ paddingBottom: '3rem' }}>
        <h5>{t('form.basicInfo')}</h5>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <FormTextInput
              label={t('form.field.dietName') + '*'}
              classes={classes}
              name="name"
              value={state.name}
              handleChange={handleInputChange}
              inputSize={8}
            />
            <FormTextInput
              label={t('form.field.workingName')}
              classes={classes}
              name="workName"
              value={state.workName}
              handleChange={handleInputChange}
              inputSize={8}
            />
            <FormTextInput
              label={t('form.field.code') + '*'}
              classes={classes}
              name="code"
              value={state.code}
              handleChange={ev => {
                const value = ev.target.value;
                const valueTrimmed = value.replace(/\s/g, '');

                handleInputChange(ev, valueTrimmed);
              }}
              inputSize={8}
            />

            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => handleToggle('checked')}
                  checked={state.checked}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={
                <>
                  {t('form.activeDiet')}
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('form.dietActiveInfo1')}:</h4>
                          <ul style={{ fontSize: '14px' }}>
                            <li>{t('form.dietActiveInfo2')}</li>
                            <li>{t('form.dietActiveInfo3')}</li>
                          </ul>
                          <h4 style={{ fontWeight: 'bold' }}>
                            {t('form.dietActiveInfo4')}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </>
              }
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FormLabel
              style={{ marginBottom: '1rem' }}
              className={classes.labelHorizontal}
            >
              {t('form.field.color')}
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  title={
                    <div>
                      <h4>{t('form.field.color.tooltip')}:</h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </div>
            </FormLabel>
            <ColorPicker color={state.color} handleChange={handleColorPicker} />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  connect(({ Diets: { diets }, Variants: { variants } }) => ({
    diets,
    variants,
  })),
  withTranslation(),
  withStyles(combinedStyles)
);

export default enhance(BasicInfo);
