import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import translateColumnConfig from './columnConfig';
import { trimEmptyStringsFromQuery } from 'helpers/helpers';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class List extends Component {
  render() {
    const { t } = this.props;
    return (
      <AdminTable title={t('diets.dietList')} icon>
        <DataGrid
          actions={true}
          export={true}
          previewPath="/admin/ecommerce-diets/preview"
          paginationTop={true}
          paginationBottom={false}
          url="/ecommerce-diets"
          reportName={'diet'}
          role="ORDER"
          columns={translateColumnConfig(t)}
          defaultHiddenColumns={['name']}
          minRows={20}
          manipulateQuery={(requestData, query) => {
            if (query['client.activeDietsCount']) {
              const filters = query['client.activeDietsCount'];
              delete query['client.activeDietsCount'];
              if (filters.valueFrom) {
                query['client.activeDietsCount[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['client.activeDietsCount[lte]'] = filters.valueTo;
              }
            }

            if (query['client.firstName']) {
              const splicedName = query['client.firstName'].split(' ');
              let _andX = [
                [
                  {
                    'client.firstName': splicedName[0],
                    'client.lastName': splicedName[0],
                  },
                ],
              ];

              if (splicedName.length > 1) {
                _andX[0][0]['client.lastName'] = splicedName[1];
              }

              const operator = splicedName.length > 1 ? '_andX' : '_orX';

              delete query['client.firstName'];
              query[operator] = _andX;
            }

            if (query['order[address]']) {
              const desc = query['order[address]'];
              delete query['order[address]'];
              query['order[address.city]'] = desc;
              query['order[address.street]'] = desc;
            }

            if (query['address']) {
              const search = query['address'];
              delete query['address'];
              let splicedSearch = search.split(' ');
              splicedSearch = trimEmptyStringsFromQuery(splicedSearch);

              let orX = [
                [
                  {
                    'address.city': splicedSearch,
                    'address.street': splicedSearch,
                    'address.postCode': splicedSearch,
                    'address.buildNumber': splicedSearch,
                    'address.placeNumberNumber': splicedSearch,
                    'pickUpPoint.value': splicedSearch,
                  },
                ],
              ];

              query['_orX'] = (query['_orX'] || []).concat(orX);
            }

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);
