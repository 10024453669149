import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/Card/Card';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';

import LogView from 'components/History/LogView';
import FormTags from 'components/FormTags/FormTags';
import ReactSelect from 'components/ReactSelect/ReactSelect';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormSelectDropdown from 'components/FormSelect/FormSelectDropdown';
import IngredientsDetails from './IngredientsDetails';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

const RegularRecipeForm = ({
  tags,
  type,
  match,
  filter,
  classes,
  getImage,
  difficulty,
  removeImage,
  recipeState,
  handleSubmit,
  getAllergens,
  selectedTags,
  handleChange,
  workNameState,
  addIngredient,
  handleQuantity,
  handleUserValue,
  preparationTime,
  handleTagChange,
  userIngredients,
  includeWorkName,
  difficultyState,
  removeIngredient,
  kitchenImageStateUrl,
  handleThermalProcessing,
  handleWorkingOnMachining,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Card>
        <GridContainer>
          <GridItem xs={12}>
            <CardHeader>
              <h3>{t('form.basicInfo')}</h3>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer justify="center">
                  <GridItem md={12}>
                    <GridContainer>
                      <GridItem md={4}>
                        <FormTextInput
                          label={t('form.field.workingName') + '*'}
                          classes={classes}
                          name="workNameState"
                          value={workNameState}
                          handleChange={handleChange}
                          inputSize={12}
                        />
                      </GridItem>
                      <GridItem md={4}>
                        <GridContainer>
                          <GridItem md={12} style={{ marginTop: '17px' }}>
                            <ReactSelect
                              selectedValues={selectedTags}
                              options={tags}
                              handleChange={handleTagChange}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12}>
                    <GridContainer>
                      <GridItem md={4}>
                        <FormSelectSingle
                          classes={classes}
                          label={t('form.field.type')}
                          options={[
                            {
                              label:
                                t('common.lack', 'Brak')[0].toUpperCase() +
                                t('common.lack', 'Brak').slice(1),
                              value: null,
                            },
                            {
                              label: t('form.field.type.WEIGHT'),
                              value: 'WEIGHT',
                            },
                            {
                              label: t('form.field.type.PIECE'),
                              value: 'PIECE',
                            },
                          ]}
                          value={type}
                          mapBy="label"
                          trackBy="value"
                          name="type"
                          handleChange={handleChange}
                          id="type"
                          allowDeselect={true}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </GridItem>
        </GridContainer>
      </Card>
      <Card>
        <CardHeader>
          <h3>{t('form.field.ingredients')}</h3>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem md={12}>
              <FormSelectDropdown
                handleClick={addIngredient}
                placeholder={t('form.field.search')}
                filter={filter}
                includeWorkName={includeWorkName}
              />
              <IngredientsDetails
                data={userIngredients}
                handleUserValue={handleUserValue}
                handleQuantity={handleQuantity}
                handleRemove={removeIngredient}
                handleThermalProcessing={handleThermalProcessing}
                handleWorkingOnMachining={handleWorkingOnMachining}
              />
            </GridItem>
            <GridItem md={6}>
              <FormTextInput
                label={t('form.field.recipe')}
                classes={classes}
                name="recipeState"
                value={recipeState}
                handleChange={handleChange}
                inputSize={12}
                multiline={true}
                rows={3}
                rowsMax={20}
                maxLength={5000}
              />
            </GridItem>
            <GridItem md={6}>
              <FormTags
                label={t('form.field.allergens')}
                classes={classes}
                tagProps={{
                  className:
                    'react-tagsinput-tag react-tagsinput-tag-small react-tagsinput-tag-disabled danger',
                }}
                state={getAllergens}
                disabled={true}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h3>{t('form.additionalInfo')}</h3>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem md={12}>
              <GridContainer>
                <GridItem md={2}>
                  <FormSelectSingle
                    classes={classes}
                    label={t('form.field.difficultyLevel') + '*'}
                    options={difficulty}
                    value={difficultyState}
                    mapBy="value"
                    trackBy="value"
                    name="difficultyState"
                    handleChange={handleChange}
                    id="difficulty"
                  />
                  <FormTextInput
                    label={t('form.field.preparationTime')}
                    type="number"
                    classes={classes}
                    name="preparationTime"
                    value={preparationTime}
                    handleChange={handleChange}
                    inputSize={12}
                  />
                </GridItem>
                <GridItem md={6}>
                  <FormImageUpload
                    label={t('form.field.kitchenImage')}
                    classes={classes}
                    stateName="kitchenImageState"
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={kitchenImageStateUrl}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/recipes"
        handleSubmit={handleSubmit}
      />
      <LogView iri={`/recipes/${match.params.id}`} />
    </>
  );
};

export default RegularRecipeForm;
