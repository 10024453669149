import React from 'react';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import CombinedReport from './CombinedReport/CombinedReport';

class RecipesChecklist extends React.Component {
  state = {
    includeSubscriptions: false,
  };

  resolveFileName = ({ parameters }) => {
    const title = this.props
      .t('common.mainMenu.reportsRecipesChecklist')
      .replace(' ', '-');

    if (parameters.length === 1) {
      const { multiplier, dateFrom, dateTo } = parameters[0];

      return `${multiplier}x${title}-${dateFrom}-${dateTo}_(${moment().format(
        'YYYY-MM-DD_HH.mm'
      )})`;
    }

    return `${title}_(${moment().format('YYYY-MM-DD_HH.mm')})`;
  };

  render() {
    const { t } = this.props;

    return (
      <CombinedReport
        title={t('reports.reportRecipesChecklist')}
        url="reports/checklist-recipes"
        mimeTypes={['application/vnd.ms-excel', 'application/pdf']}
        role={roles.ROLE_SHOW_REPORT_CHECKLIST_RECIPES}
        fileName={this.resolveFileName}
        useDateRange={true}
        showMultiplier={true}
        validateBrands={false}
        validateDatePicker={true}
        useSubBrands={true}
        useZoneCategories={true}
        archiveReportNames={['CHECK_LIST_RECIPES']}
      />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(RecipesChecklist);
