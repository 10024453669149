import React from 'react';
import { DefaultEditor } from 'react-simple-wysiwyg';

class TextEdit extends React.Component {
  render() {
    return (
      <DefaultEditor
        value={this.props.value}
        onChange={this.props.handleChange}
      />
    );
  }
}

export default TextEdit;
