import React from 'react';
import { withTranslation } from 'react-i18next';
import Moment from 'moment';

import { Container, ItemWrapper } from './v2Types.styles';

const v2Types = ({ items, fillModal, t }) => {
  const createModalContent = () => {
    return items.map(item => {
      switch (item.type) {
        case 'OrderItemDayDish':
          return (
            <ItemWrapper>
              <h3>Danie</h3>
              <p>{item?.dishSize?.dish?.nameForClient}</p>
              <p>ilość: {item?.quantity}</p>
              <p>cena po obniżce: {item?.priceAfterDiscount}</p>
              <p>cena przed obniżką: {item?.priceBeforeDiscount}</p>
              <p>
                {Moment(item?.day).format(
                  Moment.localeData().longDateFormat('L')
                )}
              </p>
            </ItemWrapper>
          );
        case 'OrderItemDayAddon':
          return (
            <ItemWrapper>
              <h3>Dodatek</h3>
              <p>{item?.purchasedAddon?.addon?.name}</p>
              <p>ilość: {item?.purchasedAddon?.quantity}</p>
              <p>cena po obniżce: {item?.priceAfterDiscount}</p>
              <p>cena przed obniżką: {item?.priceBeforeDiscount}</p>
              <p>
                {Moment(item?.day).format(
                  Moment.localeData().longDateFormat('L')
                )}
              </p>
            </ItemWrapper>
          );
        case 'OrderItemDiet':
          return (
            <ItemWrapper>
              <h3>Dieta</h3>
              <p>wariant: {item.variant.name}</p>
              <p>calorific: {item.calorific.name}</p>
              <p>
                {item?.address?.postCode} {item?.address?.city},{' '}
                {item?.address?.street} {item?.address?.buildNumber}
                {item?.address?.placeNumber &&
                  `\\${item?.address?.placeNumber}`}
              </p>
              <p>cena po obniżce: {item?.priceV2?.afterDiscount}</p>
              <p>cena przed obniżką: {item?.priceV2?.beforeDiscount}</p>
            </ItemWrapper>
          );
        case 'OrderItemDayDelivery':
          return (
            <ItemWrapper>
              <h3>Dostawa</h3>
              <p>
                {Moment(item?.day).format(
                  Moment.localeData().longDateFormat('L')
                )}
              </p>
              {item.pickupPoint && <>{item.pickupPoint.value}</>}
              {item.address && (
                <p>
                  {item.address.postCode} {item.address.city},{' '}
                  {item.address.street} {item.address.buildNumber}/
                  {item.address.placeNumber}
                </p>
              )}
            </ItemWrapper>
          );
        case 'OrderItemDayModification':
          return (
            <ItemWrapper>
              <h3 style={{ fontWeight: '500', marginBottom: '5px' }}>
                {'Modyfikacja zamówienia'}
              </h3>
              {!!item?.changedDate && (
                <p>
                  {t('orderItemDayModification.changedDate', {
                    defaultValue: 'Zmiana daty dostawy: {{date}}',
                    replace: {
                      date: Moment(item?.changeDate?.date).format(
                        Moment.localeData().longDateFormat('L')
                      ),
                    },
                  })}
                </p>
              )}
              {!!item?.changedDiet && (
                <p>
                  {t('orderItemDayModification.changedDiet', {
                    defaultValue: 'Zmiana diety: {{dietName}}',
                    replace: {
                      dietName: item?.changedDiet?.name,
                    },
                  })}
                </p>
              )}
              {!!item?.changedVariant && (
                <p>
                  {t('orderItemDayModification.changedVariant', {
                    defaultValue: 'Zmiana wariantu: {{variantName}}',
                    replace: {
                      variantName: item?.changedVariant?.name,
                    },
                  })}
                </p>
              )}
              {!!item?.changedCalorific && (
                <p>
                  {t('orderItemDayModification.changedCalorific', {
                    defaultValue: 'Zmiana kaloryczności: {{calorificName}}',
                    replace: {
                      calorificName: item?.changedCalorific?.name,
                    },
                  })}
                </p>
              )}
              {!!item?.changedDietOptions?.optionChangeMenu && (
                <p>
                  {t(
                    'orderItemDayModification.changedMenuOption',
                    '$$Dokupiono wybór menu'
                  )}
                </p>
              )}
              {!!item?.changedDietOptions?.useEcoContainers && (
                <p>
                  {t(
                    'orderItemDayModification.changedEcoContainersOption',
                    '$$Dokupiono eko opakowania'
                  )}
                </p>
              )}
              {!!item?.changedAddress && (
                <>
                  <p>
                    {t(
                      'orderItemDayModification.changedAddress',
                      '$$Zmiana adresu: '
                    )}
                  </p>
                  <p>
                    {item.changedAddress.postCode} {item.changedAddress.city},{' '}
                    {item.changedAddress.street}{' '}
                    {item.changedAddress.buildNumber}/
                    {item.changedAddress.placeNumber}
                  </p>
                </>
              )}
              {!!item?.changedPickUpPoint && (
                <>
                  <p>
                    {t(
                      'orderItemDayModification.changedPickupPoint',
                      '$$Zmiana punktu odbioru: '
                    )}
                  </p>
                  <p>{item?.changedPickUpPoint?.value}</p>
                </>
              )}
              <p style={{ marginTop: '15px' }}>
                {t('orderItem.priceAfterDiscount', {
                  defaultValue: 'Cena po obniżce: {{price}}',
                  replace: { price: item?.priceV2?.afterDiscount },
                })}
              </p>
              <p>
                {t('orderItem.priceBeforeDiscount', {
                  defaultValue: 'Cena przed obniżką: {{price}}',
                  replace: { price: item?.priceV2?.afterDiscount },
                })}
              </p>
              <p>
                {Moment(item?.bag?.date).format(
                  Moment.localeData().longDateFormat('L')
                )}
              </p>
            </ItemWrapper>
          );
        default:
          return '';
      }
    });
  };

  const getModalContent = () => {
    fillModal(
      <Container>
        {/*<h1>*/}
        {/*  {t('orders.buyBag.subscriptionRenewalWithId', {*/}
        {/*    defaultValue: 'Odnowienie subskrypcji diety #{{orderId}}',*/}
        {/*  })}*/}
        {/*</h1>*/}
        {createModalContent()}
      </Container>
    );
  };

  const isAnyItemDayModification = items.some(
    ({ type }) => type === 'OrderItemDayModification'
  );

  return (
    <span
      style={{ color: '#89229b', cursor: 'pointer' }}
      onClick={getModalContent}
    >
      {isAnyItemDayModification
        ? t('orders.modification', '$$Modyfikacje')
        : t('orders.items', '$$Przedmioty')}
    </span>
  );
};

export default withTranslation()(v2Types);
