const defaultState = {
  name: '',
  workName: '',
  code: '',
  description: '',
  checked: false,
  color: '#000000',
  clientImage: null,
  clientImageUrl: '',
  mainImage: null,
  mainImageUrl: '',
  allowSelectMenuFromDiets: [],
  carbohydratesAssumption: '',
  carbohydratesMargin: '',
  fatsAssumption: '',
  fatsMargin: '',
  proteinAssumption: '',
  proteinMargin: '',
  position: '',
  hideDisabledMenuPlannerOptions: false,
};

export default defaultState;
