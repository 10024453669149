import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import _ from 'lodash';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchPermissions } from 'actions/Permissions';
import { fetchRole } from 'actions/Roles';
import { onDeselect, onSelect } from './permissionsConfig';
import { withToast } from 'material-ui-toast-redux';

import { post, put } from 'helpers/apiHelpers';

import FormLabel from '@material-ui/core/FormLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RootCheckbox from '@material-ui/core/Checkbox';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInputNoGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { withTranslation } from 'react-i18next';
import {Card} from "@material-ui/core";
import CardBody from "../../components/Card/CardBody";

const Checkbox = ({ role, permissions, handleChange, style = {} }) => {
  return (
    <RootCheckbox
      style={style}
      disabled={lockedPermissions.includes(role)}
      checked={permissions.includes(role)}
      onChange={(ev, checked) => handleChange(checked, role)}
    />
  );
};

const translateActions = t => ({
  SHOW: t('rolesForm.show', 'Przeglądanie'),
  CREATE: t('rolesForm.create', 'Dodawanie'),
  EDIT: t('rolesForm.edit', 'Edytowanie'),
  REMOVE: t('rolesForm.remove', 'Usuwanie'),
});

const translateModules = t => ({
  MENU_PLANNER: t('common.mainMenu.menuPlanner', 'Planowanie menu'),
  MENU_PLANNER_AUTO_FILL: t(
    'rolesForm.autoFill',
    'Planowanie menu - automatyczne uzupełnianie'
  ),
  MENU: t('common.mainMenu.menuPlanner', 'Planowanie menu'),
  SIZE: t('common.mainMenu.sizes', 'Wielkości'),
  CONTRACTOR: t('rolesForm.contractors', 'Kontrahenci'),
  MEAL_TYPE: t('common.mainMenu.typesOfMeals', 'Typy posiłków'),
  DIET: t('$*common.diets', 'Diety'),
  INGREDIENT: t('common.mainMenu.ingredients', 'Składniki'),
  RECIPE: t('common.mainMenu.recipes', 'Przepisy'),
  DISH: t('common.mainMenu.meals', 'Dania'),
  PACKAGE: t('common.mainMenu.packages', 'Pakiety'),
  VARIANT: t('common.mainMenu.variants', 'Warianty'),
  DICTIONARY: t('common.mainMenu.dictionariesCaption', 'Słowniki'),
  COMPANY: t('rolesForm.company', 'Firma'),
  USER: t('common.mainMenu.clients', 'Klienci'),
  EMPLOYEE: t('common.mainMenu.myCompanyEmployees', 'Pracownicy'),
  CLIENT_CALL: t('rolesForm.clientCall', 'Połączenia z klientami'),
  IMPERSONATE_USER: t('rolesForm.impersonating', 'Podszywanie sie pod klienta'),
  BRAND: t('common.mainMenu.myCompanyBrands', 'Marki'),
  SHOW_MENU_PAGE: t('rolesForm.menuPage', 'Strona z menu'),
  ROLE: t('common.mainMenu.myCompanyRoles', 'Role'),
  ZONE_CATEGORY: t('common.mainMenu.zoneCategories', 'Kategorie stref'),
  ZONE: t('common.mainMenu.zones', 'Strefy'),
  BAG: t('ecommerceBags.name', 'Strefy'),
  BASKET: t('basket.menu', 'Koszyki'),
  COST: t('common.mainMenu.priceLists', 'Cenniki'),
  ADDRESS: t('clients.addresses', 'Adresy'),
  DISCOUNT_CODE: t('common.mainMenu.discountCodes', 'Kody rabatowe'),
  DRIVER: t('common.mainMenu.drivers', 'Kierowcy'),
  MONEY_BOX_HISTORY: t('clients.moneybox', 'Skarbonka'),
  ORDER: t('clients.orders', 'Zamówienia'),
  NOTE: t('rolesForm.clientNotes', 'Globalne zarządzanie notatkami'),
  SMS: t('rolesForm.clientSMS', 'SMS do klienta'),
  EMAIL: t('rolesForm.clientEmail', 'E-mail do klienta'),
  RATE: t('rolesForm.rates', 'Oceny'),
  SUB_BRAND: t('common.mainMenu.subBrands', 'Paramarki'),
  SUB: t('common.mainMenu.subBrands', 'Paramarki'),
  OVERPRODUCTION: t('list.additionalOrders', 'Zam. dodatkowe'),
  HOVER: t('common.mainMenu.hover', 'Hover'),
  WASTE: t('common.mainMenu.waste', 'Straty'),
  MONEY_BOX_CONFIGURATION: t(
    'common.mainMenu.moneyBoxConfig',
    'Konfiguracja skarbonki'
  ),
  MAIL_CONFIGURATION: t(
    'common.mainMenu.myCompanyMailNotifications',
    'Powiadomienia mailowe'
  ),
  END_OF_DIET_REMINDER: t(
    'common.mainMenu.myCompanyMailReminders',
    'Przypomnienia mailowe'
  ),
  NOTIFICATION: t('common.mainMenu.messages', 'Komunikaty'),
  REPORT_DISH_LIST: t(
    'rolesForm.dishListReport',
    'Raport - Dania do produkcji'
  ),
  REPORT_DISH_STICKERS: t(
    'rolesForm.stickersReport',
    'Raport - Naklejki na dania'
  ),
  REPORT_BAG_STICKERS: t(
    'rolesForm.labelsReport',
    'Raport - Etykiety na torby'
  ),
  REPORT_BAG_STICKERS_MINI: t(
    'rolesForm.labelsReportMini',
    'Raport - Etykiety na torby MINI'
  ),
  REPORT_DISHES_CARD: t('rolesForm.menuCardsReport', 'Raport - Karty dań'),

  REPORT_RECIPES_CARD: t(
    'rolesForm.recipeCardsReport',
    'Raport - Karty przepisów'
  ),
  REPORT_FOR_DRIVER: t('rolesForm.forDriverReport', 'Raport - Dla kierowcy'),
  REPORT_SHOPPING: t('rolesForm.shopListReport', 'Raport - Lista zakupów'),
  REPORT_CONTAINERS: t('rolesForm.containersReport', 'Raport - Pojemniki'),
  REPORT_CONTAINERS_DETAIL: t(
    'rolesForm.containersDetReport',
    'Raport - Pojemniki (szczegółowy)'
  ),
  REPORT_USAGE_DISCOUNT_CODES: t(
    'rolesForm.usedDiscCodesReport',
    'Raport - Użyte kody rabatowe'
  ),
  REPORT_MONEY_BOX_TOP_UP: t(
    'rolesForm.topUpReport',
    'Raport  - Doładowania skarbonki'
  ),
  REPORT_OVERPRODUCTION: t(
    'rolesForm.addOrdersReport',
    'Raport - Zam. dodatkowe'
  ),
  REPORT_WASTE: t('rolesForm.lossesReport', 'Raport - Straty'),
  REPORT_CHECKLIST_RECIPES: t(
    'rolesForm.recipesChecklistReport',
    'Raport - Checklista przepisów'
  ),
  REPORT_CHECKLIST_DISHES: t(
    'rolesForm.foodChecklistReport',
    'Raport - Checklista dań'
  ),
  REPORT_ACTIVE_DIET: t('common.mainMenu.reportActiveDiets', 'Raport - Diet'),
  REPORT_PERSONAL_PICKUPS: t(
    'common.mainMenu.reportPersonalPickups',
    'Raport - Odbiory osobiste'
  ),
  REPORT_PACKERS: t('common.mainMenu.reportPackers', 'Raport - Dla pakowni v2'),
  REPORT_RECIPE_STICKERS: t(
    'common.mainMenu.reportRecipeStickers',
    'Raport - Naklejki na przepisy'
  ),
  REPORT_INCOME_SUMMARY: t('common.mainMenu.reportsIncomeSummary'),
  REPORT_FOOD_COST: t('rolesForm.raportFoodCost', 'Raport - FoodCost Diet'),
  REPORT_FOOD_COST_BAGS: t(
    'rolesForm.raportFoodCostBags',
    'Raport - FoodCost Toreb'
  ),
  REPORT_INCOMING_SUBSCRIPTION: t(
    'rolesForm.incomingSubscriptionsReport',
    'Raport - FoodCost Diet'
  ),
  REPORT_PURCHASED_ADDONS: t(
    'rolesForm.raportAddonList',
    'Raport - Lista dodatków'
  ),
  REPORT_PURCHASED_ADDONS_BY_CLIENT:
    t('rolesForm.reportPrefix') +
    t('common.mainMenu.reportsAddonsByClientList'),
  REPORT_ARCHIVE: t('reportArchive'),
  PAGE: t('rolesForm.subpages', 'Podstrony'),
  MASS_SMS: t('rolesForm.massSMS', 'Masowa wysyłka smsów'),
  MASS_ACTION: t('massAction.dietElements.title'),
  IMPORTER_TASK: t('rolesForm.importer', 'Importer'),
  STATISTICS_DIET: t('rolesForm.statistics', 'Statystyki'),
  STATISTICS_DIET_INCOMES: t(
    'rolesForm.statisticsIncomes',
    'Statystyki - przychody'
  ),
  STATISTICS_DIET_SEX: t(
    'rolesForm.statisticsGender',
    'Statystyki - płeć klientów'
  ),
  STATISTICS_DIET_KNOW_ABOUT: t(
    'rolesForm.statisticsAboutUs',
    'Statystyki - skąd o nas wiesz'
  ),
  STATISTICS_ECOMMERCE_DAY_ORDERS: t(
    'rolesForm.statisticEcommerceDayOrders',
    'Statystyki - Ilość zamówień dla danego dnia'
  ),
  STATISTICS_ECOMMERCE_DAY_BAGS: t(
    'rolesForm.statisticEcommerceDayBags',
    'Statystyki - Ilość toreb dla danego dnia'
  ),
  STATISTICS_ECOMMERCE_DAY_INCOME: t(
    'rolesForm.statisticEcommerceDayIncome',
    'Statystyki - Przychód dla danego dnia'
  ),
  STATISTICS_CORE_DAY_USERS: t(
    'rolesForm.statisticCoreDayUsers',
    'Statystyki - Ilość utworzonych kont dla daniego dnia'
  ),
  STATISTICS_CORE_DAY_MONEY_BOXES: t(
    'rolesForm.statisticCoreDayMoneyBoxes',
    'Statystyki - Ilość dodanych dla użytkowników punktów dla danego dnia'
  ),
  STATISTICS_ECOMMERCE_YEAR_BAGS: t(
    'rolesForm.statisticEcommerceYearBags',
    'Statystyki - Ilość toreb dla danego roku'
  ),
  STATISTICS_ECOMMERCE_YEAR_INCOME: t(
    'rolesForm.statisticEcommerceYearIncome',
    'Statystyki - Przychód dla danego roku'
  ),
  MAILING: t('rolesForm.mailing', 'Przypomnienia mailowe (aktywacja)'),
  MODULE_ADDONS: t(
    'rolesForm.addonsModuleSettings',
    'Ustawienia modułu - Dodatki'
  ),
  MODULE_SHOP: t('common.mainMenu.shop'),
  ADMIN_NAV_LINK: t('rolesForm.menuShortcuts', 'Skróty menu'),
  REPORT_ADVANCED_DISH_PDF: t(
    'rolesForm.advancedPdfRaport',
    'Zaawansowany raport dania PDF'
  ),
  MODULE_DISH_PDF_GENERATOR: t(
    'rolesForm.advancedDishConfigurationModule',
    'Moduł konfiguracji zaawansowanego dania'
  ),
  SYSTEM_ADVANCED_DISH: t(
    'rolesForm.advancedDishView',
    'Widok dania zaawansowany'
  ),
  ADDON: t('rolesForm.addon', 'Dodatki'),
  BRAND_ACCESS_KEY: t('brandCfg.accessKeysLabel'),
  ROUTE_POINT: t('routePlanner.menuTitle'),
  MODULE_SETTLEMENTS: t('moduleSettlements'),
  POST_CODE_REQUESTS: t(
    'rolesForm.postCodeRequests',
    'Zapytania o kod pocztowy'
  ),
  REPORT_CHECKLIST_DIETS: t(
    'rolesForm.dietsChecklistReport',
    'Raport - Checklista diet'
  ),
  MENU_SUMMARY: t('rolesForm.menuSummary', 'Podsumowanie menu'),
  REPORT_CHECKLIST_ALTERED_DIETS: t(
    'rolesForm.alteredDietsChecklistReport',
    'Raport - Checklista wyborów'
  ),
  TRANSLATION: t('common.mainMenu.translations', 'Tłumaczenia'),
  REPORT_MACRO_INGREDIENTS: t(
    'common.mainMenu.macroIngredientsReport',
    'Raport - Wartości odżywcze'
  ),
  USE_MINI_ADDRESS_FORM: t(
    'common.rolesForm.useLessFieldsForm',
    'Użyj skróconego formularza adresu'
  ),
  REPORT_TOTAL_BEL: t('rolesForm.totalBelReport', 'Raport - Total Bel'),
  BRAND_POWER_BI: t(
    'common.rolesForm.modulePowerBI',
    'Moduł PowerBI'
  ),
  CMS_DIET: t('common.mainMenu.CMS.diets', 'Diety'),
  CMS_DISH: t('common.mainMenu.CMS.dishes', 'Dania'),
  CMS_ADDON: t('common.mainMenu.CMS.addons', 'Dodatki'),
  CMS_ZONE: t('common.mainMenu.CMS.zone', 'Strefy dostaw'),
});

const ROLE_BLOCKS = [
  {
    name: 'common.mainMenu.reports',
    permissionGroups: (roles) => roles.filter(role => role.permissions.length === 1 && role.name.includes('REPORT')),
  },
  {
    name: 'rolesForm.statistics',
    permissionGroups: (roles) => roles.filter(role => role.permissions.length === 1 && role.name.includes('STATISTICS')),
  },
  {
    name: 'CMS',
    permissionGroups: (roles) => roles.filter(role => role.permissions.length === 1 && role.name.toLowerCase().includes('cms_')),
  }
];

const lockedPermissions = [];

class RolesForm extends Component {
  state = {
    name: '',
    shortName: '',
    permissions: lockedPermissions,
    translatedActions: translateActions(this.props.t),
    translatedModules: translateModules(this.props.t),
  };

  isEdit = this.props.match.path.includes('edit');
  roleId = this.props.match.params.id;

  componentDidMount() {
    this.props.fetchPermissions();
    if (this.isEdit) {
      this.props.fetchRole(this.roleId).then(response =>
        this.setState({
          name: response.name,
          shortName: response.shortName,
          permissions: response.permissions,
        })
      );
    }
  }

  translate() {
    this.setState({
      translatedAction: translateActions(this.props.t),
      translatedModules: translateModules(this.props.t),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.i18n.lang !== this.props.i18n.lang) {
      this.translate();
    }
  }

  showRoleToast = (action, toastInfoMessage, role, toastActionMessage) => {
    let arrayToCheck = [];
    let filteredArrayToCheck = [];

    if (action === 'select') {
      arrayToCheck = onSelect[role];
      filteredArrayToCheck = arrayToCheck.filter(
        i => !this.state.permissions.includes(i)
      );
    } else if (action === 'deselect') {
      arrayToCheck = onDeselect[role];
      filteredArrayToCheck = arrayToCheck.filter(i =>
        this.state.permissions.includes(i)
      );
    }

    if (filteredArrayToCheck.length > 0) {
      const messages = [
        toastInfoMessage,
        ...this.prepareRequiredRolesMessage(filteredArrayToCheck),
        toastActionMessage,
      ];
      return this.props.openToast({
        messages,
        type: 'info',
        autoHideDuration: 6000,
      });
    }
  };

  prepareRequiredRolesMessage = rolesToCheck => {
    return rolesToCheck.map(role => {
      const roleArrayStrings = role.split('_');
      const roleNameArray = roleArrayStrings.slice(2);

      const moduleKey = roleNameArray.join('_');
      const actionKey = roleArrayStrings[1];

      const translatedModule = this.state.translatedModules[moduleKey];
      const translatedAction = this.state.translatedActions[actionKey];

      return `${translatedModule} - ${translatedAction}`;
    });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckboxChange = (append, role) => {
    let newPermissions = [...this.state.permissions];
    if (append) {
      const selectRequiredInfoMessage = this.props.t(
        'rolesForm.selectRoles',
        'Zaznaczenie tej roli wymaga też zaznaczenia ról:'
      );
      const selectRequiredActionMessage = this.props.t(
        'rolesForm.autoSelect',
        'Wymagane role zostały automatycznie zaznaczone.'
      );
      onSelect[role] &&
        this.showRoleToast(
          'select',
          selectRequiredInfoMessage,
          role,
          selectRequiredActionMessage
        );
      newPermissions.push(role, ...(onSelect[role] || []));
    } else {
      const selectRequiredInfoMessage = this.props.t(
        'rolesForm.deselectRoles',
        'Odznaczenie tej roli wymaga też odznaczenia ról:'
      );
      const selectRequiredActionMessage = this.props.t(
        'rolesForm.autoDeselect',
        'Wymagane role zostały automatycznie odznaczone.'
      );
      onDeselect[role] &&
        this.showRoleToast(
          'deselect',
          selectRequiredInfoMessage,
          role,
          selectRequiredActionMessage
        );
      newPermissions = newPermissions.filter(
        perm => !(onDeselect[role] || []).includes(perm) && perm !== role
      );
    }

    this.setState({
      permissions: newPermissions,
    });
  };

  getRoleByType = (type, permissions) => {
    const perm = permissions.find(permission => permission.type === type);

    return perm ? perm.role : false;
  };

  renderTable = () => {
    const types = _.uniq(
      _.concat(
        ...this.props.permissions.map(perm =>
          perm.permissions.map(permission => permission.type)
        )
      )
    );

    let role;
    const TRANSLATE_ACTIONS = this.state.translatedActions;
    const TRANSLATE_MODULES = this.state.translatedModules;
    const rolesInBlock = ROLE_BLOCKS.reduce((prev, curr) => [...prev, ...curr.permissionGroups(this.props.permissions)],[]).map(el => el.name);
    const permissionGroups = this.props.permissions.filter(el => !rolesInBlock.includes(el.name));
    return (
      <div>
        <Table style={{ position: 'relative' }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ position: 'sticky', top: 0, background: '#fff' }}
              >
                {this.props.t('rolesForm.moduleName', 'Nazwa modułu')}
              </TableCell>
              {types.map((type, key) => (
                <TableCell
                  align="center"
                  key={key}
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: '#fff',
                    zIndex: 2,
                  }}
                >
                  {TRANSLATE_ACTIONS[type] || type}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {permissionGroups.map((permissionGroup, key) => (
              <TableRow key={key}>
                <TableCell>
                  {TRANSLATE_MODULES[permissionGroup.name] ||
                    permissionGroup.name}
                </TableCell>
                {types.map((type, key) => (
                  <TableCell align="center" key={key}>
                    {(role = this.getRoleByType(
                      type,
                      permissionGroup.permissions
                    )) && (
                      <Checkbox
                        role={role}
                        handleChange={(append, role) =>
                          this.handleCheckboxChange(append, role)
                        }
                        permissions={this.state.permissions}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {ROLE_BLOCKS.map((permissionBlock, permissionBlockIndex) => (
          <AdminTable title={this.props.t(permissionBlock.name)} style={{marginTop: 20}} key={`pbi_${permissionBlockIndex}`}>
            <GridContainer>
              {permissionBlock.permissionGroups(this.props.permissions).map((permissionGroup, permissionGroupIndex) => (
                <GridItem key={`pgi_${permissionGroupIndex}`} xs={12} sm={4} md={3} lg={2} className={'d-flex'}>
                  <Card style={{height:'100%'}}>
                    <CardBody style={{textAlign: 'center', height: '100%'}}>
                      <GridContainer style={{alignContent: 'space-between', display: 'flex', height: '100%'}}>
                        <GridItem  sm={8} md={12}>
                          {TRANSLATE_MODULES[permissionGroup.name] || permissionGroup.name}
                        </GridItem>
                        <GridItem sm={4} md={12}>
                          <Checkbox
                            style={{display: 'block', margin: '0 auto'}}
                            role={permissionGroup.permissions[0].role}
                            handleChange={(append, role) =>
                              this.handleCheckboxChange(append, role)
                            }
                            permissions={this.state.permissions}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              ))}
            </GridContainer>
          </AdminTable>
        ))}
      </div>
    );
  };

  validatePermissions = () => {
    return this.state.permissions.length > 0;
  };

  validateForm = () => {
    return this.state.name;
  };

  handleSubmit = () => {
    if (!this.validatePermissions()) {
      return alert(
        this.props.t(
          'rolesForm.onePermission',
          'Musisz nadać przynajmniej jedno uprawnienie'
        )
      );
    }
    if (!this.validateForm()) {
      return alert(
        this.props.t(
          'rolesForm.mandatoryFields',
          'Wypełnij wszystkie obowiązkowe pola'
        )
      );
    }
    const data = this.state;

    const action = this.isEdit
      ? put(`/roles/${this.roleId}`, data)
      : post('/roles', data);

    action.then(() => this.props.history.push('/admin/roles'));
  };

  render() {
    const { classes } = this.props;
    return (
      <form>
        <h2>
          {' '}
          {this.isEdit
            ? this.props.t('myCompany.roleEdit')
            : this.props.t('myCompany.roleCreation')}{' '}
        </h2>
        <AdminTable>
          <GridContainer>
            <GridItem xs={6}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('myCompany.roleName')} *
              </FormLabel>
              <FormTextInput
                classes={classes}
                value={this.state.name}
                name="name"
                handleChange={ev => this.handleInputChange(ev)}
              />
            </GridItem>
            <GridItem xs={6}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('myCompany.roleShort')}
              </FormLabel>
              <FormTextInput
                classes={classes}
                value={this.state.shortName}
                name="shortName"
                handleChange={ev => this.handleInputChange(ev)}
              />
            </GridItem>
            <GridItem xs={12}>{this.renderTable()}</GridItem>
            <GridItem xs={12}>
              <FormControlButtons
                classes={classes}
                discardText={this.props.t('common.shared.cancel')}
                submitText={this.props.t('common.shared.save')}
                cancelPath="/admin/roles"
                handleSubmit={this.handleSubmit}
              />
            </GridItem>
          </GridContainer>
        </AdminTable>
      </form>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => ({
  permissions: state.Permissions.permissions,
  role: state.Roles.role,
});

const mapDispatchToProps = dispatch => ({
  fetchPermissions: () => dispatch(fetchPermissions()),
  fetchRole: id => dispatch(fetchRole(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withToast(withStyles(combinedStyles)(RolesForm))));
