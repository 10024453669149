import React, { Component } from 'react';
import _ from 'lodash';

import { combineStyles } from 'helpers/helpers';
import { get, post, put } from 'helpers/apiHelpers';

import { isGranted } from 'helpers/helpers';
import { ROLE_EDIT_COST } from 'helpers/roles';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { withToast } from 'material-ui-toast-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class DietDeliveryPlaces extends Component {
  state = {
    pickupPoints: [],
  };

  componentDidMount() {
    Promise.all([get('/discount-pick-up-points'), get('/pick-up-points')]).then(
      ([discountPickupsResponse, pickupsResponse]) => {
        const discountPickups = discountPickupsResponse['hydra:member'];

        const pickupPoints = pickupsResponse['hydra:member'].map(el => {
          return {
            hasChanged: false,
            ...el,
            '@id': null,
            id: _.get(
              discountPickups.find(e => e.pickUpPoint === el['@id']),
              '@id',
              null
            ),
            pickUpPoint: el['@id'],
            discount: _.get(
              discountPickups.find(e => e.pickUpPoint === el['@id']),
              'discount',
              ''
            ),
            _discount: _.get(
              discountPickups.find(e => e.pickUpPoint === el['@id']),
              'discount',
              ''
            ),
            isPercentage: _.get(
              discountPickups.find(e => e.pickUpPoint === el['@id']),
              'isPercentage',
              false
            ),
          };
        });

        this.setState({
          pickupPoints,
        });
      }
    );
  }

  findCurrentPickupIndex = id =>
    this.state.pickupPoints.findIndex(point => point.pickUpPoint === id);

  handleChange = (event, point) => {
    const { name, value } = event.target;
    const { pickupPoints } = this.state;

    const index = this.findCurrentPickupIndex(point.pickUpPoint);
    pickupPoints[index][name] = value;
    pickupPoints[index].hasChanged =
      pickupPoints[index][`_${name}`] !== parseFloat(value);

    this.setState({ pickupPoints });
  };

  validateForm = () => {
    const { pickupPoints } = this.state;
    let isValid = true;

    if (pickupPoints.some(point => point.discount === '')) {
      isValid = false;
      this.props.openToast({
        messages: [
          this.props.t(
            'prices.toast.notEmpty',
            'Pola Koszt i VAT nie mogą być puste'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (pickupPoints.some(point => isNaN(point.discount))) {
      isValid = false;
      this.props.openToast({
        messages: [
          this.props.t(
            'prices.toast.mustBeNumb',
            'Pola Koszt i VAT muszą być liczbami'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    return isValid;
  };

  saveAll = () => {
    const { pickupPoints } = this.state;

    if (!this.validateForm()) {
      return;
    }

    pickupPoints.forEach(pickupPoint => {
      if (pickupPoint.discount !== '') {
        pickupPoint.discount = parseFloat(pickupPoint.discount);
      }
    });
    return Promise.all(
      pickupPoints.map(pickupPoint => {
        if (pickupPoint.hasChanged) {
          pickupPoint.id
            ? put(pickupPoint.id, pickupPoint)
            : post('/discount-pick-up-points', pickupPoint);
        }
      })
    ).then(() => {
      pickupPoints.forEach(point => {
        point.hasChanged = false;
      });
      this.props.openToast({
        messages: [this.props.t('common.shared.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
      this.setState({
        pickupPoints: pickupPoints.map(point => ({
          ...point,
          _discount: point.discount,
        })),
      });
    });
  };

  render() {
    const { classes } = this.props;
    const { pickupPoints } = this.state;

    const DiscountElementTypes = [
      {
        name: this.props.t('common.shared.percentage', 'Procentowy'),
        value: true,
      },
      {
        name: this.props.t('common.shared.amount', 'Kwotowy'),
        value: false,
      },
    ];

    if (pickupPoints.length === 0) return null;

    return (
      <React.Fragment>
        <GridItem md={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {this.props.t('common.shared.pickupPoint')}
                </TableCell>
                <TableCell>
                  {this.props.t('common.shared.discountType')}
                </TableCell>
                <TableCell>
                  {this.props.t('common.shared.discountForDay')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pickupPoints.map((point, index) => (
                <TableRow
                  style={{
                    backgroundColor: point.hasChanged
                      ? '#ffffd4'
                      : 'transparent',
                  }}
                  key={index}
                >
                  <TableCell component="th" scope="row">
                    {point.value}
                    {point.hasChanged
                      ? this.props.t('common.shared.modified')
                      : ''}
                  </TableCell>
                  <TableCell>
                    <FormSelectSingle
                      classes={classes}
                      mapBy="name"
                      name="isPercentage"
                      trackBy="value"
                      value={point.isPercentage}
                      options={DiscountElementTypes}
                      disabled={!isGranted(ROLE_EDIT_COST)}
                      handleChange={event => this.handleChange(event, point)}
                      labelSm={0}
                      selectSm={8}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        disabled: !isGranted(ROLE_EDIT_COST),
                        type: 'number',
                        placeholder: this.props.t('common.shared.typeValue'),
                        name: 'discount',
                        value: point.discount,
                        onChange: event => this.handleChange(event, point),
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GridItem>
        {isGranted(ROLE_EDIT_COST) && (
          <FormControlButtons
            classes={classes}
            submitDisabled={
              !pickupPoints.some(pickupPoint => pickupPoint.hasChanged)
            }
            handleSubmit={this.saveAll}
            submitText={this.props.t('common.shared.save')}
          />
        )}
      </React.Fragment>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle
);

const enhance = compose(
  withStyles(combinedStyles),
  withToast,
  withTranslation()
);

export default enhance(DietDeliveryPlaces);
