import React from 'react';
import produce from 'immer';
import { useSelector } from 'react-redux';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardIcon from 'components/Card/CardIcon';
import InputsIndex from './InputsIndex';
import { useTranslation } from 'react-i18next';

const ShopMeals = ({ classes, shopModule, setShopModule }) => {
  const { t } = useTranslation();
  const currency = useSelector(
    state => state.Brands.brand.multinational.currency
  );

  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <ShoppingBasketIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          {t('$*shop.shopMeals.title', '$$Sklep z posiłkami')}
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4}>
            <GridContainer>
              <GridItem xs={12}>
                <h4 className={classes.cardIconTitle}>
                  {t(
                    '$*shop.shopMeals.dishAsOverDish',
                    '$$Dania dostępne, jako nadposiłki'
                  )}
                </h4>
                <InputsIndex
                  label={t(
                    '$*shop.shopMeals.dishesFromPlanning',
                    '$$z planowania menu na dany dzień'
                  )}
                  type="checkbox"
                  value={shopModule?.allowDishesFromPlaning}
                  handler={() => {
                    setShopModule(
                      produce(draft => {
                        draft.allowDishesFromPlaning = !draft.allowDishesFromPlaning;
                      })
                    );
                  }}
                />
                <InputsIndex
                  label={t(
                    '$*shop.shopMeals.dishesFromOffer',
                    '$$z dań będących w stałej ofercie sprzedaży'
                  )}
                  type="checkbox"
                  value={shopModule?.allowDishesFromOffer}
                  handler={() => {
                    setShopModule(
                      produce(draft => {
                        draft.allowDishesFromOffer = !draft.allowDishesFromOffer;
                      })
                    );
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={4}>
            <GridContainer>
              <GridItem xs={12}>
                <h4>
                  {t('$*shop.shopMeals.rangeDishOrderPrice', {
                    defaultValue:
                      '$$Dopuszczalna wartość zamówienia dziennie ({{currency}})',
                    replace: { currency },
                  })}
                </h4>
              </GridItem>
              <GridItem xs={6}>
                <InputsIndex
                  type="number"
                  placeholder={t('$*common.from', '$$od')}
                  value={shopModule?.minimumDailyOrderCost}
                  handler={e => {
                    setShopModule(
                      produce(draft => {
                        draft.minimumDailyOrderCost = e.target.value;
                      })
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <InputsIndex
                  type="number"
                  placeholder={t('$*common.to', '$$do')}
                  value={shopModule?.maximumDailyOrderCost}
                  handler={e => {
                    setShopModule(
                      produce(draft => {
                        draft.maximumDailyOrderCost = e.target.value;
                      })
                    );
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                {t(
                  '$*shop.shopMeals.rangeDishAmount',
                  '$$Dopuszczalna liczba dań dziennie'
                )}
              </GridItem>
              <GridItem xs={6}>
                <InputsIndex
                  type="number"
                  placeholder={t('$*common.from', '$$od')}
                  value={shopModule?.minimumDailyDishes}
                  handler={e => {
                    setShopModule(
                      produce(draft => {
                        draft.minimumDailyDishes = e.target.value;
                      })
                    );
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <InputsIndex
                  type="number"
                  placeholder={t('$*common.to', '$$do')}
                  value={shopModule?.maximumDailyDishes}
                  handler={e => {
                    setShopModule(
                      produce(draft => {
                        draft.maximumDailyDishes = e.target.value;
                      })
                    );
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default ShopMeals;
