import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableCell from '@material-ui/core/TableCell';
import { Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';
import menuPlannerUtlis from './utils';

const DishInfo = withStyles({
  body1: {
    fontSize: '0.8rem',
  },
})(Typography);

const Assumption = ({
  currentValue,
  expectedValue,
  margin,
  t,
  macroName,
  dietName,
}) => {
  if (null === currentValue || null === expectedValue || null === margin) {
    return null;
  }

  const isValid =
    currentValue <= expectedValue + margin &&
    currentValue >= expectedValue - margin;

  if (isValid) {
    return null;
  }

  return (
    <Tooltip
      style={{ cursor: 'help' }}
      title={
        <Card
          style={{
            backgroundColor: '#f65b52',
            color: '#fff',
            padding: '10px',
            fontWeight: 400,
          }}
        >
          <span style={{ fontSize: '14px' }}>
            {t(
              `menuPlanner.plannerCardSummary.waring.assumption.${macroName}`,
              { currentValue, expectedValue, margin, dietName }
            )}
          </span>
        </Card>
      }
      placement="right"
    >
      <WarningIcon
        style={{
          fontSize: '1.5em',
          color: '#f65b52',
          marginLeft: '10px',
          verticalAlign: 'middle',
        }}
      />
    </Tooltip>
  );
};

class PlannerBoxSummary extends React.Component {
  render() {
    const {
      assumptionOfCarbohydrates,
      assumptionOfFats,
      assumptionOfProtein,
    } = this.props;
    const add = (a, b) => {
      return a + b;
    };

    const getTotalMenuPlanners = property =>
      menuPlannerUtlis.getTotalMenuPlanners(
        property,
        this.props.calorific,
        this.props.menuPlanners
      );

    const getAverageRate = () => {
      let i = 0;
      let containsSizeWithoutRate = false;
      const sum = this.props.menuPlanners
        .map(row => {
          const mealTypeId = row.mealType.split('/').pop();
          const key = this.props.calorific + '_' + mealTypeId;

          if (
            typeof row.sizes === 'undefined' ||
            row.sizes === null ||
            typeof row.sizes[key] === 'undefined' ||
            row.sizes[key] === null
          ) {
            return 0;
          }
          if (
            row.sizes[key].rateCount === null ||
            row.sizes[key].rateCount === 0
          ) {
            containsSizeWithoutRate = true;
          }
          i++;
          return row.sizes[key].rateCount > 0
            ? row.sizes[key].rate / row.sizes[key].rateCount
            : 0;
        })
        .reduce(add, 0);

      if (containsSizeWithoutRate) {
        return '-';
      }

      return i > 0 ? (sum / i).toFixed(2) : '-';
    };

    const getTotalPercentage = (element, elementConstant) =>
      menuPlannerUtlis.getTotalPercentage(
        element,
        elementConstant,
        this.props.calorific,
        this.props.menuPlanners
      );

    return (
      <TableCell padding="dense" style={{ border: 0, width: '1px' }}>
        {parseInt(getTotalMenuPlanners('calories')) !== 0 && (
          <Card style={{ maxWidth: 220, margin: '0 auto' }}>
            <CardContent>
              <DishInfo component="p">
                <span>
                  {this.props.t('menuPlanner.plannerCardSummary.calories', {
                    value: getTotalMenuPlanners('calories'),
                  })}
                </span>
                <br />
                <span>
                  {this.props.t('menuPlanner.plannerCardSummary.protein', {
                    value: getTotalMenuPlanners('protein'),
                  })}
                  <Tooltip
                    title={this.props.t(
                      'menuPlanner.plannerCardSummary.protein.percentage'
                    )}
                    placement="top"
                  >
                    <span>
                      (
                      {getTotalPercentage(
                        'protein',
                        menuPlannerUtlis.constant.protein
                      )}
                      %)
                    </span>
                  </Tooltip>
                  <Assumption
                    currentValue={parseFloat(
                      getTotalPercentage(
                        'protein',
                        menuPlannerUtlis.constant.protein
                      )
                    )}
                    expectedValue={assumptionOfProtein.assumption}
                    margin={assumptionOfProtein.margin}
                    macroName={'protein'}
                    dietName={this.props.dietName}
                    t={this.props.t}
                  />
                </span>
                <br />
                <span>
                  {this.props.t(
                    'menuPlanner.plannerCardSummary.carbohydrates',
                    {
                      value: getTotalMenuPlanners('carbohydrates'),
                    }
                  )}
                  <Tooltip
                    title={this.props.t(
                      'menuPlanner.plannerCardSummary.carbohydrates.percentage'
                    )}
                    placement="top"
                  >
                    <span>
                      (
                      {getTotalPercentage(
                        'carbohydrates',
                        menuPlannerUtlis.constant.carbohydrates
                      )}
                      %)
                    </span>
                  </Tooltip>
                  <Assumption
                    currentValue={parseFloat(
                      getTotalPercentage(
                        'carbohydrates',
                        menuPlannerUtlis.constant.carbohydrates
                      )
                    )}
                    expectedValue={assumptionOfCarbohydrates.assumption}
                    margin={assumptionOfCarbohydrates.margin}
                    macroName={'carbohydrates'}
                    dietName={this.props.dietName}
                    t={this.props.t}
                  />
                </span>
                <br />
                <span>
                  {this.props.t('menuPlanner.plannerCardSummary.fat', {
                    value: getTotalMenuPlanners('fat'),
                  })}
                  <Tooltip
                    title={this.props.t(
                      'menuPlanner.plannerCardSummary.fat.percentage'
                    )}
                    placement="top"
                  >
                    <span>
                      (
                      {getTotalPercentage('fat', menuPlannerUtlis.constant.fat)}
                      %)
                    </span>
                  </Tooltip>
                  <Assumption
                    currentValue={parseFloat(
                      getTotalPercentage('fat', menuPlannerUtlis.constant.fat)
                    )}
                    expectedValue={assumptionOfFats.assumption}
                    margin={assumptionOfFats.margin}
                    macroName={'fat'}
                    dietName={this.props.dietName}
                    t={this.props.t}
                  />
                </span>
                <br />
                <span>
                  {this.props.t('menuPlanner.plannerCardSummary.price', {
                    value: getTotalMenuPlanners('price'),
                    currency: '',
                  })}
                </span>
                <br />
                <span>
                  {this.props.t('menuPlanner.plannerCardSummary.rate', {
                    value: getAverageRate(),
                  })}
                </span>
                <br />
              </DishInfo>
            </CardContent>
          </Card>
        )}
      </TableCell>
    );
  }
}

export default withTranslation()(PlannerBoxSummary);
