import React, { Component } from 'react';
import { combineStyles, isGranted } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import defaultState from './defaultState';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import Assignment from '@material-ui/icons/Assignment';
import FormLabel from '@material-ui/core/FormLabel';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import Card from 'components/Card/Card';
import CardIcon from 'components/Card/CardIcon';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ColorPicker from 'components/BlockColorPicker/BlockColorPicker';
import SelectInput from 'components/FormSelect/SelectInput';

import { get } from 'helpers/apiHelpers';
import { ROLE_CREATE_BRAND } from '../../helpers/roles';
import DataGrid from '../../components/DataGrid/DataGrid';
import columnConfig from '../Brands/columnConfig';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core/index';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { set as _set } from 'lodash';
import { ReactPhoneNumberAdapter } from 'components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { saveCompany } from 'actions/Company';

class MyCompany extends Component {
  state = {
    ...defaultState,
  };

  componentDidMount() {
    get(this.props.user.company).then(response => {
      const dishIngredientsDecimalPlaces =
        response.dietarySetup.dishIngredientsDecimalPlaces;

      this.setState({
        name: response.name,
        shortName: response.shortName,
        mainDomain: response.mainDomain,
        email: response.email,
        phone: response.phone,
        mainColor: response.mainColor,
        street: response.street,
        city: response.city,
        postCode: response.postCode,
        vatNumber: response.vatNumber,
        regon: response.regon,
        hotJarVersion: response.hotJar.version,
        hotJarId: response.hotJar.id,
        allowHotJar: response.hotJar.enabled,
        allowZohoSalesIQ: response.zohoSalesIQ.enabled,
        zohoSalesIQWidgetCode: response.zohoSalesIQ.widgetCode,
        defaultConfigFromBrand: response.defaultConfigFromBrand,
        dishIngredientsDecimalPlaces:
          dishIngredientsDecimalPlaces === 0
            ? this.props.t('common.lack')
            : dishIngredientsDecimalPlaces ?? 2,
        loadAllVariantsInitially: !!response.dietarySetup
          .loadAllVariantsInitially,
        alteredDietWorkName: response.dietarySetup.alteredDietWorkName,
      });
    });
  }

  newSize = () => this.props.history.push(`/admin/brands/add`);

  handleInputChange = event => {
    this.setState(state => _set(state, event.target.name, event.target.value));
  };

  handleColorPicker = color => {
    this.setState({
      mainColor: color.hex,
    });
  };

  validateEmail = () => {
    return this.state.email.includes('@');
  };

  validateForm = () => {
    return this.state.name;
  };

  handleSubmit = () => {
    if (!this.validateEmail()) {
      this.props.openToast({
        messages: [
          this.props.t(
            'myCompany.toast.incorrectEmail',
            'Nieprawidłowy adres email'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
      return false;
    }

    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [
          this.props.t(
            'myCompany.toast.complete',
            'Wypełnij wszystkie obowiązkowe pola oznaczone gwiazdką'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      name: this.state.name,
      shortName: this.state.shortName,
      mainDomain: this.state.mainDomain,
      email: this.state.email.toLowerCase(),
      phone: this.state.phone,
      mainColor: this.state.mainColor,
      street: this.state.street,
      city: this.state.city,
      postCode: this.state.postCode,
      vatNumber: this.state.vatNumber,
      regon: this.state.regon,
      defaultConfigFromBrand: this.state.defaultConfigFromBrand,
      hotJar: {
        version: this.state.hotJarVersion,
        id: this.state.hotJarId,
        enabled: this.state.allowHotJar,
      },
      zohoSalesIQ: {
        enabled: this.state.allowZohoSalesIQ,
        widgetCode: this.state.zohoSalesIQWidgetCode,
      },
      dietarySetup: {
        dishIngredientsDecimalPlaces:
          this.state.dishIngredientsDecimalPlaces ===
          this.props.t('common.lack')
            ? 0
            : this.state.dishIngredientsDecimalPlaces,
        loadAllVariantsInitially: this.state.loadAllVariantsInitially,
        alteredDietWorkName: this.state.alteredDietWorkName,
      },
    };

    return this.props.saveCompany(this.props.user.company, data).then(
      () =>
        this.props.openToast({
          messages: [
            this.props.t(
              'myCompany.toast.updated',
              'Zaktualizowano dane firmy'
            ),
          ],
          type: 'success',
          autoHideDuration: 3000,
        }),
      error => {
        if (error.response.data.violations[0].propertyPath === 'email') {
          this.props.openToast({
            messages: [`Email: ${error.response.data.violations[0].message}`],
            type: 'error',
            autoHideDuration: 3000,
          });
          return false;
        } else {
          this.props.openToast({
            messages: [
              'Coś poszło nie tak. Upewnij się, że formularz został poprawnie wypełniony', //myCompany.toast.wentWrong
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          return false;
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer style={{ marginTop: '15px' }}>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {this.props.t('myCompany.companyInfo')}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem md={6}>
                  <FormTextInput
                    label={this.props.t('myCompany.fullname') + '*'}
                    classes={classes}
                    name="name"
                    value={this.state.name}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                  <FormTextInput
                    label={this.props.t('myCompany.shortName')}
                    classes={classes}
                    name="shortName"
                    value={this.state.shortName}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                  <FormTextInput
                    label={this.props.t('myCompany.mainDomain')}
                    classes={classes}
                    name="mainDomain"
                    value={this.state.mainDomain}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                    maxLength={64}
                  />
                  <FormTextInput
                    label={this.props.t('myCompany.email')}
                    classes={classes}
                    name="email"
                    value={this.state.email}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />

                  <ReactPhoneNumberAdapter
                    label={this.props.t('myCompany.phone')}
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                  />

                  <FormLabel className={classes.labelHorizontal}>
                    <div style={{ display: 'flex' }}>
                      <span>{this.props.t('myCompany.defBrand')}</span>
                      <Tooltip
                        title={
                          <h4>{this.props.t('myCompany.defBrandTooltip')}</h4>
                        }
                        placement="right"
                      >
                        <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
                      </Tooltip>
                    </div>
                  </FormLabel>
                  <SelectInput
                    noGrid
                    // label={'Domyślna konfiguracja z marki *'}
                    classes={classes}
                    mapBy="name"
                    trackBy="@id"
                    value={this.state.defaultConfigFromBrand}
                    options={this.props.brands}
                    handleChange={(ev, obj) => {
                      this.setState({
                        defaultConfigFromBrand: obj['@id'],
                      });
                    }}
                    size={12}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '15px' }}
                    >
                      {this.props.t('myCompany.color')}
                    </FormLabel>
                    <div>
                      <ColorPicker
                        color={this.state.mainColor}
                        handleChange={this.handleColorPicker}
                      />
                    </div>
                  </div>
                </GridItem>
                <GridItem md={6}>
                  <FormTextInput
                    label={this.props.t('myCompany.street')}
                    classes={classes}
                    name="street"
                    value={this.state.street}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                  <FormTextInput
                    label={this.props.t('myCompany.city')}
                    classes={classes}
                    name="city"
                    value={this.state.city}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                  <NumberFormat
                    label={this.props.t('myCompany.postcode')}
                    classes={classes}
                    customInput={FormTextInput}
                    value={this.state.postCode}
                    format="##-###"
                    onChange={this.handleInputChange}
                    name="postCode"
                    inputSize={12}
                  />
                  <NumberFormat
                    label={this.props.t('myCompany.taxId')}
                    classes={classes}
                    customInput={FormTextInput}
                    value={this.state.vatNumber}
                    format="##########"
                    onChange={this.handleInputChange}
                    name="vatNumber"
                    inputSize={12}
                  />
                  <NumberFormat
                    label={this.props.t('myCompany.regon')}
                    classes={classes}
                    customInput={FormTextInput}
                    value={this.state.regon}
                    format="##########"
                    onChange={this.handleInputChange}
                    name="regon"
                    inputSize={12}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.allowHotJar}
                        onChange={e => {
                          this.setState({
                            allowHotJar: !this.state.allowHotJar,
                          });
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={this.props.t('myCompany.useHojtar')}
                  />
                  <FormTextInput
                    label="Hotjar Id"
                    classes={classes}
                    name="hotJarId"
                    value={this.state.hotJarId}
                    maxLength={64}
                    handleChange={this.handleInputChange}
                    inputSize={6}
                  />
                  <FormTextInput
                    label="Hotjar version"
                    classes={classes}
                    name="hotJarVersion"
                    value={this.state.hotJarVersion}
                    maxLength={64}
                    handleChange={this.handleInputChange}
                    inputSize={6}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.allowZohoSalesIQ}
                        onChange={e => {
                          this.setState({
                            allowZohoSalesIQ: !this.state.allowZohoSalesIQ,
                          });
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={this.props.t('myCompany.useZohoSalesIQ')}
                  />
                  <FormTextInput
                    label="Zoho SalesIQ widgetCode"
                    classes={classes}
                    name="zohoSalesIQWidgetCode"
                    value={this.state.zohoSalesIQWidgetCode}
                    maxLength={500}
                    handleChange={this.handleInputChange}
                    inputSize={9}
                  />
                </GridItem>
              </GridContainer>
              <FormControlButtons
                classes={classes}
                submitText={this.props.t('common.shared.save')}
                handleSubmit={this.handleSubmit}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <h4 className={classes.cardIconTitle}>
                {this.props.t('myCompany.brands')}
              </h4>
            </CardHeader>
            <GridItem md={12}>
              {isGranted(ROLE_CREATE_BRAND) && (
                <FormControlButtons
                  classes={classes}
                  handleSubmit={this.newSize}
                  submitText={this.props.t('myCompany.addBrand') + '+'}
                />
              )}
              <DataGrid
                actions={true}
                // editPath={`${window.location.pathname}/edit`}
                remove={true}
                export={true}
                paginationTop={true}
                paginationBottom={false}
                url="/brands"
                reportName={'brand'}
                columns={columnConfig}
                role="BRAND"
              />
            </GridItem>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <h4 className={classes.cardIconTitle}>
                {this.props.t('myCompany.dietConfiguration')}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem md={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('myCompany.dishIngredientsDecimalPlaces')}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    multiple={false}
                    classes={classes}
                    mapBy="value"
                    trackBy="value"
                    value={this.state.dishIngredientsDecimalPlaces}
                    options={Array.from(Array(10), (x, index) => ({
                      label: index,
                      value: index === 0 ? this.props.t('common.lack') : index,
                    }))}
                    handleChange={(e, { value }) => {
                      this.setState({
                        dishIngredientsDecimalPlaces:
                          value === 0 ? this.props.t('common.lack') : value,
                      });
                    }}
                    size={12}
                  />
                </GridItem>
                <GridItem md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.loadAllVariantsInitially}
                        onChange={e => {
                          this.setState({
                            loadAllVariantsInitially: !this.state
                              .loadAllVariantsInitially,
                          });
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={this.props.t(
                      'myCompany.menuPlanner.selectAllVariantsInitialy'
                    )}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={3}>
                  <FormTextInput
                    label={this.props.t('myCompany.alteredDietWorkName') + '*'}
                    classes={classes}
                    name="alteredDietWorkName"
                    value={this.state.alteredDietWorkName}
                    handleChange={this.handleInputChange}
                    inputSize={12}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <FormControlButtons
              classes={classes}
              submitText={this.props.t('common.shared.save')}
              handleSubmit={this.handleSubmit}
            />
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    user: state.Auth.user,
    brands: state.Auth.user.brands,
  };
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps, { saveCompany })
);

export default enhance(MyCompany);
