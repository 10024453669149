import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { trimEmptyStringsFromQuery } from '../../helpers/helpers';

class List extends Component {
  render() {
    return (
      <AdminTable title={' '} icon>
        <DataGrid
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/ecommerce-bags-details"
          reportName={'bagdetail'}
          columns={columnConfig(this.props.t)}
          role="BAG"
          minRows={20}
          defaultHiddenColumns={[
            'bag.clientDiet.isSubscription',
            'bag.clientDiet.order.id',
            'bag.deliveryTime',
            'bag.address.city',
            'bag.address.zone.name',
            'bag.clientDiet.diet.name',
            'bag.clientDiet.variant.name',
            'bag.clientDiet.calorific.name',
            'bag.clientDiet.optionChangeMenu',
            'bag.clientDiet.useEcoContainers',
          ]}
          defaultFiltered={[
            {
              id: 'bag.date',
              value: {
                after: new moment().format('YYYY-MM-DD'),
                before: new moment().add(7, 'days').format('YYYY-MM-DD'),
              },
            },
            {
              id: 'bag.clientDiet.active',
              value: true,
            },
            {
              id: 'bag.clientDiet.order.status.systemValue',
              value: 'PAID',
            },
          ]}
          manipulateQuery={(requestData, query) => {
            query._orX = [];

            if (query.user) {
              query._orX.push([
                {
                  'bag.clientDiet.client.firstName': query.user.split(' '),
                  'bag.clientDiet.client.lastName': query.user.split(' '),
                },
              ]);
              delete query.user;
            }
            if (query.hasOwnProperty('bag.date')) {
              const { after, before } = query['bag.date'];
              if (after) {
                query['bag.date[after]'] = after;
              }

              if (before) {
                query['bag.date[before]'] = before;
              }

              delete query['bag.date'];
            }

            if (query.hasOwnProperty('bag.deliveredAt')) {
              const { after, before } = query['bag.deliveredAt'];
              if (after) {
                query['bag.deliveredAt[after]'] = after;
              }

              if (before) {
                query['bag.deliveredAt[before]'] = before;
              }

              delete query['bag.deliveredAt'];
            }

            if (query.hasOwnProperty('address')) {
              const search = query['address'];
              delete query['address'];
              let splicedSearch = search.split(' ');
              splicedSearch = trimEmptyStringsFromQuery(splicedSearch);

              query._orX.push([
                {
                  'bag.address.city': splicedSearch,
                  'bag.address.street': splicedSearch,
                  'bag.address.postCode': splicedSearch,
                  'bag.address.buildNumber': splicedSearch,
                  'bag.address.placeNumberNumber': splicedSearch,
                  'bag.pickUpPoint.value': splicedSearch,
                },
              ]);

              delete query.address;
            }

            if (query.hasOwnProperty('realFoodCost')) {
              const filters = query.realFoodCost;
              delete query.realFoodCost;
              if (filters.valueFrom) {
                query['realFoodCost[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['realFoodCost[lte]'] = filters.valueTo;
              }
            }
            if (query.hasOwnProperty('bag.priceAfterDiscount')) {
              const filters = query['bag.priceAfterDiscount'];
              delete query['bag.priceAfterDiscount'];
              if (filters.valueFrom) {
                query['bag.priceAfterDiscount[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['bag.priceAfterDiscount[lte]'] = filters.valueTo;
              }
            }

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);
