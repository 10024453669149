import { useState, useEffect } from 'react';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import {
  HotJarModule,
  MyLeadModule,
  SalesManagoModule,
  FacebookPixelModule,
  PollsterTrackModule,
  ClickOnMetricsModule,
  GoogleAnalyticsModule,
  GoogleTagManagerModule,
} from './modules';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import React from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesNames, modulesDefaultState } from './consts';
import { DialogLoader } from '../../components/DialogLoader';

const AnalyticsTools = ({
  t,
  classes,
  openToast,
  selectedBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
}) => {
  const [modules, setModules] = useState(modulesDefaultState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules(prevState => ({
      ...prevState,
      [type]: module,
    }));
  };

  const handleSubmit = async () => {
    try {
      await updateBrandConfigModulesPack(selectedBrand, modules);

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      openToast({
        messages: [
          t('errors.modulesCouldNotBeSaved', 'Nie udało się zapisać modułów'),
          error.message,
        ],
        type: 'error',
        autoHideDuration: 300,
      });
      // this.props.errorHandlerCatch(error);
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />
      {!isLoading && modules && (
        <>
          <Card>
            <CardBody>
              <GoogleAnalyticsModule
                classes={classes}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.GoogleAnalytics]}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <GoogleTagManagerModule
                classes={classes}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.GoogleTagManager]}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <HotJarModule
                classes={classes}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.HotJar]}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <FacebookPixelModule
                classes={classes}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.FacebookPixel]}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <MyLeadModule
                classes={classes}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.MyLead]}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <SalesManagoModule
                classes={classes}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.SalesManago]}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <ClickOnMetricsModule
                classes={classes}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.ClickOnMetrics]}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <PollsterTrackModule
                classes={classes}
                handleModuleUpdate={handleModuleUpdate}
                module={modules[modulesNames.PollsterTrack]}
              />
            </CardBody>
          </Card>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrandConfigModulesPack, updateBrandConfigModulesPack }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(AnalyticsTools);
