import React, { Component, Fragment } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import { withToast } from 'material-ui-toast-redux';
import Button from 'components/CustomButtons/Button.jsx';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import Datetime from 'react-datetime';
import { get, post } from '../../helpers/apiHelpers';
import FormLabel from '@material-ui/core/FormLabel';
import FormTextInputNoGrid from '../../components/FormTextInput/FormTextInputNoGrid';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import AppDialogLoader from 'components/DialogLoader/AppDialogLoader';

const initialFilters = {
  marketingTerms: {
    trueValue: true,
    falseValue: true,
  },
  hasActiveDiets: {
    trueValue: true,
    falseValue: true,
  },
};

const MAX_MESSAGE_LENGTH = 670;

const filtersTranslations = t => ({
  marketingTerms: {
    trueText: t(
      'massSma.hasConsentFormMarketing',
      'Wyraził zgodę na marketing'
    ),
    falseText: t(
      'massSma.noConsentForMarketing',
      'Nie wyraził zgody na marketing'
    ),
  },
  hasActiveDiets: {
    trueText: t('massSma.hasActiveDiet', 'Ma aktywną dietę'),
    falseText: t('massSma.noActiveDiet', 'Nie ma aktywnej diety'),
  },
});

class Form extends Component {
  state = {
    content: '',
    processAt: new Date(),
    usersAmount: 0,
    isLoading: false,
    filters: {
      ...initialFilters,
    },
  };

  componentDidMount() {
    this.getCurrentFiltersUsersAmount(this.state);
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    return this.state.content && this.state.processAt;
  };

  checkAllFilters = () => {
    const newState = {
      ...this.state,
      filters: {
        ...initialFilters,
      },
    };

    this.setState(newState);
    this.getCurrentFiltersUsersAmount(newState);
  };

  handleFilters = (filter, valueModifier) => {
    const newState = {
      ...this.state,
      filters: {
        ...this.state.filters,
        [filter]: {
          ...this.state.filters[filter],
          [valueModifier]: !this.state.filters[filter][valueModifier],
        },
      },
    };
    this.setState(newState);
    this.getCurrentFiltersUsersAmount(newState);
  };

  getActiveFilters = filters => {
    const filterKeys = Object.keys(filters);
    const activeFilters = {};

    filterKeys.forEach(key => {
      if (filters[key].trueValue !== filters[key].falseValue) {
        activeFilters[key] = filters[key].trueValue;
      }
    });

    return activeFilters;
  };

  getCurrentFiltersUsersAmount = state => {
    this.setState({ isLoading: true });
    get('/clients', {
      pagination: false,
      itemsPerPage: 1,
      properties: ['_xxx'],
      'length_filter[gte][phone.number]': 9,
      'length_filter[lte][phone.number]': 12,
      ...this.getActiveFilters(state.filters),
    })
      .then(res => {
        this.setState({
          usersAmount: res['hydra:totalItems'],
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleSubmit = () => {
    const { t, history, openToast } = this.props;
    if (!this.validateForm()) {
      return openToast({
        messages: [t('sms.fillAllFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      processAt: this.state.processAt,
      content: this.state.content,
      filters: {
        ...this.getActiveFilters(this.state.filters),
        length_filter: {
          gte: { 'phone.number': 9 },
          lte: { 'phone.number': 12 },
        },
      },
    };

    post('/mass-sms', data).then(
      res => {
        history.push('/admin/mass-sms');
      },
      error =>
        openToast({
          messages: [t('sms.smsNotCreated')],
          type: 'error',
          autoHideDuration: 3000,
        })
    );
  };

  render() {
    const { classes, t } = this.props;
    return (
      <Fragment>
        {this.state.isLoading && (
          <AppDialogLoader
            text={t('common.loader', 'Trwa ładowanie...')} // common.loader
          />
        )}
        <form>
          <h2>{t('sms.creatingSms')}</h2>
          <AdminTable>
            <GridContainer>
              <GridItem md={6}>
                <GridItem sm={12}>
                  <p>{t('sms.smsTo')}</p>
                  <Button onClick={this.checkAllFilters}>{t('sms.all')}</Button>
                </GridItem>
                {Object.keys(initialFilters).map(filter => {
                  return (
                    <GridItem sm={12} key={filter}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.filters[filter].trueValue}
                            onChange={() =>
                              this.handleFilters(filter, 'trueValue')
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={filtersTranslations(t)[filter].trueText}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.filters[filter].falseValue}
                            onChange={() =>
                              this.handleFilters(filter, 'falseValue')
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={filtersTranslations(t)[filter].falseText}
                      />
                    </GridItem>
                  );
                })}
                <GridItem md={12}>
                  {t('sms.recCount')}: <b>{this.state.usersAmount}</b>
                </GridItem>
              </GridItem>
              <GridItem md={6}>
                <GridItem sm={12}>
                  <GridItem md={12}>
                    <p>{t('sms.msg')}</p>
                  </GridItem>
                  <GridItem md={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('sms.deliveryDate')} *
                    </FormLabel>
                    <Datetime
                      timeFormat={true}
                      closeOnSelect={true}
                      value={this.state.processAt}
                      onChange={ev =>
                        this.setState({
                          processAt: ev.format('DD.MM.YYYY HH:mm'),
                        })
                      }
                      isValidDate={day =>
                        day.isAfter(Datetime.moment().subtract(1, 'day'))
                      }
                      inputProps={{
                        placeholder: t('sms.setDate'),
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('sms.content')} *
                    </FormLabel>
                    <FormTextInputNoGrid
                      label={t('sms.content')}
                      classes={classes}
                      name="content"
                      value={this.state.content}
                      handleChange={this.handleChange}
                      maxLength={MAX_MESSAGE_LENGTH}
                      rows={2}
                      rowsMax={30}
                      multiline
                    />
                    <span>
                      {' '}
                      {t('sms.charsLeftToUse', 'Pozostała ilość znaków')}:{' '}
                      {MAX_MESSAGE_LENGTH - this.state.content.length}/
                      {MAX_MESSAGE_LENGTH}
                    </span>
                  </GridItem>
                </GridItem>
              </GridItem>
            </GridContainer>
            <FormControlButtons
              classes={classes}
              discardText={t('sms.cancel')}
              submitText={t('sms.addSms2')}
              cancelPath="/admin/mass-sms"
              handleSubmit={this.handleSubmit}
            />
          </AdminTable>
        </form>
      </Fragment>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(Form);
