import CardBody from '../Card/CardBody';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import FormTextInput from '../FormTextInput/FormTextInput';
import FormLabel from '@material-ui/core/FormLabel';
import FormImageUpload from '../FormImageUpload/FormImageUpload';
import Card from '../Card/Card';
import React from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import navPillsStyle from '../../assets/jss/material-dashboard-pro-react/components/navPillsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

const InfoBannerCard = ({
  classes,
  t,
  infoBannerTooltip,
  infoBannerCheckbox,
  bannerHeader,
  bannerText,
  handleInputChange,
  handleCheckboxChange,
  getImage,
  removeImage,
  infoBannerDesktopUrl,
  infoBannerMobileUrl,
}) => {
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            <h1>{t('cms.infoBanner')}</h1>
          </GridItem>

          <GridItem lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={handleCheckboxChange}
                  checked={infoBannerCheckbox}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={
                <div style={{ display: 'flex' }}>
                  {t('cms.showInfoBanner')}{' '}
                  {infoBannerTooltip && (
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{infoBannerTooltip}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  )}
                </div>
              }
            />

            <FormTextInput
              multiline
              rows={3}
              rowsMax={10}
              label={
                <div style={{ display: 'flex' }}>
                  <span>{t('brandCfg.captionInBanner')}</span>
                  <div
                    style={{
                      marginLeft: '10px',
                      display: 'inline-block',
                    }}
                  >
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('brandCfg.captionInBannerTooltip')}</h4>
                          <h4>
                            {t('brandCfg.etc')}{' '}
                            {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt nagłówka</div>`}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
              classes={classes}
              name="bannerHeader"
              value={bannerHeader}
              handleChange={handleInputChange}
              inputSize={12}
              maxLength={160}
            />

            <FormTextInput
              label={
                <div style={{ display: 'flex' }}>
                  <span>{t('brandCfg.textUnderCaption')}</span>
                  <div
                    style={{
                      marginLeft: '10px',
                      display: 'inline-block',
                    }}
                  >
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('brandCfg.textUnderCaptionTooltip')}</h4>
                          <h4>
                            {t('brandCfg.etc')}{' '}
                            {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt pod nagłówkiem</div>`}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
              classes={classes}
              name="bannerText"
              value={bannerText}
              handleChange={handleInputChange}
              inputSize={12}
              maxLength={1000}
              rows={3}
              rowsMax={20}
              multiline={true}
            />
          </GridItem>

          <GridItem lg={6}>
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  textAlign: 'center',
                }}
              >
                <div>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px', marginBottom: '24px' }}
                  >
                    {t('brandCfg.bannerPanorama')}
                  </FormLabel>
                  <FormImageUpload
                    classes={classes}
                    stateName="infoBannerDesktop"
                    buttonText={t('common.shared.select', 'Wybierz')}
                    imgId="desktop-banner"
                    tooltipImageSize={t('brandCfg.widthMinPanorama')}
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={infoBannerDesktopUrl ?? null}
                  />
                </div>

                <div>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginTop: '15px', marginBottom: '24px' }}
                  >
                    {t('brandCfg.bannerPortrait')}
                  </FormLabel>
                  <FormImageUpload
                    classes={classes}
                    stateName="infoBannerMobile"
                    buttonText={t('common.shared.select', 'Wybierz')}
                    imgId="mobile-banner"
                    tooltipImageSize={t('brandCfg.widthPortrait')}
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={infoBannerMobileUrl}
                  />
                </div>
              </div>
            </>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  navPillsStyle
);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(InfoBannerCard);
