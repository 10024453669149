import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_PAGE } from 'helpers/roles';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);

  render() {
    const { classes, t } = this.props;

    return (
      <AdminTable title={t('pages.list')} icon>
        {isGranted(ROLE_CREATE_PAGE) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newSize}
            submitText={t('pages.addPage') + '+'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/pages"
          reportName={'page'}
          columns={columnConfig}
          role="PAGE"
          minRows={20}
          defaultSorted={[{ id: 'position', desc: false }]}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);
