import React from 'react';
import roles from '../../helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import CombinedReport from './CombinedReport/CombinedReport';
import { Tooltip } from '@material-ui/core';

class DishCards extends React.Component {
  state = {
    includeSubscriptions: false,
  };

  resolveFileName = ({ parameters }) => {
    const title = this.props
      .t('common.mainMenu.reportsDishCards')
      .replace(' ', '-');

    if (parameters.length === 1) {
      const { multiplier, dateFrom, dateTo } = parameters[0];

      return `${multiplier}x${title}-${dateFrom}-${dateTo}_(${moment().format(
        'YYYY-MM-DD_HH.mm'
      )})`;
    }

    return `${title}_(${moment().format('YYYY-MM-DD_HH.mm')})`;
  };

  render() {
    const { t } = this.props;

    return (
      <CombinedReport
        title={t('common.mainMenu.reportsDishCards')}
        url="reports/dishes-card"
        mimeTypes={['application/vnd.ms-excel', 'application/pdf']}
        role={roles.ROLE_SHOW_REPORT_DISHES_CARD}
        fileName={this.resolveFileName}
        useDateRange={true}
        showMultiplier={true}
        validateBrands={false}
        validateDatePicker={true}
        useZoneCategories={true}
        reportConfiguration={{
          report: 'DishCardCompanyConfiguration',
          fields: [
            {
              size: 12,
              field: 'groupBy',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: 'ID',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.groupBy.id`
                    ),
                  },
                  {
                    value: 'CODE_V2',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.groupBy.codeV2`
                    ),
                  },
                ],
              },
            },
            {
              size: 12,
              field: 'orientation',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: 'Landscape',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.orientation.Landscape`
                    ),
                  },
                  {
                    value: 'Portrait',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.orientation.Portrait`
                    ),
                  },
                ],
              },
            },
            {
              size: 12,
              field: 'codeV1Version',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: ['V1_1', 'V1_2', 'V1_3'].map(option => ({
                  value: option,
                  label: this.props.t(
                    `configuration.reports.report.DishCardCompanyConfiguration.codeV1Version.${option}`
                  ),
                })),
              },
            },
            {
              size: 12,
              inputProps: {
                content: (
                  <Tooltip
                    style={{ cursor: 'help' }}
                    title={
                      <div>
                        <h4>
                          {this.props.t(
                            'configuration.reports.report.DishCardCompanyConfiguration.sortBy.help'
                          )}
                        </h4>
                      </div>
                    }
                    placement="bottom"
                  >
                    <span>
                      {this.props.t(
                        'configuration.reports.report.DishCardCompanyConfiguration.sortBy.title'
                      )}
                    </span>
                  </Tooltip>
                ),
              },
              inputType: 'divider',
            },
            {
              size: 4,
              field: 'sortByDietPosition',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [0, 100, 1000, 10000].map(option => ({
                  value: option,
                  label: option,
                })),
              },
            },
            {
              size: 4,
              field: 'sortByMealTypePosition',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [0, 100, 1000, 10000].map(option => ({
                  value: option,
                  label: option,
                })),
              },
            },
            {
              size: 4,
              field: 'sortByCodeV1',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [0, 100, 1000, 10000].map(option => ({
                  value: option,
                  label: option,
                })),
              },
            },
            {
              size: 12,
              inputProps: {
                content: (
                  <Tooltip
                    style={{ cursor: 'help' }}
                    title={
                      <div>
                        <h4>
                          {this.props.t(
                            'configuration.reports.report.DishCardCompanyConfiguration.keyBy.help'
                          )}
                        </h4>
                      </div>
                    }
                    placement="bottom"
                  >
                    <span>
                      {this.props.t(
                        'configuration.reports.report.DishCardCompanyConfiguration.keyBy.title'
                      )}
                    </span>
                  </Tooltip>
                ),
              },
              inputType: 'divider',
            },
            {
              size: 4,
              field: 'keyByDish',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'keyByDiet',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'keyByMealType',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'keyByClientChanged',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'keyByCodeV1',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'roundDecimalPoints',
              inputType: 'number',
            },
          ],
        }}
        archiveReportNames={['DISHES_CARD', 'DISH_CARDS_XLSX']}
      />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(DishCards);
