import React from 'react';
import Button from '@material-ui/core/Button';
import { combineStyles, isGranted } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import Moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import DataGrid from '../DataGrid/DataGrid';
import Filters from './Filters';
import DateFilter from '../Grid/Filter/Date';
import { connect } from 'react-redux';
import { ROLE_SHOW_REPORT_ARCHIVE } from 'helpers/roles';
import DownloadButton from './DownloadButton';

class Archive extends React.Component {
  state = {
    showArchive: this.props.opened === true,
  };

  toggleHistory = async () => {
    const showArchive = !this.state.showArchive;
    this.setState(prev => ({
      ...prev,
      showArchive,
    }));
  };

  render() {
    const { classes } = this.props;
    return this.state.loading ? (
      <CircularProgress />
    ) : (
      <>
        {this.props.opened !== true && (
          <>
            {this.state.showArchive && this.props.hideButtonHidden !== true && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={this.toggleHistory}>
                  {this.props.t('reportArchive.hideArchive')}
                </Button>
              </div>
            )}
            {!this.state.showArchive && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={this.toggleHistory}>
                  {this.props.t('reportArchive.showArchive')}
                </Button>
              </div>
            )}
          </>
        )}
        {(this.props.opened === true || this.state.showArchive) && (
          <DataGrid
            minRows={10}
            actions={true}
            remove={false}
            export={false}
            paginationTop={true}
            paginationBottom={false}
            url="export-archives"
            manipulateQuery={(requestData, query) => {
              query.type = this.props.type;
              query.reportName = this.props.reportName;

              return query;
            }}
            columns={[
              {
                title: 'reportArchive.userRaw',
                name: 'userRaw',
                accessor: 'userRaw',
                filterable: true,
                sortable: false,
              },
              {
                title: 'reportArchive.generatedAt',
                name: 'generatedAt',
                accessor: row => Moment(row.generatedAt).format('llll'),
                filterable: true,
                sortable: false,
                Filter: ({ onChange, filter }) => {
                  return (
                    <DateFilter onChange={onChange} value={filter?.value} />
                  );
                },
              },
              {
                title: 'reportArchive.filters',
                name: 'filters',
                accessor: row => <Filters filters={row.filters} />,
                filterable: false,
                sortable: false,
                width: 70,
              },
              {
                title: 'reportArchive.download',
                name: 'download',
                accessor: row => (
                  <DownloadButton
                    row={row}
                    accessGranted={
                      row?.user?.id ===
                        parseInt(this.props.user.id.split('/').pop()) ||
                      isGranted(ROLE_SHOW_REPORT_ARCHIVE)
                    }
                  />
                ),
                filterable: false,
                sortable: false,
                width: 70,
              },
            ]}
          />
        )}
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    user: state.Auth.user,
  };
};

const mapDispatchToProps = {};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(combinedStyles)(Archive))
);
