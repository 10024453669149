import React from 'react';
import { compose } from 'recompose';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
// API Connection
// @material-ui/core components
import TagsInput from 'react-tagsinput';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import Button from 'components/CustomButtons/Button.jsx';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import FormLabel from '@material-ui/core/FormLabel';
import ReactSelect from 'components/ReactSelect/ReactSelect';
import SelectInput from 'components/FormSelect/SelectInput';

import TypeBlock from './TypeBlockComponents/TypeBlock';
import FormSelectDropdown from 'components/FormSelect/FormSelectDropdown';
import DishIngredients from './DishIngredients';
import { Divider } from '@material-ui/core';
import LogView from '../../components/History/LogView';
import { Link } from 'react-router-dom';
import { DialogLoader } from '../../components/DialogLoader';
import commentsColumnConfig from './commentsColumnConfig';
import menuPlannerColumnConfig from './menuPlannerColumnConfig';
import DataGrid from '../../components/DataGrid/DataGrid';
import AdvancedMealFormComponent from './AdvancedMealFormComponent';

import {
  isGranted,
  sortByPosition,
  sortByGroupedBrandPosition,
  combineStyles,
} from '../../helpers/helpers';
import roles from '../../helpers/roles';
import { withTranslation } from 'react-i18next';
import defaultImageForClient from 'assets/img/dishPhotoForClientTemplate.jpg';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Notes from 'components/Notes/Notes';
import BasicInfoCard from '../../components/CMS/BasicInfoCard';
import DescriptionCard from '../../components/CMS/DescriptionCard';

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

class MealForm extends React.Component {
  state = {
    showNotes: false,
    showDishSubpage: false,
  };

  toggleNotesPage = bool => {
    this.setState({ showNotes: bool });
  };

  toggleDishSubpage = bool => {
    this.setState({ showDishSubpage: bool });
  };

  sortByDate = (collection) => {
    return [...collection].sort(
      (a, b) => new Date(a['createdAt']) - new Date(b['createdAt'])
    );
  };

  render() {
    const {
      classes,
      t,
      urlSlug,
      metaTitle,
      metaDescription,
      dishDescription,
      friendlyName,
      handleCmsInputChange,
    } = this.props;

    const groupedTypes = this.props.dish.types.reduce(
      (acc, { brand, ...itemWithoutBrand }) => {
        const { '@id': brandId } = brand;

        return {
          ...acc,
          [brandId]: {
            ...brand,
            types:
              acc[brandId] === undefined
                ? [itemWithoutBrand]
                : [...acc[brandId].types, itemWithoutBrand],
          },
        };
      },
      {}
    );

    const selectedBrandMealTypes = Object.values(groupedTypes).reduce(
      (acc, { types, name }) => {
        const typesWithBrand = types.map(type => ({
          ...type,
          brandName: name,
        }));

        return [...acc, ...typesWithBrand];
      },
      []
    );

    if (this.state.showNotes) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <h2 style={this.props.isEditing && { display: 'block' }}>
              {this.props.isEditing ? t('form.editMeal') : t('form.createMeal')}
              <div>
                <Button
                  onClick={() => {
                    this.toggleNotesPage(false);
                    this.toggleDishSubpage(false);
                    this.props.changeCreationFlow(false);
                  }}
                >
                  {t('common.regular', 'Regular')}
                </Button>
                {isGranted(roles.ROLE_SHOW_SYSTEM_ADVANCED_DISH) ? (
                  <Button
                    onClick={() => {
                      this.toggleNotesPage(false);
                      this.toggleDishSubpage(false);
                      this.props.changeCreationFlow(true);
                    }}
                  >
                    {t('common.advanced', 'Advanced')}
                  </Button>
                ) : null}
                {isGranted(roles.ROLE_NOTE_DISH) ||
                isGranted(roles.ROLE_SHOW_NOTE) ? (
                  <Button
                    color="info"
                    onClick={() => {
                      this.toggleNotesPage(true);
                      this.toggleDishSubpage(false);
                    }}
                  >
                    {t('common.notes')}
                  </Button>
                ) : null}
                <Button
                  onClick={() => {
                    this.toggleNotesPage(false);
                    this.toggleDishSubpage(true);
                  }}
                >
                  Podstrona dania
                </Button>
              </div>
            </h2>

            <Card>
              <CardBody>
                <Notes
                  urlParam={`/dishes/${this.props.dish.id}`}
                  notePermission={roles.ROLE_NOTE_DISH}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    }

    if (this.state.showDishSubpage) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <h2 style={this.props.isEditing && { display: 'block' }}>
              {this.props.isEditing
                ? this.props.t('form.editMeal')
                : this.props.t('form.createMeal')}
              <div>
                <Button
                  onClick={() => {
                    this.toggleNotesPage(false);
                    this.toggleDishSubpage(false);
                    this.props.changeCreationFlow(false);
                  }}
                >
                  {this.props.t('common.regular', 'Regular')}
                </Button>
                {isGranted(roles.ROLE_SHOW_SYSTEM_ADVANCED_DISH) ? (
                  <Button
                    onClick={() => {
                      this.toggleNotesPage(false);
                      this.toggleDishSubpage(false);
                      this.props.changeCreationFlow(true);
                    }}
                  >
                    {this.props.t('common.advanced', 'Advanced')}
                  </Button>
                ) : null}
                {isGranted(roles.ROLE_NOTE_DISH) ||
                isGranted(roles.ROLE_SHOW_NOTE) ? (
                  <Button
                    onClick={() => {
                      this.toggleNotesPage(true);
                      this.toggleDishSubpage(false);
                    }}
                  >
                    {this.props.t('common.notes')}
                  </Button>
                ) : null}
                <Button
                  color="info"
                  onClick={() => {
                    this.toggleNotesPage(false);
                    this.toggleDishSubpage(true);
                  }}
                >
                  Podstrona dania
                </Button>
              </div>
            </h2>

            <BasicInfoCard
              urlSlug={urlSlug}
              metaTitle={metaTitle}
              metaDescription={metaDescription}
              friendlyName={friendlyName}
              handleChange={handleCmsInputChange}
            />

            <DescriptionCard
              value={dishDescription}
              setValue={value =>
                handleCmsInputChange({
                  target: { name: 'dishDescription', value },
                })
              }
              title={this.props.t('views.addons.addonSubpage.addonDescription')}
            />

            {!this.props.isLoading && (
              <FormControlButtons
                classes={classes}
                discardText={t('form.cancel')}
                submitText={t('form.saveAndClose')}
                cancelPath="/admin/meals"
                secondarySubmitText={t('form.save')}
                handleSubmit={e => this.props.handleSubmit(e, true)}
                handleSecondarySubmit={e => this.props.handleSubmit(e, false)}
              />
            )}
          </GridItem>
        </GridContainer>
      );
    }

    if (this.props.isAdvancedCreation)
      return (
        <AdvancedMealFormComponent
          {...this.props}
          toggleNotesPage={this.toggleNotesPage}
          toggleDishSubpage={this.toggleDishSubpage}
        />
      );

    const isMealTypesDisabled = this.props.dish.brands.length === 0;

    return (
      <div>
        <DialogLoader
          loaderState={this.props.isSubmitting}
          text={t('form.savingChanges', 'Zapisywanie zmian...')}
        />
        <DialogLoader
          loaderState={this.props.isLoading}
          text={t('common.shared.isLoading', 'Wczytywanie dania...')}
        />
        <GridContainer>
          <GridItem xs={12}>
            <h2 style={this.props.isEditing && { display: 'block' }}>
              {this.props.isEditing ? t('form.editMeal') : t('form.createMeal')}

              <div>
                <Button
                  onClick={() => this.props.changeCreationFlow(false)}
                  color="info"
                >
                  {t('common.regular', 'Regular')}
                </Button>
                {isGranted(roles.ROLE_SHOW_SYSTEM_ADVANCED_DISH) ? (
                  <Button onClick={() => this.props.changeCreationFlow(true)}>
                    {t('common.advanced', 'Advanced')}
                  </Button>
                ) : null}
                {isGranted(roles.ROLE_NOTE_DISH) ||
                isGranted(roles.ROLE_SHOW_NOTE) ? (
                  <Button onClick={() => this.toggleNotesPage(true)}>
                    {this.props.t('common.notes')}
                  </Button>
                ) : null}
                <Button onClick={() => this.toggleDishSubpage(true)}>
                  Podstrona dania
                </Button>
              </div>

              {this.props.isEditing && (
                <Link
                  to={`/admin/meals/add/?savedMeal&selectedBrand=${this.props.selectedBrand}`}
                  target={'_blank'}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.props.saveToLs}
                  >
                    {t('form.meals.copyToNewMeal', 'Skopiuj do nowego dania')}
                  </Button>
                </Link>
              )}
            </h2>
            <Card>
              <CardBody>
                <form>
                  <GridContainer justify="center">
                    <GridItem md={12}>
                      <GridContainer>
                        <GridItem md={6}>
                          <h3>{t('form.basicInfo')}</h3>
                          <Divider />
                          <GridContainer>
                            <GridItem md={12}>
                              <FormLabel style={{ fontSize: '14px' }}>
                                {t('form.field.defaultName') + '*'}
                              </FormLabel>
                              <FormTextInput
                                noGrid
                                classes={classes}
                                name="nameForClient"
                                value={this.props.dish.nameForClient}
                                handleChange={this.props.handleChange}
                                maxLength={160}
                              />
                            </GridItem>
                            <GridItem md={12}>
                              <FormLabel style={{ fontSize: '14px' }}>
                                {t('form.field.workingName') + '*'}
                              </FormLabel>
                              <FormTextInput
                                noGrid
                                classes={classes}
                                name="workingName"
                                value={this.props.dish.workingName}
                                handleChange={this.props.handleChange}
                                maxLength={160}
                              />
                            </GridItem>
                            <GridItem md={12}>
                              <FormLabel style={{ fontSize: '14px' }}>
                                {this.props.t('form.field.description')}
                              </FormLabel>
                              <FormTextInput
                                noGrid
                                classes={classes}
                                name="description"
                                value={this.props.dish.description}
                                handleChange={this.props.handleChange}
                                multiline={true}
                                rows={1}
                                rowsMax={10}
                                maxLength={500}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem md={6}>
                              <FormLabel className={classes.labelHorizontal}>
                                {t('form.field.brands') + ' *'}
                              </FormLabel>
                              <SelectInput
                                multiple
                                noGrid
                                classes={classes}
                                mapBy="name"
                                trackBy="@id"
                                options={this.props.brands}
                                value={this.props.dish.brands}
                                handleChange={this.props.handleBrand}
                                name="brands"
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem
                              md={6}
                              onClick={() =>
                                isMealTypesDisabled &&
                                this.props.showValidationAlert(
                                  t('mealTypes.select.warning')
                                )
                              }
                            >
                              <FormLabel
                                disabled={isMealTypesDisabled}
                                className={classes.labelHorizontal}
                              >
                                {t('form.field.mealTypes') + '*'}
                              </FormLabel>
                              <SelectInput
                                multiple
                                noGrid
                                classes={classes}
                                mapBy="name"
                                trackBy="@id"
                                disabled={isMealTypesDisabled}
                                // disabledMenuItems={
                                //   this.props.isEditing &&
                                //   this.props.disabledTypes
                                // }
                                value={this.props.dish.types}
                                customLabel={option =>
                                  option.brand && option.brand.name
                                    ? `${option.name} - ${option.brand.name}`
                                    : option.name
                                }
                                options={sortByGroupedBrandPosition(
                                  this.props.mealTypes
                                )}
                                handleChange={(ev, obj) =>
                                  this.props.handleMealType(ev, obj)
                                }
                                name="types"
                              />
                            </GridItem>
                            <GridItem md={6}>
                              <FormLabel className={classes.labelHorizontal}>
                                {t('form.field.servingProposal')}
                              </FormLabel>
                              <SelectInput
                                noGrid
                                classes={classes}
                                options={this.props.servingSuggestions}
                                value={this.props.dish.servingSuggestion}
                                name="servingSuggestion"
                                mapBy="value"
                                trackBy="@id"
                                handleChange={this.props.handleChange}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem md={6}>
                              <FormLabel className={classes.labelHorizontal}>
                                {t('form.field.tagsForProduction')}
                              </FormLabel>
                              <ReactSelect
                                selectedValues={this.props.dish.tags}
                                options={this.props.tags}
                                handleChange={this.props.handleTagChange}
                              />
                            </GridItem>
                            <GridItem md={6}>
                              <FormLabel className={classes.labelHorizontal}>
                                {t('form.field.tagsForClient')}
                              </FormLabel>
                              <SelectInput
                                multiple
                                noGrid
                                classes={classes}
                                label={t('form.field.tagsForClient')}
                                options={this.props.clientTags}
                                value={this.props.dish.clientTags}
                                name="clientTags"
                                mapBy="value"
                                trackBy="@id"
                                handleChange={this.props.handleChange}
                              />
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem md={6}>
                              <FormTextInput
                                noGrid
                                label={t('form.field.preparationInstruction')}
                                classes={classes}
                                name="preparingInstructions"
                                value={this.props.dish.preparingInstructions}
                                handleChange={this.props.handleChange}
                                inputSize={12}
                                multiline={true}
                                rows={1}
                                rowsMax={20}
                                maxLength={5000}
                              />
                            </GridItem>
                            <GridItem md={6}>
                              <FormTextInput
                                noGrid
                                label={t('form.field.notes')}
                                classes={classes}
                                name="note"
                                value={this.props.dish.note}
                                handleChange={this.props.handleChange}
                                inputSize={12}
                                multiline={true}
                                rows={1}
                                rowsMax={20}
                                maxLength={5000}
                              />
                            </GridItem>
                            <GridItem md={6}>
                              <FormControlLabel
                                label={t(
                                  'mealForm.forceDisableInShop',
                                  '$$Wyłącz danie z oferty sklepu'
                                )}
                                control={
                                  <Checkbox
                                    checked={this.props.forceDisableInShop}
                                    onChange={() =>
                                      this.props.handleChangeForceDisableInShop(
                                        !this.props.forceDisableInShop
                                      )
                                    }
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem md={6}>
                          <h3>{t('form.field.photos')}</h3>
                          <Divider />
                          <GridContainer>
                            <GridItem md={6}>
                              <FormImageUpload
                                label={t('form.field.photoForProduction')}
                                classes={classes}
                                tooltip={t('form.field.photoSizes')}
                                stateName={'productionImage'}
                                getImage={this.props.getImage}
                                removeImage={this.props.removeImage}
                                previewUrl={this.props.productionImageUrl}
                              />
                            </GridItem>
                            <GridItem md={6}>
                              <FormImageUpload
                                label={t('form.field.photoForClient')}
                                classes={classes}
                                tooltip={t('form.field.photoForClientTooltip')}
                                stateName={'clientImage'}
                                getImage={this.props.getImage}
                                removeImage={this.props.removeImage}
                                defaultImage={defaultImageForClient}
                                previewUrl={this.props.clientImageUrl}
                              />
                            </GridItem>
                          </GridContainer>
                          {this.props.isEditing && (
                            <div>
                              <h3>{t('form.field.additionalInfo')}</h3>
                              <Divider />
                              <GridContainer>
                                <GridItem md={4}>
                                  <FormLabel style={{ fontSize: '14px' }}>
                                    {t('form.field.avgRate')}
                                  </FormLabel>
                                  <h4>
                                    <b>
                                      {this.props.dish.rateAVG
                                        ? this.props.dish.rateAVG.toFixed(2)
                                        : '0'}
                                    </b>{' '}
                                    {t('form.field.basedOn')}{' '}
                                    {this.props.dish.rateCount || 0}{' '}
                                    {t('form.field.rates')}
                                  </h4>
                                  {this.props.getStarRate(
                                    this.props.dish.rateAVG
                                  )}
                                </GridItem>
                              </GridContainer>
                            </div>
                          )}
                        </GridItem>
                        {isGranted(roles.ROLE_SHOW_MENU_PLANNING) &&
                          this.props.isEditing && (
                            <GridItem md={6}>
                              <h3>
                                {t('form.field.dishAppearedOnMenuPlanning')}
                              </h3>
                              <Divider />
                              {this.props.isEditing &&
                                (this.props?.dish?.brands ?? []).length > 0 && (
                                  <GridContainer>
                                    <GridItem md={12}>
                                      <DataGrid
                                        disableExport
                                        disableTotal
                                        filterable={false}
                                        sortable={false}
                                        paginationTop={true}
                                        paginationBottom={false}
                                        url="/menu-planners"
                                        reportName={'menu-planners'}
                                        columns={menuPlannerColumnConfig}
                                        role="MENU_PLANNER"
                                        minRows={5}
                                        customDefaultPageSize={10}
                                        showPageSizeOptions={false}
                                        manipulateQuery={(
                                          requestData,
                                          query
                                        ) => {
                                          query['draft'] = false;
                                          query[
                                            'dish'
                                          ] = this.props.match.params.id;
                                          query[
                                            'selectInBrands'
                                          ] = this.props.dish.brands.map(
                                            brand => brand['@id']
                                          );

                                          query['properties'] = [
                                            'date',
                                            'dish',
                                            'brand',
                                            'variant',
                                          ];
                                          query['properties[diet]'] = ['name'];
                                          query['properties[brand]'] = ['name'];
                                          query['properties[variant]'] = ['name'];

                                          return query;
                                        }}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                )}
                            </GridItem>
                          )}
                        {isGranted(roles.ROLE_SHOW_RATE) &&
                          this.props.isEditing &&
                          (this.props?.dish?.brands ?? []).length > 0 && (
                            <GridItem md={6}>
                              <h3>
                                {t('common.heading.comments', 'Komentarze')}
                              </h3>
                              <Divider />
                              <GridContainer>
                                <GridItem md={12}>
                                  {this.props.dish.brands.length && (
                                    <DataGrid
                                      disableExport
                                      disableTotal
                                      filterable={false}
                                      sortable={false}
                                      paginationTop={true}
                                      paginationBottom={false}
                                      url="/rates"
                                      reportName={'rate'}
                                      columns={commentsColumnConfig}
                                      role="RATE"
                                      minRows={5}
                                      customDefaultPageSize={5}
                                      showPageSizeOptions={false}
                                      manipulateQuery={(requestData, query) => {
                                        query._orX = [];

                                        query['length_filter[gt][comment]'] = 0;
                                        query[
                                          'dishItem.dish.id'
                                        ] = this.props.match.params.id;
                                        query[
                                          'selectInBrands'
                                        ] = this.props.dish.brands.map(
                                          brand => brand['@id']
                                        );

                                        return query;
                                      }}
                                    />
                                  )}
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          )}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </form>
                <h3>{t('form.field.dishIngredients')}</h3>
                <Divider />
                <GridContainer>
                  <GridItem md={3}>
                    <FormSelectDropdown
                      filterName="workName"
                      placeholder={t('form.field.addIngredient')}
                      handleClick={this.props.addMeal}
                      filter={this.props.getFilteredIngredients}
                    />
                    <FormSelectDropdown
                      filterName="workName"
                      placeholder={t('form.field.addRecipe')}
                      handleClick={this.props.addMeal}
                      filter={this.props.getFilteredRecipes}
                    />
                    <div style={{ marginBottom: '5px' }}>
                      <FormLabel style={{ fontSize: '14px' }}>
                        {t('form.field.allergens')}
                      </FormLabel>
                    </div>
                    <TagsInput
                      tagProps={{
                        className:
                          'react-tagsinput-tag react-tagsinput-tag-small react-tagsinput-tag-disabled danger',
                      }}
                      inputProps={{ placeholder: '' }}
                      disabled
                      value={[...new Set(this.props.allergens)]}
                    />
                  </GridItem>
                  <GridItem md={9}>
                    <p style={{ color: '#ff991f', 'text-align': 'right' }}>
                      {t('form.field.100gramInfo')}
                    </p>
                    <DishIngredients
                      data={this.props.selectedIngredients}
                      history={this.props.history}
                      handleRemove={this.props.handleRemove}
                      openToast={this.props.openToast}
                      classes={classes}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>

            {Object.values(groupedTypes).map(({ name, types }) => (
              <Card>
                <CardBody>
                  {!types.length ? (
                    <GridContainer>
                      <GridItem md={12}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '20px',
                          }}
                        >
                          <h1>
                            {t(
                              'mealTypes.chooseMealType',
                              'Wybierz typy posiłków, aby konfigurować dania'
                            )}
                          </h1>
                        </div>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <Card>
                      <CardBody>
                        <h1
                          style={{
                            margin: '-10px 0 0 -20px',
                            display: 'inline-block',
                            padding: '5px 10px',
                            lineHeight: 'normal',
                            color: '#ffffff',
                            background: '#9c27b0',
                            borderRadius: '0 5px 5px 0',
                          }}
                        >
                          <b>{name.toUpperCase()}</b>
                        </h1>
                        <GridContainer>
                          <GridItem md={12}>
                            {sortByPosition(types).map((type, typeKey) => (
                              <TypeBlock
                                classes={classes}
                                key={typeKey}
                                type={type}
                                dish={this.props.dish}
                                dishSizes={this.props.dish.sizes}
                                selectedIngredients={
                                  this.props.selectedIngredients
                                }
                                mealTypeIngredientsSum={
                                  this.props.mealTypeIngredientsSum
                                }
                                onChange={this.props.handleQuantityChange}
                                containers={this.sortByDate(this.props.containers)}
                                ecoContainers={this.sortByDate(this.props.ecoContainers)}
                                handleContainerChange={
                                  this.props.handleContainerChange
                                }
                                handleEcoContainerChange={
                                  this.props.handleEcoContainerChange
                                }
                                handlePriceChange={this.props.handlePriceChange}
                                handleSizeShopAvailabilityChange={
                                  this.props.handleSizeShopAvailabilityChange
                                }
                                handleSizeNameChange={
                                  this.props.handleSizeNameChange
                                }
                                scaleDishSize={this.props.scaleDishSize}
                                setShouldRescale={this.props.setShouldRescale}
                                shouldRescale={this.props.shouldRescale}
                                checkedDishToResize={
                                  this.props.checkedDishToResize
                                }
                                setDishToResize={this.props.setDishToResize}
                                selectedBrandMealTypes={selectedBrandMealTypes}
                                handleCopySizeChange={
                                  this.props.handleCopySizeChange
                                }
                                handleCopySize={this.props.handleCopySize}
                                handleSizeShopAvailabilityChangeRow={
                                  this.props.handleSizeShopAvailabilityChangeRow
                                }
                              />
                            ))}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  )}
                </CardBody>
              </Card>
            ))}
          </GridItem>
        </GridContainer>
        {!this.props.isLoading && (
          <FormControlButtons
            classes={classes}
            discardText={t('form.cancel')}
            submitText={t('form.saveAndClose')}
            cancelPath="/admin/meals"
            secondarySubmitText={t('form.save')}
            handleSubmit={e => this.props.handleSubmit(e, true)}
            handleSecondarySubmit={e => this.props.handleSubmit(e, false)}
          />
        )}
        <LogView iri={`/dishes/${this.props.match.params.id}`} />
      </div>
    );
  }
}

export default compose(withTranslation(), withStyles(combinedStyles))(MealForm);
