import React from 'react';
import Card from 'components/Card/Card.jsx';
import Notes from 'components/Notes/Notes';
import CardBody from 'components/Card/CardBody.jsx';

import roles from 'helpers/roles';

const NotesRecipeForm = ({ recipeId }) => {
  return (
    <Card>
      <CardBody>
        <Notes
          urlParam={`/recipes/${recipeId}`}
          notePermission={roles.ROLE_SHOW_NOTE}
        />
      </CardBody>
    </Card>
  );
};

export default NotesRecipeForm;
