import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Card from '../../components/Card/Card';
import React, { useEffect, useState } from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import navPillsStyle from '../../assets/jss/material-dashboard-pro-react/components/navPillsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import InfoBannerCard from '../../components/CMS/InfoBannerCard';
import { get, put } from '../../helpers/apiHelpers';
import { connect } from 'react-redux';
import { DialogLoader } from '../../components/DialogLoader';

const DietsCmsPage = ({ classes, t, selectedBrand }) => {
  const [enabled, setEnabled] = useState(false);
  const [showComponents, setShowComponents] = useState(false);
  const [showAllergens, setShowAllergens] = useState(false);
  const [showNutritionalValues, setShowNutritionalValues] = useState(false);
  const [showGlycemicIndex, setShowGlycemicIndex] = useState(false);
  const [showClientTags, setShowClientTags] = useState(false);
  const [showAvgRate, setShowAvgRate] = useState(false);
  const [showOccurrenceInDiets, setShowOccurrenceInDiets] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const [showBanner, setShowBanner] = useState(false);
  const [bannerTitle, setBannerTitle] = useState('');
  const [bannerSubTitle, setBannerSubTitle] = useState('');
  const [bannerDesktop, setBannerDesktop] = useState(null);
  const [bannerMobile, setBannerMobile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    (async () => {
      const {
        configuration: { SeoDish },
      } = await get(`brands/${selectedBrand}/modules?module[]=SeoDish`);

      const bannerDesktop = SeoDish?.bannerDesktop
        ? SeoDish['@resources'][SeoDish?.bannerDesktop]
        : null;

      const bannerMobile = SeoDish.bannerMobile
        ? SeoDish['@resources'][SeoDish.bannerMobile]
        : null;

      setEnabled(SeoDish.enabled);
      setShowComponents(SeoDish.showComponents);
      setShowAllergens(SeoDish.showAllergens);
      setShowNutritionalValues(SeoDish.showNutritionalValues);
      setShowGlycemicIndex(SeoDish.showGlycemicIndex);
      setShowClientTags(SeoDish.showClientTags);
      setShowAvgRate(SeoDish.showAvgRate);
      setShowOccurrenceInDiets(SeoDish.showOccurrenceInDiets);
      setShowComments(SeoDish.showComments);

      setShowBanner(SeoDish?.showBanner);
      setBannerTitle(SeoDish?.bannerTitle);
      setBannerSubTitle(SeoDish.bannerSubTitle);
      setBannerDesktop(bannerDesktop);
      setBannerMobile(bannerMobile);
    })();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await put(`brands/${selectedBrand}/modules`, {
        module: 'SeoDish',
        configuration: {
          SeoDish: {
            enabled,
            showBanner,
            showAvgRate,
            bannerTitle,
            showComments,
            bannerMobile: bannerMobile?.['@id'],
            showAllergens,
            bannerDesktop: bannerDesktop?.['@id'],
            bannerSubTitle,
            showComponents,
            showClientTags,
            showGlycemicIndex,
            showNutritionalValues,
            showOccurrenceInDiets,
          },
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getImage = (name, data) => {
    if (name === 'infoBannerDesktop') {
      setBannerDesktop(data);
    }

    if (name === 'infoBannerMobile') {
      setBannerMobile(data);
    }
  };

  const removeImage = (name, data) => {
    if (name === 'infoBannerDesktop') {
      setBannerDesktop(null);
    }

    if (name === 'infoBannerMobile') {
      setBannerMobile(null);
    }
  };

  const handleBannerTextChange = ({ target }) => {
    if (target?.name === 'bannerHeader') {
      setBannerTitle(target?.value);
    }

    if (target?.name === 'bannerText') {
      setBannerSubTitle(target?.value);
    }
  };

  return (
    <>
      <DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('form.basicInfo')}</h1>

              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setEnabled(!enabled)}
                    checked={enabled}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showMealsSubpage')}{' '}
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{t('cms.diets.showDietSubpage.tooltip')}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  </div>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <h1>{t('cms.zones.infoVisibility')}</h1>
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowComponents(!showComponents)}
                    checked={showComponents}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showDishComposition')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowAllergens(!showAllergens)}
                    checked={showAllergens}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showAllergens')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setShowNutritionalValues(!showNutritionalValues)
                    }
                    checked={showNutritionalValues}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showNutritionalValues')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowGlycemicIndex(!showGlycemicIndex)}
                    checked={showGlycemicIndex}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showGlycemicIndex')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowClientTags(!showClientTags)}
                    checked={showClientTags}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showTags')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowAvgRate(!showAvgRate)}
                    checked={showAvgRate}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showAverageRating')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => setShowComments(!showComments)}
                    checked={showComments}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showComments')}
                  </div>
                }
              />
            </GridItem>

            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() =>
                      setShowOccurrenceInDiets(!showOccurrenceInDiets)
                    }
                    checked={showOccurrenceInDiets}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t('cms.meals.showTimetable')}
                  </div>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <InfoBannerCard
        getImage={getImage}
        bannerText={bannerSubTitle}
        removeImage={removeImage}
        bannerHeader={bannerTitle}
        handleInputChange={handleBannerTextChange}
        infoBannerTooltip={t('cms.showInfoBanner.tooltip.addons')}
        infoBannerCheckbox={showBanner}
        infoBannerMobileUrl={bannerMobile?.contentUrl}
        infoBannerDesktopUrl={bannerDesktop?.contentUrl}
        handleCheckboxChange={() => setShowBanner(!showBanner)}
      />

      <FormControlButtons
        classes={classes}
        handleSubmit={handleSubmit}
        submitText={t('form.save')}
      />
    </>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  navPillsStyle
);

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  }))
);

export default enhance(DietsCmsPage);
