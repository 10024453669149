import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import defaultState from './defaultState';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { fetchMealType } from 'actions/MealTypes';

import { fetchMealSizes } from 'actions/MealSizes';
import { openSweetToast } from 'actions/App';

import { post, put } from 'helpers/apiHelpers';

import { sortByKey } from 'helpers/helpers';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import LogView from 'components/History/LogView';

import CheckSizes from './CheckSizes';

import { withTranslation } from 'react-i18next';

class Form extends Component {
  state = {
    sizes: [],
    ...defaultState,
  };

  typeId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount() {
    this.props
      .fetchMealSizes()
      .then(response => {
        this.setState({
          sizes: sortByKey(response, 'position'),
        });
      })
      .then(() => {
        this.isEdit && this.loadSizes();
      });
  }

  loadSizes = () => {
    this.props.fetchMealType(this.typeId).then(type => {
      if (this.state.sizes.length === 0) {
        return;
      }
      const copySizeFromState = [...this.state.sizes];

      type.sizes.forEach(element => {
        const indexToChange = copySizeFromState.findIndex(
          el => el['@id'] === element.size['@id']
        );

        copySizeFromState[indexToChange].calories = element.calories;
        copySizeFromState[indexToChange].checked = true;
        copySizeFromState[indexToChange].disabled = true;
        copySizeFromState[indexToChange]['mealTypeSize'] = element['@id'];
      });

      this.setState({
        name: type.name,
        code: type.code,
        position: type.position,
        sizes: copySizeFromState,
      });
    });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleToggle = (index, disabled) => {
    const { sizes } = this.state;

    if (disabled) {
      return this.props.openToast({
        messages: [this.props.t('form.cannotTurnOff')],
        type: 'info',
        autoHideDuration: 3000,
      });
    }

    this.setState({
      sizes: update(sizes, {
        [index]: {
          checked: { $set: !sizes[index].checked },
          calories: {
            $set:
              this.state.sizes[index].checked === false
                ? sizes[index].calories
                : '',
          },
        },
      }),
    });
  };

  handleCalories = (index, event) => {
    const { sizes } = this.state;
    this.setState({
      sizes: update(sizes, {
        [index]: {
          calories: { $set: event.target.value },
        },
      }),
    });
  };

  validateForm = () => {
    return (
      this.state.name && this.state.code !== '' && this.state.position !== ''
    );
  };

  validateCalories = () => {
    if (!this.state.sizes.some(size => size.checked)) {
      return true;
    }
    return this.state.sizes.some(size => size.checked && !size.calories);
  };

  handleSubmit = async () => {
    if (this.validateCalories()) {
      return this.props.openToast({
        messages: [this.props.t('form.fillInAllCalories')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      name: this.state.name,
      position: parseInt(this.state.position),
      code: this.state.code,
      sizes: this.state.sizes
        .reduce((result, element) => {
          if (element.checked === true) {
            result.push(element);
          }
          return result;
        }, [])
        .map(el => {
          let tmp = {
            size: el['@id'],
            calories: parseInt(el.calories),
          };

          if (el.mealTypeSize) {
            tmp['@id'] = el.mealTypeSize;
          }

          return tmp;
        }),
    };

    try {
      this.isEdit
        ? await put(`/meal-types/${this.typeId}`, data)
        : await post('/meal-types', data);

      this.setState({
        errors: {},
      });
      if (this.isEdit) {
        this.props.openSweetToast({
          style: { display: 'block' },
          content: (
            <div style={{ textAlign: 'left' }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.t('mealTypes.save.info1'),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.t('mealTypes.save.info2'),
                }}
              />
              <b
                dangerouslySetInnerHTML={{
                  __html: this.props.t('mealTypes.save.info3'),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.t('mealTypes.save.info4'),
                }}
              />
              <b
                dangerouslySetInnerHTML={{
                  __html: this.props.t('mealTypes.save.info5'),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.t('mealTypes.save.info6'),
                }}
              />
            </div>
          ),
          showCancel: false,
        });
      }
      this.props.history.push('/admin/types');
    } catch ({ response: { data } }) {
      if (data.hasOwnProperty('violations')) {
        const tempErrors =
          data.violations.reduce(
            (acc, { message, propertyPath }) => ({
              ...acc,
              [propertyPath]: message,
            }),
            {}
          ) || {};

        this.setState({
          errors: tempErrors,
        });
      } else {
        return this.props.openToast({
          messages: [
            this.props.t('form.somethingWentWrong'),
            this.props.t('form.tryAgainLater'),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <AdminTable
          title={
            this.isEdit
              ? this.props.t('form.editMealType')
              : this.props.t('form.addNewMealType')
          }
          icon
        >
          <GridContainer>
            <GridItem sm={4}>
              <FormTextInput
                label={this.props.t('form.field.typeName') + '*'}
                classes={classes}
                name="name"
                value={this.state.name}
                handleChange={this.handleInputChange}
                inputSize={12}
              />
            </GridItem>
            <GridItem sm={2} style={{ marginTop: '8px' }}>
              <FormTextInput
                label={this.props.t('form.field.order') + '*'}
                classes={classes}
                name="position"
                value={this.state.position}
                type="number"
                handleChange={this.handleInputChange}
                inputSize={12}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem sm={4}>
              <FormTextInput
                label={this.props.t('form.field.code') + '*'}
                classes={classes}
                name="code"
                value={this.state.code}
                handleChange={this.handleInputChange}
                errors={this.state.errors}
              />
            </GridItem>
          </GridContainer>
          <CheckSizes
            classes={classes}
            handleToggle={this.handleToggle}
            handleInputChange={this.handleInputChange}
            handleCalories={this.handleCalories}
            sizes={this.state.sizes}
          />
          <FormControlButtons
            classes={classes}
            discardText={this.props.t('form.cancel')}
            submitText={this.props.t('form.save')}
            cancelPath="/admin/types"
            handleSubmit={this.handleSubmit}
          />
        </AdminTable>
        {this.isEdit && <LogView iri={`/meal-types/${this.typeId}`} />}
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    mealSizes: state.MealSizes.mealSizes,
    mealType: state.MealTypes.mealType,
  };
};

const mapDispatchToProps = {
  fetchMealSizes,
  fetchMealType,
  openSweetToast,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withToast(withStyles(combinedStyles)(Form)))
);
