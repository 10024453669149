import React from 'react';
import roles from 'helpers/roles';
import moment from 'moment';
import BrandDateSubbrandsReport from './BrandDateSubbrandsReport';

import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class IncomingSubscriptions extends React.Component {
  getFileName = ({ dateFrom, dateTo }) => {
    const title = this.props
      .t('common.mainMenu.reportsIncomingSubscriptions')
      .replace(' ', '-');

    return `${title}-${dateFrom}-${dateTo}_(${moment().format(
      'YYYY-MM-DD_HH.mm'
    )}).xlsx`;
  };

  render() {
    const { t } = this.props;
    const title = t('reports.reportIncomingSubscriptions');

    return (
      <BrandDateSubbrandsReport
        title={title}
        url="reports/incoming-subscription"
        mimeType="application/vnd.ms-excel"
        fileName={this.getFileName}
        role={roles.ROLE_SHOW_REPORT_INCOMING_SUBSCRIPTION}
        useDateRange={true}
        useSubBrands={false}
        showMultiplier={false}
        archiveReportNames={['INCOMING_SUBSCRIPTION']}
      />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(IncomingSubscriptions);
