import Moment from 'moment';
import React from 'react';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import BooleanView from '../../components/DataGrid/ValueViews/BooleanView';
import YesNoFilter from '../../components/Grid/Filter/YesNo';
import OrderStatusCell from '../../components/Grid/Cells/OrderStatusCell';
import { translateOrderStatuses } from '../Orders/Orders/statusTranslations';
import { Tooltip } from '@material-ui/core';
import NumberRangeFilter from '../../components/Grid/Filter/NumberRange';

const createColumn = (accessor, width = undefined) => ({
  title: 'ecommerceBags.fields.' + accessor,
  accessor,
  name: accessor,
  width,
  filterable: true,
  sortable: true,
});

const createNumberColumn = (accessor, width = undefined) => ({
  title: 'ecommerceBags.fields.' + accessor,
  accessor,
  name: accessor,
  width,
  filterable: true,
  sortable: true,
  Filter: ({ onChange, filter }) => {
    return (
      <NumberRangeFilter onFilterChange={onChange} value={filter?.value} />
    );
  },
});

const createBooleanColumn = (field, accessor, width = undefined) => ({
  title: 'ecommerceBags.fields.' + field,
  accessor: row => <BooleanView value={accessor(row)} />,
  name: field,
  width,
  Filter: ({ onChange, filter }) => (
    <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
  ),
  filterable: true,
  sortable: false,
});

const columnConfig = t => [
  createColumn('id', 70),
  {
    title: 'ecommerceBags.fields.bag.clientDiet.id',
    name: 'bag.clientDiet.id',
    width: 70,
    filterable: true,
    sortable: true,
    accessor: prop => (
      <a
        target="_blank"
        rel="noreferrer"
        href={`/admin/ecommerce-diets/preview/${prop.bag.clientDiet?.id}`}
      >
        {prop.bag.clientDiet?.id}
      </a>
    ),
  },
  createColumn('bag.clientDiet.order.id', 70),
  createBooleanColumn(
    'bag.clientDiet.isSubscription',
    row => row.bag.clientDiet?.isSubscription,
    70
  ),
  createBooleanColumn(
    'bag.clientDiet.active',
    row => row.bag.clientDiet?.active,
    70
  ),
  {
    title: 'ecommerceBags.fields.bag.clientDiet.order.status',
    accessor: row => (
      <OrderStatusCell status={row.bag.clientDiet?.order?.status} />
    ),
    filterable: true,
    sortable: false,
    width: 170,
    name: 'bag.clientDiet.order.status.systemValue',
    Filter: ({ onChange, filter }) => {
      console.log(filter);
      const selectOptions = translateOrderStatuses(t);
      return (
        <select
          style={{ height: '30px', width: '100%', padding: '0' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value={''}>{selectOptions[0].label}</option>
          <option value={selectOptions[1].systemValue}>
            {selectOptions[1].label}
          </option>
          <option value={selectOptions[2].systemValue}>
            {selectOptions[2].label}
          </option>
          <option value={selectOptions[3].systemValue}>
            {selectOptions[3].label}
          </option>
        </select>
      );
    },
  },
  {
    title: 'ecommerceBags.fields.bag.date',
    accessor: row => Moment(row.bag.date).format('YYYY-MM-DD'),
    name: 'bag.date',
    Filter: ({ onChange, filter }) => {
      return <DateRangeFilter onChange={onChange} value={filter?.value} />;
    },
    width: 90,
    filterable: true,
    sortable: true,
  },
  {
    title: 'ecommerceBags.fields.bag.deliveryTime',
    accessor: row =>
      row.bag.deliveredAt ? Moment(row.bag.deliveredAt).format('llll') : null,
    name: 'bag.deliveredAt',
    Filter: ({ onChange, filter }) => {
      return <DateRangeFilter onChange={onChange} value={filter?.value} />;
    },
    filterable: true,
    sortable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.deliveryPhoto',
    accessor: row =>
      row.bag.deliveryPhoto ? (
        <img
          src={row.bag.deliveryPhoto}
          style={{ width: '100%', maxWidth: '150px' }}
        />
      ) : null,
    name: 'bag.deliveryPhoto',
    filterable: false,
    sortable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.clientDiet.client',
    accessor: ({
      bag: {
        clientDiet: {
          client: { id, firstName, lastName },
        },
      },
    }) => {
      return (
        <a target="_blank" rel="noreferrer" href={`/admin/clients/edit/${id}`}>
          {firstName} {lastName}
        </a>
      );
    },
    name: 'user',
    width: 200,
    filterable: true,
    sortable: false,
  },
  {
    title: 'ecommerceBags.fields.bag.clientDiet.client.email',
    accessor: prop => (
      <a href={`mailto:${prop.bag.clientDiet?.client?.email}`}>
        {prop.bag.clientDiet?.client?.email}
      </a>
    ),
    width: 200,
    name: 'bag.clientDiet.client.email',
    filterable: true,
    sortable: true,
  },
  {
    title: 'ecommerceBags.fields.bag.clientDiet.client.phone.number',
    accessor: prop => (
      <a href={`tel:${prop.bag.clientDiet?.client?.phone?.number}`}>
        {prop.bag.clientDiet?.client?.phone?.number}
      </a>
    ),
    width: 110,
    name: 'bag.clientDiet.client.phone.number',
  },
  createColumn('bag.address.city'),
  {
    title: 'ecommerceBags.fields.bag.address',
    accessor: ({ bag }) => {
      return bag.address
        ? `${bag.address.city} ${bag.address.postCode}, ${bag.address.street} ${bag.address.buildNumber}`
        : bag.pickUpPoint
        ? bag.pickUpPoint.value
        : '-';
    },
    width: 200,
    name: 'address',
    sortable: true,
    filterable: true,
  },
  createColumn('bag.address.zone.name'),
  createNumberColumn('realFoodCost'),
  {
    ...createNumberColumn('bag.priceAfterDiscount'),
    accessor: row => (
      <div style={{ textAlign: 'right', paddingRight: 8 }}>
        <Tooltip
          interactive
          title={
            <div>
              <h5>{t('ecommerceBags.fields.bag.price.diet')}</h5>
              <span style={{ fontSize: '12px' }}>
                {t('ecommerceBags.fields.bag.price.before')}:{' '}
                {(row.bag.priceBeforeDiscount ?? 0).toFixed(2)}
                <br />
                {t('ecommerceBags.fields.bag.price.discount')}:{' '}
                {(row.bag.priceDiscount ?? 0).toFixed(2)}
                <br />
                {t('ecommerceBags.fields.bag.price.after')}:{' '}
                {(row.bag.priceAfterDiscount ?? 0).toFixed(2)}
              </span>
              <hr />
              <h5>{t('ecommerceBags.fields.bag.price.delivery')}</h5>
              <span style={{ fontSize: '12px' }}>
                {t('ecommerceBags.fields.bag.price.before')}:{' '}
                {(row.bag.priceDeliveryBeforeDiscount ?? 0).toFixed(2)}
                <br />
                {t('ecommerceBags.fields.bag.price.discount')}:{' '}
                {(row.bag.priceDeliveryDiscount ?? 0).toFixed(2)}
                <br />
                {t('ecommerceBags.fields.bag.price.after')}:{' '}
                {(row.bag.priceDeliveryAfterDiscount ?? 0).toFixed(2)}
              </span>
              <hr />
              <h5>{t('ecommerceBags.fields.bag.price.addon')}</h5>
              <span style={{ fontSize: '12px' }}>
                {t('ecommerceBags.fields.bag.price.before')}:{' '}
                {(row.bag.priceAddonsBeforeDiscount ?? 0).toFixed(2)}
                <br />
                {t('ecommerceBags.fields.bag.price.discount')}:{' '}
                {(row.bag.priceAddonsDiscount ?? 0).toFixed(2)}
                <br />
                {t('ecommerceBags.fields.bag.price.after')}:{' '}
                {(row.bag.priceAddonsAfterDiscount ?? 0).toFixed(2)}
              </span>
              <hr />
              <h5>{t('ecommerceBags.fields.bag.price.ecoContainer')}</h5>
              <span style={{ fontSize: '12px' }}>
                {t('ecommerceBags.fields.bag.price.before')}:{' '}
                {(row.bag.ecoContainerPrice ?? 0).toFixed(2)}
                <br />
              </span>
            </div>
          }
          placement={'right'}
        >
          <span style={{ cursor: 'help' }}>
            {(row.bag.priceAfterDiscount ?? 0).toFixed(2)}
          </span>
        </Tooltip>
      </div>
    ),
  },
  createColumn('bag.diet.name'),
  createColumn('bag.clientDiet.diet.name'),
  createColumn('bag.variant.name'),
  createColumn('bag.clientDiet.variant.name'),
  createColumn('bag.calorific.name'),
  createColumn('bag.clientDiet.calorific.name'),
  createBooleanColumn(
    'bag.optionChangeMenu',
    row => row.bag.optionChangeMenu,
    70
  ),
  createBooleanColumn(
    'bag.clientDiet.optionChangeMenu',
    row => row.bag.clientDiet?.optionChangeMenu,
    70
  ),
  createBooleanColumn(
    'bag.useEcoContainers',
    row => row.bag.useEcoContainers,
    70
  ),
  createBooleanColumn(
    'bag.clientDiet.useEcoContainers',
    row => row.bag.clientDiet?.useEcoContainers,
    70
  ),
  createBooleanColumn('clientChanged', row => row.clientChanged, 70),
];

export default columnConfig;
