import React, { Component } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import roles from 'helpers/roles';
import { combineStyles, isGranted } from 'helpers/helpers';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchUserAddresses, removeAddress } from 'actions/Addresses';
import translateColumnConfig from './columnConfig';

import TableActions from 'components/DataTable/Actions';

import Form from './Form';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import SweetAlert from 'react-bootstrap-sweetalert';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ModalButton from '../../../../components/CustomButtons/Button';

class Addresses extends Component {
  state = {
    alert: null,
    alertOpen: false,
    openedModal: false,
    addresses: [],
    editAddress: [],
    key: 1,
  };

  userId = this.props.userId ? this.props.userId : this.props.match.params.id;

  componentDidMount() {
    this.props.fetchUserAddresses(this.userId);
  }

  hideAlert = () => {
    this.setState({
      alert: null,
      alertOpen: false,
    });
  };

  notAllowedModal = () => {
    this.setState({
      alertOpen: true,
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={this.props.t('clients.deleteDefaultAddressWarn')}
          onConfirm={this.hideAlert}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          confirmBtnText="OK"
        />
      ),
    });
  };

  confirmModal = id => {
    this.setState({
      alertOpen: true,
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={this.props.t('clients.areYouSure')}
          onConfirm={async () => {
            await this.props.removeAddress(id);
            this.props.fetchUserAddresses(this.userId);
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText={this.props.t('common.shared.yes')}
          cancelBtnText={this.props.t('common.shared.no')}
          showCancel
        />
      ),
    });
  };

  closeModal = () => {
    this.setState({
      openedModal: false,
      editAddress: [],
    });
  };

  newAddress = () => {
    this.setState({
      openedModal: true,
    });
  };

  handleEdit = id => {
    this.setState({
      openedModal: true,
      editAddress: this.props.addresses.filter(address => id === address.id),
    });
  };

  reRenderModal = id => {
    this.handleEdit(id);
    this.setState({ key: id });
  };

  renderDataTable = () =>
    this.props.addresses.map(address => {
      const [addressLine1 = '', addressLine2 = ''] = address?.street?.split(
        '; '
      );

      return {
        id: address.id,
        city: address.city,
        ...(this.props.useAddressesWithLessFields
          ? { addressLine1, addressLine2 }
          : {
              street: address.street,
              buildNumber: address.buildNumber,
              placeNumber: address.placeNumber,
            }),

        postCode: address.postCode,
        actions: this.renderTableActions(address),
      };
    });

  renderTableActions = address => {
    return (
      <TableActions
        handleEdit={
          isGranted(roles.ROLE_EDIT_ADDRESS)
            ? () => this.handleEdit(address.id)
            : false
        }
        handleRemove={
          isGranted(roles.ROLE_REMOVE_ADDRESS) && !address.containsActiveDiets
            ? address.isDefault
              ? this.notAllowedModal
              : () => this.confirmModal(address.id)
            : false
        }
      />
    );
  };

  render() {
    const { t, useAddressesWithLessFields } = this.props;
    const columnConfig = translateColumnConfig(t, useAddressesWithLessFields);

    if (
      (isGranted(roles.ROLE_EDIT_ADDRESS) ||
        isGranted(roles.ROLE_REMOVE_ADDRESS)) &&
      columnConfig[columnConfig.length - 1].accessor !== 'actions'
    ) {
      columnConfig.push({
        Header: this.props.t('dataGrid.actions'),
        accessor: 'actions',
        width: 100,
        filterable: false,
      });
    }
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Dialog fullWidth={true} open={this.state.alertOpen}>
          <div>{this.state.alert}</div>
        </Dialog>
        {isGranted(roles.ROLE_CREATE_ADDRESS) && (
          <FormControlButtons
            classes={classes}
            cancelPath={this.props.noCancel ? '' : '/admin/clients'}
            discardText={this.props.t('dataGrid.backToList')}
            handleSubmit={this.newAddress}
            submitText={this.props.t('dataGrid.addAddress')}
          />
        )}
        <ReactTable
          data={this.renderDataTable()}
          filterable={false}
          sortable={false}
          resizable={false}
          columns={columnConfig}
          minRows={10}
          showPaginationTop={true}
          showPaginationBottom={false}
          defaultPageSize={10}
          className="-striped -highlight"
          previousText={this.props.t('dataGrid.prevPage')}
          nextText={this.props.t('dataGrid.nextPage')}
          loadingText={this.props.t('dataGrid.loading')}
          noDataText={this.props.t('dataGrid.notFound')}
          pageText={this.props.t('dataGrid.page')}
          ofText={this.props.t('dataGrid.pageOf')}
          rowsText={this.props.t('dataGrid.results')}
        />
        <Dialog
          maxWidth={'xl'}
          fullWidth={true}
          open={this.state.openedModal}
          onClose={this.closeModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeModal}
            >
              &times;
            </ModalButton>
            <Form
              key={this.state.key}
              userId={this.userId ? this.userId : null}
              closeModal={this.closeModal}
              editAddress={this.state.editAddress}
              reRenderModal={this.reRenderModal}
            />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const combinedStyles = combineStyles(
  sweetAlertStyle,
  extendedFormsStyle,
  buttonsStyle
);

const mapStateToProps = state => ({
  addresses: state.Addresses.addresses,
  useAddressesWithLessFields:
    state.Brands.modules?.ConfigClientPanel?.useAddressesWithLessFields ??
    false,
});

const mapDispatchToProps = dispatch => ({
  fetchUserAddresses: id => dispatch(fetchUserAddresses(id)),
  removeAddress: id => dispatch(removeAddress(id)),
});

const enhance = compose(
  withStyles(combinedStyles),
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Addresses);
