import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_ROLE } from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);
  render() {
    const { classes, t } = this.props;

    return (
      <AdminTable title={t('myCompany.rolesList')} icon>
        {isGranted(ROLE_CREATE_ROLE) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newSize}
            submitText={t('myCompany.addRole') + '+'}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/roles"
          reportName={'role'}
          columns={columnConfig}
          role="ROLE"
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);
