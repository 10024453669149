import GridItem from '../../../components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import React, { useEffect } from 'react';

import useCheckIsPaymentDisabled from 'helpers/useCheckIsPaymentDisabled';
import { useTranslation } from 'react-i18next';

const PayPoModule = ({
  classes,
  payPoCheck,
  payPoId,
  payPoToken,
  handleInputChange,
  handleToggle,
}) => {
  const { t } = useTranslation();
  const paypo = useCheckIsPaymentDisabled('PAYPO');

  useEffect(() => {
    if (paypo.isSelected && !payPoCheck) {
      handleToggle('payPoCheck');
    }
  }, [paypo.isSelected]);

  return (
    <>
      <GridItem md={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() => handleToggle('payPoCheck')}
            checked={payPoCheck}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            disabled={paypo.isSelected}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          <span>PayPo</span>
          {paypo.isSelected && (
            <div style={{ marginLeft: '10px' }}>
              <Tooltip
                title={
                  <h4>
                    {t(
                      `brandCfg.payments.disabled.${paypo.paymentName}.tooltip`
                    )}
                  </h4>
                }
                placement="right"
              >
                <Info
                  fontSize="small"
                  style={{ color: 'grey', display: 'block' }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="PayPo ID"
          classes={classes}
          name="payPoId"
          value={!payPoCheck ? '' : payPoId}
          disabled={!payPoCheck}
          handleChange={handleInputChange}
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label={t('brandCfg.securityCode')}
          classes={classes}
          name="payPoToken"
          value={!payPoCheck ? '' : payPoToken}
          disabled={!payPoCheck}
          handleChange={handleInputChange}
          inputSize={12}
        />
      </GridItem>
    </>
  );
};

export default PayPoModule;
