import { useState, useEffect } from 'react';

import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SelectInput from 'components/FormSelect/SelectInput';
import { Divider } from '@material-ui/core';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import modulesNames from './modules/configurationConst';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';
import { put } from '../../helpers/apiHelpers';
import { DialogLoader } from '../../components/DialogLoader';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import AvailableSoonOverlay from './components/availableSoonOverlay';

// const selectOptions = [
//   { id: 1, value: 'możliwa zmiana po wykupieniu premium' },
//   { id: 2, value: 'możliwa zmiana wg cen diet' },
// ];

const premiumTypeOptions = t => [
  {
    value: 'DISABLED',
    label: t('brands.newBrandForm.premiumTypeOptions.disabled', 'Wyłączona'),
  },
  {
    value: 'STANDARD',
    label: t(
      'brands.newBrandForm.premiumTypeOptions.standard',
      'Możliwa zmiana według cen diet'
    ),
  },
  {
    value: 'PREMIUM',
    label: t(
      'brands.newBrandForm.premiumTypeOptions.premium',
      'Możliwa zmiana po wykupieniu premium'
    ),
  },
];

const MenuSelectionAssistant = ({
  selectedBrand,
  t,
  classes,
  updateBrandConfigModulesPack,
  fetchBrand,
  fetchBrandConfigModulesPack,
  openToast,
}) => {
  const [state, setState] = useState({
    premiumAdditionalStep: false,
    premiumType: 'DISABLED',
    clientCanUseAssistant: false,
  });
  const [modules, setModules] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      setState(prevState => ({
        ...prevState,
        premiumAdditionalStep: brandConfig.premiumAdditionalStep,
        premiumType: brandConfig.premiumType,
      }));
      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      await put(`/brands/${selectedBrand}`, state);
      await updateBrandConfigModulesPack(selectedBrand, modules);

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 300,
      });
    }
  };

  const handleCheckboxChange = name => {
    setState(prevState => ({ ...prevState, [name]: !prevState[name] }));
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <h1>
                    {t(
                      'brandCfg.menuSelectionAssistant.menuAndAdditionalDishes',
                      'Wybór menu i dodatkowych dań'
                    )}
                  </h1>
                </GridItem>

                <GridItem md={6}>
                  <SelectInput
                    classes={classes}
                    label={t(
                      'brands.newBrandForm.menuChangesAllowed',
                      'Możliwość zmiany menu'
                    )}
                    mapBy="label"
                    trackBy="value"
                    value={state.premiumType}
                    options={premiumTypeOptions(t)}
                    handleChange={e =>
                      setState(prevState => ({
                        ...prevState,
                        premiumType: e.target.value,
                      }))
                    }
                  />

                  {state.premiumType === 'PREMIUM' && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.premiumAdditionalStep}
                            onChange={() =>
                              handleCheckboxChange('premiumAdditionalStep')
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={t(
                          'brandCfg.menuSelectionAssistant.showSeparateStepForOrderPath',
                          'Wyświetl oddzielny krok ścieżki zamówienia - Wybór menu'
                        )}
                      />

                      <h4>
                        {t(
                          'module.configClientPanel.chooseMenuDescription.label',
                          'Tekst na kroku "Wybór menu"'
                        )}
                      </h4>
                      <Divider style={{ marginBottom: '20px' }} />

                      <FormTextInput
                        multiline
                        classes={classes}
                        name="chooseMenuDescription"
                        value={
                          modules?.ConfigClientPanel?.chooseMenuDescription
                        }
                        maxLength={5000}
                        rows={10}
                        handleChange={e =>
                          setModules(prevState => ({
                            ...prevState,
                            ConfigClientPanel: {
                              ...prevState.ConfigClientPanel,
                              chooseMenuDescription: e.target.value,
                            },
                          }))
                        }
                        inputSize={12}
                      />
                    </>
                  )}
                </GridItem>

                <GridItem md={6}>
                  {/*<FormControlLabel
                    control={
                      <Checkbox
                        checked={state.clientCanOrderAdditionalMeals}
                        onChange={() =>
                          handleCheckboxChange('clientCanOrderAdditionalMeals')
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.menuSelectionAssistant.clientCanOrderAdditionalMeals',
                      'Klient ma możliwość zamówienia dodatkowych posiłków'
                    )}
                  />*/}

                  {/*<FormControlLabel
                    control={
                      <Checkbox
                        checked={state.clientCanFilterMeals}
                        onChange={() =>
                          handleCheckboxChange('clientCanFilterMeals')
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t(
                      'brandCfg.menuSelectionAssistant.clientCanFilterMeals',
                      'Klient ma możliwość filtrowania dań podczas wyboru menu i/lub dodatkowych posiłków'
                    )}
                  />*/}

                  <AvailableSoonOverlay>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.clientCanUseAssistant}
                          onChange={() =>
                            handleCheckboxChange('clientCanUseAssistant')
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={t(
                        'brandCfg.menuSelectionAssistant.clientCanUseAssistant',
                        'Klient ma możliwość korzystania z inteligentnego asystenta wyboru menu'
                      )}
                    />
                  </AvailableSoonOverlay>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          {/* TODO: create assistant component */}
          {/*<Card>
            <CardBody>Asystent Kasia</CardBody>
          </Card>*/}

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrand, fetchBrandConfigModulesPack, updateBrandConfigModulesPack }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(MenuSelectionAssistant);
