import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';

import { default as baseColumnConfig } from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_ORDER, ROLE_SHOW_BASKET } from 'helpers/roles';
import InfoModal from 'components/InfoModal/InfoModal';

import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import BasketDetails from "../../Baskets/Dialogs/BasketDetails";

class List extends Component {
  grid = React.createRef();

  state = {
    isModalOpened: false,
    modalContent: '',
    isUseOrderParam: false,
  };

  newOrder = () => this.props.history.push('/admin/orders/add');

  readQuery = () => {
    if (!this.props.location) {
      return null;
    }
    const query = new URLSearchParams(this.props.location.search);

    return query.get('orderId');
  };

  customActions = row => {
    const { t } = this.props;
    const actions = [];

    if (isGranted(ROLE_SHOW_BASKET)) {
      actions.push({
        action: () => {},
        icon: row.basket ? <BasketDetails basketId={row?.basket.split('/').pop()} /> : false,
        disabled: row.basket === null,
        color: row.basket ? 'primary' : 'tumblr',
        simple: true,
        round: false,
        style: { width: undefined }
      });
    }

    actions.push({
      action: props => window.open(props.row.invoicePDF, '_blank'),
      icon: row.invoicePDF ? <VerticalAlignBottom/> : false,
      disabled: row.invoicePDF === null,
      color: row.invoicePDF ? 'primary' : 'tumblr',
      buttonText:
        row.invoicePDF !== null
          ? t('orders.downloadInvoice', 'Pobierz fakturę')
          : t('orders.noInvoice', 'Brak faktury'),
      simple: true,
      round: false,
      style: {width: undefined}
    });

    return actions;
  };

  render() {
    const {
      classes,
      t,
      columnConfig,
      showAddButton = true,
      customActions,
      customActionsWidth = 250,
    } = this.props;

    const columnsConfigurator = columnConfig || baseColumnConfig;
    const customActionsBuilder = customActions || this.customActions;

    return (
      <AdminTable title={t('orders.orderList')} icon>
        {showAddButton && isGranted(ROLE_CREATE_ORDER) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={() => this.newOrder()}
            submitText={t('orders.newOrder') + '+'}
          />
        )}
        <InfoModal
          modalState={this.state.isModalOpened}
          modalContent={this.state.modalContent}
          closeButtonText={t('clients.close', 'Zamknij')}
          diets={this.props.diets}
          variants={this.props.variants}
          handleClose={isModalOpened => this.setState({ isModalOpened })}
        />
        <DataGrid
          ref={this.grid}
          refreshOnFetch
          actions={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          minRows={20}
          url="/orders"
          reportName={'order'}
          role="ORDER"
          columns={columnsConfigurator(
            this.grid,
            modalContent =>
              this.setState({ modalContent, isModalOpened: true }),
            this.props.t
          )}
          defaultHiddenColumns={['paymentNeedVerification']}
          customActions={customActionsBuilder}
          customActionsWidth={customActionsWidth}
          manipulateQuery={(requestData, query) => {
            if (this.props.defaultQuery) {
              query = { ...query, ...this.props.defaultQuery };
            }
            if (query['client.firstName']) {
              const splicedName = query['client.firstName'].split(' ');
              let _andX = [
                [
                  {
                    'client.firstName': splicedName[0],
                    'client.lastName': splicedName[0],
                  },
                ],
              ];

              if (splicedName.length > 1) {
                _andX[0][0]['client.lastName'] = splicedName[1];
              }

              const operator = splicedName.length > 1 ? '_andX' : '_orX';

              delete query['client.firstName'];
              query[operator] = _andX;
            }

            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;

              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }
            if (query.priceAfterDiscountWithoutMoneyBox) {
              const filters = query.priceAfterDiscountWithoutMoneyBox;
              delete query.priceAfterDiscountWithoutMoneyBox;
              if (filters.valueFrom) {
                query['priceAfterDiscountWithoutMoneyBox[gte]'] =
                  filters.valueFrom;
              }
              if (filters.valueTo) {
                query['priceAfterDiscountWithoutMoneyBox[lte]'] =
                  filters.valueTo;
              }
            }

            const id = this.readQuery();
            query.id = !this.state.isUseOrderParam && id ? id : query.id;
            this.setState(prevState => ({
              ...prevState,
              isUseOrderParam: true,
            }));

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);
