import styled from 'styled-components';

const DisabledOverlay = styled.div`
  position: relative;
  width: 100%;

  &:before {
    ${props => props.isHidden && 'display: none'}
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }
`;

export default DisabledOverlay;
