import React, { Component } from 'react';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import { rootDefaultState, mappingDefaultState } from './defaultState';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { post } from 'helpers/apiHelpers';
import { fetchDiets } from 'actions/Diets';
import { fetchVariants } from 'actions/Variants';
import { combineStyles } from 'helpers/helpers';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { DialogLoader } from 'components/DialogLoader';

import { withTranslation } from 'react-i18next';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Assignment from '@material-ui/icons/Assignment';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import {
  AddCircleOutline,
  AllOut,
  Info,
  RemoveCircle,
} from '@material-ui/icons';
import Card from 'components/Card/Card';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Datetime from 'react-datetime';
import moment from 'moment';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import SelectInput from '../../../components/FormSelect/SelectInput';
import CardFooter from '../../../components/Card/CardFooter';
import SweetAlert from "react-bootstrap-sweetalert";

const Wrapper = styled.div`
  h5 {
    font-size: 1rem;
  }
`;

const CheckBoxForm = ({
  name,
  classes,
  checked,
  handleToggle,
  label,
  size = 12,
  disabled = false,
  tooltip = false,
}) => (
  <FormControlLabel
    control={
      <Checkbox
        tabIndex={-1}
        disabled={disabled}
        onClick={() => handleToggle(name)}
        checked={!disabled && checked}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot,
        }}
      />
    }
    classes={{
      label: classes.label,
    }}
    label={
      <>
        {label}
        {tooltip && (
          <Tooltip
            title={
              <div
                style={{ fontSize: '16px', lineHeight: 1, padding: 6 }}
                dangerouslySetInnerHTML={{ __html: tooltip }}
              />
            }
            placement="right"
          >
            <div style={{ marginLeft: '10px' }}>
              <Info fontSize={'small'} />
            </div>
          </Tooltip>
        )}
      </>
    }
  />
);

class Form extends Component {
  state = {
    ...rootDefaultState,
    isSubmitting: false,
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    this.props.fetchDiets();
    this.props.fetchVariants();
  };

  selectAllergens = ({ target: { value } }) =>
    this.setState({ selectedAllergens: value });

  selectIngredientCategories = ({ target: { value } }) =>
    this.setState({ selectedIngredientCategories: value });

  handleInputChange = (event, value) => {
    const newValue = event.target.value;
    this.setState({ [event.target.name]: value ? value : newValue });
  };

  fillDefaultsMargins = marginName => {
    if (this.state[marginName] === '') {
      this.setState({ [marginName]: 5 });
    }
  };

  handleToggle = name => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleChangeDate = async (event, field) => {
    await this.setState(prevState => ({
      ...prevState,
      [field]: event.format('YYYY-MM-DD'),
    }));
  }

  confirmModal = () => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          title={this.props.t('common.shared.areYouSure')}
          onConfirm={() => {
            this.onSave();
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
          confirmBtnText={this.props.t('common.shared.yes')}
          cancelBtnText={this.props.t('common.shared.no')}
          showCancel
        />
      ),
    });
  };


  handleSubmit = () => {

    if(!this.state.applyToAllActiveDiets) {
      if(!this.state.dateFrom || !this.state.dateTo) {
        return this.props.openToast({
          messages: [this.props.t('massAction.dietElements.validation.dates')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    }

    this.confirmModal();
  };

  onSave = () => {
    const data = {
      dateFrom: this.state.applyToAllActiveDiets ? null : this.state.dateFrom,
      dateTo: this.state.applyToAllActiveDiets ? null : this.state.dateTo,
      applyToAllActiveDiets: this.state.applyToAllActiveDiets,
      updateDietDefaults: this.state.updateDietDefaults,
      updateBags: this.state.updateBags,
      updateSubscriptions: this.state.updateSubscriptions,
      updateSubscriptionIntents:
        this.state.updateSubscriptionIntents &&
        !this.state.resetSubscriptionIntents,
      resetSubscriptionIntents: this.state.resetSubscriptionIntents,
      mapping: this.state.mapping.map(el => ({
        from: {
          diet: el.from.diet?.['@id'] ?? null,
          variant: el.from.variant?.['@id'] ?? null,
          calorific: el.from.calorific?.['@id'] ?? null,
        },
        to: {
          diet: el.to.diet?.['@id'] ?? null,
          variant: el.to.variant?.['@id'] ?? null,
          calorific: el.to.calorific?.['@id'] ?? null,
        },
      })),
    };
    this.setState({isSubmitting: true});

    const action = post('/diet-elements-configurations', data);

    action.then(() => {
      this.props.history.push('/admin/mass-actions/diet-elements');
      this.setState({isSubmitting: false});
    }).catch(() => this.setState({isSubmitting: false}));
  }

  hideAlert = () => {
    this.setState({ alert: null });
  };

  addMappingRow = () => {
    this.setState(prevState => ({
      ...prevState,
      mapping: [...prevState.mapping, { ...mappingDefaultState() }],
    }));
  };

  handleDiets = (type, index, e, selected) => {
    const mappings = [...this.state.mapping];

    mappings[index][type].diet = selected;
    this.setState(prevState => ({
      ...prevState,
      mapping: [...mappings],
    }));
  };

  handleVariants = (type, index, e, selected) => {
    const mappings = [...this.state.mapping];

    mappings[index][type].variant = selected;
    this.setState(prevState => ({
      ...prevState,
      mapping: [...mappings],
    }));
  };
  handleCalorific = (type, index, e, selected) => {
    const mappings = [...this.state.mapping];

    mappings[index][type].calorific = selected;
    this.setState(prevState => ({
      ...prevState,
      mapping: [...mappings],
    }));
  };

  getDietVariants = diet => {
    return this.props.variants.filter(el => el.diets.includes(diet['@id']));
  };

  getCountRowsWithDiet = diet => {
    return this.state.mapping.filter(
      el => el.from.diet?.['@id'] === diet['@id']
    ).length;
  };

  getCountRowsWithVariant = variant => {
    return this.state.mapping.filter(
      el => el.from.variant?.['@id'] === variant['@id']
    ).length;
  };

  getCountRowsWithVariantAndDiet = (diet, variant) => {
    return this.state.mapping.filter(
      el =>
        el.from.diet?.['@id'] === diet['@id'] &&
        el.from.variant?.['@id'] === variant['@id']
    ).length;
  };

  fillVariants = (index, diet) => {
    const variantsToFill = this.getDietVariants(diet);

    let newMapping = [...this.state.mapping];
    const currentRow = newMapping.splice(index, 1)[0];

    variantsToFill.forEach((el, vIndex) => {
      newMapping.splice(index + vIndex, 0, {
        from: {
          diet: diet,
          variant: el,
          calorific: null,
        },
        to: {
          diet: currentRow.to.diet,
          variant: currentRow.to.variant,
          calorific: currentRow.to.calorific,
        },
      });
    });

    this.setState(prevState => ({
      ...prevState,
      mapping: newMapping,
    }));
  };

  fillVariantCalories = (index, diet, variant) => {
    let newMapping = [...this.state.mapping];
    const currentRow = newMapping.splice(index, 1)[0];

    variant.calories.forEach((el, vIndex) => {
      newMapping.splice(index + vIndex, 0, {
        from: {
          diet: diet,
          variant: variant,
          calorific: el,
        },
        to: {
          diet: currentRow.to.diet,
          variant: currentRow.to.variant,
          calorific: currentRow.to.calorific,
        },
      });
    });

    this.setState(prevState => ({
      ...prevState,
      mapping: newMapping,
    }));
  };

  removeRow = index => {
    let newMapping = [...this.state.mapping];
    newMapping.splice(index, 1);

    this.setState(prevState => ({
      ...prevState,
      mapping: newMapping,
    }));
  };

  render() {
    const { classes, t } = this.props;
    return (
      <Wrapper>
        <DialogLoader
          loaderState={this.state.isSubmitting}
          text={this.props.t('form.savingChanges')}
        />
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {t('massAction.dietElements.title')}
            </h4>
          </CardHeader>
          <CardBody style={{ paddingBottom: '3rem' }}>
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <CheckBoxForm
                  name={'applyToAllActiveDiets'}
                  classes={classes}
                  checked={this.state.applyToAllActiveDiets}
                  handleToggle={this.handleToggle}
                  label={t('massAction.dietElements.applyToAllActiveDiets')}
                />
              </GridItem>
              {!this.state.applyToAllActiveDiets && (
                <>
                  <GridItem sm={4}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('massAction.dietElements.dateFrom')} *
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.dateFrom)}
                        onChange={ev => this.handleChangeDate(ev, 'dateFrom')}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem sm={4}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '5px' }}
                    >
                      {this.props.t('massAction.dietElements.dateTo')} *
                    </FormLabel>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        dateFormat={moment.localeData().longDateFormat('L')}
                        closeOnSelect={true}
                        value={new moment(this.state.dateTo)}
                        onChange={ev => this.handleChangeDate(ev, 'dateTo')}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </>
              )}
            </GridContainer>
            <hr />
            <GridContainer>
              <GridItem sm={3}>
                <CheckBoxForm
                  name={'updateBags'}
                  classes={classes}
                  checked={this.state.updateBags}
                  handleToggle={this.handleToggle}
                  label={t('massAction.dietElements.updateBags')}
                  tooltip={t('massAction.dietElements.updateBags.tooltip')}
                />
                {this.state.updateBags && (
                  <div style={{ paddingLeft: '25px' }}>
                    <CheckBoxForm
                      name={'updateDietDefaults'}
                      classes={classes}
                      checked={this.state.updateDietDefaults}
                      handleToggle={this.handleToggle}
                      label={t('massAction.dietElements.updateDietDefaults')}
                      tooltip={t(
                        'massAction.dietElements.updateDietDefaults.tooltip'
                      )}
                    />
                  </div>
                )}
              </GridItem>
              <GridItem sm={3}>
                <CheckBoxForm
                  name={'updateSubscriptions'}
                  classes={classes}
                  checked={this.state.updateSubscriptions}
                  handleToggle={this.handleToggle}
                  label={t('massAction.dietElements.updateSubscriptions')}
                  tooltip={t(
                    'massAction.dietElements.updateSubscriptions.tooltip'
                  )}
                />
                {this.state.updateSubscriptions && (
                  <div style={{ paddingLeft: '25px' }}>
                    <CheckBoxForm
                      name={'resetSubscriptionIntents'}
                      classes={classes}
                      checked={this.state.resetSubscriptionIntents}
                      handleToggle={this.handleToggle}
                      label={t(
                        'massAction.dietElements.resetSubscriptionIntents'
                      )}
                      tooltip={t(
                        'massAction.dietElements.resetSubscriptionIntents.tooltip'
                      )}
                    />
                  </div>
                )}
              </GridItem>
              {!this.state.resetSubscriptionIntents && (
                <GridItem sm={3}>
                  <CheckBoxForm
                    name={'updateSubscriptionIntents'}
                    classes={classes}
                    checked={this.state.updateSubscriptionIntents}
                    handleToggle={this.handleToggle}
                    label={t(
                      'massAction.dietElements.updateSubscriptionIntents'
                    )}
                    tooltip={t(
                      'massAction.dietElements.updateSubscriptionIntents.tooltip'
                    )}
                  />
                </GridItem>
              )}
            </GridContainer>
            <GridContainer></GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Table style={{ textAlign: 'center' }}>
              <TableHead>
                <TableRow style={{ textAlign: 'center' }}>
                  <TableCell
                    colSpan={3}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.from')}
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.to')}
                  </TableCell>
                </TableRow>
                <TableRow style={{ textAlign: 'center' }}>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.diet')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.variant')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.calorific')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.diet')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.variant')}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{
                      textAlign: 'center',
                      border: '1px solid rgb(224, 224, 224)',
                    }}
                  >
                    {t('massAction.dietElements.calorific')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.mapping.map((mapping, index) => (
                  <TableRow>
                    <TableCell
                      colSpan={1}
                      style={{
                        textAlign: 'center',
                        border: '1px solid rgb(224, 224, 224)',
                        position: 'relative',
                      }}
                    >
                      <SelectInput
                        classes={classes}
                        mapBy="name"
                        trackBy="@id"
                        options={this.props.diets}
                        handleChange={(ev, obj) =>
                          this.handleDiets('from', index, ev, obj)
                        }
                        value={mapping.from.diet}
                      />
                      <RemoveCircle
                        onClick={() => this.removeRow(index)}
                        style={{
                          position: 'absolute',
                          left: -13,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer',
                        }}
                      />
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      style={{
                        textAlign: 'center',
                        border: '1px solid rgb(224, 224, 224)',
                        position: 'relative',
                      }}
                    >
                      {mapping.from.diet && (
                        <>
                          <SelectInput
                            classes={classes}
                            mapBy="name"
                            trackBy="@id"
                            options={this.getDietVariants(mapping.from.diet)}
                            handleChange={(ev, obj) =>
                              this.handleVariants('from', index, ev, obj)
                            }
                            value={mapping.from.variant}
                          />
                          {this.getCountRowsWithDiet(mapping.from.diet) <=
                            1 && (
                            <>
                              <Button
                                onClick={() =>
                                  this.fillVariants(index, mapping.from.diet)
                                }
                                style={{
                                  position: 'absolute',
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <AllOut />
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      style={{
                        textAlign: 'center',
                        border: '1px solid rgb(224, 224, 224)',
                        position: 'relative',
                      }}
                    >
                      {mapping.from.variant && (
                        <>
                          <SelectInput
                            noGrid={true}
                            classes={classes}
                            mapBy="name"
                            trackBy="@id"
                            options={mapping.from.variant.calories}
                            handleChange={(ev, obj) =>
                              this.handleCalorific('from', index, ev, obj)
                            }
                            value={mapping.from.calorific}
                          />
                          {this.getCountRowsWithVariantAndDiet(
                            mapping.from.diet,
                            mapping.from.variant
                          ) <= 1 && (
                            <>
                              <Button
                                onClick={() =>
                                  this.fillVariantCalories(
                                    index,
                                    mapping.from.diet,
                                    mapping.from.variant
                                  )
                                }
                                style={{
                                  position: 'absolute',
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <AllOut />
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      style={{
                        textAlign: 'center',
                        border: '1px solid rgb(224, 224, 224)',
                      }}
                    >
                      <SelectInput
                        classes={classes}
                        mapBy="name"
                        trackBy="@id"
                        options={this.props.diets}
                        handleChange={(ev, obj) =>
                          this.handleDiets('to', index, ev, obj)
                        }
                        value={mapping.to.diet}
                      />
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      style={{
                        textAlign: 'center',
                        border: '1px solid rgb(224, 224, 224)',
                      }}
                    >
                      {mapping.to.diet && (
                        <SelectInput
                          classes={classes}
                          mapBy="name"
                          trackBy="@id"
                          options={this.getDietVariants(mapping.to.diet)}
                          handleChange={(ev, obj) =>
                            this.handleVariants('to', index, ev, obj)
                          }
                          value={mapping.to.variant}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      colSpan={1}
                      style={{
                        textAlign: 'center',
                        border: '1px solid rgb(224, 224, 224)',
                      }}
                    >
                      {mapping.to.variant && (
                        <SelectInput
                          classes={classes}
                          mapBy="name"
                          trackBy="@id"
                          options={mapping.to.variant.calories}
                          handleChange={(ev, obj) =>
                            this.handleCalorific('to', index, ev, obj)
                          }
                          value={mapping.to.calorific}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardBody>
          <CardFooter>
            <GridContainer justify="center">
              <Button onClick={this.addMappingRow}>
                {t('massAction.dietElements.addRow')} <AddCircleOutline />
              </Button>
            </GridContainer>
          </CardFooter>
        </Card>

        <FormControlButtons
          classes={classes}
          discardText={this.props.t('form.cancel')}
          submitText={this.props.t('form.save')}
          cancelPath="/admin/mass-actions/diet-elements"
          handleSubmit={this.handleSubmit}
        />
        {this.state.alert}
      </Wrapper>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  diets: state.Diets.diets,
  variants: state.Variants.variants,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchDiets,
    fetchVariants,
  })(withToast(withStyles(combinedStyles)(Form)))
);
