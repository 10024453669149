import React, { useEffect, useState } from 'react';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import RateReview from '@material-ui/icons/RateReview';
// core components
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import chartsStyle from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { combineStyles } from '../../../helpers/helpers';
import { get } from '../../../helpers/apiHelpers';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

const PieChart = ({ classes, selectedBrand }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState({
    data: {
      labels: [],
      series: [],
      legends: [],
      colors: [
        classes.info,
        classes.danger,
        classes.warning,
        classes.primary,
        classes.success,
      ],
    },
    allowedSeries: ['Facebook', 'Instagram', 'Google', 'Znajomi'],
    options: {
      height: '210px',
    },
  });

  useEffect(() => {
    getTotalClients();
  }, []);

  const getTotalClients = () => {
    get('/admin/statistics/know-about-us', {
      brandId: [selectedBrand],
    }).then(res => getData(res));
  };

  const getData = array => {
    const newChartData = { ...chartData };

    const totalAll = array.map(item => item.amount).reduce((a, b) => a + b, 0);
    const getPercent = totalVariable =>
      ((totalVariable / totalAll) * 100).toFixed(0);

    let otherAmount = 0;

    array.forEach(item => {
      if (
        chartData.allowedSeries.some(series => {
          return item.knowAboutUs === series;
        })
      ) {
        chartData.data.labels.push(`${getPercent(item.amount)}%`);
        chartData.data.series.push(item.amount);
        chartData.data.legends.push(item.knowAboutUs);
      } else {
        otherAmount = otherAmount + item.amount;
      }
    });

    chartData.data.labels.push(`${getPercent(otherAmount)}%`);
    chartData.data.series.push(otherAmount);
    chartData.data.legends.push(t('dashboard.other'));

    setChartData(newChartData);
  };

  return (
    <Card>
      <CardHeader color="danger" stats icon>
        <CardIcon color="danger">
          <RateReview />
        </CardIcon>
        <p style={{ paddingTop: '10px' }} className={classes.cardCategory}>
          <a href={'/admin/clients'}>{t('dashboard.goToClientList')}</a>
        </p>
        <h3 className={classes.cardTitle}>{t('dashboard.clientsWhere')}</h3>
      </CardHeader>
      <CardBody>
        <ChartistGraph
          data={chartData.data}
          type="Pie"
          options={chartData.options}
        />
      </CardBody>
      <CardFooter stats className={classes.cardFooter}>
        <h6 className={classes.legendTitle}>{t('dashboard.legend')}</h6>
        <div style={{ display: 'flex', fontSize: '12px' }}>
          {chartData.data.legends.map((legend, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginRight: '15px',
                }}
              >
                <i
                  className={'fas fa-circle ' + chartData.data.colors[index]}
                />{' '}
                {legend}
              </div>
            );
          })}
        </div>
      </CardFooter>
    </Card>
  );
};

const combinedStyles = combineStyles(dashboardStyle, chartsStyle);

const mapStateToProps = state => {
  return {
    selectedBrand: state.Auth.selectedBrand,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(combinedStyles)(PieChart));
