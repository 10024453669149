import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class List extends Component {
  render() {
    return (
      <AdminTable title={this.props.t('comments.commentsList')} icon>
        <DataGrid
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/rates"
          reportName={'rate'}
          columns={columnConfig(this.props.t)}
          role="RATE"
          minRows={20}
          manipulateQuery={(requestData, query) => {
            query._orX = [];
            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;
              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }

            if (query.user) {
              query._orX.push([
                {
                  'bag.clientDiet.client.firstName': query.user.split(' '),
                  'dishItem.bag.clientDiet.client.firstName': query.user.split(
                    ' '
                  ),
                  'bag.clientDiet.client.lastName': query.user,
                  'dishItem.bag.clientDiet.client.lastName': query.user.split(
                    ' '
                  ),
                },
              ]);
            }
            if (query.diet) {
              query._orX.push([
                {
                  'bag.diet.name': query.diet,
                  'dishItem.bag.diet.name': query.diet,
                },
              ]);
            }
            if (query.date) {
              query._orX.push([
                {
                  'bag.date': query.date,
                  'dishItem.bag.date': query.date,
                },
              ]);
            }

            return query;
          }}
        />
      </AdminTable>
    );
  }
}
const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);
