import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/App';
import { persistor, store } from './store';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.5.0';
import 'assets/scss/app.scss';
import { get } from 'helpers/apiHelpers';
import { getI18n } from 'react-i18next';
import i18n from './i18n';
import translationPL from 'locales/pl.json';
require('promise.allsettled').shim();

const loadLanguage = (lang = null) => {
  return new Promise(async res => {
    try {
      const bundle = await get(
        '/translations/loader/admin/default',
        {},
        lang ? { forcedLanguage: lang } : {}
      );

      res({ success: true, bundle });
    } catch (e) {
      console.error(e);
      res({ success: false, bundle: {} });
    }
  });
};

const loadFallbackLanguage = async () => {
  let { success, bundle } = await loadLanguage('pl');

  if (!success) {
    bundle = translationPL;
  }
  i18n.addResourceBundle('pl', 'translation', bundle);
};

loadFallbackLanguage();

loadLanguage().then(({ success, bundle: currentLanguageBundle }) => {
  if (success) {
    i18n.addResourceBundle(
      getI18n().language.substr(0, 2),
      'translation',
      currentLanguageBundle
    );
  }
  // i18n.reloadResources(['pl'], ['translation']);
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
});

// ReactDOM.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <App />
//     </PersistGate>
//   </Provider>,
//   document.getElementById('root')
// );
