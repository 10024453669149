import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import GridItem from 'components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import ShortAddressFormConfirmModal from '../components/ShortAddressFormConfirmModal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isGranted } from 'helpers/helpers';
import {
  // fetchPaymentTypes,
  // selectDefaultPaymentType,
  setEnabledPaymentTypes,
} from 'actions/Brands';
import { ROLE_EDIT_USE_MINI_ADDRESS_FORM } from 'helpers/roles';

const ConfigClientPanelModule = ({
  module,
  classes,
  handleModuleUpdate,
  setEnabledPaymentTypes,
}) => {
  const { t } = useTranslation();
  const [
    isShortAddressFormModalOpened,
    setIsShortAddressFormModalOpened,
  ] = useState(false);

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'ConfigClientPanelModule',
        })}
      </div>
    );
  }

  const isUseLessFieldsEnabled = isGranted(ROLE_EDIT_USE_MINI_ADDRESS_FORM);

  return (
    <GridContainer>
      <GridItem md={12} lg={6}>
        <GridContainer>
          <GridItem md={12}>
            <h4>{t('Konfiguracja formularza nowego zamówienia')} *</h4>
            <Divider style={{ marginBottom: '20px' }} />
          </GridItem>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module.enableVariantStep}
                  onChange={() =>
                    handleModuleUpdate({
                      ...module,
                      enableVariantStep: !module.enableVariantStep,
                    })
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{t('module.configClientPanel.enableVariantStep')}</span>
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip
                      title={
                        <h4>
                          {t(
                            'module.configClientPanel.enableVariantStep.tooltip'
                          )}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info
                        fontSize="small"
                        style={{ color: 'grey', display: 'block' }}
                      />
                    </Tooltip>
                  </div>
                </div>
              }
            />
          </GridItem>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module.useAddressesWithLessFields}
                  onChange={() => {
                    if (isUseLessFieldsEnabled) {
                      handleModuleUpdate({
                        ...module,
                        useAddressesWithLessFields: !module.useAddressesWithLessFields,
                      });
                    }
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                  disabled={!isUseLessFieldsEnabled}
                />
              }
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    {t(
                      'brands.newBrandForm.useMiniFormAddress',
                      'Użyj skróconej wersji formularza adresu dostawy'
                    )}
                  </span>
                  <div style={{ marginLeft: '10px' }}>
                    <Tooltip
                      title={
                        <h4>
                          {t('brands.newBrandForm.useMiniFormAddress.tooltip')}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info
                        fontSize="small"
                        style={{ color: 'grey', display: 'block' }}
                      />
                    </Tooltip>
                  </div>
                </div>
              }
            />
          </GridItem>
          <GridItem md={12} style={{ marginBottom: '30px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module?.allowClientSeeMoneyBox}
                  onChange={() =>
                    handleModuleUpdate({
                      ...module,
                      allowClientSeeMoneyBox: !module.allowClientSeeMoneyBox,
                    })
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t(
                'brands.newBrandForm.allowClientSeeMoneyBox',
                'Włącz pokazywanie skarbonki na pasku nawigacji'
              )}
            />
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem md={12} lg={6}>
        <GridContainer>
          <GridItem md={12}>
            <h4>{t('brandCfg.paymentMode')} *</h4>
            <Divider style={{ marginBottom: '20px' }} />
          </GridItem>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module.isOneTimePayPaymentMode}
                  onChange={() =>
                    handleModuleUpdate({
                      ...module,
                      isOneTimePayPaymentMode: !module.isOneTimePayPaymentMode,
                    })
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                  disabled={!module.isSubscriptionPaymentMode}
                />
              }
              label={t('module.configClientPanel.oneTimePayment')}
            />
          </GridItem>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module.isSubscriptionPaymentMode}
                  onChange={() => {
                    if (module.isSubscriptionPaymentMode) {
                      setEnabledPaymentTypes('dietSubscription', []);
                    }

                    handleModuleUpdate({
                      ...module,
                      isSubscriptionPaymentMode: !module.isSubscriptionPaymentMode,
                    });
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                  disabled={!module.isOneTimePayPaymentMode}
                />
              }
              label={t('module.configClientPanel.subscription')}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <ShortAddressFormConfirmModal
        isOpened={isShortAddressFormModalOpened}
        handleClose={() => setIsShortAddressFormModalOpened(false)}
        handleConfirm={() => {
          handleModuleUpdate({
            ...module,
            useAddressesWithLessFields: !module.useAddressesWithLessFields,
          });
          setIsShortAddressFormModalOpened(false);
        }}
      />
    </GridContainer>
  );
};

const enhance = compose(
  connect(
    ({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
      selectedBrand,
      paymentTypes,
    }),
    {
      setEnabledPaymentTypes,
    }
  )
);

export default enhance(ConfigClientPanelModule);
