import Success from 'components/Typography/Success';
import Danger from 'components/Typography/Danger';
import YesNoFilter from 'components/Grid/Filter/YesNo';

const columnConfig = t => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    width: 50,
  },
  {
    title: 'form.field.name',
    name: 'name',
    accessor: 'name',
    filterable: true,
  },
  {
    title: 'form.field.code',
    name: 'code',
    accessor: 'shortName',
    filterable: false,
  },
  {
    title: 'form.field.order',
    name: 'position',
    accessor: 'position',
    filterable: true,
  },
  {
    title: 'form.field.description',
    name: 'description',
    accessor: 'description',
    filterable: false,
    sortable: false,
  },
  {
    title: 'form.field.active',
    name: 'active',
    accessor: row =>
      row.active ? (
        <Success>
          <b>{t('form.field.active')}</b>
        </Success>
      ) : (
        <Danger>{t('form.field.inactive')}</Danger>
      ),
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    filterable: false,
    sortable: false,
    width: 100,
  },
];

export default columnConfig;
