const columnConfig = valueAccessor => [
  {
    title: 'ID',
    accessor: 'id',
    width: 100,
  },
  {
    title: 'columns.name',
    accessor: valueAccessor,
  },
];

export default columnConfig;
