import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { Manager, Target, Popper } from "react-popper";
import PowerSettings from '@material-ui/icons/PowerSettingsNew';
import Settings from '@material-ui/icons/Settings';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import { connect } from 'react-redux';
import { put, get } from 'helpers/apiHelpers';

import {
  handleBrandChange,
  logout,
  refreshLogin,
  setSelectedLanguage,
} from 'actions/Auth';
import { Tooltip } from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import SelectInput from '../FormSelect/SelectInput';
import {
  combineStyles,
  errorNotificationMapObject,
  isGranted,
} from '../../helpers/helpers';
import { withRouter } from 'react-router-dom';
import { ROLE_EDIT_BRAND } from '../../helpers/roles';
import { withToast } from 'material-ui-toast-redux';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/fi';
import 'moment/locale/pl';
import 'moment/locale/ru';
import { compose } from 'redux';
import { fetchLanguages } from 'actions/Auth';
import { getI18n, withTranslation } from 'react-i18next';
import i18n from 'i18next';

const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}uploaded-media/`;

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.props.i18n
      .changeLanguage(this.props.user.language)
      .catch(error => console.error(error));
    moment.locale(this.props.user.language);
  }

  getLanguages = async () => {
    await this.props.fetchLanguages();

    const selectedLanguage = this.props.languages.find(language => {
      return language.isoCode === this.props.user.language;
    });

    this.setState({
      languageImgUrl: selectedLanguage?.image?.contentUrl,
    });
  };

  componentDidMount() {
    this.getLanguages();
  }

  componentDidUpdate() {
    if (this.state.language !== this.props.i18n.language) {
      this.setState({ language: this.props.i18n.language });
      this.fetchLanguageBeforeChange(this.props.i18n.language);
      moment.locale(this.props.i18n.language);
    }
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  componentWillUnmount() {
    this.props.logout();
  }

  fetchLanguageBeforeChange(lang) {
    return new Promise(async res => {
      const resource = i18n.getResource(lang, 'translation');

      if (typeof resource === 'undefined') {
        let startedLanguage = {};

        try {
          startedLanguage = await get(
            '/translations/loader/admin/default',
            {},
            { forcedLanguage: lang }
          );
        } catch (e) {}
        await i18n.addResourceBundle(lang, 'translation', startedLanguage);
      }
      res(true);
    });
  }

  handleLanguageChange = async ({ target: { value } }, languageObject) => {
    await this.fetchLanguageBeforeChange(value);
    this.props.i18n.changeLanguage(value).catch(error => console.error(error));
    moment.updateLocale(value, {
      week: {
        dow: 1,
      },
    });

    this.saveUserLanguage(value, languageObject);
  };

  saveUserLanguage = (value, languageObject) => {
    put(this.props.user.id, { language: value })
      .then(() => {
        this.props.setSelectedLanguage(languageObject);
      })
      .then(() => {
        this.props.openToast({
          messages: [
            this.props.t('success.dataUpdated', 'Zaktualizowano dane'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      });
  };

  render() {
    const { classes, rtlActive } = this.props;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: '15px',
        }}
      >
        {isGranted(ROLE_EDIT_BRAND) && (
          <Button
            round
            justIcon
            simple
            color={'transparent'}
            onClick={() =>
              this.props.history.push(`/admin/brands/edit/${this.props.brand}`)
            }
          >
            <Settings />
          </Button>
        )}
        {/*components.navbars.adminNavbarLinks.activeBrandTooltip*/}
        <Tooltip title={'Aktywna marka'} placement={'left'}>
          <div className={wrapper} style={{ width: '150px' }}>
            <SelectInput
              classes={classes}
              options={this.props.brands}
              mapBy="name"
              trackBy="id"
              value={this.props.brand}
              handleChange={(ev, brand) => {
                this.props.handleBrandChange(ev, { brand, refresh: true });
              }}
            />
          </div>
        </Tooltip>

        {this.props.languages && this.props.adminAllowedToChangeLanguage && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80px',
              margin: '0 5px',
            }}
          >
            <div
              style={{
                marginRight: '10px',
              }}
            >
              {this.props.selectedLanguage?.image && (
                <img
                  src={`${uploadedContentBaseURL}${this.props.selectedLanguage.image.contentUrl}`}
                  alt=""
                />
              )}
            </div>
            <SelectInput
              classes={classes}
              options={this.props.languages}
              value={this.props.selectedLanguage}
              trackBy="isoCode"
              mapBy="isoCode"
              handleChange={this.handleLanguageChange}
            />
          </div>
        )}

        <Button
          color={'transparent'}
          onClick={() => this.props.history.push('/auth/login')}
          style={{ padding: '5px 10px', margin: '0 5px' }}
        >
          <PowerSettings /> {this.props.t('brandCfg.logout')}
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};

const combinedStyles = combineStyles(extendedFormsStyle, adminNavbarLinksStyle);

const mapStateToProps = state => {
  return {
    brands: state.Auth.user.brands,
    brand: state.Auth.selectedBrand,
    user: state.Auth.user,
    adminAllowedToChangeLanguage:
      state.Brands.brand.multinational.adminAllowedToChangeLanguage,
    languages: state.Auth.languages,
    selectedLanguage: state.Auth.selectedLanguage,
  };
};

const mapDispatchToProps = {
  handleBrandChange,
  logout,
  refreshLogin,
  fetchLanguages,
  setSelectedLanguage,
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default enhance(HeaderLinks);
