import React from 'react';
import { TableCell } from '@material-ui/core';
import SelectInput from '../../../../components/FormSelect/SelectInput';

class ContainerCell extends React.Component {
  render() {
    const {
      dishSizes,
      size,
      classes,
      containers,
      handleContainerChange,
    } = this.props;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    return (
      <TableCell>
        <SelectInput
          noGrid
          classes={classes}
          mapBy="name"
          trackBy="@id"
          value={dishSizeBySize.container}
          handleChange={(ev, obj) =>
            handleContainerChange(ev, obj, dishSizeBySize)
          }
          options={containers}
        />
      </TableCell>
    );
  }
}

export default ContainerCell;
