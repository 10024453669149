import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';

import { combineStyles } from 'helpers/helpers';
import { get, put } from 'helpers/apiHelpers';
import { ROLE_EDIT_HOVER } from 'helpers/roles';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import { withTranslation } from 'react-i18next';

export class HoverSettings extends Component {
  state = {
    title: '',
    image: null,
    imageUrl: '',
    active: false,
    description: '',
  };

  packageId = this.props.match.params.id;

  componentDidMount = async () => {
    const currentSettings = await get(
      `/brands/${this.props.selectedBrand}/hover`
    );

    const { title, image, active, description } = currentSettings.hover;

    this.setState({
      title: title ? title : '',
      image: image || null,
      active: active ? active : false,
      imageUrl: image?.contentUrl || null,
      description: description ? description : '',
    });
  };

  handleInputChange = (event, value) => {
    const newValue = event.target.value;
    this.setState({ [event.target.name]: value ? value : newValue });
  };

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  handleToggle = e => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  validateForm = () => {
    return this.state.title;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      title: this.state.title,
      active: this.state.active,
      image: this.state.image,
      imageUrl: this.state.image?.['@id'] || null,
      description: this.state.description,
    };

    const action = put(`/brands/${this.props.selectedBrand}/hover`, {
      hover: data,
    });

    action.then(() => window.location.reload());
  };

  render() {
    const { classes } = this.props;
    const { title, active, imageUrl, description } = this.state;

    const isEditEnabled = this.props.roles
      ? this.props.roles.includes(ROLE_EDIT_HOVER)
      : false;

    return (
      <>
        <AdminTable title={this.props.t('form.hoverSettings')} icon>
          <FormTextInput
            label={this.props.t('form.title') + '*'}
            classes={classes}
            name="title"
            value={title}
            handleChange={this.handleInputChange}
            inputSize={6}
          />

          <FormTextInput
            multiline
            rows={3}
            rowsMax={10}
            label={
              <div style={{ display: 'flex' }}>
                <span>{this.props.t('form.field.description')}</span>
                <div style={{ marginLeft: '10px', display: 'inline-block' }}>
                  <Tooltip
                    title={
                      <div>
                        <h4>{this.props.t('form.field.descriptionTooltip')}</h4>
                        <h4>
                          {this.props.t('common.shared.etc')}{' '}
                          {`<div style="margin: 30px; max-width: 50%">${this.props.t(
                            'form.menuPlanning.exampleTitleText'
                          )}</div>
`}
                        </h4>
                      </div>
                    }
                    placement="right"
                  >
                    <Info fontSize={'small'} />
                  </Tooltip>
                </div>
              </div>
            }
            classes={classes}
            name="description"
            value={description}
            handleChange={this.handleInputChange}
            inputSize={10}
            maxLength={160}
          />
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('form.field.active.label')}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    name="active"
                    onClick={this.handleToggle}
                    checked={active}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={''}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: '20px' }}>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('form.field.photo')}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10} style={{ marginLeft: '-15px' }}>
              <FormImageUpload
                classes={classes}
                stateName="image"
                getImage={this.getImage}
                removeImage={this.removeImage}
                previewUrl={imageUrl}
              />
            </GridItem>
          </GridContainer>

          {isEditEnabled && (
            <FormControlButtons
              classes={classes}
              submitText={this.props.t('form.save')}
              handleSubmit={this.handleSubmit}
            />
          )}
        </AdminTable>
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    roles: state.Auth.user.roles,
    selectedBrand: state.Auth.selectedBrand,
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    null
  )(withToast(withStyles(combinedStyles)(HoverSettings)))
);
