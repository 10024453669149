import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import SettingsIcon from '@material-ui/icons/Settings';

class LocalStorageFilterPersistDriver {
  url;
  constructor(url) {
    this.url = url;
  }

  static getAllTablesConfig = () => {
    try {
      return JSON.parse(localStorage.getItem('tables')) || {};
    } catch (e) {
      return {};
    }
  };

  getTableConfig = () => {
    return LocalStorageFilterPersistDriver.getAllTablesConfig()[this.url] || {};
  };

  setTableConfig = (payload = {}) => {
    localStorage.setItem(
      'tables',
      JSON.stringify({
        ...LocalStorageFilterPersistDriver.getAllTablesConfig(),
        [this.url]: {
          ...this.getTableConfig(),
          ...payload,
        },
      })
    );
  };

  getHiddenColumns = (defaultHiddenColumns = []) => {
    return (
      this.getTableConfig(this.url).hiddenColumns || defaultHiddenColumns || []
    );
  };

  setHiddenColumns = hidden => {
    return this.setTableConfig({ hiddenColumns: hidden });
  };
}

export { LocalStorageFilterPersistDriver as FilterPersistDriver };

export default function ColumnManager({ columns, hidden, setHidden, isFilterOnHiddenColumn }) {
  const [isOpen, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const { t } = useTranslation();

  const toggleColumn = name => {
    setHidden(
      hidden.includes(name)
        ? hidden.filter(v => v !== name)
        : hidden.concat(name)
    );
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t('dataGrid.visibleColumns')}</DialogTitle>
        <DialogContent>
          <FormGroup>
            {columns.map(c => (
              <FormControlLabel
                label={c.title ? t(c.title) : c.Header}
                control={
                  <Switch
                    checked={!hidden.includes(c.name)}
                    onChange={() => toggleColumn(c.name)}
                  />
                }
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('form.close')}</Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleOpen} color={isFilterOnHiddenColumn ? 'warning' : 'default'}  style={{alignItems: 'end'}}>
        <SettingsIcon /> {isFilterOnHiddenColumn && (<><span>{t('dataGrid.hiddenFiltersUsed')}</span></>)}
      </Button>
    </>
  );
}
