import { currencyToSymbol } from 'utils/currencies';

const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: true,
    width: 50,
  },
  {
    title: 'form.field.nameForClient',
    name: 'name',
    accessor: 'name',
    filterable: true,
  },
  {
    title: 'form.field.workingName',
    name: 'workName',
    accessor: 'workName',
    filterable: true,
    sortable: false,
  },
  {
    title: 'form.field.categories',
    name: 'categories.value',
    accessor: row => row.categories.map(category => category.value).join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'form.field.allergens',
    name: 'allergens.value',
    accessor: row => row.allergens.map(allergen => allergen.value).join(', '),
    filterable: true,
    sortable: false,
  },
  {
    title: 'form.field.pricePerKg',
    name: 'price',
    accessor: (row, plug) =>
      !row.price
        ? plug.t('form.field.noPrice', 'Brak ceny')
        : `${(row.price * 10).toFixed(2)} ${currencyToSymbol(
            plug.multinational.currency
          )}`,
    sortable: true,
    filterable: false,
    width: 150,
  },
  {
    title: 'form.field.availability',
    name: 'inventoryAmount',
    accessor: 'inventoryAmount',
    sortable: true,
    filterable: false,
    width: 150,
  },
];

export default columnConfig;
