import React, {Component, useState } from 'react';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import defaultState from './defaultState';
import {connect} from 'react-redux';
import {withToast} from 'material-ui-toast-redux';
import {post, put} from 'helpers/apiHelpers';
import {fetchDiet, fetchDiets} from 'actions/Diets';
import {combineStyles} from 'helpers/helpers';
import {fetchAllergens} from 'actions/Allergens';
import {fetchIngredientCategories, fetchIngredients,} from 'actions/Ingredients';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import TableActions from 'components/DataTable/Actions';
import {DialogLoader} from 'components/DialogLoader';

import {withTranslation} from 'react-i18next';
import BasicInfo from './sections/BasicInfo';
import ClientsPanelInterface from './sections/ClientsPanelInterface';
import Excludes from './sections/Excludes';
import Config from './sections/Config';
import DietAssumptions from './sections/DietAssumptions';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import DietSubpage from './DietSubpage';
import BasicInfoCard from '../../components/CMS/BasicInfoCard';
import InfoBannerCard from '../../components/CMS/InfoBannerCard';
import DescriptionCard from '../../components/CMS/DescriptionCard';

const toFloat = value => (value === null ? null : parseInt(value));

const Wrapper = styled.div`
  h5 {
    font-size: 1rem;
  }
`;

const CLIENT_PANEL = 'CLIENT_PANEL';
const DIET_SUBPAGE = 'DIET_SUBPAGE';

class Form extends Component {
  state = {
    ...defaultState,
    selectedAllergens: [],
    selectedIngredients: [],
    selectedIngredientCategories: [],
    isLoading: false,
    isSubmitting: false,
    reactTablePageSize: 10,
    subpage: CLIENT_PANEL,
    urlSlug: '',
    metaTitle: '',
    metaDescription: '',
    dietDescription: '',
    bannerEnabled: false,
    bannerHeader: '',
    bannerText: '',
    infoBannerDesktop: null,
    infoBannerDesktopUrl: null,
    infoBannerMobile: null,
    infoBannerMobileUrl: null,
    bannerId: null,
  };

  dietId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    this.props.fetchDiets();
    this.props.fetchAllergens();
    this.props.fetchIngredientCategories();

    if (this.isEdit) {
      const diet = await this.props.fetchDiet(this.dietId);
      const {
        assumptionOfProtein,
        assumptionOfFats,
        assumptionOfCarbohydrates,
      } = diet;

      const carbohydratesAssumption =
        assumptionOfCarbohydrates.assumption ?? '';
      const carbohydratesMargin = assumptionOfCarbohydrates.margin ?? '';
      const fatsAssumption = assumptionOfFats.assumption ?? '';
      const fatsMargin = assumptionOfFats.margin ?? '';
      const proteinAssumption = assumptionOfProtein.assumption ?? '';
      const proteinMargin = assumptionOfProtein.margin ?? '';

      const newState = {
        name: diet.name,
        workName: diet.workName,
        position: diet.position ?? '',
        code: diet.code,
        description: diet.description,
        checked: diet.active,
        color: diet.color,
        selectedAllergens: diet.excludedAllergens,
        selectedIngredients: diet.excludedIngredients,
        selectedIngredientCategories: diet.excludedIngredientCategories,
        allowSelectMenuFromDiets: diet.allowSelectMenuFromDiets,
        clientImage: diet.clientImage?.['@id'] || null,
        clientImageUrl: diet.clientImage?.contentUrl || null,
        mainImage: diet.mainImage?.['@id'] || null,
        mainImageUrl: diet.mainImage?.contentUrl || null,
        hideDisabledMenuPlannerOptions: diet.hideDisabledMenuPlannerOptions,
        carbohydratesAssumption,
        carbohydratesMargin,
        fatsAssumption,
        fatsMargin,
        proteinAssumption,
        proteinMargin,
        urlSlug: diet.pageSettings.slug ?? '',
        metaTitle: diet.seo.title ?? '',
        metaDescription: diet.seo.description ?? '',
        dietDescription: diet.pageSettings.content ?? '',
        bannerEnabled: diet.banner ? diet.banner.enabled : false,
        bannerHeader: diet.banner ? diet.banner.heading : '',
        bannerText: diet.banner ? diet.banner.content : '',
      };

      if (diet.banner) {
        newState.bannerId = diet.banner['@id'];

        if (diet.banner.desktop) {
          newState.infoBannerDesktop = diet.banner.desktop['@id'];
          newState.infoBannerDesktopUrl = diet.banner.desktop?.contentUrl;
        }

        if (diet.banner.mobile) {
          newState.infoBannerMobile = diet.banner.mobile['@id'];
          newState.infoBannerMobileUrl = diet.banner.mobile?.contentUrl;
        }
      }

      this.setState(prevState => ({ ...prevState, ...newState }));
    }

    this.setState({ isLoading: false });
  };

  renderIngredients = stateField => {
    return this.state[stateField].map(item => {
      return {
        name: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            <TableActions
              handleRemove={() => this.removeMeal(stateField, item)}
            />
            {item.label ? item.label : item.value ? item.value : item.name}
          </div>
        ),
      };
    });
  };

  selectAllergens = ({ target: { value } }) =>
    this.setState({ selectedAllergens: value });

  selectIngredientCategories = ({ target: { value } }) =>
    this.setState({ selectedIngredientCategories: value });

  handleInputChange = (event, value) => {
    const newValue = event.target.value;
    this.setState({ [event.target.name]: value ? value : newValue });
  };

  fillDefaultsMargins = marginName => {
    if (this.state[marginName] === '') {
      this.setState({ [marginName]: 5 });
    }
  };

  handleToggle = key => {
    this.setState({
      [key]: !this.state[key],
    });
  };

  handleColorPicker = color => {
    this.setState({
      color: color.hex,
    });
  };

  validateForm = () => {
    return this.state.name && this.state.code;
  };

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  getFilteredIngredients = async e => {
    return this.props.fetchIngredients({
      pageSize: 30,
      page: 0,
      sorted: [],
      filtered: [
        {
          id: 'name',
          value: e,
        },
      ],
    });
  };

  addMeal = itemToAdd => {
    const isAlreadyAdded = this.state.selectedIngredients.some(
      ingredient => ingredient['@id'] === itemToAdd['@id']
    );

    if (isAlreadyAdded) {
      return this.props.openToast({
        messages: [this.props.t('form.ingredientAdded')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const selectedIngredients = [...this.state.selectedIngredients, itemToAdd];

    this.setState(prevState => ({
      ...prevState,
      selectedIngredients,
    }));
  };

  removeMeal = (stateField, itemToRemove) => {
    const filteredElements = this.state[stateField].filter(
      ingredient => ingredient['@id'] !== itemToRemove['@id']
    );
    this.setState({ [stateField]: filteredElements });
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const arrayOfObjectToArrayOfIRI = element => element['@id'];

    const excludedIngredients = this.state.selectedIngredients.map(
      arrayOfObjectToArrayOfIRI
    );
    const excludedAllergens = this.state.selectedAllergens.map(
      arrayOfObjectToArrayOfIRI
    );
    const excludedIngredientCategories = this.state.selectedIngredientCategories.map(
      arrayOfObjectToArrayOfIRI
    );

    const {
      carbohydratesAssumption,
      carbohydratesMargin,
      proteinAssumption,
      proteinMargin,
      fatsAssumption,
      fatsMargin,
    } = this.state;

    const assumptionOfFats = {
      assumption: toFloat(fatsAssumption),
      margin: toFloat(fatsMargin),
    };
    const assumptionOfCarbohydrates = {
      assumption: toFloat(carbohydratesAssumption),
      margin: toFloat(carbohydratesMargin),
    };
    const assumptionOfProtein = {
      assumption: toFloat(proteinAssumption),
      margin: toFloat(proteinMargin),
    };

    const data = {
      name: this.state.name,
      workName: this.state.workName,
      position: parseInt(this.state.position) || null,
      code: this.state.code,
      description: this.state.description,
      active: this.state.checked,
      color: this.state.color,
      excludedIngredients,
      excludedAllergens,
      excludedIngredientCategories,
      clientImage: this.state.clientImage,
      mainImage: this.state.mainImage,
      allowSelectMenuFromDiets: this.state.allowSelectMenuFromDiets,
      hideDisabledMenuPlannerOptions: this.state.hideDisabledMenuPlannerOptions ?? false,
      assumptionOfCarbohydrates,
      assumptionOfProtein,
      assumptionOfFats,
      seo: {
        title: this.state.metaTitle,
        description: this.state.metaDescription,
      },
      pageSettings: {
        slug: this.state.urlSlug,
        content: this.state.dietDescription,
      },
      banner: {
        enabled: this.state.bannerEnabled,
        heading: this.state.bannerHeader,
        content: this.state.bannerText,
        desktop: this.state.infoBannerDesktop,
        mobile: this.state.infoBannerMobile,
      },
    };

    if (this.state.bannerId) {
      data.banner['@id'] = this.state.bannerId;
    }

    this.setState({ isSubmitting: true });

    const action = this.isEdit
      ? put(`/diets/${this.dietId}`, data)
      : post('/diets', data);

    action.then(() => {
      this.props.history.push('/admin/diets');
      this.setState({ isSubmitting: false });
      if (!this.isEdit) {
        return this.props.openToast({
          messages: [this.props.t('notify.advancedDiet')],
          type: 'warning',
          autoHideDuration: 10000,
        });
      }
    });
  };

  handleDiets = (e, selected) =>
    this.setState(prevState => ({
      ...prevState,
      allowSelectMenuFromDiets: selected.map(el => el['@id']),
    }));

  getDietOptions = () => {
    if (this.isEdit) {
      return this.props.diets.filter(el => el.id != this.dietId);
    }

    return this.props.diets;
  };

  setSubpage = subpage => {
    this.setState({ subpage: subpage });
  };

  handleChange = event => {
    this.setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  setDietSubpageData = (value, name) => {
    this.setState({ [name]: value });
  };

  handleDietSubpageInputChange = (value, name) => {
    this.setState(prevState => ({ ...prevState, [name]: value }));
  };

  handleDietSubpageBannerInputChange = e => {
    this.setState(prevState => ({
      ...prevState,
      banner: { ...prevState.banner, [e.target.name]: e.target.value },
    }));
  };

  handleBannerCheckboxChange = () => {
    this.setState(prevState => ({
      ...prevState,
      bannerEnabled: !this.state.bannerEnabled,
    }));
  };

  render() {
    const { classes, t } = this.props;

    return (
      <Wrapper>
        <DialogLoader
          loaderState={this.state.isSubmitting}
          text={t('form.savingChanges')}
        />
        <DialogLoader
          loaderState={this.state.isLoading}
          text={t('form.loadingMeal')}
        />

        <GridContainer>
          <GridItem xs={12}>
            <h2 style={{ display: 'block' }}>
              {t('views.diets.dietEdit')}
              <div>
                <Button
                  onClick={() => this.setSubpage(CLIENT_PANEL)}
                  color={this.state.subpage === CLIENT_PANEL ? 'info' : ''}
                >
                  {t('views.diets.clientPanel')}
                </Button>
                <Button
                  onClick={() => this.setSubpage(DIET_SUBPAGE)}
                  color={this.state.subpage === DIET_SUBPAGE ? 'info' : ''}
                >
                  {t('views.diets.dietSubpage')}
                </Button>
              </div>
            </h2>
          </GridItem>
        </GridContainer>

        {this.state.subpage === CLIENT_PANEL && (
          <>
            <div style={{ marginTop: '15px' }}>
              <BasicInfo {...this} />
              <ClientsPanelInterface {...this} />
              <Excludes {...this} />
              <DietAssumptions {...this} />
              <Config {...this} />
            </div>
          </>
        )}

        {this.state.subpage === DIET_SUBPAGE && (
          <>
            <BasicInfoCard
              urlSlug={this.state.urlSlug}
              metaTitle={this.state.metaTitle}
              metaDescription={this.state.metaDescription}
              handleChange={this.handleChange}
            />

            <InfoBannerCard
              infoBannerCheckbox={this.state.bannerEnabled}
              bannerHeader={this.state.bannerHeader}
              bannerText={this.state.bannerText}
              // infoBannerCheckbox={this.state.banner.enabled}
              // bannerHeader={this.state.banner.heading}
              // bannerText={this.state.banner.content}
              handleInputChange={this.handleChange}
              handleCheckboxChange={this.handleBannerCheckboxChange}
              getImage={this.getImage}
              removeImage={this.removeImage}
              infoBannerMobileUrl={this.state.infoBannerMobileUrl}
              infoBannerDesktopUrl={this.state.infoBannerDesktopUrl}
            />

            <DescriptionCard
              value={this.state.dietDescription}
              setValue={e => this.setDietSubpageData(e, 'dietDescription')}
              title={t('views.diets.dietDescription')}
            />
          </>
        )}

        <FormControlButtons
          classes={classes}
          discardText={this.props.t('form.cancel')}
          submitText={this.props.t('form.save')}
          cancelPath="/admin/diets"
          handleSubmit={this.handleSubmit}
        />
      </Wrapper>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  diet: state.Diets.diet,
  diets: state.Diets.diets,
  loading: state.Diets.loading,
  allergens: state.Allergens.allergens,
  ingredients: state.Ingredients.ingredients,
  ingredientCategories: state.Ingredients.ingredientCategories,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchDiet,
    fetchIngredients,
    fetchIngredientCategories,
    fetchAllergens,
    fetchDiets,
  })(withToast(withStyles(combinedStyles)(Form)))
);
