import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import {GetApp, MonetizationOn} from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ModalButton from 'components/CustomButtons/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

class FoodCost extends React.Component {
  state = {
    errorModal: false,
  };

  closeErrorModal = () =>
    this.setState(prevState => ({ ...prevState, errorModal: false }));

  openErrorModal = () =>
    this.setState(prevState => ({ ...prevState, errorModal: true }));

  render() {
    const { hasHigherFoodCostThanExpected, foodCosts } = this.props;

    if (!hasHigherFoodCostThanExpected) {
      return null;
    }

    return (
      <>
        <Dialog
          maxWidth={'lg'}
          open={this.state.errorModal}
          onClose={this.closeErrorModal}
        >
          <DialogContent>
            <ModalButton
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                cursor: 'pointer',
              }}
              color={'transparent'}
              justIcon
              round
              onClick={this.closeErrorModal}
            >
              &times;
            </ModalButton>
            <ModalButton
              style={{
                position: 'absolute',
                top: '10px',
                right: '50px',
                cursor: 'pointer',
                background: 'rgb(56, 125, 31)',
                color: '#fff',
              }}
              color={'transparent'}
              round
              onClick={this.props.exportFoodCost}
            >
              <GetApp/> {this.props.t('reportArchive.download')}
            </ModalButton>
          </DialogContent>
          <DialogContent>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.foodCost.fields.diet'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.foodCost.fields.variant'
                      )}
                    </TableCell>
                    <TableCell>
                      {this.props.t(
                        'menuPlanner.action.warning.foodCost.fields.calorific'
                      )}
                    </TableCell>
                    <TableCell width={280} style={{ textAlign: 'center' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          {this.props.t(
                            'menuPlanner.action.warning.foodCost.fields.foodCost'
                          )}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4}>
                          {this.props.t(
                            'menuPlanner.action.warning.foodCost.fields.realFoodCost'
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {this.props.t(
                            'menuPlanner.action.warning.foodCost.fields.expectedFoodCost'
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {this.props.t(
                            'menuPlanner.action.warning.foodCost.fields.diffFoodCost'
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(foodCosts).map((el, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell variant={'head'}>{el.diet}</TableCell>
                        <TableCell variant={'head'}>{el.variant}</TableCell>
                        <TableCell>{el.calorific}</TableCell>
                        <TableCell>
                          <Grid container style={{ textAlign: 'center' }}>
                            <Grid item xs={4}>
                              {el.foodCost?.toFixed(2)}
                            </Grid>
                            <Grid item xs={4}>
                              {el.expectedFoodCost}
                            </Grid>
                            <Grid item xs={4} style={{ fontWeight: 600 }}>
                              {Math.round(
                                (el.foodCost - el.expectedFoodCost) * 100
                              ) /
                                100 >
                                0 && (
                                <span style={{ color: '#f44336' }}>
                                  +
                                  {(el.foodCost - el.expectedFoodCost).toFixed(
                                    2
                                  )}
                                </span>
                              )}
                              {Math.round(
                                (el.foodCost - el.expectedFoodCost) * 100
                              ) /
                                100 <=
                                0 && (
                                <span style={{ color: '#387d1f' }}>
                                  {(el.foodCost - el.expectedFoodCost).toFixed(
                                    2
                                  )}
                                </span>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </DialogContent>
        </Dialog>
        <BottomNavigationAction
          label={this.props.t(
            'menuPlanner.action.warning.foodCost.tooltip.actionName'
          )}
          showLabel={true}
          icon={<MonetizationOn style={{ color: '#f65b52' }} />}
          onClick={this.openErrorModal}
        />
      </>
    );
  }
}

export default withTranslation()(
  withStyles(sweetAlertStyle)(withToast(FoodCost))
);
