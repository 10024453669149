import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core/index';
import SelectInput from 'components/FormSelect/SelectInput';
import Button from 'components/CustomButtons/Button.jsx';

import SizeRow from './Size/SizeRow';
import SizeNameRow from './SizeName/SizeNameRow';
import ShopAvailabilityRow from './ShopAvailability/ShopAvailabilityRow';
import ContainerRow from './Container/ContainerRow';
import EcoContainerRow from './EcoContainer/ContainerRow';
import CodeServingRow from './CodeServing/CodeServingRow';
import CaloriesSumRow from './CaloriesSum/CaloriesSumRow';
import WeightSumRow from './WeightSum/WeightSumRow';
import Danger from 'components/Typography/Danger';
import PriceSumRow from './PriceSum/PriceSumRow';
import PriceInputRow from './PriceInput/PriceInputRow';
import ElementSumRow from './ElementSum/ElementSumRow';
import ScaleSizeRow from './ScaleSize/ScaleSizeRow';
import GlycemicIndexSum from './GlycemicIndexSum/GlycemicIndexSumRow';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

class TypeBlock extends React.Component {
  state = {
    copyMealTypeIdFrom: '',
  };

  sortByPosition = collection => {
    return collection.sort((a, b) => {
      return a.size.position - b.size.position;
    });
  };

  handleChange = (e, typeId) => {
    const {
      target: { name, value },
    } = e;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));

    this.props.handleCopySizeChange(e, typeId);
  };

  render() {
    const {
      type,
      dish,
      dishSizes,
      selectedIngredients,
      onChange,
      containers,
      ecoContainers,
      handleContainerChange,
      handleEcoContainerChange,
      classes,
      handleSizeNameChange,
      handleSizeShopAvailabilityChangeRow,
      mealTypeIngredientsSum,
      scaleDishSize,
      shouldRescale,
      setShouldRescale,
      checkedDishToResize,
      setDishToResize,
      selectedBrandMealTypes,
      handleCopySize,
      handlePriceChange,
      handleSizeShopAvailabilityChange,
      t,
    } = this.props;

    const dishSizesByType = dishSizes.filter(dishSize => {
      return dishSize.mealType['@id'] === type['@id'];
    });
    const selectedBrandMealTypesWithoutSelf = selectedBrandMealTypes.filter(
      item => item['@id'] !== type['@id']
    );

    return (
      <div style={{ overflow: 'auto' }}>
        <div style={{ display: 'flex', paddingLeft: '24px' }}>
          <h2 style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            <b>{type.name}</b>
          </h2>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '25px',
            }}
          >
            <div style={{ width: '180px' }}>
              <SelectInput
                classes={classes}
                options={selectedBrandMealTypesWithoutSelf}
                value={this.state.copyMealTypeIdFrom}
                name="copyMealTypeIdFrom"
                mapBy="name"
                trackBy="@id"
                label={t(
                  'mealTypes.copyFromOtherMeal',
                  'Kopiuj z innego posiłku'
                )}
                customLabel={option => `${option.name} (${option.brandName})`}
                handleChange={e => this.handleChange(e, type['@id'])}
                disabled={!selectedBrandMealTypesWithoutSelf.length}
              />
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={handleCopySize}
              disabled={!this.state.copyMealTypeIdFrom}
            >
              {t('common.copy', 'Skopiuj')}
            </Button>
            <Tooltip
              title={
                <h4>
                  <Trans i18nKey="mealTypes.copyRulesInfo">
                    Skopiowane zostaną wartości składników z wielkości o tych
                    samych <b>wymaganych kalorycznościach</b>. np. Śniadanie S
                    (200kcal) można skopiować do II Śniadania XS (200kcal).
                    <br />
                    <br />
                    Jeśli wielkość do której chcemy kopiować nie będzie obecna w
                    wielkości, z której kopiujemy, to wartości nie zostaną
                    zmienione.
                  </Trans>
                </h4>
              }
              placement="right"
            >
              <Info
                fontSize={'small'}
                style={{
                  color: 'grey',
                  marginLeft: '4px',
                }}
              />
            </Tooltip>
          </div>
        </div>

        <Table style={{ marginBottom: '15px' }}>
          <TableHead>
            <TableRow style={{ backgroundColor: 'lightgrey' }}>
              <TableCell style={{ minWidth: '215px' }}>
                {t('columns.name', 'Nazwa')}
              </TableCell>
              <TableCell>
                {t('mealTypes.scaling', 'Skalowanie')}
                <Tooltip
                  title={
                    <Trans i18nKey="mealTypes.scalingNote">
                      <h4>
                        Po wciśnięciu przycisku <b>"Skaluj"</b> wszystkie
                        wielkości zostaną uzupełnione odpowiednio wyskalowaną
                        ilością z pierwszej uzupełnionej przez użytkownika
                        wielkości posiłku.
                        <br />
                        <br />
                        Niezaznaczone składniki zostaną przepisane do
                        pozostałych wielkości bez skalowania (1:1 jak w
                        pierwszej wielkości).
                        <br />
                        <br />
                        Na podstawie ilości podanych w pierwszej wielkości
                        uzupełnia ono resztę z zachowaniem odpowiednich
                        proporcji.
                      </h4>
                    </Trans>
                  }
                  placement="right"
                >
                  <Info
                    fontSize={'small'}
                    style={{
                      color: 'grey',
                      marginLeft: '4px',
                      marginBottom: '-5px',
                    }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell>{t('comments.category', 'Kategoria')}</TableCell>
              {this.sortByPosition(type.sizes).map((size, sizeKey) => {
                return (
                  <TableCell key={sizeKey}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>{size.size.name}</div>
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <SizeNameRow
              classes={classes}
              type={type}
              dish={dish}
              typeSizes={type.sizes}
              dishSizes={dishSizesByType}
              handleSizeNameChange={handleSizeNameChange}
            />
            <ShopAvailabilityRow
              classes={classes}
              type={type}
              dish={dish}
              typeSizes={type.sizes}
              dishSizes={dishSizesByType}
              handleSizeShopAvailabilityChange={
                handleSizeShopAvailabilityChange
              }
              handleSizeShopAvailabilityChangeRow={
                handleSizeShopAvailabilityChangeRow
              }
            />
            {selectedIngredients.length === 0 ? (
              <TableRow>
                <TableCell>
                  <Danger>
                    <b>
                      {t(
                        'mealTypes.noMealIngredietsChoosed',
                        'Nie wybrano żadnych składników dania'
                      )}
                    </b>
                  </Danger>
                </TableCell>
                <TableCell />
                {type.sizes.map((size, sizeKey) => (
                  <TableCell key={sizeKey}>-</TableCell>
                ))}
              </TableRow>
            ) : (
              selectedIngredients.map(
                (selectedIngredient, selectedIngredientKey) => {
                  const key = !selectedIngredient.recipe
                    ? selectedIngredient.ingredient['@id']
                    : selectedIngredient.recipe['@id'];
                  return (
                    <SizeRow
                      key={key}
                      ingredient={selectedIngredient}
                      type={type}
                      mealTypeSizes={type.sizes}
                      mealTypeIngredientsSum={mealTypeIngredientsSum}
                      dishSizes={dishSizesByType}
                      onChange={onChange}
                      shouldRescale={shouldRescale}
                      setShouldRescale={setShouldRescale}
                      checkedDishToResize={checkedDishToResize}
                      setDishToResize={setDishToResize}
                    />
                  );
                }
              )
            )}
            <ScaleSizeRow
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
              scaleDishSize={scaleDishSize}
              hasCheckedDishToResize={checkedDishToResize.length}
            />
            <GlycemicIndexSum
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
            />
            <CaloriesSumRow
              rowType={'sum'}
              classes={classes}
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
              t={t}
            />
            <CaloriesSumRow
              rowType={'required'}
              classes={classes}
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
              t={t}
            />
            <WeightSumRow
              classes={classes}
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
              t={t}
            />
            <ElementSumRow
              element={'protein'}
              elementRowName={t('common.protein', 'Białko')}
              elementConstant={4}
              classes={classes}
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
            />
            <ElementSumRow
              element={'carbohydrates'}
              elementRowName={t('common.carbohydrates', 'Węglowodany')}
              elementConstant={4}
              classes={classes}
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
            />
            <ElementSumRow
              element={'fat'}
              elementRowName={t('common.fats', 'Tłuszcze')}
              elementConstant={9}
              classes={classes}
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
            />
            <ElementSumRow
              element={'roughage'}
              elementRowName={t('common.roughage', 'Błonnik')}
              elementConstant={2}
              classes={classes}
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
            />
            <PriceSumRow
              classes={classes}
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
              t={t}
            />
            <ContainerRow
              classes={classes}
              mealTypeSizes={type.sizes}
              containers={containers}
              dishSizes={dishSizesByType}
              handleContainerChange={handleContainerChange}
              t={t}
            />
            <EcoContainerRow
              classes={classes}
              mealTypeSizes={type.sizes}
              containers={ecoContainers}
              dishSizes={dishSizesByType}
              handleContainerChange={handleEcoContainerChange}
              t={t}
            />
            <CodeServingRow
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
              t={t}
            />
            <PriceInputRow
              classes={classes}
              mealTypeSizes={type.sizes}
              dishSizes={dishSizesByType}
              handlePriceChange={handlePriceChange}
              t={t}
            />
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withTranslation()(TypeBlock);
