import React from 'react';
import {combineStyles} from 'helpers/helpers';
import buttonsStyle
  from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle
  from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import {withToast} from 'material-ui-toast-redux';
import {withTranslation} from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import {Collapse} from '@material-ui/core';

class PriceBlock extends React.Component {
  state = {
    detailsOpened: false,
  };

  render() {
    const {price, t} = this.props;
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <table className={'table'}
                   style={{width: '100%', textAlign: 'center'}}>
              <thead>
              <tr>
                <th>{t('subscription.priceBeforeDiscount')}</th>
                <th>{t('subscription.priceDiscount')}</th>
                <th>{t('subscription.priceAfterDiscount')}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{price.beforeDiscount.toFixed(2)}</td>
                <td>{price.discount.toFixed(2)}</td>
                <td>{price.afterDiscount.toFixed(2)}</td>
              </tr>
              </tbody>
            </table>
          </GridItem>
          <GridItem xs={12} style={{textAlign: 'center'}}>
            <hr style={{margin: '8px 0'}}/>
            <span onClick={() => this.setState((prevState) => ({
              ...prevState,
              detailsOpened: !prevState.detailsOpened,
            }))} style={{cursor: 'pointer'}}>{t('basket.priceDetails.showHide')}</span>
          </GridItem>
          <GridItem xs={12}>
            <Collapse in={this.state.detailsOpened} style={{with: '100%'}}>
              <>
                <GridContainer>
                  <GridItem xs={6}>
                    {Object.keys(price.priceParts).length > 0 && (<>
                      <span><b>{t('basket.priceDetails.costInfo')}:</b></span>
                      <table style={{width: '100%'}}>
                        <tbody>
                        {Object.keys(price.priceParts).
                          map((el, index) => (
                            <p key={'o_' + index}><b>{t(`basket.priceDetails.costInfo.${el}`)}</b>:
                              +{price.priceParts[el].toFixed(
                                2)}</p>))}
                        </tbody>
                      </table>
                    </>)}
                  </GridItem>
                  <GridItem xs={6}>
                    {Object.keys(price.discountParts).length > 0 && (<>
                      <span><b>{t('basket.priceDetails.discountInfo')}:</b></span>
                      {Object.keys(price.discountParts).
                        map((el, index) => (
                          <p key={'i_' + index}><b>{t(`basket.priceDetails.discountInfo.${el}`)}</b>:
                            -{price.discountParts[el].toFixed(
                              2)}</p>))}
                      <table style={{width: '100%'}}>
                        <tbody>
                        </tbody>
                      </table>
                    </>)}
                  </GridItem>
                </GridContainer>
              </>
            </Collapse>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

export default withTranslation()(
  withStyles(combinedStyles)(withToast(PriceBlock)),
);
