import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const SalesManagoModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  const termsSupportTypes = [
    { value: 'STANDARD', label: 'Tryb standard tj. oznaczanie opt in/out' },
    {
      value: 'TAG',
      label:
        'Tryb zgoda poprzez tag tj. tagowanie użytkowników którzy wyrazili zgody marketingowe',
    },
  ];

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'PayUModule',
        })}
      </div>
    );
  }

  return !module ? (
    <div>
      {t('brands.newBrandForm.moduleNotLoaded', {
        defaultValue: '{{moduleName}} not loaded.',
        replace: { moduleName: __filename.split('.')[0] },
      })}
    </div>
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.enabled}
                onChange={e =>
                  handleModuleUpdate({
                    ...module,
                    enabled: !module.enabled,
                  })
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t(
              'brands.newBrandForm.useSalesMangoLabel',
              'Używaj Sales Manago'
            )}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextInput
            label={t('brands.newBrandForm.salesMango.endpoint', 'Endpoint')}
            classes={classes}
            name="salesManagoEndpoint"
            value={module.endpoint}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                endpoint: e.target.value,
              })
            }
            inputSize={6}
          />
          <FormTextInput
            label={t('brands.newBrandForm.salesMango.clientId', 'ID klienta')}
            classes={classes}
            name="salesManagoClientId"
            value={module.clientId}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                clientId: e.target.value,
              })
            }
            inputSize={6}
          />
          <FormTextInput
            label="API Secret"
            classes={classes}
            name="salesManagoApiSecret"
            value={module.apiSecret}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiSecret: e.target.value,
              })
            }
            inputSize={6}
          />
          <FormTextInput
            label="MicroSite Key"
            classes={classes}
            name="salesManagoApiKey"
            value={module.apiKey}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiKey: e.target.value,
              })
            }
            inputSize={6}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextInput
            label={t(
              'brands.newBrandForm.salesMango.email',
              'Sales Manago E-mail'
            )}
            classes={classes}
            name="salesManagoEmail"
            value={module.email}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                email: e.target.value,
              })
            }
            inputSize={6}
          />

          <FormTextInput
            label={
              <div style={{ display: 'flex' }}>
                <span>
                  {t('brands.newBrandForm.salesMango.location', 'Lokalizacja')}
                </span>
                <div
                  style={{
                    marginLeft: '10px',
                    display: 'inline-block',
                  }}
                >
                  <Tooltip
                    title={
                      <div>
                        <h4>
                          {t(
                            'brands.newBrandForm.salesMango.locationTooltip',
                            'Wprowadź nazwę cateringu jaka ma pokazywać się w SalesManago'
                          )}
                        </h4>
                      </div>
                    }
                    placement="right"
                  >
                    <Info fontSize={'small'} />
                  </Tooltip>
                </div>
              </div>
            }
            classes={classes}
            name="salesManagoName"
            value={module.name}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                name: e.target.value,
              })
            }
            inputSize={6}
          />

          <SelectInput
            noGrid
            classes={classes}
            mapBy="label"
            trackBy="value"
            name="salesManagoTermsSupportType"
            value={module.termsSupportType}
            options={termsSupportTypes}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                termsSupportType: e.target.value,
              })
            }
            size={12}
          />
          <FormTextInput
            label={t(
              'brands.newBrandForm.salesMango.tagForConsents',
              'Tag dla zgód'
            )}
            classes={classes}
            name="salesManagoTermsTag"
            value={module.termsTag}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                email: e.target.value,
              })
            }
            inputSize={6}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};

export default SalesManagoModule;
