import { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Input from '@material-ui/core/Input';

import S from './TreeNode.styles';

const TreeNode = ({
  children,
  label,
  indent,
  onChange = () => {},
  onDeleteNode = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialValue, setInitialValue] = useState(label);

  const handleClickClear = () => {
    onChange({ target: { value: initialValue } });
    setIsEditing(false);
  };

  return (
    <S.TreeNodeWrapper indent={indent}>
      <S.TreeNode>
        <div
          style={{ display: 'flex', 'align-items': 'center' }}
          onClick={() => {
            !isEditing && setIsExpanded(prevState => !prevState);
          }}
        >
          {children && children[0] !== false && !isEditing ? (
            <span>
              <KeyboardArrowDownIcon
                style={{
                  transform: isExpanded ? 'rotate(0)' : 'rotate(-90deg)',
                  transition: 'all 0.1s linear',
                }}
              />
            </span>
          ) : (
            <span>
              <KeyboardArrowDownIcon
                style={{
                  visibility: 'hidden',
                }}
              />
            </span>
          )}
          {isEditing ? (
            <Input value={label} onChange={onChange} />
          ) : (
            <p>{label}</p>
          )}
        </div>
        <span
          onClick={() => {
            setIsEditing(prevState => !prevState);
          }}
        >
          {isEditing ? (
            <CheckIcon style={{ color: 'rgb(76, 175, 80)' }} />
          ) : (
            <EditIcon style={{ color: '#ff9800', 'font-size': '20px' }} />
          )}
        </span>
        {isEditing && (
          <span onClick={handleClickClear}>
            <ClearIcon style={{ color: 'rgb(244, 67, 54)' }} />
          </span>
        )}
        {!isEditing && (
          <span onClick={onDeleteNode}>
            <DeleteForeverIcon style={{ color: 'rgb(244, 67, 54)' }} />
          </span>
        )}
      </S.TreeNode>
      {isExpanded && children}
    </S.TreeNodeWrapper>
  );
};

export default TreeNode;
