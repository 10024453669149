import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { withTranslation } from 'react-i18next';

import AdminTable from 'layouts/AdminTable';

import { isGranted } from 'helpers/helpers';

import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import ImagePreviewCustomAction from 'components/ImagePreviewCustomAction/ImagePreviewCustomAction';

import columnConfig from '../columnConfig';
import {
  ROLE_CREATE_DISH,
  ROLE_EDIT_MODULE_DISH_PDF_GENERATOR,
  ROLE_SHOW_MODULE_DISH_PDF_GENERATOR,
} from 'helpers/roles';

class List extends Component {
  newSize = () => this.props.history.push(`${window.location.pathname}/add`);
  settings = () =>
    this.props.history.push(`${window.location.pathname}/settings`);

  customActions = row => {
    if (isEmpty(row?.productionImage)) {
      return;
    }

    return [
      {
        action: () => {},
        icon: <ImagePreviewCustomAction image={row?.productionImage} />,
        color: 'primary',
        simple: true,
        round: false,
      },
    ];
  };

  render() {
    const { classes } = this.props;
    const canEditSettings =
      isGranted(ROLE_EDIT_MODULE_DISH_PDF_GENERATOR) || true;
    const canSeeSettings =
      isGranted(ROLE_SHOW_MODULE_DISH_PDF_GENERATOR) || true;

    return (
      <AdminTable title={this.props.t('list.mealsList')} icon>
        {isGranted(ROLE_CREATE_DISH) && (
          <>
            <FormControlButtons
              classes={classes}
              handleSubmit={this.newSize}
              submitText={this.props.t('form.addMeal') + ' +'}
              secondarySubmitText={this.props.t(
                'views.recipeDatabase.meal.mealPdfSettings'
              )}
              secondarySubmitDisabled={!(canSeeSettings || canEditSettings)}
              handleSecondarySubmit={this.settings}
            />
          </>
        )}

        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/dishes"
          reportName={'dish'}
          columns={columnConfig}
          role="DISH"
          customActions={this.customActions}
          manipulateQuery={(table, query) => {
            query['properties'] = [
              'id',
              'nameForClient',
              'workingName',
              'rateAVG',
              'productionImage',
            ];
            query['properties[productionImage]'] = ['contentUrl'];
            query['properties[tags]'] = ['value'];
            query['properties[components][recipe]'] = ['workName', 'ingredients'];
            query['properties[components][ingredient]'] = ['name', 'workName'];
            query['properties[types]'] = ['name'];
            query['properties[components][recipe][ingredients]'] = ['ingredient'];
            query['properties[components][recipe][ingredients][ingredient]'] = ['name', 'workName'];

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

export default withTranslation()(withStyles(buttonsStyle)(List));
