import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormLabel,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const FakturowniaModule = ({
  classes,
  handleModuleUpdate,
  module,
  handleSubmit,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const integrationOptions = useMemo(
    () => [
      {
        label: t(
          'module.Fakturownia.integrationOption.invoice',
          'Generuj faktury'
        ),
        value: 'INVOICE',
      },
      {
        label: t(
          'module.Fakturownia.integrationOption.receipt',
          'Generuj paragony'
        ),
        value: 'RECEIPT',
      },
    ],
    [language]
  );

  const handleCheckboxClick = () => {
    if (module.integrationType === 'DISABLED') {
      handleModuleUpdate({
        ...module,
        integrationType: 'INVOICE',
      });
    } else {
      handleModuleUpdate({
        ...module,
        integrationType: 'DISABLED',
      });
    }
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'FakturowniaModule',
        })}
      </div>
    );
  }

  return (
    <>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={module.allowInvoicesForClient}
                    onChange={() =>
                      handleModuleUpdate({
                        ...module,
                        allowInvoicesForClient: !module.allowInvoicesForClient,
                      })
                    }
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t(
                  'brands.newBrandForm.showOption',
                  'Pokaż opcję faktur na panelu klienta'
                )}
              />
            </GridItem>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={module.mergeSamePositions}
                    onChange={() =>
                      handleModuleUpdate({
                        ...module,
                        mergeSamePositions: !module.mergeSamePositions,
                      })
                    }
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t(
                  'brands.newBrandForm.mergeSamePositions',
                  'Scal identyczne pozycje na fakturze w jedną'
                )}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      module.integrationType === 'INVOICE' ||
                      module.integrationType === 'RECEIPT'
                    }
                    onChange={handleCheckboxClick}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={'Fakturownia.pl'}
              />
            </GridItem>
            <GridItem md={6}>
              <GridContainer>
                <GridItem md={12} style={{ height: '65px' }}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'module.Fakturownia.integrationType.label',
                      'Tryb integracji'
                    )}
                  </FormLabel>
                  <SelectInput
                    noGrid
                    classes={classes}
                    mapBy="label"
                    trackBy="value"
                    name="integrationType"
                    value={module.integrationType}
                    options={integrationOptions}
                    handleChange={e =>
                      handleModuleUpdate({
                        ...module,
                        integrationType: e.target.value,
                      })
                    }
                    size={12}
                    disabled={module.integrationType === 'DISABLED'}
                  />
                </GridItem>
                <GridItem md={12} style={{ height: '65px' }}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brands.newBrandForm.host', 'Host ')}
                  </FormLabel>
                  <FormTextInputNoGrid
                    classes={classes}
                    name="host"
                    value={module.host}
                    maxLength={64}
                    handleChange={e =>
                      handleModuleUpdate({
                        ...module,
                        host: e.target.value,
                      })
                    }
                  />
                </GridItem>
                <GridItem md={12} style={{ height: '65px' }}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brands.newBrandForm.token', 'Token ')}
                  </FormLabel>
                  <FormTextInputNoGrid
                    classes={classes}
                    name="token"
                    value={module.token}
                    maxLength={64}
                    handleChange={e =>
                      handleModuleUpdate({
                        ...module,
                        token: e.target.value,
                      })
                    }
                  />
                </GridItem>
                <GridItem md={12} style={{ height: '65px' }}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t('brands.newBrandForm.department', 'Departament ')}
                  </FormLabel>
                  <FormTextInputNoGrid
                    classes={classes}
                    name="department"
                    value={module.department}
                    maxLength={64}
                    handleChange={e => {
                      if (!isNaN(e.target.value)) {
                        handleModuleUpdate({
                          ...module,
                          department: e.target.value,
                        });
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} style={{ height: '65px' }}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'brands.newBrandForm.dishNameOnInvoice',
                      'Nazwy dań na fakturze'
                    )}
                  </FormLabel>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={module.dishesCustomNameEnabled}
                      onChange={() =>
                        handleModuleUpdate({
                          ...module,
                          dishesCustomNameEnabled: !module.dishesCustomNameEnabled,
                        })
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                      style={{ padding: '11px 14px 9px 0' }}
                    />

                    <FormTextInputNoGrid
                      classes={classes}
                      name="dishesCustomName"
                      value={module.dishesCustomName}
                      maxLength={255}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          dishesCustomName: e.target.value,
                        })
                      }
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={6}>
              <GridContainer>
                <GridItem md={12} style={{ height: '65px' }}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'brands.newBrandForm.productName',
                      'Nazwa pozycji produktu (diety)'
                    )}
                    <Tooltip
                      title={
                        <h4>
                          {t(
                            'brands.newBrandForm.typeOfDiet',
                            'Wartość % VAT jest pobierana z cennika rodzaju diety.'
                          )}
                        </h4>
                      }
                      placement="top"
                    >
                      <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
                    </Tooltip>
                  </FormLabel>
                  <FormTextInputNoGrid
                    classes={classes}
                    name="dietItemName"
                    value={module.dietItemName}
                    maxLength={255}
                    style={{ marginTop: '5px' }}
                    handleChange={e =>
                      handleModuleUpdate({
                        ...module,
                        dietItemName: e.target.value,
                      })
                    }
                  />
                </GridItem>
                <GridItem md={12} style={{ height: '65px' }}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'brands.newBrandForm.deliveryItem',
                      'Nazwa pozycji dostawy'
                    )}
                    <Tooltip
                      title={
                        <h4>
                          {t(
                            'brands.newBrandForm.zoneDelivery',
                            'Wartość % VAT jest pobierana z cennika dostawy '
                          )}
                        </h4>
                      }
                      placement="top"
                    >
                      <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
                    </Tooltip>
                  </FormLabel>
                  <FormTextInputNoGrid
                    classes={classes}
                    name="deliveryItemName"
                    value={module.deliveryItemName}
                    maxLength={255}
                    handleChange={e =>
                      handleModuleUpdate({
                        ...module,
                        deliveryItemName: e.target.value,
                      })
                    }
                  />
                </GridItem>
                <GridItem md={12} style={{ height: '65px' }}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'brands.newBrandForm.dietDayMod',
                      'Nazwa pozycji "modyfikacji dnia diety"'
                    )}
                  </FormLabel>
                  <FormTextInputNoGrid
                    classes={classes}
                    name="bagModificationName"
                    value={module.bagModificationName}
                    maxLength={255}
                    handleChange={e =>
                      handleModuleUpdate({
                        ...module,
                        bagModificationName: e.target.value,
                      })
                    }
                  />
                </GridItem>
                <GridItem md={12} style={{ height: '65px' }}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'module.Fakturownia.bagModificationVat.label',
                      'Stawka VAT pozycji "modyfikacji dnia diety"'
                    )}
                  </FormLabel>
                  <FormTextInputNoGrid
                    classes={classes}
                    name="bagModificationVat"
                    value={module.bagModificationVat}
                    maxLength={255}
                    handleChange={e =>
                      handleModuleUpdate({
                        ...module,
                        bagModificationVat: e.target.value,
                      })
                    }
                  />
                </GridItem>

                <GridItem md={12}>
                  <FormLabel className={classes.labelHorizontal}>
                    {t(
                      'module.Fakturownia.addonsCustomName.label',
                      'Nazwa pozycji dodatki'
                    )}

                    <Tooltip
                      title={
                        <h4>
                          {t(
                            'module.Fakturownia.addonsCustomName.toltip',
                            'Wartość % VAT jest pobierana z dodatków.'
                          )}
                        </h4>
                      }
                      placement="top"
                    >
                      <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
                    </Tooltip>
                  </FormLabel>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={module.addonsCustomNameEnabled}
                      onChange={() =>
                        handleModuleUpdate({
                          ...module,
                          addonsCustomNameEnabled: !module.addonsCustomNameEnabled,
                        })
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                      style={{ padding: '11px 14px 9px 0' }}
                    />

                    <FormTextInputNoGrid
                      classes={classes}
                      name="addonsCustomName"
                      value={module.addonsCustomName}
                      maxLength={255}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          addonsCustomName: e.target.value,
                        })
                      }
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
            <FormControlButtons
              classes={classes}
              discardText={t('common.shared.cancel', 'Anuluj')}
              submitText={t('common.shared.save', 'Zapisz')}
              handleSubmit={handleSubmit}
            />
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

export default FakturowniaModule;
