import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import { setRegionForValidations } from 'actions/I18nSection';

import SelectInput from '../../components/FormSelect/SelectInput';

const transformResponse = response => {
  return response?.map(country => ({
    ...country,
    label: country.name,
    value: country.code,
  }));
};

const I18NSection = ({
  classes,
  countryInformations,
  setSupportedRegions,
  supportedRegions,
  setRegionForValidations,
  t,
}) => {
  if (!countryInformations) return null;

  const countries = transformResponse(countryInformations);

  const supportedRegion = supportedRegions?.[0];

  const getCountryForValidation = countryCode =>
    countries?.find(({ code }) => code === countryCode);

  return (
    <div>
      <FormLabel className={classes.labelHorizontal}>
        {t('brandCfg.postCodeFormat')}
      </FormLabel>
      <SelectInput
        style={{ padding: 0 }}
        noGrid
        classes={classes}
        mapBy="label"
        trackBy="value"
        name={'clientFont'}
        value={supportedRegion}
        options={countries}
        handleChange={event => {
          setSupportedRegions(event.target.value);
          setRegionForValidations(getCountryForValidation(event.target.value));
        }}
      />
    </div>
  );
};

const mapDispatchToProps = {
  setRegionForValidations,
};

const mapStateToProps = state => ({
  countryInformations: state.Brands.countryInformations,
  loading: state.Brands.loading,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
);
export default enhance(I18NSection);
