import React from 'react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import InputsIndex from './InputsIndex';
import produce from 'immer';
import { useTranslation } from 'react-i18next';

const DishDetails = ({ classes, shopModule, setShopModule }) => {
  const { t } = useTranslation();

  const getValueHandlerByStateKey = stateKey => {
    const fieldProps = {
      value: shopModule?.[stateKey],
      handler: () => {
        setShopModule(
          produce(draft => {
            draft[stateKey] = !draft[stateKey];
          })
        );
      },
    };

    return fieldProps;
  };

  const fields = [
    [
      {
        label: t('$*shop.dishDetails.showCalorific', '$$Pokazuj kaloryczność'),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showCalorific'),
      },
      {
        label: t('$*shop.dishDetails.showWeight', '$$Pokazuj wagę'),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showWeight'),
      },
      {
        label: t(
          '$*shop.dishDetails.showMacroIngredients',
          '$$Makroskładniki (białka, węglowodany, tłuszcze)'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showMacroIngredients'),
      },
      {
        label: t('$*shop.dishDetails.showAllergens', '$$Alergeny'),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showAllergens'),
      },
      {
        label: t(
          '$*shop.dishDetails.showGlycemicIndex',
          '$$Indeks glikemiczny'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showGlycemicIndex'),
      },
    ],
    [
      {
        label: t('$*shop.dishDetails.showImage', '$$Zdjęcie dania'),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showImage'),
      },
      {
        label: t('$*shop.dishDetails.showTags', '$$Tagi dla klienta'),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showTags'),
      },
      {
        label: t('$*shop.dishDetails.showRate', '$$Średnia ocena dania'),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showRate'),
      },
      {
        label: t(
          '$*shop.dishDetails.showServingSuggestion',
          '$$Propozycja podania'
        ),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showServingSuggestion'),
      },
      {
        label: t('$*shop.dishDetails.showIngredients', '$$Skład dania'),
        type: 'checkbox',
        ...getValueHandlerByStateKey('showIngredients'),
      },
    ],
  ];

  const [fieldsLeft, fieldsRight] = fields;

  return (
    <Card>
      <CardHeader>
        <h4 className={classes.cardIconTitle}>
          {t('$*shop.dishDetails.optionDishDetails', '$$Opcje szczegółów dań')}
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4}>
            {fieldsLeft.map(field => (
              <GridContainer>
                <GridItem xs={12}>
                  <InputsIndex {...field} />
                </GridItem>
              </GridContainer>
            ))}
          </GridItem>
          <GridItem xs={4}>
            {fieldsRight.map(field => (
              <GridContainer>
                <GridItem xs={12}>
                  <InputsIndex {...field} />
                </GridItem>
              </GridContainer>
            ))}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default DishDetails;
