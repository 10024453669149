import React from 'react';
import roles from 'helpers/roles';
import moment from 'moment';
import FormLabel from '@material-ui/core/FormLabel';
import SelectAll from 'components/SelectAll';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import { get } from 'helpers/apiHelpers';
import { withTranslation } from 'react-i18next';
import CombinedReport from './CombinedReport/CombinedReport';
import GridContainer from '../../components/Grid/GridContainer';
import { Tooltip } from '@material-ui/core';

class RecipeCards extends React.Component {
  state = {
    tags: [],
    selectedTags: [],
    eachRecipeOnNewPage: true,
    includeSubscriptions: false,
  };

  resolveFileName = ({ parameters }) => {
    const title = this.props
      .t('common.mainMenu.reportsRecipeCards')
      .replace(' ', '-');

    if (parameters.length === 1) {
      const { multiplier, dateFrom, dateTo } = parameters[0];

      return `${multiplier}x${title}-${dateFrom}-${dateTo}_(${moment().format(
        'YYYY-MM-DD_HH.mm'
      )})`;
    }

    return `${title}_(${moment().format('YYYY-MM-DD_HH.mm')})`;
  };

  fetchTags = async () => {
    const response = await get('tags', { pagination: false });

    const tags = response['hydra:member'];

    return this.setState({ tags });
  };

  handleTagsChange = async selected => {
    await this.setState({ selectedTags: selected });
  };

  componentDidMount() {
    this.fetchTags();
  }

  render() {
    const { classes, t } = this.props;
    const title = t('reports.reportRecipeCards');

    console.log('Selected tags: ', this.state.selectedTags);
    return (
      <CombinedReport
        title={title}
        additionalParams={{
          eachRecipeOnNewPage: this.state.eachRecipeOnNewPage,
          tags: this.state.selectedTags
            .map(tag => tag.value)
            .filter(tag => tag && tag !== '*'),
        }}
        url="reports/recipes-card"
        mimeTypes={['application/pdf']}
        fileName={this.resolveFileName}
        role={roles.ROLE_SHOW_REPORT_RECIPES_CARD}
        useDateRange={true}
        showMultiplier={true}
        validateBrands={false}
        validateDatePicker={true}
        archiveReportNames={['RECIPES_CARD']}
        useZoneCategories={true}
        reportConfiguration={{
          report: 'RecipeCardCompanyConfiguration',
          fields: [
            {
              size: 12,
              field: 'roundDecimalPoints',
              inputType: 'number',
            },
          ],
        }}
      >
        <GridContainer justify="flex-end">
          <GridItem sm={4}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '2px' }}
            >
              {this.props.t('reports.selectTags')}
            </FormLabel>
            <SelectAll
              className="input-select--tags"
              options={this.state.tags}
              trackBy={'@id'}
              mapBy={'name'}
              optionSelected={this.state.selectedTags}
              customLabel={tag => tag.value}
              handleChange={this.handleTagsChange}
            />
          </GridItem>
          <GridItem sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  className="input-checkbox--recipe-new-page"
                  checked={this.state.eachRecipeOnNewPage}
                  onChange={e => {
                    this.setState({
                      eachRecipeOnNewPage: !this.state.eachRecipeOnNewPage,
                    });
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('reports.recipePerNewPage')}
            />
          </GridItem>
        </GridContainer>
      </CombinedReport>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(RecipeCards);
