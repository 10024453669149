const add = (a, b) => a + b;

export const proteinConstant = 4;
export const carbohydratesConstant = 4;
export const fatConstant = 9;

export const getTotalPercentage = (
  element,
  elementConstant,
  calorific,
  menuPlanners
) => {
  return (
    (100 *
      getTotalMenuPlanners(element, calorific, menuPlanners) *
      elementConstant) /
      getTotalMenuPlanners('calories', calorific, menuPlanners) || 0
  ).toFixed(2);
};

export const getTotalMenuPlanners = (property, calorific, menuPlanners) => {
  return menuPlanners
    .map(row => {
      const mealTypeId = row.mealType.split('/').pop();
      const key = calorific + '_' + mealTypeId;
      if (
        typeof row.sizes === 'undefined' ||
        row.sizes === null ||
        typeof row.sizes[key] === 'undefined' ||
        row.sizes[key] === null
      ) {
        return 0;
      }
      if (property === 'price') {
        return row.sizes[key][property];
      } else {
        return row.sizes[key]['macrosAfterProcessing'][property];
      }
    })
    .reduce(add, 0)
    .toFixed(2);
};

const utils = {
  getTotalMenuPlanners,
  getTotalPercentage,
  constant: {
    protein: proteinConstant,
    carbohydrates: carbohydratesConstant,
    fat: fatConstant,
  },
};

export default utils;
