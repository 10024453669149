import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { orderStyles, singleDayStyles } from '../../styles';
import SelectInput from 'components/FormSelect/SelectInput';
import { openSweetToast } from 'actions/App';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { get, put } from 'helpers/apiHelpers';
import Button from 'components/CustomButtons/Button';
import Accordion from 'components/Accordion/Accordion';
import Datetime from 'react-datetime';
import FormLabel from '@material-ui/core/FormLabel';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import BagChangeModal from './bagChangeModal';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Info } from '@material-ui/icons';
import NewPriceModalContent from './NewPriceModalContent';

const renderAddonElement = onClick => ({ addon, quantity }, index) => {
  const quantityPrefix = quantity > 1 ? `${quantity}x ` : '';
  return (
    <li key={index} style={singleDayStyles.addonElement}>
      <Button
        justIcon
        round
        simple
        onClick={() => onClick(addon?.['@id'])}
        color="danger"
        size="lg"
        className="remove"
      >
        <DeleteForeverIcon />
      </Button>
      <span style={{ wordBreak: 'break-word' }}>
        {quantityPrefix}
        {addon.name}
      </span>
    </li>
  );
};

const renderSuspendButton = ({
  toggleSuspensionOfSelectedDay,
  holdOnButtonCopy,
  isSelectedDaySubscriptionIntent,
  isSuspendLoading,
}) => {
  if (!isSelectedDaySubscriptionIntent) return null;
  if (isSuspendLoading)
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <CircularProgress size={20} />
      </div>
    );
  return (
    <Button color="info" onClick={toggleSuspensionOfSelectedDay}>
      <b>{holdOnButtonCopy}</b>
    </Button>
  );
};

const sweetToastPrompt = function ({ openSweetToast, t, newPrice, price }) {
  return new Promise((resolve, reject) => {
    openSweetToast({
      style: { display: 'block' },
      title: t('menuPlanner.action.warning.groupTitle'),
      content: <NewPriceModalContent newPrice={newPrice} price={price} />,
      showCancel: true,
      onConfirm: resolve,
      onCancel: reject,
      confirmBtnText: t('common.shared.yes', 'Tak'),
      cancelBtnText: t('common.shared.no', 'Nie'),
    });
  });
};

const renderNotAvailableOptionInSubscriptionTooltip = t => {
  return (
    <Tooltip
      title={
        <h4>
          {t(
            'tooltips.subscription.disabledOption',
            'Tej opcji nie można zmienić w dniu subskrypcyjnym.'
          )}
        </h4>
      }
      placement="right"
    >
      <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
    </Tooltip>
  );
};

const SingleDayDetails = ({
  bagInfo,
  match,
  classes,
  variants,
  diets,
  setBagInfo,
  setAddressType,
  addressType,
  pickupPoints,
  clientAddresses,
  openToast,
  openSweetToast,
  fetchingDay,
  selectedDiet,
  selectDiet,
  calendarNeedsUpdate,
  isSelectedDaySubscriptionIntent,
  dietInfo,
  setCalendarNeedsUpdate,
  brandInfo,
  orderInfo,
  removeAddons,
}) => {
  const { t } = useTranslation();
  const WEEKDAY_NAMES_LONG = moment.localeData().weekdays();
  const MONTH_NAMES = moment.localeData().months();

  const applyForRestBags = false;
  const [multipleChange, setMultipleChange] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [createOrder, setCreateOrder] = useState(
    orderInfo.status.systemValue !== 'WAITING_FOR_PAYMENT'
  );
  const [bagChangeModal, setBagChangeModal] = useState(false);
  const [bagChanges, setBagChanges] = useState(null);
  const [pickerDate, setPickerDate] = useState(null);
  const [dietDays, setDietDays] = useState([]);
  const [changedAttributes, setChangedAttributes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuspendLoading, setIsSuspendLoading] = useState(false);
  const [bagSnap, setBagSnap] = useState(null);
  const [addonIdToRemove, setAddonIdToRemove] = useState(false);

  const bagDate = bagInfo ? bagInfo.date : null;

  const isSelectedDaySuspended = bagInfo?.newState === 'SUBSCRIPTION_SUSPENDED';

  useEffect(() => {
    bagDate && setPickerDate(new moment(bagDate));
    bagInfo && setBagSnap(bagInfo);
    setMultipleChange(false);
    setChangeDate(false);
    setChangedAttributes([]);
    setDietDays([]);

    checkIfBagIsComplete(bagInfo);
  }, [bagDate]);

  useEffect(() => {
    if (pickerDate) {
      fetchOnDateChange();
    }
  }, [pickerDate]);

  let datesLength = bagInfo && bagInfo.dates ? bagInfo.dates.length : 0;
  let applyForDatesLength =
    bagInfo && bagInfo.applyForDates ? bagInfo.applyForDates.length : 0;

  const buildDataForSubscriptionChange = () => {
    if (bagInfo === null) return {};
    return changedAttributes.reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: (function (objField) {
          if (objField === null) return null;
          if (objField?.['@id'] === undefined) return bagInfo[curr];
          return bagInfo[curr]?.['@id'];
        })(bagInfo[curr]),
      };
    }, {});
  };

  const checkIfBagIsComplete = bag => {
    const message = {
      show: false,
      mainMessage: t(
        'errors.selectedConfigurationNotSupportedAnymore',
        'UWAGA: Aktualna konfiguracja wybranego dnia nie jest już obsługiwana.'
      ),
      dietMessage: '',
      variantMessage: '',
      calorificMessage: '',
      footerMessage: t(
        'errors.chooseSupportedConfigurationAppointment',
        'Zalecana jest zmiana konfiguracji diety na obsługiwaną.'
      ),
    };

    if (bag) {
      const currentlySelectedDiet = diets.find(
        diet => bag.diet?.['@id'] === diet?.['@id']
      );

      if (!bag.diet && (bag.addons || bag.dishSizeItems)) {
      } else {
        if (
          (currentlySelectedDiet && !currentlySelectedDiet.active) ||
          !bag.diet
        ) {
          message.show = true;
          message.dietMessage = t(
            'errors.dietNotSupported',
            'Wybrana dieta nie jest już obsługiwana'
          );
        }

        if (!bag.variant?.active || !bag.variant) {
          message.show = true;
          message.variantMessage = t(
            'errors.variantNotSupported',
            'Wybrany wariant nie jest już obsługiwany'
          );
        }

        if (!bag.calorific) {
          message.show = true;
          message.calorificMessage = t(
            'errors.calorificNotSupported',
            'Wybrana kaloryczność nie jest już obsługiwana'
          );
        }
      }
    }

    if (message.show) {
      const {
        mainMessage,
        dietMessage,
        variantMessage,
        calorificMessage,
        footerMessage,
      } = message;

      openToast({
        messages: [
          mainMessage,
          dietMessage,
          variantMessage,
          calorificMessage,
          footerMessage,
        ],
        type: 'error',
        autoHideDuration: 20000,
      });
    }
  };

  const toggleSuspensionOfSelectedDay = () => {
    const selectedDate = bagInfo.date;
    const subscriptionId = dietInfo.subscription?.id;
    setIsSuspendLoading(true);
    put(`subscription/${subscriptionId}`, {
      [isSelectedDaySuspended ? 'enableDays' : 'disableDays']: [selectedDate],
    })
      .then(() => {
        setCalendarNeedsUpdate(true);
        openToast({
          messages: [t('success.changesSaved', 'Zmiany zostały zapisane')],
          type: 'success',
          autoHideDuration: 3000,
        });
      })
      .catch(error => {
        openToast({
          messages:
            error.response && error.response.data.violations
              ? [error.response.data.violations[0].message]
              : [t('notify.cannotSave', 'Nie udało się dokonać zmian')],
          type: 'error',
          autoHideDuration: 3000,
        });
      })
      .finally(() => {
        setIsSuspendLoading(false);
      });
  };

  const fetchOnDateChange = () => {
    const dateFormat = 'YYYY-MM-DD';
    const startOfMonth = pickerDate.startOf('month').format(dateFormat);

    const amountOfDaysToSubtract = new moment(startOfMonth).isoWeekday() - 1;

    const firstVisibleDay = new moment(startOfMonth)
      .subtract(amountOfDaysToSubtract, 'days')
      .format(dateFormat);
    const lastVisibleDay = new moment(startOfMonth)
      .subtract(amountOfDaysToSubtract, 'days')
      .add(41, 'days')
      .format(dateFormat);

    return fetchDietDays(firstVisibleDay, lastVisibleDay);
  };

  const fetchDietDays = (firstVisibleDay, lastVisibleDay) => {
    if (firstVisibleDay && lastVisibleDay) {
      const url = `/ecommerce-diets/${match.params.id}/calendar/${firstVisibleDay}/${lastVisibleDay}`;

      get(url).then(res => {
        const daysObject = res.days;

        let dietDaysArray = [];

        Object.keys(daysObject).forEach(day => {
          const formattedDay = new moment(daysObject[day].date).format(
            'YYYY-MM-DD'
          );

          if (
            !dietDays.includes(formattedDay) &&
            daysObject[day].state !== 'NO_DIET' &&
            daysObject[day].state !== 'ADD_DIET' &&
            daysObject[day].state !== 'NOT_DIET_CAN_PLACE_ORDER'
          ) {
            dietDaysArray.push(formattedDay);
          }
        });

        setDietDays([...dietDays, ...dietDaysArray]);
      });
    }
  };

  const addToChangedAttributes = attributes => {
    let newAttributes = [...changedAttributes];
    attributes.forEach(attribute => {
      changedAttributes.indexOf(attribute) === -1 &&
        newAttributes.push(attribute);
    });

    setChangedAttributes(newAttributes);
  };

  const removeMultipleFromChangedAttributes = attributes => {
    let newAttributes = [...changedAttributes];

    attributes.forEach(attribute => {
      newAttributes = newAttributes.filter(
        attrToFilter => attrToFilter !== attribute
      );
    });

    setChangedAttributes(newAttributes);
  };

  const removeFromChangedAttributes = attribute => {
    const newAttributes = changedAttributes.filter(
      changedAttribute => changedAttribute !== attribute
    );

    setChangedAttributes(newAttributes);
  };

  const getCurrentPickerDate = action => {
    if (pickerDate) {
      const currentMoment = new moment(pickerDate);

      switch (action) {
        case 'add':
          currentMoment.add(1, 'month');
          break;

        case 'subtract':
          currentMoment.subtract(1, 'month');
          break;

        default:
          break;
      }

      setPickerDate(currentMoment);
    }
  };

  const getVariantOptions = () => {
    let result = [];
    if (selectedDiet) {
      result = variants.filter(
        variant =>
          selectedDiet.variants.includes(variant?.['@id']) && variant.active
      );
    }
    return result;
  };

  const getCaloriesOptions = () => {
    let calories = [];
    if (bagInfo.variant && bagInfo.variant.active) {
      calories = variants.find(
        variant => variant?.['@id'] === bagInfo.variant?.['@id']
      ).calories;
    }
    return calories;
  };

  const addresses = clientAddresses.map(address => ({
    ...address,
    label: `${address.street} ${address.buildNumber}, ${address.postCode} ${address.city}`,
  }));

  const validateBag = bagInfo => {
    if (!bagInfo.address && !bagInfo.pickUpPoint) {
      openToast({
        messages: [
          t(
            'errors.deliveryOtionRequired',
            'Aby zapisać, wybierz adres lub punkt odbioru'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
      return true;
    } else if (!bagInfo.calorific) {
      openToast({
        messages: [
          t('errors.calorificRequired', 'Aby zapisać, wybierz kaloryczność'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
      return true;
    } else if (multipleChange && !bagInfo.applyForDates) {
      openToast({
        messages: [
          t('errors.selectDayToAmend', 'Wybierz dni, które chcesz zmienić'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
      return true;
    } else if (
      multipleChange &&
      changeDate &&
      bagInfo.dates.length < bagInfo.applyForDates.length
    ) {
      openToast({
        messages: [
          t(
            'errors.datesForDietDaysRequired',
            'Nie wybrano nowych dat, dla wszystkich wybranych dni diety'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
      return true;
    }

    return false;
  };

  const changeDay = bagInfo => {
    setIsLoading(true);

    const isSelectedDaySubscriptionIntent =
      bagInfo['@type'] === 'SubscriptionIntent';

    const requestUrl = isSelectedDaySubscriptionIntent
      ? `/ecommerce-diets/${bagInfo.clientDiet.id}/delivery-days`
      : bagInfo?.['@id'];

    const data = {
      variant: bagInfo.variant?.['@id'],
      calorific: bagInfo.calorific?.['@id'],
      diet: bagInfo.diet?.['@id'],
      address: bagInfo.address ? bagInfo.address?.['@id'] : null,
      pickUpPoint: bagInfo.pickUpPoint ? bagInfo.pickUpPoint?.['@id'] : null,
      optionChangeMenu: bagInfo.optionChangeMenu,
      // date: bagInfo.date,
      dates: multipleChange
        ? bagInfo.dates
          ? bagInfo.dates.map(date => new moment(date).format('YYYY-MM-DD'))
          : []
        : [
            new moment(
              bagInfo.dateToChange ? bagInfo.dateToChange : bagInfo.date
            ).format('YYYY-MM-DD'),
          ],
      applyForDates: multipleChange
        ? bagInfo.applyForDates.map(date =>
            new moment(date).format('YYYY-MM-DD')
          )
        : applyForRestBags
        ? []
        : [new moment(bagInfo.date).format('YYYY-MM-DD')],
      useEcoContainers: bagInfo.useEcoContainers,
      applyForRestDays: applyForRestBags,
      createOrder: createOrder,
      changedAttributes,
    };

    const dataForSubscriptionChange = {
      date: moment(bagDate).format('YYYY-MM-DD'),
      ...buildDataForSubscriptionChange(),
      ...(() => (bagInfo.key ? { costAcceptKey: bagInfo.key } : {}))(),
    };

    put(
      requestUrl,
      isSelectedDaySubscriptionIntent ? dataForSubscriptionChange : data
    )
      .then(res => {
        const firstChangedBag =
          res.bagsChanged?.['hydra:member']?.[0] || res || null;
        setIsLoading(false);
        if (res.order) {
          openToast({
            messages: [
              t('success.changeRequiresSurcharge', 'Zmiana wymaga dopłaty'),
            ],
            type: 'warning',
            autoHideDuration: 5000,
          });

          setBagChanges(res);
          setBagChangeModal(true);
        } else if (res.returnPoints > 0) {
          openToast({
            messages: [
              t(
                'success.changesSaved',
                'Zmiany zostały zapisane'
              )`Zwrócone punkty: ${res.returnPoints}`,
            ],
            type: 'success',
            autoHideDuration: 5000,
          });
          setCalendarNeedsUpdate(!calendarNeedsUpdate);
          setChangedAttributes([]);
          setBagInfo({ ...firstChangedBag, hasChanged: false });
        } else {
          setChangedAttributes([]);
          openToast({
            messages: [t('success.changesSaved', 'Zmiany zostały zapisane')],
            type: 'success',
            autoHideDuration: 3000,
          });

          setCalendarNeedsUpdate(!calendarNeedsUpdate);
          setDietDays([]);
          fetchOnDateChange();
          setBagInfo({ ...firstChangedBag, hasChanged: false });
        }
      })
      .catch(async error => {
        setIsLoading(false);

        if (error.response.data.key) {
          const { newPrice, price, key } = error.response.data || {};
          try {
            await sweetToastPrompt({ openSweetToast, t, newPrice, price });
            changeDay({ ...bagInfo, key });
          } catch (err) {}
        } else {
          openToast({
            messages:
              error.response && error.response.data.violations
                ? [error.response.data.violations[0].message]
                : [t('notify.cannotSave', 'Nie udało się dokonać zmian')],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      });
  };

  const confirmDeleteAddons = async () => {
    await removeAddons(addonIdToRemove);
    setAddonIdToRemove(null);
    openToast({
      messages: [
        t(
          'warnings.clientsMoneybox',
          'Pamiętaj, aby zaktualizować skarbonkę klienta.'
        ),
      ],
      type: 'warning',
      autoHideDuration: 4000,
    });
  };

  const isDietDay = currentDay => {
    return dietDays.includes(currentDay);
  };

  const isDisabledDay = (current, enablePastDays) => {
    let key;
    key = new moment(current).locale('en').format('dddd').toLowerCase();

    const isToday = DateUtils.isSameDay(new Date(current), new Date());
    const isPastDay = enablePastDays
      ? false
      : new Date(current) < new Date() && !isToday;
    const isDisabledInConfigDay = brandInfo[key].shouldBeDeliveredInBlock === 0;
    const isCustomDisabledDay = brandInfo.disabledDays.includes(
      moment(current).format('YYYY-MM-DD') + ' 00:00:00'
    );

    return isDisabledInConfigDay || isPastDay || isCustomDisabledDay;
  };

  const bagHasBeenDelivered =
    bagInfo &&
    !DateUtils.isSameDay(new Date(bagInfo.deliveredIn), new Date()) &&
    new Date(bagInfo.deliveredIn) < new Date();
  const isEditDisabled = orderInfo.status.systemValue === 'CANCELED';

  const selectedDate = React.useMemo(
    () =>
      bagInfo &&
      moment(bagInfo?.dateToChange ? bagInfo.dateToChange : bagInfo.date),
    [bagInfo]
  );

  const isSelectedDaySubscription = bagInfo?.clientDiet?.isSubscription; // counts for Intents and Bags made from intents

  const holdOnButtonCopy = t(
    isSelectedDaySuspended ? 'subscription.resume' : 'subscription.hold'
  );

  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {fetchingDay && <CircularProgress size={100} />}
      </div>
      <div
        style={{
          position: 'relative',
          right: fetchingDay || false ? '2000px' : '0',
          transition: '0.5s all',
        }}
      >
        <div
          style={orderStyles.box}
          onClick={() => {
            if (isEditDisabled)
              return openToast({
                messages: [
                  'Zamówienie ma status Anulowane.',
                  'Nie możesz modyfikować anulowanego zamówienia.',
                ],
                type: 'info',
                autoHideDuration: 4000,
              });
            else if (bagHasBeenDelivered) {
              return openToast({
                messages: [
                  'Torba została już dostarczona.',
                  'Nie możesz modyfikować dostarczonej torby.',
                ],
                type: 'info',
                autoHideDuration: 4000,
              });
            }
          }}
        >
          {bagInfo ? (
            <h1
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <b>
                {moment(bagInfo.date).format(
                  moment.localeData().longDateFormat('L')
                )}
              </b>
              {renderSuspendButton({
                toggleSuspensionOfSelectedDay,
                holdOnButtonCopy,
                isSelectedDaySubscriptionIntent,
                isSuspendLoading,
              })}
            </h1>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <h3>
                <Trans i18nKey="singleDayDetails.chooseDietDay">
                  Wybierz dzień diety, aby zobaczyć <b>szczegóły</b>
                </Trans>
              </h3>
            </div>
          )}
          {bagInfo && bagInfo.diet && !isSelectedDaySuspended && (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="none">
                      {t('clients.edit', 'Edytuj')}
                    </TableCell>
                    <TableCell padding="none">
                      {t('common.attribute', 'Atrybut')}
                    </TableCell>
                    <TableCell align="right" padding="none">
                      {t('form.value', 'Wartość')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEditDisabled && !bagHasBeenDelivered && (
                    <TableRow>
                      <TableCell padding="none">
                        <Checkbox
                          id={'multipleChangeCheck'}
                          checked={multipleChange}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscription
                          }
                          onChange={() => {
                            setBagInfo({
                              ...bagInfo,
                              applyForDates: [new Date(bagInfo.date)],
                            });
                            setPickerDate(new moment(bagInfo.date));
                            setMultipleChange(!multipleChange);
                            setChangeDate(false);
                            removeFromChangedAttributes('date');
                          }}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <FormLabel
                          htmlFor={'multipleChangeCheck'}
                          className={classes.labelHorizontal}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                        >
                          {t(
                            'singleDayDetails.changeMultipleMonths',
                            'Zmień wiele dni'
                          )}
                          {isSelectedDaySubscription &&
                            renderNotAvailableOptionInSubscriptionTooltip(t)}
                        </FormLabel>
                      </TableCell>
                      <TableCell align="right" padding="none"></TableCell>
                    </TableRow>
                  )}
                  {!multipleChange && !bagInfo.clientDiet?.isSubscription && (
                    <TableRow>
                      <TableCell padding="none">
                        <Checkbox
                          id="dateCheck"
                          checked={changedAttributes.includes('date')}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                          onChange={() => {
                            setBagInfo({ ...bagInfo, hasChanged: true });
                            !changedAttributes.includes('date')
                              ? addToChangedAttributes(['date'])
                              : removeFromChangedAttributes('date');
                          }}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>

                      <TableCell padding="none">
                        <FormLabel
                          htmlFor="dateCheck"
                          className={classes.labelHorizontal}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                        >
                          {t('singleDayDetails.bagDate', 'Data torby')}
                          {isSelectedDaySubscriptionIntent &&
                            renderNotAvailableOptionInSubscriptionTooltip(t)}
                        </FormLabel>
                      </TableCell>
                      <TableCell align="right" padding="none">
                        <Datetime
                          className={'ecommerceDietPicker'}
                          timeFormat={false}
                          dateFormat={moment.localeData().longDateFormat('L')}
                          closeOnSelect={false}
                          onNavigateBack={() => {
                            getCurrentPickerDate('subtract');
                          }}
                          onNavigateForward={() => {
                            getCurrentPickerDate('add');
                          }}
                          value={selectedDate}
                          isValidDate={current => !isDisabledDay(current, true)}
                          onChange={ev => {
                            if (
                              !DateUtils.isSameDay(new Date(ev), new Date()) &&
                              isDietDay(ev.format('YYYY-MM-DD'))
                            ) {
                              return openToast({
                                messages: [
                                  t(
                                    'singleDayDetails.thereIsAnotherBagForThisDay',
                                    'Na ten dzień jest już zaplanowana torba'
                                  ),
                                ],
                                type: 'warning',
                                autoHideDuration: 4000,
                              });
                            }
                            addToChangedAttributes(['date']);
                            setBagInfo({
                              ...bagInfo,
                              dateToChange: ev.format('YYYY-MM-DD'),
                              hasChanged: true,
                            });
                          }}
                          inputProps={{
                            readOnly: true,
                            id: 'date',
                            disabled:
                              isEditDisabled ||
                              bagHasBeenDelivered ||
                              isSelectedDaySubscriptionIntent,
                          }}
                          renderDay={(props, currentDate, selected) => {
                            if (isDietDay(currentDate.format('YYYY-MM-DD'))) {
                              props.className += ' isDietDay';
                            }

                            return (
                              <td {...props}>
                                <div
                                // className={customDayStyle}
                                >
                                  {currentDate.date()}
                                </div>
                              </td>
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}

                  {multipleChange && (
                    <TableRow>
                      <TableCell padding="none" colSpan={3}>
                        <div
                          style={{
                            maxWidth: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <FormLabel className={classes.labelHorizontal}>
                            {t(
                              'singleDayDetails.chooseDaysToApplyChanges',
                              'Wybierz daty, dla których zastosować zmiany'
                            )}
                          </FormLabel>
                          <DayPicker
                            style={{ margin: 0 }}
                            fromMonth={new Date()}
                            months={MONTH_NAMES}
                            month={new Date(bagInfo.date)}
                            weekdaysLong={WEEKDAY_NAMES_LONG}
                            weekdaysShort={moment.localeData().weekdaysMin()}
                            firstDayOfWeek={1}
                            selectedDays={bagInfo.applyForDates}
                            onMonthChange={monthStart =>
                              setPickerDate(new moment(monthStart))
                            }
                            disabledDays={day =>
                              moment(day).isBefore(moment()) ||
                              !isDietDay(new moment(day).format('YYYY-MM-DD'))
                            }
                            onDayClick={(day, { selected }) => {
                              if (
                                DateUtils.isSameDay(new Date(bagInfo.date), day)
                              ) {
                                return openToast({
                                  messages: [
                                    t(
                                      'cantDeselectCurrentyEditedDay',
                                      'Nie można odznaczyć dnia, który edytujesz.'
                                    ),
                                  ],
                                  type: 'warning',
                                  autoHideDuration: 3000,
                                });
                              }

                              const dayToApplyToIsDisabled =
                                moment(day).isBefore(moment()) ||
                                !isDietDay(
                                  new moment(day).format('YYYY-MM-DD')
                                );

                              if (dayToApplyToIsDisabled) {
                                return;
                              }

                              const selectedDays = bagInfo.applyForDates
                                ? bagInfo.applyForDates
                                : [];

                              if (selected) {
                                const selectedIndex = selectedDays.findIndex(
                                  selectedDay =>
                                    DateUtils.isSameDay(selectedDay, day)
                                );
                                selectedDays.splice(selectedIndex, 1);
                              } else {
                                selectedDays.push(day);
                              }

                              setBagInfo({
                                ...bagInfo,
                                applyForDates: selectedDays,
                                hasChanged: true,
                              });
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell padding="none"></TableCell>
                      <TableCell align="right" padding="none"></TableCell>
                    </TableRow>
                  )}

                  {multipleChange && (
                    <TableRow>
                      <TableCell padding="none">
                        <Checkbox
                          id="datesChangeCheck"
                          checked={changeDate}
                          onChange={() => {
                            setBagInfo({
                              ...bagInfo,
                              dates: [],
                              hasChanged: true,
                            });
                            removeFromChangedAttributes('date');
                            setPickerDate(new moment(new Date()));
                            setChangeDate(!changeDate);
                          }}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <FormLabel
                          htmlFor="datesChangeCheck"
                          className={classes.labelHorizontal}
                        >
                          {t(
                            'singleDayDetails.changeBagsDays',
                            'Zmień daty toreb'
                          )}
                        </FormLabel>
                      </TableCell>
                      <TableCell align="right" padding="none"></TableCell>
                    </TableRow>
                  )}
                  {changeDate && (
                    <TableRow>
                      <TableCell padding="none" colSpan={3}>
                        <div
                          style={{
                            maxWidth: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <FormLabel className={classes.labelHorizontal}>
                            <Trans i18nKey="singleDayDetails.chooseNewDates">
                              Wybierz nowe daty: (wybrano {datesLength} z{' '}
                              {applyForDatesLength})
                            </Trans>
                          </FormLabel>
                          <DayPicker
                            // fromMonth={new Date()}
                            months={MONTH_NAMES}
                            weekdaysLong={WEEKDAY_NAMES_LONG}
                            weekdaysShort={moment.localeData().weekdaysMin()}
                            firstDayOfWeek={1}
                            selectedDays={bagInfo.dates}
                            onMonthChange={monthStart =>
                              setPickerDate(new moment(monthStart))
                            }
                            disabledDays={day =>
                              isDisabledDay(day, true) ||
                              isDietDay(new moment(day).format('YYYY-MM-DD'))
                            }
                            onDayClick={(day, { selected }) => {
                              if (
                                !selected &&
                                applyForDatesLength > 0 &&
                                datesLength === applyForDatesLength
                              ) {
                                return openToast({
                                  messages: [
                                    t(
                                      'singleDayDetails.allDaysHaveBeenUsedUp',
                                      'Wykorzystano już wszystkie dni.'
                                    ),
                                    t(
                                      'singleDayDetails.unselectSomeDaysToChooseAnother',
                                      'Aby zaznaczyć inne dni, odznacz wybrane'
                                    ),
                                  ],
                                  type: 'info',
                                  autoHideDuration: 3000,
                                });
                              }

                              const targetDayIsDisabled =
                                isDisabledDay(day, true) ||
                                isDietDay(new moment(day).format('YYYY-MM-DD'));

                              if (targetDayIsDisabled) {
                                return;
                              }

                              addToChangedAttributes(['date']);
                              const selectedDays = bagInfo.dates
                                ? bagInfo.dates
                                : [];

                              if (selected) {
                                const selectedIndex = selectedDays.findIndex(
                                  selectedDay =>
                                    DateUtils.isSameDay(selectedDay, day)
                                );
                                selectedDays.splice(selectedIndex, 1);
                              } else {
                                selectedDays.push(day);
                              }

                              setBagInfo({
                                ...bagInfo,
                                dates: selectedDays,
                                hasChanged: true,
                              });
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell padding="none"></TableCell>
                      <TableCell align="right" padding="none"></TableCell>
                    </TableRow>
                  )}
                  {brandInfo.premiumType === 'PREMIUM' && (
                    <TableRow>
                      <TableCell padding="none">
                        <Checkbox
                          id="optionChangeMenuCheck"
                          checked={changedAttributes.includes(
                            'optionChangeMenu'
                          )}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          onChange={() => {
                            setBagInfo({ ...bagInfo, hasChanged: true });
                            !changedAttributes.includes('optionChangeMenu')
                              ? addToChangedAttributes(['optionChangeMenu'])
                              : removeFromChangedAttributes('optionChangeMenu');
                          }}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <FormLabel
                          htmlFor="optionChangeMenuCheck"
                          className={classes.labelHorizontal}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                        >
                          {t(
                            'singleDayDetails.menuChangeAvailability',
                            'Możliwość zmiany menu'
                          )}
                          {isSelectedDaySubscriptionIntent &&
                            renderNotAvailableOptionInSubscriptionTooltip(t)}
                        </FormLabel>
                      </TableCell>
                      <TableCell align="right" padding="none">
                        <Checkbox
                          style={{ marginLeft: '-14px' }}
                          disabled={
                            isEditDisabled ||
                            bagHasBeenDelivered ||
                            isSelectedDaySubscriptionIntent
                          }
                          checked={bagInfo.optionChangeMenu}
                          onChange={() => {
                            addToChangedAttributes(['optionChangeMenu']);
                            setBagInfo({
                              ...bagInfo,
                              optionChangeMenu: !bagInfo.optionChangeMenu,
                              hasChanged: true,
                            });
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {brandInfo.ecoContainer && (
                    <TableRow>
                      <TableCell padding="none">
                        <Checkbox
                          id="ecoContainersCheck"
                          checked={changedAttributes.includes(
                            'useEcoContainers'
                          )}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          onChange={() => {
                            setBagInfo({ ...bagInfo, hasChanged: true });
                            !changedAttributes.includes('useEcoContainers')
                              ? addToChangedAttributes(['useEcoContainers'])
                              : removeFromChangedAttributes('useEcoContainers');
                          }}
                          icon={<Check className={classes.uncheckedIcon} />}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <FormLabel
                          htmlFor="ecoContainersCheck"
                          className={classes.labelHorizontal}
                        >
                          {t(
                            'singleDayDetails.useEcoPacks',
                            'Użyj eko-opakowań'
                          )}
                        </FormLabel>
                      </TableCell>
                      <TableCell align="right" padding="none">
                        <Checkbox
                          style={{ marginLeft: '-14px' }}
                          checked={bagInfo.useEcoContainers}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          onChange={() => {
                            addToChangedAttributes(['useEcoContainers']);
                            setBagInfo({
                              ...bagInfo,
                              useEcoContainers: !bagInfo.useEcoContainers,
                              hasChanged: true,
                            });
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell padding="none">
                      <Checkbox
                        id="dietChangeCheck"
                        checked={changedAttributes.includes('diet')}
                        disabled={isEditDisabled || bagHasBeenDelivered}
                        onChange={() => {
                          setBagInfo({ ...bagInfo, hasChanged: true });
                          !changedAttributes.includes('diet')
                            ? addToChangedAttributes([
                                'diet',
                                'variant',
                                'calorific',
                              ])
                            : removeMultipleFromChangedAttributes([
                                'diet',
                                'variant',
                                'calorific',
                              ]);
                        }}
                        icon={<Check className={classes.uncheckedIcon} />}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    </TableCell>
                    <TableCell padding="none">
                      <div style={{ padding: '10px 0' }}>
                        <FormLabel
                          htmlFor="dietChangeCheck"
                          className={classes.labelHorizontal}
                        >
                          {t('diets.diet', 'Dieta')}
                        </FormLabel>
                      </div>
                      <div style={{ padding: '10px 0' }}>
                        <FormLabel
                          htmlFor="dietChangeCheck"
                          className={classes.labelHorizontal}
                        >
                          {t('diets.variant', 'Wariant')}
                        </FormLabel>
                      </div>
                      <div style={{ padding: '10px 0' }}>
                        <FormLabel
                          htmlFor="dietChangeCheck"
                          className={classes.labelHorizontal}
                        >
                          {t('diets.calories', 'Kaloryczność')}
                        </FormLabel>
                      </div>
                    </TableCell>
                    <TableCell align="right" padding="none">
                      <div
                        style={{
                          textAlign: 'left',
                          maxWidth: '140px',
                        }}
                      >
                        <SelectInput
                          noGrid
                          classes={classes}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          mapBy="name"
                          trackBy="@id"
                          options={diets.filter(diet => diet.active)}
                          value={bagInfo.diet}
                          handleChange={(ev, obj) => {
                            addToChangedAttributes([
                              'diet',
                              'variant',
                              'calorific',
                            ]);
                            setBagInfo({
                              ...bagInfo,
                              diet: obj,
                              variant: null,
                              calorific: null,
                              hasChanged: true,
                            });
                            selectDiet(obj);
                          }}
                        />
                        <SelectInput
                          noGrid
                          classes={classes}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          mapBy="name"
                          trackBy="@id"
                          label={'Wariant'}
                          options={getVariantOptions()}
                          value={bagInfo.variant}
                          handleChange={(ev, obj) => {
                            addToChangedAttributes([
                              'diet',
                              'variant',
                              'calorific',
                            ]);
                            setBagInfo({
                              ...bagInfo,
                              variant: obj,
                              calorific: null,
                              hasChanged: true,
                            });
                          }}
                          size={12}
                        />
                        <SelectInput
                          noGrid
                          classes={classes}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          mapBy="name"
                          trackBy="@id"
                          options={getCaloriesOptions()}
                          value={bagInfo.calorific}
                          handleChange={(ev, obj) => {
                            addToChangedAttributes([
                              'diet',
                              'variant',
                              'calorific',
                            ]);
                            setBagInfo({
                              ...bagInfo,
                              calorific: obj,
                              hasChanged: true,
                            });
                          }}
                          size={12}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                  {bagInfo.addons?.length > 0 && (
                    <TableRow>
                      <TableCell padding="none" colSpan={3}>
                        <p style={singleDayStyles.addonsTitle}>Dodatki</p>
                        <ul style={{ margin: 0, padding: 0 }}>
                          {bagInfo.addons.map(
                            renderAddonElement(setAddonIdToRemove)
                          )}
                        </ul>
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell padding="none">
                      <Checkbox
                        checked={
                          changedAttributes.includes('address') ||
                          changedAttributes.includes('pickUpPoint')
                        }
                        disabled={isEditDisabled || bagHasBeenDelivered}
                        onChange={() => {
                          setBagInfo({ ...bagInfo, hasChanged: true });
                          !changedAttributes.includes('address') ||
                          !changedAttributes.includes('pickUpPoint')
                            ? addToChangedAttributes(['address', 'pickUpPoint'])
                            : removeMultipleFromChangedAttributes([
                                'address',
                                'pickUpPoint',
                              ]);
                        }}
                        icon={<Check className={classes.uncheckedIcon} />}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    </TableCell>
                    <TableCell padding="none" colSpan={2}>
                      <FormControlLabel
                        control={
                          <Radio
                            disabled={isEditDisabled || bagHasBeenDelivered}
                            checked={addressType === 'pickup'}
                            onChange={() => {
                              addToChangedAttributes([
                                'address',
                                'pickUpPoint',
                              ]);
                              setAddressType('pickup');
                              setBagInfo({
                                ...bagInfo,
                                pickUpPoint: null,
                                address: null,
                                hasChanged: true,
                              });
                            }}
                            icon={
                              <FiberManualRecord
                                className={classes.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classes.radioChecked}
                              />
                            }
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={t('orders.pickupPoint', 'Punkt odbioru')}
                      />
                      {clientAddresses.length > 0 ? (
                        <FormControlLabel
                          control={
                            <Radio
                              disabled={isEditDisabled || bagHasBeenDelivered}
                              checked={addressType === 'delivery'}
                              onChange={() => {
                                addToChangedAttributes([
                                  'address',
                                  'pickUpPoint',
                                ]);
                                setAddressType('delivery');
                                setBagInfo({
                                  ...bagInfo,
                                  address: null,
                                  pickUpPoint: null,
                                  hasChanged: true,
                                });
                              }}
                              icon={
                                <FiberManualRecord
                                  className={classes.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classes.radioChecked}
                                />
                              }
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={t('diets.addresses', 'Adres')}
                        />
                      ) : (
                        <FormControlLabel
                          control={
                            <Radio
                              disabled={isEditDisabled || bagHasBeenDelivered}
                              onChange={() =>
                                openToast({
                                  messages: [
                                    t(
                                      'errors.userHasNoAnyAddress',
                                      'Ten użytkownik nie posiada żadnego adresu.'
                                    ),
                                    t(
                                      'errors.addAddresToUnblockDeliveryOptions',
                                      'Aby móc wybrać opcję dostawy, dodaj adres.'
                                    ),
                                  ],
                                  type: 'warning',
                                  autoHideDuration: 4000,
                                })
                              }
                              checked={false}
                              icon={
                                <FiberManualRecord
                                  className={classes.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classes.radioChecked}
                                />
                              }
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={
                            <div style={{ textAlign: 'center' }}>
                              <div>{t('orders.delivery', 'Dostawa')}</div>
                              <div>
                                ({t('common.itUnavailable', 'niedostępne')})
                              </div>
                            </div>
                          }
                        />
                      )}
                      {addressType === 'delivery' ? (
                        <SelectInput
                          classes={classes}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          mapBy="label"
                          trackBy="@id"
                          options={addresses}
                          value={bagInfo.address}
                          handleChange={(ev, obj) => {
                            addToChangedAttributes(['address', 'pickUpPoint']);
                            setBagInfo({
                              ...bagInfo,
                              address: obj,
                              pickUpPoint: null,
                              hasChanged: true,
                            });
                          }}
                          size={12}
                        />
                      ) : (
                        <SelectInput
                          classes={classes}
                          disabled={isEditDisabled || bagHasBeenDelivered}
                          mapBy="value"
                          trackBy="@id"
                          options={pickupPoints}
                          value={bagInfo.pickUpPoint}
                          handleChange={(ev, obj) => {
                            addToChangedAttributes(['address', 'pickUpPoint']);
                            setBagInfo({
                              ...bagInfo,
                              pickUpPoint: obj,
                              address: null,
                              hasChanged: true,
                            });
                          }}
                          size={12}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right" padding="none"></TableCell>
                  </TableRow>

                  {!isEditDisabled &&
                    !bagHasBeenDelivered &&
                    !isSelectedDaySubscriptionIntent && (
                      <TableRow>
                        <TableCell padding="none">
                          <Checkbox
                            id="createOrderCheck"
                            checked={
                              orderInfo.status.systemValue ===
                              'WAITING_FOR_PAYMENT'
                                ? false
                                : createOrder
                            }
                            onChange={() => {
                              if (
                                orderInfo.status.systemValue ===
                                'WAITING_FOR_PAYMENT'
                              ) {
                                return openToast({
                                  messages: [
                                    t(
                                      'info.theErrorsHasNotBeenPaid',
                                      'Zamówienie nie zostało opłacone.'
                                    ),
                                    t(
                                      'info.orderWillBUpdated',
                                      'Edycja zaktualizuje go o nowe wartości'
                                    ),
                                  ],
                                  type: 'info',
                                  autoHideDuration: 3000,
                                });
                              }
                              setCreateOrder(!createOrder);
                            }}
                            icon={<Check className={classes.uncheckedIcon} />}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        </TableCell>
                        <TableCell padding="none" colSpan={2}>
                          <FormLabel
                            htmlFor="createOrderCheck"
                            className={classes.labelHorizontal}
                          >
                            {t(
                              'singleDayDetails.includeTheCostOfChanges',
                              'Uwzględnij koszty zmian'
                            )}
                          </FormLabel>
                        </TableCell>
                        <TableCell align="right" padding="none"></TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
              {orderInfo.status.systemValue !== 'CANCELED' &&
                !bagHasBeenDelivered && (
                  <div style={orderStyles.centered}>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <Button
                          fullWidth={true}
                          onClick={() => {
                            setChangedAttributes([]);
                            setChangeDate(false);
                            setMultipleChange(false);
                            !bagSnap.address
                              ? setAddressType('pickup')
                              : setAddressType('delivery');
                            return setBagInfo(bagSnap);
                          }}
                          color={'secondary'}
                        >
                          {t('common.cancelChanges', 'Anuluj zmiany')}
                        </Button>
                        <Button
                          disabled={!bagInfo.hasChanged || isLoading}
                          fullWidth={true}
                          onClick={() => {
                            if (validateBag(bagInfo)) {
                              return;
                            }
                            changeDay(bagInfo);
                          }}
                          color={'primary'}
                        >
                          {bagInfo.hasChanged
                            ? t('common.saveChanges', 'Zapisz zmiany')
                            : t('cmmon.noChangesApplied', 'Nie dokonano zmian')}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
            </div>
          )}
        </div>
        {!isEmpty(bagInfo) && (
          <div style={orderStyles.box}>
            <h1>
              <b>{t('dietDetails.items', 'Przedmioty')}</b>
            </h1>
            {!bagInfo.diet && (
              <div>
                <div style={{ marginTop: '20px' }}>
                  <b>{t('common.mainMenu.addons', 'Dodatki')}</b>
                  <Divider />
                  <div style={{ marginTop: '10px' }}>
                    {bagInfo.addons && bagInfo.addons.length > 0 ? (
                      bagInfo.addons.map(({ addon, quantity }) => {
                        return (
                          <div
                            style={{
                              marginTop: '5px',
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span>{addon?.name ?? ''}</span>
                            <span>{`x${quantity}`}</span>
                          </div>
                        );
                      })
                    ) : (
                      <span>{t('common.lack', '$$Brak')}</span>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div>
              <div style={{ marginTop: '20px' }}>
                <b>{t('bagInfo.dishes', 'Dania')}</b>
                <Divider />
                <div style={{ marginTop: '10px' }}>
                  {!isEmpty(bagInfo.dishSizeItems) ? (
                    bagInfo.dishSizeItems.map(({ dishSize }) => {
                      return (
                        <div
                          style={{
                            marginTop: '5px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span>{dishSize?.name ?? ''}</span>
                        </div>
                      );
                    })
                  ) : (
                    <span>{t('common.lack', '$$Brak')}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {bagInfo && (
          <div style={orderStyles.box}>
            <h1>
              <b>{t('rolesForm.rates', 'Oceny')}</b>
            </h1>
            <div style={{ marginTop: '20px' }}>
              <b>{t('orders.delivery', 'Dostawa')}</b>
              <Divider />
              <div>
                {bagInfo.deliveryRate ? (
                  <p
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {t('comments.rate', 'Ocena')}:{' '}
                    <b>
                      {bagInfo.deliveryRate.rate === 5 ? (
                        <span style={{ color: 'green' }}>
                          {t('common.rating.positive', 'pozytywna')}
                        </span>
                      ) : (
                        <span style={{ color: 'red' }}>
                          {t('common.rating.negative', 'negatywna')}
                        </span>
                      )}
                    </b>
                  </p>
                ) : (
                  <span>{t('common.ratings.noRating', 'Brak oceny')}</span>
                )}
              </div>
              <div style={{ marginTop: '10px' }}>
                {bagInfo.deliveryRate ? (
                  bagInfo.deliveryRate.rate === 1 ? (
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {t('clientCall.comment', 'Komentarz')}:{' '}
                      <i>"{bagInfo.deliveryRate.comment}"</i>
                    </p>
                  ) : (
                    t('common.ratings.noComment', 'Brak komentarza')
                  )
                ) : null}
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <b>{t('common.menu', 'Menu')}</b>
              <Divider />
              {bagInfo.items.length > 0
                ? bagInfo.items.map((item, index) => (
                    <div key={index}>
                      <Accordion
                        active={-1}
                        collapses={[
                          {
                            title: item.mealType.name,
                            content: (
                              <div style={{ width: '100%' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <p>
                                      {item.dish && item.dish.nameForClient}
                                    </p>
                                  </div>
                                  <p style={{ margin: 0 }}>
                                    {item.rate
                                      ? `${item.rate.rate}/5`
                                      : t(
                                          'common.ratings.noRating',
                                          'Brak oceny'
                                        )}
                                  </p>
                                </div>
                                <p>
                                  {item.rate
                                    ? item.rate.comment &&
                                      t(
                                        'common.rating.clientComment',
                                        'Komentarz klienta'
                                      )
                                    : t(
                                        'common.ratings.noComment',
                                        'Brak komentarza'
                                      )}
                                </p>
                                <p>
                                  <i>
                                    {item.rate
                                      ? item.rate.comment &&
                                        `"${item.rate.comment}"`
                                      : ''}
                                  </i>
                                </p>
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  ))
                : t('common.ratings.noDishesToRate', 'Brak dań do oceny')}
            </div>
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <b>{t('common.ratings.extraComment', 'Dodatkowy komentarz')}</b>
              <Divider />
              <div>
                {bagInfo.rate ? (
                  <i>"{bagInfo.rate.comment}"</i>
                ) : (
                  t('common.ratings.noComment', 'Brak komentarza')
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <BagChangeModal
        bagChangeModalStatus={bagChangeModal}
        setBagChangeModalStatus={setBagChangeModal}
        bagChanges={bagChanges}
        t={t}
      />
      {addonIdToRemove && (
        <SweetAlert
          warning
          title={
            isSelectedDaySubscriptionIntent
              ? t(
                  'menuPlanner.planner.confirmModal.deleteAddonSubscription',
                  'Czy na pewno chcesz to zrobić? Dodatek zostanie usunięty ze wszystkich dni subskrypcyjnych'
                )
              : t(
                  'menuPlanner.planner.confirmModal.alertTitle',
                  'Czy na pewno chcesz to zrobić?'
                )
          }
          onConfirm={confirmDeleteAddons}
          onCancel={() => setAddonIdToRemove(null)}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText={t('common.shared.yes', 'Tak')}
          cancelBtnText={t('common.shared.no', 'Nie')}
          showCancel
        />
      )}
    </div>
  );
};

const enhance = compose(
  withStyles(sweetAlertStyle),
  connect(null, { openSweetToast })
);

export default enhance(SingleDayDetails);
