import React, { Component } from 'react';
import { compose } from 'redux';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted, translateNamesForExport } from 'helpers/helpers';
import {
  ROLE_CREATE_IMPORTER_TASK,
  ROLE_CREATE_ZONE,
  ROLE_SHOW_IMPORTER_TASK,
  ROLE_IMPORT_ZONE,
  ROLE_REMOVE_ZONE,
} from 'helpers/roles';
import Button from 'components/CustomButtons/Button';
import InfoModal from 'components/InfoModal/InfoModal';
import MediaUpload from 'components/MediaUpload/MediaUpload';
import importCSVExample from './import-xlsx-example.xlsx';
import gastro from 'helpers/gastro';
import { post } from 'helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';
import { remove } from 'helpers/apiHelpers';
import { DialogLoader } from '../../../components/DialogLoader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Dialog } from '@material-ui/core';

const EXPORT_DETAIL_MANIPULATOR = ({ name, shortName, ...rest }) => {
  let query = { ...rest, 'zone.name': name, 'zone.shortName': shortName };

  return Object.keys(query).reduce((prev, curr) => {
    if (query[curr]) {
      prev[curr] = query[curr];
    }
    return prev;
  }, {});
};

class List extends Component {
  state = {
    openedModal: false,
    file: null,
    isImporting: false,
    importButtonClicked: false,
    zonesIds: [],
    numberOfZones: null,
    refreshList: false,
    isDeleting: false,
    isConfirmModalOpened: false,
  };

  componentDidMount() {
    const successfullyAddedZone = !!this.props.location.state
      ?.successfullyAddedZone;

    if (successfullyAddedZone) {
      this.props.openToast({
        messages: [this.props.t('zones.rememberToCompletePriceList')],
        type: 'warning',
        autoHideDuration: 5000,
      });
    }
  }

  newZone = () => this.props.history.push(`${window.location.pathname}/add`);

  closeModal = () => {
    this.setState({
      openedModal: false,
    });
  };

  openModal = () => {
    this.setState({
      openedModal: true,
    });
  };

  importZones = () => {
    this.setState({ importButtonClicked: true });
    this.uploadFile(this.state.file).then(file => {
      this.postImporterTask(file);
    });
  };

  postImporterTask = file => {
    this.setState({ isImporting: true });
    const data = {
      type: 'ZONES',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      res => {
        this.setState({
          openedModal: false,
          file: null,
          importButtonClicked: false,
        });
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [
            this.props.t('zones.importAdded'),
            this.props.t('zones.refreshPage'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [this.props.t('zones.cannotImport')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  uploadFile = async file => {
    let data = new FormData();
    data.append('file', file);

    let response = await gastro.post('/media', data);

    return new Promise((resolve, reject) => {
      resolve(response.data);
    });
  };

  setNumberOfZones = zones => {
    this.setState(prevState => ({
      ...prevState,
      numberOfZones: zones.length,
      zonesIds: zones.length > 0 ? prevState.zonesIds : [],
    }));
  };

  massDeleteHandleSingleCheckbox = id => {
    const zonesIdsUpdated = [...this.state.zonesIds];
    const zoneIdIndex = zonesIdsUpdated.indexOf(id);

    if (zoneIdIndex >= 0) {
      zonesIdsUpdated.splice(zoneIdIndex, 1);
    } else {
      zonesIdsUpdated.push(id);
    }

    this.setState(prevState => ({ ...prevState, zonesIds: zonesIdsUpdated }));
  };

  massDeleteHandleMultipleCheckboxes = array => {
    this.setState(prevState => ({ ...prevState, zonesIds: array }));
  };

  massDeleteZonesSubmit = () => {
    this.setState(prevState => ({
      ...prevState,
      isConfirmModalOpened: false,
    }));

    let url;
    if (this.state.zonesIds.length === this.state.numberOfZones) {
      url = '/zones?removeAllAction=true';
    } else {
      url = '/zones?';
      this.state.zonesIds.forEach((zoneId, index) => {
        if (index === 0) {
          url = url.concat(`id[]=${zoneId}`);
        } else {
          url = url.concat(`&id[]=${zoneId}`);
        }
      });
    }

    this.setState(prevState => ({ ...prevState, isDeleting: true }));

    remove(url)
      .then(() => {
        this.refreshList();
        this.props.openToast({
          messages: [
            this.props.t(
              'zones.toastMessage.chosenZonesWereDeleted',
              'Wybrane strefy zostały usunięte'
            ),
          ],
          type: 'success',
          autoHideDuration: 5000,
        });
      })
      .catch(e => {
        this.props.openToast({
          messages: [
            this.props.t(
              'zones.toastMessage.chosenZonesWereNotDeleted',
              'Usunięcie wybranych stref nie powiodło się'
            ),
          ],
          type: 'warning',
          autoHideDuration: 5000,
        });
      })
      .finally(() => {
        this.setState(prevState => ({
          ...prevState,
          isDeleting: false,
          zonesIds: [],
        }));
      });
  };

  refreshList = () => {
    this.setState(prevState => ({ refreshList: !prevState.refreshList }));
  };

  render() {
    const { classes, t } = this.props;
    const namesForExport = translateNamesForExport(t);

    return (
      <>
        <DialogLoader loaderState={this.state.isDeleting} />

        <AdminTable title={t('zones.list')} icon>
          <InfoModal
            modalState={this.state.openedModal}
            handleClose={() => this.setState({ openedModal: false })}
            modalContent={
              <div>
                <h1>{t('zones.zonesImport')}</h1>
                {/*<b>Informacje dotyczące importu:</b>*/}
                <p style={{ textAlign: 'left' }}>
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: t('zones.importerModalInfo', {
                        importCSVExample: importCSVExample,
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  />
                </p>
                <MediaUpload
                  file={this.state}
                  setFile={file => this.setState({ file: file })}
                  buttonText={t('zones.selectXLSXFile')}
                  addButtonProps={{
                    color: 'info',
                    round: false,
                  }}
                  changeButtonProps={{
                    color: 'info',
                    round: false,
                  }}
                  removeButtonProps={{
                    color: 'danger',
                    round: false,
                  }}
                />
                <div>
                  {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                    isGranted(ROLE_IMPORT_ZONE) && (
                      <Button
                        color={this.state.file ? 'success' : 'default'}
                        disabled={
                          !this.state.file ||
                          this.state.isImporting ||
                          this.state.importButtonClicked
                        }
                        onClick={this.importZones}
                      >
                        {t('zones.import')}
                      </Button>
                    )}
                  <Button onClick={this.closeModal}>{t('zones.close')}</Button>
                </div>
              </div>
            }
            closeButtonText={t('zones.close')}
            hideDefaultCloseButton
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {isGranted(ROLE_SHOW_IMPORTER_TASK) && isGranted(ROLE_IMPORT_ZONE) && (
              <Button
                style={{ margin: '5px', width: '158px' }}
                color={'info'}
                onClick={this.openModal}
              >
                {t('zones.import')}
              </Button>
            )}
            {isGranted(ROLE_REMOVE_ZONE) && (
              <Button
                color="danger"
                onClick={() =>
                  this.setState(prevState => ({
                    ...prevState,
                    isConfirmModalOpened: true,
                  }))
                }
                disabled={this.state.zonesIds.length === 0}
              >
                {t('zones.deleteZones', 'Usuń strefy')}
              </Button>
            )}
            {isGranted(ROLE_CREATE_ZONE) && (
              <FormControlButtons
                classes={classes}
                handleSubmit={this.newZone}
                submitText={t('zones.addZone') + ' +'}
              />
            )}
          </div>
          <DataGrid
            refresh={this.state.refreshList}
            actions={true}
            editPath={`${window.location.pathname}/edit`}
            remove={true}
            export={true}
            paginationTop={true}
            paginationBottom={false}
            url="/zones"
            reportName={'zone'}
            columns={columnConfig(this.props.t)}
            role="ZONE"
            manipulateQuery={(table, query) => {
              query['properties'] = [
                'id',
                'name',
                'shortName',
                'category',
                'drivers',
                'postCodesLength',
                'deliveryType',
                'deliveryHours',
                'priority',
              ];
              query['properties[category]'] = ['name'];
              query['properties[drivers]'] = ['name'];
              query['properties[deliveryType]'] = ['value'];
              query['properties[deliveryHours]'] = ['hourFrom', 'hourTo'];

              return query;
            }}
            exportButtonConfig={[
              {
                label: `XLSX - ${this.props.t('common.details')}`,
                format: 'xlsx',
                endpoint: '/zones',
                fileName: (url, date) => {
                  return namesForExport[url]
                    ? namesForExport[url] + date
                    : url + date;
                },
              },
              {
                label: `CSV - ${this.props.t('common.details')}`,
                format: 'csv',
                endpoint: '/zones',
                fileName: (url, date) => {
                  return namesForExport[url]
                    ? namesForExport[url] + date
                    : url + date;
                },
              },
              {
                label: 'XLSX',
                format: 'xlsx',
                endpoint: '/zone-post-codes-export',
                fileName: (url, date) => {
                  return namesForExport[url]
                    ? namesForExport[url] + date
                    : url + date;
                },
                manipulateQuery: EXPORT_DETAIL_MANIPULATOR,
              },
              {
                label: 'CSV',
                format: 'csv',
                endpoint: '/zone-post-codes-export',
                fileName: (url, date) => {
                  return namesForExport[url]
                    ? namesForExport[url] + date
                    : url + date;
                },
                manipulateQuery: EXPORT_DETAIL_MANIPULATOR,
              },
            ]}
            massDelete={true}
            massDeleteHandleSingleCheckbox={this.massDeleteHandleSingleCheckbox}
            massDeleteHandleMultipleCheckboxes={
              this.massDeleteHandleMultipleCheckboxes
            }
            massDeleteItemsIds={this.state.zonesIds}
            onFetchedData={this.setNumberOfZones}
          />
        </AdminTable>

        <Dialog fullWidth={true} open={this.state.isConfirmModalOpened}>
          <SweetAlert
            warning
            style={{ display: 'block' }}
            title={t('clients.areYouSure')}
            onConfirm={this.massDeleteZonesSubmit}
            onCancel={() =>
              this.setState(prevState => ({
                ...prevState,
                isConfirmModalOpened: !prevState.isConfirmModalOpened,
              }))
            }
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText={t('common.shared.yes')}
            cancelBtnText={t('common.shared.no')}
            showCancel
          />
        </Dialog>
      </>
    );
  }
}

const enhance = compose(withToast, withStyles(buttonsStyle), withTranslation());

export default enhance(List);
