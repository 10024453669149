import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { get, post } from 'helpers/apiHelpers';
import { fetchDiets } from 'actions/Diets';
import { fetchVariants } from 'actions/Variants';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import isEmpty from 'lodash/isEmpty';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Counter from 'components/Counter/Counter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import SelectInput from 'components/FormSelect/SelectInput';
import Button from 'components/CustomButtons/Button';
import ModalButton from 'components/CustomButtons/Button';
import MaterialButton from '@material-ui/core/Button';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import Check from '@material-ui/icons/Check';
import { Divider } from '@material-ui/core';

import { Async } from 'react-select';

import {
  getCurrentMonthAndYear,
  getCurrentMonthDates,
  getMonth,
  getStartOfMonth,
  isDateInArray,
  isTheSameDays,
} from 'helpers/dateHelpers';
import { combineStyles, sortByKey } from 'helpers/helpers';

import {
  asyncSelectStyles,
  calendarStyles,
  orderStyles,
} from 'views/Orders/styles';

import 'views/Orders/calendar.css';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import Addresses from 'views/Clients/FormTabs/Addresses/List';
import NumberFormat from 'react-number-format';
import FormTextInputNoGrid from '../../../components/FormTextInput/FormTextInputNoGrid';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { usedDiscountsTranslations } from '../Diets/Details/DietDetailsPrices';
import { currencyToSymbol } from 'utils/currencies';

const minDietLength = 1;
const defaultDietLength = 5;
const maxDietLength = 365;

const addressTypeSelectObjects = t => ({
  deliveryToDoorEnabled: {
    value: 'indoor',
    name: t('brands.newBrandForm.toDoor', 'Dostawa pod drzwi'),
  },
  pickUpPointEnabled: {
    value: 'outdoor',
    name: t('orders.pickupInPerson', 'Odbiór osobisty'),
  },
});

const enabledDeliveryOptions = ['pickUpPointEnabled', 'deliveryToDoorEnabled'];

let addressTypeSelect = [];

const transformCalories = calory => ({
  id: calory['@id'],
  name: calory.name,
  value: calory.name,
});

const AddOrder = ({
  classes,
  fetchDiets,
  diets = [],
  fetchVariants,
  variants = [],
  selectedBrand,
  openToast,
  history,
  t,
  currency,
}) => {
  //Utilities
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addressModalState, setAddressModalState] = useState(false);
  const [brandInfo, setBrandInfo] = useState(null);

  // Client
  const [selectedClient, selectUser] = useState('');

  // Diet info
  const [selectedDiet, setDiet] = useState('');
  const [dietName, setDietName] = useState('');
  const [selectedVariant, selectVariant] = useState('');
  const [selectedCalories, selectCalories] = useState('');
  const [dietSelectCheckbox, setDietSelectCheckbox] = useState(false);

  // Length & Calendar
  const [month, setMonth] = useState(moment().month());
  const [disabledDays, setDisabledDays] = useState([]);
  const [dietLengthOptions, setDietLengthOptions] = useState([]);
  const [dietLength, setDietLength] = useState(0);
  const [selectedDays, selectDay] = useState([]);
  const [includeWeekends, setWeekendOption] = useState(false);
  const [saturdayInclude, setSaturdayInclude] = useState(false);
  const [sundayInclude, setSundayInclude] = useState(false);

  // Delivery details & payment
  const [addressType, setAddressType] = useState('');
  const [pickUpPoints, setPickUpPoints] = useState([]);
  const [selectedPickup, selectPickup] = useState('');
  const [userAddresses, setUserAddresses] = useState([]);
  const [selectedUserAddress, selectUserAddress] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentType, selectPaymentType] = useState('');
  const [sendConfirmation, setSendConfirmation] = useState(true);

  // Promo code & Money box
  const [usePromoCode, setUsePromoCode] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const [useMoneyBox, setUseMoneyBox] = useState(false);
  const [userMoneyBoxPoints, setUserMoneyBoxPoints] = useState(null);
  const [userMoneyBoxAsPrice, setUserMoneyBoxAsPrice] = useState(null);
  const [usedDiscounts, setUsedDiscounts] = useState({});

  // Summary
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState('');
  const [pricePerDayAfterDiscount, setPricePerDayAfterDiscount] = useState('');
  const [priceAfterDiscount, setPriceAfterDiscount] = useState('');
  const [priceDiscount, setPriceDiscount] = useState('');
  const [deliveryPriceAfterDiscount, setDeliveryPriceAfterDiscount] = useState(
    ''
  );
  const [dietPriceAfterDiscount, setDietPriceAfterDiscount] = useState('');

  // Invoice
  const [isInvoiceIncluded, setIsInvoiceIncluded] = useState(false);
  const [isEcoContainerIncluded, setEcoContainerIncluded] = useState(false);
  const [invoiceCompanyName, setInvoiceCompanyName] = useState('');
  const [invoiceVatNumber, setInvoiceVatNumber] = useState('');
  const [invoiceAddressPostCode, setInvoiceAddressPostCode] = useState('');
  const [invoiceAddressCity, setInvoiceAddressCity] = useState('');
  const [invoiceAddressStreet, setInvoiceAddressStreet] = useState('');
  const [invoiceAddressBuildNumber, setInvoiceAddressBuildNumber] = useState(
    ''
  );
  const [invoiceAddressPlaceNumber, setInvoiceAddressPlaceNumber] = useState(
    ''
  );

  const invoiceInfo = isInvoiceIncluded
    ? {
        isInvoiceIncluded,
        invoiceCompanyName,
        invoiceVatNumber,
        invoiceAddressPostCode,
        invoiceAddressCity,
        invoiceAddressStreet,
        invoiceAddressBuildNumber,
        invoiceAddressPlaceNumber,
        invoiceType: 'Other',
      }
    : { isInvoiceIncluded };

  // const
  const currMonth = getMonth(month);
  const startOfMonth = getStartOfMonth(month);
  const currentMonthDatesToDisplay = [
    ..._.range(1, startOfMonth).map(el => ''),
    ...getCurrentMonthDates(month),
  ];

  const getDatesRange = (firstDay, range) => {
    let days = [];
    const startDate = new Date(new moment({ ...firstDay }));
    const endDate = new Date(new moment({ ...firstDay }).add(range, 'd'));

    const disabledDaysMap = disabledDays.map(disabledDay =>
      moment(disabledDay).format('YYYYMMDD')
    );

    while (startDate < endDate) {
      if (startDate.getDay() === 6 && !saturdayInclude) {
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
      } else if (startDate.getDay() === 0 && !sundayInclude) {
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
      } else if (
        disabledDaysMap.some(d => d === moment(startDate).format('YYYYMMDD'))
      ) {
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
      } else {
        days.push(new moment(startDate));
        startDate.setDate(startDate.getDate() + 1);
      }
    }

    return days;
  };

  useEffect(() => {
    if (typeof getQueryVariable('userId') === 'undefined') {
      selectUser('');
    } else {
      Promise.all([
        get(`clients/${getQueryVariable('userId')}`),
        get(`clients/${getQueryVariable('userId')}/addresses`),
      ]).then(([user, addresses]) => {
        const transformedUserAddresses = addresses['hydra:member'].map(
          address => {
            return {
              ...address,
              fullAddress: `${address.street} ${address.buildNumber}, ${address.postCode} ${address.city}`,
            };
          }
        );
        setUserAddresses(transformedUserAddresses);

        const selectedUser = {
          label: `${user.firstName} ${user.lastName} (${user.email})`,
          value: user['@id'],
          invoiceInfo: {
            invoiceAddressBuildNumber: user.invoiceAddressBuildNumber,
            invoiceAddressCity: user.invoiceAddressCity,
            invoiceAddressPlaceNumber: user.invoiceAddressPlaceNumber,
            invoiceAddressPostCode: user.invoiceAddressPostCode,
            invoiceAddressStreet: user.invoiceAddressStreet,
            invoiceCompany: user.invoiceCompany,
            invoiceVatNumber: user.invoiceVatNumber,
          },
        };
        selectCurrentUser(selectedUser);
      });
    }
    Promise.all([
      fetchDiets(),
      fetchVariants(),
      get('/frontend/payment-types'),
      get('/pick-up-points'),
      get(`/brands/${selectedBrand}`),
    ]).then(([diets, variants, paymentTypes, pickupsResponse, brandInfo]) => {
      const pickups = pickupsResponse['hydra:member'];
      const days = brandInfo.adminDays
        ? brandInfo.adminDays.map(day => ({ name: day, value: day }))
        : [];
      const disabledDays = brandInfo.disabledDays;
      const brandInformation = brandInfo;
      const paymentMethods = paymentTypes['hydra:member'].map(paymentType => ({
        value: paymentType.type,
        label: paymentType.label,
      }));

      // create deliveryOptions array
      let tempDeliveryOptions = [];

      enabledDeliveryOptions.forEach(option => {
        if (brandInformation[option]) {
          tempDeliveryOptions.push(addressTypeSelectObjects(t)[option]);
        }
      });
      addressTypeSelect = tempDeliveryOptions;

      // check if the current dates to display are available in the brand and update disabled days
      const updatedDisabledDays = [];
      currentMonthDatesToDisplay.forEach(day => {
        let key = '';
        if (day) {
          key = new moment(day).locale('en').format('dddd').toLowerCase();
          if (brandInfo[key].shouldBeDeliveredInBlock === 0) {
            return updatedDisabledDays.push(day.format('YYYY-MM-DD 00:00:00'));
          }
        }
      });

      const allDisabledDays = updatedDisabledDays.concat(disabledDays);

      setPaymentMethods(paymentMethods);
      setDietLengthOptions(sortByKey(days, 'value'));
      setDietLength(isEmpty(days) ? defaultDietLength : 0);
      setPickUpPoints(pickups);
      setDisabledDays(allDisabledDays);
      setBrandInfo(brandInformation);
      setLoading(false);
    });
  }, []);

  const getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  };

  const isInSelectedDays = day => {
    let bool = false;

    selectedDays.forEach(dayInSelectedArray => {
      if (day.format('L') === dayInSelectedArray.format('L')) {
        bool = true;
      }
    });

    return bool;
  };

  const selectDietDay = day => {
    if (dietLength === 0) {
      return openToast({
        messages: [t('orders.firstSelectTime')],
        type: 'info',
        autoHideDuration: 3000,
      });
    }

    if (!saturdayInclude && day.day() === 6) {
      if (brandInfo.saturday.shouldBeDeliveredInBlock !== 0) {
        return openToast({
          messages: [t('orders.selectSaturday')],
          type: 'info',
          autoHideDuration: 3000,
        });
      }
    }

    if (
      disabledDays
        .map(d => new Date(d).getTime())
        .some(disabledDay => disabledDay === new Date(day).setHours(0, 0, 0, 0))
    ) {
      return openToast({
        messages: [t('orders.dayNotSupported')],
        type: 'warning',
        autoHideDuration: 3000,
      });
    }

    const today = new Date(moment()).setHours(0, 0, 0, 0);

    if (day.isBefore(today) && !isInSelectedDays(day)) {
      openToast({
        messages: [t('orders.selectedDayInPast')],
        type: 'warning',
        autoHideDuration: 3000,
      });
    }

    if (selectedDays.length === 0) {
      let days = [];
      const startDate = new Date(new moment({ ...day }));
      const endDate = new Date(new moment({ ...day }).add(dietLength, 'd'));

      const disabledDaysMap = disabledDays.map(disabledDay =>
        moment(disabledDay).format('YYYYMMDD')
      );

      while (startDate < endDate) {
        if (startDate.getDay() === 6 && !saturdayInclude) {
          startDate.setDate(startDate.getDate() + 1);
          endDate.setDate(endDate.getDate() + 1);
        } else if (startDate.getDay() === 0 && !sundayInclude) {
          startDate.setDate(startDate.getDate() + 1);
          endDate.setDate(endDate.getDate() + 1);
        } else if (
          disabledDaysMap.some(d => d === moment(startDate).format('YYYYMMDD'))
        ) {
          startDate.setDate(startDate.getDate() + 1);
          endDate.setDate(endDate.getDate() + 1);
        } else {
          days.push(new moment(startDate));
          startDate.setDate(startDate.getDate() + 1);
        }
      }

      return selectDay(days);
    }
    const isDayInSelectedDays = isDateInArray(day, selectedDays);

    if (!isDayInSelectedDays && dietLength - selectedDays.length === 0) {
      return;
    }

    const daysToChange = isDayInSelectedDays
      ? selectedDays.filter(el => !isTheSameDays(el, day))
      : selectedDays.concat(day);

    selectDay(daysToChange);
  };

  const closeModal = () => {
    get(`${selectedClient.value}/addresses`).then(addresses => {
      const transformedUserAddresses = addresses['hydra:member'].map(
        address => {
          return {
            ...address,
            fullAddress: `${address.street} ${address.buildNumber}, ${address.postCode} ${address.city}`,
          };
        }
      );
      setUserAddresses(transformedUserAddresses);
      setAddressModalState(false);
    });
  };

  const applyMoneyBoxPoints = () => {
    if (!selectedClient) {
      return openToast({
        messages: [t('orders.firstSelectClient')],
        type: 'info',
        autoHideDuration: 3000,
      });
    }
    setUseMoneyBox(!useMoneyBox);
  };

  const handleDietLengthInputChange = ({ target }) => {
    const parsedValue = parseInt(target?.value);

    if (isNaN(parsedValue)) {
      selectDay([]);
      return setDietLength(0);
    }

    if (
      typeof parsedValue === 'number' &&
      !isNaN(parsedValue) &&
      parsedValue <= maxDietLength &&
      parsedValue >= minDietLength
    ) {
      selectDay([]);
      setDietLength(parsedValue);
    }
  };

  const handleClickDecrement = () => {
    if (dietLength - 1 >= minDietLength) {
      if (selectedDays.length === dietLength) {
        let sortedDates = [...selectedDays];
        sortedDates.sort((dateA, dateB) => dateA - dateB);
        sortedDates.pop();

        selectDay(sortedDates);
      }

      setDietLength(dietLength - 1);
    }
  };
  const handleClickIncrement = () => {
    if (!isEmpty(selectedDays)) {
      let sortedDates = [...selectedDays];
      sortedDates.sort((dateA, dateB) => dateA - dateB);
      let popedDate = sortedDates.pop();
      let lastTwoDays = getDatesRange(popedDate, 2);

      selectDay([...sortedDates, ...lastTwoDays]);
    }

    setDietLength(dietLength + 1);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);
    const selected =
      addressType === 'indoor' ? selectedUserAddress : selectedPickup;

    if (
      !dietName ||
      !selectedClient ||
      !selectedVariant ||
      !selectedCalories ||
      !selected ||
      !dietLength ||
      !paymentType
    ) {
      setIsSubmitting(false);
      return openToast({
        messages: [t('orders.fillAllFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (dietLength - selectedDays.length !== 0) {
      setIsSubmitting(false);
      return openToast({
        messages: [
          `${t('orders.useAllDays')}: ${dietLength - selectedDays.length}`,
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    if (
      isInvoiceIncluded &&
      (!invoiceCompanyName ||
        !invoiceAddressCity ||
        !invoiceAddressStreet ||
        !invoiceAddressBuildNumber ||
        !invoiceAddressPostCode ||
        !invoiceVatNumber)
    ) {
      setIsSubmitting(false);
      return openToast({
        messages: [t('orders.invoiceFieldsNotComplete'), t('orders.fillAll')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const selectedAddress =
      addressType === 'indoor'
        ? { address: selectedUserAddress }
        : { pickUpPoint: selectedPickup };

    const postData = {
      client: selectedClient.value,
      paymentType: paymentType,
      useMoneyBox: useMoneyBox,
      discountCode: promoCode ? promoCode.value['@id'] : null,
      sendConfirmation: sendConfirmation,
      ...invoiceInfo,
      diets: [
        {
          name: dietName,
          diet: selectedDiet,
          variant: selectedVariant,
          calorific: selectedCalories,
          saturdayInclude: saturdayInclude,
          useEcoContainers: isEcoContainerIncluded,
          dietLength: dietLength,
          optionChangeMenu: dietSelectCheckbox,
          deliveryDays: selectedDays.map(momentDate =>
            momentDate.format('YYYY-MM-DD')
          ),
          ...selectedAddress,
        },
      ],
    };

    post('/orders', postData).then(
      () => history.push('/admin/orders'),
      error => {
        setIsSubmitting(false);
        if (error.response.data.violations[0]) {
          return openToast({
            messages: [error.response.data.violations[0].message],
            type: 'error',
            autoHideDuration: 5000,
          });
        } else {
          return openToast({
            messages: [t('orders.smthWentWrong')],
            type: 'error',
            autoHideDuration: 5000,
          });
        }
      }
    );
  };

  const setPriceInfo = res => {
    setPriceAfterDiscount(res.priceLeftToPay);
    setPriceBeforeDiscount(res.priceBeforeDiscount);
    setPriceDiscount(res.priceDiscount);
    setUsedDiscounts(res.dietCosts[0].usedDiscounts);
    // setDiscountPercentage(res.discountPercentage);
    setDeliveryPriceAfterDiscount(
      res?.dietCosts[0]?.delivery?.afterDiscount ?? 0
    );
    setDietPriceAfterDiscount(res?.dietCosts[0]?.diet?.afterDiscount ?? 0);
    setPricePerDayAfterDiscount(res.dietCosts[0].pricePerDayAfterDiscount);
    setUserMoneyBoxPoints(res.usedMoneyboxPoint);
    setUserMoneyBoxAsPrice(res.usedMoneyBoxPointsAsPrice);
  };

  const checkPrice = () => {
    const selectedAddress =
      addressType === 'indoor'
        ? { address: selectedUserAddress || null }
        : { pickUpPoint: selectedPickup || null };

    const postData = {
      discountCode: promoCode ? promoCode.value['@id'] : null,
      useMoneyBox: useMoneyBox,
      client: selectedClient.value,
      diets: [
        {
          diet: selectedDiet,
          variant: selectedVariant,
          calorific: selectedCalories,
          dietLength: dietLength || null,
          useEcoContainers: isEcoContainerIncluded,
          optionChangeMenu: dietSelectCheckbox,
          deliveryDays: selectedDays.map(momentDate =>
            momentDate.format('YYYY-MM-DD')
          ),
          ...selectedAddress,
        },
      ],
    };

    if (
      !postData.diets
        .map(
          diet =>
            diet.diet !== '' &&
            diet.variant !== '' &&
            diet.calorific !== '' &&
            diet.pickUpPoint !== null &&
            diet.address !== null
        )
        .includes(false)
    ) {
      post('/frontend/order-cost', postData).then(
        res => {
          setPriceInfo(res);
        },
        error => {
          openToast({
            messages: [t('orders.cannotPriceOrder'), t('orders.priceListSet')],
            type: 'error',
            autoHideDuration: 5000,
          });
        }
      );
    }
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      const splicedName = inputValue.split(' ');
      let filter = {};

      if (splicedName.length === 2) {
        filter = {
          firstName: splicedName[0],
          lastName: splicedName[1],
        };
      } else {
        filter = {
          lastName: splicedName,
          firstName: splicedName,
          email: splicedName,
        };
      }
      const operator = [2, 3].includes(splicedName.length) ? '_andX' : '_orX';

      const query = { [operator]: [[filter]] };
      get('clients', query).then(data => {
        resolve(
          data['hydra:member'].map(row => {
            return {
              label: `${row.firstName} ${row.lastName} (${row.email})`,
              value: row['@id'],
              moneyBox: row.moneyBox,
              invoiceInfo: {
                invoiceCompany: row.invoiceCompany,
                invoiceVatNumber: row.invoiceVatNumber,
                invoiceAddressPostCode: row.invoiceAddressPostCode,
                invoiceAddressCity: row.invoiceAddressCity,
                invoiceAddressStreet: row.invoiceAddressStreet,
                invoiceAddressBuildNumber: row.invoiceAddressBuildNumber,
                invoiceAddressPlaceNumber: row.invoiceAddressPlaceNumber,
              },
            };
          })
        );
      });
    });

  const selectCurrentUser = async user => {
    setAddressType('');
    setUserAddresses([]);
    selectUserAddress('');
    selectPickup('');
    setUseMoneyBox(false);
    setUserMoneyBoxPoints(null);

    if (user === null) {
      selectUser('');
      setAddressType('');
      setUserAddresses([]);
      clearUserInvoiceInfo();
      setIsInvoiceIncluded(false);
      return;
    }

    const userId = user.value.match(/\d+/g).map(Number)[0];
    selectUser({ ...user, id: userId });

    const userAddresses = await get(`${user.value}/addresses`);
    const transformedUserAddresses = userAddresses['hydra:member'].map(
      address => {
        return {
          ...address,
          fullAddress: `${address.street} ${address.buildNumber}, ${address.postCode} ${address.city}`,
        };
      }
    );

    setUserInvoiceInfo(user.invoiceInfo);
    setUserAddresses(transformedUserAddresses);
  };

  const setUserInvoiceInfo = invoiceInfo => {
    setInvoiceCompanyName(invoiceInfo.invoiceCompany);
    setInvoiceVatNumber(invoiceInfo.invoiceVatNumber);
    setInvoiceAddressPostCode(invoiceInfo.invoiceAddressPostCode);
    setInvoiceAddressCity(invoiceInfo.invoiceAddressCity);
    setInvoiceAddressStreet(invoiceInfo.invoiceAddressStreet);
    setInvoiceAddressBuildNumber(invoiceInfo.invoiceAddressBuildNumber);
    setInvoiceAddressPlaceNumber(invoiceInfo.invoiceAddressPlaceNumber);
  };

  const clearUserInvoiceInfo = () => {
    setInvoiceCompanyName('');
    setInvoiceVatNumber('');
    setInvoiceAddressPostCode('');
    setInvoiceAddressCity('');
    setInvoiceAddressStreet('');
    setInvoiceAddressBuildNumber('');
    setInvoiceAddressPlaceNumber('');
  };

  const selectPromoCode = code => {
    if (code === null) {
      return setPromoCode(null);
    }

    const today = new Date(moment()).setHours(0, 0, 0, 0);
    let validFrom;
    let validTo;

    const codeError = error => {
      return openToast({
        messages: [error],
        type: 'warning',
        autoHideDuration: 3000,
      });
    };
    const errorMessage_inactive = t('orders.thisDiscountCode');
    const errorMessage_expired = t('orders.discountCodeExpired');

    if (code.value.validFrom && !code.value.validTo) {
      validFrom = new Date(moment(code.value.validFrom)).setHours(0, 0, 0, 0);
      validTo = null;

      validFrom > today ? codeError(errorMessage_inactive) : setPromoCode(code);
    } else if (!code.value.validFrom && code.value.validTo) {
      validFrom = null;
      validTo = new Date(moment(code.value.validTo)).setHours(0, 0, 0, 0);

      validTo < today ? codeError(errorMessage_expired) : setPromoCode(code);
    } else if (code.value.validFrom && code.value.validTo) {
      validFrom = new Date(moment(code.value.validFrom)).setHours(0, 0, 0, 0);
      validTo = new Date(moment(code.value.validTo)).setHours(0, 0, 0, 0);

      validTo < today
        ? codeError(errorMessage_expired)
        : validFrom > today
        ? codeError(errorMessage_inactive)
        : setPromoCode(code);
    } else {
      return setPromoCode(code);
    }
  };

  const promisePromoCodes = inputValue =>
    new Promise(resolve => {
      const query = { code: inputValue, active: true };
      get('discount-codes', query).then(data => {
        resolve(
          data['hydra:member']
            .filter(
              code =>
                code.diets.length === 0 || code.diets.includes(selectedDiet)
            )
            .map(code => {
              const codeLabel = `${code.code}, rabat ${
                code.isPercentage ? code.discount + '%' : code.discount + ' zł'
              }`;

              return {
                label: codeLabel,
                value: code,
              };
            })
        );
      });
    });

  const selVariant = variants.find(
    variant => variant['@id'] === selectedVariant
  );
  const caloriesOptions =
    selVariant && selVariant.calories
      ? selVariant.calories.map(transformCalories)
      : [];

  //checks price when all required data is available
  useEffect(() => {
    selectedVariant && selectedCalories && checkPrice();
  }, [
    selectedClient,
    selectedDays,
    selectedPickup,
    selectedDiet,
    selectedUserAddress,
    addressType,
    paymentType,
    selectedVariant,
    selectedCalories,
    useMoneyBox,
    usePromoCode,
    promoCode,
    dietSelectCheckbox,
    isEcoContainerIncluded,
  ]);

  const getVariantOptions = () => {
    let result = [];
    if (selectedDiet) {
      const dietObject = diets.find(diet => diet['@id'] === selectedDiet);
      result = variants.filter(variant =>
        dietObject.variants.includes(variant['@id'])
      );
    }
    return result;
  };

  return (
    <form style={{ opacity: isLoading ? 0 : 1, transition: '0.3s all' }}>
      {addressModalState ? (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={addressModalState}
          onClose={() => closeModal()}
        >
          <DialogContent>
            <ModalButton
              style={orderStyles.closeButton}
              color={'transparent'}
              justIcon
              round
              onClick={() => closeModal()}
            >
              <Close />
            </ModalButton>
            <Addresses noCancel userId={selectedClient.id} />
          </DialogContent>
        </Dialog>
      ) : null}
      <h1>{t('orders.newOrderForm')}</h1>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={3}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h2>{t('orders.client')}</h2>
              </div>
              <GridContainer>
                <GridItem md={12}>
                  <div style={orderStyles.box}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '15px' }}
                    >
                      {t('orders.startFromClient')} *
                    </FormLabel>
                    <Async
                      styles={asyncSelectStyles}
                      isClearable={true}
                      loadOptions={promiseOptions}
                      onChange={user => selectCurrentUser(user)}
                      value={selectedClient}
                      placeholder={t('orders.selectClient')}
                      clearValue={() => selectCurrentUser('')}
                      noOptionsMessage={() => t('orders.typeNameAndEmail')}
                    />
                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      {t('orders.or')}
                    </div>
                    <Link to={'/admin/clients/add'}>
                      <MaterialButton
                        variant={'outlined'}
                        fullWidth
                        style={{ marginBottom: '5px' }}
                      >
                        {t('orders.newClient')} +
                      </MaterialButton>
                    </Link>
                  </div>
                </GridItem>
              </GridContainer>
              <Divider style={{ margin: '20px 0' }} />
              <h2>{t('orders.clientInfo')}</h2>
              <GridContainer>
                <GridItem md={12}>
                  <SelectInput
                    classes={classes}
                    mapBy="name"
                    trackBy="@id"
                    label={t('orders.diet') + ' *'}
                    value={selectedDiet}
                    options={diets}
                    handleChange={(e, element) => {
                      setDietName(element.name);
                      setDiet(e.target.value);
                      setPromoCode(null);
                      selectVariant(null);
                      selectCalories(null);
                    }}
                    size={12}
                  />
                </GridItem>
                <GridItem md={12}>
                  <div
                    onClick={() =>
                      selectedDiet === ''
                        ? openToast({
                            messages: [t('orders.firstSelectDate')],
                            type: 'info',
                            autoHideDuration: 3000,
                          })
                        : null
                    }
                  >
                    <SelectInput
                      classes={classes}
                      disabled={selectedDiet === ''}
                      mapBy="name"
                      label={t('orders.variant') + ' *'}
                      trackBy="@id"
                      value={selectedVariant}
                      options={getVariantOptions()}
                      handleChange={event => {
                        const variant = event.target.value;
                        selectVariant(variant);
                        selectCalories(null);
                      }}
                      size={12}
                    />
                  </div>
                </GridItem>
                <GridItem md={12}>
                  <div
                    onClick={() =>
                      selectedVariant === ''
                        ? openToast({
                            messages: [t('orders.firstSelectVariant')],
                            type: 'info',
                            autoHideDuration: 3000,
                          })
                        : null
                    }
                  >
                    <SelectInput
                      disabled={selectedVariant === ''}
                      classes={classes}
                      mapBy="name"
                      label={t('orders.calories') + ' *'}
                      trackBy="id"
                      value={selectedCalories}
                      options={caloriesOptions}
                      handleChange={event => selectCalories(event.target.value)}
                      size={12}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              {brandInfo && brandInfo.premiumType === 'PREMIUM' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dietSelectCheckbox}
                      onChange={e => {
                        setDietSelectCheckbox(!dietSelectCheckbox);
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={t('orders.possibilitySelectMeals')}
                />
              )}
            </GridItem>
            <GridItem md={6}>
              <GridItem md={12}>
                <h2>{t('orders.dietDaysSelect')}</h2>
                <GridContainer>
                  <GridItem md={6}>
                    <div
                      style={{
                        ...orderStyles.box,
                      }}
                    >
                      <p>{t('orders.dietTime')} *</p>
                      {isEmpty(dietLengthOptions) ? (
                        <Counter
                          min={minDietLength}
                          max={maxDietLength}
                          count={dietLength}
                          handleChangeValue={handleDietLengthInputChange}
                          handleClickDecrement={handleClickDecrement}
                          handleClickIncrement={handleClickIncrement}
                        />
                      ) : (
                        <SelectInput
                          classes={classes}
                          mapBy="name"
                          trackBy="value"
                          value={dietLength}
                          options={dietLengthOptions}
                          handleChange={event => {
                            selectDay([]);
                            setDietLength(event.target.value);
                          }}
                          size={12}
                        />
                      )}
                      {brandInfo &&
                        brandInfo.saturday.shouldBeDeliveredInBlock === 0 &&
                        brandInfo.sunday.shouldBeDeliveredInBlock === 0 && (
                          <p style={{ marginTop: '20px' }}>
                            {t('orders.weekendNotAv')}
                          </p>
                        )}
                      {brandInfo &&
                      brandInfo.saturday.shouldBeDeliveredInBlock !== 0 &&
                      brandInfo.sunday.shouldBeDeliveredInBlock !== 0 ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeWeekends}
                              onChange={() => {
                                setWeekendOption(!includeWeekends);
                                setSaturdayInclude(!saturdayInclude);
                                setSundayInclude(!sundayInclude);
                                //deselect weekend days when user disable weekends
                                saturdayInclude &&
                                  selectDay(
                                    selectedDays.filter(
                                      day => day.day() !== 6 && day.day() !== 0
                                    )
                                  );
                              }}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          label={t('orders.allowWeekends')}
                        />
                      ) : (
                        <div>
                          {brandInfo &&
                            brandInfo.saturday.shouldBeDeliveredInBlock !==
                              0 && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={saturdayInclude}
                                    onChange={() => {
                                      setSaturdayInclude(!saturdayInclude);
                                      //deselect weekend days when user disable weekends
                                      saturdayInclude &&
                                        selectDay(
                                          selectedDays.filter(
                                            day => day.day() !== 6
                                          )
                                        );
                                    }}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                label={t('orders.allowSaturdays')}
                              />
                            )}
                          {brandInfo &&
                            brandInfo.sunday.shouldBeDeliveredInBlock !== 0 && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={sundayInclude}
                                    onChange={() => {
                                      setSundayInclude(!sundayInclude);
                                      //deselect weekend days when user disable weekends
                                      sundayInclude &&
                                        selectDay(
                                          selectedDays.filter(
                                            day => day.day() !== 0
                                          )
                                        );
                                    }}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                label={t('orders.allowSundays')}
                              />
                            )}
                        </div>
                      )}
                    </div>
                  </GridItem>
                  <GridItem md={6}>
                    <div
                      style={{
                        ...orderStyles.box,
                      }}
                    >
                      <p style={calendarStyles.spaceBetween}>
                        {t('orders.selectedDays')}:{' '}
                        <b>
                          {selectedDays.length} / {dietLength}
                        </b>
                      </p>
                      <p style={calendarStyles.spaceBetween}>
                        {t('orders.daysLeast')}:{' '}
                        <b>{dietLength - selectedDays.length}</b>
                      </p>
                      <div style={{ textAlign: 'center', marginTop: '47px' }}>
                        <Button fullWidth onClick={ev => selectDay([])}>
                          {t('orders.clearSelection')}
                        </Button>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridContainer>
                <GridItem md={11} lg={12}>
                  <p style={calendarStyles.month}>
                    <KeyboardArrowLeft
                      style={{ cursor: 'pointer' }}
                      onClick={() => setMonth(month - 1)}
                    />
                    {getCurrentMonthAndYear(currMonth)}
                    <KeyboardArrowRight
                      style={{ cursor: 'pointer' }}
                      onClick={() => setMonth(month + 1)}
                    />
                  </p>

                  <div style={calendarStyles.calendar}>
                    {moment.weekdaysMin(true).map((el, index) => (
                      <div key={index} style={calendarStyles.weekName}>
                        {el}
                      </div>
                    ))}
                    {currentMonthDatesToDisplay.map((el, index) => {
                      const disabledDaysMap = disabledDays.map(d =>
                        new Date(d).getTime()
                      );
                      const today = new Date(moment()).setHours(0, 0, 0, 0);
                      return (
                        <div
                          style={
                            el && isDateInArray(el, selectedDays)
                              ? calendarStyles.selectedDay
                              : el &&
                                ((el.day() === 6 && !saturdayInclude) ||
                                  (el.day() === 0 && !sundayInclude) ||
                                  disabledDaysMap.some(
                                    date =>
                                      date === new Date(el).setHours(0, 0, 0, 0)
                                  ))
                              ? calendarStyles.disabledDay
                              : calendarStyles.day
                          }
                          onClick={() => el && selectDietDay(el)}
                          key={index}
                          className="no-scrollbar"
                        >
                          <div
                            style={
                              today === new Date(el).setHours(0, 0, 0, 0)
                                ? calendarStyles.todayMark
                                : {}
                            }
                          />
                          <p
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                              margin: '0',
                            }}
                          >
                            {el && el.format('DD')}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={3}>
              <h2>{t('orders.deliveryInfo')}</h2>
              <GridContainer>
                <GridItem md={12}>
                  <div
                    onClick={() =>
                      selectedClient === ''
                        ? openToast({
                            messages: [t('orders.clientFirstSelect')],
                            type: 'info',
                            autoHideDuration: 3000,
                          })
                        : null
                    }
                  >
                    <SelectInput
                      disabled={selectedClient === ''}
                      classes={classes}
                      mapBy="name"
                      trackBy="value"
                      label={t('orders.delivery') + ' *'}
                      value={addressType}
                      options={addressTypeSelect}
                      handleChange={event => {
                        setAddressType(event.target.value);
                        selectUserAddress('');
                        selectPickup('');
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem md={12}>
                  <div
                    onClick={() =>
                      selectedClient === ''
                        ? openToast({
                            messages: [t('orders.clientFirstSelect')],
                            type: 'info',
                            autoHideDuration: 3000,
                          })
                        : null
                    }
                  >
                    {selectedClient &&
                    userAddresses.length === 0 &&
                    addressType === 'indoor' ? (
                      <div>
                        <p>{t('orders.thisUserDontHaveAddrs')}</p>
                        <Button
                          color={'success'}
                          fullWidth
                          onClick={() => setAddressModalState(true)}
                        >
                          {t('orders.clientAddresses')}
                        </Button>
                      </div>
                    ) : addressType === 'indoor' ? (
                      <div>
                        <SelectInput
                          disabled={selectedClient === ''}
                          classes={classes}
                          mapBy="fullAddress"
                          trackBy="@id"
                          label={t('orders.address') + ' *'}
                          value={selectedUserAddress}
                          options={userAddresses}
                          handleChange={event => {
                            selectUserAddress(event.target.value);
                          }}
                        />
                        <Button
                          color={'success'}
                          fullWidth
                          onClick={() => setAddressModalState(true)}
                        >
                          {t('orders.addNewAddress')} +
                        </Button>
                      </div>
                    ) : (
                      <SelectInput
                        disabled={selectedClient === '' || addressType === ''}
                        classes={classes}
                        mapBy="value"
                        trackBy="@id"
                        label={t('orders.pickupPoint') + ' *'}
                        value={selectedPickup}
                        options={pickUpPoints}
                        handleChange={event => {
                          selectPickup(event.target.value);
                        }}
                      />
                    )}
                  </div>
                </GridItem>
                <GridItem md={12}>
                  <SelectInput
                    classes={classes}
                    mapBy="label"
                    trackBy="value"
                    label={t('orders.payment') + ' *'}
                    value={paymentType}
                    options={paymentMethods}
                    handleChange={event => {
                      selectPaymentType(event.target.value);
                    }}
                  />
                </GridItem>
                {brandInfo && brandInfo.ecoContainer && (
                  <GridItem md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isEcoContainerIncluded}
                          onChange={e => {
                            setEcoContainerIncluded(!isEcoContainerIncluded);
                          }}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={t('orders.ecoBoxes')}
                    />
                  </GridItem>
                )}
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isInvoiceIncluded}
                        onChange={e => {
                          setIsInvoiceIncluded(!isInvoiceIncluded);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('orders.makeInvoice')}
                  />
                  {isInvoiceIncluded && (
                    <GridContainer>
                      <GridItem md={12}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('orders.companyName')}*
                        </FormLabel>
                        <FormTextInputNoGrid
                          classes={classes}
                          value={invoiceCompanyName}
                          handleChange={ev =>
                            setInvoiceCompanyName(ev.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem md={12}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('orders.taxId')}*
                        </FormLabel>
                        <NumberFormat
                          classes={classes}
                          customInput={FormTextInputNoGrid}
                          value={invoiceVatNumber}
                          format="##########"
                          onChange={ev => setInvoiceVatNumber(ev.target.value)}
                        />
                      </GridItem>
                      <GridItem md={6}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('orders.postcode')}*
                        </FormLabel>
                        <NumberFormat
                          classes={classes}
                          customInput={FormTextInputNoGrid}
                          value={invoiceAddressPostCode}
                          format="##-###"
                          onChange={ev =>
                            setInvoiceAddressPostCode(ev.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem md={6}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('orders.city')}*
                        </FormLabel>
                        <FormTextInputNoGrid
                          classes={classes}
                          value={invoiceAddressCity}
                          handleChange={ev =>
                            setInvoiceAddressCity(ev.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem md={12}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('orders.street')}*
                        </FormLabel>
                        <FormTextInputNoGrid
                          classes={classes}
                          value={invoiceAddressStreet}
                          handleChange={ev =>
                            setInvoiceAddressStreet(ev.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem md={6}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('orders.buildingNo')}*
                        </FormLabel>
                        <FormTextInputNoGrid
                          classes={classes}
                          value={invoiceAddressBuildNumber}
                          handleChange={ev =>
                            setInvoiceAddressBuildNumber(ev.target.value)
                          }
                        />
                      </GridItem>
                      <GridItem md={6}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('orders.localNo')}
                        </FormLabel>
                        <FormTextInputNoGrid
                          classes={classes}
                          value={invoiceAddressPlaceNumber}
                          handleChange={ev =>
                            setInvoiceAddressPlaceNumber(ev.target.value)
                          }
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                </GridItem>
                <GridItem md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sendConfirmation}
                        onChange={e => {
                          setSendConfirmation(!sendConfirmation);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('orders.sendClientNotify')}
                  />
                </GridItem>
              </GridContainer>
              {dietLength !== 0 &&
              dietLength - selectedDays.length === 0 &&
              selectedDiet &&
              selectedVariant &&
              selectedCalories &&
              priceAfterDiscount !== '' &&
              (selectedPickup || selectedUserAddress) ? (
                <div
                  style={{
                    ...orderStyles.box,
                  }}
                >
                  <h4>
                    <b>{t('orders.summary')}</b>
                  </h4>
                  <Divider />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={usePromoCode}
                        onChange={e => {
                          usePromoCode && selectPromoCode(null);
                          setUsePromoCode(!usePromoCode);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('orders.useDiscountCode')}
                  />
                  {usePromoCode && (
                    <Async
                      styles={asyncSelectStyles}
                      isClearable={true}
                      loadOptions={promisePromoCodes}
                      onChange={code => selectPromoCode(code)}
                      value={promoCode}
                      placeholder={t('orders.discountCode')}
                      clearValue={() => selectPromoCode(null)}
                      noOptionsMessage={() => t('orders.discountCodeContent')}
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useMoneyBox}
                        onChange={e => {
                          applyMoneyBoxPoints();
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={t('orders.useUserPoints')}
                  />
                  <h4 style={calendarStyles.spaceBetween}>
                    {t('orders.moneyBoxPoints')}{' '}
                    <span>
                      {userMoneyBoxPoints} {t('common.point', 'pkt')} (
                      {userMoneyBoxAsPrice} {currencyToSymbol(currency)})
                    </span>
                  </h4>
                  <h4 style={calendarStyles.spaceBetween}>
                    {t('orders.pricePerDay')}:{' '}
                    <span>
                      {pricePerDayAfterDiscount !== ''
                        ? `${pricePerDayAfterDiscount.toFixed(
                            2
                          )} ${currencyToSymbol(currency)}`
                        : '-'}
                    </span>
                  </h4>
                  <h4 style={calendarStyles.spaceBetween}>
                    {t('orders.diet')}:{' '}
                    <span>
                      {dietPriceAfterDiscount !== ''
                        ? `${dietPriceAfterDiscount.toFixed(
                            2
                          )} ${currencyToSymbol(currency)}`
                        : '-'}
                    </span>
                  </h4>
                  <h4 style={calendarStyles.spaceBetween}>
                    {t('orders.delivery')}:{' '}
                    <span>
                      {deliveryPriceAfterDiscount !== ''
                        ? `${deliveryPriceAfterDiscount.toFixed(
                            2
                          )} ${currencyToSymbol(currency)}`
                        : '-'}
                    </span>
                  </h4>
                  <h4 style={calendarStyles.spaceBetween}>
                    {t('orders.orderValue')}:{' '}
                    <span>
                      {priceBeforeDiscount !== ''
                        ? `${priceBeforeDiscount.toFixed(2)} ${currencyToSymbol(
                            currency
                          )}`
                        : '-'}
                    </span>
                  </h4>
                  <h4>{t('orders.discounts')}</h4>
                  {Object.keys(usedDiscounts).map(discountKey => (
                    <h4 key={discountKey} style={calendarStyles.spaceBetween}>
                      {usedDiscountsTranslations(t)[discountKey]}:{' '}
                      <span>
                        -{usedDiscounts[discountKey].discount.toFixed(2)}{' '}
                        {currencyToSymbol(currency)}
                      </span>
                    </h4>
                  ))}
                  <h4 style={calendarStyles.spaceBetween}>
                    <b>{t('orders.discountSummary')}: </b>
                    <b>
                      {priceDiscount !== ''
                        ? `-${priceDiscount.toFixed(2)} ${currencyToSymbol(
                            currency
                          )}`
                        : '-'}
                    </b>
                  </h4>
                  <h4 style={calendarStyles.spaceBetween}>
                    <b>{t('orders.priceAfter')}: </b>
                    <b>
                      {priceAfterDiscount
                        ? `${priceAfterDiscount.toFixed(2)} ${currencyToSymbol(
                            currency
                          )}`
                        : '-'}
                    </b>
                  </h4>
                </div>
              ) : (
                <div
                  style={{
                    ...orderStyles.box,
                  }}
                >
                  <h4>
                    <b>{t('orders.summary')}</b>
                  </h4>
                  <Divider />
                  <p style={{ marginTop: '15px' }}>{t('orders.summaryShow')}</p>
                </div>
              )}
            </GridItem>
            <FormControlButtons
              classes={classes}
              discardText={t('orders.cancelOrder')}
              submitText={t('orders.createNewOrder')}
              cancelPath="/admin/orders"
              submitDisabled={isSubmitting}
              handleSubmit={handleSubmit}
            />
          </GridContainer>
        </CardBody>
      </Card>
    </form>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  const variants = sortByKey(state.Variants.variants, 'position');
  const diets = sortByKey(state.Diets.diets, 'position');
  return {
    diets,
    variants,
    selectedBrand: state.Auth.selectedBrand,
    currency: state.Brands.brand.multinational.currency,
  };
};

const mapDispatchToProps = {
  fetchDiets,
  fetchVariants,
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AddOrder);
