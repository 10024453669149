import React from 'react';

import { withTranslation } from 'react-i18next';

import CardBody from '../Card/CardBody';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import SimpleWysiwyg from '../FormTextInput/SimpleWysiwyg';
import Card from '../Card/Card';

const DescriptionCard = ({ t, value, setValue, title }) => {
  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <p
              style={{
                margin: '20px 0 10px',
                color: 'rgba(0,0,0,.46)',
                fontWeight: 400,
              }}
            >
              {title}
            </p>
            <SimpleWysiwyg
              handleChange={e => setValue(e.target.value)}
              value={value}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(DescriptionCard);
