import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { combineStyles } from 'helpers/helpers';
import { connect } from 'react-redux';
import { fetchUser } from 'actions/Users';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { FakeUserIcon } from 'components';
import NavPills from 'components/NavPills/NavPills.jsx';
import GeneralInfo from './FormTabs/GeneralInfo/GeneralInfo';
import List from './FormTabs/Addresses/List';
import Moneybox from './FormTabs/Moneybox/Moneybox';
import withRole from 'components/Security/withRole';
import {
  ROLE_SHOW_ADDRESS,
  ROLE_SHOW_MONEY_BOX_HISTORY,
  ROLE_SHOW_ORDER,
  ROLE_SHOW_CLIENT_CALL,
  ROLE_NOTE_CLIENT,
} from 'helpers/roles';
import { Card } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import Orders from './FormTabs/Orders/Orders';
import Diets from './FormTabs/Diets/Diets';
import History from './FormTabs/History/History';
import Calls from './FormTabs/Calls/Calls';
import Notes from 'components/Notes/Notes';
import { withTranslation } from 'react-i18next';
import { ROLE_SHOW_NOTE } from 'helpers/roles';
import { isGranted } from 'helpers/helpers';
import { fetchBrandConfigModulesPack } from 'actions/Brands';
class UserForm extends Component {
  state = {
    userFullName: '',
  };

  isEdit = this.props.match.path.includes('edit');
  userId = this.props.match.params.id;

  componentDidMount = () => {
    this.props.fetchBrandConfigModulesPack(this.props.selectedBrand, [
      'ConfigClientPanel',
    ]);
  };

  getUserFullName = name => {
    this.setState({
      userFullName: name,
    });
  };

  render() {
    const { classes, t } = this.props;

    const AddressList = withRole(ROLE_SHOW_ADDRESS, List);
    const MoneyBox = withRole(ROLE_SHOW_MONEY_BOX_HISTORY, Moneybox);
    const OrdersList = withRole(ROLE_SHOW_ORDER, Orders);
    const DietsList = withRole(ROLE_SHOW_ORDER, Diets);
    const CallsList = withRole(ROLE_SHOW_CLIENT_CALL, Calls);

    return (
      <div>
        <h2>
          {this.isEdit ? (
            <span>
              <b>{this.state.userFullName}</b>
              <FakeUserIcon
                email={this.props.user.email}
                domain={this.props.user.homeDomain}
              />
            </span>
          ) : (
            t('clients.clientCreation')
          )}
        </h2>
        <NavPills
          color="warning"
          changeTabOnMount={{
            bool: this.props.location.search.includes('openAddresses'),
            index: 1,
            timeout: 1000,
          }}
          tabs={
            this.isEdit
              ? [
                  {
                    tabButton: t('clients.basicInfo'),
                    tabContent: (
                      <GeneralInfo
                        getUserFullName={this.getUserFullName}
                        classes={classes}
                      />
                    ),
                  },
                  {
                    tabButton: t('clients.addresses'),
                    tabContent: (
                      <Card>
                        <CardBody>
                          <AddressList classes={classes} />
                        </CardBody>
                      </Card>
                    ),
                  },
                  {
                    tabButton: t('clients.moneybox'),
                    tabContent: (
                      <Card>
                        <CardBody>
                          <MoneyBox
                            userMoneybox={this.props.user.moneyBox}
                            fetchUser={this.props.fetchUser}
                            classes={classes}
                          />
                        </CardBody>
                      </Card>
                    ),
                  },
                  {
                    tabButton: t('clients.diets'),
                    tabContent: (
                      <Card>
                        <CardBody>
                          <DietsList userId={this.userId} />
                        </CardBody>
                      </Card>
                    ),
                  },
                  {
                    tabButton: t('clients.orders'),
                    tabContent: (
                      <Card>
                        <CardBody>
                          <OrdersList
                            history={this.props.history}
                            classes={classes}
                            userId={this.userId}
                          />
                        </CardBody>
                      </Card>
                    ),
                  },
                  {
                    tabButton: t('clients.historyActivity'),
                    tabContent: (
                      <Card>
                        <CardBody>
                          <History
                            history={this.props.history}
                            classes={classes}
                            userId={this.userId}
                          />
                        </CardBody>
                      </Card>
                    ),
                  },
                  {
                    tabButton: t('clients.connections', 'Połączenia'),
                    tabContent: (
                      <Card>
                        <CardBody>
                          <CallsList userId={this.userId} />
                        </CardBody>
                      </Card>
                    ),
                  },
                  ...(isGranted(ROLE_SHOW_NOTE) || isGranted(ROLE_NOTE_CLIENT)
                    ? [
                        {
                          tabButton: t('clients.notes', 'Notatki'),
                          tabContent: (
                            <Card>
                              <CardBody>
                                <Notes
                                  classes={classes}
                                  urlParam={`/clients/${this.userId}`}
                                  notePermission={ROLE_NOTE_CLIENT}
                                />
                              </CardBody>
                            </Card>
                          ),
                        },
                      ]
                    : []),
                ]
              : [
                  {
                    tabButton: t('clients.basicInfo'),
                    tabContent: <GeneralInfo classes={classes} />,
                  },
                ]
          }
        />
      </div>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  user: state.Users.user,
  selectedBrand: state.Auth.selectedBrand,
});

const mapDispatchToProps = {
  fetchUser,
  fetchBrandConfigModulesPack,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combinedStyles)(withTranslation()(withRouter(UserForm))));
