import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { withToast } from 'material-ui-toast-redux';

import {
  fetchIngredient,
  fetchIngredientCategories,
} from 'actions/Ingredients';
import { fetchAllergens } from 'actions/Allergens';
import { fetchUnits } from 'actions/Units';
import { fetchWholesalers } from 'actions/Wholesalers';

import { post, put } from 'helpers/apiHelpers';
import {
  countBeforeDecimal,
  countDecimal,
  toDecimalPlaces,
} from 'helpers/helpers';

import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import FormSelectMultiple from 'components/FormSelect/FormSelectMultiple';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import defaultState from './defaultState';
import LogView from '../../components/History/LogView';
import SelectInput from '../../components/FormSelect/SelectInput';
import Danger from '../../components/Typography/Danger';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { get } from 'helpers/apiHelpers';
import { Trans } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';
import Button from 'components/CustomButtons/Button';

class Form extends Component {
  state = {
    ...defaultState,
    edit: this.props.match.path.includes('edit'),
    isLoading: true,
    contractors: [],
    contractor: null,
  };

  componentDidMount = () => {
    Promise.all([
      this.props.fetchAllergens(),
      this.props.fetchUnits(),
      this.props.fetchIngredientCategories(),
      this.props.fetchWholesalers(),
      get('/contractors', { pagination: false }),
    ]).then(res => {
      const { 'hydra:member': contractors } = res[4];

      this.state.edit &&
        this.props
          .fetchIngredient(this.props.match.params.id)
          .then(this.loadIngredient);
      this.props.location.search.includes('fromSavedIngredient') &&
        this.loadIngredient();

      this.setState({
        isLoading: false,
        contractors: [
          {
            '@id': null,
            name: this.props.t(
              'form.field.contractor.selectOption.noContractor'
            ),
          },
          ...contractors,
        ],
      });
    });
  };

  handleToggle = state => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  getCaloriesSumByWeight = () => {
    const elementsWeights = {
      proteins: 4,
      carbohydrates: 4,
      fat: 9,
      dietaryFiber: 2,
    };

    const computeElementWeight = element => {
      return (+this.state[element] || 0) * elementsWeights[element];
    };

    const elementsToSum = ['proteins', 'carbohydrates', 'fat', 'dietaryFiber'];

    let caloriesSumByWeight = 0;

    elementsToSum.forEach(
      element => (caloriesSumByWeight += computeElementWeight(element))
    );

    return caloriesSumByWeight;
  };

  caloriesErrorDetector = () => {
    const errorMargin = 0.1;

    const typedCalories = +this.state.calories;
    const computedCalories = this.getCaloriesSumByWeight();

    const typedVsComputedRatio = Math.abs(
      parseFloat(Math.abs(typedCalories / computedCalories || 0).toFixed(2)) - 1
    );

    const error = parseFloat(typedVsComputedRatio.toFixed(2)) > errorMargin;

    if (typedCalories && error) {
      return (
        <Danger>
          <Trans
            i18nKey="ingredients.calorificAlert"
            typedCalories={typedCalories}
            computedCalories={computedCalories}
          >
            Wpisana kaloryczność to <b>{{ typedCalories }} kcal</b>. Według
            wyliczeń na makroskładnikach, kaloryczność powinna wynosić{' '}
            <b>{{ computedCalories }} kcal</b>. Upewnij się, że w konfiguracji
            makroskładników i kaloryczności, nie popełniono błędu.
          </Trans>
        </Danger>
      );
    }
  };

  loadIngredient = () => {
    const toString = val => (val === null ? '' : val);

    this.setState({
      name: this.props.ingredient.name,
      workName: this.props.ingredient.workName,
      weight: this.props.ingredient.weight,
      calories: this.props.ingredient.calories,
      fat: toString(this.props.ingredient.fat),
      carbohydrates: toString(this.props.ingredient.carbohydrates),
      monosaccharide: toString(this.props.ingredient.sugar),
      proteins: toString(this.props.ingredient.protein),
      dietaryFiber: toString(this.props.ingredient.roughage),
      salt: toString(this.props.ingredient.salt),
      nkt: toString(this.props.ingredient.saturatedFattyAcids),
      note: this.props.ingredient.note,
      wot: toString(this.props.ingredient.thermalProcessing),
      wom: toString(this.props.ingredient.workingOnMachining),
      pricePerKg: toString((this.props.ingredient.price * 10).toFixed(2)),
      pricePerUnit: (
        (this.props.ingredient.price * this.props.ingredient.weight) /
        100
      ).toFixed(4),
      glycemicIndex: toString(this.props.ingredient.glycemicIndex),
      unit: this.props.ingredient.unit
        ? this.props.ingredient.unit['@id']
        : null,
      categories: this.props.ingredient.categories,
      allergens: this.props.ingredient.allergens,
      wholesaler: this.props.ingredient.wholesaler
        ? this.props.ingredient.wholesaler['@id']
        : null,
      inventoryAmount: this.props.ingredient.inventoryAmount,
      hermesCardIndex: this.props.ingredient.hermesCardIndex,
      contractor: this.props.ingredient.contractor,
      homeIngredient: this.props.ingredient.homeIngredient,
      allert: this.props.ingredient.allert,
    });
  };

  validateObligatoryFields = () =>
    this.state.name &&
    this.state.categories.length > 0 &&
    this.state.weight !== '' &&
    this.state.calories !== '' &&
    this.state.unit &&
    this.state.wom &&
    this.state.wot;

  findIds = (items, collection) => {
    return items
      .map(item => {
        return collection.filter(
          collectionItem => collectionItem.value === item
        )[0];
      })
      .filter(item => item)
      .map(item => item['@id']);
  };

  submitForm = event => {
    event.preventDefault();

    if (!this.validateObligatoryFields()) {
      return this.props.openToast({
        messages: [this.props.t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const toNull = state => (state === '' ? null : state);

    const data = {
      name: this.state.name.toString(),
      workName: this.state.workName.toString(),
      unit: this.state.unit,
      categories: this.state.categories.map(category => category['@id']),
      allergens: this.state.allergens.map(allergen => allergen['@id']),
      weight: this.state.weight,
      calories: this.state.calories,
      protein: toNull(this.state.proteins),
      carbohydrates: toNull(this.state.carbohydrates),
      fat: toNull(this.state.fat),
      thermalProcessing: toNull(this.state.wot),
      glycemicIndex: toNull(this.state.glycemicIndex),
      roughage: toNull(this.state.dietaryFiber),
      saturatedFattyAcids: toNull(this.state.nkt),
      salt: toNull(this.state.salt),
      sugar: toNull(this.state.monosaccharide),
      price: toNull(parseFloat((this.state.pricePerKg / 10).toFixed(4))),
      workingOnMachining: toNull(this.state.wom),
      wholesaler: this.state.wholesaler,
      hermesCardIndex: `${this.state.hermesCardIndex}`,
      inventoryAmount: toNull(this.state.inventoryAmount),
      contractor: toNull(this.state.contractor),
      note: this.state.note,
      homeIngredient: this.state.homeIngredient,
      allert: this.state.allert,
    };

    const action = this.state.edit
      ? put(`/ingredients/${this.props.match.params.id}`, data)
      : post('/ingredients', data);

    action.then(
      () => this.props.history.push('/admin/ingredients'),
      error => {
        return this.props.openToast({
          messages: [
            error.response.data['hydra:description'] ||
              this.props.t('form.smthWentWrong'),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  handleChange = event => {
    const isNumber = !_.isNaN(parseInt(event.target.value, 10));

    this.setState({
      [event.target.name]: isNumber
        ? _.toNumber(event.target.value)
        : event.target.value === ''
        ? null
        : event.target.value,
    });
  };

  recalculatePricePerKg = event => {
    const parsedInt = parseFloat(event.target.value);
    const pricePerKg = (this.state.pricePerUnit * (1000 / parsedInt)).toFixed(
      2
    );

    this.setState({ pricePerKg });
  };

  handleWeightBeforeProcessingChange = event => {
    if (this.state.pricePerUnit > 0) {
      this.recalculatePricePerKg(event);
    }
    this.handleChange(event);
  };

  handleWeightChange = ({ target: { name, value } }) => {
    const inputName = name;

    if (countBeforeDecimal(value) > 13) return;

    switch (inputName) {
      case 'pricePerKg': {
        const pricePerKg =
          countDecimal(value) <= 2 ? value : toDecimalPlaces(value, 2);
        this.setState({ pricePerKg });

        if (this.state.weight) {
          const pricePerUnit = (
            pricePerKg *
            (this.state.weight / 1000)
          ).toFixed(4);

          this.setState({ pricePerUnit });
        }

        break;
      }

      case 'pricePerUnit': {
        const pricePerUnit =
          countDecimal(value) <= 4 ? value : toDecimalPlaces(value, 4);
        this.setState({ pricePerUnit });

        if (this.state.weight) {
          const pricePerKg = pricePerUnit / (this.state.weight / 1000);
          const cutDecimalPricePerKg =
            countDecimal(pricePerKg) <= 4
              ? pricePerKg
              : toDecimalPlaces(pricePerKg, 4);

          this.setState({
            pricePerKg: parseFloat(cutDecimalPricePerKg).toFixed(2),
          });
        }
        break;
      }

      default:
    }
  };

  render() {
    const { classes } = this.props;

    const basicOptions = [
      {
        label: (
          <>
            <span>{this.props.t('form.field.nameForClient') + '*'}</span>{' '}
            <Tooltip
              title={
                <div>
                  <h4>{this.props.t('form.field.nameTooltip1')}</h4>
                </div>
              }
              placement="right"
            >
              <Info style={{ marginLeft: '5px' }} fontSize={'small'} />
            </Tooltip>
          </>
        ),
        id: 'nameInput',
        type: 'text',
        name: 'name',
        value: this.state.name,
      },
      {
        label: this.props.t('form.field.weightBeforeProcessing') + '*',
        id: 'weightInput',
        type: 'number',
        name: 'weight',
        value: this.state.weight,
      },
      {
        label: (
          <>
            <span>{this.props.t('form.field.workingName')}</span>{' '}
            <Tooltip
              title={
                <div>
                  <h4>{this.props.t('form.field.workingNameTooltip1')}</h4>
                  <h4>{this.props.t('form.field.workingNameTooltip2')}</h4>
                </div>
              }
              placement="right"
            >
              <Info style={{ marginLeft: '5px' }} fontSize={'small'} />
            </Tooltip>
          </>
        ),
        id: 'workNameInput',
        type: 'text',
        name: 'workName',
        value: this.state.workName,
      },
    ];

    const options = [
      {
        label: this.props.t('form.field.fat'),
        id: 'fatInput',
        type: 'number',
        name: 'fat',
        value: this.state.fat,
      },
      {
        label: this.props.t('form.field.absorbableCarbohydrates'),
        id: 'carbohydratesInput',
        type: 'number',
        name: 'carbohydrates',
        value: this.state.carbohydrates,
      },
      {
        label: this.props.t('form.field.simpleSugars'),
        id: 'monosaccharideInput',
        name: 'monosaccharide',
        type: 'number',
        value: this.state.monosaccharide,
      },
      {
        label: this.props.t('form.field.protein'),
        id: 'proteinsInput',
        type: 'number',
        name: 'proteins',
        value: this.state.proteins,
      },
      {
        label: this.props.t('form.field.fiber'),
        id: 'dietaryFiberInput',
        type: 'number',
        name: 'dietaryFiber',
        value: this.state.dietaryFiber,
      },
      {
        label: this.props.t('form.field.salt'),
        id: 'saltInput',
        type: 'number',
        name: 'salt',
        value: this.state.salt,
      },
      {
        label: this.props.t('form.field.nkt'),
        id: 'nktInput',
        type: 'number',
        name: 'nkt',
        value: this.state.nkt,
      },
      {
        label: this.props.t('form.field.glycemicIndex.label'),
        id: 'glycemicIndexInput',
        type: 'number',
        name: 'glycemicIndex',
        value: this.state.glycemicIndex,
      },
      {
        label: (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {this.props.t('form.field.caloriesPer100g')} *
            <Tooltip
              title={
                <div>
                  <h4>{this.props.t('form.caloriesInfo1')}</h4>
                  <h4>{this.props.t('form.caloriesInfo2')}</h4>
                  <br />
                  <h4>{this.props.t('form.caloriesInfo3')}</h4>
                  <h4>{this.props.t('form.caloriesInfo4')}</h4>
                  <h4>{this.props.t('form.caloriesInfo5')}</h4>
                  <h4>{this.props.t('form.caloriesInfo6')}</h4>
                </div>
              }
              placement="right"
            >
              <Info style={{ marginLeft: '5px' }} fontSize={'small'} />
            </Tooltip>
          </div>
        ),
        id: 'caloriesInput',
        type: 'number',
        name: 'calories',
        value: this.state.calories,
      },
    ];

    const processingOptions = [
      {
        label: this.props.t('form.field.wot') + '*',
        id: 'wotInput',
        type: 'number',
        name: 'wot',
        value: this.state.wot,
      },
      {
        label: this.props.t('form.field.wom') + '*',
        id: 'womInput',
        type: 'number',
        name: 'wom',
        value: this.state.wom,
      },
    ];

    const salesOptions = currency => [
      {
        label: this.props.t('form.field.pricePerUnitCurr', {
          replace: { currency },
        }),
        id: 'pricePerUnitInput',
        type: 'number',
        name: 'pricePerUnit',
        value:
          this.state.pricePerUnit ||
          this.state.price * (this.state.weight / 1000),
      },
      {
        label: this.props.t('form.field.pricePerKgCurr', {
          replace: { currency },
        }),
        id: 'pricePerKgInput',
        type: 'number',
        name: 'pricePerKg',
        value: this.state.pricePerKg,
      },
    ];

    const additionalSalesOptions = [
      {
        label: this.props.t('form.field.availability'),
        id: 'inventoryAmount',
        type: 'number',
        name: 'inventoryAmount',
        value: this.state.inventoryAmount,
      },
    ];

    const salesSelectsOptions = [
      {
        Component: FormSelectSingle,
        label: this.props.t('form.field.wholesalers'),
        options: this.props.wholesalers,
        value: this.state.wholesaler,
        name: 'wholesaler',
        id: 'wholesaler',
        mapBy: 'value',
        trackBy: '@id',
      },
    ];

    const selectsOptions = [
      {
        Component: FormSelectMultiple,
        label: this.props.t('form.field.categories') + '*',
        options: this.props.ingredientCategories,
        value: this.props.ingredientCategories.filter(el =>
          this.state.categories.some(filter => el['@id'] === filter['@id'])
        ),
        name: 'categories',
        id: 'categories',
        mapBy: 'value',
      },
      {
        Component: FormSelectSingle,
        label: this.props.t('form.field.unit') + '*',
        options: this.props.units,
        value: this.state.unit,
        name: 'unit',
        id: 'unit',
        mapBy: 'value',
        trackBy: '@id',
      },
      {
        Component: FormSelectMultiple,
        name: 'allergens',
        id: 'allergens',
        options: this.props.allergens,
        value: this.props.allergens.filter(el =>
          this.state.allergens.some(filter => el['@id'] === filter['@id'])
        ),
        label: this.props.t('form.field.allergens'),
        mapBy: 'value',
      },
    ];

    return (
      <form
        id="newIngredientForm"
        onSubmit={this.submitForm}
        style={{
          opacity: this.state.isLoading ? 0 : 1,
          transition: '0.3s all',
        }}
      >
        <GridContainer>
          <GridItem xs={12}>
            <h2
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {' '}
              {this.state.edit
                ? this.props.t('form.ingredientEdit')
                : this.props.t('form.ingredientCreation')}{' '}
              {this.state.edit && (
                <Button
                  component={Link}
                  color="primary"
                  variant="contained"
                  to={`/admin/ingredients/add?fromSavedIngredient`}
                >
                  {this.props.t(
                    'form.copyToNewIngredient',
                    'Skopiuj do nowego składnika'
                  )}
                </Button>
              )}
            </h2>
            <Card>
              <CardHeader>
                <h4>{this.props.t('form.basicInfo')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem md={10}>
                    <GridContainer>
                      <GridItem md={12}>
                        <FormLabel className={classes.labelHorizontal}>
                          {' '}
                          {basicOptions[0].label}{' '}
                        </FormLabel>
                        <CustomInput
                          id={basicOptions[0].id}
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            id: basicOptions[0].id,
                            type: basicOptions[0].type,
                            name: basicOptions[0].name,
                            onChange: this.handleChange,
                            value: basicOptions[0].value,
                          }}
                        />
                      </GridItem>
                      <GridItem md={12}>
                        <FormLabel className={classes.labelHorizontal}>
                          {' '}
                          {basicOptions[2].label}{' '}
                        </FormLabel>
                        <CustomInput
                          id={basicOptions[2].id}
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            id: basicOptions[2].id,
                            type: basicOptions[2].type,
                            name: basicOptions[2].name,
                            onChange: this.handleChange,
                            value: basicOptions[2].value,
                          }}
                        />
                      </GridItem>
                      <GridItem md={6}>
                        <FormLabel className={classes.labelHorizontal}>
                          {' '}
                          {selectsOptions[1].label}{' '}
                        </FormLabel>
                        <div style={{ marginTop: '-10px' }}>
                          <SelectInput
                            noGrid
                            classes={classes}
                            options={selectsOptions[1].options}
                            value={selectsOptions[1].value}
                            trackBy={selectsOptions[1].trackBy}
                            mapBy={selectsOptions[1].mapBy}
                            handleChange={this.handleChange}
                            name={selectsOptions[1].name}
                            id={selectsOptions[1].id}
                            labelSm={3}
                            selectSm={4}
                          />
                        </div>
                      </GridItem>
                      <GridItem md={6}>
                        <FormLabel className={classes.labelHorizontal}>
                          {' '}
                          {basicOptions[1].label}{' '}
                        </FormLabel>
                        <CustomInput
                          id={basicOptions[1].id}
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            id: basicOptions[1].id,
                            type: basicOptions[1].type,
                            name: basicOptions[1].name,
                            onChange: this.handleWeightBeforeProcessingChange,
                            value: basicOptions[1].value,
                          }}
                        />
                      </GridItem>
                      <GridItem md={12}>
                        <FormSelectMultiple
                          classes={classes}
                          label={selectsOptions[0].label}
                          options={selectsOptions[0].options}
                          value={selectsOptions[0].value}
                          trackBy={selectsOptions[0].trackBy}
                          mapBy={selectsOptions[0].mapBy}
                          handleChange={this.handleChange}
                          name={selectsOptions[0].name}
                          id={selectsOptions[0].id}
                          labelSm={3}
                          selectSm={4}
                        />
                      </GridItem>
                      <GridItem md={12}>
                        <FormSelectMultiple
                          classes={classes}
                          label={selectsOptions[2].label}
                          options={selectsOptions[2].options}
                          value={selectsOptions[2].value}
                          trackBy={selectsOptions[2].trackBy}
                          mapBy={selectsOptions[2].mapBy}
                          handleChange={this.handleChange}
                          name={selectsOptions[2].name}
                          id={selectsOptions[2].id}
                          labelSm={3}
                          selectSm={4}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem md={10}>
                    <GridContainer>
                      <GridItem sm={3} style={{ marginTop: '-5px' }}>
                        <Checkbox
                          style={{ marginLeft: '-14px' }}
                          id={'homeIngredient'}
                          onClick={() => this.handleToggle('homeIngredient')}
                          checked={this.state.homeIngredient}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                        <FormLabel
                          htmlFor={'homeIngredient'}
                          className={classes.labelHorizontal}
                        >
                          {this.props.t('form.field.homeIngredient')}
                        </FormLabel>
                      </GridItem>
                      <GridItem sm={3} style={{ marginTop: '-5px' }}>
                        <Checkbox
                          style={{ marginLeft: '-14px' }}
                          id={'allert'}
                          onClick={() => this.handleToggle('allert')}
                          checked={this.state.allert}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                        <FormLabel
                          htmlFor={'allert'}
                          className={classes.labelHorizontal}
                        >
                          {this.props.t('form.field.allert')}
                        </FormLabel>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <h4>{this.props.t('form.field.macronutrients')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem md={10}>
                    <GridContainer>
                      {options.map((option, index) => (
                        <GridItem md={3} key={index}>
                          <FormLabel className={classes.labelHorizontal}>
                            {' '}
                            {option.label}{' '}
                          </FormLabel>
                          <CustomInput
                            id={option.id}
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: option.type,
                              name: option.name,
                              onChange: this.handleChange,
                              value: option.value,
                            }}
                          />
                        </GridItem>
                      ))}
                      <GridItem md={3}>{this.caloriesErrorDetector()}</GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <h4>
                  {this.props.t('form.field.thermalAndMechanicalTreatment')}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem md={10}>
                    <GridContainer>
                      {processingOptions.map((option, index) => (
                        <GridItem md={3} key={index}>
                          <FormLabel className={classes.labelHorizontal}>
                            {' '}
                            {option.label}{' '}
                          </FormLabel>
                          <CustomInput
                            id={option.id}
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: option.type,
                              name: option.name,
                              onChange: this.handleChange,
                              value: option.value,
                            }}
                          />
                        </GridItem>
                      ))}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <h4>{this.props.t('form.field.shoppingInfo')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem md={10}>
                    <GridContainer>
                      <GridItem md={3}>
                        {salesOptions(this.props.currency).map(
                          (option, index) => (
                            <Fragment key={index}>
                              <FormLabel
                                style={{ marginTop: '8px' }}
                                className={classes.labelHorizontal}
                              >
                                {' '}
                                {option.label}{' '}
                              </FormLabel>
                              <CustomInput
                                id={option.id}
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                  type: option.type,
                                  name: option.name,
                                  onChange: this.handleWeightChange,
                                  value: option.value,
                                }}
                              />
                            </Fragment>
                          )
                        )}
                        {salesSelectsOptions.map((option, index) => {
                          return (
                            <option.Component
                              key={index}
                              classes={classes}
                              label={option.label}
                              options={option.options}
                              value={option.value}
                              trackBy={option.trackBy}
                              mapBy={option.mapBy}
                              handleChange={this.handleChange}
                              name={option.name}
                              id={option.id}
                              labelSm={3}
                              selectSm={4}
                            />
                          );
                        })}
                      </GridItem>
                      <GridItem md={3}>
                        {additionalSalesOptions.map((option, index) => (
                          <Fragment key={index}>
                            <FormLabel
                              style={{ marginTop: '8px' }}
                              className={classes.labelHorizontal}
                            >
                              {option.label}
                            </FormLabel>
                            <CustomInput
                              id={option.id}
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: option.type,
                                name: option.name,
                                onChange: this.handleChange,
                                value: option.value,
                              }}
                            />
                          </Fragment>
                        ))}

                        <FormSelectSingle
                          classes={classes}
                          label={this.props.t('form.field.contractor.label')}
                          options={this.state.contractors}
                          value={this.state.contractor}
                          trackBy="@id"
                          mapBy="name"
                          handleChange={this.handleChange}
                          name="contractor"
                          id="contractor"
                          labelSm={3}
                          selectSm={4}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader>
                <h4>{this.props.t('form.field.note')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem md={10}>
                    <GridContainer>
                      <GridItem md={10}>
                        <FormLabel className={classes.labelHorizontal}>
                          {' '}
                          {this.props.t('form.field.note')}
                        </FormLabel>
                        <CustomInput
                          id="noteInput"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            id: 'noteInput',
                            type: 'text',
                            name: 'note',
                            onChange: this.handleChange,
                            value: this.state.note,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <FormControlButtons
          classes={classes}
          discardText={this.props.t('form.cancel')}
          submitText={this.props.t('form.save')}
          cancelPath="/admin/ingredients"
          handleSubmit={this.submitForm}
        />
        <LogView iri={`/ingredients/${this.props.match.params.id}`} />
      </form>
    );
  }
}

const mapStateToProps = state => ({
  currency: state.Brands.brand.multinational.currency,
  allergens: state.Allergens.allergens,
  units: state.Units.units,
  ingredient: state.Ingredients.ingredient,
  ingredientCategories: state.Ingredients.ingredientCategories,
  wholesalers: state.Wholesalers.wholesalers,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchAllergens,
    fetchUnits,
    fetchIngredientCategories,
    fetchIngredient,
    fetchWholesalers,
  })(withToast(withStyles(extendedFormsStyle)(Form)))
);
