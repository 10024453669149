import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

import RootCheckbox from '@material-ui/core/Checkbox';
import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import makeStyles from '@material-ui/styles/makeStyles';
import { Info } from '@material-ui/icons';
import {
  fetchPaymentTypes,
  selectDefaultPaymentType,
  setEnabledPaymentTypes,
} from 'actions/Brands';

const useStyles = makeStyles({
  select_cell: {
    minWidth: 280,
  },
});

const paymentTypesTranslations = t => ({
  BANK_WIRE: t('paymentType.transfer'),
  CASH: t('paymentType.cash'),
  BLUE_MEDIA_CARD: t('paymentType.blueMediaCard'),
  PAYU_CARD: t('paymentType.payUCard'),
  STRIPE_CARD: t('paymentType.stripeCard'),
  BLUE_MEDIA: t('paymentType.blueMedia'),
  PAYPO: t('paymentType.payPo'),
  PAYU: t('paymentType.payU'),
  STRIPE_LINK: t('paymentType.stripeLink'),
  TPAY: t('paymentType.tPay'),
});

const orderTypes = ['dietStandard', 'dietSubscription', 'other'];

const subscriptionPaymentTypes = [
  'BLUE_MEDIA_CARD',
  'PAYU_CARD',
  'STRIPE_CARD',
];

const additionalPaymentTypes = [
  'BLUE_MEDIA_CARD',
  'PAYU_CARD',
  'STRIPE_CARD',
  'BLUE_MEDIA',
  'PAYPO',
  'PAYU',
  'STRIPE_LINK',
  'TPAY',
];

const PaymentTypeConfig = ({
  classes,
  selectedBrand,
  t,
  fetchPaymentTypes,
  paymentTypes,
  selectDefaultPaymentType,
  setEnabledPaymentTypes,
  configClientPanelModule,
  openToast,
}) => {
  const customStyles = useStyles();

  useEffect(() => {
    if (selectedBrand) {
      fetchPaymentTypes(selectedBrand);
    }
  }, [selectedBrand]);

  const handleCheckboxChange = (order, paymentType) => {
    if (paymentTypes[order].defaultPaymentType === paymentType) {
      openToast({
        messages: [t('notify.cantDeselectPaymentMethod')],
        type: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }

    const modifiedArray = [...paymentTypes[order].enabledPaymentTypes];
    const index = modifiedArray.indexOf(paymentType);

    if (index >= 0 && modifiedArray.length > 1) modifiedArray.splice(index, 1);
    if (index === -1) modifiedArray.push(paymentType);

    setEnabledPaymentTypes(order, modifiedArray);
  };

  const handleSubscriptionCheckboxChange = paymentType => {
    if (paymentTypes.dietSubscription.defaultPaymentType === paymentType) {
      openToast({
        messages: [t('notify.cantDeselectPaymentMethod')],
        type: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }

    setEnabledPaymentTypes('dietSubscription', [paymentType]);
  };

  const handleSelectChange = (order, paymentType) => {
    selectDefaultPaymentType(order, paymentType);
  };

  return (
    <>
      {paymentTypes && (
        <>
          <div style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    {t(
                      'module.configClientPanel.oneTimePayment',
                      'Płatność jednorazowa'
                    )}
                  </TableCell>
                  <TableCell>
                    {t('module.configClientPanel.subscription', 'Subskrypcja')}
                  </TableCell>
                  <TableCell>
                    {t(
                      'module.configClientPanel.orderPayment',
                      'Dodatkowe płatności'
                    )}
                    <Tooltip
                      title={
                        <h4>
                          {t('module.configClientPanel.orderPaymentTooltip')}
                        </h4>
                      }
                      placement="right"
                    >
                      <Info
                        style={{
                          marginLeft: '4px',
                          fontSize: '16px',
                          color: '#f50057',
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={customStyles.select_cell}>
                    {t(
                      'module.configClientPanel.defaultPaymentMode',
                      'Domyślny tryb Płatności'
                    )}
                  </TableCell>
                  <TableCell className={customStyles.select_cell}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        value={paymentTypes.dietStandard.defaultPaymentType}
                        onChange={e =>
                          handleSelectChange(e.target.name, e.target.value)
                        }
                        displayEmpty
                        name="dietStandard"
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                      >
                        {paymentTypes.dietStandard.enabledPaymentTypes &&
                          paymentTypes.dietStandard.enabledPaymentTypes.map(
                            paymentType => (
                              <MenuItem
                                value={paymentType}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple,
                                }}
                              >
                                {paymentTypesTranslations(t)[paymentType]}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={customStyles.select_cell}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        value={paymentTypes.dietSubscription.defaultPaymentType}
                        onChange={e =>
                          handleSelectChange(e.target.name, e.target.value)
                        }
                        displayEmpty
                        name="dietSubscription"
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        disabled={
                          !configClientPanelModule.isSubscriptionPaymentMode
                        }
                      >
                        {paymentTypes.dietSubscription.enabledPaymentTypes &&
                          paymentTypes.dietSubscription.enabledPaymentTypes.map(
                            paymentType => (
                              <MenuItem
                                value={paymentType}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple,
                                }}
                              >
                                {paymentTypesTranslations(t)[paymentType]}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={customStyles.select_cell}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        value={paymentTypes.other.defaultPaymentType}
                        onChange={e =>
                          handleSelectChange(e.target.name, e.target.value)
                        }
                        displayEmpty
                        name="other"
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                      >
                        {paymentTypes.other.enabledPaymentTypes &&
                          paymentTypes.other.enabledPaymentTypes.map(
                            paymentType => (
                              <MenuItem
                                value={paymentType}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple,
                                }}
                              >
                                {paymentTypesTranslations(t)[paymentType]}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>

                {Object.entries(paymentTypesTranslations(t)).map(
                  ([key, value]) => (
                    <TableRow>
                      <TableCell>{value}</TableCell>

                      {orderTypes.map(orderType => {
                        if (orderType === 'dietStandard') {
                          return (
                            <TableCell>
                              <RootCheckbox
                                checked={paymentTypes.dietStandard.enabledPaymentTypes.includes(
                                  key
                                )}
                                onChange={() =>
                                  handleCheckboxChange('dietStandard', key)
                                }
                              />
                            </TableCell>
                          );
                        }
                        if (orderType === 'dietSubscription') {
                          return subscriptionPaymentTypes.includes(key) ? (
                            <TableCell>
                              <RootCheckbox
                                checked={paymentTypes.dietSubscription.enabledPaymentTypes.includes(
                                  key
                                )}
                                onChange={() =>
                                  handleSubscriptionCheckboxChange(key)
                                }
                                disabled={
                                  !configClientPanelModule.isSubscriptionPaymentMode
                                }
                              />
                            </TableCell>
                          ) : (
                            <TableCell></TableCell>
                          );
                        }

                        if (orderType === 'other') {
                          return additionalPaymentTypes.includes(key) ? (
                            <TableCell>
                              <RootCheckbox
                                checked={paymentTypes.other.enabledPaymentTypes.includes(
                                  key
                                )}
                                onChange={() =>
                                  handleCheckboxChange('other', key)
                                }
                              />
                            </TableCell>
                          ) : (
                            <TableCell></TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
      selectedBrand,
      paymentTypes,
    }),
    {
      fetchPaymentTypes,
      selectDefaultPaymentType,
      setEnabledPaymentTypes,
    }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(PaymentTypeConfig);
