import GridItem from 'components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import React, { useEffect } from 'react';
import useCheckIsPaymentDisabled from 'helpers/useCheckIsPaymentDisabled';
import { useTranslation } from 'react-i18next';

const BankWireModule = ({
  classes,
  handleInputChange,
  transferAccount,
  transferCheck,
  transferInfo,
  validateAccountNumber,
  handleToggle,
}) => {
  const { t } = useTranslation();
  const bankWire = useCheckIsPaymentDisabled('BANK_WIRE');

  useEffect(() => {
    if (bankWire.isSelected && !transferCheck) {
      handleToggle('transferCheck');
    }
  }, [bankWire.isSelected]);

  return (
    <>
      <GridItem md={12}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() => handleToggle('transferCheck')}
            checked={transferCheck}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            disabled={bankWire.isSelected}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          <span>{t('brandCfg.bankWire')}</span>
          {bankWire.isSelected && (
            <div style={{ marginLeft: '10px' }}>
              <Tooltip
                title={
                  <h4>
                    {t(
                      `brandCfg.payments.disabled.${bankWire.paymentName}.tooltip`
                    )}
                  </h4>
                }
                placement="right"
              >
                <Info
                  fontSize="small"
                  style={{ color: 'grey', display: 'block' }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </GridItem>
      <GridItem md={6}>
        <NumberFormat
          label={t('brandCfg.bankWireNumber')}
          classes={classes}
          customInput={FormTextInput}
          value={!transferCheck ? '' : transferAccount}
          disabled={!transferCheck}
          success={validateAccountNumber()}
          error={!validateAccountNumber()}
          helpText={
            !validateAccountNumber() && transferAccount.length > 0
              ? t('brandCfg.wireNumberMustHave')
              : ''
          }
          format="## #### #### #### #### #### ####"
          onChange={handleInputChange}
          name="transferAccount"
          inputSize={12}
        />
      </GridItem>
      <GridItem md={12}>
        <FormTextInput
          label={t('brandCfg.infoForClient')}
          classes={classes}
          name="transferInfo"
          value={!transferCheck ? '' : transferInfo}
          disabled={!transferCheck}
          handleChange={handleInputChange}
          inputSize={10}
          multiline={true}
          rows={3}
          rowsMax={20}
        />
      </GridItem>
    </>
  );
};

export default BankWireModule;
