import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import React, { useEffect, useState } from 'react';
import { get, put } from '../../helpers/apiHelpers';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import Assignment from '@material-ui/icons/Assignment';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import TableInputContainer from './TableInputContainer';
import FormSelectSingle from '../../components/FormSelect/FormSelectSingle';
import { DialogLoader } from '../../components/DialogLoader';
import produce from 'immer';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  string_cell: {
    maxWidth: 300,
  },
  translations_cell: {
    minWidth: 200,
  },
});

let newTranslations = {};

const TranslationsTable = ({
  classes,
  t,
  catalog,
  subCatalog,
  isApi,
  openToast,
}) => {
  const [languages, setLanguages] = useState(null);
  const [languageOrder, setLanguageOrder] = useState(null);
  const [translations, setTranslations] = useState(null);
  const [urlSubCatalog, setUrlSubCatalog] = useState(subCatalog);
  const [isLoading, setIsLoading] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const customStyles = useStyles();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const languagesResponse = await get(`/languages`);
      setLanguages(languagesResponse['hydra:member']);

      const langOrder = Object.values(languagesResponse['hydra:member']).map(
        val => val.isoCode
      );
      setLanguageOrder(langOrder);

      const translationsResponse = await get(
        `/translations/${catalog}/${urlSubCatalog}`
      );
      setTranslations(translationsResponse);
      newTranslations = {};

      setIsLoading(false);
    })();
  }, [catalog, urlSubCatalog]);

  const changeTranslationValue = (string, language, value) => {
    newTranslations = produce(newTranslations, draftState => {
      if (value === translations[string][language]) {
        delete draftState[string][language];
        if (Object.keys(draftState[string]).length === 0) {
          delete draftState[string];
        }
        return;
      }
      if (draftState[string]) {
        draftState[string][language] = value;
      } else {
        draftState[string] = { [language]: value };
      }
    });
  };

  const handleSubmit = async () => {
    // setIsSubmitting(true);
    const translationObject = {
      [catalog]: {
        [subCatalog]: newTranslations,
      },
    };

    try {
      await put('/translations/update', translationObject);
      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (e) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      // setIsSubmitting(false);
    }
  };

  return (
    <>
      <DialogLoader loaderState={isLoading} text={t('common.loading')} />
      {/*<DialogLoader loaderState={isSubmitting} text={t('form.savingChanges')} />*/}

      {languages && translations && (
        <GridContainer style={{ marginTop: '15px' }}>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {isApi && (
                    <GridItem md={6}>
                      <FormSelectSingle
                        classes={classes}
                        options={[
                          { value: 'messages', name: 'Messages' },
                          { value: 'validators', name: 'Validators' },
                        ]}
                        mapBy="name"
                        trackBy="value"
                        value={urlSubCatalog}
                        handleChange={e => {
                          setUrlSubCatalog(e.target.value);
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem md={12}>
                    <div style={{ overflow: 'scroll', maxHeight: '520px' }}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={customStyles.string_cell}>
                              String
                            </TableCell>
                            {languages.map(lang => (
                              <TableCell
                                key={lang.name}
                                className={customStyles.translations_cell}
                              >
                                {lang.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.entries(translations).map(
                            ([string, translationValues]) => (
                              <TableRow key={string}>
                                <TableCell className={customStyles.string_cell}>
                                  {string}
                                </TableCell>
                                {languageOrder.map(lang => (
                                  <TableCell
                                    key={`${string}-${lang}`}
                                    className={customStyles.translations_cell}
                                  >
                                    <TableInputContainer
                                      string={string}
                                      translation={translationValues[lang]}
                                      language={lang}
                                      changeTranslationValue={
                                        changeTranslationValue
                                      }
                                    />
                                  </TableCell>
                                ))}
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </GridItem>
                </GridContainer>

                <FormControlButtons
                  classes={classes}
                  submitText={t('common.shared.save', 'Zapisz')}
                  handleSubmit={handleSubmit}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  })),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(TranslationsTable);
