import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_DISCOUNT_CODE } from 'helpers/roles';
import { connect } from 'react-redux';
import { fetchDiets } from '../../actions/Diets';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class List extends Component {
  componentDidMount() {
    this.props.fetchDiets();
  }

  newSize = () => this.props.history.push(`${window.location.pathname}/add`);
  render() {
    const { classes } = this.props;

    return (
      <AdminTable title={this.props.t('list.promoCodesList')} icon>
        {isGranted(ROLE_CREATE_DISCOUNT_CODE) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.newSize}
            submitText={this.props.t('list.generateCode')}
          />
        )}
        <DataGrid
          refreshOnFetch
          actions={true}
          previewPath={`${window.location.pathname}/preview`}
          remove={true}
          export={true}
          editPath={`${window.location.pathname}/edit`}
          paginationTop={true}
          paginationBottom={false}
          url="/discount-codes"
          reportName={'discountcode'}
          columns={columnConfig(this.props.diets, this.props.t)}
          role="DISCOUNT_CODE"
          manipulateQuery={(_, query) => {
            if (query.discount) {
              query['discount[]'] = query.discount;
              query['discount[_operator]'] = 'eq';
            }

            return query;
          }}
          canRemoveChecker={(_, row) => row.orders.length === 0}
        />
      </AdminTable>
    );
  }
}

const mapStateToProps = state => ({
  diets: state.Diets.diets,
});

const mapDispatchToProps = dispatch => ({
  fetchDiets: () => dispatch(fetchDiets()),
});

const enhance = compose(
  withStyles(buttonsStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
);

export default enhance(List);
