import moment from 'moment';

const columnConfig = [
  {
    title: 'Data',
    accessor: row => new moment(row.createdAt).format('DD.MM.YYYY'),
    name: 'createdAt',
    filterable: false,
    sortable: false,
    width: 100,
  },
  {
    title: 'Numer',
    accessor: 'data',
    name: 'data',
    filterable: false,
    sortable: false,
    width: 150,
  },
  {
    title: 'Treść',
    accessor: 'content',
    name: 'content',
    filterable: false,
    sortable: false,
  },
];

export default columnConfig;
