import { useState, useEffect } from 'react';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import { BlueMediaModule, PayUModule, StripeModule } from './modules';
import FormControlButtons from '../../components/FormControlButtons/FormControlButtons';
import Card from '../../components/Card/Card';
import React from 'react';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from '../../actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesNames } from './consts';
import { put } from '../../helpers/apiHelpers';
import { DialogLoader } from '../../components/DialogLoader';
import PaymentTypeConfig from './components/PaymentTypeConfig';
import PayPoModule from './modules/PayPoModule';
import BankWireModule from './modules/BankWireModule';
import TPayModule from './modules/TPayModule';

const PaymentMethods = ({
  classes,
  t,
  fetchBrand,
  paymentTypes,
  selectedBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
  openToast,
}) => {
  const [state, setState] = useState({
    tpayCheck: false,
    tpayId: '',
    tpayAuthKey: '',
    transferCheck: false,
    transferAccount: '',
    transferInfo: '',
    cashCheck: false,
    payPoCheck: false,
    payPoId: '',
    payPoToken: '',
    cashInfo: '',
  });
  const [modules, setModules] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const brandConfig = await fetchBrand(selectedBrand);
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      const newState = {
        tpayAuthKey: brandConfig.tpayAuth,
        tpayCheck: brandConfig.tpay,
        tpayId: brandConfig.tpayId,
        transferCheck: brandConfig.bankWire,
        transferInfo: brandConfig.bankWireNote,
        transferAccount:
          brandConfig.bankWireNumber === null ? '' : brandConfig.bankWireNumber,
        cashCheck: brandConfig.cash,
        cashInfo: brandConfig.cashNote,
        payPoCheck: brandConfig.paypo,
        payPoId: brandConfig.paypoConfig
          ? brandConfig.paypoConfig.merchantId
          : null,
        payPoToken: brandConfig.paypoConfig
          ? brandConfig.paypoConfig.token
          : null,
      };

      setState(prevState => ({ ...prevState, ...newState }));
      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleToggle = variableName => {
    setState(prevState => ({
      ...prevState,
      [variableName]: !prevState[variableName],
    }));
  };

  const handleInputChange = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const validateAccountNumber = () => {
    return state.transferCheck
      ? state.transferAccount.replace(/ /g, '').length === 26
      : true;
  };

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };

  const handleSubmit = async () => {
    const data = {
      paypo: state.payPoCheck,
      paypoConfig: {
        merchantId: state.payPoId,
        token: state.payPoToken,
      },
      tpay: state.tpayCheck,
      tpayAuth: state.tpayAuthKey,
      tpayId: state.tpayId,
      bankWire: state.transferCheck,
      bankWireNote: state.transferInfo,
      bankWireNumber: state.transferAccount,
      cash: state.cashCheck,
      cashNote: state.cashInfo,
    };

    try {
      await put(`/brands/${selectedBrand}`, data);
      await updateBrandConfigModulesPack(selectedBrand, modules);
      await put(`/brands/${selectedBrand}/modules`, {
        module: 'ItemPaymentTypes',
        configuration: {
          ItemPaymentTypes: paymentTypes,
        },
      });

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (e) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {!isLoading && (
        <>
          <Card>
            <CardBody>
              <GridContainer>
                <TPayModule
                  classes={classes}
                  tpayCheck={state.tpayCheck}
                  tpayId={state.tpayId}
                  tpayAuthKey={state.tpayAuthKey}
                  handleInputChange={handleInputChange}
                  handleToggle={handleToggle}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <BankWireModule
                  classes={classes}
                  transferCheck={state.transferCheck}
                  transferInfo={state.transferInfo}
                  transferAccount={state.transferAccount}
                  handleInputChange={handleInputChange}
                  validateAccountNumber={validateAccountNumber}
                  handleToggle={handleToggle}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <Checkbox
                    style={{ marginLeft: '-14px' }}
                    onClick={() => handleToggle('cashCheck')}
                    checked={state.cashCheck}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                  {t('brandCfg.pickupPointPayment')}
                </GridItem>
                <GridItem md={12}>
                  <FormTextInput
                    label={t('brandCfg.infoForClient')}
                    classes={classes}
                    name="cashInfo"
                    value={!state.cashCheck ? '' : state.cashInfo}
                    disabled={!state.cashCheck}
                    handleChange={handleInputChange}
                    inputSize={10}
                    multiline={true}
                    rows={3}
                    rowsMax={20}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <PayPoModule
                  classes={classes}
                  payPoCheck={state.payPoCheck}
                  payPoId={state.payPoId}
                  payPoToken={state.payPoToken}
                  handleInputChange={handleInputChange}
                  handleToggle={handleToggle}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <PayUModule
                  classes={classes}
                  handleModuleUpdate={handleModuleUpdate}
                  module={modules[modulesNames.PayU]}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <StripeModule
                  classes={classes}
                  handleModuleUpdate={handleModuleUpdate}
                  module={modules[modulesNames.Stripe]}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <GridContainer>
                <BlueMediaModule
                  classes={classes}
                  handleModuleUpdate={handleModuleUpdate}
                  module={modules[modulesNames.BlueMedia]}
                />
              </GridContainer>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <PaymentTypeConfig
                configClientPanelModule={modules.ConfigClientPanel}
              />
            </CardBody>
          </Card>

          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
      paymentTypes,
      selectedBrand,
    }),
    { fetchBrand, fetchBrandConfigModulesPack, updateBrandConfigModulesPack }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(PaymentMethods);
