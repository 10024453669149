import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import ContainerReplacementSection from './components/ContainerReplacementSection';
import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_DICTIONARY } from 'helpers/roles';

import { withTranslation } from 'react-i18next';

class List extends Component {
  new = () => this.props.history.push(`${window.location.pathname}/add`);

  renderCustomRemoveBody = props => {
      return (
        <ContainerReplacementSection {...props} classes={this.props.classes} />
      );
  };

  render() {
    const { classes } = this.props;

    return (
      <AdminTable title={this.props.t(this.props.tableTitle)} icon>
        {isGranted(
          this.props.createRole ? this.props.createRole : ROLE_CREATE_DICTIONARY
        ) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={this.new}
            submitText={`${this.props.t(
              'common.mainMenu.listAdd'
            )} ${this.props.t(this.props.tableName)} +`}
          />
        )}
        <DataGrid
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          remove={true}
          export={true}
          renderCustomRemoveBody={this.renderCustomRemoveBody}
          paginationTop={true}
          paginationBottom={false}
          url={this.props.valueUrl}
          columns={columnConfig}
          role={this.props.roleName ? this.props.roleName : 'DICTIONARY'}
        />
      </AdminTable>
    );
  }
}

export default withTranslation()(withStyles(buttonsStyle)(List));
