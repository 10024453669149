import React from 'react';
import Button from '@material-ui/core/Button';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { withTranslation } from 'react-i18next';
import { openSweetToast } from '../../actions/App';
import { connect } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import LockIcon from '@material-ui/icons/Lock';
import axios from '../../helpers/gastro';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import reportNames from '../../views/Archive/reportNames';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';

class DownloadButton extends React.Component {
  state = {
    isGenerating: false,
  };

  getFileName = ({ type, reportName, generatedAt }, backendFileName) => {
    const key = reportNames?.[type]?.[reportName];

    const fullReportName = `${this.props.t(
      'reportArchive.type.' + type
    )}-${this.props.t(key || reportName)}`;
    return `${this.props.t('reportArchive')}(${moment(generatedAt).format(
      'YYYY-MM-DD_HH.mm'
    )})-${fullReportName
      .replaceAll(' ', '_')
      .toLowerCase()}.${backendFileName.split('.').pop()}`;
  };

  download = row => {
    this.setState({ isGenerating: true });
    axios
      .get(`/export-archives/${row.id}/download`, { responseType: 'blob' })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            this.getFileName(row, response.headers['x-file-name'])
          );
          document.body.appendChild(link);
          link.click();
          this.setState({ isGenerating: false });
        },
        error => {
          this.props.openToast({
            messages: [
              this.props.t('reports.cannotGenerate'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          this.setState({ isGenerating: false });
        }
      );
  };

  render() {
    if (!this.props.accessGranted) {
      return (
        <Tooltip
          title={<h4>{this.props.t('reportArchive.tooltip.access')}</h4>}
          placement="top"
        >
          <LockIcon size={20} />
        </Tooltip>
      );
    }
    if (this.props.row.fileRemoved) {
      return (
        <Tooltip
          title={<h4>{this.props.t('reportArchive.tooltip.expired')}</h4>}
          placement="top"
        >
          <NotInterestedIcon size={20} />
        </Tooltip>
      );
    }
    if (this.state.isGenerating) {
      return <CircularProgress size={20} />;
    }

    return (
      <Tooltip
        title={<h4>{this.props.t('reportArchive.tooltip.generate')}</h4>}
        placement="top"
      >
        <GetAppIcon
          style={{ cursor: 'pointer' }}
          onClick={() => this.download(this.props.row)}
        />
      </Tooltip>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

DownloadButton.propTypes = {};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  openSweetToast,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(DownloadButton);
