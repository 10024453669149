import React, { Component } from 'react';
import defaultState from './defaultState';
import { withRouter } from 'react-router-dom';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchUser, clearUser } from 'actions/Employees';
import { post, put } from 'helpers/apiHelpers';
import { fetchBrandsList, fetchBrands } from 'actions/Brands';
import { fetchRoles } from 'actions/Roles';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectInput from 'components/FormSelect/SelectInput';
import { errorNotificationMapObject } from '../../../helpers/helpers';
import { ReactPhoneNumberAdapter } from 'components/ReactPhoneNumberAdapter/ReactPhoneNumberAdapter';
import { withTranslation } from 'react-i18next';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormLabel from '@material-ui/core/FormLabel';
import userPhotoTemplate from 'assets/img/new_logo.png';

class GeneralInfo extends Component {
  state = {
    ...defaultState,
    passwordError: '',
    emailTaken: null,
    loading: false,
  };

  userId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount = async () => {
    this.props.fetchBrandsList();
    this.props.fetchRoles();
    let user = { ...defaultState };
    if (this.userId) {
      const givenUser = await this.props.fetchUser(this.userId);

      user = {
        firstName: givenUser.firstName,
        lastName: givenUser.lastName,
        phone: givenUser.phone,
        email: givenUser.email,
        selectedBrands: givenUser.brands.map(brand => brand['@id']),
        selectedRoles: givenUser.permissions.map(
          permission => permission['@id']
        ),
        image: givenUser.image?.['@id'],
        imageUrl: givenUser.image?.contentUrl,
      };
    }

    this.setState(user);
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateEmail = () => {
    return this.state.email.includes('@');
  };

  validatePassword = () => {
    return this.state.plainPasswordMatch === this.state.plainPassword;
  };

  validateForm = () => {
    if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.phone.number ||
      !this.state.email
    ) {
      this.props.openToast({
        messages: [
          this.props.t(
            'errors.fillAllRequiredFields',
            'Wypelnij wszystkie obowiązkowe pola oznaczone gwiazdką'
          ),
        ],

        type: 'error',
        autoHideDuration: 3000,
      });

      return false;
    }

    if (!this.validateEmail()) {
      this.props.openToast({
        messages: [
          this.props.t(
            'myCompany.toast.incorrectEmail',
            'Nieprawidłowy adres email'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });

      return false;
    }

    if (!this.validatePassword()) {
      this.props.openToast({
        messages: [
          this.props.t('errors.passwordsDontMatch', 'Hasła nie są takie same'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });

      return false;
    }

    return true;
  };

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return;
    }

    this.setState({
      loading: true,
    });

    const data = {
      type: 'EMPLOYEE',
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email.toLowerCase(),
      plainPassword:
        this.state.plainPassword !== '' ? this.state.plainPassword : null,
      brands: this.state.selectedBrands,
      image: this.state.image,
      permissions: this.state.selectedRoles,
    };

    if (!this.isEdit) {
      data.language = this.props.adminDefaultLanguage ?? 'pl';
    }

    const action = this.isEdit
      ? put(`/employees/${this.userId}`, data)
      : post('/employees', data);

    action.then(
      () => {
        this.setState({
          loading: false,
        });
        this.props.history.push('/admin/employees');
        window.location.reload();
      },
      error => {
        const { propertyPath } = error.response.data.violations[0];
        if (
          propertyPath === 'email' ||
          propertyPath === 'plainPassword' ||
          propertyPath === 'phone'
        ) {
          this.setState({
            loading: false,
          });

          const messages = error.response.data.violations.map(
            ({ propertyPath, message }) => {
              const prefix = errorNotificationMapObject[propertyPath];
              return `${prefix}: ${message}`;
            }
          );

          return this.props.openToast({
            messages,
            type: 'error',
            autoHideDuration: 3000,
          });
        } else {
          this.setState({
            loading: false,
          });

          return this.props.openToast({
            messages: [this.props.t('employees.smthWrong')],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    );
  };

  render() {
    const { classes, t } = this.props;
    const {
      email,
      phone,
      imageUrl,
      lastName,
      firstName,
      plainPassword,
      selectedRoles,
      selectedBrands,
      plainPasswordMatch,
    } = this.state;

    return (
      <form>
        <h2>{this.userId ? t('employees.edit') : t('employees.create')} </h2>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem sm={6}>
                <FormTextInput
                  label={t('employees.firstName') + '*'}
                  classes={classes}
                  name="firstName"
                  value={firstName}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                  maxLength={64}
                />
                <FormTextInput
                  label={t('employees.lastName') + '*'}
                  classes={classes}
                  name="lastName"
                  value={lastName}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                  maxLength={64}
                />

                <ReactPhoneNumberAdapter
                  label={t('employees.phone') + '*'}
                  name="phone"
                  value={phone}
                  onChange={this.handleInputChange}
                />

                <SelectInput
                  classes={classes}
                  multiple={true}
                  label={t('employees.role')}
                  mapBy="name"
                  trackBy="@id"
                  name="selectedRoles"
                  value={selectedRoles}
                  options={this.props.roles}
                  handleChange={this.handleInputChange}
                  id="roles"
                  size={12}
                />
                <SelectInput
                  classes={classes}
                  multiple={true}
                  label={t('employees.brand')}
                  options={this.props.brands}
                  value={selectedBrands}
                  mapBy="name"
                  trackBy="@id"
                  name="selectedBrands"
                  handleChange={this.handleInputChange}
                  id="brands"
                  size={12}
                />
                <FormTextInput
                  label={t('employees.emailA') + '*'}
                  classes={classes}
                  name="email"
                  value={email}
                  noAutoComplete={true}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={
                    this.isEdit ? t('employees.newPass') : t('employees.pass')
                  }
                  classes={classes}
                  name="plainPassword"
                  value={plainPassword}
                  type="password"
                  noAutoComplete={true}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
                <FormTextInput
                  label={
                    this.isEdit ? t('employees.rNewpass') : t('employees.rpass')
                  }
                  classes={classes}
                  name="plainPasswordMatch"
                  value={plainPasswordMatch}
                  success={
                    this.state.plainPassword !== '' &&
                    this.state.plainPassword === this.state.plainPasswordMatch
                  }
                  error={
                    !(
                      this.state.plainPassword === this.state.plainPasswordMatch
                    )
                  }
                  type="password"
                  noAutoComplete={true}
                  handleChange={this.handleInputChange}
                  inputSize={12}
                />
              </GridItem>
              <GridItem md={6}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '20px' }}
                >
                  Zdjęcie:
                </FormLabel>
                <FormImageUpload
                  classes={classes}
                  stateName="image"
                  getImage={this.getImage}
                  removeImage={this.removeImage}
                  previewUrl={this.state.imageUrl}
                  defaultImage={userPhotoTemplate}
                />
              </GridItem>
              {this.state.loading ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CircularProgress />
                </div>
              ) : (
                <FormControlButtons
                  classes={classes}
                  loading={this.state.loading}
                  discardText={t('common.shared.cancel')}
                  submitText={t('common.shared.save')}
                  cancelPath="/admin/employees"
                  handleSubmit={this.handleSubmit}
                />
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  user: state.Employees.user,
  brands: state.Brands.brandsList,
  roles: state.Roles.roles,
  adminDefaultLanguage: state.Brands.brand.multinational.adminDefaultLanguage,
});

const mapDispatchToProps = dispatch => ({
  fetchUser: id => dispatch(fetchUser(id)),
  clearUser: () => dispatch(clearUser()),
  fetchBrandsList: () => dispatch(fetchBrandsList()),
  fetchBrands: () => dispatch(fetchBrands()),
  fetchRoles: () => dispatch(fetchRoles()),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation(),
  withToast
);

export default enhance(GeneralInfo);
