import googleFonts from './googleFonts';

export const modulesNames = {
  Addons: 'Addons',
  AuthServices: 'AuthServices',
  BlueMedia: 'BlueMedia',
  Caching: 'Caching',
  ConfigClientPanel: 'ConfigClientPanel',
  FacebookPixel: 'FacebookPixel',
  Fakturownia: 'Fakturownia',
  Fonts: 'Fonts',
  Goodspeed: 'Goodspeed',
  GoogleAnalytics: 'GoogleAnalytics',
  GoogleTagManager: 'GoogleTagManager',
  HotJar: 'HotJar',
  PayU: 'PayU',
  SalesManago: 'SalesManago',
  Stripe: 'Stripe',
  MyLead: 'MyLead',
  PaymentModesCopy: 'PaymentModesCopy',
  ClickOnMetrics: 'ClickOnMetrics',
  PollsterTrack: 'PollsterTrack',
};

export const modulesDefaultState = {
  ConfigClientPanel: {
    '@type': modulesNames.ConfigClientPanel,
    defaultPaymentMode: 2,
    enableVariantStep: true,
    isOneTimePayPaymentMode: true,
    isSubscriptionPaymentMode: false,
    oneTimePaymentDefaultGateway: 'TPAY',
    orderPaymentDefaultGateway: 'TPAY',
    referralProgramDescription: '',
    hideDisabledDaysOnClientCalendar: false,
    subscriptionPaymentDefaultGateway: 'STRIPE_CARD',
    validateBagChangesWithSlots: false,
    showCalendarIcons: true,
    useAddressesWithLessFields: false,
    allowClientPayWithMoneboxEverywhere: false,
  },
  Fakturownia: {
    '@type': modulesNames.Fakturownia,
    addonsCustomName: '',
    addonsCustomNameEnabled: false,
    allowInvoicesForClient: false,
    bagModificationName: '',
    bagModificationVat: 8,
    deliveryItemName: '',
    department: null,
    dietItemName: '',
    enabled: false,
    host: '',
    integrationType: 'DISABLED',
    token: '',
  },
  PayU: {
    '@type': modulesNames.PayU,
    clientId: null,
    clientSecret: '',
    enabled: false,
    md5Key: '',
    posId: null,
    sandbox: false,
  },
  Stripe: {
    '@type': modulesNames.Stripe,
    cardPayments: false,
    enabled: false,
    linkPayments: false,
    publishableKey: '',
    rulesLink: null,
    secretKey: '',
  },
  MyLead: {
    '@type': modulesNames.MyLead,
    clickId: null,
    enabled: false,
    payoutDecimal: null,
    providerHash: null,
    userId: null,
  },
  ClickOnMetrics: {
    '@type': modulesNames.ClickOnMetrics,
    enabled: false,
    trackerId: null,
  },
};

export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const dayFields = [
  'newOrder',
  'newOrderSubscription',
  'changeDate',
  'changeAddress',
  'changeDiet',
  'buyAddons',
  'paymentDeadline',
  'shouldBeDeliveredIn',
  'shouldBeDeliveredInBlock',
];

export const timeFields = [
  'newOrder',
  'newOrderSubscription',
  'buyAddons',
  'changeDiet',
  'changeDate',
  'changeAddress',
  'paymentDeadline',
  'rate',
];

export const dayObject = (curr = {}) => {
  let obj = {};

  dayFields.forEach(field => {
    obj[field] = 0;
  });

  return { ...obj, ...curr };
};

// export const defaultBrandConfig = {
//   name: '',
//   shortName: '',
//   mainDomain: '',
//   termsLink: '',
//   marketingTermsLink: '',
//   homePageLink: '',
//   logoLink: '',
//   moneyBoxReadMoreLink: '',
//   termsContent: '',
//   marketingTermsContent: '',
//   facebookEnabled: false,
//   facebookAppId: '',
//   facebookSecretKey: '',
//   appleEnabled: false,
//   appleClientId: '',
//   googleEnabled: false,
//   googleClientId: '',
//   googleClientSecret: '',
//   email: '',
//   phone: { countryCode: 'PL', number: null },
//   description: '',
//   logo: null,
//   logoMobile: null,
//   logoDesktop: null,
//   logoMail: null,
//   ecoContainer: false,
//   smsApiEnabled: false,
//   smsApiToken: '',
//   logoUrl: '',
//   tpayCheck: false,
//   tpayId: '',
//   tpayAuthKey: '',
//   transferCheck: false,
//   transferAccount: '',
//   transferInfo: '',
//   cashCheck: false,
//   payPoCheck: false,
//   payPoId: '',
//   payPoToken: '',
//   cashInfo: '',
//   referralsCheck: false,
//   mainColor: '#000000',
//   backgroundColor: '#FAFBFC',
//   backgroundImage: null,
//   backgroundImageUrl: null,
//   useImageAsBackground: false,
//   calendarText: '',
//   disabledDays: [],
//   adminDays: [],
//   days: [],
//   daysSelectorType: null,
//   daysArrayActive: true,
//   daysDefault: null,
//   daysMax: null,
//   daysMin: null,
//   monday: 1,
//   tuesday: 0,
//   wednesday: 0,
//   thursday: 0,
//   friday: 0,
//   saturday: 0,
//   sunday: 0,
//   deliveryDaysOptions: [
//     { label: 'Niedostępny', name: 'inaccessible', value: 0 },
//     { label: 'Poniedziałek', name: 'monday', value: 1 },
//     { label: 'Wtorek', name: 'tuesday', value: 2 },
//     { label: 'Środa', name: 'wednesday', value: 3 },
//     { label: 'Czwartek', name: 'thursday', value: 4 },
//     { label: 'Piątek', name: 'friday', value: 5 },
//     { label: 'Sobota', name: 'saturday', value: 6 },
//     { label: 'Niedziela', name: 'sunday', value: 7 },
//   ],
//   mailerEncryption: null,
//   encryptionOptions: [
//     { label: 'Automatyczne', value: 'auto' },
//     { label: 'SSL', value: 'ssl' },
//     { label: 'TLS', value: 'tls' },
//   ],
//   mailerHost: '',
//   mailerFrom: '',
//   mailerName: '',
//   mailerPort: '',
//   mailerUsername: '',
//   mailerPassword: '',
//   mailAfterRegistration: false,
//   mailAfterOrder: false,
//   mailAfterOrderStatusChange: false,
//   allowInvoicesForClient: false,
//   pickUpPointEnabled: false,
//   pickUpPointInformationLink: '',
//   deliveryToDoorEnabled: true,
//   allChangesWithoutCosts: false,
//   allowChangeAddress: false,
//   allowChangeCalorific: false,
//   allowChangeVariant: false,
//   allowChangeDeliveryDate: false,
//   allowChangeMultipleDays: false,
//   allowChangeDiet: false,
//   testDays: [],
//   testDaysDefault: null,
//   testDaysEnabled: false,
//   testDaysMax: null,
//   testDaysMin: null,
//   testDaysSelectorType: null,
//   showRefererCode: false,
//   showMenuButton: false,
//   menuButtonLink: '',
//   showCalculatorLink: false,
//   calculatorLink: '',
//   privacyPolicyLink: '',
//   googleTagManager: null,
//   facebookPixel: null,
//   allowGoogleTagManager: false,
//   allowGoogleAnalytics: false,
//   googleAnalytics: '',
//   allowFacebookPixel: false,
//   allowHotJar: false,
//   hotJarId: '',
//   hotJarVersion: '',
//   showFacebookCodeReference: true,
//   showLinkedinCodeReference: true,
//   showTwitterCodeReference: true,
//   showWhatsappCodeReference: true,
//   showMessengerCodeReference: true,
//   facebookCodeReferenceLink: '',
//   linkedinCodeReferenceLink: '',
//   whatsappCodeReferenceLink: '',
//   twitterCodeReferenceLink: '',
//   messengerCodeReferenceLink: '',
//   premiumType: 'DISABLED',
//   premiumAdditionalStep: false,
//   mailing: {
//     remove_account_client: false,
//     remove_account_office: false,
//     new_account_client: false,
//     new_account_office: false,
//     new_order_client: false,
//     new_order_office: false,
//     order_status_change_client: false,
//     order_status_change_office: false,
//     change_with_surcharge_client: false,
//     change_with_surcharge_office: false,
//     change_with_refund_client: false,
//     change_with_refund_office: false,
//     lost_password_client: false,
//     lost_password_office: false,
//     end_of_diet_reminder: false,
//     end_of_diet_reminder_config: [],
//   },
//   newOrderInfoEnabled: false,
//   newOrderInfoTitle: '',
//   newOrderInfoDescription: '',
//   newOrderInfoBannerDesktop: null,
//   newOrderInfoBannerDesktopUrl: null,
//   newOrderInfoBannerMobile: null,
//   newOrderInfoBannerMobileUrl: null,
//   customScripts: '',
//   clientFontLibrary: 'google',
//   clientFontLibraryParams: {},
//   clientFont: 'Nunito Sans',
//   clientFontLibraryFonts: [...googleFonts],
//   fonts: [],
//   showInformation: {
//     macroIngredients: false,
//     dishCalorific: false,
//     dishImage: false,
//     allergens: false,
//     dishComposition: false,
//     tags: false,
//     servingProposal: false,
//     avgDishRating: false,
//     glycemicIndex: { show: false, tooltip: '' },
//   },
//   multinational: {
//     clientAllowedToChangeLanguage: false,
//   },
// };
