import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class RecipeStickers extends React.Component {
  getFileName = title => ({ dateFrom, dateTo }) => {
    return `${title.replaceAll(
      ' ',
      '_'
    )}-${dateFrom}-${dateTo}_(${moment().format('YYYY-MM-DD_HH.mm')})`;
  };

  render() {
    const { t } = this.props;
    const title = t('common.mainMenu.reportRecipeStickers');
    return (
      <BaseBrandAndDateReport
        title={title}
        excelUrl="reports/stickers-recipe"
        fileName={this.getFileName(title)}
        role={roles.ROLE_SHOW_REPORT_RECIPE_STICKERS}
        pdfAvaliable={false}
        excelAvaliable={true}
        useDateRange={true}
        archiveReportNames={['RECIPE_STICKERS']}
      />
    );
  }
}

export default withTranslation()(RecipeStickers);
