const defaultState = {
  companyImage: null,
  companyImageUrl: null,
  name: '',
  shortName: '',
  mainDomain: '',
  email: '',
  phone: '',
  street: '',
  city: '',
  postCode: '',
  vatNumber: '',
  regon: '',
  mainColor: '#000000',
  defaultConfigFromBrand: '',
  allowHotJar: false,
  hotJarId: '',
  hotJarVersion: '',
  loadAllVariantsInitially: false,
  allowZohoSalesIQ: false,
  zohoSalesIQWidgetCode: '',
  dishIngredientsDecimalPlaces: 2,
};

export default defaultState;
