import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import GeneralInfo from './GeneralInfo/GeneralInfo';

class UserForm extends Component {
  render() {
    const { classes } = this.props;

    return <GeneralInfo classes={classes} />;
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(UserForm);
