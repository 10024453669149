import PropTypes from 'prop-types';
import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
import { v4 as uuidv4 } from 'uuid';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button';
import CardIcon from 'components/Card/CardIcon';

import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from 'components/Security/AccessDenied';
import ReportConfigurator from 'components/Report/ReportConfigurator';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CombinedReportRow from './CombinedReportRow';
import { AddCircle } from '@material-ui/icons';
import Archive from '../../../components/Archive/Archive';
import { fetchZoneCategories } from 'actions/ZoneCategories';

const defaultRow = () => ({
  _key: uuidv4(),
  brands: [],
  subBrands: [],
  zoneCategories: [],
  dateFrom: '',
  dateTo: '',
  multiplier: 1,
  includeSubscriptions: false,
});

class CombinedReport extends React.Component {
  state = {
    isGenerating: false,
    parameters: [defaultRow()],
  };

  componentDidMount = async () => {
    if (this.props.useZoneCategories) {
      await this.props.fetchZoneCategories();
    }
  };

  addNextRow = () =>
    this.setState(prevState => ({
      ...prevState,
      parameters: [...prevState.parameters, defaultRow()],
    }));

  onRowRemoved = row => {
    this.setState(prevState => ({
      ...prevState,
      parameters: prevState.parameters.filter(
        parameter => parameter._key !== row._key
      ),
    }));
  };

  onRowUpdated = row => {
    const parameters = this.state.parameters;

    let rowIndex = parameters.findIndex(
      parameter => parameter._key === row._key
    );

    parameters[rowIndex] = { ...parameters[rowIndex], ...row };

    this.setState(prevState => ({
      ...prevState,
      parameters: parameters,
    }));
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validate = row => {
    if (!this.validateDate(row)) {
      this.props.openToast({
        messages: [this.props.t('reports.sDate')],
        type: 'error',
        autoHideDuration: 3000,
      });
      return false;
    }
    if (!this.validateBrands(row)) {
      this.props.openToast({
        messages: [this.props.t('reports.selectBrand')],
        type: 'error',
        autoHideDuration: 3000,
      });
      return false;
    }

    return true;
  };

  validateDate = row => {
    if (this.props.validateDatePicker) {
      return this.props.useDateRange
        ? row.dateFrom && row.dateTo
        : row.dateFrom;
    }

    return true;
  };

  validateBrands = row => {
    if (this.props.validateBrands) {
      return row.brands.length !== 0;
    }

    return true;
  };

  resolveBtnTxt = mimeType => {
    switch (mimeType) {
      case 'application/pdf':
        return this.props.t('reports.gPDF');
      case 'application/vnd.ms-excel':
        return this.props.t('reports.gExcel', { format: 'XLSX' });
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return this.props.t('reports.gExcel', { format: 'XLS' });
      case 'text/comma-separated-values':
        return this.props.t('reports.gCSV');
      default:
        return 'reports.gReport';
    }
  };

  resolveFileExtension = mimeType => {
    switch (mimeType) {
      case 'application/pdf':
        return 'pdf';
      case 'application/vnd.ms-excel':
        return 'xlsx';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xls';
      case 'text/comma-separated-values':
        return 'csv';
      default:
        return '';
    }
  };

  handleGenerate = mimeType => {
    if (this.state.parameters.some(parameter => !this.validate(parameter))) {
      return;
    }
    this.setState({ isGenerating: true });

    const parameters = this.state.parameters.map(parameter => ({
      ...parameter,
      brands: parameter.brands.filter(val => val).map(val => val.value),
      subBrands: parameter.subBrands.filter(val => val).map(val => val.value),
      zoneCategories: parameter.zoneCategories
        .filter(val => val)
        .map(val => val.value),
    }));

    let additionalParams = {};
    if (typeof this.props.additionalParams === 'object') {
      additionalParams = this.props.additionalParams;
    }

    const params = {
      parameters: parameters,
      ...additionalParams,
    };

    axios
      .get(this.props.url, {
        responseType: 'blob',
        params: params,
        headers: { accept: mimeType },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              this.props.fileName(this.state) +
                '.' +
                this.resolveFileExtension(mimeType)
            );
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(this.props.t('reports.noAccess'));
          }
        },
        error => {
          if (typeof error.response !== 'undefined') {
            this.props.openToast({
              messages: [
                this.props.t('reports.cannotGenerate'),
                error.response.data['hydra:description'],
              ],
              type: 'error',
              autoHideDuration: 3000,
            });
          } else {
            console.error(error);
            this.props.openToast({
              messages: [this.props.t('reports.cannotGenerate'), error],
              type: 'error',
              autoHideDuration: 3000,
            });
          }
          this.setState({ isGenerating: false });
        }
      );
  };

  render() {
    const { classes, title, company, reportConfiguration } = this.props;
    const companyId = parseInt(company.split('/').pop());
    const reportKey = reportConfiguration?.report || false;
    const fields = reportConfiguration?.fields;

    return isGranted(this.props.role) ? (
      <>
        <Card style={{ marginTop: '20px' }}>
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>{this.props.t('reports.generate')}</h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <PlaylistAddCheck />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {title}
              {reportKey && (
                <ReportConfigurator
                  companyId={companyId}
                  report={reportKey}
                  fields={fields}
                />
              )}
            </h4>
          </CardHeader>
          <CardBody>
            {this.state.parameters.map((el, index) => (
              <CombinedReportRow
                key={el._key}
                _key={el._key}
                onRowRemoved={this.onRowRemoved}
                onRowUpdated={this.onRowUpdated}
                canBeRemoved={index !== 0}
                brands={el.brands}
                subBrands={el.subBrands}
                zoneCategories={el.zoneCategories}
                dateFrom={el.dateFrom}
                dateTo={el.dateTo}
                multiplier={el.multiplier}
                includeSubscriptions={el.includeSubscriptions}
                useDateRange={this.props.useDateRange}
                useSubBrands={this.props.useSubBrands}
                useZoneCategories={this.props.useZoneCategories}
                showMultiplier={this.props.showMultiplier}
                showIncludeSubscriptions={this.props.showIncludeSubscriptions}
              />
            ))}
            {this.props.children}
            <GridContainer justify={'space-between'}>
              <GridItem>
                <Button
                  onClick={() => this.addNextRow()}
                  disabled={this.state.isGenerating}
                  color={'default'}
                  round
                  justIcon
                >
                  <AddCircle />
                </Button>
              </GridItem>
              <div style={{ display: 'flex' }}>
                {this.props.mimeTypes.map(mimeType => {
                  return (
                    <GridItem>
                      <Button
                        className={`btn--generate-${this.resolveFileExtension(
                          mimeType
                        )}`}
                        onClick={() => this.handleGenerate(mimeType)}
                        disabled={this.state.isGenerating}
                        color={'success'}
                        round
                      >
                        {this.resolveBtnTxt(mimeType)}
                      </Button>
                    </GridItem>
                  );
                })}
              </div>
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Archive
              type={'REPORT'}
              reportName={this.props.archiveReportNames}
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
  company: state.Auth.user.company,
  zoneCategories: state.ZoneCategories.zoneCategories,
});

const mapDispatchToProps = dispatch => ({
  fetchZoneCategories: () => dispatch(fetchZoneCategories()),
});

CombinedReport.propTypes = {
  brands: PropTypes.array,
  zoneCategories: PropTypes.array,
  classes: PropTypes.any,
  archiveReportNames: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.func.isRequired,
  showMultiplier: PropTypes.bool,
  validateBrands: PropTypes.bool,
  validateDatePicker: PropTypes.bool,
  useDateRange: PropTypes.bool,
  useSubBrands: PropTypes.bool,
  useZoneCategories: PropTypes.bool,
  mimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  openToast: PropTypes.any,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(CombinedReport);
