import React from 'react';
import {combineStyles} from 'helpers/helpers';
import buttonsStyle
  from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle
  from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import {withToast} from 'material-ui-toast-redux';
import {withTranslation} from 'react-i18next';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PriceBlock from '../Common/PriceBlock';

class BasketDetails extends React.Component {

  render() {
    const {row, t} = this.props;
    return (
      <>
        <GridContainer style={{padding: '8px'}}>
          <GridItem xs={12}>
            <h4 style={{textAlign: 'center', fontWeight: 'bold'}}>{t('basket.details.BasketItemDiet.title')}</h4>
          </GridItem>
          <GridItem xs={12} style={{padding: '0 16px'}}>
            <p><b>{t('basket.details.BasketItemDiet.paymentMode')}</b>: {t('basket.details.BasketItemDiet.paymentMode.'+ row.paymentMode)}</p>
            <p><b>{t('orders.diet')}</b>: {row.diet.name}</p>
            <p><b>{t('orders.variant')}</b>: {row.variant.name}</p>
            <p><b>{t('orders.calories')}</b>: {row.calorific.name}</p>
            <p><b>{t('clients.orderDays')}</b>: {row.dietLength}</p>
            <p><b>{t('basket.details.BasketItemDiet.deliveryDays')}</b>: {row.days.join(', ')}</p>
            <p><b>{t('ecommerceBags.fields.bag.price.delivery')}</b>: {row.pickUpPoint && <>{row.pickUpPoint.value}</>}
              {row.address && (<>
                  {row.address.postCode} {row.address.city},{' '}
                  {row.address.street} {row.address.buildNumber}{row.address.placeNumber && '/'}{row.address.placeNumber}
                </>
              )}
            </p>
            {row.addons.length > 0 && (<>
              <p>{t('basket.details.BasketItemDiet.addons')}:</p>
              {row.addons.map((el, index) => (<p key={index}>
                {t('basket.details.BasketItemDiet.addons.' + el.type)} x{el.quantity} {el.addon.name}
              </p>))}
            </>)}
            <h4 style={{textAlign: 'center', fontWeight: 'bold'}}>{t('basket.details.common.cost')}</h4>
            <PriceBlock price={row.price}/>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

export default withTranslation()(
  withStyles(combinedStyles)(withToast(BasketDetails)),
);
