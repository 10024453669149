import React, { useEffect, useState } from 'react';
import AdminTable from 'layouts/AdminTable';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import roles, {
  ROLE_CREATE_WASTE,
  ROLE_EDIT_WASTE,
  ROLE_REMOVE_WASTE,
} from '../../helpers/roles';
import Moment from 'moment';
import { get, post, put, remove } from '../../helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
import { NavLink } from 'react-router-dom';
//styles
import {
  combineStyles,
  getNumberFromString,
  isGranted,
  sortByKey,
} from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
//components
import ReactTable from 'react-table';
import SweetAlert from 'react-bootstrap-sweetalert';

import ChooseFromMenuPlanningModal from './ChooseFromMenuPlanningModal';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import AccessDenied from 'components/Security/AccessDenied';
import { DialogLoader } from 'components/DialogLoader';
import SelectInput from 'components/FormSelect/SelectInput';
import CustomInput from 'components/CustomInput/CustomInput';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { CheckBox, RemoveCircle } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { dateTimeCell } from 'components/Grid/Cells/DateTimeCell';
import { currencyToSymbol } from 'utils/currencies';
import { yesNoOptions } from 'utils/yesNoOptions';

const sourceOptions = t => [
  {
    name: t('common.waste.sourcePacking', 'Pakownia'),
    value: 'packing_room',
  },
  {
    name: t('common.waste.sourceColdroom', 'Chłodnia'),
    value: 'cold_store',
  },
];

const WeightUnitSelect = ({
  row,
  classes,
  rowWeightTransform,
  setOverProductions,
  overProductions,
}) => {
  const unitOptions = [
    { name: 'g', value: 0 },
    { name: 'kg', value: 1 },
  ];

  const findBy = row.temporaryId ? row.temporaryId : row['@id'];
  const key = row.temporaryId ? 'temporaryId' : '@id';

  const newOverProductions = [...overProductions];
  const rowToChange = newOverProductions.find(
    overProduction => overProduction[key] === findBy
  );

  useEffect(() => {
    const result = (
      row.amount * (!row.ingredient ? row.recipe.weight : row.ingredient.weight)
    ).toFixed(2);

    const rowWeightIsInKG = result > 1000;

    if (rowWeightIsInKG) {
      rowToChange.isInKG = true;
      rowWeightTransform(row, 'toKG', true);
      setSelected(unitOptions[1]);
    } else {
      setSelected(unitOptions[0]);
    }

    setOverProductions(newOverProductions);
  }, []);

  const [selected, setSelected] = useState(unitOptions[0]);

  return (
    <div style={{ width: '50px' }}>
      <SelectInput
        noGrid
        classes={classes}
        mapBy="name"
        trackBy="value"
        name="source"
        value={selected}
        options={unitOptions}
        handleChange={(ev, obj) => {
          if (obj.value === selected.value) {
            return;
          }
          setSelected(obj);
          const transformation = obj.value === 1 ? 'toKG' : 'toG';
          rowWeightTransform(row, transformation);
        }}
        id="weightUnitSelect"
      />
    </div>
  );
};

const ProcessingSelect = ({ row, classes, changeRow, openToast, t }) => {
  const [selected, setSelected] = useState(yesNoOptions({ t })[1]);
  const rowIsIngredient = !row.recipe;

  useEffect(() => {
    changeRow(row, { target: { value: true } }, 'weightIsAfterMachining');
  }, []);

  return (
    <div style={{ width: '50px' }}>
      <SelectInput
        noGrid
        classes={classes}
        mapBy="name"
        trackBy="value"
        name="weightIsAfterMachining"
        value={selected}
        options={yesNoOptions({ t })}
        disabled={!rowIsIngredient}
        handleChange={(ev, obj) => {
          if (obj.value === selected.value) {
            return;
          }
          const newValue = {
            target: {
              value: obj.value === 1,
            },
          };
          changeRow(row, newValue, 'weightIsAfterMachining');
          changeRow(row, { target: { value: 0 } }, 'amount');
          changeRow(row, { target: { value: 0 } }, 'lossWeight');
          setSelected(obj);
        }}
        id="weightIsAfterMachiningSelect"
      />
    </div>
  );
};

const Waste = ({
  classes,
  userBrands,
  openToast,
  selectedBrand,
  multinational,
}) => {
  const defaultBrand = userBrands.find(brand => brand.id === selectedBrand);
  const { t } = useTranslation();

  const [overProductions, setOverProductions] = useState([]);
  const [date, setDate] = useState(new Date());
  const selectInBrands = [
    {
      value: defaultBrand['@id'],
      label: defaultBrand.name,
    },
  ];
  const [mealTypesFromConfig, setMealTypesFromConfig] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [
    isFetchingRecipesAndIngredients,
    setIsFetchingRecipesAndIngredients,
  ] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [alert, setAlert] = useState(null);
  const defaultPageSize = 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pages, setPages] = useState(1);
  const [currentTotalItems, setCurrentTotalItems] = useState(0);

  useEffect(() => {
    if (selectInBrands.length) {
      setIsFetching(true);
      Promise.all([
        fetchMenuPlanner(),
        fetchMealTypesFromConfig(),
        getTotalItems(pageSize),
        fetchOverProductionsOnFetchData({}),
      ]).then(() => {
        setIsFetching(false);
      });
    } else {
      // setPageSize(defaultPageSize)
      setOverProductions([]);
    }
  }, [selectInBrands?.value, date]);

  const getTotalItems = async pageSize => {
    const query = {
      'date[]': date,
      'date[_operator]': 'eq',
      selectInBrands: selectInBrands.length
        ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
        : [],
    };

    let { 'hydra:totalItems': totalItems } = await get('/wastes', {
      ...query,
      partial: false,
      'properties[]': '_',
    });
    let pages = Math.floor(totalItems / (pageSize || defaultPageSize));

    if (pages !== totalItems / pageSize) {
      pages++;
    }

    setCurrentTotalItems(totalItems);

    setPages(pages);
  };

  const fetchOverProductionsOnFetchData = ({
    requestData,
    table,
    rowToBeSaved,
  }) => {
    setIsTableLoading(true);

    const query = {
      itemsPerPage: pageSize,
      page: requestData ? requestData.page + 1 : 1,
      'order[id]': 'DESC',
      'date[]': date,
      'date[_operator]': 'eq',
      selectInBrands: selectInBrands.length
        ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
        : [],
    };

    get('/wastes', {
      ...query,
    }).then(res => {
      const newOverProductions = res['hydra:member'];
      const modified = newOverProductions.map(item => ({
        ...item,
        temporaryId:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        lossWeight: (
          item.amount *
          (!item.ingredient ? item.recipe.weight : item.ingredient.weight)
        ).toFixed(2),
        hasChanged: false,
      }));

      const unSavedOverProductions = rowToBeSaved
        ? overProductions.filter(
            overProduction =>
              !overProduction['@id'] &&
              overProduction.temporaryId !== rowToBeSaved.temporaryId
          )
        : [];

      setIsTableLoading(false);
      setOverProductions([...unSavedOverProductions, ...modified]);
    });
  };

  const fetchMenuPlanner = async () => {
    return await get('/menu-planners', {
      pagination: false,
      'date[after]': new Moment(date).format('YYYY-MM-DD'),
      'date[before]': new Moment(date).format('YYYY-MM-DD'),
      selectInBrands: selectInBrands.length
        ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
        : [],
    }).then(data => {
      transformMenuPlannerData(data['hydra:member']);
    });
  };

  const fetchMealTypesFromConfig = async () => {
    const response = await get('/meal-types', {
      pagination: false,
      selectInBrands: selectInBrands.length
        ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
        : [],
    });

    setMealTypesFromConfig(response['hydra:member']);

    return response;
  };

  const transformMenuPlannerData = async data => {
    const dishes = data.map(item => item.dish);
    const uniqueDishes = [];
    const recipes = [];
    const ingredients = [];

    dishes.forEach(dish => {
      if (!uniqueDishes.some(uniqueDish => uniqueDish.id === dish.id)) {
        uniqueDishes.push(dish);
      }
    });

    uniqueDishes.forEach(uniqueDish => {
      let mealTypes = [];
      let brands = [];
      let sizes = [];
      data.forEach(item => {
        if (uniqueDish['@id'] === item.dish['@id']) {
          if (!mealTypes.includes(item.mealType)) {
            mealTypes.push(item.mealType);
          }
          if (!brands.includes(item.brand['@id'])) {
            brands.push(item.brand['@id']);
          }

          Object.values(item.sizes).forEach(size => {
            if (size && !sizes.includes(size.size)) {
              sizes.push(size.size);
            }
          });
        }
      });

      uniqueDish.brands = brands.map(brand =>
        userBrands.find(userBrand => userBrand['@id'] === brand)
      );
      uniqueDish.mealTypes = sortByKey(
        mealTypes.map(type =>
          mealTypesFromConfig.find(mealType => mealType['@id'] === type)
        ),
        'position'
      );

      return uniqueDish;
    });

    setIsFetchingRecipesAndIngredients(true);

    await Promise.all(
      uniqueDishes.map(option => {
        return get(option['@id'], {
          selectInBrands: selectInBrands.length
            ? selectInBrands
                .map(brand => brand.value)
                .filter(val => val !== '*')
            : [],
        });
      })
    ).then(dishes => {
      dishes.forEach(dish => {
        const { components = [] } = dish;
        dish.sizes.forEach(dishSize => {
          dishSize.ingredients.forEach(dishIngredient => {
            if (dishIngredient.type === 'Ingredient') {
              const componentIngredient = components.find(
                component =>
                  component.ingredient &&
                  component.ingredient['@id'] === dishIngredient.ingredient
              );

              dishIngredient.ingredient = componentIngredient;

              if (
                !ingredients.some(
                  ingredient =>
                    ingredient['@id'] === componentIngredient.ingredient['@id']
                )
              ) {
                ingredients.push(componentIngredient.ingredient);
              }
            } else {
              const componentRecipe = components.find(
                component =>
                  component.recipe &&
                  component.recipe['@id'] === dishIngredient.recipe
              );

              dishIngredient.recipe = componentRecipe;

              if (
                !recipes.some(
                  recipe => recipe['@id'] === componentRecipe.recipe['@id']
                )
              ) {
                recipes.push(componentRecipe.recipe);
              }
            }
          });
        });
      });
      setRecipes(recipes);
      setIngredients(ingredients);
      setIsFetchingRecipesAndIngredients(false);
    });
  };

  const changeRow = (row, ev, name) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';
    const newOverProductions = [...overProductions];
    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );
    rowToChange[name] = ev.target.value;
    rowToChange.hasChanged = true;
    setOverProductions(newOverProductions);
  };

  const rowWeightTransform = (row, transformation, doNotTransformAmount) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';

    const newOverProductions = [...overProductions];
    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );

    if (transformation === 'toKG') {
      rowToChange.lossWeight = (
        rowToChange.lossWeight / (doNotTransformAmount ? 1000 : 1)
      ).toFixed(2);
      rowToChange.amount = doNotTransformAmount
        ? rowToChange.amount
        : rowToChange.amount * 1000;
    } else {
      rowToChange.lossWeight = (
        rowToChange.lossWeight * (doNotTransformAmount ? 1000 : 1)
      ).toFixed(2);
      rowToChange.amount = doNotTransformAmount
        ? rowToChange.amount
        : rowToChange.amount / 1000;
    }

    setOverProductions(newOverProductions);
  };

  const changeSubBrand = (row, ev, name) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';
    const newOverProductions = [...overProductions];

    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );
    rowToChange.source = ev.target.value;
    rowToChange.hasChanged = true;
    setOverProductions(newOverProductions);
  };

  const changeAmountWithWeight = (row, ev) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';

    const result = (
      (+ev.target.value /
        (!row.ingredient
          ? row.weightIsAfterMachining
            ? row.recipe.macrosAfterProcessing.weight
            : row.recipe.weight
          : row.weightIsAfterMachining
          ? row.ingredient.macrosAfterProcessing.weight
          : row.ingredient.weight)) *
      (row.isInKG ? 1000 : 1)
    ).toFixed(2);

    const newOverProductions = [...overProductions];
    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );
    rowToChange.amount = result;
    rowToChange.hasChanged = true;
    setOverProductions(newOverProductions);
  };

  const changeWeightWithAmount = (row, ev) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';

    const result = (
      +ev.target.value *
      (!row.ingredient
        ? row.weightIsAfterMachining
          ? row.recipe.macrosAfterProcessing.weight
          : row.recipe.weight
        : row.weightIsAfterMachining
        ? row.ingredient.macrosAfterProcessing.weight
        : row.ingredient.weight)
    ).toFixed(2);

    const newOverProductions = [...overProductions];
    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );
    rowToChange.lossWeight = result;
    rowToChange.hasChanged = true;
    setOverProductions(newOverProductions);
  };

  const validateRow = row => {
    if (row.amount < 0) {
      openToast({
        messages: [t('common.waste.wasteCannotBeNegative')],
        type: 'error',
        autoHideDuration: 3000,
      });

      return false;
    }

    return row.amount && row.source;
  };

  const renderAlert = (onConfirm, onCancel) => {
    const alert = (
      <SweetAlert
        warning
        title={t('common.shared.areYouSure')}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('common.shared.yes')}
        cancelBtnText={t('common.shared.no')}
        showCancel
      />
    );

    setAlert(alert);
  };

  const saveRow = row => {
    if (validateRow(row)) {
      const data = { ...row };

      const action = data['@id'] ? put : post;
      const endpoint = data['@id'] ? data['@id'] : '/wastes';

      data.recipe
        ? (data.recipe = data.recipe['@id'])
        : (data.ingredient = data.ingredient['@id']);
      data.amount = parseFloat(data.amount);

      action(endpoint, data).then(
        () => {
          getTotalItems(pageSize);
          fetchOverProductionsOnFetchData({ rowToBeSaved: row });
          openToast({
            messages: [
              action === put
                ? t('common.waste.wasteChanged')
                : t('common.waste.wasteAdded'),
            ],
            type: 'success',
            autoHideDuration: 3000,
          });
        },
        error =>
          openToast({
            messages: [t('common.waste.notAddedWaste')],
            type: 'error',
            autoHideDuration: 3000,
          })
      );
    }
  };

  const overProductionsToSave = overProductions.filter(
    overProd => overProd.temporaryId && validateRow(overProd)
  );

  const saveAllRows = () => {
    overProductionsToSave.length && setIsFetching(true);
    setIsTableLoading(true);

    return Promise.all(
      overProductionsToSave.map(row => {
        const data = { ...row };

        const action = data['@id'] ? put : post;
        const endpoint = data['@id'] ? data['@id'] : '/wastes';

        data.recipe
          ? (data.recipe = data.recipe['@id'])
          : (data.ingredient = data.ingredient['@id']);
        data.amount = parseFloat(data.amount);

        action(endpoint, data).then(
          () => {
            getTotalItems(pageSize);
            fetchOverProductionsOnFetchData({});
            openToast({
              messages: [t('common.waste.savedAllWaste')],
              type: 'success',
              autoHideDuration: 3000,
            });
            setIsFetching(false);
            setIsTableLoading(false);
          },
          error => {
            openToast({
              messages: [t('common.waste.notSavedWaste')],
              type: 'error',
              autoHideDuration: 3000,
            });
            setIsFetching(false);
            setIsTableLoading(false);
          }
        );
      })
    );
  };

  const columns = [
    {
      title: 'ID',
      accessor: 'id',
      name: 'id',
      filterable: false,
      sortable: false,
      width: 50,
    },
    {
      title: t('columns.name'),
      name: 'name',
      accessor: option => {
        return (
          <div>
            {option.recipe || option.ingredient ? (
              <NavLink
                to={
                  !option.ingredient
                    ? `/admin/recipes/edit/${getNumberFromString(
                        option.recipe['@id']
                      )}`
                    : `/admin/ingredients/edit/${getNumberFromString(
                        option.ingredient['@id']
                      )}`
                }
                target={'_blank'}
              >
                <h4>
                  {option.recipe
                    ? option.recipe.workName
                    : option.ingredient.name}
                </h4>
              </NavLink>
            ) : null}
          </div>
        );
      },
      filterable: false,
      sortable: false,
      width: 250,
    },
    {
      title: t('columns.date'),
      accessor: row => dateTimeCell(row.date, true),
      name: 'date',
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.unit'),
      name: 'unit',
      filterable: false,
      accessor: ({ recipe, ingredient, ...row }) =>
        recipe || ingredient
          ? recipe
            ? `Porcja`
            : ingredient.unit.value
            ? ingredient.unit.value
            : ingredient.unit
          : null,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.qty') + '*',
      name: 'total',
      accessor: ({ amount, ...row }) => {
        return (
          <div style={{ paddingTop: '9px' }}>
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{
                disabled: row['@id'] && !isGranted(ROLE_EDIT_WASTE),
                type: 'number',
                placeholder: t('columns.typeValue'),
                name: 'amount',
                value: amount,
                onChange: ev => {
                  changeRow(row, ev, 'amount');
                  changeWeightWithAmount(row, ev);
                },
              }}
            />
          </div>
        );
      },
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.wasteWeight'),
      name: 'lossWeight',
      accessor: ({ lossWeight, ...row }) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ paddingRight: '10px', paddingTop: '9px' }}>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  disabled: row['@id'] && !isGranted(ROLE_EDIT_WASTE),
                  type: 'number',
                  placeholder: t('common.shared.typeValue'),
                  name: 'lossWeight',
                  value: lossWeight || 0,
                  onChange: ev => {
                    changeAmountWithWeight(row, ev);
                    changeRow(row, ev, 'lossWeight');
                  },
                }}
              />
            </div>
            <WeightUnitSelect
              row={row}
              classes={classes}
              changeRow={changeRow}
              rowWeightTransform={rowWeightTransform}
              setOverProductions={setOverProductions}
              overProductions={overProductions}
            />
          </div>
        );
      },
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      title: t('columns.afterProcessing'),
      name: 'weightIsAfterMachining',
      accessor: ({ ...row }) => {
        return (
          <ProcessingSelect
            row={row}
            classes={classes}
            changeRow={changeRow}
            t={t}
          />
        );
      },
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      title: t('columns.wasteValue'),
      name: 'lossCash',
      accessor: ({ amount, ingredient, recipe, ...row }) => {
        return (
          (
            ((amount * (!ingredient ? 1 : ingredient.weight)) /
              (!ingredient ? 1 : 100)) *
            (!ingredient ? recipe.price : ingredient.price)
          ).toFixed(2) + ` ${currencyToSymbol(multinational.currency)}`
        );
      },
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      title: t('columns.comment'),
      name: 'comment',
      accessor: ({ comment, ...row }) => {
        return (
          <div style={{ paddingTop: '6px' }}>
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{
                disabled: row['@id'] && !isGranted(ROLE_EDIT_WASTE),
                placeholder: t('common.shared.typeComment'),
                name: 'comment',
                value: comment,
                onChange: ev => {
                  changeRow(row, ev, 'comment');
                },
              }}
            />
          </div>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      title: t('columns.wasteSource'),
      name: 'source',
      accessor: ({ source, ...row }) => {
        return (
          <SelectInput
            classes={classes}
            mapBy="name"
            trackBy="value"
            name="source"
            value={source}
            options={sourceOptions(t)}
            handleChange={ev => changeSubBrand(row, ev, 'source')}
            id="source"
            size={12}
          />
        );
      },
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      title: '',
      name: 'action',
      accessor: row => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            {isGranted(ROLE_REMOVE_WASTE) && (
              <Button
                simple
                color={'danger'}
                onClick={() => {
                  const onConfirm = () => {
                    const findBy = row.temporaryId
                      ? row.temporaryId
                      : row['@id'];
                    const key = row.temporaryId ? 'temporaryId' : '@id';

                    const filteredOverProductions = overProductions.filter(
                      overProduction => overProduction[key] !== findBy
                    );
                    row['@id'] && remove(row['@id']);
                    setOverProductions(filteredOverProductions);
                    setAlert(null);
                  };
                  const onCancel = () => setAlert(null);
                  !row['@id'] ? onConfirm() : renderAlert(onConfirm, onCancel);
                }}
              >
                <span style={{ marginRight: '5px' }}>
                  {t('common.shared.delete')}
                </span>
                <RemoveCircle />
              </Button>
            )}
            {isGranted(row['@id'] ? ROLE_EDIT_WASTE : ROLE_CREATE_WASTE) && (
              <Button
                simple
                color={'success'}
                disabled={!validateRow(row) || (row['@id'] && !row.hasChanged)}
                onClick={() => saveRow(row)}
              >
                <div>
                  <span style={{ marginRight: '5px' }}>
                    {t('common.shared.save')}
                  </span>
                  <CheckBox />
                </div>
              </Button>
            )}
          </div>
        );
      },
      filterable: false,
      sortable: false,
    },
  ];

  let columnConfig = columns.map(column => {
    return {
      ...column,
      id: column.name,
      Header: column.title,
      accessor: column.accessor,
      sortable: column.sortable,
    };
  });

  return isGranted(roles.ROLE_SHOW_WASTE) ? (
    <AdminTable title={t('common.waste.waste')} icon>
      <GridContainer>
        {alert}
        <DialogLoader
          loaderState={isFetching || isFetchingRecipesAndIngredients}
          text={t('common.shared.isLoading')}
        />
        <ChooseFromMenuPlanningModal
          classes={classes}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          date={date}
          selectInBrands={selectInBrands}
          menuPlannerOptions={{
            recipes,
            ingredients,
          }}
          setOverProductions={setOverProductions}
          overProductions={overProductions}
          mealTypesFromConfig={mealTypesFromConfig}
        />
        <GridItem md={3}>
          <FormLabel
            className={classes.labelHorizontal}
            style={{ marginBottom: '5px' }}
          >
            {t('common.shared.selectDate')}
          </FormLabel>
          <FormControl fullWidth>
            <Datetime
              isValidDate={isDatepickerValidDay}
              timeFormat={false}
              closeOnSelect={true}
              value={date}
              onChange={ev => {
                setDate(ev);
                setOverProductions([]);
              }}
              inputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem md={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!isFetching &&
                `${overProductions.length} ${t('dataGrid.pageOf')} ${
                  overProductions.length >= currentTotalItems
                    ? overProductions.length
                    : currentTotalItems
                } ${t('dataGrid.results')}`}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isGranted(ROLE_CREATE_WASTE) && (
                <div
                  style={{ marginRight: '5px' }}
                  onClick={() =>
                    !selectInBrands.length &&
                    openToast({
                      messages: [
                        t('info.chooseBrand', 'Najpierw wybierz marki'),
                      ],
                      type: 'info',
                      autoHideDuration: 3000,
                    })
                  }
                >
                  <Button
                    disabled={!selectInBrands.length}
                    color={'primary'}
                    onClick={() => setModalOpen(true)}
                  >
                    {t('common.waste.addWaste')}
                  </Button>
                </div>
              )}
              {isGranted(ROLE_CREATE_WASTE) && (
                <Button
                  color={'success'}
                  onClick={saveAllRows}
                  disabled={!overProductionsToSave.length}
                >
                  {t('common.shared.saveAll')}
                </Button>
              )}
            </div>
          </div>
        </GridItem>
        <GridItem md={12}>
          <ReactTable
            manual
            loading={isTableLoading}
            data={overProductions}
            pageSizeOptions={[1, 5, 10, 20, 50, 100]}
            columns={columnConfig}
            defaultPageSize={defaultPageSize}
            pageSize={pageSize}
            onPageSizeChange={pageSize => {
              getTotalItems(pageSize);
              setPageSize(pageSize);
            }}
            showPaginationBottom={false}
            pages={pages}
            onFetchData={(requestData, table) =>
              fetchOverProductionsOnFetchData({ requestData, table })
            }
            showPaginationTop
            className="-striped -highlight"
            previousText={t('dataGrid.prevPage')}
            nextText={t('dataGrid.nextPage')}
            loadingText={t('dataGrid.loading')}
            noDataText={t('dataGrid.notFound')}
            pageText={t('dataGrid.page')}
            ofText={t('dataGrid.pageOf')}
            rowsText={t('dataGrid.results')}
          />
        </GridItem>
      </GridContainer>
    </AdminTable>
  ) : (
    <AccessDenied />
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => ({
  userBrands: state.Auth.user.brands,
  selectedBrand: state.Auth.selectedBrand,
  mealSizes: state.MealSizes.mealSizes,
  multinational: state.Brands.brand.multinational,
});
//
// const mapDispatchToProps = {
//   fetchMealTypes,
// }

export default connect(
  mapStateToProps,
  null
)(withStyles(combinedStyles)(withToast(withTranslation()(Waste))));
