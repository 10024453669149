import React from 'react';
import AdminTable from 'layouts/AdminTable';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import SelectInput from '../../../components/FormSelect/SelectInput';
import TextFieldTooltip from './Tooltip';

const DietDelivery = ({
  classes,
  getImage,
  removeImage,
  handleToggle,
  handleSubmit,
  allowedToModify,
  handleInputChange,
  selectOptions,
  showMenuButton,
  bannerInfoEnabled,
  bannerTitle,
  bannerMobile,
  menuButtonLink,
  bannerDescription,
  bannerDesktop,
  pageDescription,
  mode,
  defaultDiet,
  defaultVariant,
  activeDiets,
  activeVariants,
  handleDietChange,
  mainDomain,
  showDishIngredients
}) => {
  const { t } = useTranslation();

  const selectedMode = selectOptions.find(({ value }) => value === mode);
  const dietOptions = activeDiets.map(
    ({ name: label, '@id': value, variants }) => ({
      label,
      value,
      variants,
    })
  );

  const selectedDiet = dietOptions.find(({ value }) => value === defaultDiet);

  const variantOptions = activeVariants.map(
    ({ name: label, '@id': value }) => ({
      label,
      value,
    })
  );

  const variantsInDiet = selectedDiet
    ? variantOptions.filter(({ value }) => {
        return selectedDiet?.variants.some(variant => variant === value);
      })
    : [];

  const selectedVariant = variantsInDiet.find(
    ({ value }) => value === defaultVariant
  );

  const menuUrlPlaceholder = `${t(
    'form.menuPlanning.defaultMenuUrl'
  )}https://${mainDomain}/menu`;

  return (
    <AdminTable title={t('form.menuPlanning.subpageSettings')} icon>
      <FormLabel className={classes.labelHorizontal}>
        {t('module.MenuPage.howShowMenuPage')}
      </FormLabel>
      <SelectInput
        noGrid
        classes={classes}
        mapBy="label"
        trackBy="value"
        name="mode"
        value={selectedMode}
        options={selectOptions}
        disabled={false}
        handleChange={handleInputChange}
        size={12}
      />

      {mode === 'DEFAULT_DIET' && (
        <>
          <FormLabel className={classes.labelHorizontal}>Dieta</FormLabel>
          <SelectInput
            noGrid
            classes={classes}
            mapBy="label"
            trackBy="value"
            name="defaultDiet"
            value={selectedDiet}
            options={dietOptions}
            disabled={false}
            handleChange={handleDietChange}
            size={12}
          />

          <FormLabel className={classes.labelHorizontal}>Wariant</FormLabel>
          <SelectInput
            noGrid
            classes={classes}
            mapBy="label"
            trackBy="value"
            name="defaultVariant"
            value={selectedVariant}
            options={variantsInDiet}
            disabled={false}
            handleChange={handleInputChange}
            size={12}
          />
        </>
      )}

      <FormControlLabel
        control={
          <Checkbox
            name="showMenuButton"
            onClick={handleToggle}
            checked={!!showMenuButton}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t('brandCfg.menuBtnShow')}
      />
      <FormTextInput
        label={t('brandCfg.menuAddr')}
        classes={classes}
        placeholder={menuUrlPlaceholder}
        name="menuButtonLink"
        value={menuButtonLink}
        handleChange={handleInputChange}
        inputSize={12}
        maxLength={255}
      />
      <FormTextInput
        label={t('module.MenuPage.pageDescription')}
        classes={classes}
        name="pageDescription"
        value={pageDescription}
        handleChange={handleInputChange}
        inputSize={12}
        maxLength={255}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="showDishIngredients"
            onClick={handleToggle}
            checked={!!showDishIngredients}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t('form.menuPlanning.showDishIngredients')}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="bannerInfoEnabled"
            onClick={handleToggle}
            checked={!!bannerInfoEnabled}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t('form.menuPlanning.showInfoBanner')}
      />
      {bannerInfoEnabled && (
        <>
          <FormTextInput
            multiline
            rows={3}
            rowsMax={10}
            label={
              <TextFieldTooltip label={t('form.menuPlanning.headerInBanner')} />
            }
            classes={classes}
            name="bannerTitle"
            value={bannerTitle}
            handleChange={handleInputChange}
            inputSize={10}
            maxLength={160}
          />
          <FormTextInput
            label={
              <TextFieldTooltip
                label={t('form.menuPlanning.textUnderCaption')}
              />
            }
            classes={classes}
            name="bannerDescription"
            value={bannerDescription}
            handleChange={handleInputChange}
            inputSize={10}
            maxLength={1000}
            rows={3}
            rowsMax={20}
            multiline={true}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              textAlign: 'center',
            }}
          >
            <div>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginTop: '15px', marginBottom: '24px' }}
              >
                {t('form.menuPlanning.bannerForDesktop')}
              </FormLabel>
              <FormImageUpload
                classes={classes}
                stateName="bannerDesktop"
                buttonText={t('common.shared.select')}
                getImage={getImage}
                imgId="desktop-banner"
                tooltipImageSize={t('form.menuPlanning.tooltipImageDesktop')}
                removeImage={removeImage}
                previewUrl={bannerDesktop?.contentUrl}
              />
            </div>
            <div>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginTop: '15px', marginBottom: '24px' }}
              >
                {t('form.menuPlanning.bannerMobile')}
              </FormLabel>
              <FormImageUpload
                classes={classes}
                stateName="bannerMobile"
                buttonText={t('common.shared.select')}
                getImage={getImage}
                imgId="mobile-banner"
                tooltipImageSize={t('form.menuPlanning.tooltipImageMobile')}
                removeImage={removeImage}
                previewUrl={bannerMobile?.contentUrl}
              />
            </div>
          </div>
        </>
      )}
      {allowedToModify && (
        <FormControlButtons
          classes={classes}
          submitText={t('form.save')}
          handleSubmit={handleSubmit}
        />
      )}
    </AdminTable>
  );
};

export default DietDelivery;
