import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import Moment from 'moment';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import { Divider, ListItem, ListItemText } from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import { DialogLoader } from '../../components/DialogLoader';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormSelectDropdown from '../../components/FormSelect/FormSelectDropdown';
import { fetchDishes } from '../../actions/Dishes';
import { fetchIngredients } from '../../actions/Ingredients';
import { fetchRecipes } from '../../actions/Recipes';
import { useTranslation } from 'react-i18next';

const ChooseFromMenuPlanningModal = ({
  classes,
  modalOpen,
  setModalOpen,
  date,
  selectInBrands,
  menuPlannerOptions,
  overProductions,
  setOverProductions,
  mealTypesFromConfig,
  fetchIngredients,
  fetchRecipes,
}) => {
  const { t } = useTranslation();
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [checkedOptionsFromAllDiets, setCheckedOptionsFromAllDiets] = useState({
    recipes: [],
    ingredients: [],
  });
  const [isAddingDishes, setIsAddingDishes] = useState(false);
  const [selectDishFrom, setSelectDishFrom] = useState('menuPlanning');
  const [searchInIngredients, setSearchInIngredients] = useState(true);

  const brandsToRender = selectInBrands
    .filter(brand => brand.value !== '*')
    .map(brand => brand.label)
    .join(', ');

  const checkRow = option => {
    let newCheckedOptionsArray = [...checkedOptions];

    let result = checkedOptions.includes(option['@id'])
      ? newCheckedOptionsArray.filter(
          checkedOption => checkedOption !== option['@id']
        )
      : [...newCheckedOptionsArray, ...[option['@id']]];

    return setCheckedOptions(result);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCheckedOptions([]);
    setCheckedOptionsFromAllDiets({ recipes: [], ingredients: [] });
  };

  const getFiltered = async e => {
    const action = searchInIngredients ? fetchIngredients : fetchRecipes;

    return action({
      pageSize: 30,
      page: 0,
      sorted: [],
      filtered: [
        {
          id: searchInIngredients ? 'name' : 'workName',
          value: e,
        },
        {
          id: 'selectInBrands',
          value: selectInBrands.length
            ? selectInBrands
                .map(brand => brand.value)
                .filter(val => val !== '*')
            : [],
        },
      ],
    });
  };

  const renderList = options => {
    return (
      <List>
        {options.recipes.length || options.ingredients.length ? (
          <Fragment>
            <ListItem
              className={'variant-listItem'}
              onClick={() =>
                checkedOptions.length
                  ? setCheckedOptions([])
                  : setCheckedOptions([
                      ...options.recipes.map(option => option['@id']),
                      ...options.ingredients.map(option => option['@id']),
                    ])
              }
            >
              <Checkbox
                checked={checkedOptions.length}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                onChange={ev =>
                  checkedOptions.length
                    ? setCheckedOptions([])
                    : setCheckedOptions([
                        ...options.recipes.map(option => option['@id']),
                        ...options.ingredients.map(option => option['@id']),
                      ])
                }
              />
              <div>
                <ListItemText>
                  {checkedOptions.length
                    ? t('common.shared.uncheckAll')
                    : t('common.shared.checkAll')}
                </ListItemText>
              </div>
            </ListItem>
            <Divider />
          </Fragment>
        ) : (
          <ListItem>
            <div>
              <ListItemText>{t('common.waste.notSelected')}</ListItemText>
            </div>
          </ListItem>
        )}
        {options.recipes.length ? <h1>{t('common.waste.recipes')}</h1> : ''}
        {options.recipes.map(option => {
          return (
            <ListItem
              key={option['@id']}
              className={'variant-listItem'}
              onClick={() => checkRow(option)}
            >
              <Checkbox
                checked={checkedOptions.includes(option['@id'])}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                onChange={ev => checkRow(option)}
              />
              <div>
                <ListItemText>{option.workName}</ListItemText>
              </div>
            </ListItem>
          );
        })}
        <Divider />
        {options.ingredients.length ? (
          <h1>{t('common.waste.ingredients', 'Składniki')}</h1>
        ) : (
          ''
        )}
        {options.ingredients.map(option => {
          return (
            <ListItem
              key={option['@id']}
              className={'variant-listItem'}
              onClick={() => checkRow(option)}
            >
              <Checkbox
                checked={checkedOptions.includes(option['@id'])}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
                onChange={ev => checkRow(option)}
              />
              <div>
                <ListItemText>{option.name}</ListItemText>
              </div>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const addSelectedDishesToOverprod = () => {
    const newWaste = [];

    checkedOptions.forEach(option => {
      const randomId =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      const wasteObj = {
        temporaryId: randomId,
        recipe: null,
        ingredient: null,
        comment: '',
        source: null,
        date: date,
        amount: 0,
        weightIsAfterMachining: false,
      };
      if (option.includes('recipe')) {
        wasteObj.weightIsAfterMachining = true;
        wasteObj.recipe = menuPlannerOptions.recipes.find(
          recipe => recipe['@id'] === option
        );
      } else {
        wasteObj.ingredient = menuPlannerOptions.ingredients.find(
          ingredient => ingredient['@id'] === option
        );
      }

      return newWaste.push(wasteObj);
    });

    setOverProductions([...newWaste, ...overProductions]);

    closeModal();
  };

  return (
    <Dialog open={modalOpen} onClose={closeModal}>
      <DialogLoader
        loaderState={isAddingDishes}
        text={t('common.waste.adding')}
      />
      <DialogTitle>
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={selectDishFrom === 'menuPlanning'}
                onChange={() => setSelectDishFrom('menuPlanning')}
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={t('common.overproduction.fromPlanningMenu')}
          />
          <FormControlLabel
            control={
              <Radio
                checked={selectDishFrom === 'allDishes'}
                onChange={() => setSelectDishFrom('allDishes')}
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={t('common.shared.formAll')}
          />
        </div>
        <span>
          {selectDishFrom === 'menuPlanning'
            ? `${t('common.waste.recipesAndIngredients')} ${new Moment(
                date
              ).format('DD.MM.YYYY')}`
            : t('common.waste.selectRecipesOrIngredients')}
        </span>
      </DialogTitle>
      <DialogContent>
        <p>
          {t('common.waste.recipesAndIngredientsFrom')}:{' '}
          {brandsToRender.length
            ? brandsToRender
            : t('common.waste.noBrandSelected')}
        </p>
        {selectDishFrom === 'menuPlanning' ? (
          renderList(menuPlannerOptions)
        ) : (
          <div style={{ minHeight: '400px' }}>
            <div>
              <FormControlLabel
                control={
                  <Radio
                    checked={searchInIngredients}
                    onChange={() =>
                      setSearchInIngredients(!searchInIngredients)
                    }
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={t('common.waste.ingredients')}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={!searchInIngredients}
                    onChange={() =>
                      setSearchInIngredients(!searchInIngredients)
                    }
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={t('common.waste.recipes')}
              />
            </div>
            <FormSelectDropdown
              filterName={searchInIngredients ? 'name' : 'workName'}
              placeholder={
                searchInIngredients
                  ? t('common.waste.selectIngredient')
                  : t('common.waste.selectRecipe')
              }
              handleClick={item => {
                const newCheckedOptions = {
                  recipes: checkedOptionsFromAllDiets.recipes,
                  ingredients: checkedOptionsFromAllDiets.ingredients,
                };

                item['@type'] === 'Ingredient'
                  ? (newCheckedOptions.ingredients = [
                      ...newCheckedOptions.ingredients,
                      item,
                    ])
                  : (newCheckedOptions.recipes = [
                      ...newCheckedOptions.recipes,
                      item,
                    ]);

                const recipes = newCheckedOptions.recipes.map(
                  recipe => recipe['@id']
                );
                const ingredients = newCheckedOptions.ingredients.map(
                  ingredient => ingredient['@id']
                );

                const combined = [...recipes, ...ingredients];

                setCheckedOptionsFromAllDiets(newCheckedOptions);
                setCheckedOptions(combined);
              }}
              filter={getFiltered}
            />
            {renderList(checkedOptionsFromAllDiets)}
          </div>
        )}
      </DialogContent>
      <div style={{ padding: '24px', display: 'flex' }}>
        <div style={{ width: '100%', marginRight: '12px' }}>
          <Button color={'danger'} fullWidth onClick={closeModal}>
            {t('common.shared.cancel')}
          </Button>
        </div>
        <div style={{ width: '100%', marginLeft: '12px' }}>
          <Button
            color={'success'}
            fullWidth
            onClick={() => {
              if (selectDishFrom === 'menuPlanning') {
                addSelectedDishesToOverprod();
              } else {
                const newWaste = [];

                checkedOptions.forEach(option => {
                  const randomId =
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15);
                  const wasteObj = {
                    temporaryId: randomId,
                    recipe: null,
                    ingredient: null,
                    comment: '',
                    source: null,
                    date: date,
                    amount: 0,
                  };
                  option.includes('recipe')
                    ? (wasteObj.recipe = checkedOptionsFromAllDiets.recipes.find(
                        recipe => recipe['@id'] === option
                      ))
                    : (wasteObj.ingredient = checkedOptionsFromAllDiets.ingredients.find(
                        ingredient => ingredient['@id'] === option
                      ));

                  return newWaste.push(wasteObj);
                });

                closeModal();
                setIsAddingDishes(false);
                setOverProductions([...newWaste, ...overProductions]);
              }
            }}
          >
            {t('common.shared.addSelected')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const mapDispatchToProps = {
  fetchDishes,
  fetchIngredients,
  fetchRecipes,
};

export default connect(null, mapDispatchToProps)(ChooseFromMenuPlanningModal);
