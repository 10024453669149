import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import RegularButton from 'components/CustomButtons/Button.jsx';
import CustomInput from '../../components/CustomInput/CustomInput';
import SelectInput from '../../components/FormSelect/SelectInput';
import {Info} from '@material-ui/icons';
import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs/components/prism-core';
import 'views/MailReminders/style.css';
import {isGranted} from '../../helpers/helpers';
import roles from '../../helpers/roles';
import {Trans, withTranslation} from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';

require('prismjs/components/prism-clike');
require('prismjs/components/prism-javascript');
require('prismjs/components/prism-twig');
require('prismjs/components/prism-markup');
require('prismjs/components/prism-jsx');

const EndOfDietReminders = ({
  mailing,
  handleCheckbox,
  classes,
  addRow,
  removeRow,
  saveRow,
  handleRow,
  handleEditor,
  handlePrefix,
  handleToggle,
  t,
}) => {
  const getPrefixValue = days => {
    let value;
    if (days > 0) {
      value = '+';
    }
    if (days < 0) {
      value = '-';
    }
    if (days === '0' || days === 0) {
      value = '0';
    }

    return value;
  };

  return (
    <div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Tooltip
                title={
                  <Trans i18nKey="reminders.content2">
                    <div style={{ fontSize: '12px' }}>
                      <h4>
                        Aby dodać przypomnienie, które zostanie wysłane{' '}
                        <b>PRZED</b> końcem diety, poprzedź liczbę dni minusem.
                      </h4>
                      <h4>
                        np. dla wartości <b>-7</b>, przypomnienie zostanie
                        wysłane 7 dni przed końcem diety.
                      </h4>
                      <br />
                      <h4>
                        Analogicznie, aby dodać przypomnienie, które zostanie
                        wysłane <b>PO</b> zakończeniu diety, nie poprzedzaj
                        liczby niczym.
                      </h4>
                      <h4>
                        np. dla wartości <b>7</b>, przypomnienie zostanie
                        wysłane 7 dni po zakończeniu diety.
                      </h4>
                      <br />
                      <h4>
                        Dla wartości <b>0</b>, przypomnienie zostanie wysłane{' '}
                        <b>W DNIU</b> zakończenia diety.
                      </h4>
                    </div>
                  </Trans>
                }
                placement={'right'}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'help',
                  }}
                >
                  {t('common.daysAmount', 'Ilość dni')}

                  <Info style={{ fontSize: 16 }} />
                </div>
              </Tooltip>
            </TableCell>
            <TableCell>
              {t('common.finishMoment', 'Moment zakończenia')}
            </TableCell>
            <TableCell>
              {t('reminders.ignoreClientMarketingAgreement', 'Wysyłaj również klientom bez zgody marketingowej')}
            </TableCell>
            <TableCell>
              {t('common.messageContent', 'Treść wiadomości')}
            </TableCell>
            {(isGranted(roles.ROLE_EDIT_END_OF_DIET_REMINDER) ||
              isGranted(roles.ROLE_REMOVE_END_OF_DIET_REMINDER)) && (
              <TableCell>{t('notify.actions', 'Akcje')}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {mailing.end_of_diet_reminder_config.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell width={'12%'} component="th" scope="row">
                  <CustomInput
                    formControlProps={{ fullWidth: false }}
                    inputProps={{
                      disabled: false,
                      type: 'number',
                      placeholder: t('common.enterValue', 'Wpisz wartość'),
                      name: 'days',
                      value: row.days,
                      onChange: event => handleRow(event, index),
                    }}
                  />
                </TableCell>
                <TableCell width={'20%'} component="th" scope="row">
                  <SelectInput
                    classes={classes}
                    options={[
                      {
                        label: t(
                          'endOfDietOptions.beforeFinish',
                          'Przed zakończeniem'
                        ),
                        value: '-',
                      },
                      {
                        label: t(
                          'endOfDietOptions.afterFinish',
                          'Po zakończeniu'
                        ),
                        value: '+',
                      },
                      {
                        label: t(
                          'endOfDietOptions.atTheLastDay',
                          'W dniu zakończenia'
                        ),
                        value: '0',
                      },
                    ]}
                    mapBy="label"
                    trackBy="value"
                    value={getPrefixValue(row.days)}
                    handleChange={(ev, obj) => {
                      handlePrefix(ev, index);
                    }}
                  />
                </TableCell>
                <TableCell width={'12%'} component="th" scope="row">
                  <Checkbox
                    checked={row.ignoreClientMarketingAgreement}
                    onChange={event => handleToggle(event, index)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                    name={'ignoreClientMarketingAgreement'}
                  />
                </TableCell>
                <TableCell width={'60%'} component="th" scope="row">
                  <Editor
                    value={row.template}
                    onValueChange={code =>
                      handleEditor(code, 'template', index)
                    }
                    highlight={code => highlight(code, languages.jsx)}
                    padding={10}
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 12,
                    }}
                  />
                </TableCell>
                <TableCell>
                  {isGranted(roles.ROLE_EDIT_END_OF_DIET_REMINDER) && (
                    <RegularButton
                      fullWidth
                      disabled={!row.hasChanged}
                      color={'success'}
                      onClick={() => saveRow(index)}
                    >
                      {t('form.save', 'Zapisz')}
                    </RegularButton>
                  )}
                  {isGranted(roles.ROLE_REMOVE_END_OF_DIET_REMINDER) && (
                    <RegularButton
                      fullWidth
                      color={'danger'}
                      onClick={() => removeRow(index)}
                    >
                      {t('form.delete', 'Usuń')}
                    </RegularButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell
              className={'variant-addRow'}
              colSpan={6}
              onClick={addRow}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <span style={{ display: 'block' }}>
                  {t('remonders.addNotification', '+ Dodaj powiadomienie')}
                </span>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default withTranslation()(EndOfDietReminders);
