import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from 'views/Ingredients/List/List';
import Form from 'views/Ingredients/Form';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_INGREDIENT,
  ROLE_EDIT_INGREDIENT,
  ROLE_SHOW_INGREDIENT,
} from 'helpers/roles';

class Ingredients extends Component {
  render() {
    const props = this.props;

    const ListSecured = withRole(ROLE_SHOW_INGREDIENT, List);
    const CreateForm = withRole(ROLE_CREATE_INGREDIENT, Form);
    const EditForm = withRole(ROLE_EDIT_INGREDIENT, Form);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <CreateForm {...props} key="add" />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => (
              <EditForm {...props} {...parentProps} key="edit" />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Ingredients);
