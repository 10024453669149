import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';

const MyLeadModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'MyLeadModule',
        })}
      </div>
    );
  }

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={module.enabled}
            onChange={() =>
              handleModuleUpdate({
                ...module,
                enabled: !module.enabled,
              })
            }
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t('brands.newBrandForm.useMyLead', 'Używaj MyLead')}
      />
      <FormTextInput
        label="Provider hash"
        classes={classes}
        name="providerHash"
        value={module.providerHash}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            providerHash: e.target.value,
          })
        }
        inputSize={6}
      />
    </Fragment>
  );
};

export default MyLeadModule;
