import React, { useEffect, useState } from 'react';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import ShowChart from '@material-ui/icons/ShowChart';
// core components
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import chartsStyle from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { combineStyles } from '../../../helpers/helpers';
import { get } from '../../../helpers/apiHelpers';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Moment from 'moment';

const BarChartCard = ({ classes, selectedBrand }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState({
    data: {
      labels: [
        t('months.short.Jan'),
        t('months.short.Feb'),
        t('months.short.Mar'),
        t('months.short.Apr'),
        t('months.short.May'),
        t('months.short.Jun'),
        t('months.short.Jul'),
        t('months.short.Aug'),
        t('months.short.Sep'),
        t('months.short.Oct'),
        t('months.short.Nov'),
        t('months.short.Dec'),
      ],
      colors: [classes.info, classes.danger],
      series: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
      legends: ['2020', '2021'],
    },
    options: { seriesBarDistance: 10, axisX: { showGrid: false } },
  });

  useEffect(() => {
    getIncomeHistory();
  }, []);

  const getIncomeHistory = () => {
    get(`/statistics/ecommerce/income/year`, {
      brandId: [selectedBrand],
      date: Moment(new Date()).format('YYYY-MM-DD'),
    }).then(res => getData(res));
    return;
  };

  const getData = incomeData => {
    const { currentYear, lastYear } = incomeData;
    if (currentYear && lastYear) {
      const newChartData = {
        data: {
          labels: [
            t('months.short.Jan'),
            t('months.short.Feb'),
            t('months.short.Mar'),
            t('months.short.Apr'),
            t('months.short.May'),
            t('months.short.Jun'),
            t('months.short.Jul'),
            t('months.short.Aug'),
            t('months.short.Sep'),
            t('months.short.Oct'),
            t('months.short.Nov'),
            t('months.short.Dec'),
          ],
          colors: [classes.info, classes.danger],
          series: [lastYear['hydra:member'], currentYear['hydra:member']],
          legends: [new Date().getFullYear() - 1, new Date().getFullYear()],
        },
        options: { seriesBarDistance: 10, axisX: { showGrid: false } },
      };

      setChartData(newChartData);
    }
  };

  return (
    <Card>
      <CardHeader color="warning" quickStats icon>
        <CardIcon color="warning">
          <ShowChart />
        </CardIcon>
        <h3 className={classes.quickStatsTitle}>
          {t('dashboard.incomeStats')}
        </h3>
      </CardHeader>
      <CardBody>
        <ChartistGraph
          data={chartData.data}
          type="Bar"
          options={chartData.options}
        />
      </CardBody>
      <CardFooter stats className={classes.cardFooter}>
        <h6 className={classes.legendTitle}>{t('dashboard.legend')}</h6>
        <div style={{ display: 'flex', fontSize: '12px' }}>
          {chartData.data.legends.map((legend, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginRight: '15px',
                }}
              >
                <i
                  className={'fas fa-circle ' + chartData.data.colors[index]}
                />{' '}
                {legend}
              </div>
            );
          })}
        </div>
      </CardFooter>
    </Card>
  );
};

const combinedStyles = combineStyles(dashboardStyle, chartsStyle);

const mapStateToProps = state => {
  return {
    selectedBrand: state.Auth.selectedBrand,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(combinedStyles)(BarChartCard));
