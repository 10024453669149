import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class IncomeSummary extends React.Component {
  getFileName = ({ dateFrom, dateTo }) => {
    const title = this.props.t('common.mainMenu.reportsIncomeSummary');
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${dateFrom}-${dateTo}_(${moment().format(
      'YYYY-MM-DD_HH.mm'
    )}).xlsx`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  render() {
    const { t } = this.props;
    const title = t('common.mainMenu.reportsIncomeSummary');

    return (
      <BaseBrandAndDateReport
        title={title}
        excelUrl="reports/income-summary"
        fileName={this.getFileName}
        role={roles.ROLE_SHOW_REPORT_INCOME_SUMMARY}
        pdfAvaliable={false}
        excelAvaliable={true}
        useDateRange={true}
        archiveReportNames={['INCOME_SUMMARY']}
      />
    );
  }
}

export default withTranslation()(IncomeSummary);
