import React, { Component } from 'react';
import { Route, Router, Switch, withRouter } from 'react-router';
import {
  ROLE_CREATE_DICTIONARY,
  ROLE_EDIT_DICTIONARY,
  ROLE_SHOW_DICTIONARY,
} from 'helpers/roles';

import List from './List';
import Form from './Form';
import withRole from 'components/Security/withRole';

class Index extends Component {
  render() {
    const props = this.props;

    const ListSecured = withRole(
      props.showRole ? props.showRole : ROLE_SHOW_DICTIONARY,
      List
    );
    const CreateForm = withRole(
      props.createRole ? props.createRole : ROLE_CREATE_DICTIONARY,
      Form
    );
    const EditForm = withRole(
      props.editRole ? props.editRole : ROLE_EDIT_DICTIONARY,
      Form
    );

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <CreateForm {...props} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => <EditForm {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Index);
