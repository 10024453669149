import React, { Fragment } from 'react';
import { compose } from 'redux';

import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  withStyles,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import { combineStyles } from 'helpers/helpers';
import { FormTextInput, SelectInput } from 'components';

const InputsIndex = ({
  classes,
  name,
  type = 'text',
  label = '',
  value = null,
  handler = () => {},
  ...inputProps
}) => {
  if (type === 'checkbox') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={value ?? false}
            onClick={handler}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={label}
      />
    );
  }

  if (type === 'select') {
    const { options, ...restInputProps } = inputProps;

    return (
      <Fragment>
        <FormLabel className={classes.labelHorizontal}>{label}</FormLabel>
        <SelectInput
          classes={classes}
          value={value}
          options={options}
          handleChange={handler}
          {...restInputProps}
        />
      </Fragment>
    );
  }

  return (
    <FormTextInput
      label={label}
      classes={classes}
      value={value}
      handleChange={handler}
      {...inputProps}
    />
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withStyles(combinedStyles));

export default enhance(InputsIndex);
