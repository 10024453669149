import React from 'react';
import BaseBrandAndDateReport from './BaseBrandAndDateReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import CombinedReport from "./CombinedReport/CombinedReport";

class DishesChecklist extends React.Component {
  state = {
    includeSubscriptions: false,
  };

  getFileName = title => ({ date }) => {
    let fileName = `${title
      .replace(this.props.t('reports.generatePrefix'), '')
      .replaceAll(' ', '_')}-${date}_(${moment().format('YYYY-MM-DD_HH.mm')})`;

    return fileName.charAt(0).toUpperCase() + fileName.slice(1);
  };

  resolveFileName = ({ parameters }) => {
    const title = this.props
      .t('common.mainMenu.reportsDishesChecklist')
      .replace(' ', '-');

    if (parameters.length === 1) {
      const { multiplier, dateFrom, dateTo } = parameters[0];

      return `${multiplier}x${title}-${dateFrom}-${dateTo}_(${moment().format(
        'YYYY-MM-DD_HH.mm'
      )})`;
    }

    return `${title}_(${moment().format('YYYY-MM-DD_HH.mm')})`;
  };

  render() {
    const { t } = this.props;

    return (
      <CombinedReport
        title={t('reports.reportDishesCheck')}
        url="reports/checklist-dishes"
        mimeTypes={['application/vnd.ms-excel', 'application/pdf']}
        role={roles.ROLE_SHOW_REPORT_CHECKLIST_RECIPES}
        fileName={this.resolveFileName}
        useDateRange={true}
        showMultiplier={true}
        validateBrands={false}
        validateDatePicker={true}
        useSubBrands={true}
        useZoneCategories={true}
        archiveReportNames={['CHECK_LIST_DISHES']}
      />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(DishesChecklist);
