import { isGranted } from '../../helpers/helpers';
import { ROLE_EDIT_COST, ROLE_EDIT_TRANSLATION } from '../../helpers/roles';
import CustomInput from '../../components/CustomInput/CustomInput';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

const TableInputContainer = ({
  string,
  translation,
  language,
  t,
  changeTranslationValue,
}) => {
  const [value, setValue] = useState(translation);

  const handleChange = val => {
    setValue(val);
    changeTranslationValue(string, language, val);
  };

  return (
    <CustomInput
      formControlProps={{ fullWidth: true }}
      inputProps={{
        disabled: !isGranted(ROLE_EDIT_TRANSLATION),
        placeholder: t('languageSettings.enterContent', 'Wpisz treść'),
        type: 'text',
        name: `${string}-${language}-input`,
        value: value,
        onChange: event => handleChange(event.target.value),
      }}
    />
  );
};

export default withTranslation()(TableInputContainer);
