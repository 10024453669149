import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import ModuleNotLoaded from './ModuleNotLoaded';

const ClickOnMetricsModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  if (!module) {
    return <ModuleNotLoaded moduleName="ClickOnMetricsModule" />;
  }

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={module.enabled}
            onChange={() =>
              handleModuleUpdate({
                ...module,
                enabled: !module.enabled,
              })
            }
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t(
          'brands.newBrandForm.useClickOnMetrics',
          'Używaj ClickOnMetrics'
        )}
      />
      <FormTextInput
        label="Tracker id"
        classes={classes}
        name="trackerId"
        value={module.trackerId}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            trackerId: e.target.value,
          })
        }
        inputSize={6}
      />
    </Fragment>
  );
};

export default ClickOnMetricsModule;
