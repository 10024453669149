import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';

import { fetchDiets } from 'actions/Diets';
import { fetchPackage } from 'actions/Packages';

import { post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import LogView from 'components/History/LogView';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { withTranslation } from 'react-i18next';

export class Form extends Component {
  state = {
    modal: null,
    modalOptions: [],
    name: '',
    diets: [],
    image: null,
    position: 0,
    imageUrl: null,
    active: false,
    description: '',
    disabledDiets: [],
  };

  packageId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount = async () => {
    await this.props.fetchDiets();

    if (this.isEdit) {
      this.props.fetchPackage(this.packageId).then(response => {
        const PACKAGE = response;

        this.setState({
          name: PACKAGE.name,
          image: PACKAGE.image?.['@id'] || null,
          diets: PACKAGE.diets,
          active: PACKAGE.active,
          position: PACKAGE.position,
          description: PACKAGE.description,
          disabledDiets: PACKAGE.disabledDiets || [],
          imageUrl: PACKAGE.image?.contentUrl || null,
        });
      });
    }
  };

  handleInputChange = (event, value) => {
    const newValue = event.target.value;
    this.setState({ [event.target.name]: value ? value : newValue });
  };

  handleSelect = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  getImage = (stateName, data) => {
    this.setState({
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.setState({
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  handleToggle = e => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  validateForm = () => {
    return this.state.name && this.state.diets.length > 0;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (this.state.position < 1) {
      return this.props.openToast({
        messages: [this.props.t('form.orderStarts')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      name: this.state.name,
      position: parseInt(this.state.position),
      description: this.state.description,
      active: this.state.active,
      image: this.state.image,
      disabledDiets: this.state.disabledDiets,
      diets: this.state.diets,
    };

    const action = this.isEdit
      ? put(`/packages/${this.packageId}`, data)
      : post('/packages', data);

    action.then(() => this.props.history.push('/admin/packages'));
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      diets,
      active,
      imageUrl,
      position,
      description,
      disabledDiets,
    } = this.state;

    return (
      <>
        <AdminTable
          title={
            this.isEdit
              ? this.props.t('form.editFilter')
              : this.props.t('form.addNewFilter')
          }
          icon
        >
          <FormTextInput
            label={this.props.t('form.field.filterName') + '*'}
            classes={classes}
            name="name"
            value={name}
            handleChange={this.handleInputChange}
            inputSize={6}
          />
          <FormTextInput
            label={this.props.t('form.field.order') + '*'}
            classes={classes}
            name="position"
            value={position}
            type="number"
            handleChange={this.handleInputChange}
            inputSize={6}
          />
          <FormTextInput
            label={this.props.t('form.field.description')}
            classes={classes}
            name="description"
            value={description}
            handleChange={this.handleInputChange}
            inputSize={6}
            multiline={true}
            rows={3}
            rowsMax={20}
            maxLength={2000}
          />
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('form.field.active.label')}
                <div style={{ marginLeft: '10px' }}>
                  <Tooltip
                    title={
                      <div>
                        <h4>{this.props.t('form.activeInfo')}</h4>
                      </div>
                    }
                    placement="right"
                  >
                    <Info fontSize={'small'} />
                  </Tooltip>
                </div>
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    name="active"
                    onClick={this.handleToggle}
                    checked={active}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={''}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <SelectInput
                name="diets"
                multiple
                classes={classes}
                label={
                  <div style={{ display: 'flex' }}>
                    <span>{this.props.t('form.field.dietsInFilter')} *</span>
                    <div style={{ marginLeft: '10px' }}>
                      <Tooltip
                        title={
                          <div>
                            <h4>{this.props.t('form.dietsInPackageInfo')}</h4>
                          </div>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </div>
                  </div>
                }
                mapBy="name"
                trackBy="@id"
                value={diets}
                disabledMenuItems={this.props.diets.filter(diet => {
                  if (this.state.disabledDiets.includes(diet['@id'])) {
                    return diet['@id'];
                  }
                })}
                options={this.props.diets.map(diet => {
                  return diet.active
                    ? diet
                    : {
                        ...diet,
                        name: `${diet.name} (${this.props.t('form.inactive')})`,
                      };
                })}
                handleChange={this.handleSelect}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <SelectInput
                name="disabledDiets"
                multiple
                classes={classes}
                label={
                  <FormLabel className={classes.labelHorizontal}>
                    {`${this.props.t('form.field.dietsGrayedOut')}`}
                  </FormLabel>
                }
                mapBy="name"
                trackBy="@id"
                value={disabledDiets}
                disabledMenuItems={this.props.diets.filter(diet => {
                  if (this.state.diets.includes(diet['@id'])) {
                    return diet['@id'];
                  }
                })}
                options={this.props.diets.map(diet => {
                  return diet.active
                    ? diet
                    : { ...diet, name: `${diet.name} (Nieaktywna)` };
                })}
                handleChange={this.handleSelect}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: '20px' }}>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('form.field.filterPhoto')}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10} style={{ marginLeft: '-15px' }}>
              <FormImageUpload
                classes={classes}
                stateName="image"
                getImage={this.getImage}
                removeImage={this.removeImage}
                previewUrl={imageUrl}
              />
            </GridItem>
          </GridContainer>
          <FormControlButtons
            classes={classes}
            discardText={this.props.t('form.cancel')}
            submitText={this.props.t('form.save')}
            cancelPath="/admin/packages"
            handleSubmit={this.handleSubmit}
          />
        </AdminTable>
        {this.isEdit && <LogView iri={`/meal-types/${this.typeId}`} />}
      </>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    mealSizes: state.MealSizes.mealSizes,
    mealType: state.MealTypes.mealType,
    diets: state.Diets.diets,
  };
};

const mapDispatchToProps = {
  fetchPackage,
  fetchDiets,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withToast(withStyles(combinedStyles)(Form)))
);
