import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const TableCategoryComponent = ({ dishItem, dishSizeItem, type, t }) => {
  if (type === 'MEAL' || type === 'MEAL_SHOP') {
    let dish = dishItem ? dishItem.dish : dishSizeItem?.dishSize?.dish;

    if (dish) {
      return (
        <NavLink to={`/admin/meals/edit/${dish.id}`}>
          ({dish.id}) {dish.nameForClient}
        </NavLink>
      );
    }

    return '';
  }

  return '-';
};

export default withTranslation()(TableCategoryComponent);
