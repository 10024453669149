import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import translateColumnConfig from './columnConfig';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SubscriptionOrders from './Dialogs/SubscriptionOrders';
import SubscriptionHistory from './Dialogs/SubscriptionHistory';
import SubscriptionPause from './Dialogs/SubscriptionPause';
import SubscriptionMarkToRemove from './Dialogs/SubscriptionMarkToRemove';
import { Visibility } from '@material-ui/icons';

class List extends Component {
  state = {
    refresh: false,
  };

  customActions = row => {
    return [
      {
        action: () => {},
        icon: <SubscriptionHistory row={row} />,
        color: 'primary',
        simple: true,
        round: false,
      },
      {
        action: () => {},
        icon: <SubscriptionOrders row={row} />,
        color: 'primary',
        simple: true,
        round: false,
      },
      {
        action: () => {},
        icon: <SubscriptionPause row={row} onUpdate={this.refresh} />,
        color: 'primary',
        simple: true,
        round: false,
      },
      {
        action: () => {},
        icon: <SubscriptionMarkToRemove row={row} onUpdate={this.refresh} />,
        color: 'primary',
        simple: true,
        round: false,
      },
      {
        action: ({
          row: {
            clientDiet: { id },
          },
        }) => this.props.history.push(`/admin/ecommerce-diets/preview/${id}`),
        icon: <Visibility />,
        color: 'primary',
        simple: true,
        round: false,
      },
    ];
  };

  refresh = () =>
    this.setState(prevState => ({
      ...prevState,
      refresh: !this.state.refresh,
    }));

  render() {
    const { t } = this.props;

    return (
      <AdminTable title={t('')} icon>
        <DataGrid
          actions={true}
          refresh={this.state.refresh}
          customActions={this.customActions}
          customActionsWidth={180}
          export={true}
          paginationTop={true}
          paginationBottom={true}
          url="/subscriptions"
          reportName={'subscription'}
          role="ORDER"
          defaultSorted={[{ id: 'clientDiet.id', desc: true }]}
          columns={translateColumnConfig(t)}
          minRows={20}
          manipulateQuery={(requestData, query) => {
            if (query['client']) {
              const splicedName = query['client'].split(' ');
              let _andX = [[]];

              if (splicedName.length === 1) {
                _andX[0][0] = {
                  'clientDiet.client.firstName': splicedName[0],
                  'clientDiet.client.lastName': splicedName[0],
                  'clientDiet.client.email': splicedName[0],
                };
              }

              if (splicedName.length === 2) {
                _andX[0][0] = {
                  'clientDiet.client.firstName': splicedName[0],
                  'clientDiet.client.lastName': splicedName[1],
                };
              }

              if (splicedName.length === 3) {
                _andX[0][0] = {
                  'clientDiet.client.firstName': splicedName[0],
                  'clientDiet.client.lastName': splicedName[1],
                  'clientDiet.client.email': splicedName[2],
                };
              }

              const operator = splicedName.length > 1 ? '_andX' : '_orX';

              delete query['client'];
              query[operator] = _andX;
            }

            if (query['clientDiet.id']) {
              if (query['clientDiet.id'].includes('-')) {
                query['id'] = query['clientDiet.id'];
                delete query['clientDiet.id'];
              }
            }

            if (query['clientDiet.subscriptionStartsAt']) {
              const filters = query['clientDiet.subscriptionStartsAt'];
              delete query['clientDiet.subscriptionStartsAt'];

              if (filters.after) {
                query['clientDiet.subscriptionStartsAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['clientDiet.subscriptionStartsAt[before]'] =
                  filters.before;
              }
            }

            if (query.priceAfterDiscount) {
              const filters = query.priceAfterDiscount;
              delete query.priceAfterDiscount;
              if (filters.valueFrom) {
                query['priceAfterDiscount[gte]'] = filters.valueFrom;
              }
              if (filters.valueTo) {
                query['priceAfterDiscount[lte]'] = filters.valueTo;
              }
            }

            return query;
          }}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withTranslation(), withStyles(buttonsStyle));

export default enhance(List);
