import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import defaultState from './defaultState';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import gastro from 'helpers/gastro';

import { post, put } from 'helpers/apiHelpers';
import { withTranslation } from 'react-i18next';

class Form extends Component {
  state = {
    ...defaultState,
  };

  elementId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount() {
    if (this.isEdit) {
      this.fetchValue();
    }
  }

  fetchValue = async () => {
    await gastro
      .get(`${this.props.valueUrl}/${this.elementId}`)
      .then(({ data }) => {
        this.setState({
          name: data.value,
          position: data.position,
        });
      });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    return this.state.name;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('form.nameFieldCannotBeEmpty')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    const data = {
      [this.props.valueKey ? this.props.valueKey : 'value']: this.state.name,
      position: parseInt(this.state.position),
    };

    const action = this.isEdit
      ? put(`${this.props.valueUrl}/${this.elementId}`, data)
      : post(this.props.valueUrl, data);

    action.then(() => this.props.history.push(`/admin/${this.props.valueUrl}`));
  };

  render() {
    const { classes } = this.props;
    return (
      <AdminTable
        title={
          this.isEdit
            ? this.props.t('form.editValue')
            : this.props.t('form.addNewValue')
        }
        icon
      >
        <FormTextInput
          label={this.props.t('form.field.name') + '*'}
          classes={classes}
          name="name"
          value={this.state.name}
          handleChange={this.handleInputChange}
          inputSize={4}
        />
        <FormTextInput
          label={this.props.t('form.field.order') + '*'}
          classes={classes}
          name="position"
          value={this.state.position}
          type="number"
          handleChange={this.handleInputChange}
          inputSize={2}
        />
        <FormControlButtons
          classes={classes}
          discardText={this.props.t('form.cancel')}
          submitText={this.props.t('form.save')}
          cancelPath={`/admin/${this.props.valueUrl}`}
          handleSubmit={this.handleSubmit}
        />
      </AdminTable>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withTranslation()(withToast(withStyles(combinedStyles)(Form)));
