import React, { Component } from 'react';

import PeriodDiscounts from './PeriodDiscounts.container';
import DeliveryDiscountPlaces from './DeliveryDiscountPlaces.container';
import SameAddressDiscounts from './SameAddressDiscounts.container';

import FormSelectSingle from 'components/FormSelect/FormSelectSingle';

import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import LogView from 'components/History/LogView';

const VIEW_PERIOD = 'period';
const VIEW_POINT = 'point';
const VIEW_SAME_ADDRESS = 'sameaddress';

const translateDiscountsTypes = t => [
  {
    name: t('dietDiscountsTypes.period', 'Okres zamówienia'),
    value: VIEW_PERIOD,
  },
  {
    name: t('dietDiscountsTypes.point', 'Odbiór z punktu'),
    value: VIEW_POINT,
  },
  {
    name: t('dietDiscountsTypes.sameaddress', 'Wspólny adres'),
    value: VIEW_SAME_ADDRESS,
  },
];

const historyEndpoints = {
  [VIEW_PERIOD]: '/discount-diet-lengths',
  [VIEW_POINT]: '/discount-pick-up-points',
  [VIEW_SAME_ADDRESS]: '/discount-same-addresses',
};

const discountViews = {
  [VIEW_PERIOD]: <PeriodDiscounts />,
  [VIEW_POINT]: <DeliveryDiscountPlaces />,
  [VIEW_SAME_ADDRESS]: <SameAddressDiscounts />,
};

class PeriodDiscountsContainer extends Component {
  state = {
    currentView: translateDiscountsTypes(this.props.t)[0].value,
  };

  changeDiscountView = event =>
    this.setState({ currentView: event.target.value });

  renderLayout = () => discountViews[this.state.currentView];

  renderLogView = () => (
    <LogView iri={historyEndpoints[this.state.currentView]} />
  );

  render() {
    const { classes } = this.props;

    return (
      <form id="newIngredientForm">
        <Card>
          <CardBody>
            <GridItem md={3}>
              <FormSelectSingle
                classes={classes}
                label={this.props.t('common.dietPrices.discountType')}
                mapBy="name"
                trackBy="value"
                value={this.state.currentView}
                options={translateDiscountsTypes(this.props.t)}
                handleChange={this.changeDiscountView}
                labelSm={2}
                selectSm={8}
              />
            </GridItem>
            {this.renderLayout()}
          </CardBody>
        </Card>
        {this.renderLogView()}
      </form>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle
);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(PeriodDiscountsContainer);
